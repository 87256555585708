import React, { useMemo, useState, useCallback } from 'react';
import {
    Button, ContentBox, Table, Title,
} from 'ui-library';
import PropTypes from 'prop-types';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { messagesColumns, messagesSorting } from 'constants/constants';
import { useDashboardConfig } from 'pages/AdvisoryDashboard/hooks/useDashboardConfig';
import PageSection from '../PageSection/PageSection';
import Preloader from '../Preloader';
import EmptyContent from '../EmptyContent';
import Draggable from '../Draggable/Draggable';
import WidgetResize from '../WidgetResize';
import TableSkeleton from '../TableSkeleton';


function Messages({
    data, isLoading, error, onRow, link, drop, withoutHeader, defaultSize, fromQuickAccessMenu,
}) {
    const { widgetSize, saveWidgetSize } = useDashboardConfig('Messages', defaultSize);
    const { getFormattedDate, getFormattedTime } = useFormatting();

    const [isLimited, setLimit] = useState(false);

    const { t } = useTranslation();

    const onNewMessage = useCallback(() => {
        if (fromQuickAccessMenu) {
            return history.push({
                pathname: `${link}/messages/new`,
                state: { fromQuickAccessMenu: true },
            });
        }

        return history.push(`${link}/messages/new`);
    }, [link, fromQuickAccessMenu, history]);

    const titleComponent = useMemo(() => {
        if (drop) {
            return (
                <Draggable {...drop}>
                    <Title type={2} link={`${link}/messages`}>{t('advisoryDashboard.messages.title')}</Title>
                </Draggable>
            );
        }

        return <Title type={2} link={`${link}/messages`}>{t('advisoryDashboard.messages.title')}</Title>;
    }, [drop, link, t]);
    const messagesData = useMemo(() => data?.slice(0, widgetSize), [data, widgetSize]);

    return (
        <PageSection>
            <ContentBox className={`messages table-without-last-row-padding resize-widget ${isLimited ? 'limited-size' : ''}`}>
                {titleComponent}
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent
                        data={data}
                        text={t('advisoryDashboard.messages.noMessages')}
                        buttonText={t('advisoryDashboard.messages.newMessage')}
                        onClick={() => history.push(`${link}/messages/new`)}
                    >
                        <WidgetResize
                            onLimited={setLimit}
                            widgetSize={widgetSize}
                            setWidgetSize={saveWidgetSize}
                            elementHeight={71}
                            minHeight={100}
                            dataLength={data?.length}
                            SkeletonComponent={TableSkeleton}
                            autoUpdateHeight={false}
                            footerSlot={(
                                <div className="messages__button">
                                    <Button
                                        type="secondary"
                                        size="small"
                                        onClick={onNewMessage}
                                    >
                                        {t('advisoryDashboard.messages.newMessage')}
                                    </Button>
                                </div>
                            )}
                        >
                            <Table
                                data={messagesData}
                                onRow={onRow}
                                columns={messagesColumns(t, getFormattedDate, getFormattedTime)}
                                sorting={messagesSorting}
                                mini
                                headerControls={{
                                    sortByLabel: t('headerControls.sortBy'),
                                }}
                                noHeader={withoutHeader}
                            />
                        </WidgetResize>

                    </EmptyContent>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

Messages.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            Subject: PropTypes.shape({
                title: PropTypes.string,
                subtitle: PropTypes.string,
            }),
            Date: PropTypes.string,
        }),
    ),
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    onRow: PropTypes.func,
    link: PropTypes.string,
    drop: PropTypes.shape({
        role: PropTypes.string,
        tabIndex: PropTypes.number,
        onDragStart: PropTypes.func,
    }),
    withoutHeader: PropTypes.bool,
    defaultSize: PropTypes.number,
    fromQuickAccessMenu: PropTypes.bool,
};

Messages.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
    onRow: () => {
    },
    link: '',
    drop: null,
    withoutHeader: false,
    defaultSize: undefined,
    fromQuickAccessMenu: false,
};

export default React.memo(Messages);
