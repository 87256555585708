import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient, gql } from '@apollo/client';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';

const GET_LIQUIDITY = gql`
    query GetLiquidity($positionId: Int!) {
        securities(
            where: { id: { eq: $positionId } }
        ){
            nodes {
                id
                name
                typeId
                tradingPrice
                currency { id isoCode }
                assetClass { id, name, parent { id, name, parent { parent { id, name, parent { id, name, parent { id, name, parent { id, name } } } } } } }
            }
        }
    }
`;

export const useCreateLiquidity = (options = {}) => {
    const {
        productId: productIdOption,
    } = options;
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const client = useApolloClient();
    const { i18n: { language } } = useTranslation();

    // Callbacks
    const getLiquidity = useCallback(async ({
        currencyId,
        productId = productIdOption,
    } = {}) => {
        setError(null);
        setLoading(true);

        try {
            const params = {
                SecurityTypeIds: [1],
                CurrencyIds: [currencyId],
                Page: 1,
                PageSize: 1,
            };
            const securityIdResponse = await SM.instrumentsService('searchSecurities', [productId, params, { language }]);
            const securityId = securityIdResponse?.data?.Results?.[0]?.Id;
            const { data: response } = await client.query({
                query: GET_LIQUIDITY,
                variables: { positionId: securityId },
            });
            const securityData = response?.securities?.nodes?.[0];

            setData(securityData);
            setLoading(false);

            return securityData;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [client, language, productIdOption]);

    return {
        data, error, isLoading, getLiquidity,
    };
};
