import { getAdaptedDate } from 'utils/formatting';

const MEETING_ID = 1;
const CALL_ID = 2;
const MESSAGE_ID = 3;
const CHAT_ID = 4;
const eventColorByType = {
    [MEETING_ID]: 'green',
    [CALL_ID]: 'turquoise',
    [MESSAGE_ID]: 'purple',
    [CHAT_ID]: 'blue',
};

export const adaptNextUp = (data, {
    types, topics, getFormattedTime = (d) => d, getFormattedDate = (d) => d, t,
}) => {
    const typesAdapted = types.reduce((acc, item) => ({
        ...acc,
        [item.Id]: item.Name,
    }), {});
    const topicsAdapted = topics.reduce((acc, item) => ({
        ...acc,
        [item.Id]: item.Name,
    }), {});

    return data.map((item) => {
        const client = item.Participants.filter((i) => !i.IsMember)[0];

        return ({
            ticket: {
                topic: `${typesAdapted[item.InteractionTypeId] || ''}: ${topicsAdapted[item.InteractionTopicId] || ''}`,
                time: `${getFormattedDate(getAdaptedDate(item.DateFrom, t, true))}, ${getFormattedTime(item.DateFrom)} - ${getFormattedTime(item.DateTo)}`,
                contact: item.Participants
                    .filter((i) => !i.IsMember)
                    .map((i) => ({ id: i.Id, name: `${i.FirstName} ${i.LastName}` })),
                color: eventColorByType[item.InteractionTypeId],
            },
            contactId: item.Participants.filter((i) => !i.IsMember)[0]?.Id,
            clientName: `${client.FirstName} ${client.LastName}`,
        });
    });
};
