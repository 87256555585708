import { CHANGE_MODEL } from 'constants/constants';

export const CHANGE_STRATEGY = 'change-strategy';
export const MODIFY = 'modify';
export const QUICK_EDIT = 'quick-edit';
export const REBALANCE = 'rebalance';
export const NEW_PORTFOLIO = 'new-portfolio';

export const mapActionTypeName = (action, t) => {
    const actionsMap = {
        [CHANGE_STRATEGY]: t('overview.changeStrategy'),
        [CHANGE_MODEL]: t('overview.changeModel'),
        [QUICK_EDIT]: t('clientDashboard.portfolioEdit.quickEdit'),
        [MODIFY]: t('clientDashboard.portfolioEdit.title'),
        [REBALANCE]: t('clientDashboard.portfolio.rebalance'),
        [NEW_PORTFOLIO]: t('clientDashboard.portfolio.newPortfolio'),
    };

    return actionsMap[action] || null;
};
