import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { SORT_DIRECTION_DESC, sorterByDate } from 'utils/sorting';
import { adaptNews, adaptRssNews } from '../pages/AdvisoryDashboard/adapters/adaptNews';

export const useNews = (newsItem) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [newsList, setNewsList] = useState([]);
    const { i18n } = useTranslation();

    useEffect(() => {
        if (newsItem === undefined) {
            setLoading(true);
            setError(null);
            Promise.all([
                ServiceManager.commonContent('getNews', [1, i18n.language, {}]),
                ServiceManager.rssService('rssFeed', [i18n.language, {}]),
            ])
                .then((response) => response.map((item) => item.data))
                .then((response) => {
                    try {
                        const adapted = [
                            ...adaptNews(response[0]),
                            ...adaptRssNews(response[1]?.result),
                        ];

                        setNewsList(sorterByDate('dateSort')(adapted, { direction: SORT_DIRECTION_DESC }));
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch(handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                ));
        } else {
            setLoading(false);
            setNewsList([newsItem]);
        }
    }, [i18n.language, newsItem]);

    return { data: newsList, isLoading, error };
};
