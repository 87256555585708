import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PortfolioOverviewProvider } from 'domain/Portfolio';
import { ClarityProvider } from 'domain/Clarity';
import { getClientId } from 'utils';
import PortfolioOverview from './pages/SinglePortfolioView';
import PositionDetails from './pages/PositionDetails/PositionDetails';
import PositionDetailsOverview from './pages/PositionDetails/PositionDetailsOverview';
import PortfolioActionsRouter from './PortfolioActionsRouter';
import withClientLayout from './hocs/withClientLayout';
import withPortfolioActionsLayout from './hocs/withPortfolioActionsLayout';
import FundingInformation from './pages/FundingInformation/FundingInformation';
import PendingOrders from './pages/PendingOrders/PendingOrders';
import withPendingOrdersAccess from './hocs/withPendingOrdersAccess';
import withFundingInformationAccess from './hocs/withFundingInformationAccess';
import withActionAccess from './hocs/withActionAccess';
import withPortfolioAccess from './hocs/withPortfolioAccess';
import { adaptCashFlowsWithInflowsAndOutflows } from '../../../../domain/Portfolio/adapters/adaptCashFlowsWithInflowsAndOutflows';

const PortfolioRouter = (props) => {
    const { match: { path, params: { dfsClientId, portfolioId } } } = props;
    const clientId = getClientId(dfsClientId);
    const withProps = (Component) => (defProps) => <Component {...props} {...defProps} />;

    return (
        <PortfolioOverviewProvider
            options={{
                id: Number(portfolioId),
                clientId: Number(clientId),
                portfolio: { loadInitially: true },
                cashFlows: {
                    adaptData: adaptCashFlowsWithInflowsAndOutflows,
                },
            }}
        >
            <ClarityProvider>
                <Switch>
                    <Route exact path={`${path}`} render={withProps(withPortfolioAccess(PortfolioOverview))} />
                    <Route
                        exact
                        path={`${path}/position/:productId/:positionId/:tabId`}
                        render={withProps(withPortfolioActionsLayout(PositionDetails))}
                    />
                    <Route
                        exact
                        path={`${path}/position/:productId/:positionId/:tabId/overview`}
                        render={withProps(withClientLayout(PositionDetailsOverview))}
                    />
                    <Route
                        exact
                        path={`${path}/position/:productId/:positionId/:tabId/:extraTabId`}
                        render={withProps(withPortfolioActionsLayout(PositionDetails))}
                    />
                    <Route
                        exact
                        path={`${path}/funding-information`}
                        render={withProps(withFundingInformationAccess(FundingInformation))}
                    />
                    <Route
                        exact
                        path={`${path}/pending-orders`}
                        render={withProps(withPendingOrdersAccess(PendingOrders))}
                    />
                    <Route path={`${path}`} component={withActionAccess(PortfolioActionsRouter)} />
                    <Redirect from="*" to="/404" />
                </Switch>
            </ClarityProvider>
        </PortfolioOverviewProvider>
    );
};

PortfolioRouter.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

PortfolioRouter.defaultProps = {};

PortfolioRouter.displayName = 'PortfolioRouter';

export default PortfolioRouter;
