import {
    useCallback, useEffect, useMemo, useReducer, useState,
} from 'react';
import {
    ADVISORY,
    DISCRETIONARY,
    EXECUTION_ONLY,
    generateObject,
} from 'constants/portfolioProducts';
import { useProducts } from 'hooks/useProducts';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';
import { useModelList } from 'hooks/useModelList';
import { useClientRiskCategory } from 'hooks/useClientRiskCategory';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import useFormatting from 'locale/useFormatting';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { checkNullData } from 'utils';
import { useCheckRiskProfile } from '../../../hooks/useCheckRiskProfile';
import { useNewPortfolioData } from './useNewPortfolioData';

const initialState = {
    isLoadingCreate: false,
    errorStrategy: null,
    riskCategories: {},
    showRiskDisclaimer: false,
    clientRiskProfile: {},
    isLoadingStrategy: false,
    riskCategoriesLang: null,
    errorCreate: null,
};

export function reducer(state, action) {
    switch (action.type) {
        case 'setErrorCreate':
            return { ...state, errorCreate: action.payload };
        case 'setIsLoadingCreate':
            return { ...state, isLoadingCreate: action.payload };
        case 'setErrorStrategy':
            return { ...state, errorStrategy: action.payload };
        case 'setIsLoadingStrategy':
            return { ...state, isLoadingStrategy: action.payload };
        case 'setRiskCategories':
            return {
                ...state,
                riskCategories:
                {
                    ...state.riskCategories,
                    [action.lang]: {
                        ...state.riskCategories?.[action.lang], ...action.payload,
                    },
                },
            };
        case 'setRiskCategoriesLang':
            return { ...state, riskCategoriesLang: action.payload };
        case 'setShowRiskDisclaimer':
            return { ...state, showRiskDisclaimer: action.value };
        case 'setClientRiskProfile':
            return { ...state, clientRiskProfile: action.value };
        default:
            throw new Error();
    }
}

export const useNewPortfolio = ({ clientId, loadInitially }) => {
    const { newPortfolio, saveNewPortfolio } = useNewPortfolioData();
    const { products: productsData, isLoadingProducts, errorProducts } = useProducts();

    const [state, dispatch] = useReducer(reducer, initialState);

    const {
        showRiskDisclaimer,
        clientRiskProfile,
        riskCategories,
        isLoadingStrategy,
        riskCategoriesLang,
        errorStrategy,
    } = state;

    const { i18n, t } = useTranslation();
    const [formValues, setFormValues] = useState(newPortfolio);
    const { getFormattedNumber } = useFormatting();

    // Minimum Amount
    const minimumAmount = useMemo(() => (productsData.products || []).find(
        (i) => i.id === formValues?.product?.value,
    )?.minimumInvestmentValue, [productsData.products, formValues]);

    const minimumInvestmentValidate = useCallback((v) => {
        if (!minimumAmount) {
            return true;
        }

        return +v >= minimumAmount;
    }, [minimumAmount, formValues?.product?.value]);

    // form
    const schema = useMemo(() => object().shape({
        portfolioAmount: string().required(t('onBoarding.goal.validation.initialInvestmentText')).test('minimum-portfolio-amount', `${t('portfolio.minimumInvestment.error.part1')} ${getFormattedNumber(minimumAmount, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        })}${t('portfolio.minimumInvestment.error.part2')}`, minimumInvestmentValidate).nullable(),
        selectedCurrency: object().required(t('validation.mandatoryField')),
        product: object().required(t('validation.mandatoryField')).nullable(),
        strategy: object().shape({
            value: string(),
            label: string(),
        }).test('is-required', t('validation.mandatoryField'), function (value) {
            if (this?.parent?.product) {
                const isExecution = this?.parent?.product.label.toLowerCase()
                    .includes(EXECUTION_ONLY);

                return isExecution ? true : !!value;
            }

            return true;
        }).nullable(),
        model: string().test('is-required', t('validation.mandatoryField'), function (value) {
            if (this?.parent?.product) {
                const isExecution = this?.parent?.product.label.toLowerCase()
                    .includes(EXECUTION_ONLY);

                return isExecution ? true : !!value;
            }

            return true;
        }).nullable(),
    }), [t, minimumInvestmentValidate, getFormattedNumber, minimumAmount]);
    const resolver = useYupValidationResolver(schema);
    const {
        register, handleSubmit, errors, setValue, reset, control, getValues, formState, trigger,
    } = useForm({
        resolver,
        reValidateMode: 'onChange',
        shouldUnregister: false,
    });

    /**
     * pre-populate the form. its useful during back and forth navigation.
     */
    useEffect(() => {
        if (!checkNullData(newPortfolio)) {
            const {
                model, portfolioAmount, selectedCurrency, product, strategy,
            } = newPortfolio;


            setValue('model', model);
            setValue('portfolioAmount', portfolioAmount);
            setValue('selectedCurrency', selectedCurrency);
            setValue('product', product);
            setValue('strategy', strategy);
        }
    }, [newPortfolio]);

    useEffect(() => {
        register('portfolioAmount');
        register('selectedCurrency');
        register('product');
        register('strategy');
        register('model');
    }, [register]);

    // Validation
    const formErrors = Object.keys(errors || {}).reduce((acc, key) => ({
        ...acc, [key]: errors[key].message,
    }), {});

    // Data
    const checkRiskCategories = useMemo(() => !riskCategories?.[i18n.language]
        || !riskCategories?.[i18n.language]?.[formValues?.product?.value]
        || i18n.language !== riskCategoriesLang,
    [riskCategories, i18n.language, formValues?.product, riskCategoriesLang]);

    const strategyOptions = useMemo(
        () => riskCategories?.[i18n.language]?.[formValues?.product?.value]?.map(
            (item) => ({
                label: item.Name,
                value: item.Id,
            }),
        ), [riskCategories, i18n.language, formValues?.product],
    );
    const strategyOptionsByProduct = generateObject({
        [ADVISORY]: strategyOptions,
        [DISCRETIONARY]: strategyOptions,
        [EXECUTION_ONLY]: [],
    });
    const strategyDisabled = generateObject({
        [ADVISORY]: false,
        [DISCRETIONARY]: false,
        [EXECUTION_ONLY]: true,
    });
    const listCurrenciesFiltered = useMemo(() => (productsData?.currencies || []).map((i) => (
        { value: i.Id, label: i.CurrencyCode }))
        ?.filter((i) => i.value !== ''), [productsData]);
    const productOptions = (productsData?.products || [])?.map((item) => ({
        label: item.name,
        value: item.id,
    }));

    // Hooks
    const {
        data: strategyList, isLoading: isLoadingModelList, error: errorModelList,
    } = useModelList(
        { productId: formValues?.product?.value, riskCategoryId: formValues?.strategy?.value },
    );

    // Callbacks
    const getRiskCategories = useCallback(async () => {
        if (!formValues?.product?.value) return null;

        try {
            if (checkRiskCategories) {
                dispatch({ type: 'setIsLoadingStrategy', payload: true });
                const riskCategoriesResp = await ServiceManager.commonService('getRiskCategoriesByProduct', [formValues?.product?.value, i18n.language]);

                dispatch({ type: 'setRiskCategories', payload: { [formValues?.product?.value]: riskCategoriesResp.data }, lang: i18n.language });
                dispatch({ type: 'setRiskCategoriesLang', payload: i18n.language });
                dispatch({ type: 'setIsLoadingStrategy', payload: false });

                return riskCategoriesResp.data;
            }

            return riskCategories[i18n.language][formValues?.product?.value];
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorStrategy', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoadingStrategy', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [formValues?.product, checkRiskCategories, riskCategories, i18n.language]);

    const onChange = useCallback((type) => (value) => {
        if (type === 'portfolioAmount') {
            setValue(type, value);
            trigger(type);
        } else if (type === 'product') {
            const i = productsData?.products?.find(
                ({ id }) => id === value,
            );

            setValue(type, { label: i.name, value: i.id });
            setValue('strategy', null);
            setValue('model', '');
            if (formState.isSubmitted) {
                trigger('model');
            }
        } else if (type === 'strategy') {
            const i = strategyOptionsByProduct[formValues?.product?.value].find(
                (item) => item.value === value,
            );

            setValue(type, { label: i?.label, value: i?.value });
            setValue('model', '');
        } else if (type === 'selectedCurrency') {
            const i = listCurrenciesFiltered.find(
                (item) => item.value === value,
            );

            setValue(type, { label: i?.label, value: i?.value });
        } else {
            setValue(type, value);
        }

        if (formState.isSubmitted) {
            trigger(type);
        }
        setFormValues(getValues());
    }, [
        setValue,
        trigger,
        formState.isSubmitted,
        productsData.products,
        strategyOptionsByProduct,
        setFormValues,
    ]);

    // Effects
    useEffect(() => {
        if (loadInitially) getRiskCategories();
    }, [loadInitially, getRiskCategories]);
    useEffect(() => {
        if (loadInitially) {
            saveNewPortfolio({
                product: formValues?.product,
                strategy: formValues?.strategy,
                model: formValues?.model,
                portfolioAmount: formValues?.portfolioAmount,
                portfolioCurrency: formValues?.selectedCurrency,
            });
        }
    }, [loadInitially, saveNewPortfolio, state, formValues]);
    useEffect(() => {
        if (formValues?.product?.value && minimumAmount) {
            trigger('portfolioAmount');
        }
    }, [formValues?.product?.value, minimumAmount, trigger]);

    // Risk Profile Check
    const { comparePortfolioRisk } = useCheckRiskProfile(clientId);
    const { clientRiskCategory } = useClientRiskCategory(clientId);

    useEffect(() => {
        dispatch({ type: 'setShowRiskDisclaimer', value: false });

        if (loadInitially && !clientRiskProfile?.ChosenRiskCategory) {
            dispatch({ type: 'setClientRiskProfile', value: clientRiskCategory });
        }

        if (formValues?.strategy?.value) {
            dispatch({
                type: 'setShowRiskDisclaimer',
                value: clientRiskProfile?.ChosenRiskCategory?.Id
                    && !comparePortfolioRisk(
                        formValues?.strategy?.value,
                        clientRiskProfile?.ChosenRiskCategory?.Id,
                    ),
            });
        }
    }, [comparePortfolioRisk,
        clientRiskProfile,
        formValues?.strategy,
        clientRiskCategory,
    ]);


    return {
        productOptions,
        state,
        strategyDisabled,
        strategyOptionsByProduct,
        isLoadingProducts,
        errorProducts,
        listCurrencies: listCurrenciesFiltered,
        showRiskDisclaimer,
        clientRiskProfile,
        isLoadingStrategy,
        errorStrategy,
        strategyList,
        isLoadingModelList,
        errorModelList,
        control,
        register,
        formErrors,
        handleSubmit,
        onChange,
        values: formValues,
        onPartiallyChange: reset,
        minimumAmount,
    };
};
