import { useCallback, useState } from 'react';
import history from 'services/history';

export const usePortfolioCreating = (dfsClientId) => {
    const [data] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const cancelPortfolio = useCallback(() => {
        history.push(`/client/${dfsClientId}/portfolios`);
    }, []);

    const createPortfolio = useCallback(() => {
        setError(null);
        setLoading(true);
    }, []);

    return {
        cancelPortfolio,
        createPortfolio,
        data,
        isLoading,
        error,
    };
};
