import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import { useFormatting, useLocale } from 'locale';
import ServerError from 'errors/ServerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { portfolioActions } from '../../../constants';
import { adaptPortfolios } from '../adapters/adaptPortfolios';

export const usePortfolios = (clientId, dfsId, proposals) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const { i18n, t } = useTranslation();
    const { getFormattedCurrency } = useFormatting();
    const { locale } = useLocale();

    const getInvestmentOverview = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const params = { language: i18n.language };

            const { data: dataInvestment } = await ServiceManager.portfolioManagement('getInvestmentOverview', [clientId, params]);
            const portfoliosOverview = await Promise.all(dataInvestment.Portfolios
                .map(async ({ Id }) => ({
                    Id,
                    ...(await ServiceManager.portfolioManagement('getPortfolioOverview', [Id, clientId, params])).data,
                })));

            try {
                setData(
                    adaptPortfolios({
                        data: dataInvestment,
                        dfsId,
                        portfolioActions,
                        t,
                        getFormattedCurrency,
                        portfoliosOverview,
                        proposals: proposals.data,
                    }),
                );
                setLoading(false);

                return data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [i18n.language, locale, proposals.data, clientId, dfsId]);

    useEffect(() => {
        if (proposals?.isLoading) return;

        getInvestmentOverview();
    }, [getInvestmentOverview, locale, proposals?.isLoading]);

    return { data, isLoading, error };
};
