import {
    CellLink, CellWithMoreActions, CellPerformance,
} from 'components/renderers';
import { sorterString } from 'utils/sorting';
import { renderers } from 'ui-library';

const { CellWithCheckboxLinked } = renderers;

export const REJECTED_STATUS = 'Rejected';
export const COUNT_OF_PAGE = 10;
export const selectType = 'Select';
export const selectMultipleType = 'SelectMultiple';
export const fieldTypes = [selectType, selectMultipleType];
export const tableColumnsClient = (getFormattedNumber) => [
    {
        dataPath: 'id',
        hidden: false,
        key: 'internalId',
        render: CellLink,
    },
    {
        dataPath: 'relationshipInformation.externalId',
        hidden: true,
        key: 'externalId',
    },
    {
        dataPath: 'personalInformation.displayName',
        hidden: false,
        key: 'name',
    },
    {
        dataPath: 'relationshipInformation.contactType',
        onFilter: false,
        hidden: true,
        key: 'type',
    },
    {
        dataPath: 'relationshipInformation.isProspect',
        key: 'status',
        hidden: true,
    },
    {
        dataPath: 'personalInformation.identification.countryOfNationality',
        key: 'nationality',
        hidden: true,
    },
    {
        dataPath: 'communicationMethods.primaryAddressCountry',
        key: 'country',
        hidden: false,
    },
    {
        dataPath: 'taxSituation.isUsPerson',
        hidden: true,
        key: 'isUsPerson',
    },
    {
        dataPath: 'politicalExposure.isPoliticallyExposedPerson',
        hidden: true,
        key: 'pep',
    },
    {
        dataPath: 'relationshipInformation.lastClientContactDate',
        hidden: true,
        key: 'lastClientContactDate',
    },
    {
        dataPath: 'relationshipInformation.lastClientLoginDate',
        hidden: true,
        key: 'lastClientLoginDate',
    },
    {
        dataPath: 'investmentInformation.chosenRiskCategory',
        hidden: true,
        key: 'chosenRiskCategory',
    },
    {
        dataPath: 'investmentInformation.totalNumberOfPortfolios',
        hidden: true,
        key: 'totalNumberOfPortfolios',
    },
    {
        dataPath: 'investmentInformation.activeProducts',
        key: 'activeProducts',
        hidden: true,
    },
    {
        dataPath: 'investmentInformation.performanceYTD',
        hidden: false,
        key: 'performanceYTD',
        render: CellPerformance(getFormattedNumber),
    },
    {
        dataPath: 'investmentInformation.performance1Year',
        hidden: true,
        key: 'performance1Year',
        render: CellPerformance(getFormattedNumber),

    },
    {
        dataPath: 'investmentInformation.performance3Year',
        hidden: true,
        key: 'performance3Year',
        render: CellPerformance(getFormattedNumber),
    },
    {
        dataPath: 'investmentInformation.performance5Year',
        hidden: true,
        key: 'performance5Year',
        render: CellPerformance(getFormattedNumber),
    },
    {
        dataPath: 'investmentInformation.performanceSinceInception',
        hidden: true,
        key: 'performanceSinceInception',
        render: CellPerformance(getFormattedNumber),
    },
    {
        dataPath: 'investmentInformation.reportingCurrency',
        key: 'reportingCurrency',
        hidden: false,
    },
    {
        dataPath: 'investmentInformation.totalValueOfPortfolios',
        hidden: false,
        key: 'totalValueOfPortfolios',
        className: 'text-right',
    },
    {
        dataPath: 'investmentInformation.cashComponentOfTotalValue',
        hidden: true,
        key: 'cashComponentOfTotalValue',
        className: 'text-right',
    },
    {
        dataPath: 'investmentInformation.securitiesComponentOfTotalValue',
        hidden: true,
        key: 'securitiesComponentOfTotalValue',
        className: 'text-right',
    },

    {
        key: 'actions',
        title: '',
        titleType: 'columns',
        width: 40,
        minWidth: 40,
        fixed: 'right',
        render: CellWithMoreActions,
    },
];

export const tableColumnsProspect = () => [
    {
        dataPath: 'id',
        hidden: false,
        key: 'internalId',
        render: CellLink,
    },
    {
        dataPath: 'personalInformation.displayName',
        hidden: false,
        key: 'name',
    },
    {
        dataPath: 'relationshipInformation.contactType',
        hidden: true,
        key: 'type',
    },
    {
        dataPath: 'relationshipInformation.isProspect',
        key: 'status',
        hidden: false,
    },
    {
        dataPath: 'relationshipInformation.onboardingStage',
        key: 'onboardingStage',
        hidden: false,
    },
    {
        dataPath: 'personalInformation.identification.countryOfNationality',
        key: 'nationality',
        hidden: true,
    },
    {
        dataPath: 'communicationMethods.primaryAddressCountry',
        key: 'country',
        hidden: false,
    },
    {
        dataPath: 'taxSituation.isUsPerson',
        hidden: true,
        key: 'isUsPerson',
    },
    {
        dataPath: 'politicalExposure.isPoliticallyExposedPerson',
        hidden: false,
        key: 'pep',
    },
    {
        key: 'actions',
        title: '',
        titleType: 'columns',
        width: 40,
        minWidth: 40,
        fixed: 'right',
        render: CellWithMoreActions,
    },
];

export const data = [
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Conor Estrada',
        type: 'Private',
        product: 'Execution only',
        country: 'Switzerland',
        rm: 'Carlos Jakob',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Fiona Figueroa',
        type: 'Private',
        product: 'Execution only',
        country: 'Swiss',
        rm: 'Sandra Weiler',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Sandra Weller',
        type: 'Private',
        product: 'Execution only',
        country: 'Switzerland',
        rm: 'Carlo Del Sol',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Adalyn Donovan',
        type: 'Private',
        product: 'Execution only',
        country: 'Democratic Republic of the Congo',
        rm: 'Bruce Banner',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Presley Riley',
        type: 'Private',
        product: 'Execution only',
        country: 'Switzerland',
        rm: 'Carl Jackson',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Easton Hodges',
        type: 'Private',
        product: 'Execution only',
        country: 'Russia',
        rm: 'Natasha Romanoff',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Jamie Caldwell',
        type: 'Private',
        product: 'Execution only',
        country: 'Luxembourg',
        rm: 'Peter Parker',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Gwendolyn Thomas',
        type: 'Private',
        product: 'Execution only',
        country: 'Denmark',
        rm: 'Peter Quill',
        icon: 'action',
    },
];

export const filters = data.map((item) => (
    Object.values(item)
))
    .reduce((a, b) => (
        a.concat(b)
    ), [])
    .filter((val, i, arr) => (
        arr.indexOf(val) === i && val !== ''
    ));

// TODO: unused code below, to be deleted after approval
export const getProspectColumns = (t) => ([
    {
        key: 'internalId',
        title: t('clients.columns.internalId'),
        sorter: sorterString('internalId'),
        render: CellWithCheckboxLinked,
    },
    {
        key: 'name',
        title: t('clients.columns.name'),
        sortable: true,
        sorter: sorterString('name'),
    },
    {
        key: 'type',
        title: t('clients.columns.type'),
        sorter: sorterString('type'),
    },
    {
        key: 'country',
        title: t('clients.columns.country'),
        filterable: true,
        sorter: sorterString('country'),
    },
    {
        key: 'rm',
        title: t('clients.columns.rm'),
        filterable: true,
        sorter: sorterString('rm'),
    },
]);
