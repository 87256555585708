import React from 'react';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import Preloader from 'components/Preloader';
import { usePortfolio } from 'domain/Portfolio';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject,
} from 'constants/portfolioProducts';
import AddPositionAdvisory from './products/AddPositionAdvisory';
import AddPositionDiscretionary from './products/AddPositionDiscretionary';
import AddPositionExecutionOnly from './products/AddPositionExecutionOnly';
import './AddPosition.css';

const AddPositionByProduct = generateObject({
    [ADVISORY]: AddPositionAdvisory,
    [DISCRETIONARY]: AddPositionDiscretionary,
    [EXECUTION_ONLY]: AddPositionExecutionOnly,
});

function AddPosition(props) {
    useCancelAllRequests();

    const { portfolio: { productId, error } } = usePortfolio();
    const AddPositionPage = AddPositionByProduct[productId] || (() => '');

    return (
        <Preloader isLoading={productId === undefined} error={error}>
            <AddPositionPage {...props} />
        </Preloader>
    );
}

AddPosition.propTypes = {
};

AddPosition.defaultProps = {};

export default AddPosition;
