import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Accordion, AccordionPanel as Panel, Row, Column,
} from 'ui-library';
import Preloader from 'components/Preloader';
import { useLocale, useFormatting } from 'locale';
import Analysis from 'components/Analysis';
import PositionsCompare from 'components/PositionsCompare';
import Allocation from 'components/AllocationCompare';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import { OverviewItem, OverviewPanel } from 'components/Overview';
import { TYPE_CURRENCY } from 'utils/formatting';
import { formatDate, yearFormat } from 'utils/datetime';
import { groupComparedPositions } from 'adaptors/groupComparedPositions';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useReviewData } from '../../../../hooks/useReviewData';
import { useComparePortfolio } from '../../hooks/useComparePortfolio';
import { getCompareColumns } from '../../../../constants';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';

function ModifyGoal(props) {
    const { contactId, onPageChange, location: { pathname } } = props;

    const {
        goalDetails,
        product,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();
    const { locale } = useLocale();
    const { t } = useTranslation();
    const projectionYears = useMemo(
        () => formatDate(goalDetails.targetDate, yearFormat) - formatDate(new Date(), yearFormat),
        [goalDetails.targetDate, formatDate],
    );
    const positionLink = `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`;
    const options = useMemo(
        () => ({
            positionLink,
            projectionYears,
            productId: +product.id,
            targetDate: goalDetails.targetDate,
            targetValue: +goalDetails.targetValue,
            currency: goalDetails.selectedCurrency,
            portfolioValue: +goalDetails.initialInvestment,
            bandwidthTitle: t('onBoarding.3rdPartyPortfolio'),
            riskReturnTitle: t('onBoarding.3rdPartyPortfolio'),
            performanceTitle: t('onBoarding.3rdPartyPortfolio'),
        }),
        [
            t,
            product.id,
            positionLink,
            projectionYears,
            goalDetails.targetDate,
            goalDetails.targetValue,
            goalDetails.selectedCurrency,
            goalDetails.initialInvestment,
        ],
    );
    const productId = product ? product.id : undefined;
    const {
        data, isLoading, error,
    } = useComparePortfolio(contactId, productId, options);
    const proposedOptions = useMemo(
        () => ({
            bandwidthTitle: t('onBoarding.proposedPortfolio'),
            riskReturnTitle: t('onBoarding.proposedPortfolio'),
            performanceTitle: t('onBoarding.proposedPortfolio'),
        }),
        [t],
    );
    const {
        data: strategyData, isLoading: isLoadingStrategy, error: errorStrategy,
    } = useReviewData(contactId, proposedOptions);
    const isLoadingData = useMemo(() => isLoading || isLoadingStrategy,
        [isLoading, isLoadingStrategy]);
    const errorData = useMemo(() => error || errorStrategy,
        [error, errorStrategy]);
    const [positions, setPositions] = useState([]);

    useEffect(() => {
        if (data.positionsRaw && strategyData.positionsRaw) {
            setPositions(groupComparedPositions({
                positions: data.positionsRaw,
                modelPositions: strategyData.positionsRaw,
                portfolioCurrency: goalDetails.selectedCurrency.id,
                t,
                portfolioTotalValue: +goalDetails.initialInvestment,
                positionLink,
                getFormattedNumber,
                getFormattedCurrency,
            }));
        }
    }, [data.positionsRaw, strategyData.positionsRaw, positionLink, locale]);

    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoadingData, true);
    const {
        analysisData, isLoadingModel, errorModel, onPerformanceChange, performanceSelected,
    } = useAnalysisDetails({
        portfolio: data,
        strategy: strategyData,
        positions,
        isLoading: isLoadingData,
        benchmark,
    });

    const AllocationRender = useMemo(() => (
        <Allocation
            data={data.investmentAllocation}
            dataNew={strategyData?.investmentAllocation || data.investmentAllocation}
            isLoading={isLoadingData}
            titles={[t('onBoarding.3rdPartyPortfolio'), t('onBoarding.proposedPortfolio')]}
            error={errorData}
        />
    ), [
        data.investmentAllocation,
        strategyData?.investmentAllocation,
        isLoadingData,
        errorData,
    ]);
    const onPrev = () => {
        onPageChange('compare-positions');
    };
    const onContinue = () => {
        onPageChange('');
    };

    return (
        <OnBoardingBaseTemplate
            title={`${goalDetails.goalName} - ${t('onBoarding.compare')}`}
            className="goal-review on-boarding_modify"
            prevButton={{
                onClick: onPrev,
            }}
            nextButton={{
                text: t('onBoarding.continue'),
                disabled: isLoading,
                loading: isLoadingData,
                onClick: onContinue,
            }}
        >
            <Preloader isLoading={isLoadingData} error={errorData}>
                <div className="content-wrapper">
                    <div>
                        <OverviewPanel>
                            <Row>
                                <Column size={['sm-3']}>
                                    <OverviewItem
                                        title={t('overview.portfolioValue')}
                                        value={data.portfolioValue}
                                        type={TYPE_CURRENCY}
                                        currency={data.currency}
                                    />
                                </Column>
                            </Row>
                        </OverviewPanel>
                    </div>
                    <Accordion defaultActiveKey={['1', '2', '3']}>
                        <Panel header={t('clientDashboard.portfolio.allocation')} key="1" className="allocation">
                            {AllocationRender}
                        </Panel>
                        <Panel header={t('clientDashboard.portfolio.analysis')} key="2" className="analysis">
                            <Analysis
                                data={analysisData}
                                onFilterChange={onPerformanceChange}
                                benchmarkOptions={benchmarkOptions}
                                benchmarkSelected={benchmarkSelected}
                                onBenchmarkChange={onBenchmarkChange}
                                isLoadingBenchmarks={isLoadingBenchmark}
                                performanceSelected={performanceSelected}
                                isLoading={isLoadingModel || isLoadingStrategy}
                                compareTitles={[t('onBoarding.3rdPartyPortfolio'), t('onBoarding.proposedPortfolio')]}
                                error={errorModel || errorStrategy}
                                isPortfolioProposed
                            />
                        </Panel>
                        <Panel header={t('clientDashboard.portfolio.positions')} className="positions" key="3">
                            <PositionsCompare
                                data={{ positions }}
                                columns={getCompareColumns(t)}
                                isLoading={isLoading}
                                error={error}
                            />
                        </Panel>
                    </Accordion>
                </div>
            </Preloader>
        </OnBoardingBaseTemplate>
    );
}

ModifyGoal.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
    onPageChange: PropTypes.func,
};

ModifyGoal.defaultProps = {
    onPageChange: () => {
    },
};

export default ModifyGoal;
