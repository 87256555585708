import {
    useEffect, useState, useCallback, useRef,
} from 'react';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useAllContacts } from 'hooks/useAllContacts';
import { adaptClientListForWidget } from '../adapters/adaptClientList';


export const useClientList = (options = {}) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [names, setNames] = useState([]);
    const {
        loadInitially = false,
        searchDelay = 300,
    } = options;
    const delayedCall = useRef(null);

    const {
        contactsRawData,
        getContactsByName,
        isLoadingContacts,
        contactsError,
    } = useAllContacts({ loadInitially });

    const searchContacts = async (search) => {
        clearTimeout(delayedCall.current);
        if (search.length === 0) {
            setData([]);
            setNames([]);
        }

        if (search.length >= 3) {
            delayedCall.current = setTimeout(async () => {
                const results = await getContactsByName(search);
                const adapted = adaptClientListForWidget(results);
                const namesList = adapted.map(({ name }) => name);

                setData(adapted);
                setNames(namesList);
            }, searchDelay);
        }
    };

    const getClients = useCallback(() => {
        setLoading(true);

        if (contactsError) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            );
        }

        if (!isLoadingContacts && contactsRawData) {
            try {
                const adapted = adaptClientListForWidget(contactsRawData.contacts.items);

                setData(adapted);
                setNames(adapted.map(({ name }) => name));
                setLoading(false);
            } catch (err) {
                throw new AdapterError(err);
            }
        }
    }, [contactsRawData, isLoadingContacts, contactsError]);

    useEffect(() => {
        if (loadInitially) getClients();
    }, [loadInitially, getClients]);

    return {
        data,
        names,
        isLoading,
        error,
        searchContacts,
    };
};
