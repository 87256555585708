/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Column,
    Infobox,
    Paragraph,
    Title,
} from 'ui-library';
import Preloader from 'components/Preloader';
import WidgetError from 'components/WidgetError';
import RadioGroupReview from 'components/RadioGroupReview';
import './Review.css';

const Review = ({
    risks, isLoading, error, changeStep, data,
}) => {
    const [infoBox] = useState(false);
    const onClick = (index) => () => {
        changeStep(index);
    };

    useEffect(() => {
        /*
        if (risks.length > 0) {
            if (risks[0].score > risks[1].score) setInfoBox(step3Data.infobox[1]);
            if (risks[0].score !== risks[1].score) setInfoBox(step3Data.infobox[0]);
        }
         */
    }, [risks]);

    return (
        <Preloader isLoading={isLoading}>
            <div className="risk-profile-review">
                {
                    data.sections.map((section, index) => (
                        <div className="review-section" key={`review-section-${index + 1}`}>
                            <div className="row">
                                <Column size="9">
                                    <Title type={3}>{section.title}</Title>
                                    <Paragraph type="secondary">
                                        {section.description}
                                    </Paragraph>
                                    {section.redoButton && (
                                        <Link onClick={onClick(index)} to="#">
                                            {`Redo ${section.title}`}
                                        </Link>
                                    )}
                                </Column>
                                <Column size="3">
                                    {
                                        risks[index] && (
                                            <div className="result-radio">
                                                <span className="result-radio__label">
                                                    {risks[index]?.label}
                                                </span>
                                                {
                                                    risks[index].score && (
                                                        <RadioGroupReview
                                                            selected={risks[index].score}
                                                        />
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </Column>
                            </div>
                        </div>
                    ))
                }
                {infoBox && <Infobox accent>{infoBox}</Infobox>}
                {error && <WidgetError accent error message={error.message} />}
            </div>
        </Preloader>
    );
};

Review.propTypes = {
    risks: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        score: PropTypes.number,
    })),
    data: PropTypes.shape({
        sections: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            redoButton: PropTypes.bool,
        })),
    }),
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    changeStep: PropTypes.func.isRequired,
};

Review.defaultProps = {
    isLoading: false,
    error: null,
    risks: [],
    data: {
        sections: [],
    },
};

export default Review;
