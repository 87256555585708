/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React, { useMemo } from 'react';
import { Icon, Tooltip } from 'ui-library';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    formatSignPercent, formatValueByType, getColorClass, TYPE_TEXT, VALUE_TYPES,
} from 'utils/formatting';
import { useFormatting } from 'locale';
import { getClassNames } from '../../utils';
import { OVERVIEW_SIZE_LAGRE, OVERVIEW_SIZE_SMALL, OVERVIEW_SIZES } from './constants';

import './Overview.css';

function OverviewItem({
    title,
    value,
    size,
    type,
    colored,
    percent,
    link,
    linkLabel,
    itemLink,
    currency,
    percentTooltip,
    icon,
    iconColored,
    additionalValue,
    currencySize,
    RenderValue,
    className: classNameProp,
}) {
    const { getFormattedNumber } = useFormatting();

    const overviewClassName = getClassNames('overview-item', classNameProp);
    const className = getClassNames(
        getColorClass(colored ? value : 0, 'overview-item__value'),
        `overview-item__value_${size}`,
    );

    const currencyClassName = getClassNames(
        getColorClass(colored ? value : 0, 'overview-item__currency'),
        `overview-item__currency_${currencySize}`,
    );

    let percentComponent = null;

    if (percent !== null) {
        percentComponent = (
            <span className={getColorClass(percent, 'overview-item__percent')}>
                {formatSignPercent(percent)}
            </span>
        );
        if (percentTooltip !== null) {
            percentComponent = (
                <Tooltip title={percentTooltip}>
                    {percentComponent}
                </Tooltip>
            );
        }
    }
    let iconComponent = null;

    if (icon !== null) {
        iconComponent = (
            <span className={getColorClass(iconColored ? value : 0, 'overview-item__icon')}>
                <Icon type={icon} size={16} />
            </span>
        );
    }

    const Tag = itemLink ? Link : 'div';
    const LinkComponent = useMemo(() => {
        if (!link) return null;

        return typeof link === 'function' ? (
            <span role="link" className="overview-item__link" onClick={link}>
                {linkLabel}
            </span>
        ) : (
            <Link className="overview-item__link" to={link}>
                {linkLabel}
            </Link>
        );
    }, [link, linkLabel]);

    const getFormattedValue = (color) => {
        if (value === undefined || value === null || value === 'N/A') {
            return 'N/A';
        }
        if (color) {
            return formatValueByType(value, type, colored, getFormattedNumber);
        }

        return formatValueByType(value, type, false, getFormattedNumber);
    };

    return (
        <Tag to={itemLink} className={overviewClassName}>
            {iconComponent}
            <div className={getColorClass(iconColored ? value : 0, 'overview-item__text')}>
                {title && <span className="overview-item__title">{title}</span>}
                <div>
                    {RenderValue ? <RenderValue /> : (
                        <>
                            <span
                                className={className}
                                title={getFormattedValue()}
                            >
                                {getFormattedValue(colored)}
                            </span>
                            {type !== 'percent' && currency !== null && (
                                <span className={currencyClassName}>
                                    {currency}
                                </span>
                            )}
                            {percentComponent}
                        </>
                    )}
                </div>
                {additionalValue && (<div className="additional-value">{additionalValue}</div>)}
                {LinkComponent}
            </div>
        </Tag>
    );
}

OverviewItem.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOf(OVERVIEW_SIZES),
    currencySize: PropTypes.oneOf(OVERVIEW_SIZES),
    type: PropTypes.oneOf(VALUE_TYPES),
    colored: PropTypes.bool,
    percent: PropTypes.number,
    percentTooltip: PropTypes.string,
    link: PropTypes.string,
    linkLabel: PropTypes.string,
    itemLink: PropTypes.string,
    currency: PropTypes.string,
    icon: PropTypes.string,
    iconColored: PropTypes.bool,
    additionalValue: PropTypes.string,
    RenderValue: PropTypes.node,
    className: PropTypes.string,
};

OverviewItem.defaultProps = {
    size: OVERVIEW_SIZE_LAGRE,
    currencySize: OVERVIEW_SIZE_SMALL,
    type: TYPE_TEXT,
    value: null,
    colored: false,
    percent: null,
    percentTooltip: null,
    link: null,
    linkLabel: null,
    itemLink: null,
    currency: null,
    icon: null,
    iconColored: false,
    additionalValue: null,
    RenderValue: undefined,
    className: null,
};

export default OverviewItem;
