import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    BreadCrumbs,
    Column,
    ContentBox,
    Row,
    Select,
    TextInput,
    Textarea,
    Title,
} from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import { Controller } from 'react-hook-form';
import { useNewMessage } from './hooks/useNewMessage';
import './NewMessage.css';

function NewMessage(props) {
    useCancelAllRequests();

    const { t } = useTranslation();
    const { match: { params: { dfsClientId } } } = props;

    const {
        isLoading,
        values,
        onChange,
        onCancel,
        formErrors,
        control,
        handleSubmit,
        isSending,
        messageSubTypesOptions,
        sendMessage,
    } = useNewMessage({ dfsClientId });

    return (
        <PageSection className="new-message">
            <ContentBox>
                <BreadCrumbs current={t('messages.newMessage')}>
                    <Link to={`/client/${dfsClientId}/messages`}>{t('messages.title')}</Link>
                </BreadCrumbs>
                <Title type={1} className="mb-0">
                    {t('messages.newMessage')}
                </Title>
            </ContentBox>
            <ContentBox>
                <Preloader isLoading={isLoading} error={null}>
                    <Row className="mb-0">
                        <Column size={['sm-6']}>
                            <Select
                                control={control}
                                name="contactReason"
                                value={values.contactReason}
                                onChange={onChange('contactReason')}
                                options={messageSubTypesOptions}
                                label={t('messages.category')}
                                withInfobox={false}
                                error={formErrors.contactReason}
                                required
                            />
                            <br />
                            <Controller
                                as={<TextInput />}
                                name="subject"
                                control={control}
                                value={values.subject}
                                onChange={onChange('subject')}
                                label={t('messages.subject')}
                                error={formErrors.subject}
                                required
                            />
                            <br />
                            <Controller
                                as={<Textarea />}
                                name="message"
                                control={control}
                                label={t('messages.message')}
                                defaultValue={values.message}
                                error={formErrors.message}
                                onChange={onChange('message')}
                                required
                            />
                        </Column>
                    </Row>
                    <ButtonsBlockRow
                        additionalButton={{
                            text: t('messages.cancel'),
                            onClick: onCancel,
                        }}
                        primaryButton={{
                            text: t('messages.send'),
                            loading: isSending,
                            onClick: handleSubmit(sendMessage),
                        }}
                    />
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

NewMessage.propTypes = {
    match: PropTypes.shape({
        params: {
            dfsClientId: PropTypes.string,
        },
    }),
};

NewMessage.defaultProps = {
    match: { params: {} },
};

export default NewMessage;
