import { checkNullData } from 'utils';

export const BUY = 'Buy';
export const SELL = 'Sell';
export const MARKET = 'Market';
export const LIMIT = 'Limit';
export const STOP = 'Stop';

export const GOOD_TILL_DATE = 'GoodTillDate';

export const mapOrderTypeName = (orderId) => {
    const orderTypeMap = {
        1: BUY,
        2: SELL,
    };

    return orderTypeMap[orderId] || undefined;
};

export const orderTypeOptions = (t) => ([
    { label: t('position.marketOrder'), value: MARKET },
    { label: t('position.limitOrder'), value: LIMIT },
    { label: t('position.stopLimitOrder'), value: STOP },
]);

export const orderValues = (t, quantity, defaultData) => {
    const values = [
        {
            label: t('position.buy'), value: BUY, disabled: false, error: false,
        },
        {
            label: t('position.sell'), value: SELL, disabled: true, error: false,
        },
    ];

    if (!checkNullData(defaultData)) {
        values[0].disabled = defaultData.order !== BUY;
        values[1].disabled = defaultData.order !== SELL;

        return values;
    }

    if (quantity > 0) {
        values[1].disabled = false;
    }

    return values;
};

export const validityOptions = (t) => ([
    { label: t('position.validityOptions.goodTillDate'), value: GOOD_TILL_DATE },
    { label: t('position.validityOptions.dayOrder'), value: 'DayOrder' },
    { label: t('position.validityOptions.goodTillCancelled'), value: 'GoodTillCancelled' },
    { label: t('position.validityOptions.immediateOrCancel'), value: 'ImmediateOrCancel' },
    { label: t('position.validityOptions.fillOrKill'), value: 'FillOrKill' },
    { label: t('position.validityOptions.onTheOpen'), value: 'OnTheOpen' },
    { label: t('position.validityOptions.onTheClose'), value: 'OnTheClose' },
]);
