import PropTypes from 'prop-types';
import { isToday, isYesterday, isTomorrow } from 'utils';
import { formatTime } from 'utils/datetime';


const CellAdaptedDate = (t, getFormattedDate = null, getFormattedTime = null) => (date) => {
    if (isToday(date)) return getFormattedTime ? getFormattedTime(date) : formatTime(date);

    if (isTomorrow(date)) return t('messages.tomorrow');
    if (isYesterday(date)) return t('messages.yesterday');

    return getFormattedDate ? getFormattedDate(new Date(date)) : '';
};

CellAdaptedDate.propTypes = {
    isReplied: PropTypes.bool,
    date: PropTypes.string,
};

CellAdaptedDate.defaultProps = {
    isReplied: true,
    date: null,
};

export default CellAdaptedDate;
