import React from 'react';
import PropTypes from 'prop-types';
import Preloader from 'components/Preloader';
import { usePortfolio } from 'domain/Portfolio';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject,
} from 'constants/portfolioProducts';
import QuickEditAdvisory from './products/QuickEditAdvisory';
import QuickEditDiscretionary from './products/QuickEditDiscretionary';
import QuickEditExecutionOnly from './products/QuickEditExecutionOnly';
import './QuickEdit.css';

const QuickEditPortfolioByProduct = generateObject({
    [ADVISORY]: QuickEditAdvisory,
    [DISCRETIONARY]: QuickEditDiscretionary,
    [EXECUTION_ONLY]: QuickEditExecutionOnly,
});

function QuickEditPortfolio(props) {
    const { portfolio: { productId, error } } = usePortfolio();
    const QuickEditPortfolioPage = QuickEditPortfolioByProduct[productId] || (() => '');

    return (
        <Preloader isLoading={productId === undefined} error={error}>
            <QuickEditPortfolioPage {...props} />
        </Preloader>
    );
}

QuickEditPortfolio.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

QuickEditPortfolio.defaultProps = {};

export default QuickEditPortfolio;
