import React from 'react';
import {
    Button, Column, ContentBox, Icon, Title, Paragraph,
} from 'ui-library';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './UnderConstruction.css';

export const UnderConstruction = ({ match: { params: { dfsClientId } } }) => {
    const backUrl = dfsClientId !== undefined ? `/client/${dfsClientId}/dashboard` : '/dashboard';
    const backText = dfsClientId !== undefined ? 'Back to client overview' : 'Back to Dashboard';
    const { t } = useTranslation();

    return (
        <div className="PageUnderConstruction wrapper">
            <Column size="4">
                <ContentBox underline={false}>
                    <Icon type="error" size={32} color="#4C8476" />
                    <Title type={2}>{t('routerErrors.constructionPage.title')}</Title>
                    <Paragraph type="secondary">
                        {t('routerErrors.constructionPage.warningText')}
                    </Paragraph>
                    <Button
                        type="secondary"
                        onClick={() => history.push(backUrl)}
                        block
                    >
                        {backText}
                    </Button>
                </ContentBox>
            </Column>
        </div>
    );
};

UnderConstruction.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string,
        }),
    }).isRequired,
};

export default UnderConstruction;
