import React from 'react';
import PropTypes from 'prop-types';
import Preloader from 'components/Preloader';
import { usePortfolio } from 'domain/Portfolio';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject,
} from 'constants/portfolioProducts';
import ModifyPortfolioAdvisory from './products/ModifyPortfolioAdvisory';
import ModifyPortfolioDiscretionary from './products/ModifyPortfolioDiscretionary';
import ModifyPortfolioExecutionOnly from './products/ModifyPortfolioExecutionOnly';
import './ModifyPortfolio.css';

const ModifyPortfolioByProduct = generateObject({
    [ADVISORY]: ModifyPortfolioAdvisory,
    [DISCRETIONARY]: ModifyPortfolioDiscretionary,
    [EXECUTION_ONLY]: ModifyPortfolioExecutionOnly,
});

function ModifyPortfolio(props) {
    const { portfolio: { productId, error } } = usePortfolio();
    const ModifyPortfolioPage = ModifyPortfolioByProduct[productId] || (() => '');

    return (
        <Preloader isLoading={productId === undefined} error={error}>
            <ModifyPortfolioPage {...props} />
        </Preloader>
    );
}

ModifyPortfolio.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

ModifyPortfolio.defaultProps = {};

export default ModifyPortfolio;
