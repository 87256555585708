import { checkNullData } from 'utils';
import { formatDate, getDatesDifference } from 'utils/datetime';
import { urgencyLabels } from '../constants/constants';

const getColor = ({ Urgency }) => {
    if (Urgency === 3) return 'red';
    if (Urgency === 2) return 'yellow';

    return 'green';
};

export const adaptUrgencyFilters = (data, t) => (data || [])
    .sort((a, b) => b.Id - a.Id)
    .map((item) => t(urgencyLabels[item?.Name]));

export const adaptTasks = (data, severityLevels, quantity, t) => {
    if (checkNullData(data)) return [];

    const getSeverityLevel = ({ Urgency }) => {
        const severityLevel = severityLevels.find(({ Id }) => Id === Urgency);

        return severityLevel?.Name || undefined;
    };
    const now = new Date();
    let tasks = data
        .filter(({ CategoryId }) => CategoryId === 1)
        .filter(({ DueDate }) => getDatesDifference(DueDate, now) >= 0)
        .sort((a, b) => new Date(a.DueDate) - new Date(b.DueDate));

    if (quantity) tasks = tasks.slice(0, quantity);

    return tasks.map((item) => {
        const urgencyType = getSeverityLevel(item);

        return ({
            id: item.Id,
            Subject: {
                title: undefined,
                subtitle: item.Name.split('||')[0],
            },
            type: item.Name.split('||')[1] || 'General',
            date: formatDate(item.DueDate),
            urgency: {
                type: urgencyType,
                text: t(urgencyLabels[urgencyType]),
                color: getColor(item),
            },
            contactId: item.ContactId,
        });
    });
};

export const adaptWithContacts = (data, { list: contacts }) => data.map((item) => {
    const contact = contacts.find(({ Id }) => Id === item.contactId);

    return ({
        ...item,
        Subject: {
            ...item.Subject,
            title: contact ? `${contact.FirstName} ${contact.LastName}` : undefined,
        },
    });
}).filter((item) => item.Subject.title);

export const adaptWithContactsForWidget = (data, { list: contacts }) => data.map((item) => {
    const contact = contacts.find(({ id }) => id === item.contactId);

    return ({
        ...item,
        Subject: {
            ...item.Subject,
            title: contact ? contact?.personalInformation?.displayName : undefined,
        },
    });
}).filter((item) => item.Subject.title);
