import React from 'react';
import {
    Button,
} from 'ui-library';
import { getClassNames } from 'utils';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const LeftMenu = ({
    active, onMenuItemClick,
}) => {
    const { t } = useTranslation();

    return (
        <div className="left-menu">
            <div className="parent-link active">{t('editProfile.information')}</div>
            <Button
                type="link"
                onClick={() => { onMenuItemClick('personal-information'); }}
                className={getClassNames('left-menu-link', { active: active === 'personal-information' })}
            >
                {t('editProfile.personalInformation')}
            </Button>
            <Button
                type="link"
                onClick={() => { onMenuItemClick('business-information'); }}
                className={getClassNames('left-menu-link', { active: active === 'business-information' })}
            >
                {t('editProfile.businessInformation')}
            </Button>
            <Button
                type="link"
                onClick={() => { onMenuItemClick('system-information'); }}
                className={getClassNames('left-menu-link', { active: active === 'system-information' })}
            >
                {t('editProfile.systemInformation')}
            </Button>
        </div>
    );
};

LeftMenu.propTypes = {
    active: PropTypes.string.isRequired,
    onMenuItemClick: PropTypes.func.isRequired,
};
