import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Title, ContentBox, Table, SkeletonLine,
} from 'ui-library';
import Draggable from 'components/Draggable/Draggable';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import history from 'services/history';
import { genarateDFSClientId, getClassNames } from 'utils';
import { tasks, tasksSorting } from 'constants/constants';
import WidgetResize from 'components/WidgetResize';
import { useDashboardConfig } from 'pages/AdvisoryDashboard/hooks/useDashboardConfig';
import { useFormatting } from 'locale';

import './Tasks.css';

export const TasksSkeleton = () => (
    <div className="TasksSkeleton">
        <div>
            <SkeletonLine width={180} />
            <SkeletonLine width={280} />
        </div>
        <div>
            <SkeletonLine width={100} />
        </div>
        <div>
            <SkeletonLine width={60} />
        </div>
        <div>
            <SkeletonLine width={60} />
        </div>
    </div>
);

function Tasks({
    data: dataSource, isLoading, error, drop, mini, fullPage,
    isResizable, urgencyFilters, disableLinks, noDataText,
}) {
    const { getFormattedDate } = useFormatting();
    const { t, i18n } = useTranslation();
    const { widgetSize, saveWidgetSize } = useDashboardConfig('Tasks');
    const [total, setTotal] = useState(data?.length ?? 0);
    const [isLimited, setLimit] = useState(false);
    const className = getClassNames('tasks', { 'tasks-mini': mini });

    const onChange = useCallback((_, __, ___, { updatedTotal }) => {
        setTotal(updatedTotal);
    }, [setTotal]);

    const data = useMemo(() => {
        if (!mini) return dataSource;

        return dataSource.map((item) => ({
            ...item,
            Subject: { ...item.Subject, maxLength: 15 },
        }));
    }, [dataSource, mini]);

    const titleComponent = useMemo(() => {
        const titleLink = !disableLinks ? '/tasks' : null;
        const titleText = !fullPage
            ? <Title type={2} link={titleLink}>{t('advisoryDashboard.tasks.title')}</Title>
            : <Title type={2} className="underlined">{t('advisoryDashboard.tasks.title')}</Title>;

        if (drop) return (<Draggable {...drop}>{titleText}</Draggable>);

        return titleText;
    }, [drop, fullPage, i18n.language, disableLinks]);

    const onRowClick = ({ contactId, Subject: { title } }) => ({
        onClick: () => {
            const { location: { pathname } } = history;

            if (contactId) history.push(`/client/${genarateDFSClientId(contactId)}`, { fromOrigin: pathname, clientName: title });
        },
    });

    const tasksData = useMemo(() => (isResizable ? data.slice(0, widgetSize) : data),
        [data, widgetSize, isResizable]);

    return (
        <PageSection className={className}>
            <ContentBox className={`resize-widget table-without-last-row-padding ${isLimited ? 'limited-size' : ''}`}>
                {titleComponent}
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data} text={t(noDataText)}>

                        <WidgetResize
                            onLimited={setLimit}
                            widgetSize={data.length < widgetSize ? data.length : widgetSize}
                            setWidgetSize={saveWidgetSize}
                            elementHeight={71}
                            minHeight={45}
                            dataLength={data.length}
                            SkeletonComponent={TasksSkeleton}
                            isResizable={isResizable}
                        >
                            <Table
                                data={tasksData}
                                columns={tasks(t, getFormattedDate, fullPage, urgencyFilters, mini)}
                                sorting={fullPage ? null : tasksSorting}
                                onRow={!disableLinks && onRowClick}
                                mini={!fullPage}
                                onChange={onChange}
                                headerControls={{
                                    sortByLabel: t('headerControls.sortBy'),
                                }}
                                paging={{
                                    total,
                                    pageSize: 9,
                                    currentPage: 1,
                                }}
                            />
                        </WidgetResize>
                    </EmptyContent>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

Tasks.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.shape({
                title: PropTypes.string,
                subtitle: PropTypes.string,
            }),
            type: PropTypes.string,
            date: PropTypes.string,
            urgency: PropTypes.shape({
                text: PropTypes.string,
                color: PropTypes.string,
            }),
        }),
    ),
    urgencyFilters: PropTypes.arrayOf(PropTypes.string),
    isLoading: PropTypes.bool,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    fullPage: PropTypes.bool,
    mini: PropTypes.bool,
    isResizable: PropTypes.bool,
    drop: PropTypes.shape({
        role: PropTypes.string,
        tabIndex: PropTypes.number,
        onDragStart: PropTypes.func,
    }),
    disableLinks: PropTypes.bool,
    noDataText: PropTypes.string,
};

Tasks.defaultProps = {
    data: [],
    urgencyFilters: [],
    isLoading: true,
    error: null,
    fullPage: false,
    mini: false,
    isResizable: false,
    drop: null,
    disableLinks: false,
    noDataText: 'advisoryDashboard.noData',
};

export default React.memo(Tasks);
