import { get } from 'lodash/fp';
import accountsService from 'additiv-services/dist/services/core/accounts';
import contactManagement from 'additiv-services/dist/services/core/contactManagement';
import instrumentsService from 'additiv-services/dist/services/core/instruments';
import PortfolioManagement from 'additiv-services/dist/services/core/portfolioManagement';
import performanceService from 'additiv-services/dist/services/core/performance';
import Products from 'additiv-services/dist/services/core/products';
import Security, { security } from 'additiv-services/dist/services/core/security';
import commonService from 'additiv-services/dist/services/core/common';
import commonContent from 'additiv-services/dist/services/core/commonContent';
import currenciesService from 'additiv-services/dist/services/core/currencies';
import tradingService from 'additiv-services/dist/services/core/trading';
import complianceService from 'additiv-services/dist/services/core/compliance';
import dataService from 'additiv-services/dist/services/core/data';
import relationshipManagement from 'additiv-services/dist/services/core/relationshipManagement';
import deviceEnrollment from 'additiv-services/dist/services/core/deviceEnrollment';
import messageService from 'additiv-services/dist/services/core/messages';
import goalsService from 'additiv-services/dist/services/core/goals';
import optimizationService from 'additiv-services/dist/services/core/optimization';
import reports from 'additiv-services/dist/services/core/reports';
import contactInteraction from 'additiv-services/dist/services/core/contactInteraction';
import bank from 'additiv-services/dist/services/core/bank';
import rss from 'additiv-services/dist/services/core/rss';
import documents from 'additiv-services/dist/services/core/documents';
import memberManagement from 'additiv-services/dist/services/core/memberManagement';
import clientService from 'additiv-services/dist/services/core/clientService';
import Service from 'additiv-services/dist/services/core/service';
import engagementSchema from 'additiv-services/dist/services/core/engagementSchema';
import engagementContent from 'additiv-services/dist/services/core/engagementContent';
import fIdentity from 'additiv-services/dist/services/core/fIdentity';
import fundingManagement from 'additiv-services/dist/services/core/fundingManagement';
import middleware from 'additiv-services/dist/services/core/middleware';
import { checkStatus } from './generalStatusCheck';

const serviceCall = (service, method, argsArray = []) => {
    const result = service[method](...argsArray);
    const promise = result instanceof Promise ? result : result[0];

    promise
        .then(() => {})
        .catch((reason) => {
            checkStatus(get('response.status', reason));
        });

    return result;
};

class ServiceManager {
    static Service(method, argsArray) {
        return serviceCall(Service, method, argsArray);
    }

    static tradingService(method, argsArray) {
        return serviceCall(tradingService, method, argsArray);
    }

    static compliance(method, argsArray) {
        return serviceCall(complianceService, method, argsArray);
    }

    static contactManagement(method, argsArray) {
        return serviceCall(contactManagement, method, argsArray);
    }

    static dataService(method, argsArray) {
        return serviceCall(dataService, method, argsArray);
    }

    static goalsService(method, argsArray) {
        return serviceCall(goalsService, method, argsArray);
    }

    static optimizationService(method, argsArray) {
        return serviceCall(optimizationService, method, argsArray);
    }

    static performanceService(method, argsArray) {
        return serviceCall(performanceService, method, argsArray);
    }

    static relationshipManagement(method, argsArray) {
        return serviceCall(relationshipManagement, method, argsArray);
    }

    static instrumentsService(method, argsArray) {
        return serviceCall(instrumentsService, method, argsArray);
    }

    static portfolioManagement(method, argsArray) {
        return serviceCall(PortfolioManagement, method, argsArray);
    }

    static products(method, argsArray) {
        return serviceCall(Products, method, argsArray);
    }

    static security(method, argsArray) {
        return serviceCall(security, method, argsArray);
    }

    static Security(method, argsArray) {
        return serviceCall(Security, method, argsArray);
    }

    static commonService(method, argsArray) {
        return serviceCall(commonService, method, argsArray);
    }

    static currenciesService(method, argsArray) {
        return serviceCall(currenciesService, method, argsArray);
    }

    static commonContent(method, argsArray) {
        return serviceCall(commonContent, method, argsArray);
    }

    static messageService(method, argsArray) {
        return serviceCall(messageService, method, argsArray);
    }

    static deviceEnrollment(method, argsArray) {
        return serviceCall(deviceEnrollment, method, argsArray);
    }

    static accountsService(method, argsArray) {
        return serviceCall(accountsService, method, argsArray);
    }

    static reportsService(method, argsArray) {
        return serviceCall(reports, method, argsArray);
    }

    static contactInteraction(method, argsArray) {
        return serviceCall(contactInteraction, method, argsArray);
    }

    static bank(method, argsArray) {
        return serviceCall(bank, method, argsArray);
    }

    static memberManagement(method, argsArray) {
        return serviceCall(memberManagement, method, argsArray);
    }

    static rssService(method, argsArray) {
        return serviceCall(rss, method, argsArray);
    }

    static documents(method, argsArray) {
        return serviceCall(documents, method, argsArray);
    }

    static clientService(method, argsArray) {
        return serviceCall(clientService, method, argsArray);
    }

    static engagementSchema(method, argsArray) {
        return serviceCall(engagementSchema, method, argsArray);
    }

    static engagementContent(method, argsArray) {
        return serviceCall(engagementContent, method, argsArray);
    }

    static fIdentity(method, argsArray) {
        return serviceCall(fIdentity, method, argsArray, false);
    }

    static middleware(method, argsArray) {
        return serviceCall(middleware, method, argsArray);
    }

    static fundingManagement(method, argsArray) {
        return serviceCall(fundingManagement, method, argsArray);
    }
}

export default ServiceManager;
