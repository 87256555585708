import {
    CellPercent, CellPerformanceWithValue, CellNextUp, CellWithSubtextAndTooltip,
} from 'components/renderers';
import {
    sorterString, sorterNumber, SORT_DIRECTION_ASC, SORT_DIRECTION_DESC,
} from 'utils/sorting';

export const marketsColumns = (t, getFormattedNumber) => [
    {
        key: 'Index',
        title: t('advisoryDashboard.marketColumns.index'),
        sortable: true,
        sorter: sorterString('Index'),
        defaultSortOrder: 'descend',
        width: '30%',
    },
    {
        key: 'Ticker',
        title: t('advisoryDashboard.marketColumns.ticker'),
        sorter: sorterString('Ticker'),
    },
    {
        key: 'Last',
        title: t('advisoryDashboard.marketColumns.last'),
        sortable: true,
        sorter: sorterNumber('Last'),
        className: 'text-right',
        width: '15%',
    },
    {
        key: 'Change',
        title: t('advisoryDashboard.marketColumns.change'),
        sortable: true,
        sorter: sorterNumber('Change'),
        className: 'text-right',
        width: '20%',
        render: CellPercent(getFormattedNumber),
    },
    {
        key: 'ChangeYTD',
        title: t('advisoryDashboard.marketColumns.YTDChange'),
        sortable: true,
        sorter: sorterNumber('ChangeYTD'),
        className: 'text-right',
        width: '20%',
        render: CellPercent(getFormattedNumber),
    },
];

export const marketsColumnsMini = (t, getFormattedNumber) => [
    {
        key: 'Subject',
        title: t('advisoryDashboard.marketColumns.ticker'),
        sortable: true,
        sorter: sorterString('Subject.title'),
        render: CellWithSubtextAndTooltip,
        width: '70%',
        className: 'table-cell__subject',
    },
    {
        key: 'Performance',
        title: t('advisoryDashboard.marketColumns.change'),
        sortable: true,
        sorter: sorterNumber('Performance.performance'),
        render: CellPerformanceWithValue(getFormattedNumber),
        className: 'text-right table-cell__subject',
    },
];

export const marketsSorting = { key: 'Subject', direction: SORT_DIRECTION_ASC };

export const currenciesColumns = (t, getFormattedNumber) => [
    {
        key: 'BaseCurrency',
        title: t('advisoryDashboard.currencyColumns.baseCurrency'),
        sortable: true,
        sorter: sorterString('BaseCurrency'),
        width: '23%',
        defaultSortOrder: 'descend',
    },
    {
        key: 'QuoteCurrency',
        title: t('advisoryDashboard.currencyColumns.quoteCurrency'),
        sortable: true,
        sorter: sorterString('QuoteCurrency'),
        width: '23%',
        defaultSortOrder: 'ascend',
    },
    {
        key: 'Rate',
        title: t('advisoryDashboard.currencyColumns.exchangeRate'),
        sortable: true,
        sorter: sorterNumber('Rate'),
        className: 'text-right',
        width: '20%',
    },
    {
        key: 'Change',
        title: t('advisoryDashboard.currencyColumns.change'),
        width: '17%',
        sortable: true,
        sorter: sorterNumber('Change'),
        className: 'table-cell_highlighted text-right',
        render: CellPercent(getFormattedNumber),
    },
    {
        key: 'ChangeYTD',
        title: t('advisoryDashboard.currencyColumns.changeYTD'),
        width: '17%',
        sortable: true,
        sorter: sorterNumber('ChangeYTD'),
        className: 'table-cell_highlighted text-right',
        render: CellPercent(getFormattedNumber),
    },
];

export const currenciesColumnsMini = (t, getFormattedNumber) => [
    {
        key: 'BaseQuoteCurrency',
        title: t('advisoryDashboard.currencyColumns.baseQuoteCurrency'),
        sortable: true,
        sorter: sorterString('BaseQuoteCurrency'),
        width: '70%',
        defaultSortOrder: 'descend',
    },
    {
        key: 'Performance',
        title: t('advisoryDashboard.currencyColumns.performanceSI'),
        sortable: true,
        sorter: sorterNumber('Performance.performance'),
        render: CellPerformanceWithValue(getFormattedNumber),
        className: 'text-right table-cell__subject',
    },
];

export const currenciesSorting = { key: 'Performance', direction: SORT_DIRECTION_DESC };

export const nextUp = [
    {
        key: 'ticket',
        title: '',
        render: CellNextUp,
    },
];

export const urgencyLabels = {
    Low: 'tasks.lowUrgency',
    Medium: 'tasks.mediumUrgency',
    High: 'tasks.highUrgency',
};
