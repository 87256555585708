import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'ui-library';

import './CellValueWithCheckbox.css';

const CellValueWithCheckbox = ({ value, selected, onChecked }) => (
    <div className="value_checkbox">
        <Checkbox label={value.toString()} checked={selected} onChange={onChecked} />
    </div>
);

CellValueWithCheckbox.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    selected: PropTypes.bool,
    onChecked: PropTypes.func,
};

CellValueWithCheckbox.defaultProps = {
    value: null,
    selected: false,
    onChecked: () => {},
};

export default CellValueWithCheckbox;
