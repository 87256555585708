import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptSecurityTransactions } from '../adapters/adaptSecurityTransactions';

export const useSecurityTransactions = (
    currencyId,
    investmentAllocations,
    amount,
    clientId,
) => {
    const [errorSescurityTransactions, setError] = useState(null);
    const [isLoadingSecurityTransactions, setLoading] = useState(true);
    const [securityTransactionsData, setData] = useState({});
    const { i18n, t } = useTranslation();

    useEffect(() => {
        setLoading(true);

        if (investmentAllocations === undefined || amount === undefined) {
            return;
        }

        const dataSimulate = {
            CurrencyId: currencyId,
            Amount: amount,
            TargetInstrumentAllocations: investmentAllocations,
        };

        ServiceManager.portfolioManagement('postPortfolioOrderbookSimulate', [clientId, { language: i18n.language }, dataSimulate])
            .then((response) => {
                try {
                    setData(adaptSecurityTransactions(response.data, t));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, [currencyId, investmentAllocations, amount, i18n.language]);

    return { securityTransactionsData, isLoadingSecurityTransactions, errorSescurityTransactions };
};
