import React, {
    useEffect, useMemo, useState, useCallback,
} from 'react';
import { useLayout } from 'prodivers/layout';
import { useTranslation } from 'react-i18next';
import { Logo } from 'components/Logo';
import history from 'services/history';
import { useUnreadMessages } from 'prodivers/unreadMessages';
import ThemeToggle from 'components/ThemeToggle';
import SideNavigation from 'components/SideNavigation';
import QuickAccessMenu from 'components/QuickAccessMenu';
import { useSecure } from 'additiv-react-oidc-client';
import { useDetectedHandheldDevice } from './useDetectedHandheldDevice';
import { useManagerProfile } from '../prodivers/managerProfile';
import { useNotifications } from './useNotifications';

export const useMainLayout = ({ pathname }) => {
    const { logout } = useSecure();
    const { initHeaderState } = useLayout();
    const { t } = useTranslation();
    const unreadMessages = useUnreadMessages();
    const { isHandheldSize } = useDetectedHandheldDevice();
    const { data: { name, email, image } } = useManagerProfile();
    const { onReadNotification, allNotifications, errorNotifications } = useNotifications();

    const logoutAction = useCallback(() => logout(), [logout]);

    const [isOpen, setOpen] = useState(false);
    const [isOpenProfile, setOpenProfile] = useState(false);
    const toggleNavigation = useCallback(() => setOpen((v) => !v), []);

    const current = useMemo(() => pathname.split('/')[1], [pathname]);
    const currentSub = useMemo(() => pathname.split('/')[2], [pathname]);

    const navigation = useMemo(() => {
        const navigationHeader = [
            { title: t('navigation.sideNav.dashboard'), link: '/dashboard', active: current === 'dashboard' },
            { title: t('navigation.sideNav.clients'), link: '/clients', active: current === 'clients' },
            {
                title: t('navigation.sideNav.messages'),
                link: '/messages',
                active: current === 'messages',
                hasChanges: unreadMessages,
            },
            { title: t('navigation.sideNav.tasks'), link: '/tasks', active: current === 'tasks' },
            {
                title: t('navigation.sideNav.news'),
                active: current === 'news',
                submenu: [
                    {
                        title: t('navigation.sideNav.announcements'),
                        link: '/news/announcements',
                        active: currentSub === 'announcements',
                    },
                    {
                        title: t('navigation.sideNav.research'),
                        link: '/news/research',
                        active: currentSub === 'research',
                    },
                    {
                        title: t('navigation.sideNav.externalNews'),
                        link: '/news/external',
                        active: currentSub === 'external',
                    },
                ],
            },
        ];

        if ((global.AppConfig.REACT_APP_SURFLY_URL?.length ?? 0) !== 0) {
            navigationHeader.push({
                title: t('navigation.sideNav.support'),
                link: '/support',
                active: current === 'support',
            });
        }

        return navigationHeader;
    }, [t, pathname, unreadMessages]);
    const navigationTablet = useMemo(() => (
        isHandheldSize && (
            <SideNavigation
                isOpen={isOpen}
                current={pathname}
                onClose={() => setOpen(false)}
            />
        )
    ), [isHandheldSize, isOpen, pathname]);
    const profileInfo = useMemo(() => ({
        name,
        image,
        email,
        withAlert: true,
        withEdit: true,
        editText: t('advisoryDashboard.profileMenu.editProfile'),
        onEdit: () => {
            setOpenProfile(false);
            history.push('/edit-profile');
        },
        onLogout: logoutAction,
        logoutText: t('advisoryDashboard.profileMenu.logout'),
        notifications: {
            data: allNotifications,
            onReadNotification,
            emptyMessage: t('advisoryDashboard.profileMenu.notifications.empty'),
            errorMessage: errorNotifications,
        },
        visible: isOpenProfile,
        onVisibleChange: setOpenProfile,
        footer: <ThemeToggle />,
    }), [
        allNotifications,
        email,
        errorNotifications,
        image,
        logoutAction,
        name,
        onReadNotification,
        t,
        isOpenProfile,
        setOpenProfile,
    ]);

    useEffect(() => {
        initHeaderState({
            content: Logo,
            navigation,
            profileInfo,
            navigationTablet,
            quickAccessMenuComponent: QuickAccessMenu,
            onLeftButtonClick: toggleNavigation,
            onRightButtonClick: logoutAction,
        });
    }, [
        logoutAction,
        initHeaderState,
        navigation,
        navigationTablet,
        toggleNavigation,
        profileInfo,
    ]);
};
