import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Input from 'react-phone-number-input/input';
import './PhoneNumber.css';
import { TextInput, Label as LabelUI } from 'ui-library';
import { getClassNames } from 'utils';
import { CountrySelect } from './CountrySelect';

export const PhoneNumber = (props) => {
    const {
        label,
        phoneName,
        phoneNumberValue,
        onChangePhoneNumber,
        phoneNumberError,
        countryCodeName,
        countryCodeValue,
        onChangeCountryCode,
        defaultCountry,
        control,
        ref,
        required,
    } = props;

    useEffect(() => {
        onChangePhoneNumber('');
    },
    [countryCodeValue]);

    useEffect(() => {
        onChangeCountryCode(defaultCountry);
    },
    []);

    const classNames = useMemo(() => getClassNames('PhoneInput',
        { 'PhoneInput-disabled': countryCodeValue.length < 1 }), [countryCodeValue]);

    return (
        <div className="PhoneNumber">
            <LabelUI label={label} required={required} />
            <div className="PhoneNumber-container">
                <div className="PhoneNumber-input">
                    <Input
                        name={phoneName}
                        control={control}
                        international
                        country={countryCodeValue}
                        value={phoneNumberValue}
                        onChange={onChangePhoneNumber}
                        className={classNames}
                        inputComponent={TextInput}
                        error={phoneNumberError}
                        ref={ref}
                        required={required}
                        limitMaxLength
                        countryCallingCodeEditable={false}
                        prefix={(
                            <CountrySelect
                                name={countryCodeName}
                                control={control}
                                value={countryCodeValue}
                                onChange={onChangeCountryCode}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

PhoneNumber.propTypes = {
    phoneNumberValue: PropTypes.string,
    onChangePhoneNumber: PropTypes.func,
    label: PropTypes.string,
    countryCodeValue: PropTypes.string,
    onChangeCountryCode: PropTypes.func,
    defaultCountry: PropTypes.string,
    phoneName: PropTypes.string,
    countryCodeName: PropTypes.string,
    phoneNumberError: PropTypes.string,
    control: PropTypes.string,
    ref: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    required: PropTypes.bool,
};


PhoneNumber.defaultProps = {
    phoneNumberValue: '',
    label: '',
    onChangePhoneNumber: () => {},
    defaultCountry: '',
    countryCodeValue: '',
    onChangeCountryCode: () => {},
    phoneName: '',
    countryCodeName: '',
    phoneNumberError: '',
    control: '',
    ref: undefined,
    required: false,
};
