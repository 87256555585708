import React from 'react';
import PropTypes from 'prop-types';

const Metadata = ({
    date, senderFullName, recipients, t,
}) => (
    <>
        <div className="message-metadata-from">
            <p>
                <b>
                    {t('messages.from')}
                    :
                    {' '}
                </b>
                {senderFullName}
            </p>
            <p className="message-date">
                {date}
            </p>
        </div>
        <div>
            <b>
                {t('messages.to')}
                :
                {' '}
            </b>
            {
                recipients.map((item, i, arr) => (
                    `${item.recipientFullName}${i !== arr.length - 1 ? ';' : ''} `
                ))
            }
        </div>
    </>
);

Metadata.propTypes = {
    date: PropTypes.bool,
    recipients: PropTypes.arrayOf(PropTypes.shape({
        recipientFullName: PropTypes.string,
    })),
    senderFullName: PropTypes.string,
    t: PropTypes.func.isRequired,
};

Metadata.defaultProps = {
    date: '',
    senderFullName: '',
    recipients: [],
};

export default Metadata;
