import React from 'react';
import KnowledgeExperienceDetails from './components/KnowledgeExperienceDetails/KnowledgeExperienceDetails';

export const customSchema = ({ t, contactId }) => ({
    components: [{
        title: t('clientDashboard.details.ke'),
        key: 'ke',
        custom: true,
        renderComponent: <KnowledgeExperienceDetails clientId={contactId} />,
    }],
});
