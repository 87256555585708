import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ContentBox, Table, Title } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import { informationColumns } from '../../../constants';
import './Information.css';

function Information({ data, isLoading, error }) {
    const { t } = useTranslation();

    return (
        <PageSection className="information">
            <ContentBox underline={false}>
                <Title type={2}>
                    {t('clientDashboard.information.title')}
                </Title>
                <Preloader isLoading={isLoading} error={error}>
                    <div className="media">
                        <div className="picture Avatar">
                            <span className="avatar-content">
                                {data.Img && <img src={data.Img} alt={data.Name} width="72" height="72" />}
                            </span>
                        </div>
                        <div className="media-body">
                            <Title type={3}>{data.Name}</Title>
                        </div>
                    </div>
                    <Table
                        columns={informationColumns}
                        data={data.Contacts}
                        noHeader
                    />
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

Information.propTypes = {
    data: PropTypes.shape({
        Img: PropTypes.string,
        Name: PropTypes.string,
        Contacts: PropTypes.arrayOf(PropTypes.shape({
            Information: PropTypes.shape({
                title: PropTypes.string,
                subtitle: PropTypes.string,
            }),
            Icon: PropTypes.shape({
                type: PropTypes.string,
                size: PropTypes.number,
                href: PropTypes.string,
            }),
        })),
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
};

Information.defaultProps = {
    error: null,
};

export default Information;
