import React, { useMemo } from 'react';
import uniq from 'lodash/uniq';
import {
    getPortfolioStatus,
    sortStatuses, CLOSED,
    IN_EXECUTION, NOT_IMPLEMENTED,
} from 'constants/portfolioStatuses';
import { usePortfolio } from 'domain/Portfolio';
import { useProposals } from 'hooks/useProposals';
import { adaptProposalsByPortfolioId } from 'adaptors/adaptProposals';
import Preloader from 'components/Preloader';
import PropTypes from 'prop-types';
import PageNotFoundContent from 'components/PageError/PageNotFoundContent';
import { getClientId } from 'utils';
import history from 'services/history';

const withPortfolioAccess = (Component) => {
    const Portfolio = (props) => {
        const { match: { params: { dfsClientId } }, location: { state } } = props;
        const clientId = getClientId(dfsClientId);
        const { portfolio: { data, isLoading, error } } = usePortfolio();

        const { data: proposalsData } = useProposals(clientId);

        const proposalsAdapted = adaptProposalsByPortfolioId(proposalsData);

        const proposalStatuses = sortStatuses(
            uniq((proposalsAdapted[data?.id] || []).map(({ status }) => status)),
        );

        const status = getPortfolioStatus({
            portfolioStatusId: data?.portfolioStatusId,
            isBreached: data?.isBreached,
            proposalStatus: proposalStatuses?.[0],
            isOrderBookBlocked: data?.hasPendingOrders,
        });

        const pageNotFound = useMemo(() => [
            CLOSED].includes(status), [status]);

        if (status === NOT_IMPLEMENTED) {
            history.push(`/client/${dfsClientId}/portfolios/${data.id}/funding-information`, state);
        }

        if (status === IN_EXECUTION) {
            history.push(`/client/${dfsClientId}/portfolios/${data.id}/pending-orders`, state);
        }

        if (data?.portfolioStatusId && pageNotFound) {
            return (
                <Preloader isLoading={isLoading} error={error}>
                    <PageNotFoundContent />
                </Preloader>
            );
        }

        Portfolio.propTypes = {
            match: PropTypes.shape({
                params: PropTypes.shape({
                    dfsClientId: PropTypes.string,
                }),
            }).isRequired,
            location: PropTypes.shape({
                state: {
                    fromReview: PropTypes.bool,
                },
            }).isRequired,
        };

        return <Component status={status} {...props} />;
    };

    return Portfolio;
};

export default withPortfolioAccess;
