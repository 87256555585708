import { renderers } from 'ui-library';
import { CellSwitch, CellWithMoreActions } from 'components/renderers';
import { sorterNumber, sorterString } from 'utils/sorting';

const { CellWithIcon, CellWithCurrentNewValue } = renderers;

export const getPositionsColumns = (t) => ([
    {
        key: 'name',
        title: t('clientDashboard.portfolio.columns.name'),
        sortable: true,
        sorter: sorterString('name'),
        render: CellSwitch,
        width: 250,
        minWidth: 250,
    },
    {
        title: t('clientDashboard.portfolio.columns.isin'),
        key: 'isin',
        hidden: true,
        sortable: true,
        sorter: sorterString('isin'),
    },
    {
        title: t('clientDashboard.portfolio.columns.type'),
        key: 'type',
        sortable: true,
        sorter: sorterString('type'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.currency'),
        key: 'currency',
        sortable: true,
        sorter: sorterString('currency'),
        className: 'currency-cel',
        width: 80,
        minWidth: 80,
    },
    {
        title: t('clientDashboard.portfolio.columns.latestPrice'),
        key: 'latestPrice',
        width: 107,
        minWidth: 107,
        sortable: true,
        sorter: sorterNumber('latestPrice'),
        className: 'text-right',
    },
    {
        title: t('clientDashboard.portfolio.columns.subAssetClass'),
        key: 'subAssetClass',
        width: 200,
        minWidth: 200,
        sortable: true,
        sorter: sorterString('subAssetClass'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.country'),
        key: 'country',
        width: 250,
        minWidth: 250,
        sortable: true,
        sorter: sorterString('country'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.region'),
        key: 'region',
        width: 150,
        minWidth: 150,
        sortable: true,
        sorter: sorterString('region'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.sector'),
        key: 'sector',
        width: 100,
        minWidth: 100,
        sortable: true,
        sorter: sorterString('sector'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.subSector'),
        key: 'subSector',
        width: 200,
        minWidth: 200,
        sortable: true,
        sorter: sorterString('subSector'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.valuationInSecurityCurrency'),
        className: 'text-right',
        key: 'valuationInSecurityCurrency',
        sortable: true,
        sorter: sorterNumber('valuationInSecurityCurrency'),
        width: 200,
        minWidth: 200,
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.fxRate'),
        key: 'fxRate',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('fxRate'),
        width: 150,
        minWidth: 150,
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.unrealizedGainLoss'),
        key: 'unrealizedGainLoss',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('unrealizedGainLoss'),
        width: 200,
        minWidth: 200,
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.allocation'),
        key: 'allocation',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('allocation'),
        width: 120,
        minWidth: 120,
    },
    {
        title: t('clientDashboard.portfolio.columns.valueData'),
        className: 'text-right',
        key: 'valuation',
        sortable: true,
        sorter: sorterNumber('valuation'),
        width: 150,
        minWidth: 150,
    },
    {
        title: '',
        className: 'text-right table-cell__actions',
        key: 'actions',
        render: CellWithMoreActions,
        width: 40,
        minWidth: 40,
        titleType: 'columns',
        fixed: 'right',
        headerRenderer: CellWithIcon,
    },
]);

export const getComparePositionsColumns = (t) => ([
    {
        key: 'name',
        title: t('clientDashboard.portfolio.columns.name'),
        sortable: true,
        sorter: sorterString('name.label'),
        width: 350,
        minWidth: 350,
        className: 'with-link',
        render: CellSwitch,
        defaultSortOrder: 'descending',
    },
    {
        key: 'valueData',
        title: t('clientDashboard.portfolio.columns.valueData'),
        className: 'text-right',
        render: (data) => data && CellWithCurrentNewValue(data, {
            current: t('confirmation.current'),
            new: t('confirmation.new'),
        }),
        sorter: sorterNumber('valueData.currentValue'),
    },
    {
        key: 'allocationD',
        title: t('clientDashboard.portfolio.columns.allocation'),
        className: 'text-right',
        sorter: sorterNumber('allocationD.currentValue'),
        render: (data) => data && CellWithCurrentNewValue(data, {
            current: t('confirmation.current'),
            new: t('confirmation.new'),
        }),
    },
]);
