import { renderers } from 'ui-library';
import { sorterNumber, sorterString } from '../utils/sorting';
import { CellSwitch } from '../components/renderers';

const { CellWithCurrentNewValue } = renderers;

export const getPositionsColumns = (t) => ([
    {
        key: 'name',
        title: t('clientDashboard.portfolio.columns.name'),
        sortable: true,
        sorter: sorterString('name'),
        className: 'with-link',
        render: CellSwitch,
        defaultSortOrder: 'descending',
    },
    {
        key: 'valueData',
        title: t('clientDashboard.portfolio.columns.valueData'),
        className: 'text-right',
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('confirmation.current'),
            new: t('confirmation.new'),
        }),
        minWidth: 220,
        sorter: sorterNumber('valueData.currentValue'),
    },
    {
        key: 'allocationD',
        title: t('clientDashboard.portfolio.columns.allocation'),
        className: 'text-right',
        sorter: sorterNumber('allocationD.currentValue'),
        minWidth: 120,
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('confirmation.current'),
            new: t('confirmation.new'),
        }),
    },
]);

export const ORDER_TYPE = {
    1: 'orderType.buy',
    2: 'orderType.sell',
};
