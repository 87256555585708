import { sorterByDate, sorterString } from 'utils/sorting';
import { isEmpty } from 'utils';
import { dateTimeFormats } from 'locale';

function changeFormat(date) {
    const result = date.split(',');

    return `${result[1]} ${result[0]}`;
}

export const LOG_ENTRIES_COLUMNS = ({ t, getFormattedDate }) => [
    {
        key: 'time',
        title: t('interaction.sorting.time'),
        render: (data) => (isEmpty(data) ? undefined : changeFormat(getFormattedDate(data, {
            hour: dateTimeFormats.TWO_DIGIT,
            minute: dateTimeFormats.TWO_DIGIT,
        }))),
        width: 90,
        sortable: true,
        sorter: sorterByDate('time'),
        className: 'table-cell__date',
    },
    {
        key: 'message',
        title: t('interaction.sorting.message'),
        sortable: true,
        sorter: sorterString('message'),
        className: 'text-left',
    },
    {
        key: 'createdBy',
        title: t('interaction.sorting.createdBy'),
        sortable: true,
        sorter: sorterString('createdBy'),
        className: 'text-right',
        width: 120,
    },
];
