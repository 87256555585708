import {
    memberIdSelector,
} from 'redux/auth/authSelectors';
import { useSelector } from 'react-redux';
import { gql, useLazyQuery } from '@apollo/client';
import { withCSContext } from 'services/ApolloClient';


export const useClientList = (variables = {}, ContactItemsFragment = '') => {
    const CONTACTS_DATA = gql`
        query ContactsData(
          $includeTotalCount: Boolean = false
          $take: Int
          $skip: Int
          $where:ContactFilterInput
          $order: [ContactSortInput!]! = []
        ) {
              contacts(
                includeTotalCount: $includeTotalCount
                take: $take
                skip: $skip
                order: $order
                where: $where
              ) {
                totalCount
                items {
                    ...ContactItems
                }
              }
        }
        ${ContactItemsFragment}
`;

    const memberId = useSelector(memberIdSelector);
    const [
        getCommon, {
            loading: isLoading,
            data: { contacts: { totalCount, items: data = [] } = {} } = {},
            error,
        },
    ] = useLazyQuery(CONTACTS_DATA, {
        variables,
        context: withCSContext(
            { queryParams: { memberId } },
        ),
    });


    return {
        data,
        isLoading,
        error,
        totalCount,
        getCommon,
    };
};
