import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptConstraints } from 'adaptors/adaptConstraints';
import { MODIFY, NEW_PORTFOLIO } from '../../../constants';

export const useCreateProposal = (actionType) => {
    const [isCreating, setCreating] = useState(false);
    const [errorCreating, setError] = useState(null);

    const { i18n, t } = useTranslation();

    const onCreateProposalComplete = ({ dfsClientId, portfolioId }) => {
        setCreating(false);
        history.push(
            `/client/${dfsClientId}/success-page`,
            {
                title: t('confirm.title'),
                confirmButton: t('confirmation.backToPortfolio'),
                message: t('confirmation.acceptProposalCreationContent'),
                redirectURL: `/client/${dfsClientId}/portfolios/${portfolioId}`,
            },
        );
    };
    const onCreateProposal = useCallback(({
        clientId,
        dfsClientId,
        portfolioId,
        productId,
        allocations,
        name,
        modelPortfolioId,
        constraints,
        contactGroupId,
    }) => {
        setCreating(true);
        setError(null);

        const params = {
            OptimizationConstraints: adaptConstraints(constraints),
            PortfolioId: Number(portfolioId),
            ProductId: Number(productId),
            Name: name,
        };

        if (modelPortfolioId) {
            params.ModelPortfolioId = modelPortfolioId;
        } else {
            params.InstrumentAllocations = allocations;
        }

        return ServiceManager.portfolioManagement('postPortfolioProposal', [clientId, i18n.language, params, contactGroupId ? { contactGroupId } : {}])
            .then((response) => {
                try {
                    const { Id } = response?.data;

                    if (actionType === NEW_PORTFOLIO) return Id;

                    ServiceManager.reportsService('reportInvestmentProposal', [clientId, +portfolioId, Id, i18n.language])
                        .then(() => {
                            if (actionType === MODIFY) {
                                ServiceManager.portfolioManagement('deleteTradingCartOrder', [clientId, portfolioId])
                                    .then(() => {
                                        onCreateProposalComplete({ dfsClientId, portfolioId });
                                    })
                                    .catch(handlerRequestCanceling(
                                        HandlerError({ setError, setLoading: setCreating }),
                                    ));
                            } else {
                                onCreateProposalComplete({ dfsClientId, portfolioId });
                            }
                        })
                        .catch(handlerRequestCanceling(
                            HandlerError({
                                setError,
                                setLoading: setCreating,
                            }),
                        ));

                    return Id;
                } catch (err) {
                    setCreating(false);
                    setError(err);
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading: setCreating,
                }),
            ));
    }, [i18n.language]);

    return {
        onCreateProposal,
        isCreating,
        errorCreating,
    };
};
