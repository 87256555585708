import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import update from 'lodash/update';
import { FileInput, Infobox } from 'ui-library';
import FormIo from 'components/FormIo';
import AdapterError from 'errors/AdapterError';
import { adaptFiles } from 'adaptors/adaptFiles';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { useDynamicCRM } from '../../hooks/useDynamicCRM';
import './PersonalInformation.css';

function PersonalInformation(props) {
    const {
        onPageChange, changeStep, contactId,
    } = props;
    const { t } = useTranslation();
    const [formio, setFormio] = useState();
    const [isValid, setIsValid] = useState(true);
    const [submissionData, setSubmissionData] = useState({});
    const [files, setFiles] = useState();

    // Helper hooks
    const {
        data: schema, submission, isLoading, error,
        errorValidation, errorSave, isSaving, saveData,
        isUploading, errorUpload, uploadFiles,
    } = useDynamicCRM({ contactId, selectors: ['personalInformation', 'communicationMethods'] });

    // Data
    const submissionMemo = useMemo(() => ({ data: submission }), [submission]);

    // Callbacks
    const onFileChange = useCallback(async (filesToStore) => {
        if (filesToStore.length === 0) {
            setFiles(null);

            return;
        }

        try {
            const fileParams = await adaptFiles(contactId, filesToStore, true);

            setFiles(fileParams);
        } catch (err) {
            throw new AdapterError(err);
        }
    }, [contactId]);
    const onChange = useCallback(({ isValid: isValidForm }, { changes }) => {
        (changes || []).forEach((change) => {
            if (!change?.component?.disabled && !change?.flags?.noValidate) {
                setSubmissionData((obj) => update(
                    obj,
                    change.instance.path,
                    () => change.value,
                ));
            }
        });
        setIsValid(isValidForm);
    }, []);
    const onNextClick = useCallback(async () => {
        await formio.submit();

        if (!isValid) return;

        try {
            await Promise.all([uploadFiles(files), saveData(submissionData)]);
            onPageChange('professional');
        } catch { /* Do not throw error */ }
    }, [files, formio, isValid, onPageChange, saveData, submissionData, uploadFiles]);

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.personalInformation')}
            error={error}
            isLoading={isLoading}
            classNameContent="personal-information"
            prevButton={{
                loading: isSaving || isUploading,
                onClick: () => changeStep(5, 'summary'),
            }}
            nextButton={{
                loading: isSaving || isUploading,
                text: t('onBoarding.continue'),
                onClick: onNextClick,
            }}
            buttonsRowError={(
                <>
                    {errorValidation.length > 0 && errorValidation.map((err) => (
                        <Infobox error>{err}</Infobox>
                    ))}
                    {errorSave && (<Infobox error>{errorSave.message}</Infobox>)}
                    {errorUpload && (<Infobox error>{errorUpload.message}</Infobox>)}
                </>
            )}
        >
            <FormIo
                form={schema}
                submission={submissionMemo}
                options={{
                    readOnly: false,
                    noAlerts: false,
                    renderMode: 'HWM',
                }}
                onChange={onChange}
                formReady={setFormio}
            />
            <div className="personal-information__upload">
                <FileInput
                    multiple
                    label={t('onBoarding.personalInformationDocument')}
                    accept=".pdf, .docx, .doc"
                    uploadText={t('advisoryDashboard.documents.selectFile')}
                    onChange={onFileChange}
                    disabled={isUploading}
                />
            </div>
        </OnBoardingBaseTemplate>
    );
}

PersonalInformation.propTypes = {
    contactId: PropTypes.string.isRequired,
    onPageChange: PropTypes.func.isRequired,
    changeStep: PropTypes.func.isRequired,
};

PersonalInformation.defaultProps = {
};

export default PersonalInformation;
