import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import merge from 'lodash/merge';
import { useContactActivation, useEditCustomer, useSchema } from 'domain/ClientService';
import { getHardcodedDetails } from 'domain/ClientService/constants';
import { useSelector } from 'react-redux';
import { memberIdSelector } from 'redux/auth/authSelectors';
import { adaptFormForSave } from '../adaptors/adaptRegistration';

const IN_PROGRESS = 'in progress';
const selectors = [
    'personalInformation.salutation',
    'personalInformation.firstName',
    'personalInformation.lastName',
    'communicationMethods.primaryEmail',
    'personalInformation.gender',
    'communicationMethods.primaryPhoneCountryCode',
    'communicationMethods.primaryPhoneNumber',
    'communicationMethods.primaryAddressCountry',
    'personalInformation.identification.countryOfNationality',
    'taxSituation.isUsPerson',
    'politicalExposure.isPoliticallyExposedPerson',
];

export const useRegistration = () => {
    const memberId = useSelector(memberIdSelector);
    const [customError, setCustomError] = useState(null);
    const {
        data: schema, error, isLoading, getSchema,
        createContactError, createContactIsLoading, createContact,
    } = useEditCustomer();
    const {
        error: errorActivation, isLoading: isLoadingActivation, sendActivationKey,
    } = useContactActivation();
    const {
        errorLists, isLoadingLists, getSchemasLists,
    } = useSchema();

    // Get Stages
    const {
        errorListsKey: errorStages, isLoadingListsKey: isLoadingStages, getSchemaListByKey,
    } = useSchema({ loadInitially: false });

    // Callbacks
    const saveData = useCallback(async (data) => {
        setCustomError(null);

        const clientName = `${data.personalInformation.firstName} ${data.personalInformation.lastName}`;

        try {
            const stages = await getSchemaListByKey({ key: 'prospectOnboardingStatus' });
            const stageId = (stages || [])?.[1]?.id || (stages || [])?.[1]?.value;
            const statuses = await getSchemaListByKey({ key: 'contactStatus' });
            const contactStatus = statuses
                .find((item) => item?.label.toLowerCase() === IN_PROGRESS);
            const details = merge(
                getHardcodedDetails({
                    countryOfNationality: data.personalInformation
                        .identification.countryOfNationality,
                    onboardingStage: stageId,
                    contactStatus: contactStatus?.id || contactStatus?.value,
                }),
                adaptFormForSave(data),
            );
            const { id } = await createContact({
                data: { contactTypeId: 1, memberIds: [memberId], details },
            });

            await sendActivationKey({ contactId: id });

            return { contactId: id, clientName };
        } catch (e) {
            const { ValidationErrors } = e.error?.response?.data?.properties || {};
            const primaryEmailError = ValidationErrors.find((err) => err?.property === 'communicationMethods.primaryEmail');

            if (primaryEmailError) setCustomError(primaryEmailError);

            throw e;
        }
    }, [createContact, memberId]);

    // Data
    const data = useMemo(() => {
        let dataReturn = null;

        try {
            dataReturn = JSON.parse(schema?.schema);
        } catch (e) { /* Do not throw error */ }

        return dataReturn;
    }, [schema]);

    // Effects
    useEffect(() => {
        const params = { selectors, onlyFields: true };

        getSchema({ data: params });
    }, [getSchema]);
    useEffect(() => {
        getSchemasLists();
    }, [getSchemasLists]);

    return {
        data,
        error: error || errorLists,
        isLoading: isLoading || isLoadingLists || isLoadingStages || isLoadingActivation,
        errorSave: customError || createContactError || errorStages || errorActivation,
        usSaving: createContactIsLoading,
        saveData,
    };
};
