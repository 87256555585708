import React from 'react';
import PropTypes from 'prop-types';
import { RiskProfileProvider } from 'domain/RiskProfile';
import { useClientProfile } from 'prodivers/clientProfile';
import Router from './Router';

const RiskProfileContainer = (props) => {
    // Client Provider
    const { clientId } = useClientProfile();


    return (
        <RiskProfileProvider options={{ clientId }}>
            <Router {...props} />
        </RiskProfileProvider>
    );
};

RiskProfileContainer.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
    }).isRequired,
};

RiskProfileContainer.defaultProps = {};

export default RiskProfileContainer;
