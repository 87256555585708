import React from 'react';
import { Link } from 'react-router-dom';
import {
    BreadCrumbs,
    Button,
    Column,
    ContentBox,
    Row,
    Select,
    TextInput,
    Textarea,
    Title,
    Accordion,
    AccordionPanel as Panel,
    Table,
} from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useTranslation } from 'react-i18next';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { withMainLayout } from '../../../hocs';
import { getColumns } from '../constants';
import { useNewMessage } from './hooks/useNewMessage';

function NewMessage(props) {
    useCancelAllRequests();

    const { t } = useTranslation();
    const { location } = props;
    const {
        onChange,
        recipientTypes,
        control,
        onCancel,
        onGoToDashboard,
        formErrors,
        recipientToOptions,
        clientListWithChecked,
        isLoading,
        values,
        clientListChecked,
        filterClientList,
        messageSubTypesOptions,
        isSending,
        handleSubmit,
        sendMessage,
    } = useNewMessage();

    return (
        <PageSection className="new-message">
            <ContentBox>
                <BreadCrumbs current={t('messages.newMessage')}>
                    <Link to="/messages">{t('messages.title')}</Link>
                </BreadCrumbs>
                <Title type={1} className="mb-0">
                    {t('messages.newMessage')}
                </Title>
            </ContentBox>
            <Preloader isLoading={isLoading} error={null}>
                <Accordion defaultActiveKey={['1']}>
                    <Panel header={t('messages.recipients')} key="1" count={clientListChecked.length > 0 ? clientListChecked.length : undefined}>
                        <Row>
                            <Column size={['sm-6']}>
                                <div className="row">
                                    <Column size={['sm-6']}>
                                        <Controller
                                            as={<Select />}
                                            name="recipientsType"
                                            control={control}
                                            value={values.recipientsType}
                                            onChange={onChange('recipientsType')}
                                            options={recipientTypes}
                                            label={t('messages.type')}
                                            withInfobox={false}
                                            error={formErrors.recipientsType}
                                            required
                                        />
                                    </Column>
                                    <Column size={['sm-6']}>
                                        <Controller
                                            as={<Select />}
                                            name="recipientsTo"
                                            control={control}
                                            value={values.recipientsTo}
                                            onChange={onChange('recipientsTo')}
                                            options={recipientToOptions}
                                            label={t('messages.to')}
                                            withInfobox={false}
                                            error={formErrors.recipientsTo}
                                            required
                                        />
                                    </Column>
                                </div>
                                <div className="row">
                                    <Column size={['sm-12']}>
                                        <Controller
                                            name="clients"
                                            control={control}
                                            onChange={onChange('clients')}
                                            label={t('messages.client')}
                                            value={values.clients}
                                            error={formErrors.clients}
                                            required
                                            as={<TextInput />}
                                        />
                                    </Column>
                                </div>
                                <TextInput
                                    label={t('messages.searchClient')}
                                    onChange={filterClientList}
                                    search
                                />
                            </Column>
                        </Row>
                        <EmptyContent data={clientListWithChecked} text={t('advisoryDashboard.noData')}>
                            <Table
                                data={clientListWithChecked}
                                paging={{ pageSize: 10, total: clientListWithChecked?.length }}
                                columns={getColumns(t)}
                                className="client-list-table"
                            />
                        </EmptyContent>
                    </Panel>
                    <Panel className="new-message__message-panel" header="Message" key="2">
                        <Row className="mb-0">
                            <Column size={['sm-6']}>
                                <Select
                                    control={control}
                                    name="contactReason"
                                    value={values.contactReason}
                                    onChange={onChange('contactReason')}
                                    options={messageSubTypesOptions}
                                    label={t('messages.category')}
                                    withInfobox={false}
                                    error={formErrors.contactReason}
                                    required
                                />
                                <br />
                                <Controller
                                    as={<TextInput />}
                                    name="subject"
                                    control={control}
                                    value={values.subject}
                                    onChange={onChange('subject')}
                                    label={t('messages.subject')}
                                    error={formErrors.subject}
                                    required
                                />
                                <br />
                                <Controller
                                    as={<Textarea />}
                                    name="message"
                                    control={control}
                                    label={t('messages.message')}
                                    defaultValue={values.message}
                                    error={formErrors.message}
                                    onChange={onChange('message')}
                                    required
                                />
                            </Column>
                        </Row>
                    </Panel>
                    <Row className="new-message__buttons-block">
                        <Column size={12} className="text-sm-right button-group">
                            <Button
                                type="secondary"
                                size="small"
                                onClick={
                                    location?.state?.fromQuickAccessMenu ? onGoToDashboard
                                        : onCancel}
                            >
                                {t('messages.cancel')}
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                disabled={isSending}
                                onClick={handleSubmit(sendMessage)}
                            >
                                {t('messages.send')}
                            </Button>
                        </Column>
                    </Row>
                </Accordion>
            </Preloader>
        </PageSection>
    );
}

NewMessage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            fromQuickAccessMenu: PropTypes.bool,
        }),
    }).isRequired,
};

NewMessage.defaultProps = {};

export default withMainLayout(NewMessage);
