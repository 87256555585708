import React from 'react';
import PropTypes from 'prop-types';
import CellSwitch from '../CellSwitch/CellSwitch';

import './CellWithNewIconAndText.css';

const CellWithNewIconAndText = (props) => {
    const { isNew, maxLength, value } = props;

    return (
        <div className="cell-with-new-icon">
            {isNew && (
                <div className="cell-with-new-icon_icon">
                    <span>New</span>
                </div>
            )}
            {maxLength ? <CellSwitch {...props} /> : (
                <span>{value}</span>
            )}
        </div>
    );
};

CellWithNewIconAndText.propTypes = {
    value: PropTypes.string,
    isNew: PropTypes.bool,
    maxLength: PropTypes.number,
};

CellWithNewIconAndText.defaultProps = {
    value: '',
    isNew: false,
    maxLength: undefined,
};

export default CellWithNewIconAndText;
