import { createContext } from 'react';

const initialContext = {
    clientId: undefined,
    // logic from useProductOffers hook
    productOffers: {
        data: {},
        error: null,
        isLoading: false,
    },
    // logic from useProducts hook
    products: {
        data: {},
        globalSettings: {},
        error: null,
        isLoading: false,
    },
    // logic from useGoalCreation hook
    goalCreation: {
        data: {},
        error: null,
        isLoading: false,
        dataGoalTemplates: null,
        dataGoalPictures: [],
        errorGoalTemplates: null,
        isLoadingGoalTemplates: false,
        dataCurrencies: {},
        errorCurrencies: null,
        isLoadingCurrencies: false,
        dataRecurrences: {},
        errorRecurrences: null,
        isLoadingRecurrences: false,
        dataOptimize: null,
        errorOptimize: null,
        isLoadingOptimize: false,
    },
    // logic from useGoalOptimize hook
    goalOptimize: {
        data: {},
        error: null,
        isLoading: false,
    },
    // logic from useSaveAndUpdateGoal hook
    goalSavingAndUpdating: {
        saveGoal: null,
        updateFunding: null,
        updateGoal: null,
        error: null,
        isLoading: false,
    },
};

const OnBoardingContext = createContext(initialContext);

export default OnBoardingContext;
