import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { adaptCurrencies } from '../adapters/adaptCurrencies';

const combinations = ['EUR-USD', 'USD-JPY', 'GBP-USD', 'USD-CAD', 'USD-CHF', 'EUR-JPY', 'EUR-GBP', 'EUR-CHF'];
const parsedCombinations = combinations.map((item) => {
    const itemParsed = item.split('-');

    return {
        BaseCurrencyCode: itemParsed[0],
        QuoteCurrencyCode: itemParsed[1],
    };
});

export const useCurrencies = () => {
    const { i18n } = useTranslation();
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        ServiceManager.currenciesService('postCurrenciesExchangerates', [parsedCombinations])
            .then((response) => {
                try {
                    setCurrencies(adaptCurrencies(response.data.CurrencyExchangeRatesPerformance));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, [i18n.language]);

    return { data: currencies, isLoading, error };
};
