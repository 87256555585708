import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'ui-library';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import DomesticReview from '../../components/DomesticReview/DomesticReview';
import InternationalReview from '../../components/InternationalReview/InternationalReview';
import AccountReview from '../../components/AccountReview/AccountReview';
import './ReviewPayment.css';
import { usePaymentsData } from '../../hooks/usePaymentsData';

const Reviews = {
    0: InternationalReview,
    1: DomesticReview,
    2: AccountReview,
};

const ReviewPayment = (props) => {
    const { dfsClientId } = props;

    const { getPaymentCode, clearPayments } = usePaymentsData();
    const payment = getPaymentCode();

    const { t } = useTranslation();

    const onCancel = useCallback(() => {
        Modal.confirm({
            title: t('payments.cancelPaymentTitle'),
            content: t('payments.cancelPaymentContent'),
            okText: t('payments.cancelPayment'),
            onOk: () => { clearPayments(); history.push(`/client/${dfsClientId}/dashboard`); },
            okType: 'danger',
            cancelText: t('payments.back'),
            className: 'close-client-modal',
        });
    }, [dfsClientId]);

    const ReviewPaymentRender = useMemo(() => Reviews[payment], [payment]);

    return (
        <ReviewPaymentRender payment={payment} onCancel={onCancel} {...props} />
    );
};

ReviewPayment.propTypes = {
    dfsClientId: PropTypes.string.isRequired,
};

ReviewPayment.defaultProps = {
};

export default ReviewPayment;
