export const adaptParticipants = (data, contactId) => data.map((item) => ({
    value: item?.Id || item?.MemberId,
    label: item?.clientName || `${item.FirstName} ${item.LastName}`,
    disabled: contactId ? contactId === item?.Id : false,
})).filter(({ value }) => value > 0);

export const adaptTopics = (data) => data
    .map((item) => ({
        value: item.Id,
        label: item.Name,
    }));

export const adaptTypes = (data) => data.map((item) => ({
    value: item.Id,
    label: item.Name,
}));
