import { checkNullData } from 'utils';

export const adaptProductFee = (data, portfolioValue) => {
    if (checkNullData(data) || checkNullData(data?.Fees)) {
        return null;
    }

    const fee = data?.Fees
        .filter(({ FeeType }) => FeeType.Id === 1) // TODO: Remove FeeType hardcode
        .find((item) => (portfolioValue >= item.ValueFrom && portfolioValue <= item.ValueTo));

    return (fee?.FeeValue || 0) * 100; // TODO: avoid default hardcode
};
