import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from 'services/history';
import PersonalInformation from './pages/PersonalInformation';
import ProfessionalInformation from './pages/ProfessionalInformation';
import TaxInformation from './pages/TaxInformation';

const Router = (props) => {
    const { match: { url, path } } = props;
    const onPageChange = (pageKey) => {
        history.push(`${url[url.length - 1] === '/' ? url : `${url}/`}${pageKey}`);
    };
    const withProps = (Component) => (defProps) => (
        <Component {...props} {...defProps} onPageChange={onPageChange} />
    );

    return (
        <Switch>
            <Route exact path={`${path}`} render={withProps(PersonalInformation)} />
            <Route exact path={`${path}/professional`} render={withProps(ProfessionalInformation)} />
            <Route exact path={`${path}/tax`} render={withProps(TaxInformation)} />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
