import { NA } from 'utils/formatting';

export const adaptRiskInformation = ({
    dataClient, clientRiskProfile, hasChosenRisk, t, hasProducts,
}) => {
    const riskInformation = {
        Information: {
            title: t('clientDashboard.overview.contacts.riskProfile'),
            subtitle: clientRiskProfile?.ChosenRiskCategory?.Name || NA,
        },
        Icon: {
            type: 'form',
            size: 20,
            link: hasProducts && 'risk-profile',
            className: 'information-risk-profile',
        },
    };
    const knowledgeExperience = {
        Information: {
            title: t('clientDashboard.overview.EditKE'),
        },
        Icon: {
            type: 'form',
            size: 20,
            link: hasProducts && 'details?defaultTab=ke',
            className: 'information-knowledge-experience',
        },
    };

    const Contacts = dataClient?.data?.Contacts
        ? [
            riskInformation,
            knowledgeExperience,
            ...dataClient?.data?.Contacts,
        ]
        : [];

    const adaptedData = {
        ...dataClient,
        data: {
            ...dataClient?.data,
            Contacts,
            HasRiskProfile: hasChosenRisk,
            ChosenRiskCategory: clientRiskProfile?.ChosenRiskCategory?.Name,
        },
        hasChosenRisk,
    };

    return adaptedData;
};
