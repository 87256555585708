import {
    useState, useCallback, useEffect,
} from 'react';
import SM from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { downloadDmsDocument } from 'utils/downloadDmsDocument';

export const useSaveDocuments = (options) => {
    const {
        documentId,
    } = options;
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const downloadDocument = useCallback(() => {
        if (+documentId) {
            setLoading(true);
            SM.documents('getDocumentById', [documentId]).then((response) => {
                try {
                    if (response?.data) {
                        setData(response?.data);
                        setLoading(false);
                    }
                } catch (err) {
                    setLoading(false);
                    throw new AdapterError(err);
                }
            }).catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
        }
    }, [documentId]);

    const saveDocument = useCallback(() => {
        if (data) {
            downloadDmsDocument(
                data,
                {
                    name: data?.retrieveFileAsName,
                    saveFile: true,
                },
            );
        }
    }, [data]);

    useEffect(() => {
        downloadDocument();
    }, [downloadDocument]);

    return {
        saveDocument,
        isLoading,
        error,
    };
};
