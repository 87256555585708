import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import {
    memberIdSelector,
} from 'redux/auth/authSelectors';
import { getfromDataObject } from 'utils/formatting';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { groupObjectById } from 'adaptors/groupObjectById';
import { adaptInvestmentAllocation } from '../adapters/adaptInvestmentAllocation';


export const useInvestmentAllocation = (configuration) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({ chart: [] });
    const { i18n, t } = useTranslation();
    const memberId = useSelector(memberIdSelector);

    const [rawData, setRawData] = useState(null);
    // eslint-disable-next-line consistent-return
    const getFlatMemberData = useCallback(() => new Promise((resolve, reject) => {
        if (rawData && Object.keys(rawData).length) {
            return resolve(rawData);
        }

        ServiceManager.dataService('getFlatMemberData', [memberId])
            .then((response) => {
                const parsedJSON = JSON.parse(getfromDataObject(response, 'data.Data', {}));

                setRawData(parsedJSON);
                resolve(parsedJSON);
            })
            .catch(reject);
    }), [rawData]);

    useEffect(() => {
        setError(null);
        setLoading(true);
        if (!configuration.isLoading) {
            getFlatMemberData().then((dataRaw) => {
                Promise.all([
                    ServiceManager.commonService('getAssetClasses', [i18n.language]),
                    ServiceManager.commonService('getSecurityTypes', [i18n.language]),
                    ServiceManager.currenciesService('getCurrencies', [i18n.language]),
                    ServiceManager.commonService('getCountries', [i18n.language]),
                    ServiceManager.commonService('getSectors', [i18n.language]),
                ])
                    .then((responses) => responses
                        .map((item) => groupObjectById(item.data)))
                    .then((responseNames) => {
                        try {
                            const { CurrencyCode } = getfromDataObject(configuration, 'data.currency', {});

                            setData({
                                chart: adaptInvestmentAllocation(
                                    dataRaw, responseNames, CurrencyCode, t,
                                ),
                                currency: CurrencyCode,
                            });
                            setLoading(false);
                        } catch (err) {
                            throw new AdapterError(err);
                        }
                    })
                    .catch(handlerRequestCanceling(HandlerError({ setError, setLoading })));
            })
                .catch(handlerRequestCanceling(HandlerError({ setError, setLoading })));
        }
    }, [configuration.isLoading]);

    return { data, isLoading, error };
};
