import React from 'react';
import PropTypes from 'prop-types';
import { ContentBox, ProgressBar, Step } from 'ui-library';
import { useTranslation } from 'react-i18next';

export const RiskProfileTemplate = (props) => {
    const {
        children,
        groups,
        currentGroup,
    } = props;
    const { t } = useTranslation();

    return (
        <>
            {(groups.length && currentGroup !== undefined) ? (
                <ProgressBar current={+currentGroup}>
                    {groups.map((group) => (
                        <Step key={group.Name} title={group.Name} />
                    ))}
                    <Step key="review" title={t('riskProfile.review')} />
                </ProgressBar>
            ) : null}
            <ContentBox>
                {children}
            </ContentBox>
        </>
    );
};

RiskProfileTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    currentGroup: PropTypes.number,
    groups: PropTypes.arrayOf(PropTypes.shape({

    })),
};

RiskProfileTemplate.defaultProps = {
    currentGroup: undefined,
    groups: [],
};

RiskProfileTemplate.displayName = 'RiskProfileTemplate';
