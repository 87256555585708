import {
    useCallback, useState,
} from 'react';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';
import ServiceManager from 'services/ServiceManager';
import axios from 'axios';
import { setup } from 'additiv-services';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useTranslation } from 'react-i18next';
import { blobFileToBase64 } from '../utils';

const FIDENTITY = 'fidentity';

export const useSuccess = ({
    fidentityUserId, documentName, contactId, documentId,
}) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { saveSignedDocumentId } = useOnBoardingSelector(onBoardingDataSelector);
    const { t } = useTranslation();

    const onSaveDocument = useCallback(async () => {
        try {
            setError(null);
            setLoading(true);

            const accessTokenResp = await ServiceManager.Security('getAccessTokenForExtension', [{ payload: { type: FIDENTITY } }]);

            const bearerFIdentityAccessToken = `Bearer ${accessTokenResp?.data?.accessToken}`;

            setup({ bearerFIdentityAccessToken });

            const { data: fidentityProcessData } = await ServiceManager.fIdentity('getFidentityProcess', [fidentityUserId]);

            const uri = fidentityProcessData?.mediaUris.find((i) => i.mediatype === 'signed-pdf')?.uri?.replace('/api', '');

            const { data: signedDocumentData } = await axios.get(`${process.env.REACT_APP_GATEWAY_API_URL}${global?.AppConfig?.FIDENTITY_URL_SUFFIX}${uri}`, {
                responseType: 'blob',
                headers: {
                    authorization: bearerFIdentityAccessToken,
                },
            });

            const signedDocument = await blobFileToBase64(signedDocumentData);

            const changeDocumentParams = {
                originalFilename: `${documentName} - ${t('onBoarding.contract.signed')}`,
                retrieveFileAsName: `${documentName} - ${t('onBoarding.contract.signed')}`,
                ownerIds: [contactId],
                metadataReferenceId: 'InvestmentContract',
                file: signedDocument.split(';base64,')[1],
            };

            await ServiceManager.documents('patchDocument', [documentId, changeDocumentParams]);

            saveSignedDocumentId(documentId);

            setLoading(false);

            return signedDocument;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            )(err);
            throw err.type !== undefined ? err : new ServerError(err);
        }
    },
    [fidentityUserId, documentName, contactId, documentId]);

    return {
        error,
        isLoading,
        onSaveDocument,
    };
};
