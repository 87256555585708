import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useFormatting, useLocale } from 'locale';
import ServerError from 'errors/ServerError';
import { adaptInvestmentOverview } from '../adapters/adaptInvestmentOverview';
import { portfolioActions } from '../../../constants';

export const useWithInvestmentOverview = ({
    clientId, dfsClientId: dfsId, proposals, usePortfolioOverview,
}) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({
        overview: {},
        investmentAllocation: { chart: [{ data: { pieSa: {}, bar: {}, list: {} } }] },
    });
    const { i18n, t } = useTranslation();
    const { getFormattedCurrency } = useFormatting();
    const { locale } = useLocale();

    const getInvestmentOverview = useCallback(async () => {
        setLoading(true);
        try {
            const params = { language: i18n.language };
            const { data: dataInvestment } = await ServiceManager.portfolioManagement('getInvestmentOverview', [clientId, params]);
            const portfoliosOverview = usePortfolioOverview
                ? await Promise.all(dataInvestment.Portfolios
                    .map(async ({ Id }) => ({
                        Id,
                        ...(await ServiceManager.portfolioManagement('getPortfolioOverview', [Id, clientId, params])).data,
                    })))
                : [];

            try {
                setData(
                    adaptInvestmentOverview(
                        {
                            data: dataInvestment,
                            dfsId,
                            portfolioActions,
                            t,
                            getFormattedCurrency,
                            proposals: proposals?.data,
                            portfoliosOverview,
                        },

                    ),
                );
                setLoading(false);

                return data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [i18n.language, locale, proposals?.data, clientId]);

    useEffect(() => {
        if (proposals?.isLoading) return;

        getInvestmentOverview();
    }, [getInvestmentOverview, proposals?.isLoading, locale]);

    return { data, isLoading, error };
};
