import { renderers } from 'ui-library';
import { sorterString, sorterNumber } from 'utils/sorting';

const { CellWithCheckboxLinked } = renderers;

export const getColumns = (t) => ([
    {
        key: 'internalId',
        title: t('messages.internalId'),
        sorter: sorterNumber('internalId.value'),
        width: 200,
        render: CellWithCheckboxLinked,
    },
    {
        key: 'externalId',
        sorter: sorterString('externalId'),
        title: t('messages.externalId'),
        width: 200,
    },
    {
        key: 'name',
        title: t('messages.name'),
        sortable: true,
        sorter: sorterString('name'),
        className: 'table-cell_clientName',
    },
]);
