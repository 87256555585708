import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import update from 'lodash/update';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    ContentBox, Infobox, Title, Row, Column,
} from 'ui-library';
import Preloader from 'components/Preloader';
import PageSection from 'components/PageSection';
import LoadingOverlap from 'components/LoadingOverlap';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import DetailsFormIo from 'components/FormIo';
import { useClientServiceSelector, schemaSelector, contactSelector } from 'domain/ClientService';
import { adaptLists } from 'domain/ClientService/adapters/adaptLists';
import LeftNavigation from './components/LeftNavigation';
import DetailsCustomContent from './components/DetailsCustomContent';

import './Details.css';
import { customSchema } from './constants';

const Details = () => {
    const { t } = useTranslation();
    const form = useRef();
    const [submissionData, setSubmissionData] = useState(null);
    const [hasActive, setHasActive] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [openedTab, setOpenedTab] = useState(null);
    // ClientService Domain
    const {
        data, error, isLoading, getSchema,
        errorLists, isLoadingLists, getSchemasLists,
    } = useClientServiceSelector(schemaSelector);
    const {
        data: contact, error: errorContact, isLoading: isLoadingContact, getContact,
        errorUpdateContact, isLoadingUpdateContact, updateContact,
    } = useClientServiceSelector(contactSelector);

    // Callbacks
    const updateContactDetails = useCallback(() => {
        // Delete properties which are unable to save
        delete submissionData.personalInformation.displayName;
        delete submissionData.personalInformation.displayName1;
        delete submissionData.personalInformation.isProspect;
        updateContact({ params: { details: submissionData } });
    }, [updateContact, submissionData]);
    const onChange = useCallback(({ isValid: isValidForm }, { changes }) => {
        (changes || []).forEach((change) => {
            if (!change?.component?.disabled && !change?.flags?.noValidate) {
                setSubmissionData((obj) => update(
                    obj,
                    change.instance.path,
                    () => change.value,
                ));
            }
        });
        setIsValid(isValidForm);
    }, []);

    // Data
    const formIoSchema = useMemo(() => {
        let dataReturn = null;

        try {
            dataReturn = JSON.parse(data?.schema);
        } catch (e) { /* Do not throw error */
        }

        return dataReturn;
    }, [data?.schema]);
    const submission = useMemo(() => ({ data: contact?.details }), [contact]);

    // Effects
    useEffect(() => {
        getSchema({ key: 'editCustomer' });
    }, [getSchema]);
    useEffect(() => {
        getContact();
    }, [getContact]);
    useEffect(() => {
        getSchemasLists();
    }, [getSchemasLists]);
    useEffect(() => {
        setSubmissionData(contact?.details);
    }, [contact]);

    const schema = {
        formIo: formIoSchema,
        custom: customSchema({ t, contactId: contact?.id }),
    };

    const onFormReady = () => {
        setOpenedTab(
            schema?.formIo?.components?.find((item) => item?.title && item?.key)?.key,
        );
    };

    return (
        <PageSection>
            <Preloader
                isLoading={isLoading || isLoadingLists || isLoadingContact}
                error={error || errorLists || errorContact}
            >
                <ContentBox className="client-details">
                    <Title type={2} className="underlined">{t('clientDashboard.details.title')}</Title>
                    <Row>
                        <Column size={['sm-3', 'lg-3']}>
                            <LeftNavigation
                                openedTab={openedTab}
                                form={form.current}
                                schema={schema}
                                onChange={setHasActive}
                            />
                        </Column>
                        <Column size={['sm-9', 'lg-9']}>
                            <LoadingOverlap isLoading={!hasActive}>
                                <div className="details-form-io">
                                    <DetailsFormIo
                                        ref={form}
                                        form={schema.formIo}
                                        submission={submission}
                                        formReady={onFormReady}
                                        options={{
                                            readOnly: false,
                                            noAlerts: false,
                                            renderMode: 'HWM',
                                        }}
                                        onChange={onChange}
                                        onSubmit={updateContactDetails}
                                    />
                                </div>
                                <div className="details-custom-content">
                                    <DetailsCustomContent
                                        schema={schema.custom}
                                    />
                                </div>
                            </LoadingOverlap>
                        </Column>
                    </Row>
                    <ButtonsBlockRow
                        primaryButton={{
                            text: t('clientDetails.save'),
                            loading: isLoadingUpdateContact,
                            disabled: !isValid,
                            onClick: updateContactDetails,
                        }}
                    >
                        {errorUpdateContact && (
                            <Infobox error>{errorUpdateContact.message}</Infobox>
                        )}
                    </ButtonsBlockRow>
                </ContentBox>
            </Preloader>
        </PageSection>
    );
};

Details.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ dfsClientId: PropTypes.string }),
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({ step: PropTypes.number }),
    }),
};

Details.defaultProps = {
    location: {
        state: {},
    },
};

export default Details;
