import { useCallback, useEffect, useReducer } from 'react';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import SM from 'services/ServiceManager';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import set from 'lodash/set';
import { adaptLists } from '../adapters/adaptLists';

const SCHEMA_LIST_PATH = 'additiv.FormIoCommonDataSource';
const initialState = {
    data: null,
    error: null,
    isLoading: false,
    dataLists: null,
    errorLists: null,
    isLoadingLists: false,
    dataListsKey: null,
    errorListsKey: null,
    isLoadingListsKey: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setDataLists':
            return { ...state, dataLists: action.payload };
        case 'setErrorLists':
            return { ...state, errorLists: action.payload };
        case 'setIsLoadingLists':
            return { ...state, isLoadingLists: action.payload };
        case 'setDataListsKey':
            return { ...state, dataListsKey: action.payload };
        case 'setErrorListsKey':
            return { ...state, errorListsKey: action.payload };
        case 'setIsLoadingListsKey':
            return { ...state, isLoadingListsKey: action.payload };
        default:
            return state;
    }
};

export const useSchema = (options = {}) => {
    const { loadInitially = true } = options;
    const { i18n: { language } } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);

    // Callbacks
    const getSchema = useCallback(async ({ key } = {}) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const response = await SM.clientService('getSchema', [key]);

            dispatch({ type: 'setData', payload: response?.data });
            dispatch({ type: 'setIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);
    const getSchemasLists = useCallback(async () => {
        const dataSource = get(window, SCHEMA_LIST_PATH);

        if (dataSource && dataSource?.language === language) {
            dispatch({ type: 'setDataLists', payload: dataSource?.PureList?.items });

            return dataSource?.PureList;
        }

        dispatch({ type: 'setErrorLists', payload: null });
        dispatch({ type: 'setIsLoadingLists', payload: true });

        try {
            const response = await SM.clientService('getSchemasLists', [language]);
            const { items } = response?.data;

            dispatch({ type: 'setDataLists', payload: items });
            dispatch({ type: 'setIsLoadingLists', payload: false });

            // Side effect to avoid multiple calls
            set(window, SCHEMA_LIST_PATH, {
                Lists: adaptLists(items), PureList: response?.data, language,
            });

            return items;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorLists', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoadingLists', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [language]);
    const getSchemaListByKey = useCallback(async ({ key }) => {
        const existKey = get(window, SCHEMA_LIST_PATH)?.Lists?.[key];

        if (existKey) {
            dispatch({ type: 'setDataListsKey', payload: existKey });

            return existKey;
        }

        dispatch({ type: 'setErrorListsKey', payload: null });
        dispatch({ type: 'setIsLoadingListsKey', payload: true });
        try {
            const { data: { items } } = await SM.clientService('getSchemaListByKey', [key]);

            dispatch({ type: 'setDataListsKey', payload: items });
            dispatch({ type: 'setIsLoadingListsKey', payload: false });

            return items;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorListsKey', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoadingListsKey', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, []);

    const getSchemaLabelById = useCallback((itemId) => {
        const itemFound = (state.dataLists || [])?.find((item) => item?.id === itemId);

        return itemFound?.label || null;
    }, [state.dataLists]);

    useEffect(() => {
        if (loadInitially) getSchemasLists();
    }, [loadInitially, getSchemasLists]);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getSchema,
        dataLists: state.dataLists,
        errorLists: state.errorLists,
        isLoadingLists: state.isLoadingLists,
        getSchemasLists,
        dataListsKey: state.dataListsKey,
        errorListsKey: state.errorListsKey,
        isLoadingListsKey: state.isLoadingListsKey,
        getSchemaListByKey,
        getSchemaLabelById,
    };
};
