import { CellSwitch } from 'components/renderers';
import { sorterString } from 'utils/sorting';

export const getSecuritiesColumns = (t) => ([
    {
        key: 'Name',
        title: t('clientDashboard.portfolio.columns.name'),
        render: CellSwitch,
        width: 300,
        sorter: sorterString('Name.value'),
    },
    {
        key: 'Isin',
        title: t('clientDashboard.portfolio.columns.isin'),
        sortable: true,
        sorter: sorterString('Isin'),
    },
    {
        key: 'AssetClass',
        title: t('clientDashboard.portfolio.columns.assetClass'),
        sortable: true,
        filterable: true,
        width: 145,
        defaultSortOrder: 'ascend',
        sorter: sorterString('AssetClass'),
    },
    {
        key: 'SubAssetClass',
        title: t('clientDashboard.portfolio.columns.subAssetClass'),
        sortable: true,
        sorter: sorterString('SubAssetClass'),
    },
    {
        key: 'Type',
        title: t('clientDashboard.portfolio.columns.type'),
        sortable: true,
        sorter: sorterString('Type'),
    },
    {
        key: 'Currency',
        title: t('clientDashboard.portfolio.columns.currency'),
        width: 100,
        filterable: true,
        sortable: true,
        className: 'text-right',
        sorter: sorterString('Currency'),
    },
]);
