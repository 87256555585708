import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Accordion, AccordionPanel as Panel, ContentBox, Infobox, Table,
} from 'ui-library';
import { useFormatting } from 'locale';
import Preloader from 'components/Preloader';
import history from 'services/history';
import PageHeader from 'components/PageHeader';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import { useClientProfile } from 'prodivers/clientProfile';
import { useModelPortfolio } from 'domain/Portfolio';
import { useProductFee } from 'hooks/useProductFee';
import { useSummaryDocuments } from 'hooks/useSummaryDocuments';
import { getTradesColumns, getDocumentsColumns } from './constants';
import { useNewPortfolioData } from '../../hooks/useNewPortfolioData';
import { adaptPositions } from '../Modify/adapters/adaptModifyPositions';
import { useNewPortfolioLayout } from '../../hooks/useNewPortfolioLayout';
import './Summary.css';
import { adaptPortfolioReviewOverview } from './adapters/adaptPortfolioReviewOverview';
import { OverviewInformation } from '../../../../../../../../components/OverviewInformation';

function Summary(props) {
    useNewPortfolioLayout({ current: 2 });

    const { match: { params: { dfsClientId } } } = props;
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();
    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/new-portfolio`, [dfsClientId]);

    // Client Provider
    const {
        isLoading: isLoadingClientInfo,
    } = useClientProfile();
    // New Portfolio - Local data
    const { newPortfolio, modifiedPositions } = useNewPortfolioData();
    const {
        amount, model, currency, currencyId, productId, productName, strategyName,
    } = useMemo(() => ({
        model: newPortfolio?.model,
        amount: newPortfolio?.portfolioAmount,
        currency: newPortfolio?.portfolioCurrency?.label,
        productId: newPortfolio?.product?.value,
        currencyId: newPortfolio?.portfolioCurrency?.value,
        strategyId: newPortfolio?.strategy?.value,
        productName: newPortfolio?.product?.label,
        strategyName: newPortfolio?.strategy?.label,
    }), [newPortfolio]);

    // Portfolio Domain
    const {
        dataRaw: modelPortfolio, isLoading: isLoadingMP, error: errorMP, getModelPortfolio,
    } = useModelPortfolio();

    // Product fee
    const { fee, isLoadingFee, errorFee } = useProductFee(productId, amount);

    // Summary products
    const { documents, errorDocuments, isLoadingDocuments } = useSummaryDocuments(productId);

    // Data
    const optionsForAdapt = useMemo(
        () => ({
            currency,
            portfolioCurrency: currency,
            portfolioValue: +amount,
            portfolioTotalValue: +amount,
            portfolioSecuritiesValue: +amount,
            productName,
            t,
            getFormattedNumber,
            getFormattedCurrency,
            nameLength: 35,
            positionLink: `${baseUrl}/summary/position`,
        }),
        [currency, amount, productName, baseUrl, t, getFormattedNumber, getFormattedCurrency],
    );
    const positionsModel = useMemo(() => adaptPositions(modelPortfolio?.Positions, optionsForAdapt),
        [modelPortfolio.Positions, optionsForAdapt]);

    // Effects
    useEffect(() => {
        if (modifiedPositions?.raw?.length > 0) return;
        getModelPortfolio(model, { currencyId });
    }, [modifiedPositions, currencyId, getModelPortfolio, model]);

    const portfolioReviewOverviewRows = useMemo(() => adaptPortfolioReviewOverview(
        {
            amount,
            currency,
            t,
            productName,
            fee,
            strategyName,
        },
    ), [
        amount,
        currency,
        t,
        productName,
        fee,
        strategyName,
    ]);

    return (
        <div className="new-portfolio_summary">
            <PageHeader title={`${t('portfolio.createNewPortfolio')} - ${t('onBoarding.review')}`} />
            <ContentBox>
                <Preloader
                    isLoading={isLoadingMP || isLoadingFee || isLoadingClientInfo}
                    error={errorMP || errorFee}
                >
                    <OverviewInformation rowsData={portfolioReviewOverviewRows} columnSize={['sm-6', 'lg-3']} />
                    <Accordion defaultActiveKey={['1', '2']}>
                        <Panel header={t('onBoarding.listOfTrades')} key="1" className="table-without-last-row-padding" dataTestId="list-of-trades">
                            <Infobox accent>{t('onBoarding.review.tradesDisclaimer')}</Infobox>
                            <Table
                                className="positions-table"
                                data={modifiedPositions?.positions?.length > 0
                                    ? modifiedPositions?.positions : positionsModel}
                                columns={getTradesColumns(t)}
                                expandedColumn="name"
                                defaultExpandAllRows
                            />
                        </Panel>
                        <Panel header={t('onBoarding.documents')} key="2" className="documents table-without-last-row-padding" dataTestId="documents">
                            <Preloader isLoading={isLoadingDocuments} error={errorDocuments}>
                                <Table
                                    columns={getDocumentsColumns(t)}
                                    data={documents}
                                />
                            </Preloader>
                        </Panel>
                    </Accordion>
                </Preloader>
                <ButtonsBlockRow
                    leftButton={{
                        text: t('confirmation.back'),
                        onClick: () => history.push(`${baseUrl}/modify`),
                    }}
                    primaryButton={{
                        text: t('portfolio.continue'),
                        onClick: () => history.push(`${baseUrl}/interaction`),
                    }}
                />
            </ContentBox>
        </div>
    );
}

Summary.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

Summary.defaultProps = {
};

export default Summary;
