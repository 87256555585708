import { dateTimeSecondsFormatSlashes, formatDate } from 'utils/datetime';

export const formatOriginalMessage = (data) => {
    let originalMessage = '';

    data.forEach((item) => {
        originalMessage += `Sender: ${item.SenderFullName} \nDate: ${formatDate(item.CreateDate, dateTimeSecondsFormatSlashes)}
        \n${item.Text} \n ----------------------------------------------------------- \n`;
    });

    return originalMessage;
};
