import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChangeStrategy from './ChangeStrategy';
import Interaction from './pages/Interaction';
import DetailedOrders from './pages/DetailedOrders';

const Router = (props) => {
    const { match: { path } } = props;
    const withProps = (Component) => (defProps) => <Component {...props} {...defProps} />;

    return (
        <Switch>
            <Route exact path={`${path}`} render={withProps(ChangeStrategy)} />
            <Route exact path={`${path}/interaction`} render={withProps(Interaction)} />
            <Route exact path={`${path}/orders`} render={withProps(DetailedOrders)} />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
