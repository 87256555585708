import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { formatDate } from 'utils/datetime';
import { groupComparedPositions } from 'adaptors/groupComparedPositions';
import { useModelPortfolio } from 'domain/Portfolio';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { usePortfolioProposal } from './usePortfolioProposal';
import { adaptReviewWithProposal } from '../adapters/adaptReviewWithProposal';

export const useReviewWithProposal = (
    contactId, proposalIdDefault,
    { reviewData, isLoadingReviewData, errorReview },
    optionsAdditional,
) => {
    const [proposalId, setProposalId] = useState();
    const { getGoalDetails, getGoalChanges } = useOnBoardingSelector(onBoardingDataSelector);

    const goalDetails = useMemo(() => getGoalDetails(), [getGoalDetails]);
    const goalChanges = useMemo(() => (Number.isInteger(+getGoalChanges())
        ? getGoalChanges() : undefined), [getGoalChanges]);

    const {
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
    } = useFormatting();

    const { t } = useTranslation();

    const projectionYears = formatDate(goalDetails.targetDate, 'YYYY') - formatDate(new Date(), 'YYYY');
    const options = useMemo(
        () => (isLoadingReviewData ? null : {
            currency: goalDetails.selectedCurrency,
            currencyId: goalDetails?.selectedCurrency?.value,
            portfolioValue: +goalDetails.initialInvestment,
            portfolioSecuritiesValue: +goalDetails.initialInvestment,
            recurringPayment: +goalDetails.recurringPayment,
            comparedAllocations: reviewData.allocationGroups,
            projectionYears,
            t,
            getFormattedDate,
            getFormattedNumber,
            getFormattedCurrency,
            ...optionsAdditional,
        }),
        [
            isLoadingReviewData,
            reviewData.allocationGroups,
            goalDetails.selectedCurrency,
            goalDetails.initialInvestment,
            goalDetails.recurringPayment,
            projectionYears,
            t,
            getFormattedDate,
            getFormattedNumber,
            getFormattedCurrency,
            optionsAdditional,
        ],
    );

    const { proposalData, isLoadingProposal, errorLoadingProposal } = usePortfolioProposal(
        contactId,
        proposalId,
        adaptReviewWithProposal,
        options,
    );
    const {
        data, isLoading: isLoadingModelData, error: errorModelData, getModelPortfolio,
    } = useModelPortfolio({ adaptOptions: options });

    useEffect(() => {
        if (isLoadingReviewData) return;
        if (goalChanges) {
            getModelPortfolio(goalChanges);
        } else if (proposalIdDefault) {
            setProposalId(proposalIdDefault);
        }
    }, [proposalIdDefault, getModelPortfolio, goalChanges, isLoadingReviewData]);

    const reviewDataAdapted = useMemo(() => ({
        ...reviewData,
        ...data,
        ...proposalData,
        overview: {
            ...reviewData?.overview,
            ...data?.overview,
            ...proposalData?.overview,
            product: reviewData?.productName,
            strategy: data?.overview?.strategy || reviewData?.overview?.strategy,
        },
        product: reviewData?.productId,
    }), [data, reviewData, proposalData]);
    const positions = useMemo(() => groupComparedPositions({
        positions: reviewData?.positionsRaw || [],
        modelPositions: data?.Positions
            || proposalData?.positionsRaw
            || reviewData?.positionsRaw,
        portfolioCurrency: reviewData?.currency,
        t,
        portfolioTotalValue: reviewData?.portfolioValue,
        getFormattedNumber,
        getFormattedCurrency,
        ...optionsAdditional,
    }), [reviewData, proposalData, data, optionsAdditional]);

    return {
        isLoading: isLoadingProposal || isLoadingReviewData || isLoadingModelData,
        error: errorLoadingProposal || errorReview || errorModelData,
        reviewData: reviewDataAdapted,
        positions,
    };
};
