import React from 'react';
import PropTypes from 'prop-types';
import Preloader from 'components/Preloader';
import PageNotFound from 'components/PageError/PageNotFoundContent';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject,
} from 'constants/portfolioProducts';
import { usePortfolio } from 'domain/Portfolio';
import ChangeStrategyAdvisory from './products/ChangeStrategyAdvisory';
import ChangeStrategyDiscretionary from './products/ChangeStrategyDiscretionary';
import './ChangeStrategy.css';

const PortfolioByProduct = generateObject({
    [ADVISORY]: ChangeStrategyAdvisory,
    [DISCRETIONARY]: ChangeStrategyDiscretionary,
    [EXECUTION_ONLY]: PageNotFound,
});

function ChangeStrategy(props) {
    const { portfolio: { productId, error } } = usePortfolio();
    const PortfolioPage = PortfolioByProduct[productId] || (() => '');

    return (
        <Preloader isLoading={productId === undefined} error={error}>
            <PortfolioPage {...props} />
        </Preloader>
    );
}

ChangeStrategy.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

ChangeStrategy.defaultProps = {
};

export default ChangeStrategy;
