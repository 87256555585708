import {
    useCallback, useEffect, useMemo, useReducer,
} from 'react';
import RDom from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { getPeriodByFilter } from 'utils';
import { getDatesDifference } from 'utils/datetime';
import {
    formatWithDate,
} from 'utils/formatting';
import { YTD } from 'filters/investementPerformanceFilters';
import { LINE, RISKRETURN } from 'constants/constants';
import RiskReturnTooltip from 'components/RiskReturnTooltip';
import { adaptPlot, adaptRiskCompare } from 'adaptors/adaptPerformanceAndRiskCompare';
import { useFormatting } from '../locale';

let cancelPortfolio = () => {};
let cancelStrategy = () => {};
let cancelBenchmark = () => {};

const MAX_DAYS = 3651;

const setError = 'setError';
const setPerformanceTime = 'setPerformanceTime';
const setPerformanceTimePortfolio = 'setPerformanceTimePortfolio';
const setPerformanceTimeForAdapter = 'setPerformanceTimeForAdapter';
const setPerformanceTimeBenchmark = 'setPerformanceTimeBenchmark';
const setIsLoadingPortfolio = 'setIsLoadingPortfolio';
const setIsLoadingStrategy = 'setIsLoadingStrategy';
const setIsLoadingBenchmark = 'setIsLoadingBenchmark';
const setPortfolioData = 'setPortfolioData';
const setStrategyData = 'setStrategyData';
const setBenchmarkData = 'setBenchmarkData';

const initialState = {
    error: null,
    performanceTime: null,
    performanceTimePortfolio: null,
    performanceTimeForAdapter: null,
    performanceTimeBenchmark: null,
    isLoadingPortfolio: true,
    isLoadingStrategy: false,
    isLoadingBenchmark: true,
    portfolioData: null,
    strategyData: null,
    benchmarkData: null,
};
const reducer = (state, action) => {
    switch (action.type) {
        case setError:
            return { ...state, error: action.payload };
        case setPerformanceTime:
            return { ...state, performanceTime: action.payload };
        case setPerformanceTimePortfolio:
            return { ...state, performanceTimePortfolio: action.payload };
        case setPerformanceTimeForAdapter:
            return { ...state, performanceTimeForAdapter: action.payload };
        case setPerformanceTimeBenchmark:
            return { ...state, performanceTimeBenchmark: action.payload };
        case setIsLoadingPortfolio:
            return { ...state, isLoadingPortfolio: action.payload };
        case setIsLoadingStrategy:
            return { ...state, isLoadingStrategy: action.payload };
        case setIsLoadingBenchmark:
            return { ...state, isLoadingBenchmark: action.payload };
        case setPortfolioData:
            return { ...state, portfolioData: action.payload };
        case setStrategyData:
            return { ...state, strategyData: action.payload };
        case setBenchmarkData:
            return { ...state, benchmarkData: action.payload };
        default: return state;
    }
};

export const usePerformanceAndRiskCompare = ({
    clientId, portfolio, strategy, currencyId, benchmark, additionalOptions, isLoading,
}) => {
    const {
        isEdit,
        usePortfolioPerformance,
        usePortfolioAllocations,
    } = (additionalOptions || {});

    const [state, dispatch] = useReducer(reducer, initialState);
    const { t, i18n: { language } } = useTranslation();
    const { getFormattedNumber, getFormattedDate } = useFormatting();

    // Optional
    const yAxisFormat = useCallback(({ value }) => `${getFormattedNumber(value)}%`,
        [getFormattedNumber]);
    const tooltipFormat = useCallback((number, date) => formatWithDate(`${getFormattedNumber(number)}%`, date, getFormattedDate),
        [getFormattedDate, getFormattedNumber]);
    const tooltipFormatRisk = useCallback((name, x, y) => RDom.renderToString(RiskReturnTooltip({
        x, y, t, getFormattedNumber,
    })), [getFormattedNumber, t]);
    const startDate = useMemo(() => getPeriodByFilter(state.performanceTime).startDate,
        [state.performanceTime]);
    const endDate = useMemo(() => getPeriodByFilter(state.performanceTime).endDate,
        [state.performanceTime]);
    const setKPIParams = useCallback((options, days) => ({
        InstrumentSet: {
            ...options,
            CurrencyId: currencyId,
        },
        Days: (!startDate && days) || (startDate ? getDatesDifference(new Date(), startDate, 'days') : MAX_DAYS),
        CalculationInterval: 'Monthly',
    }), [startDate, currencyId]);

    // Callbacks
    const getPortfolioPerformance = useCallback(async (startDateNew) => {
        const daysNew = startDateNew ? getDatesDifference(new Date(), startDateNew, 'days') : undefined;

        if (!portfolio?.currencyId) return null;
        try {
            cancelPortfolio();
            dispatch({ type: setIsLoadingPortfolio, payload: true });

            if (usePortfolioPerformance) {
                const params = {};

                if (startDate || startDateNew) params.from = startDate || startDateNew;
                if (endDate) params.to = endDate;
                const request = SM.portfolioManagement('getPortfolioDetailsPerformance', [portfolio?.id, clientId, { language, ...params }, { withCancel: true }]);

                cancelPortfolio = request?.[1];
                const response = await request[0];
                const firstDPortfolio = response?.data?.Values?.[0]?.Date;

                dispatch({
                    type: setPortfolioData, payload: { HistoricalPerformance: [response?.data] },
                });
                if (!startDate && MAX_DAYS > getDatesDifference(new Date(), firstDPortfolio, 'days')) {
                    dispatch({
                        type: setPerformanceTimePortfolio,
                        payload: firstDPortfolio,
                    });
                }
                dispatch({ type: setIsLoadingPortfolio, payload: false });

                return { HistoricalPerformance: [response?.data] };
            }

            const options = {};

            if (portfolio?.proposalId) {
                options.PortfolioProposalId = portfolio.proposalId;
            } else if (!portfolio.modelPortfolioId || usePortfolioAllocations) {
                options.Allocations = portfolio?.investmentAllocations;
            } else {
                options.ModelPortfolioId = portfolio?.modelPortfolioId;
            }

            const params = setKPIParams(options, daysNew);

            setTimeout(async () => {
                const request = SM.performanceService('calculateKPIPerformance', [params, { withCancel: true }]);

                cancelPortfolio = request?.[1];
                const response = await request[0];

                dispatch({ type: setPortfolioData, payload: response?.data });
                if (!startDate) {
                    dispatch({
                        type: setPerformanceTimeForAdapter,
                        payload: response?.data?.HistoricalPerformance?.[0]?.Values?.[0]?.Date,
                    });
                }
                dispatch({ type: setIsLoadingPortfolio, payload: false });

                return response?.data;
            }, 1000);
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (e) => dispatch({ type: setError, payload: e }),
                    setLoading: () => dispatch({ type: setIsLoadingPortfolio, payload: false }),
                }),
            )(err);
        }

        return null;
    }, [
        portfolio,
        usePortfolioPerformance,
        usePortfolioAllocations,
        setKPIParams,
        startDate,
        clientId,
        language,
    ]);
    const getStrategyPerformance = useCallback(async (startDateNew) => {
        const daysNew = startDateNew ? getDatesDifference(new Date(), startDateNew, 'days') : undefined;

        if (!strategy?.currencyId || !strategy?.investmentAllocations?.length || !currencyId) {
            dispatch({ type: setStrategyData, payload: null });


            return null;
        }
        try {
            cancelStrategy();
            dispatch({ type: setIsLoadingStrategy, payload: true });
            const options = {};

            if (strategy?.proposalId) {
                options.PortfolioProposalId = strategy.proposalId;
            } else if (isEdit) {
                options.Allocations = strategy?.investmentAllocations;
            } else {
                options.ModelPortfolioId = strategy?.modelPortfolioId;
            }

            const params = setKPIParams(options, daysNew);
            const request = await SM.performanceService('calculateKPIPerformance', [params, { withCancel: true }]);

            cancelStrategy = request?.[1];
            const response = await request[0];

            dispatch({ type: setStrategyData, payload: response?.data });
            dispatch({ type: setIsLoadingStrategy, payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (e) => dispatch({ type: setError, payload: e }),
                    setLoading: () => dispatch({ type: setIsLoadingStrategy, payload: false }),
                }),
            )(err);
        }

        return null;
    }, [dispatch, setKPIParams, strategy, isEdit]);
    const getBenchmarkPerformance = useCallback(async (startDateNew) => {
        const daysNew = startDateNew ? getDatesDifference(new Date(), startDateNew, 'days') : undefined;

        if (!benchmark?.id) return null;
        try {
            cancelBenchmark();
            dispatch({ type: setIsLoadingBenchmark, payload: true });
            const params = setKPIParams({
                Allocations: [{ InstrumentId: benchmark?.id, Allocation: 1 }],
            }, daysNew);
            const request = await SM.performanceService('calculateKPIPerformance', [params, { withCancel: true }]);

            cancelBenchmark = request?.[1];
            const response = await request[0];
            const firstDBenchmark = response?.data?.HistoricalPerformance?.[0]?.Values?.[0]?.Date;

            if (!startDate && (daysNew || MAX_DAYS) > getDatesDifference(new Date(), firstDBenchmark, 'days')) {
                dispatch({
                    type: setPerformanceTimeBenchmark,
                    payload: firstDBenchmark,
                });
            }
            dispatch({ type: setBenchmarkData, payload: response?.data });
            dispatch({ type: setIsLoadingBenchmark, payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (e) => dispatch({ type: setError, payload: e }),
                    setLoading: () => dispatch({ type: setIsLoadingBenchmark, payload: false }),
                }),
            )(err);
        }

        return null;
    }, [dispatch, setKPIParams, benchmark?.id]);

    const onPerformanceChange = useCallback((param) => {
        dispatch({ type: setError, payload: null });
        dispatch({ type: setPerformanceTime, payload: param });
        dispatch({ type: setPerformanceTimePortfolio, payload: null });
        dispatch({ type: setPerformanceTimeForAdapter, payload: null });
        dispatch({ type: setPerformanceTimeBenchmark, payload: null });
    }, []);

    // Effects
    useEffect(() => {
        dispatch({
            type: setPerformanceTime, payload: YTD,
        });
    }, []);
    useEffect(() => {
        getPortfolioPerformance(state.performanceTimeBenchmark);
    }, [getPortfolioPerformance, state.performanceTimeBenchmark]);
    useEffect(() => {
        getStrategyPerformance(state.performanceTimePortfolio || state.performanceTimeBenchmark);
    }, [getStrategyPerformance, state.performanceTimePortfolio, state.performanceTimeBenchmark]);
    useEffect(() => {
        getBenchmarkPerformance(state.performanceTimePortfolio);
    }, [getBenchmarkPerformance, state.performanceTimePortfolio]);

    useEffect(() => {
        if (!isLoading && !benchmark?.id) {
            dispatch({ type: setIsLoadingBenchmark, payload: isLoading });
        }
    }, [isLoading, benchmark?.id]);

    // Data to set
    const volatility = useMemo(() => state.strategyData?.Volatility,
        [state.strategyData]);
    const lineChartPortfolio = useMemo(() => adaptPlot({
        ...state.portfolioData, startDate, endDate,
    }), [state.portfolioData, startDate, endDate]);
    const lineChartStrategy = useMemo(() => adaptPlot({
        ...state.strategyData, startDate: startDate || state.performanceTimeForAdapter, endDate,
    }), [state.strategyData, state.performanceTimeForAdapter, startDate, endDate]);
    const lineChartBenchmark = useMemo(() => adaptPlot({
        ...state.benchmarkData, startDate: startDate || state.performanceTimeForAdapter, endDate,
    }), [state.benchmarkData, state.performanceTimeForAdapter, startDate, endDate]);
    const lineChart = useMemo(() => {
        const data = [
            {
                name: portfolio?.performanceTitle || t('clientDashboard.portfolio.current'),
                data: lineChartPortfolio?.data,
            },
        ];

        if (benchmark) {
            data.push(
                {
                    type: 'line',
                    name: benchmark?.name || '',
                    data: lineChartBenchmark?.data,
                },
            );
        }

        if (strategy) {
            data.splice(1, 0, {
                type: 'line',
                name: strategy?.performanceTitle || t('clientDashboard.portfolio.new'),
                data: lineChartStrategy?.data,
            });
        }

        return { data, optional: { tooltipFormat, yAxisFormat } };
    }, [
        portfolio?.performanceTitle,
        strategy,
        benchmark?.name,
        lineChartPortfolio,
        lineChartStrategy,
        lineChartBenchmark,
        tooltipFormat,
        yAxisFormat,
    ]);
    const riskReturn = useMemo(() => adaptRiskCompare(portfolio, state.strategyData, {
        tooltipFormat: tooltipFormatRisk,
        t,
        getFormattedNumber,
        benchmark,
        strategyTitle: strategy?.riskReturnTitle,
        matrix: strategy?.riskReturnMatrix || portfolio?.riskReturnMatrix,
    }), [
        portfolio,
        state.strategyData,
        strategy?.riskReturnTitle,
        strategy?.riskReturnMatrix,
        tooltipFormatRisk,
        t,
        getFormattedNumber,
        benchmark,
    ]);

    return {
        performanceAndRisk: {
            [LINE]: {
                ...lineChart,
                error: state.error,
                isLoading: state.isLoadingPortfolio
                    || state.isLoadingStrategy
                    || state.isLoadingBenchmark,
            },
            [RISKRETURN]: {
                ...riskReturn,
                error: state.error,
                isLoading: state.isLoadingBenchmark || state.isLoadingBenchmark,
            },
        },
        errorPerformanceAndRisk: state.error,
        isLoadingPerformanceAndRisk: state.isLoadingPortfolio
            || state.isLoadingStrategy
            || state.isLoadingBenchmark,
        volatility,
        performanceSelected: state.performanceTime,
        onPerformanceChange,
    };
};
