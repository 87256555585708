import {
    getfromDataObject,
    NA, percentIsZero,
    validateStringData,
} from 'utils/formatting';
import { isLiquidity } from '../pages/common/utils';
import { ORDER_TYPE } from '../pages/common/constants';

export const adaptSecurityTransactions = (
    {
        data, portfolioId, productID, dfsClientId, actionType, t,
        getFormattedDate, getFormattedNumber, getFormattedCurrency, linkToOverview,
    },
) => {
    const transactions = data.OrderBookEntries || [];
    const groupToValues = transactions.reduce((obj, {
        CreateDate, FxRate, Security, OrderType, AmountSecurityCurrency, Quantity, Price,
    }) => {
        const type = t(ORDER_TYPE[getfromDataObject(OrderType, 'Id')]);
        const link = isLiquidity(Security)
            ? undefined
            : `/client/${dfsClientId}/portfolios/${portfolioId}/${actionType ? `${actionType}/` : ''}position/${productID}/${Security.Id}/2${linkToOverview ? '/overview' : ''}`;
        const accumulator = { ...obj };

        accumulator[type] = obj[type] || [];
        accumulator[type].push({
            Name: {
                label: validateStringData(Security.Name),
                link,
                maxLength: 40,
            },
            Date: getFormattedDate(CreateDate),
            Id: Security.Id,
            Number: Quantity ? getFormattedNumber(Math.abs(Quantity), {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }) : NA,
            Price: Price ? getFormattedNumber(Price, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }) : NA,
            Type: type,
            FxRate: getFormattedNumber(FxRate, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 4,
            }),
            Total: getFormattedCurrency(Math.abs(AmountSecurityCurrency), {
                currency: Security.Currency.CurrencyCode,
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            Fee: `${percentIsZero(0.1)}%`, // TODO: Remove hardcoded fee (AT-1527)
        });

        return accumulator;
    }, {});

    return {
        confirmationData: Object.keys(groupToValues).map((key) => {
            const groupMembersData = groupToValues[key];

            return {
                Name: key,
                children: groupMembersData,
            };
        }),
    };
};
