import { renderers } from 'ui-library';
import {
    CellInputText, CellWithPlusMinusIcons, CellSwitch, CellPercent,
} from 'components/renderers';
import { sorterNumber, sorterString } from 'utils/sorting';

const { CellWithIcon } = renderers;

export const getQuickColumns = (t, getFormattedNumber, numberSeparators) => ([
    {
        key: 'Name',
        title: t('clientDashboard.portfolio.columns.name'),
        sortable: true,
        sorter: sorterString('Name'),
        width: 350,
        minWidth: 350,
        className: 'with-link',
        render: CellSwitch,
        defaultSortOrder: 'descending',
    },
    {
        key: 'ISIN',
        title: t('clientDashboard.portfolio.columns.isin'),
        width: 120,
        minWidth: 120,
        hidden: true,
        sortable: true,
        sorter: sorterString('ISIN'),
    },
    {
        key: 'Value',
        title: t('clientDashboard.portfolio.columns.valueData'),
        className: 'text-right',
        width: 180,
        minWidth: 180,
        sorter: sorterNumber('Value'),
    },
    {
        key: 'Units',
        title: t('clientDashboard.portfolio.columns.units'),
        className: 'text-right',
        hidden: true,
        width: 100,
        minWidth: 100,
        sorter: sorterNumber('Units'),
    },
    {
        key: 'Currency',
        title: t('clientDashboard.portfolio.columns.currency'),
        hidden: true,
        width: 100,
        minWidth: 100,
        sortable: true,
        sorter: sorterString('Currency'),
    },
    {
        key: 'LatestPrice',
        title: t('clientDashboard.portfolio.columns.latestPrice'),
        className: 'text-right',
        hidden: true,
        width: 150,
        minWidth: 150,
        sorter: sorterNumber('LatestPrice'),
    },
    {
        title: t('clientDashboard.portfolio.columns.valuationInSecurityCurrency'),
        className: 'text-right',
        key: 'ValuationInSecurityCurrency',
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterNumber('ValuationInSecurityCurrency'),
    },
    {
        title: t('clientDashboard.portfolio.columns.unrealizedGainLoss'),
        key: 'UnrealizedGainLoss',
        className: 'text-right',
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterNumber('UnrealizedGainLoss'),
    },
    {
        key: 'Performance',
        title: t('clientDashboard.portfolio.columns.performanceSI'),
        className: 'text-right',
        render: CellPercent(getFormattedNumber),
        width: 153,
        minWidth: 153,
        sorter: sorterNumber('Performance'),
    },
    {
        key: 'Allocation',
        title: t('clientDashboard.portfolio.columns.allocation'),
        className: 'text-right',
        width: 100,
        minWidth: 100,
        sorter: sorterNumber('Allocation'),
    },
    {
        key: 'New',
        title: t('clientDashboard.portfolio.columns.new'),
        className: 'with-input text-right',
        render: CellInputText(numberSeparators),
        width: 135,
        minWidth: 135,
        sorter: sorterNumber('New.value'),
    },
    {
        key: 'Icon',
        title: '',
        render: CellWithPlusMinusIcons,
        width: 70,
        minWidth: 70,
    },
    {
        key: 'Actions',
        title: '',
        headerRenderer: CellWithIcon,
        width: 30,
        minWidth: 30,
        titleType: 'columns',
    },
]);
