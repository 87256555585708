import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptData } from '../adapters/adaptData';

const initialState = {
    isLoadingCurrency: true,
    errorCurrency: null,
    dataCurrency: [],
};

function reducer(state, action) {
    switch (action.type) {
        case 'setCurrency':
            return {
                ...state,
                isLoadingCurrency: action.payload.isLoading,
                dataCurrency: action.payload.data,
            };

        case 'setErr':
            return {
                ...state,
                errorCurrency: action.payload.error,
            };
        case 'setIsLoading':
            return {
                ...state,
                isLoadingCurrency: action.payload.isLoading,
            };

        default: return state;
    }
}


export const useCurrencies = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { i18n } = useTranslation();

    const setError = (error) => {
        dispatch({ type: 'setErr', payload: { error } });
    };
    const setIsLoading = (isLoading) => {
        dispatch({ type: 'setIsLoading', payload: { isLoading } });
    };

    useEffect(() => {
        ServiceManager.currenciesService('getCurrencies', [i18n.language])
            .then((response) => {
                try {
                    const data = adaptData(response.data);

                    dispatch({
                        type: 'setCurrency',
                        payload: { data, isLoading: false },
                    });
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading: setIsLoading }),
            ));
    }, [i18n.language]);

    return state;
};
