/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
    useCallback, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import {
    DatePicker,
    NumberInput, Select,
    TextInput, Title, Toggle,
} from 'ui-library';
import { Controller } from 'react-hook-form';
import ContentBox from 'ui-library/dist/components/ContentBox';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import {
    getDateFormat, formatDate, compareDates, subtractUnits, isWeekendDay,
} from 'utils/datetime';
import { datePickerLocale } from 'constants/constants';
import { useLocale } from 'locale';
import PropTypes from 'prop-types';
import { useDomesticForm } from './hooks/useDomesticForm';
import { usePaymentsData } from '../../hooks/usePaymentsData';
import { executionDateDefault } from '../../utils';

const DomesticForm = ({
    listCurrencies, cashAccounts, onCancel, onNext, listCountries,
}) => {
    useCancelAllRequests();

    const { t } = useTranslation();
    const { locale, numberSeparators } = useLocale();
    const { getPayment, savePayment } = usePaymentsData();
    const defaultData = getPayment(1);

    const {
        control, formErrors, values, onChange,
        handleSubmit, register, showBankDetails, setShowBankDetails,
    } = useDomesticForm({ defaultData, defaultShowBankDetails: defaultData?.showBankDetails });

    const defaultCurrency = useMemo(() => listCurrencies?.find((item) => item.label === 'USD'), [listCurrencies]);

    useEffect(() => {
        if (!defaultData?.currency?.value) onChange('currency')(defaultCurrency);
    }, [defaultCurrency, defaultData?.currency?.value, onChange]);

    const amountSelectAfter = useMemo(() => {
        if (listCurrencies.length > 0) {
            return (
                <Controller
                    name="currency"
                    control={control}
                    render={({ value }) => (
                        <Select
                            options={listCurrencies}
                            value={value}
                            error={formErrors.currency}
                            onChange={(val) => onChange('currency')(
                                listCurrencies.find((item) => item.value === val),
                            )}
                        />
                    )}
                />

            );
        }

        return null;
    }, [listCurrencies, control, onChange, formErrors.currency]);

    const onSubmit = useCallback(() => {
        savePayment({
            1: { ...values, showBankDetails },
        });
        onNext();
    }, [showBankDetails, values]);

    return (
        <div className="DomesticForm">
            <ContentBox>
                <div className="payment-content">
                    <header className="on-boarding_header">
                        <Title type={2}>{t('payments.domesticPayment')}</Title>
                    </header>
                    <div>
                        <div className="credit-account">
                            <div className="group-title">
                                <Title type={3}>{t('payments.creditAccount')}</Title>
                            </div>
                            <Controller
                                name="iban"
                                control={control}
                                label={t('payments.iban')}
                                labelInfobox
                                onChange={onChange('iban')}
                                error={formErrors.iban}
                                value={values.iban}
                                required
                                as={<TextInput />}

                            />
                            <div className="toggle-block">
                                <span>{t('payments.payeeBankDetails')}</span>
                                <Toggle
                                    value={showBankDetails}
                                    onChange={setShowBankDetails}
                                />
                            </div>
                            {showBankDetails && (
                                <>
                                    <Controller
                                        name="bankName"
                                        control={control}
                                        as={<TextInput />}
                                        label={t('payments.bankName')}
                                        labelInfobox
                                        onChange={onChange('bankName')}
                                        error={formErrors.bankName}
                                        value={values.bankName}
                                        required
                                    />
                                    <div className="one-line">
                                        <Controller
                                            name="bankPostCode"
                                            control={control}
                                            as={<TextInput />}
                                            label={t('payments.postcode')}
                                            labelInfobox
                                            onChange={onChange('bankPostCode')}
                                            error={formErrors.bankPostCode}
                                            value={values.bankPostCode}
                                            required
                                        />
                                        <Controller
                                            name="bankCity"
                                            control={control}
                                            as={<TextInput />}
                                            label={t('payments.city')}
                                            labelInfobox
                                            onChange={onChange('bankCity')}
                                            error={formErrors.bankCity}
                                            value={values.bankCity}
                                            required
                                        />
                                    </div>
                                    <Controller
                                        control={control}
                                        name="bankCountry"
                                        render={() => (
                                            <Select
                                                ref={register}
                                                name="bankCountry"
                                                value={values.bankCountry}
                                                error={formErrors.bankCountry}
                                                options={listCountries}
                                                label={t('payments.country')}
                                                onChange={(val) => onChange('bankCountry')(
                                                    listCountries?.find(
                                                        (item) => item.value === val,
                                                    ),
                                                )}
                                                hasSearch
                                                required
                                            />
                                        )}
                                    />
                                </>
                            )}
                        </div>
                        <div className="payee">
                            <div className="group-title">
                                <Title type={3}>{t('payments.payee')}</Title>
                            </div>
                            <Controller
                                name="payeeName"
                                control={control}
                                as={<TextInput />}
                                label={t('payments.nameOfPayee')}
                                labelInfobox
                                onChange={onChange('payeeName')}
                                error={formErrors.payeeName}
                                value={values.payeeName}
                                required
                            />
                        </div>
                        <div className="payment-details">
                            <div className="group-title">
                                <Title type={3}>{t('payments.paymentDetails')}</Title>
                            </div>
                            <div className="amount">
                                <Controller
                                    name="amount"
                                    control={control}
                                    render={() => (
                                        <NumberInput
                                            addonAfter={amountSelectAfter}
                                            label={t('payments.amount')}
                                            labelInfobox
                                            onChange={onChange('amount')}
                                            error={formErrors.amount}
                                            value={values.amount}
                                            withSelectBox
                                            required
                                            {...numberSeparators}
                                        />
                                    )}
                                />
                            </div>
                            <Controller
                                name="executionDate"
                                control={control}
                                defaultValue={executionDateDefault()}
                                render={() => (
                                    <DatePicker
                                        label={t('payments.executionDate')}
                                        name="executionDate"
                                        value={values.executionDate ? formatDate(
                                            new Date(values.executionDate),
                                            getDateFormat(locale),
                                        ) : undefined}
                                        popupPosition="top"
                                        onChange={onChange('executionDate')}
                                        withInfobox={false}
                                        format={getDateFormat(locale)}
                                        labels={datePickerLocale(t, locale)}
                                        error={formErrors.executionDate}
                                        disabledDate={(current) => current
                                            && (compareDates(subtractUnits(new Date(), 1, 'minutes'), current) || isWeekendDay(current))}
                                    />
                                )}
                            />
                            <Controller
                                name="personalNote"
                                control={control}
                                as={<TextInput />}
                                label={t('payments.personalNote')}
                                labelInfobox
                                onChange={onChange('personalNote')}
                                error={formErrors.personalNote}
                                value={values.personalNote}
                            />
                            <Controller
                                name="messageForPayee"
                                control={control}
                                label={t('payments.messageForPayee')}
                                labelInfobox
                                onChange={onChange('messageForPayee')}
                                error={formErrors.messageForPayee}
                                value={values.messageForPayee}
                                as={<TextInput />}
                            />
                        </div>
                        <div className="debit-account">
                            <div className="group-title">
                                <Title type={3}>{t('payments.debitAccount')}</Title>
                            </div>
                            <Select
                                name="debitAccount"
                                ref={register}
                                label={t('payments.debitAccount')}
                                options={cashAccounts}
                                value={values.debitAccount}
                                error={formErrors.debitAccount}
                                onChange={(val) => onChange('debitAccount')(
                                        cashAccounts?.find((item) => item.value === val),
                                )}
                                required
                            />
                        </div>
                    </div>
                </div>
            </ContentBox>
            <ButtonsBlockRow
                additionalButton={{
                    text: t('payments.cancel'),
                    onClick: onCancel,
                    type: 'danger',
                }}
                primaryButton={{
                    text: t('confirmation.continue'),
                    onClick: handleSubmit(onSubmit),
                }}
            />
        </div>
    );
};

DomesticForm.propTypes = {
    listCurrencies: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    cashAccounts: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    onCancel: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    listCountries: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
};

DomesticForm.defaultProps = {
    listCurrencies: null,
    cashAccounts: null,
    listCountries: null,
};

export default DomesticForm;
