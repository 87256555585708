import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ location: { pathname } }) => {
    useEffect(() => {
        global.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

ScrollToTop.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

export default withRouter(ScrollToTop);
