const useResizeLocalStorage = (nameWidget) => {
    const getResizeHeight = () => {
        try {
            const { resizeHeight = {} } = JSON.parse(
                global.localStorage.getItem('resize-height'),
            ) || {};

            return resizeHeight || {};
        } catch (e) {
            console.warn(e);


            return {};
        }
    };
    const getSize = () => {
        const resizeHeight = getResizeHeight();

        if (resizeHeight[nameWidget] && resizeHeight[nameWidget].itemsToResize) {
            return resizeHeight[nameWidget].itemsToResize;
        }


        return null;
    };

    const setSize = (itemsToResize) => {
        const resizeHeight = getResizeHeight();


        resizeHeight[nameWidget] = { itemsToResize };
        global.localStorage.setItem('resize-height', JSON.stringify(
            { resizeHeight },
        ));
    };

    return [getSize(), setSize];
};

export default useResizeLocalStorage;
