import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Textarea, Title } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { getClassNames } from 'utils';
import FieldControl from 'components/FieldControl/FieldControl';

import './Comment.css';

const Comment = ({
    showHelpText, onChange, value: valueProp, editable, helpText, register, error, className,
}) => {
    const { t } = useTranslation();
    const classNames = getClassNames('comment', className);

    return (
        <div className={classNames}>
            {showHelpText && (
                <>
                    <Title type={3}>{t('interaction.comments')}</Title>
                    <Paragraph type="secondary">{helpText || t('interaction.commentsDescription')}</Paragraph>
                </>
            )}
            <FieldControl
                label={t('interaction.comment')}
                value={valueProp}
                editable={editable}
                editComponent={({ value, label }) => (
                    <Textarea
                        value={value}
                        label={label}
                        ref={register}
                        name="comment"
                        error={error}
                        helpText={t('interaction.commentsHelpText')}
                        placeholder={t('interaction.commentsPlaceholder')}
                        onChange={onChange}
                        labelInfobox
                    />
                )}
            />
        </div>
    );
};

Comment.propTypes = {
    register: PropTypes.func,
    showHelpText: PropTypes.bool,
    editable: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    helpText: PropTypes.string,
    error: PropTypes.string,
    className: PropTypes.string,
};

Comment.defaultProps = {
    register: null,
    showHelpText: true,
    editable: false,
    onChange: () => {},
    value: '',
    helpText: null,
    error: null,
    className: undefined,
};

export default Comment;
