import { useCallback, useState } from 'react';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';
import { formatDate } from 'utils/datetime';
import ServiceManager from 'services/ServiceManager';

export const useCreateProspect = () => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const createProspect = useCallback(async (data) => {
        try {
            setError(null);
            setLoading(true);

            const registerParams = {
                ContactType: 'Private',
                Gender: data.gender,
                FirstName: data.firstName,
                LastName: data.lastName,
                MobilePhoneNumber: data.mobile,
                UserName: data.email,
                Email: data.email,
                Password: 'test@1234',
                LanguageCultureCode: data.language,
            };

            const { data: registerResponse } = await ServiceManager.Security('registerPrivateUser', [registerParams]);

            const contactId = registerResponse?.ContactId;

            const prospectParams = {
                IsProspect: true,
            };

            const privateParams = {
                Gender: data.gender,
                FirstName: data.firstName,
                LastName: data.lastName,
                AddressLine1: data.street,
                AddressLine3: data.number,
                AddressLine4: data.postalCode,
                AddressLine5: data.city,
                AddressLine6: data.additional,
                CountryId: data.countryId,
                NationalityId: data.countryId,
                PreferredLanguageCultureCode: data.language,
                BirthDate: formatDate(data.birthDate),
                MobileNumber: data.mobile,
                Email: data.email,
            };

            await Promise.all([
                ServiceManager.contactManagement('patchProspect', [contactId, prospectParams]),
                ServiceManager.contactManagement('putPrivate', [privateParams, contactId]),
            ]);

            const contactGroupsParams = {
                GroupName: `Group${contactId}`,
                Contacts: [
                    {
                        ContactId: contactId,
                        AccessPolicyIds: [
                            20,
                        ],
                    },
                ],
            };

            await ServiceManager.contactManagement('postContactsGroups', [contactId, contactGroupsParams]);

            setLoading(false);

            return registerResponse;
        } catch (err) {
            if (err?.response?.data?.Code === 'ValidationFailed') {
                setError({
                    message:
                    err?.response?.data?.Properties?.ValidationErrors?.[0]?.Message,
                    type: err?.response?.data?.Code,
                });
                setLoading(false);
            } else {
                handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                )(err);
            }
            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);


    return {
        error,
        isLoading,
        createProspect,
    };
};
