const firstLetterLowerCase = (s) => {
    if (typeof s !== 'string') return '';

    return s.charAt(0).toLowerCase() + s.slice(1);
};

export const adaptRecurrences = (options, t) => (options || []).map((item) => ({
    label: t(`onBoarding.goal.${firstLetterLowerCase(item.Key)}`),
    value: item.Key,
    id: item.Id,
}));
