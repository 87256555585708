import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ModifyPortfolio from './ModifyPortfolio';
import AddPosition from './pages/AddPosition/AddPosition';
import ModifyConfirmation from './pages/ModifyConfirmation';
import DetailedOrders from './pages/DetailedOrders';
import Interaction from './pages/Interaction';

const Router = (props) => {
    const { match: { path } } = props;
    const withProps = (Component) => (defProps) => <Component {...props} {...defProps} />;

    return (
        <Switch>
            <Route exact path={`${path}`} component={ModifyPortfolio} />
            <Route exact path={`${path}/add-position`} component={AddPosition} />
            <Route exact path={`${path}/confirmation`} component={ModifyConfirmation} />
            <Route exact path={`${path}/orders`} component={DetailedOrders} />
            <Route exact path={`${path}/interaction`} render={withProps(Interaction)} />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.displayName = 'Router';

export default Router;
