import { useContext } from 'react';
import InteractionsContext from './context';

const useSelector = (callback) => {
    const { getValue } = useContext(InteractionsContext);

    if (getValue === undefined) {
        throw new Error('useInteractions can only be used inside InteractionsProvider');
    }

    return getValue(callback);
};

export default useSelector;
