import { createStore } from 'redux';
import { load as loadedSession } from 'middleware/redux-sessionstorage';
import { get } from 'lodash/fp';
import { setup } from 'additiv-services';

const loadStateAndSetupServices = () => {
    const loaded = loadedSession({ states: ['auth'] });
    const jwt = get('auth.jwt', loaded);

    if (jwt) {
        setup({
            bearerAccessToken: `Bearer ${jwt}`,
        });
    }

    return loaded;
};

const storeObj = {
    mStore: ((preloadedState) => (
        createStore(
            () => {
            },
            preloadedState,
        )
    ))(loadStateAndSetupServices()),
    set store(params) {
        const { reducer, enhancer } = params;

        this.mStore = ((preloadedState) => (
            createStore(
                reducer,
                preloadedState,
                enhancer,
            )
        ))(loadStateAndSetupServices());
    },
    get store() {
        return this.mStore;
    },
};

export const getStore = () => storeObj.store;

export const setStore = (reducer, enhancer) => {
    storeObj.store = { reducer, enhancer };

    return storeObj.store;
};
