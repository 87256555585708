import { renderers } from 'ui-library';
import {
    CellPDFView,
    CellSwitch,
    ActionCellWithIconAndText,
    CellInputText,
    CellWithPlusMinusIcons,
    CellWithNewIconAndText,
} from 'components/renderers';
import { sorterNumber, sorterString } from 'utils/sorting';
import CellValue from 'templates/Portfolio/components/CellValue';

const { CellWithButtonIcon, CellWithCurrentNewValue } = renderers;

export const SELECTED_PRODUCT = 'selectedProduct';
export const SELECTED_GOAL_DETAILS = 'selectedGoalDetails';
export const SELECTED_GOAL_CHANGES = 'selectedGoalChanges';
export const CREATED_GOAL = 'createdGoal';
export const SELECTED_RISK_APPETITE = 'selectedRiskAppetite';
export const SELECTED_RISK_CAPACITY = 'selectedRiskCapacity';
export const MODIFY_POSITIONS_MODIFIED = 'modifiedPositions';
export const MODIFY_POSITIONS_NEW_POSITIONS = 'addedPositions';
export const CREATED_PROPOSAL_ID = 'createdProposalId';
export const IBAN = 'IBAN';

export const REVIEW_DEFAULT = 'default';
export const REVIEW_STRATEGY_CHANGE = 'strategyChange';
export const REVIEW_MODIFY = 'modify';
export const REVIEW_SUMMARY = 'goalSummary';
export const ADD_POSITION = 'addPosition';

export const defaultMinMaxForEmptyData = {
    min: 55806,
    max: 85131,
};

export const getStepTitles = (t) => ({
    product: t('onBoarding.product'),
    riskAppetite: t('onBoarding.riskProfile'),
    knowledgeExperience: t('onBoarding.knowledgeExperience'),
    goal: t('onBoarding.goal'),
    review: t('onBoarding.review'),
    completion: t('onBoarding.completion'),
    portfolioSetup: t('onBoarding.portfolioSetup'),
});

export const getTradesColumns = (t) => ([
    {
        key: 'name',
        title: t('clientDashboard.portfolio.columns.name'),
        sortable: true,
        render: CellSwitch,
        width: 250,
        minWidth: 250,
    },
    {
        title: t('clientDashboard.portfolio.columns.currency'),
        key: 'currency',
        sortable: true,
        sorter: sorterString('currency'),
        className: 'currency-cel',
        width: 80,
        minWidth: 80,
    },
    {
        title: t('clientDashboard.portfolio.columns.latestPrice'),
        key: 'latestPrice',
        width: 107,
        minWidth: 107,
        sortable: true,
        sorter: sorterNumber('latestPrice'),
        className: 'text-right',
        render: CellValue,
    },
    {
        title: t('clientDashboard.portfolio.columns.units'),
        className: 'text-right',
        key: 'units',
        sortable: true,
        sorter: sorterNumber('units'),
        width: 90,
        minWidth: 90,
    },
    {
        title: t('clientDashboard.portfolio.columns.valueData'),
        className: 'text-right',
        key: 'valuation',
        sortable: true,
        sorter: sorterNumber('valuation'),
        width: 150,
        minWidth: 150,
    },
    {
        title: t('clientDashboard.portfolio.columns.allocation'),
        key: 'newAllocation',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('allocation'),
        width: 120,
        minWidth: 120,
    },
    {
        title: t('clientDashboard.portfolio.columns.fee'),
        key: 'fee',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('fee'),
        width: 60,
        minWidth: 60,
    },
]);

export const getDocumentsColumns = (t) => ([
    {
        key: 'icon',
        title: '',
        render: CellWithButtonIcon,
        width: 30,
        minWidth: 30,
    },
    {
        key: 'name',
        title: t('clientDashboard.documents.name'),
        render: CellPDFView,
        sortable: true,
        sorter: sorterString('name.value'),
        lastSorter: true,
    },
]);

export const getModifyColumns = (t, numberSeparators) => ([
    {
        key: 'nameNew',
        title: t('clientDashboard.portfolio.columns.name'),
        sortable: true,
        render: CellWithNewIconAndText,
        sorter: sorterString('name'),
        width: 300,
        minWidth: 300,
        defaultSortOrder: 'descending',
    },
    {
        key: 'valuation',
        title: t('clientDashboard.portfolio.columns.valueData'),
        className: 'text-right',
        width: 150,
        minWidth: 150,
    },
    {
        key: 'allocation',
        title: t('clientDashboard.portfolio.columns.allocation'),
        className: 'text-right',
        width: 180,
        minWidth: 180,
    },
    {
        key: 'new',
        title: t('clientDashboard.portfolio.columns.new'),
        className: 'with-input text-right modify-goal__cell-new',
        render: CellInputText(numberSeparators),
        width: 170,
        minWidth: 170,
    },
    {
        key: 'icon',
        render: CellWithPlusMinusIcons,
        width: 80,
        minWidth: 80,
        title: '',
        className: 'modify-goal__cell-with-icons',
    },
    {
        key: 'actions',
        title: t('clientDashboard.portfolio.columns.actions'),
        render: ActionCellWithIconAndText,
        className: 'text-right',
        width: 90,
        minWidth: 90,
    },
]);

export const getSecuritiesColumns = (t) => ([
    {
        key: 'Name',
        title: t('clientDashboard.portfolio.columns.name'),
        render: CellSwitch,
        width: 300,
        sorter: sorterString('Name.value'),
    },
    {
        key: 'Isin',
        title: t('clientDashboard.portfolio.columns.isin'),
        sortable: true,
        sorter: sorterString('Isin'),
    },
    {
        key: 'AssetClass',
        title: t('clientDashboard.portfolio.columns.assetClass'),
        sortable: true,
        filterable: true,
        width: 145,
        defaultSortOrder: 'ascend',
        sorter: sorterString('AssetClass'),
    },
    {
        key: 'SubAssetClass',
        title: t('clientDashboard.portfolio.columns.subAssetClass'),
        sortable: true,
        sorter: sorterString('SubAssetClass'),
    },
    {
        key: 'Type',
        title: t('clientDashboard.portfolio.columns.type'),
        sortable: true,
        sorter: sorterString('Type'),
    },
    {
        key: 'Currency',
        title: t('clientDashboard.portfolio.columns.currency'),
        width: 100,
        filterable: true,
        sortable: true,
        sorter: sorterString('Currency'),
    },
]);

export const getComparePositionsColumns = (t) => ([
    {
        key: 'name',
        title: t('clientDashboard.portfolio.columns.name'),
        render: CellSwitch,
        sorter: sorterString('name'),
    },
    {
        key: 'isin',
        title: t('clientDashboard.portfolio.columns.isin'),
        sortable: true,
        sorter: sorterString('isin'),
    },
    {
        title: t('clientDashboard.portfolio.columns.allocation'),
        key: 'allocation',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('allocation'),
    },
]);

export const getCompareColumns = (t) => ([
    {
        key: 'name',
        title: t('clientDashboard.portfolio.columns.name'),
        sortable: true,
        sorter: sorterString('name'),
        className: 'with-link',
        render: CellSwitch,
        defaultSortOrder: 'descending',
    },
    {
        key: 'valueData',
        title: t('clientDashboard.portfolio.columns.valueData'),
        className: 'text-right',
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('onBoarding.3rdParty'),
            new: t('onBoarding.proposed'),
        }),
        minWidth: 220,
        sorter: sorterNumber('valueData.currentValue'),
    },
    {
        key: 'allocationD',
        title: t('clientDashboard.portfolio.columns.allocation'),
        className: 'text-right',
        sorter: sorterNumber('allocationD.currentValue'),
        minWidth: 120,
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('onBoarding.3rdParty'),
            new: t('onBoarding.proposed'),
        }),
    },
]);
