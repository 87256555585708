import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Button, Column, Modal, Title,
} from 'ui-library';
import { useClientProfile } from 'prodivers/clientProfile';
import { ListPreview, Preview } from 'templates/ListPreview';
import { PageError } from 'components/PageError';
import Preloader from 'components/Preloader';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import { compareDates } from 'utils/datetime';
import Service from 'additiv-services';
import history from 'services/history';
import { getUTCDate } from 'locale';
import PreviewContent from './components/PreviewContent';
import './Interactions.css';
import ListComponent from './components/ListComponent';
import { useInteractions } from './hooks/useInteractions';

const Interactions = ({ match }) => {
    useCancelAllRequests();

    const { t } = useTranslation();
    const { params: { dfsClientId } } = match;
    // Client Provider
    const { clientId } = useClientProfile();

    const [editMode, setEditMode] = useState(false);
    const [createEntryMode, setCreateEntryMode] = useState(false);
    const [editData, setEditData] = useState(null);
    const [formErrors, setFormErrors] = useState([]);

    const {
        getData,
        needUpdate,
        getDataCallback,
        getPreview,
        changePreviewTab,
        previewTab,
        changeListTab,
        listTab,
        firstLoad,
        setFirstLoad,
        data: interactions,
        deleteItem,
        updateItem,
        isLoadingInteractionTopics,
        isLoadingInteractionTypes,
    } = useInteractions();


    const onRowSelect = useCallback(() => {
        if (!firstLoad) {
            Service.cancelAll();
        }
        setEditMode(false);
        setCreateEntryMode(false);
        setEditData(null);
        setFirstLoad(false);
    }, [firstLoad]);

    const interactionsLength = useMemo(() => interactions?.length || 0, [interactions]);

    if (clientId === undefined) {
        return (<PageError />);
    }

    const titleComponent = (
        <>
            <Column size={['sm-4']}>
                <Title type={2}>{t('interaction.title')}</Title>
            </Column>
            <Column size={['sm-8']} className="text-sm-right button-group">
                <Button type="primary" size="small" onClick={() => history.push(`/client/${dfsClientId}/interactions/new`)}>
                    {t('interaction.newInteraction')}
                </Button>
            </Column>
        </>
    );


    const listComponent = (props) => (
        <div className="interactions-list">
            <ListComponent
                tab={listTab}
                needUpdate={needUpdate}
                changeTab={changeListTab}
                onNewInteraction={() => history.push(`/client/${dfsClientId}/interactions/new`)}
                onRowSelect={onRowSelect}
                isLoading={isLoadingInteractionTopics
                || isLoadingInteractionTypes}
                useDynamicSorting
                {...props}
            />
        </div>
    );

    const onDeleteItem = (id, actions) => {
        Modal.confirm({
            title: t('interaction.deleteSelected'),
            content: t('interaction.deleteCurrentContent'),
            okText: t('interaction.confirm'),
            onOk: async () => {
                setFirstLoad(true);
                await actions.deletePreview(id);
                setEditMode(false);
                await actions.deleteCallback(id);
            },
            cancelText: t('confirmation.cancel'),
        });
    };

    const onEditItem = (actions) => {
        if (formErrors.length > 0) return;

        Modal.confirm({
            title: t('interaction.editSelected'),
            content: t('interaction.editCurrentContent'),
            okText: t('interaction.confirm'),
            onOk: async () => {
                await actions.updatePreview(editData);
                setCreateEntryMode(false);
                setEditMode(false);
                actions.getPreview(editData.id);
            },
            cancelText: t('confirmation.cancel'),
        });
    };

    const onDiscard = () => {
        Modal.confirm({
            title: t('interaction.discard.title'),
            content: t('interaction.discard.text'),
            okText: t('interaction.discard'),
            onOk: () => {
                setCreateEntryMode(false);
                setEditData(null);
            },
            cancelText: t('confirmation.cancel'),
        });
    };

    const renderButtons = (item, actions) => {
        if (previewTab === 'interaction-details') {
            if (item.isEditable
                && !item.IsCanceled && compareDates(getUTCDate(item.DateTo), new Date())) {
                return (
                    <>
                        {!editMode && (
                            <Button
                                type="secondary"
                                size="small"
                                disabled={!compareDates(getUTCDate(item.DateTo), new Date())}
                                onClick={() => { setEditMode(true); }}
                            >
                                {t('interaction.edit')}
                            </Button>
                        )}
                        {editMode && (
                            <div>
                                <Button size="small" onClick={() => { setEditMode(false); setEditData(null); }}>
                                    {t('interaction.back')}
                                </Button>
                                <Button type="danger" size="small" onClick={() => onDeleteItem(item.id, actions)}>
                                    {t('interaction.cancel')}
                                </Button>
                            </div>
                        )}
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => onEditItem(actions)}
                            disabled={!editMode}
                        >
                            {t('interaction.save')}
                        </Button>
                    </>
                );
            }
        }
        if (previewTab === 'log-entries') {
            if (item.isEditable
                && !item.IsCanceled && compareDates(getUTCDate(item.DateTo), new Date())) {
                if (createEntryMode) {
                    return (
                        <div className="log-entry-bottons">
                            <Button
                                type="secondary"
                                size="small"
                                onClick={onDiscard}
                            >
                                {t('interaction.discard')}
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => onEditItem(actions)}
                                disabled={
                                    !createEntryMode
                                || (createEntryMode && !(editData?.comment.length >= 10))
                                }
                            >
                                {t('interaction.save')}
                            </Button>
                        </div>
                    );
                }

                return (
                    <div className="log-entry-bottons">
                        <Button
                            type="secondary"
                            size="small"
                            onClick={() => setCreateEntryMode(true)}
                        >
                            {t('interaction.addLogEntry')}
                        </Button>
                    </div>
                );
            }
        }

        return null;
    };

    return (
        <Preloader>
            <div className="interaction-page">
                <ListPreview
                    getData={getData}
                    getPreview={getPreview}
                    listComponent={listComponent}
                    titleComponent={titleComponent}
                    getDataCallback={getDataCallback}
                    dataLength={interactionsLength}
                >
                    {
                        (previewProps) => (
                            <Preview
                                {...previewProps}
                                deleteItem={deleteItem}
                                updateItem={updateItem}
                                renderMetadata={null}
                                renderButtons={renderButtons}
                                renderContentItem={(data) => (
                                    <PreviewContent
                                        data={data}
                                        editMode={editMode}
                                        setEditData={setEditData}
                                        tab={previewTab}
                                        changeTab={changePreviewTab}
                                        createEntryMode={createEntryMode}
                                        formErrors={formErrors}
                                        setFormErrors={setFormErrors}
                                    />
                                )}
                            />
                        )
                    }

                </ListPreview>
            </div>
        </Preloader>
    );
};

Interactions.propTypes = {
    memberId: PropTypes.number,
    clientId: PropTypes.number.isRequired,
    match: PropTypes.shape({
        params: { dfsClientId: PropTypes.string },
    }),
};

Interactions.defaultProps = {
    memberId: undefined,
    match: { params: {} },
};

export default Interactions;
