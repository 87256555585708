import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InvestmentPerformance from 'components/InvestmentPerformance';
import { investorPerformanceSelectVlaues, YTD } from 'filters/investementPerformanceFilters';
import { chartValueFormatCurrency, formatNumberRounding } from 'utils/formatting';
import { useFormatting } from 'locale';
import { useInvestmentPerformance } from '../hooks/useInvestmentPerformance';

const filterNamespace = 'advisoryDashboard.investmentPerformance.filters';
const getInvestorPerformanceSelectOptions = (t) => (
    investorPerformanceSelectVlaues.map((item) => ({
        ...item,
        label: t(`${filterNamespace}.${item.value}`),
    }))
);

const AdvisoryInvestmentPerformance = ({ configuration, drop }) => {
    const { t } = useTranslation();
    const [currentFilter, setCurrentFilter] = useState(YTD);
    const performance = useInvestmentPerformance(currentFilter, configuration);

    const onFilterChange = (value) => {
        setCurrentFilter(value);
    };
    const { getFormattedNumber } = useFormatting();

    return (
        <div className="total-client-assets">
            <InvestmentPerformance
                {...performance}
                drop={drop}
                title={t('advisoryDashboard.investmentPerformance.title')}
                currency={configuration.data.currency}
                currentFilter={currentFilter}
                filters={getInvestorPerformanceSelectOptions(t)}
                yAxisFormat={({ value }) => formatNumberRounding(value, getFormattedNumber)}
                onFilterChange={onFilterChange}
                chartValueFormat={chartValueFormatCurrency}
            />
        </div>
    );
};

AdvisoryInvestmentPerformance.propTypes = {
    configuration: PropTypes.shape({
        isLoading: PropTypes.bool,
        error: PropTypes.shape({
            type: PropTypes.string,
            message: PropTypes.string,
        }),
        data: PropTypes.shape({
            currency: PropTypes.shape({
                Id: PropTypes.number,
                Name: PropTypes.string,
                Symbol: PropTypes.string,
                CurrencyCode: PropTypes.string,
            }),
        }),
    }).isRequired,
    drop: PropTypes.shape({
        role: PropTypes.string,
        tabIndex: PropTypes.number,
        onDragStart: PropTypes.func,
    }),
};

AdvisoryInvestmentPerformance.defaultProps = {
    drop: null,
};

export default React.memo(AdvisoryInvestmentPerformance);
