import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import update from 'lodash/update';
import { Infobox } from 'ui-library';
import FormIo from 'components/FormIo';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { useDynamicCRM } from '../../hooks/useDynamicCRM';

function ProfessionalInformation(props) {
    const {
        onPageChange, contactId,
    } = props;
    const { t } = useTranslation();
    const [formio, setFormio] = useState();
    const [isValid, setIsValid] = useState(true);
    const [submissionData, setSubmissionData] = useState({});

    // Helper hooks
    const {
        data: schema, submission, isLoading, error,
        errorSave, isSaving, saveData,
    } = useDynamicCRM({ contactId, selectors: ['professionalInformation', 'politicalExposure'] });

    // Data
    const submissionMemo = useMemo(() => ({ data: submission }), [submission]);

    // Callbacks
    const onChange = useCallback(({ isValid: isValidForm }, { changes }) => {
        (changes || []).forEach((change) => {
            if (!change?.component?.disabled && !change?.flags?.noValidate) {
                setSubmissionData((obj) => update(
                    obj,
                    change.instance.path,
                    () => change.value,
                ));
            }
        });
        setIsValid(isValidForm);
    }, []);
    const onNextClick = useCallback(async () => {
        await formio.submit();

        if (!isValid) return;

        await saveData(submissionData);
        onPageChange('tax');
    }, [formio, isValid, onPageChange, saveData, submissionData]);

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.professionalInformation')}
            error={error}
            isLoading={isLoading}
            classNameContent="professional-information"
            prevButton={{
                loading: isSaving,
                onClick: () => onPageChange(''),
            }}
            nextButton={{
                loading: isSaving,
                text: t('onBoarding.continue'),
                onClick: onNextClick,
            }}
            buttonsRowError={(
                <>
                    {errorSave && (<Infobox error>{errorSave.message}</Infobox>)}
                </>
            )}
        >
            <FormIo
                form={schema}
                submission={submissionMemo}
                options={{
                    readOnly: false,
                    noAlerts: false,
                    renderMode: 'HWM',
                }}
                onChange={onChange}
                formReady={setFormio}
            />
        </OnBoardingBaseTemplate>
    );
}

ProfessionalInformation.propTypes = {
    contactId: PropTypes.string.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

ProfessionalInformation.defaultProps = {
};

export default ProfessionalInformation;
