import React, { Suspense } from 'react';
import { Loader } from 'ui-library';
import history from 'services/history';
import { Route, Router } from 'react-router-dom';
import AutoLogout from 'domain/Auth/AutoLogout';
import withSignalR from 'hocs/withSignalR';
import { Router as RootRouter } from 'pages';
import ScrollToTop from 'components/ScrollToTop';
import ErrorBoundary from 'components/ErrorBoundary';
import { ApolloClientProvider } from 'services/ApolloClient';
import { useGA } from 'services/GoogleAnalytics/useGA';
import './inversify.config';

const App = () => {
    useGA();

    return (
        <Suspense fallback={<Loader />}>
            <ApolloClientProvider>
                <ErrorBoundary>
                    <AutoLogout />
                    <Router history={history}>
                        <ScrollToTop />
                        <Route
                            path="/"
                            component={RootRouter}
                        />
                    </Router>
                </ErrorBoundary>
            </ApolloClientProvider>
        </Suspense>
    );
};

export default withSignalR(App);
