import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { gql, useLazyQuery } from '@apollo/client';

const initialState = {
    isLoadingSecurities: false,
    errorSecurities: null,
    dataSecurities: [],
    rawDataSecurities: [],
    totalSecurities: 0,
    setSecurityIds: { ids: [] },
};

const SEARCH_SECURITIES = gql`
    query SearchSecurities($ids: [Int!]) {
        securities(
            where: { id: { in: $ids } }
        ){
            nodes {
                id
                name
                isin
                type { name, id }
                currency { isoCode }
                assetClass { id, name, parent { id, name, parent { parent { id, name, parent { id, name, parent { id, name, parent { id, name, parent { id, name } } } } } } } }
            }
        }
    }
`;

function reducer(state, action) {
    switch (action.type) {
        case 'setData':
            return {
                ...state,
                dataSecurities: action.payload.data,
                rawDataSecurities: action.payload.rawData,
            };

        case 'setErr':
            return {
                ...state,
                errorSecurities: action.payload.error,
            };
        case 'setIsLoading':
            return {
                ...state,
                isLoadingSecurities: action.payload.isLoading,
            };
        case 'setSecurityIds':
            return {
                ...state,
                securityIds: action.payload.data,
                totalSecurities: action.payload.total,
            };

        default: return state;
    }
}

export const useSecurities = (
    adaptSecurities, portfolioId, productId, searchData, baseUrl,
) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { i18n } = useTranslation();

    const setError = (error) => {
        dispatch({ type: 'setErr', payload: { error } });
    };
    const setLoading = (isLoading) => {
        dispatch({ type: 'setIsLoading', payload: { isLoading } });
    };
    const [getSecurities, { loading, error, data: securities }] = useLazyQuery(SEARCH_SECURITIES);

    const searchSecurities = useCallback(async () => {
        if (!searchData) return;

        if (productId) {
            setError(null);
            setLoading(true);

            try {
                const response = await ServiceManager.instrumentsService('searchSecurities', [productId, searchData, { language: i18n.language }]);

                try {
                    const securityIds = response.data?.Results.map(({ Id }) => Id);

                    await getSecurities({ variables: { ids: securityIds } });

                    const total = response.data.TotalCount || 0;

                    dispatch({
                        type: 'setSecurityIds',
                        payload: { data: securityIds, total },
                    });
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError,
                        setLoading,
                    }),
                )(err);
            }
        }
    }, [productId, searchData, i18n.language, getSecurities]);

    useEffect(() => {
        searchSecurities();
    }, [searchSecurities]);

    useEffect(() => {
        const { securitiesData: data } = adaptSecurities(
            securities?.securities?.nodes,
            {
                baseUrl,
                language: i18n.language,
            },
        );

        dispatch({
            type: 'setData',
            payload: { data, rawData: securities?.securities?.nodes },
        });
    }, [securities?.securities?.nodes]);

    return {
        ...state,
        isLoadingSecurities: state.isLoadingSecurities || loading,
        errorSecurities: state.errorSecurities || error,
    };
};
