import { isEmpty } from 'lodash/fp';
import { sum } from 'utils';
import { NA } from 'utils/formatting';
import { adaptModifiedSecurities } from './adaptModifiedSecurities';

const formatFooter = (value, allocation, newVal, t) => ({
    nameNew: { value: t('clientDashboard.portfolio.footer.total') },
    valuation: value,
    allocation,
    new: newVal,
    icon: '',
    actions: { icon: '' },
});

export const adaptPositionsModify = (positionsData, { t, getFormattedNumber, options }) => {
    let positions;
    let positionsFooter;

    if (isEmpty(positionsData)) {
        positions = [];
        positionsFooter = formatFooter(NA, NA, NA, t);
    } else {
        positions = adaptModifiedSecurities(positionsData, options);

        const allocationData = sum(positions, 'allocationData');

        positionsFooter = formatFooter(
            getFormattedNumber(sum(positions, 'value')),
            `${getFormattedNumber(allocationData)}%`,
            `${getFormattedNumber(allocationData)}%`,
            t,
        );
    }

    return { positions, positionsFooter };
};
