import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    ContentBox, Title, BreadCrumbs, Modal, Accordion, AccordionPanel as Panel,
} from 'ui-library';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import { getClientId } from 'utils';
import Preloader from 'components/Preloader';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import StrategyOverview from 'components/StrategyOverview';
import AllocationCompare from 'components/AllocationCompare';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import PositionsCompare from 'components/PositionsCompare';
import Analysis from 'components/Analysis';
import {
    orderBookSelector, portfolioSelector, usePortfolioSelector, modelPortfolioSelector,
} from 'domain/Portfolio';
import { groupComparedPositions } from 'adaptors/groupComparedPositions';
import { useFormatting } from 'locale';
import RiskDisclaimer from 'components/RiskDisclaimer/RiskDisclaimer';
import { onPreventRedirect, redirectToReadOnlyPositionDetails } from '../../common/utils';
import { useCheckRiskProfile } from '../../../hooks/useCheckRiskProfile';

function RebalanceAdvisory(props) {
    const { match: { params: { portfolioId, dfsClientId } } } = props;
    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        data: modelPortfolio, dataRaw: modelPortfolioRaw,
        isLoading: isLoadingModelPortfolio, error: errorModelPortfolio, getModelPortfolio,
    } = usePortfolioSelector(modelPortfolioSelector);
    const {
        data: orderBook, isLoading: isLoadingOrderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    useEffect(() => {
        getOrderBook();
    }, [getOrderBook]);

    // Data
    const baseUrl = `/client/${dfsClientId}/portfolios/${portfolioId}/rebalance/position/${data?.productId}`;
    const positions = useMemo(() => groupComparedPositions({
        positions: dataRaw.Positions,
        modelPositions: modelPortfolioRaw.Positions || dataRaw.Positions,
        portfolioCurrency: data.currency,
        baseUrl,
        t,
        portfolioTotalValue: data.investedAmount,
        redirectToReadOnlyPositionDetails,
        getFormattedNumber,
        getFormattedCurrency,
    }), [
        dataRaw.Positions,
        modelPortfolioRaw.Positions,
        data.currency,
        data.investedAmount,
        baseUrl,
        t,
        getFormattedNumber,
        getFormattedCurrency,
    ]);
    const modelPortfolioOptions = useMemo(() => ({
        portfolioValue: dataRaw?.CurrentValue,
        comparedAllocations: dataRaw?.Positions,
        portfolioSecuritiesValue: dataRaw?.InvestedAmount,
    }), [dataRaw?.CurrentValue, dataRaw?.Positions, dataRaw?.InvestedAmount]);

    // Hooks
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);

    const {
        analysisData, isLoading: isLoadingModel, errorModel,
        onPerformanceChange, performanceSelected,
    } = useAnalysisDetails({
        clientId,
        portfolio: data,
        strategy: modelPortfolio,
        positions,
        isLoading,
        benchmark,
        isLoadingBenchmark,
        additionalOptions: {
            usePortfolioAllocations: true,
        },
    });

    // Effects
    useEffect(() => {
        if (dataRaw.ModelPortfolioId && modelPortfolioRaw.Id !== dataRaw.ModelPortfolioId) {
            getModelPortfolio(dataRaw.ModelPortfolioId, { adaptOptions: modelPortfolioOptions });
        }
    }, [getModelPortfolio, dataRaw.ModelPortfolioId, modelPortfolioOptions, modelPortfolioRaw.Id]);

    // Risk Profile Check
    const { comparePortfolioRisk } = useCheckRiskProfile(clientId);
    const [showRiskDisclaimer, setShowRiskDisclaimer] = useState(false);
    const { riskCategoryId, riskCategoryName } = useMemo(() => ({
        riskCategoryId: dataRaw?.RiskCategory?.Id,
        riskCategoryName: dataRaw?.RiskCategory?.Name,
    }), [dataRaw?.RiskCategory]);

    useEffect(() => {
        setShowRiskDisclaimer(false);

        if (modelPortfolio?.riskCategory) {
            setShowRiskDisclaimer(
                !comparePortfolioRisk(modelPortfolio?.riskCategory, riskCategoryId),
            );
        }
    }, [comparePortfolioRisk, modelPortfolio, riskCategoryId]);

    // Renderers
    const currentAllocations = data.investmentAllocation;
    const newAllocations = modelPortfolio?.investmentAllocation?.chart
        ? (modelPortfolio.investmentAllocation)
        : currentAllocations;
    const AllocationRender = useMemo(() => (
        <AllocationCompare
            data={currentAllocations}
            dataNew={newAllocations}
            isLoading={isLoading || isLoadingModelPortfolio}
            error={error || errorModelPortfolio}
        />
    ), [
        currentAllocations,
        newAllocations,
        isLoading,
        isLoadingModelPortfolio,
        error,
        errorModelPortfolio,
    ]);
    const renderRiskDisclaimer = () => (
        <RiskDisclaimer
            PRP={modelPortfolio.overview?.strategy}
            CRP={riskCategoryName}
        />
    );

    // Callbacks
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onDiscardChanges = () => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                history.push(`/client/${dfsClientId}/portfolios/${portfolioId}`);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onConfirm = () => {
        if (!showRiskDisclaimer) {
            history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/rebalance/orders`);

            return;
        }

        Modal.confirm({
            title: t('clientDashboard.disclaimer'),
            content: t('clientDashboard.changeStrategy.riskProfileContent'),
            okText: t('clientDashboard.yes'),
            cancelText: t('clientDashboard.no'),
            onOk: () => {
                history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/rebalance/orders`);
            },
            className: 'accept-risk-profile',
        });
    };

    return (
        <Preloader
            isLoading={isLoadingModelPortfolio || isLoadingOrderBook}
            error={errorModelPortfolio || errorOrderBook}
        >
            <ContentBox className="rebalance with-mb" underline={false}>
                <BreadCrumbs current={t('clientDashboard.portfolio.rebalance')}>
                    <Link
                        to={`/client/${dfsClientId}/portfolios`}
                        onClick={(e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios`, onCancel)}
                    >
                        {t('clientDashboard.portfolio.portfolios')}
                    </Link>
                    <Link
                        to={`/client/${dfsClientId}/portfolios/${portfolioId}`}
                        onClick={(e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/${portfolioId}`, onCancel)}
                    >
                        {data.title}
                    </Link>
                </BreadCrumbs>
                <Title className="underlined with-mb" type={1}>
                    {t('clientDashboard.portfolio.rebalance')}
                </Title>
                {showRiskDisclaimer && renderRiskDisclaimer()}
                <div className="overview strategy-overview">
                    <StrategyOverview
                        data={data.overview}
                        dataStrategy={modelPortfolio.overview}
                        isLoading={isLoading}
                        error={error}
                    />
                </div>
                <div className="content-wrapper confirmation">
                    <Accordion defaultActiveKey={['1', '2', '3']}>
                        <Panel header={t('clientDashboard.portfolio.allocation')} key="1" className="allocation">
                            {AllocationRender}
                        </Panel>
                        <Panel header={t('clientDashboard.portfolio.analysis')} key="2" className="analysis">
                            <Analysis
                                data={analysisData}
                                onFilterChange={onPerformanceChange}
                                benchmarkOptions={benchmarkOptions}
                                benchmarkSelected={benchmarkSelected}
                                onBenchmarkChange={onBenchmarkChange}
                                isLoadingBenchmarks={isLoadingBenchmark}
                                performanceSelected={performanceSelected}
                                isLoading={isLoadingModel}
                                error={errorModel}
                            />
                        </Panel>
                        <Panel header={t('clientDashboard.portfolio.positions')} className="positions" key="3">
                            <PositionsCompare
                                data={{ positions }}
                                isLoading={isLoading}
                                error={error}
                                defaultExpandAllRows
                            />
                        </Panel>
                    </Accordion>
                </div>
                <ButtonsBlockRow
                    leftButton={{
                        type: 'danger',
                        text: t('confirmation.cancel'),
                        onClick: onDiscardChanges,
                    }}
                    primaryButton={{
                        text: t('confirmation.continue'),
                        disabled: orderBook,
                        onClick: onConfirm,
                    }}
                />
            </ContentBox>
        </Preloader>
    );
}

RebalanceAdvisory.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

RebalanceAdvisory.defaultProps = {
};

export default RebalanceAdvisory;
