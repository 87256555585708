import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import { Table } from 'ui-library';
import EmptyContent from 'components/EmptyContent';
import AddPositionSearchForm from 'components/AddPositionSearchForm';

export function AddPosition({
    handleSelect,
    handleFilterSubmit,
    setValue,
    handleSubmit,
    isLoading,
    totalSecurities,
    page,
    pageSize,
    securities,
    currencies,
    securityTypes,
    columns,
    onChangeTable,
    dataRatingAgencies,
    dataRatings,
    isLoadingRatings,
    ratingAgencyValue,
    ratingValue,
}) {
    const { t } = useTranslation();

    return (
        <>
            <AddPositionSearchForm
                searchDisabled={isLoading}
                onSearch={handleSubmit(handleFilterSubmit)}
                onChangeISIN={(e) => setValue('SearchTerm', e?.target?.value)}
                typeOptions={securityTypes}
                onChangeType={(v) => setValue('SecurityTypeIds', v)}
                currencyOptions={currencies}
                onChangeCurrency={(v) => setValue('CurrencyIds', v)}
                ratingAgencyOptions={dataRatingAgencies || []}
                onChangeRatingAgency={(v) => setValue('RatingAgencyIds', v)}
                ratingOptions={dataRatings || []}
                onChangeRating={(v) => setValue('RatingIds', v)}
                isLoadingRatings={isLoadingRatings}
                ratingAgencyValue={ratingAgencyValue}
                ratingValue={ratingValue}
            />
            <Preloader isLoading={isLoading}>
                <EmptyContent data={securities} text={t('addPosition.noSecurities')}>
                    <form>
                        <Table
                            columns={columns}
                            data={securities}
                            onChange={onChangeTable}
                            rowSelection={{
                                type: 'radio',
                                onChange: handleSelect,
                            }}
                            paging={{
                                total: totalSecurities,
                                currentPage: page,
                                pageSize,
                            }}
                            headerControls={{
                                selectControlsLabel: {
                                    selectAll: t('headerControls.selectAll'),
                                    reset: t('headerControls.reset'),
                                },
                            }}
                        />
                    </form>
                </EmptyContent>
            </Preloader>
        </>
    );
}


AddPosition.propTypes = {
    handleSelect: PropTypes.func,
    handleFilterSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    totalSecurities: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    columns: PropTypes.shape({
        key: PropTypes.string,
        render: PropTypes.func,
        sorter: PropTypes.func,
        sortable: PropTypes.bool,
        filterable: PropTypes.bool,
        title: PropTypes.string,
        width: PropTypes.number,
    }),
    securityTypes: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
    }),
    currencies: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
    }),
    securities: PropTypes.shape({
        key: PropTypes.number,
        AssetClass: PropTypes.string,
        Currency: PropTypes.string,
        Id: PropTypes.number,
        Isin: PropTypes.string,
        Name: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
            link: PropTypes.string,
            name: PropTypes.string,
            maxLength: PropTypes.number,
        }),
        SubAssetClass: PropTypes.string,
        Type: PropTypes.string,
    }),
    onChangeTable: PropTypes.func,
    dataRatingAgencies: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
    }),
    dataRatings: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
    }),
    isLoadingRatings: PropTypes.bool,
    setValue: PropTypes.func,
    handleSubmit: PropTypes.func,
    ratingAgencyValue: PropTypes.string,
    ratingValue: PropTypes.arrayOf(PropTypes.number),
};

AddPosition.defaultProps = {
    handleSelect: () => { },
    handleFilterSubmit: () => { },
    onChangeTable: () => {},
    isLoading: true,
    totalSecurities: 0,
    page: 1,
    pageSize: 8,
    columns: {},
    securityTypes: [],
    currencies: [],
    securities: [],
    dataRatingAgencies: [],
    dataRatings: [],
    isLoadingRatings: false,
    setValue: () => {},
    handleSubmit: () => {},
    ratingAgencyValue: undefined,
    ratingValue: [],
};
