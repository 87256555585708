export const adaptClientList = (data) => (
    data.map(({
        id,
        personalInformation: { displayName },
        relationshipInformation: { externalId },
    }) => ({
        id,
        internalId: {
            value: id,
            selected: false,
        },
        externalId: externalId || 'N/A',
        name: displayName,
    }))
);
