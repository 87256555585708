import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
    }

    render() {
        const { t, children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            // You can render any custom fallback UI
            return <h1>{t('routerErrors.errorBoundary')}</h1>;
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    t: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
};

export default ErrorBoundary;
