import React, { useCallback, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from 'services/history';
import { getClientId } from 'utils';
import { ClientProfileProvider } from 'prodivers/clientProfile';
import ProductSelection from './pages/ProductSelection';
import RiskProfile from './pages/RiskProfile';
import KnowledgeExperience from './pages/KnowledgeExperience';
import Review from './pages/Review/Router';
import Identification from './pages/Identification';
import Contract from './pages/Contract';
import Completion from './pages/Completion';
import GoalAndPortfolioSetup from './pages/GoalAndPortfolioSetup';
import ProductOfferSelection from './pages/ProductOfferSelection';
import { OnBoardingProvider, useStage } from '../../domain/OnBoarding';
import OnBoardingRouterTemplate from './components/OnBoardingRouterTemplate/OnBoardingRouterTemplate';

import './OnBoarding.css';

const ClientRouter = (props) => {
    const {
        match: { url, path, params: { dfsClientId } },
        location: { pathname, state },
    } = props;

    // Data
    const params = /step\/([0-9])/g.exec(pathname);
    const clientId = getClientId(dfsClientId);
    const step = params ? Number(params[1]) : 1;
    const stepPageKey = pathname.split('/').pop();
    const isLastStep = step === 8;

    // OnBoarding stages
    const { setClientStage } = useStage({ clientId });

    // Callbacks
    const changeStep = useCallback((stepValue, pageKey) => {
        history.push(`${url}/step/${stepValue}${pageKey ? `/${pageKey}` : ''}`);
    }, [url]);
    const onPrev = useCallback(() => {
        changeStep(step - 1);
    }, [changeStep, step]);
    const onNext = useCallback(async (clientName) => {
        await setClientStage(step + 1);
        if (isLastStep) {
            history.push(`/client/${dfsClientId}/dashboard`, { clientName });
        } else {
            changeStep(step + 1);
        }
    }, [setClientStage, step, isLastStep, dfsClientId, changeStep]);

    // Page props
    const pageProps = useMemo(() => ({
        contactId: clientId,
        dfsClientId,
        onPrev,
        onNext,
        changeStep,
        currentStep: step,
    }), [clientId, dfsClientId, onPrev, onNext, changeStep, step]);
    const withProps = (Component) => (defProps) => <Component {...pageProps} {...defProps} />;
    const withPropsRiskProfile = (Component) => (defProps) => (
        <Component {...pageProps} onPrev={() => changeStep(1, 'product')} {...defProps} />
    );

    return (
        <ClientProfileProvider
            clientName={state?.clientName}
            clientId={clientId}
            isProspect
        >
            <OnBoardingProvider options={{ clientId }}>
                <OnBoardingRouterTemplate step={step} pageKey={stepPageKey} changeStep={changeStep}>
                    <Switch>
                        <Route exact path={`${path}/step/1`} render={withProps(ProductOfferSelection)} />
                        <Route exact path={`${path}/step/1/product`} render={withProps(ProductSelection)} />
                        <Route path={`${path}/step/2`} render={withPropsRiskProfile(RiskProfile)} />
                        <Route path={`${path}/step/3`} render={withProps(KnowledgeExperience)} />
                        <Route path={`${path}/step/4`} render={withProps(GoalAndPortfolioSetup)} />
                        <Route path={`${path}/step/5`} render={withProps(Review)} />
                        <Route path={`${path}/step/6`} render={withProps(Identification)} />
                        <Route path={`${path}/step/7`} render={withProps(Contract)} />
                        <Route path={`${path}/step/8`} render={withProps(Completion)} />
                        <Redirect exact from={path} to={{ pathname: `${path}/step/1`, state }} />
                    </Switch>
                </OnBoardingRouterTemplate>
            </OnBoardingProvider>
        </ClientProfileProvider>
    );
};

ClientRouter.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        state: PropTypes.shape({
            clientName: PropTypes.string,
        }),
    }).isRequired,
};

ClientRouter.defaultProps = {
};

export default ClientRouter;
