import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageVariant } from 'ui-library';
import history from 'services/history';
import PageVariantWrapper from 'components/PageVariantWrapper';

function Success() {
    const { t } = useTranslation();

    return (
        <PageVariantWrapper>
            <PageVariant
                type="success"
                title={t('confirm.title')}
                message={t('registration.success.message')}
                actionButtonLabel={t('onBoarding.done')}
                onActionButtonClick={() => {
                    history.push('/dashboard');
                }}
            />
        </PageVariantWrapper>
    );
}

Success.propTypes = {};

Success.defaultProps = {};

export default Success;
