import { useEffect, useMemo, useState } from 'react';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject,
    DEPOSITS,
} from 'constants/portfolioProducts';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useSecurities } from '../../AddPosition/hooks';
import { adaptSecurities } from '../../AddPosition/adapters/adaptSecurities';
import { usePositionsToSave } from '../../PositionDetails/hooks/usePositionsToSave';


export const useLiquidityCurrencyPosition = ({ modifiedPositions, reviewData, onPageChange }) => {
    const { product, getGoalDetails } = useOnBoardingSelector(onBoardingDataSelector);

    const goalDetails = useMemo(() => getGoalDetails(), [getGoalDetails]);
    const { saveWithNewPositions } = usePositionsToSave();
    const [loading, setLoading] = useState(true);
    const productForSecurity = useMemo(() => generateObject({
        [ADVISORY]: null,
        [DISCRETIONARY]: null,
        [EXECUTION_ONLY]: modifiedPositions === undefined ? product?.id : null,
        [DEPOSITS]: null,
    }).getByName(product?.name), [product?.name, modifiedPositions]);
    const searchOptions = useMemo(() => ({
        SecurityTypeIds: [1],
        CurrencyIds: [goalDetails.selectedCurrency?.value],
        Page: 1,
        PageSize: 1,
    }), [goalDetails.selectedCurrency]);

    // Hooks
    const {
        isLoadingSecurities, errorSecurities, rawDataSecurities,
    } = useSecurities(adaptSecurities, null, productForSecurity, searchOptions);

    const securityData = useMemo(() => {
        const data = rawDataSecurities?.[0] || {};

        return { ...data, Id: data.id };
    }, [rawDataSecurities]);

    useEffect(() => {
        if (modifiedPositions === undefined) {
            if (securityData.Id) {
                const securityDataWithAllocation = { ...securityData, Allocation: 1 };

                saveWithNewPositions(reviewData, securityDataWithAllocation, onPageChange, []);
            }
        }
        setTimeout(() => { setLoading(false); }, 2000);
    }, [modifiedPositions, onPageChange, reviewData, saveWithNewPositions, securityData]);

    return {
        isLoading: isLoadingSecurities || loading,
        error: errorSecurities,
    };
};
