import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-library';
import './EmptyContent.css';

const EmptyContent = ({
    data, text, buttonText, onClick, children,
}) => {
    if (data === null || (data && data.length === 0)) {
        return (
            <>
                {text && <p className="widget__empty">{text}</p>}
                {buttonText && (
                    <div className="widget__button">
                        <Button type="secondary" size="small" onClick={onClick}>{buttonText}</Button>
                    </div>
                )}
            </>
        );
    }

    return children;
};

EmptyContent.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.shape,
        PropTypes.arrayOf(PropTypes.any),
    ]),
    text: PropTypes.string,
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
};

EmptyContent.defaultProps = {
    data: null,
    text: null,
    onClick: () => {},
    buttonText: null,
};

export default EmptyContent;
