import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import ServiceManager from '../services/ServiceManager';

export const useProposals = (contactId) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [hasNewProposals, setHasNewProposals] = useState(false);
    const { i18n } = useTranslation();

    const checkHasNewProposals = (list) => {
        if (list.length === 0) {
            return false;
        }

        return list.filter((item) => item.PortfolioProposalStatusId === 2).length > 0;
    };

    useEffect(() => {
        const params = { language: i18n.language };

        setError(null);
        setLoading(true);
        ServiceManager.portfolioManagement('getPortfolioProposals', [contactId, params])
            .then((response) => {
                try {
                    setData(response.data);
                    setHasNewProposals(checkHasNewProposals(response.data));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [i18n.language, contactId]);

    return {
        data, hasNewProposals, isLoading, error,
    };
};
