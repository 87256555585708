import { renderers } from 'ui-library';
import { CellDate, CellWithMoreActions, CellWithLinkOrHyperlinkIcon } from 'components/renderers';
import history from '../../services/history';
import { EXECUTION_ONLY } from './pages/Portfolios/pages/common/constants';

const {
    CellWithSubtext, CellLinked, CellWithIcon,
} = renderers;

export const informationColumns = [
    {
        key: 'Information',
        render: CellWithSubtext,
        width: 'calc(100% - 40px)',
    },
    {
        key: 'Icon',
        width: 40,
        render: CellWithLinkOrHyperlinkIcon,
        className: 'text-right',
    },
];

export const portfolioActions = ({
    dfsId, id, productName, t, withEditActions = true, withPendingOrders, withFundingInformation,
}) => {
    if (withPendingOrders) {
        return [
            {
                text: `${t('clientDashboard.portfolio.pendingOrder')}`,
                onClick: () => history.push(`/client/${dfsId}/portfolios/${id}/pending-orders`),
            },
        ];
    }

    if (withFundingInformation) {
        return [
            {
                text: `${t('fundingInformation.title')}`,
                onClick: () => history.push(`/client/${dfsId}/portfolios/${id}/funding-information`),
            },
        ];
    }

    const actions = [{
        text: `${t('clientDashboard.portfolio.openPortfolio')}`,
        onClick: () => history.push(`/client/${dfsId}/portfolios/${id}`),
    }];

    if (withEditActions) {
        const rebalancePortfolioLinks = [
            {
                text: `${t('clientDashboard.portfolio.changeStrategy')}`,
                onClick: () => history.push(`/client/${dfsId}/portfolios/${id}/change-strategy`),
            },
            {
                text: `${t('clientDashboard.portfolio.changeModel')}`,
                onClick: () => history.push(`/client/${dfsId}/portfolios/${id}/change-model`),
            },
            {
                text: `${t('clientDashboard.portfolio.rebalance')}`,
                onClick: () => history.push(`/client/${dfsId}/portfolios/${id}/rebalance`),
            },
        ];

        const editPortfolioLinks = [
            {
                text: `${t('clientDashboard.portfolio.quickEdit')}`,
                onClick: () => history.push(`/client/${dfsId}/portfolios/${id}/quick-edit`),
            },
            {
                text: `${t('clientDashboard.portfolio.modify')}`,
                onClick: () => history.push(`/client/${dfsId}/portfolios/${id}/modify`),
            },
        ];

        if (productName === EXECUTION_ONLY) {
            return actions.concat(editPortfolioLinks);
        }

        return actions.concat(rebalancePortfolioLinks).concat(editPortfolioLinks);
    }

    return actions;
};

// TODO: unused code below, to be deleted after approval
export const informationData = [
    {
        Information: {
            title: 'Full name',
            subtitle: 'Mrs. Sandra Weiler',
        },
        Icon: '',
    },
    {
        Information: {
            title: 'Email',
            subtitle: 'sandra_weiler@hotmail.com',
        },
        Icon: 'envolope',
    },
    {
        Information: {
            title: 'Phone',
            subtitle: ' +41 79 158 95 37',
        },
        Icon: 'phone-call',
    },
    {
        Information: {
            title: 'Skype',
            subtitle: 'sandra.weiler',
        },
        Icon: 'skype',
    },
    {
        Information: {
            title: 'Date of birth',
            subtitle: '01/27/1990',
        },
        Icon: 'bell',
    },
];

export const messagesColumns = [
    {
        key: 'Subject',
        sortable: true,
        render: CellWithSubtext,
    },
    {
        key: 'Date',
        sortable: true,
        render: CellDate,
    },
];

export const messagesData = [
    {
        Subject: {
            title: 'Mrs. Sandra Weller',
            subtitle: 'Lorem ipsum dolor sit amet…',
        },
        Date: '10:43',
    },
    {
        Subject: {
            title: 'Mrs. Sandra Weller',
            subtitle: 'Lorem ipsum dolor sit amet…',
        },
        Date: '08:49',
    },
    {
        Subject: {
            title: 'Mrs. Sandra Weller',
            subtitle: 'Lorem ipsum dolor sit amet…',
        },
        Date: 'Yesterday',
    },
];

export const messagesSorting = { key: 'Date', direction: 'asc' };

export const documentsColumns = [
    {
        key: 'Document',
        sortable: true,
        render: CellWithSubtext,
    },
    {
        key: 'Date',
        sortable: true,
    },
    {
        key: 'Icon',
        render: CellWithMoreActions,
    },
];

export const documentsData = [
    {
        Document: {
            title: 'Presentation',
            subtitle: 'Mrs. Sandra Weller',
        },
        Date: '09:48',
        Icon: 'action',
    },
    {
        Document: {
            title: 'Contract',
            subtitle: 'Mrs. Sandra Weller',
        },
        Date: 'Yesterday',
        Icon: 'action',
    },
];

export const documentsSorting = { key: 'Date', direction: 'asc' };

export const assetClassesPieChartData = [
    {
        name: 'Cash',
        value: 11340.010,
    },
    {
        name: 'Equities',
        value: 51780.010,
    },
    {
        name: 'Bonds',
        value: 36880.010,
    },
];

export const invPrfLineChartData = [
    {
        data: [
            [1167609600000, -1],
            [1167696000000, 1],
            [1167782400000, 2.5],
            [1167868800000, 1],
            [1167955200000, 0.5],
            [1168214400000, 3],
        ],
    },
];

export const invPrfLineChartValueFormat = {
    symbol: '%',
    precision: 0,
    thousand: ',',
    decimal: '.',
    format: '%v%s',
};

export const invPrfSelectOptions = [
    'YTD', 'Last 12 months', '2 Years', '5 Years', 'Max',
];

export const overviewSelectOptions = [
    'Month', 'Year',
];

export const portfoliosColumns = [
    {
        key: 'Name',
        sortable: true,
        render: CellLinked,
    },
    {
        key: 'Product',
    },
    {
        key: 'Profile',
    },
    {
        key: 'Value',
    },
    {
        key: 'Performance',
        title: 'Performance s.i.',
        className: 'table-cell_highlighted',
    },
    {
        key: 'Icon',
        title: 'columns',
        headerRenderer: CellWithIcon,
        render: CellWithMoreActions,
    },
];

export const portfoliosData = [
    {
        Name: 'C000027593',
        Product: 'Execution only',
        Profile: 'Conservative',
        Value: '2.130.291,10',
        Performance: '+24.59%',
        Icon: 'action',
    },
    {
        Name: 'C0000271997',
        Product: 'Discretionary',
        Profile: 'Balanced',
        Value: '771.277,90',
        Performance: '+5.98%',
        Icon: 'action',
    },
    {
        Name: 'C000027593',
        Product: 'Execution only',
        Profile: 'Conservative',
        Value: '2.130.291,10',
        Performance: '+24.59%',
        Icon: 'action',
    },
    {
        Name: 'C0000271997',
        Product: 'Discretionary',
        Profile: 'Balanced',
        Value: '771.277,90',
        Performance: '+5.98%',
        Icon: 'action',
    },
];

export const portfoliosSorting = { key: 'Name', direction: 'asc' };

export const infoboxText = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.';

export const RISK_PROFILE_SELECTED = 'riskProfileSelected';
