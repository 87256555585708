import React from 'react';
import {
    ContentBox, Table,
    Button,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import PageHeader from 'components/PageHeader';
import { portfoliosColumns } from '../../constants/constants';
import PageSection from '../PageSection';
import Preloader from '../Preloader';
import EmptyContent from '../EmptyContent';
import './Portfolios.css';

function Portfolios({
    data, isLoading, error, onOpenNewPortfolio, link, pageSize,
}) {
    const { t } = useTranslation();
    const { getFormattedNumber } = useFormatting();

    return (
        <PageSection>
            <ContentBox className="portfolios table-without-last-row-padding">
                <PageHeader
                    title={t('clientDashboard.portfolios.title')}
                    link={link && `${link}/portfolios`}
                >
                    <Button
                        type="secondary"
                        size="small"
                        onClick={onOpenNewPortfolio}
                    >
                        {t('clientDashboard.portfolios.openNewPortfolio')}
                    </Button>
                </PageHeader>
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data} text={t('clientDashboard.portfolios.noPortfolios')}>
                        <Table
                            data={data}
                            columns={portfoliosColumns(t, getFormattedNumber)}
                            paging={{
                                pageSize,
                            }}
                            headerControls={{
                                selectControlsLabel: {
                                    selectAll: t('headerControls.selectAll'),
                                    reset: t('headerControls.reset'),
                                },
                            }}
                        />
                    </EmptyContent>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

Portfolios.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            Name: PropTypes.shape({ label: PropTypes.string, link: PropTypes.string }),
            Product: PropTypes.string,
            Profile: PropTypes.string,
            Value: PropTypes.string,
            Performance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            Icon: PropTypes.string,
        }),
    ),
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    onOpenNewPortfolio: PropTypes.func,
    link: PropTypes.string,
    pageSize: PropTypes.number,
};

Portfolios.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
    onOpenNewPortfolio: () => { },
    link: '',
    pageSize: 9,
};

export default Portfolios;
