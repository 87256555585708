import React from 'react';
import PropTypes from 'prop-types';
import { getClientId } from 'utils';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import ClientServiceProvider from 'domain/ClientService/context/Provider';
import { useClientLayout } from '../../hooks/useClientLayout';
import Details from './Details';

const DetailsContainer = (props) => {
    useCancelAllRequests();
    useClientLayout();

    const { match: { params: { dfsClientId } } } = props;
    const contactId = getClientId(dfsClientId);

    return (
        <ClientServiceProvider options={{ contactId }}>
            <Details {...props} />
        </ClientServiceProvider>
    );
};

DetailsContainer.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ dfsClientId: PropTypes.string }),
    }).isRequired,
};

DetailsContainer.defaultProps = {
};

export default DetailsContainer;
