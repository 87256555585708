import {
    NA,
    validateStringData,
} from 'utils/formatting';
import { getParent } from 'utils/index';
import { LIQUIDITY_TYPE } from 'constants/constants';
import { parseXml } from 'utils/xmlParser';

export const adaptSecurities = (data, { baseUrl, language }) => {
    const securities = data || [];
    const securitiesData = securities.map(({
        assetClass, currency, id, isin, name, type,
    }) => ({
        key: id,
        Name: {
            id,
            label: validateStringData(name),

            link: type?.name && parseXml(type?.name)?.root?.[language]?.['#text'] === LIQUIDITY_TYPE ? undefined : `${baseUrl}${id}`,
            maxLength: 33,
            name: 'position',
        },
        Id: id,
        Isin: validateStringData(isin),
        AssetClass: assetClass ? parseXml(validateStringData(getParent(assetClass).name))?.root?.[language]?.['#text'] : NA,
        SubAssetClass: assetClass ? parseXml(validateStringData(getParent(assetClass).name))?.root?.[language]?.['#text'] : NA,
        Currency: currency ? validateStringData(currency.isoCode) : NA,
        Type: type ? parseXml(validateStringData(type?.name))?.root?.[language]?.['#text'] : NA,
    }));


    return { securitiesData };
};
