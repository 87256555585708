import { formatCurrencyWithPrecision, formatPercentage, formatWithDate } from 'utils/formatting';
import { adaptPlotsChart } from 'adaptors/adaptPlotsChart';


const adaptPlots = ({
    data,
    chartStartDate,
    filter,
    key,
    multiplyBy,
    useFirstValueForFlat,
}) => {
    const adaptValue = (item) => Math.round(item[key] * multiplyBy) / 100;

    return adaptPlotsChart({
        data,
        chartStartDate,
        filter,
        adaptValue,
        useFirstValueForFlat,
    });
};

export const adaptAssetDevelopment = (
    data,
    currency,
    chartStartDate,
    filter,
    getFormattedNumber,
    getFormattedCurrency,
    getFormattedDate,
) => {
    const adaptedInvestmentOverviewPerformance = {
        performance: [
            {
                data: adaptPlots({
                    data,
                    chartStartDate,
                    filter,
                    key: 'Value',
                    multiplyBy: 10000,
                }),
                tooltipFormat: (number, date) => (getFormattedNumber
                    ? formatWithDate(`${getFormattedNumber(number)}%`, date, getFormattedDate)
                    : formatWithDate(formatPercentage(number), date, getFormattedDate)
                ),
            },
        ],
        assets: [
            {
                data: adaptPlots({
                    data,
                    chartStartDate,
                    filter,
                    key: 'Amount',
                    multiplyBy: 100,
                    useFirstValueForFlat: true,
                }),
                tooltipFormat: (number, date) => formatWithDate(
                    getFormattedCurrency
                        ? getFormattedCurrency(number, { currency })
                        : formatCurrencyWithPrecision(number, 0, currency),
                    date,
                    getFormattedDate,
                ),
            },
        ],
    };

    return adaptedInvestmentOverviewPerformance;
};
