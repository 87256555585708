import {
    memberIdSelector,
} from 'redux/auth/authSelectors';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptMessages } from '../adapters/adaptMessages';

export const useMessages = () => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const { i18n } = useTranslation();
    const memberId = useSelector(memberIdSelector);

    useEffect(() => {
        ServiceManager.messageService('getMemberInboxMessages', [{ PageNumber: 1, pageSize: 5, memberId }])
            .then((response) => {
                try {
                    setMessages(adaptMessages(response.data));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, [i18n.language, memberId]);

    return { data: messages, isLoading, error };
};
