import React, {
    createContext, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { post } from '@additiv-modularity/event-bus';
import { useGetClientInfo } from './hooks/useGetClientInfo';

const CLIENT_NAME = 'CLIENT_NAME';
const CLIENT_IS_PROSPECT = 'CLIENT_IS_PROSPECT';

export const clearClient = () => {
    sessionStorage.removeItem(CLIENT_NAME);
    sessionStorage.removeItem(CLIENT_IS_PROSPECT);
};

const ClientProfileContext = createContext({});
const ClientProfileProvider = (props) => {
    const {
        clientId,
        children,
        clientName: clientNameProp,
        isProspect: isProspectProp,
        withAdditionalInfo,
    } = props;
    const clientInfo = useGetClientInfo({ clientId, withAdditionalInfo });
    const { current: clientNameState } = useRef(clientNameProp);
    const clientNameCached = sessionStorage.getItem(CLIENT_NAME);
    const clientName = useMemo(() => clientNameCached
        || clientNameState
        || clientInfo.data.Name
        || '',
    [clientNameCached, clientNameState, clientInfo.data.Name]);

    useEffect(() => {
        if (clientName?.length) sessionStorage.setItem(CLIENT_NAME, clientName);
    }, [clientName]);

    useEffect(() => {
        post('client/id', { id: clientId });
    }, [clientId]);

    const { current: isProspectState } = useRef(isProspectProp);
    const isProspectCached = sessionStorage.getItem(CLIENT_IS_PROSPECT) === 'true';
    const isProspect = useMemo(() => isProspectCached || isProspectState,
        [isProspectCached, isProspectState]);

    useEffect(() => {
        if (typeof isProspect === 'boolean') sessionStorage.setItem(CLIENT_IS_PROSPECT, isProspect);
    }, [isProspect]);

    useEffect(() => () => clearClient(), []);

    return (
        <ClientProfileContext.Provider
            value={{
                clientId, clientName, isProspect, ...clientInfo,
            }}
        >
            {children}
        </ClientProfileContext.Provider>
    );
};

ClientProfileProvider.propTypes = {
    clientId: PropTypes.number,
    clientName: PropTypes.string,
    isProspect: PropTypes.bool,
    withAdditionalInfo: PropTypes.bool,
    children: PropTypes.node,
};

ClientProfileProvider.defaultProps = {
    clientId: undefined,
    clientName: undefined,
    isProspect: undefined,
    withAdditionalInfo: true,
    children: null,
};

export {
    ClientProfileContext,
    ClientProfileProvider,
};
