import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { subtractUnits } from 'utils/datetime';

const initialState = {
    data: null,
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useSignedDocument = (options = {}) => {
    const {
        clientId,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);
    const { t } = useTranslation();

    // Callbacks
    const getSignedDocument = useCallback(async () => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const { data: metadata } = await SM.documents('getMetadataByReferenceId', ['InvestmentContract']);
            const params = {
                personIds: [clientId],
                createDateFrom: subtractUnits(new Date(), 1, 'years'),
                createDateTo: new Date(),
                page: 1,
                pageSize: 1,
                sortBy: 'CreateDate',
                searchBy: 'OwnerOnly',
                sortOrder: 'Descending',
                documentMetadataId: metadata?.id,
                searchTerm: t('onBoarding.contract.signed'),
            };
            const { data: documents } = await SM.documents('postDocumentsList', [params]);
            const documentData = (documents?.results || [])?.[0];

            dispatch({ type: 'setData', payload: documentData });
            dispatch({ type: 'setIsLoading', payload: false });

            return documentData;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, t]);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getSignedDocument,
    };
};
