import React, { useState } from 'react';
import {
    TabPane, Tabs, TabsExtraItem,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import PropTypes from 'prop-types';
import { allocationPercentFormat, formatCurrencyWithPrecision } from 'utils/formatting';
import { PIE_SA, BAR, LIST } from 'constants/constants';
import ChartType from 'components/ChartType';

function Allocation({ data, isLoading, error }) {
    const { t } = useTranslation();
    const [chartView, setChartView] = useState(PIE_SA);
    const changeView = (type) => () => setChartView(type);
    const numberFormat = (number) => formatCurrencyWithPrecision(number, 0, data.currency);
    const labelFormat = (label, value) => `<label class="Form-control-label"><span>${label}</span></label>${numberFormat(value?.y || value)}`;

    return (
        <Preloader isLoading={isLoading} error={error}>
            <Tabs
                className="investment-allocation"
                defaultActiveKey="1"
                tabBarExtraContent={(
                    <>
                        <TabsExtraItem
                            icon="chart-pie"
                            isActive={chartView === PIE_SA}
                            onClick={changeView(PIE_SA)}
                        />
                        <TabsExtraItem
                            icon="chart-bar-horizontal"
                            isActive={chartView === BAR}
                            onClick={changeView(BAR)}
                        />
                        <TabsExtraItem
                            icon="list"
                            isActive={chartView === LIST}
                            onClick={changeView(LIST)}
                        />
                    </>
                )}
            >
                {
                    data.chart.map((item, i) => (
                        <TabPane tab={item.name} key={(i + 1).toString()}>
                            <EmptyContent
                                data={item.data[chartView].data}
                                text={t('advisoryDashboard.noData')}
                            >
                                <ChartType
                                    data={item.data[chartView].data}
                                    title={item.title || item.name}
                                    labels={item.data[chartView].labels}
                                    currency={data.currency}
                                    chartView={chartView}
                                    labelFormat={labelFormat}
                                    numberFormat={numberFormat}
                                    percentFormat={allocationPercentFormat}
                                />
                            </EmptyContent>
                        </TabPane>
                    ))
                }
            </Tabs>
        </Preloader>
    );
}

Allocation.propTypes = {
    data: PropTypes.shape({
        chart: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            data: PropTypes.shape({
                [PropTypes.string]: PropTypes.arrayOf(PropTypes.shape({
                    name: PropTypes.string,
                    value: PropTypes.number,
                })),
            }),
        })),
        currency: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};

Allocation.defaultProps = {
    data: { chart: [] },
    isLoading: true,
    error: null,
};

export default Allocation;
