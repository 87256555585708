import {
    useCallback, useReducer,
} from 'react';
import ServiceManager from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';

const initialState = {
    deleteInteraction: null,
    errorDeleteInteraction: null,
    isLoadingDeleteInteraction: false,
    updateInteraction: null,
    errorUpdateInteraction: null,
    isLoadingUpdateInteraction: false,
    createInteraction: null,
    errorCreateInteraction: null,
    isLoadingCreateInteraction: false,
    linkInteraction: null,
    errorLinkInteraction: null,
    isLoadingLinkInteraction: false,
    updateInteractionJournal: null,
    errorUpdateInteractionJournal: null,
    isLoadingUpdateInteractionJournal: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setDeleteInteractionData':
            return { ...state, deleteInteraction: action.payload };
        case 'setDeleteInteractionError':
            return { ...state, errorDeleteInteraction: action.payload };
        case 'setDeleteInteractionIsLoading':
            return { ...state, isLoadingDeleteInteraction: action.payload };
        case 'setUpdateInteractionData':
            return { ...state, updateInteraction: action.payload };
        case 'setUpdateInteractionError':
            return { ...state, errorUpdateInteraction: action.payload };
        case 'setUpdateInteractionIsLoading':
            return { ...state, isLoadingUpdateInteraction: action.payload };
        case 'setCreateInteractionData':
            return { ...state, createInteraction: action.payload };
        case 'setCreateInteractionError':
            return { ...state, errorCreateInteraction: action.payload };
        case 'setCreateInteractionIsLoading':
            return { ...state, isLoadingCreateInteraction: action.payload };
        case 'setLinkInteractionData':
            return { ...state, linkInteraction: action.payload };
        case 'setLinkInteractionError':
            return { ...state, errorLinkInteraction: action.payload };
        case 'setLinkInteractionIsLoading':
            return { ...state, isLoadingLinkInteraction: action.payload };
        case 'setUpdateInteractionJournalData':
            return { ...state, updateInteractionJournal: action.payload };
        case 'setUpdateInteractionJournalError':
            return { ...state, errorUpdateInteractionJournal: action.payload };
        case 'setUpdateInteractionJournalIsLoading':
            return { ...state, isLoadingUpdateInteractionJournal: action.payload };
        default:
            return state;
    }
};


export const useInteractionModify = (interactionModifyOptions = {}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { clientId } = (interactionModifyOptions || {});

    // Callbacks
    const deleteInteraction = useCallback(async (id) => {
        dispatch({ type: 'setDeleteInteractionError', payload: null });
        dispatch({ type: 'setDeleteInteractionIsLoading', payload: true });

        const params = { IsCanceled: true };

        try {
            await ServiceManager.contactInteraction('patchInteractions', [id, params]);

            dispatch({ type: 'setDeleteInteractionData', payload: id });
            dispatch({ type: 'setDeleteInteractionIsLoading', payload: false });

            return id;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setDeleteInteractionError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setDeleteInteractionIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);

    const linkInteraction = useCallback(
        async (options = {}) => {
            dispatch({ type: 'setLinkInteractionError', payload: null });
            dispatch({ type: 'setLinkInteractionIsLoading', payload: true });

            const { interactionId, journalId } = (options || {});

            try {
                const { data: response } = await ServiceManager.contactInteraction('linkJournals', [interactionId, { JournalIds: [journalId] }]);

                dispatch({ type: 'setLinkInteractionData', payload: response });
                dispatch({ type: 'setLinkInteractionIsLoading', payload: false });

                return (response.data);
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError: (val) => dispatch({ type: 'setUpdateInteractionError', payload: val }),
                        setLoading: (val) => dispatch({ type: 'setUpdateInteractionIsLoading', payload: val }),
                    }),
                )(err);

                throw err.type !== undefined ? err : new ServerError(err);
            }
        },
        [],
    );

    const updateInteraction = useCallback(async (options = {}) => {
        dispatch({ type: 'setUpdateInteractionError', payload: null });
        dispatch({ type: 'setUpdateInteractionIsLoading', payload: true });

        const {
            patchInteractionOptions,
            createJournalOptions,
            interactionId,
            isUpdateJournal = true,
        } = (options || {});

        try {
            const { data: interaction } = await ServiceManager.contactInteraction('patchInteractions', [interactionId, patchInteractionOptions]);

            if (isUpdateJournal) {
                const { data: journal } = await ServiceManager.contactInteraction('createJournals', [clientId, createJournalOptions]);

                await linkInteraction({ interactionId, journalId: journal?.Id });
            }

            dispatch({ type: 'setUpdateInteractionData', payload: interaction });
            dispatch({ type: 'setUpdateInteractionIsLoading', payload: false });

            return interaction;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setUpdateInteractionError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setUpdateInteractionIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId]);


    const updateInteractionJournal = useCallback(async (options = {}) => {
        dispatch({ type: 'setUpdateInteractionJournalError', payload: null });
        dispatch({ type: 'setUpdateInteractionJournalIsLoading', payload: true });

        const {
            postJournalOptions,
            interactionId,
        } = (options || {});

        try {
            const { data: journal } = await ServiceManager.contactInteraction('postJournal', [clientId, postJournalOptions]);

            const linkedInteraction = await linkInteraction(
                { interactionId, journalId: journal?.Id },
            );

            dispatch({ type: 'setUpdateInteractionJournalData', payload: linkedInteraction });
            dispatch({ type: 'setUpdateInteractionJournalIsLoading', payload: false });

            return linkedInteraction;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setUpdateInteractionJournalError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setUpdateInteractionJournalIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId]);

    const createInteraction = useCallback(async (options = {}) => {
        dispatch({ type: 'setCreateInteractionError', payload: null });
        dispatch({ type: 'setCreateInteractionIsLoading', payload: true });

        const {
            postInteractionOptions,
            postJournalOptions,
            journalContactId,
        } = (options || {});

        try {
            const { data: interaction } = await ServiceManager.contactInteraction('postInteractions', [postInteractionOptions]);

            const journal = await ServiceManager.contactInteraction('postJournal', [journalContactId, postJournalOptions]);

            const linkedInteraction = linkInteraction(
                { interactionId: interaction?.Id, journalId: journal?.data?.Id },
            );

            dispatch({ type: 'setCreateInteractionData', payload: interaction });
            dispatch({ type: 'setCreateInteractionIsLoading', payload: false });

            return linkedInteraction;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setCreateInteractionError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setCreateInteractionIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId]);

    return {
        deleteInteraction,
        errorDeleteInteraction: state.errorDeleteInteraction,
        isLoadingDeleteInteraction: state.isLoadingDeleteInteraction,
        dataDeleteInteraction: state.deleteInteraction,
        updateInteraction,
        errorUpdateInteraction: state.errorUpdateInteraction,
        dataUpdateInteraction: state.updateInteraction,
        isLoadingUpdateInteraction: state.isLoadingUpdateInteraction,
        createInteraction,
        errorCreateInteraction: state.errorCreateInteraction,
        dataCreateInteraction: state.createInteraction,
        isLoadingCreateInteraction: state.isLoadingCreateInteraction,
        updateInteractionJournal,
        errorUpdateInteractionJournal: state.errorUpdateInteractionJournal,
        dataUpdateInteractionJournal: state.updateInteractionJournal,
        isLoadingUpdateInteractionJournal: state.isLoadingUpdateInteractionJournal,
    };
};
