import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-library';
import { getClassNames, getParamsFromUrl } from 'utils';
import { useCustomPanel } from '../../hooks/useCustomPanel';
import './LeftNavigation.css';

const FORMIO_CLASS = 'formio-form';
const FORMIO_PANEL_BASE_CLASS = 'formio-component-';
const CUSTOM_CLASS = 'custom-form';
const CUSTOM_PANEL_BASE_CLASS = 'custom-component-';

const LeftNavigation = (props) => {
    const {
        form, schema, onChange, openedTab,
    } = props;
    const [active, setActive] = useState();
    const [activeSubLabel, setActiveSubLabel] = useState();
    const tabFromUrl = getParamsFromUrl('defaultTab');
    const defaultTab = tabFromUrl || openedTab;

    const formIoContent = useCustomPanel(schema.formIo);
    const customContent = useCustomPanel(schema.custom);

    const content = [...formIoContent, ...customContent];

    // Callbacks
    const changeActive = (key) => {
        setActive(key);
        if (typeof onChange === 'function') onChange(key);
    };
    const onMenuItemClick = ({ key, custom }) => {
        let block;

        if (custom) {
            block = (document).querySelector(`.${CUSTOM_PANEL_BASE_CLASS}${key}`);
        } else {
            block = (form || document).querySelector(`.${FORMIO_PANEL_BASE_CLASS}${key}`);
        }

        if (block) {
            block.scrollIntoView({ block: 'start', behavior: 'smooth' });
            setActiveSubLabel(key);
        }
    };
    const setActiveBlock = ({ key }, disableScroll) => {
        if (tabFromUrl !== key) {
            window.history.pushState({}, document.title, window.location.pathname);
        }
        const customBlocks = (document)?.querySelectorAll(`.${CUSTOM_CLASS} > .${CUSTOM_PANEL_BASE_CLASS}panel`);

        customBlocks.forEach((customBlock) => {
            if (customBlock.classList.contains(`${CUSTOM_PANEL_BASE_CLASS}${key}`)) customBlock.classList.add('active');
            else customBlock.classList.remove('active');
        });

        const blocks = (form || document)?.querySelectorAll(`.${FORMIO_CLASS} > .${FORMIO_PANEL_BASE_CLASS}panel`);

        blocks.forEach((block) => {
            if (block.classList.contains(`${FORMIO_PANEL_BASE_CLASS}${key}`)) block.classList.add('active');
            else block.classList.remove('active');
        });
        if (!disableScroll && form && form.getBoundingClientRect()?.top < 120) {
            form.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    };
    const onGroupTitleClick = ({ key, subLabels, custom }, disableScroll = false) => {
        if (!subLabels?.length) onMenuItemClick({ key, custom });
        changeActive(key);

        setActiveBlock({ key }, disableScroll);
        setActiveSubLabel(null);
    };
    const renderSubLabels = (subLabels, key) => {
        if (!subLabels?.length) return null;

        return (
            <div className={getClassNames('sub-block', { 'sub-block__opened': active === key })}>
                {subLabels.map((subLabel) => (
                    <Button
                        key={subLabel.key}
                        type="link"
                        className={getClassNames('sub-label', { active: activeSubLabel === subLabel.key })}
                        onClick={() => onMenuItemClick(subLabel)}
                    >
                        {subLabel.title}
                    </Button>
                ))}
            </div>
        );
    };

    // Effects;
    useEffect(() => {
        if (defaultTab && !active) {
            onGroupTitleClick((content || []).find((i) => i.key === defaultTab), true);
        }
    }, [defaultTab, active]);

    if (!schema) return null;

    return (
        <div className="client__navigation">
            {content.map((item) => (
                <Fragment key={item.key}>
                    <Button
                        type="link"
                        className={getClassNames({ active: active === item.key })}
                        onClick={() => onGroupTitleClick(item)}
                    >
                        {item.title}
                    </Button>
                    {renderSubLabels(item.subLabels, item.key)}
                </Fragment>
            ))}
        </div>
    );
};

LeftNavigation.propTypes = {
    openedTab: PropTypes.string,
    form: PropTypes.oneOfType([PropTypes.node, null]),
    schema: PropTypes.shape({
        formIo: PropTypes.shape({
            [PropTypes.string]: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.shape,
            ]),
        }),
        custom: PropTypes.shape({
            [PropTypes.string]: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.shape,
            ]),
        }),
    }),
    onChange: PropTypes.func,
};

LeftNavigation.defaultProps = {
    openedTab: null,
    form: null,
    schema: null,
    onChange: null,
};

export default LeftNavigation;
