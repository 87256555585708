import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    // eslint-disable-next-line no-unused-vars
    BreadCrumbs, Table, ContentBox, Title, Modal,
} from 'ui-library';
import history from 'services/history';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import PageSection from 'components/PageSection';
import WidgetError from 'components/WidgetError';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useFormatting, useLocale } from 'locale';
import {
    orderBookSelector, portfolioSelector, usePortfolioSelector, portfolioQuickEditSelector,
} from 'domain/Portfolio';
import { QUICK_EDIT } from '../../../constants';
import { getQuickColumns } from '../constants';
import { onPreventRedirect } from '../../common/utils';
import { adaptPositions, adaptQuickEditPositions } from '../adapters/adaptQuickEditPositions';

function QuickEditDiscretionary(props) {
    const { numberSeparators } = useLocale();
    const { getFormattedCurrency, getFormattedNumber } = useFormatting();
    const { match: { params: { dfsClientId, portfolioId } } } = props;
    const { t } = useTranslation();

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        positionsAdapted, dataSummary, changes: positionsChanged,
        initializePositions, optimizePositions,
    } = usePortfolioSelector(portfolioQuickEditSelector);
    const {
        data: orderBook, isLoading: isLoadingOrderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    useEffect(() => {
        getOrderBook();
    }, []);

    const baseUrl = `/client/${dfsClientId}/portfolios/${portfolioId}/quick-edit/position/${data?.productId}`;
    const positionsForQuickEdit = useMemo(() => adaptPositions({
        positions: dataRaw?.Positions,
        baseUrl,
        t,
        getFormattedNumber,
        getFormattedCurrency,
    }), [baseUrl, dataRaw.Positions, getFormattedCurrency, getFormattedNumber, t]);
    const adaptQuickEdit = useMemo(() => adaptQuickEditPositions({
        portfolioValue: data?.portfolioValue, baseUrl, t, getFormattedNumber, getFormattedCurrency,
    }), [baseUrl, data.portfolioValue, getFormattedCurrency, getFormattedNumber, t]);
    const storedPositions = useMemo(() => JSON.parse(sessionStorage.getItem(QUICK_EDIT)), []);

    useEffect(() => {
        if (positionsForQuickEdit) {
            initializePositions({
                changes: storedPositions,
                positions: positionsForQuickEdit,
                adaptData: adaptQuickEdit,
            });
        }
    }, [initializePositions, positionsForQuickEdit, adaptQuickEdit, storedPositions]);

    // Callbacks
    const total = +dataSummary?.New;
    const disableOptimizeButton = orderBook || total === 100;
    const disableSubmitButton = orderBook || total !== 100;
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                sessionStorage.removeItem(QUICK_EDIT);
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onContinue = () => {
        if (total === 100) {
            sessionStorage.setItem(QUICK_EDIT, JSON.stringify(positionsChanged));
            history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/quick-edit/confirmation`);
        }
    };

    return (
        <PageSection className="single-portfolio-edit">
            <Preloader
                isLoading={isLoading || isLoadingOrderBook}
                error={error || errorOrderBook}
            >
                <ContentBox underline={false}>
                    <BreadCrumbs current={t('clientDashboard.portfolioEdit.quickEdit')}>
                        <Link
                            to={`/client/${dfsClientId}/portfolios/`}
                            onClick={(e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/`, onCancel)}
                        >
                            {t('clientDashboard.portfolioEdit.portfolios')}
                        </Link>
                        <Link
                            to={`/client/${dfsClientId}/portfolios/${portfolioId}`}
                            onClick={(e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/${portfolioId}`, onCancel)}
                        >
                            {data.title}
                        </Link>
                    </BreadCrumbs>
                    <div className="single-portfolio-edit__header">
                        <Title type={1}>{t('clientDashboard.portfolioEdit.quickEdit')}</Title>
                    </div>
                    <EmptyContent data={positionsAdapted.positions} text={t('clientDashboard.portfolioEdit.noDataFound')}>
                        <div className="content-wrapper">
                            <Table
                                columns={getQuickColumns(t, getFormattedNumber, numberSeparators)}
                                data={positionsAdapted.positions}
                                footer={positionsAdapted.footer}
                                expandedColumn="Name"
                                defaultExpandAllRows
                                headerControls={{
                                    selectControlsLabel: {
                                        selectAll: t('headerControls.selectAll'),
                                        reset: t('headerControls.reset'),
                                    },
                                }}
                            />
                            {
                                (total !== 100)
                                && (<WidgetError accent error message={t('clientDashboard.portfolio.errorSum')} />)
                            }
                        </div>
                        <ButtonsBlockRow
                            leftButton={{
                                type: 'danger',
                                text: t('clientDashboard.portfolioEdit.cancel'),
                                onClick: () => onCancel(`/client/${dfsClientId}/portfolios/${portfolioId}`),
                            }}
                            additionalButton={{
                                text: t('clientDashboard.portfolioEdit.optimize'),
                                disabled: disableOptimizeButton,
                                onClick: optimizePositions,
                            }}
                            primaryButton={{
                                text: t('clientDashboard.portfolioEdit.continue'),
                                disabled: disableSubmitButton,
                                onClick: onContinue,
                            }}
                        />
                    </EmptyContent>
                </ContentBox>
            </Preloader>
        </PageSection>
    );
}

QuickEditDiscretionary.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

QuickEditDiscretionary.defaultProps = {};

export default QuickEditDiscretionary;
