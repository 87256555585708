import { MAX_TWO_COLUMN_GRID_WIDTH } from 'constants/constants';
import { useMemo, useState, useEffect } from 'react';

const getWindowDimensions = () => {
    const width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
    const height = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;

    return { width, height };
};

const detectGridDimensions = ({ width }) => {
    const isOneCol = useMemo(() => width < MAX_TWO_COLUMN_GRID_WIDTH, [width]);

    return { isOneCol };
};

export const useGridDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return detectGridDimensions(windowDimensions);
};
