import {
    getfromDataObject,
    validateStringData,
} from 'utils/formatting';
import { portfolioTypeIds } from 'constants/portfolioTypeIds';
import { uniq } from 'lodash';
import { adaptProposalsByPortfolioId } from 'adaptors/adaptProposals';
import {
    IN_EXECUTION,
    NOT_IMPLEMENTED,
    PENDING_ORDERS,
    portfolioStatuses, portfolioStatusesColors,
    portfolioStatusesIcons,
    sortStatuses,
    BLOCKED, CLOSED, getPortfolioStatus,
    PORTFOLIO_CLOSED_ID,
} from 'constants/portfolioStatuses';
import { EXECUTION_ONLY_ID } from 'constants/portfolioProducts';

export const adaptInvestmentOverview = ({
    data, dfsId,
    portfolioActions, t,
    getFormattedCurrency,
    portfoliosOverview, proposals = [],
}) => {
    let positions;
    const currency = data?.BaseCurrency?.CurrencyCode;

    if (data && data.Positions) {
        positions = data.Positions;
    } else if (data && data.ConsolidatedPositions) {
        positions = data.ConsolidatedPositions;
    }
    const pendingOrders = portfoliosOverview?.reduce((acc, item) => ({
        ...acc,
        [item.Id]: item?.HasPendingOrders,
    }), {}) || {};

    const proposalsAdapted = adaptProposalsByPortfolioId(proposals);

    const portfolios = data?.Portfolios !== undefined
        ? data.Portfolios
            .filter((item) => item.PortfolioTypeId === portfolioTypeIds.MAIN
                && item.PortfolioStatusId !== PORTFOLIO_CLOSED_ID)
            .map((item) => {
                const proposalStatuses = sortStatuses(
                    uniq((proposalsAdapted[item.Id] || []).map(({ status }) => status)),
                );
                const portfolioStatus = getPortfolioStatus({
                    portfolioStatusId: item.PortfolioStatusId,
                    isBreached: item?.IsBreached,
                    isOrderBookBlocked: pendingOrders?.[item.Id],
                    proposalStatus: proposalStatuses?.[0],
                });

                const link = () => {
                    if (portfolioStatus === NOT_IMPLEMENTED) {
                        return `/client/${dfsId}/portfolios/${item.Id}/funding-information`;
                    }
                    if (portfolioStatus === IN_EXECUTION) {
                        return `/client/${dfsId}/portfolios/${item.Id}/pending-orders`;
                    }

                    return `/client/${dfsId}/portfolios/${item.Id}`;
                };

                const isStrategyHidden = EXECUTION_ONLY_ID.includes(getfromDataObject(item, 'Product.Id'));

                return {
                    Name: {
                        label: item.Name,
                        link: link(),
                        disabled: portfolioStatus === CLOSED,
                    },
                    ProductId: item?.Product.Id,
                    Product: getfromDataObject(item, 'Product.Name'),
                    Status: {
                        text: portfolioStatuses(t)[portfolioStatus] || '-',
                        icon: portfolioStatusesIcons[portfolioStatus] || '',
                        color: portfolioStatusesColors[portfolioStatus] || '',
                        value: portfolioStatus,
                    },
                    Profile: isStrategyHidden ? '' : validateStringData(item.RiskCategory.Name),
                    Value:
                        getFormattedCurrency(
                            item.CurrentValue, { currency: item.Currency.CurrencyCode },
                        ),
                    Performance: item.CurrentPerformance
                        ? item.CurrentPerformance * 100 : undefined,
                    Actions: {
                        value: 'action',
                        actions: portfolioActions({
                            dfsId,
                            id: item.Id,
                            productName: item.Product.Name,
                            t,
                            withEditActions:
                                portfolioStatus !== PENDING_ORDERS && portfolioStatus !== BLOCKED,
                            withPendingOrders: portfolioStatus === IN_EXECUTION,
                            withFundingInformation: portfolioStatus === NOT_IMPLEMENTED,
                        }),
                        disabled: portfolioStatus === CLOSED,
                    },
                };
            })
        : [];

    return {
        overview: {
            currency,
            securityValue: data.SecuritiesValue,
            totalValue: data.CurrentValue,
            cashAmount: data.CashAmount,
            performanceValue: data.CurrentPerformance * 100,
            performanceAmount: data.CurrentPerformanceAmount,
        },
        positions,
        portfolios,
        productIds: portfolios.map(({ ProductId }) => ProductId),
    };
};
