import {
    useCallback, useReducer,
} from 'react';
import ServiceManager from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useTranslation } from 'react-i18next';
import ServerError from 'errors/ServerError';
import { useAllContacts } from 'hooks/useAllContacts';

const initialState = {
    interactions: [],
    errorInteractions: null,
    isLoadingInteractions: false,
    interactionDetails: null,
    errorInteractionDetails: null,
    isLoadingInteractionDetails: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setInteractionsData':
            return { ...state, interactions: action.payload };
        case 'setInteractionsError':
            return { ...state, errorInteractions: action.payload };
        case 'setInteractionsIsLoading':
            return { ...state, isLoadingInteractions: action.payload };
        case 'setInteractionDetailsData':
            return { ...state, interactionDetails: action.payload };
        case 'setInteractionDetailsError':
            return { ...state, errorInteractionDetails: action.payload };
        case 'setInteractionDetailsIsLoading':
            return { ...state, isLoadingInteractionDetails: action.payload };
        default:
            return state;
    }
};

export const useInteractionsData = (interactionsDataOptions = {}) => {
    const {
        clientId,
        memberId,
    } = (interactionsDataOptions || {});

    const [state, dispatch] = useReducer(reducer, initialState);
    const { t } = useTranslation();
    const { contactsRawData } = useAllContacts({ take: 1000 });

    // Callbacks
    const getInteractions = useCallback(async (params) => {
        dispatch({ type: 'setInteractionsError', payload: null });
        dispatch({ type: 'setInteractionsIsLoading', payload: true });

        try {
            const response = await ServiceManager.contactInteraction('getInteractions', [clientId, params]);

            dispatch({ type: 'setInteractionsData', payload: response?.data });
            dispatch({ type: 'setInteractionsIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setInteractionsError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setInteractionsIsLoading', payload: val }),
                }),
            )(err);

            return null;
        }
    }, [clientId]);

    const getMembersProfile = useCallback(async (memberIds) => {
        try {
            const membersProfile = await Promise.all((memberIds || [])?.map(
                async (Id) => ({
                    MemberId: Id,
                    ...(await ServiceManager.contactManagement('getMembersProfile', [Id])).data,
                }),
            ));

            return membersProfile;
        } catch (err) {
            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);

    const getInteractionDetails = useCallback(async (interactionDetailsOptions = {}) => {
        const {
            interactionParams,
            journalsParams,
        } = (interactionDetailsOptions || {});

        dispatch({ type: 'setInteractionDetailsError', payload: null });
        dispatch({ type: 'setInteractionDetailsIsLoading', payload: true });

        try {
            const { data: interaction } = await ServiceManager.contactInteraction('getInteraction', [interactionParams]);

            const { contacts: { items: memberClients } } = contactsRawData;

            const { data: journals } = await ServiceManager.contactInteraction('getJournals', [journalsParams]);

            const memberClientsIds = memberClients?.map((i) => i.id);

            const participantsWithoutClientsIds = interaction?.Participants
                ?.filter((i) => !memberClientsIds?.includes(i?.PersonId))?.map((i) => i?.PersonId)
                ?.filter((i) => i !== memberId);

            const membersProfile = await getMembersProfile(participantsWithoutClientsIds);

            dispatch({
                type: 'setInteractionDetailsData',
                payload: {
                    interaction, memberClients, journals, membersProfile,
                },
            });
            dispatch({ type: 'setInteractionDetailsIsLoading', payload: false });

            return {
                interaction, memberClients, journals, membersProfile,
            };
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setInteractionDetailsError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setInteractionDetailsIsLoading', payload: val }),
                }),
            )(err);

            return null;
        }
    }, [t, memberId, contactsRawData]);

    return {
        getInteractions,
        dataInteractions: state.interactions,
        errorInteractions: state.errorInteractions,
        isLoadingInteractions: state.isLoadingInteractions,
        getInteractionDetails,
        dataInteractionDetails: state.interactionDetails,
        errorInteractionDetails: state.errorInteractionDetails,
        isLoadingInteractionDetails: state.isLoadingInteractionDetails,
    };
};
