/* eslint-disable no-param-reassign,no-multi-assign */
import {
    getfromDataObject, validateNumber, validateData,
    formatCurrency,
    formatBigNumber,
    percentIsZero, NA,
} from 'utils/formatting';
import { sortAssets } from 'utils/sortingAllocation';
import { formatDate } from 'utils/datetime';
import { orderBy } from 'lodash/fp';
import { PIE_SA, BAR, LIST } from 'constants/constants';
import { getParent, roundAllocations } from 'utils';
import { adaptAllocationGroups } from 'adaptors/adaptAllocationGroups';

const sum = (data, prop) => (
    data.reduce((accu, item) => (
        accu + Number(item[prop])
    ), 0)
);

const groupPositions = (data, portfolioCurrency, investAmount) => {
    const roundedAllocation = data.length && roundAllocations(data);
    const groupToValues = data.reduce((obj, item) => {
        const securityData = item.Security;
        const accumulator = { ...obj };
        const roundedAlloc = roundedAllocation.find((n) => n.id === securityData.Id).value;

        const accParent = getParent(item.Security.AssetClass).Name;

        accumulator[accParent] = obj[accParent] || [];
        accumulator[accParent].push({
            isin: validateData(securityData.Isin),
            securityId: validateNumber(securityData.Id),
            url: '',
            name: {
                label: validateData(securityData.Name),
                maxLength: 15,
            },
            allocationData: roundedAlloc,
            allocation: `${percentIsZero(roundedAlloc)}%`,
            currency: validateData(securityData.Currency.CurrencyCode),
            units: validateNumber(item.Quantity),
            performance: (validateNumber(item.Performance)),
            latestPrice: validateNumber(item.ValuationPrice),
            valuation: formatCurrency(
                validateNumber(item.Allocation * investAmount),
                portfolioCurrency,
            ),
            subAssetClass: getfromDataObject(item.Security.AssetClass, 'Name'),
            type: getfromDataObject(item.Security.Type, 'Name'),
            country: getfromDataObject(item.Security.Country, 'Name'),
            region: getfromDataObject(item.Security.Country.Continent, 'Name'),
            subSector: getfromDataObject(item.Security.Sector, 'Name'),
            sector: getParent(item.Security.Sector).Name,
            fxRate: formatBigNumber(item.FxRate),
            unrealizedGainLoss: formatBigNumber(item.MonetaryPerformance),
            costPrice: formatBigNumber(item.BuyPrice),
            valuationInSecurityCurrency: formatCurrency(
                validateNumber(item.Allocation * investAmount),
                item.Security.Currency.CurrencyCode,
            ),
            valuationData: validateNumber(item.Allocation * investAmount),
            performanceCCY: validateNumber(item.MonetaryPerformance),
            actions: {
                value: 'action',
            },
        });

        return accumulator;
    }, {});

    let groups = Object.keys(groupToValues).map((key) => {
        const groupMembersData = groupToValues[key];

        return {
            valuation: formatCurrency(sum(groupMembersData, 'valuationData'), portfolioCurrency),
            allocation: `${percentIsZero(formatBigNumber(sum(groupMembersData, 'allocationData')))}%`,
            name: key,
            children: groupMembersData,
            icon: 'action',
            actions: {},
        };
    });

    groups = groups.map((groupItem) => {
        const resultGroupItem = { ...groupItem };

        resultGroupItem.valuation = groupItem.valuation;

        resultGroupItem.children = resultGroupItem.children.map((groupMemberItem) => {
            const resultGroupMemberItem = groupMemberItem;

            resultGroupMemberItem.performance = (groupMemberItem.performance * 100);
            resultGroupMemberItem.valuation = (groupMemberItem.valuation);
            resultGroupMemberItem.icon = 'action';

            return resultGroupMemberItem;
        });

        return resultGroupItem;
    });

    return sortAssets(groups);
};

const getAllocations = (data) => {
    const allocations = [];

    data.forEach((item) => {
        allocations.push({
            Allocation: +item.Allocation.toFixed(3),
            InstrumentId: item.Security ? item.Security.Id : null,
        });
    });

    return allocations.filter(({ Allocation }) => Allocation !== 0);
};

export const adaptModelPortfolio = (data, investAmount, product, t) => {
    let currency;
    let positions;
    const title = getfromDataObject(data, 'Name') || product.name;

    if (data && data.Currency) {
        currency = data.Currency;
    } else if (data && data.BaseCurrency) {
        currency = data.BaseCurrency;
    }

    if (data && data.Positions) {
        positions = data.Positions;
    } else if (data && data.ConsolidatedPositions) {
        positions = data.ConsolidatedPositions;
    }

    return {
        title,
        backTitle: title,
        currency,
        productId: product.id,
        overview: {
            portfolioValue: investAmount,
            currency: currency.CurrencyCode,
            strategy: getfromDataObject(data, 'RiskCategory.Name') || NA,
            product: product.name,
            performance: data.CurrentPerformance * 100,
            sharpRatio: data.SharpeRatio,
            expectedReturn: data.CalculatedExpectedReturn * 100,
            expectedRisk: data.CalculatedRisk * 100,
            firstInvestment: formatDate(data.FirstInvestmentDate),
        },
        investmentAllocation: {
            chart: adaptAllocationGroups({
                positions,
                currency: currency.CurrencyCode,
                t,
                securitiesValue: investAmount,
                chartTypesList: [PIE_SA, BAR, LIST],
            }),
            currency: currency.CurrencyCode,
        },
        positions: groupPositions(data.Positions, currency.CurrencyCode, investAmount, t),
        investmentAllocations: getAllocations(data.Positions),
    };
};
