import React, { useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from 'services/history';
import { genarateDFSClientId } from 'utils';
import Registration from './pages/Registration';
import ClientRouter from './ClientRouter';
import { useOnBoardingLayout } from './components/OnBoardingRouterTemplate/hooks/useOnBoardingLayout';

import './OnBoarding.css';

const Router = (props) => {
    const {
        match: { path }, location: { state },
    } = props;

    useOnBoardingLayout();

    const onNext = useCallback(({ contactId, clientName }) => {
        history.push(`${path}/${genarateDFSClientId(contactId)}/step/1`, { clientName });
    }, [path]);

    const withProps = (Component) => (defProps) => <Component onNext={onNext} {...defProps} />;

    return (
        <Switch>
            <Route path={`${path}/register`} render={withProps(Registration)} />
            <Route path={`${path}/:dfsClientId`} component={ClientRouter} />
            <Redirect exact from={path} to={{ pathname: `${path}/register`, state }} />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        state: PropTypes.shape({
            clientName: PropTypes.string,
        }),
    }).isRequired,
};

Router.defaultProps = {
};

Router.displayName = 'Router';

export default Router;
