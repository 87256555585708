import { checkNullData } from 'utils';
import { stepValues } from '../mockData';

export const adaptAnswers = (rawData, answers) => {
    if (checkNullData(rawData) || checkNullData(answers)) return [];

    const riskHorizon = rawData.Groups[0].Steps[0].Questions[0].Answers
        .findIndex(({ Id }) => Id === +answers[0]);
    const riskExpectation = rawData.Groups[1].Steps[0].Questions[0].Answers
        .findIndex(({ Id }) => Id === +answers[1]);
    const riskCommon = Math.min(riskHorizon, riskExpectation);

    return [
        { label: stepValues[riskHorizon], score: riskHorizon + 1 },
        { label: stepValues[riskExpectation], score: riskExpectation + 1 },
        { label: stepValues[riskCommon], score: riskCommon + 1 },
    ];
};
