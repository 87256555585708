import {
    Column, ContentBox, Title, Row, Modal,
} from 'ui-library';
import React, {
    useCallback, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Infobox from 'ui-library/dist/components/Infobox';
import { getImageMime } from 'utils';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import { useManagerProfile } from '../../prodivers/managerProfile';
import PageSection from '../../components/PageSection';
import Preloader from '../../components/Preloader';
import { adaptPictureFile } from './adaptors/adaptPictureFile';
import { memberIdSelector } from '../../redux/auth/authSelectors';
import { useChangeProfilePicture } from './hooks/useChangeProfilePicture';
import { LeftMenu } from './components/LeftMenu';
import './EditProfile.css';
import { EditForm } from './components/EditForm';
import { useWithMemberProfiles } from './hooks/useWithMemberProfiles';

const EditProfile = () => {
    const {
        data, isLoading, error, getMemberProfile,
    } = useManagerProfile();
    const { t } = useTranslation();
    const memberId = useSelector(memberIdSelector);
    const { data: superiorData } = useWithMemberProfiles(data?.superiorIds);
    const superiorNames = useMemo(() => (superiorData || []).map((i) => (`${i.FirstName} ${i.LastName}`)), [superiorData]);

    const [uploadParams, setUploadParams] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [sizeError, setSizeError] = useState(false);
    const [extensionError, setExtensionError] = useState(false);
    const onPictureChange = useCallback(async (files) => {
        if (files.length === 0) {
            setUploadParams(null);

            return;
        }
        try {
            const fileParams = await adaptPictureFile(files);

            setUploadParams(fileParams);
            setSizeError(fileParams.size >= 2097152);
            setExtensionError(!['jpg', 'png', 'svg', 'jpeg'].includes(fileParams.extension));
        } catch (err) {
            setUploadError(err);
        }
    }, []);

    const profilePicture = useMemo(() => {
        if (uploadParams?.ImageContentBase64?.length === 0) {
            return null;
        }
        const currentImage = data?.hasProfilePicture ? data?.image : null;

        const newImage = uploadParams?.ImageContentBase64
            ? `data:${getImageMime(uploadParams?.ImageContentBase64)};base64,${uploadParams?.ImageContentBase64}` : null;

        return newImage || currentImage;
    },
    [data?.hasProfilePicture, data?.image, uploadParams?.ImageContentBase64, getImageMime]);

    const {
        changeMemberProfilePicture,
        error: errorChangeProfilePicture,
        isLoading: isLoadingChangeProfilePicture,
        deleteMemberProfilePicture,
    } = useChangeProfilePicture();

    const onSave = useCallback(() => {
        if (sizeError || extensionError) return;

        Modal.confirm({
            title: t('editProfile.saveChangesTitle'),
            content: t('editProfile.saveChangesTitleContent'),
            okText: t('editProfile.yes'),
            cancelText: t('editProfile.no'),
            onOk: async () => {
                if (uploadParams?.ImageContentBase64 !== undefined) {
                    if (uploadParams?.ImageContentBase64?.length === 0) {
                        await deleteMemberProfilePicture(memberId);
                        await getMemberProfile();

                        return;
                    }
                    await changeMemberProfilePicture(memberId,
                        { ImageContentBase64: uploadParams.ImageContentBase64 });
                    await getMemberProfile();
                }
            },
        });
    }, [changeMemberProfilePicture, memberId, uploadParams,
        deleteMemberProfilePicture, sizeError, extensionError]);

    /* Menu */
    const [active, setActive] = useState('personal-information');

    const personalInformation = useRef(null);
    const systemInformation = useRef(null);
    const businessInformation = useRef(null);

    const menuRefs = {
        'personal-information': personalInformation,
        'business-information': businessInformation,
        'system-information': systemInformation,
    };

    const onMenuItemClick = (key) => {
        setActive(key);
        const item = menuRefs[key];

        if (item?.current) {
            item.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    return (
        <PageSection>
            <Preloader
                isLoading={isLoading}
                error={error || uploadError || errorChangeProfilePicture}
            >
                <ContentBox className="edit-profile">
                    <Title type={2} className="underlined with-mb">{t('editProfile.title')}</Title>
                    <Row>
                        <Column size={['sm-2']}>
                            <LeftMenu
                                active={active}
                                onMenuItemClick={onMenuItemClick}
                            />
                        </Column>
                        <Column size={['sm-10']}>
                            <EditForm
                                data={{ ...data, superiorNames }}
                                profilePicture={profilePicture}
                                onPictureChange={onPictureChange}
                                setUploadParams={setUploadParams}
                                menuRefs={menuRefs}
                            />
                        </Column>
                        <Column size={['sm-12']}>
                            {sizeError && <Infobox error>{t('editProfile.profilePicture.sizeError')}</Infobox>}
                            {extensionError && <Infobox error>{t('editProfile.profilePicture.extensionError')}</Infobox>}
                        </Column>
                    </Row>
                    <ButtonsBlockRow
                        additionalButton={{
                            text: t('clientDetails.cancel'),
                            onClick: history.goBack,
                        }}
                        primaryButton={{
                            text: t('clientDetails.save'),
                            loading: isLoadingChangeProfilePicture,
                            onClick: onSave,
                        }}
                    />
                </ContentBox>
            </Preloader>
        </PageSection>
    );
};

EditProfile.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        hash: PropTypes.string,
    }).isRequired,
};

export default EditProfile;
