import PropTypes from 'prop-types';

const CellValue = (value) => (
    value === undefined ? null : value
);

CellValue.propTypes = {
    value: PropTypes.number.isRequired,
};

CellValue.defaultProps = {};

export default CellValue;
