import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { documentsColumns } from 'constants/constants';
import SM from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { subtractUnits } from 'utils/datetime';
import { downloadDmsDocument } from 'utils/downloadDmsDocument';
import ServerError from 'errors/ServerError';
import { adaptDocuments } from '../adapters/adaptDocuments';

export const useWithDocuments = (contactId) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [documents, setDocuments] = useState([]);
    const { i18n } = useTranslation();
    const config = useMemo(() => ({
        personIds: [contactId],
        createDateFrom: subtractUnits(new Date(), 1, 'years'),
        createDateTo: new Date(),
        page: 1,
        pageSize: 10,
        sortBy: 'CreateDate',
        searchBy: 'OwnerOnly',
        sortOrder: 'Descending',
    }), [contactId]);
    const downloadDocument = useCallback((documentId) => {
        SM.documents('getDocumentById', [documentId]).then((response) => {
            try {
                downloadDmsDocument(response.data, { saveFile: true });
            } catch (err) {
                throw new AdapterError(err);
            }
        }).catch(handlerRequestCanceling(
            (err) => (err.type !== undefined ? err : new ServerError(err)),
        ));
    }, []);

    useEffect(() => {
        setLoading(true);
        SM.documents('postDocumentsList', [config])
            .then((response) => {
                try {
                    setDocuments(adaptDocuments(response.data, { language: i18n.language }));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, [i18n.language, contactId, config]);

    const documentData = useMemo(
        () => documents.map((document) => ({
            ...document,
            Icon: {
                ...document.Icon,
                onClickIcon: () => downloadDocument(document.id, document.ownerId),
            },
        })),
        [documents, downloadDocument],
    );

    return {
        data: documentData, isLoading, error, documentsColumns,
    };
};
