import React from 'react';
import PropTypes from 'prop-types';
import Preloader from 'components/Preloader';
import PageNotFound from 'components/PageError/PageNotFoundContent';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject,
} from 'constants/portfolioProducts';
import { usePortfolio } from 'domain/Portfolio';
import RebalanceAdvisory from './products/RebalanceAdvisory';
import RebalanceDiscretionary from './products/RebalanceDiscretionary';
import './Rebalance.css';

const RebalanceByProduct = generateObject({
    [ADVISORY]: RebalanceAdvisory,
    [DISCRETIONARY]: RebalanceDiscretionary,
    [EXECUTION_ONLY]: PageNotFound,
});

function Rebalance(props) {
    const { portfolio: { productId, error } } = usePortfolio();
    const RebalancePage = RebalanceByProduct[productId] || (() => '');

    return (
        <Preloader isLoading={productId === undefined} error={error}>
            <RebalancePage {...props} />
        </Preloader>
    );
}

Rebalance.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

Rebalance.defaultProps = {
};

export default Rebalance;
