import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Button, Column, ContentBox, Paragraph, Title, Row,
} from 'ui-library';
import Preloader from 'components/Preloader';
import { useModelPortfolio } from 'domain/Portfolio';
import { useBankInfo } from 'hooks/useBankInfo';
import { useQuestionnaires } from 'hooks/useQuestionnaires';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useClientProfile } from 'prodivers/clientProfile';
import history from 'services/history';
import { getClientId } from 'utils';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject, DEPOSITS,
} from 'constants/portfolioProducts';
import { useInvestmentContract } from 'pages/OnBoarding/hooks/useInvestmentContract';
import { useAdvisoryProtocol } from 'pages/OnBoarding/hooks/useAdvisoryProtocol';
import { useNewPortfolioLayout } from '../../hooks/useNewPortfolioLayout';
import { useNewPortfolioData } from '../../hooks/useNewPortfolioData';
import './Completion.css';

function Completion(props) {
    useNewPortfolioLayout({ current: 3 });

    const {
        match: { params: { dfsClientId } },
        location: { state: { newPortfolioData } },
    } = props;
    const {
        productId,
        currencyId,
        portfolioId,
        modelPortfolioId,
        allocations,
        proposalId,
    } = newPortfolioData;
    const { t } = useTranslation();

    // Client Provider
    const { ...information } = useClientProfile();
    const clientId = getClientId(dfsClientId);
    const {
        bankInfo, isLoadingBankInfo, Iban, clearIban,
    } = useBankInfo({ contactId: clientId });

    // Model portfolio
    const {
        data: modelData,
        isLoading: isLoadingModel,
    } = useModelPortfolio({ modelPortfolioId, loadInitially: true });

    // New Portfolio Data
    const { newPortfolio, clearNewPortfolio } = useNewPortfolioData();
    const showBenchmark = useMemo(() => generateObject({
        [ADVISORY]: true,
        [DISCRETIONARY]: true,
        [EXECUTION_ONLY]: false,
        [DEPOSITS]: true,
    }).getByName(newPortfolio?.product?.label), [newPortfolio?.product?.label]);

    // Questionnaire
    const { questionnaireTypeId } = useQuestionnaires();

    // Selected Benchmark
    const { benchmarkSelected } = useBenchmarks({
        productId,
        currencyId,
        benchmarkId: modelData?.benchmarkId,
    }, false, showBenchmark);

    const details = useMemo(() => ({
        PortfolioId: portfolioId,
        ModelPortfolioId: modelData?.modelPortfolioId,
        ProductId: productId,
        CurrencyId: currencyId,
        PortfolioProposalId: proposalId,
        Allocations: allocations || modelData?.investmentAllocations,
    }), [newPortfolioData, modelData, allocations]);

    const {
        saveInvestmentContract, isLoading: isLoadingIC,
    } = useInvestmentContract({
        contactId: clientId,
        clientName: information?.clientName,
        details,
        benchmarkSelected,
    });

    const {
        saveAdvisoryProtocol, isLoading: isLoadingProtocol,
    } = useAdvisoryProtocol({
        contactId: clientId,
        clientName: information?.clientName,
        details,
        benchmarkSelected,
        questionnaireTypeId,
    });

    const clearAndNext = () => {
        clearIban();
        clearNewPortfolio();
        history.push(`/client/${dfsClientId}/portfolios`);
    };

    return (
        <div className="completion">
            <Preloader isLoading={information?.isLoading || isLoadingBankInfo || isLoadingModel}>
                <ContentBox>
                    <header className="on-boarding_header">
                        <Title type={2}>{t('onBoarding.congratulations')}</Title>
                    </header>
                    <div className="completion-content">
                        <Row>
                            <Column size="12">
                                <Paragraph type="secondary" className="completion-text">
                                    {t('onBoarding.completionText')}
                                </Paragraph>
                            </Column>
                            <Column size="12">
                                <div className="group-title">
                                    <Title type={3}>{t('onBoarding.bankDetails')}</Title>
                                    <Paragraph type="secondary">
                                        {t('onBoarding.bankDetailsText')}
                                    </Paragraph>
                                </div>
                            </Column>
                        </Row>
                        <Row className="bank-info">
                            <Column size="6">
                                <div className="group-title">
                                    <Title type={2}>{t('onBoarding.iban')}</Title>
                                    <p className="bank-info-description">{Iban || ''}</p>
                                </div>
                            </Column>
                            <Column size="6">
                                <div className="group-title">
                                    <Title type={2}>{t('onBoarding.bic')}</Title>
                                    <p className="bank-info-description">{bankInfo.BIC}</p>
                                </div>
                            </Column>
                            <Column size="6">
                                <div className="group-title">
                                    <Title type={2}>{t('onBoarding.beneficiary')}</Title>
                                    <p className="bank-info-description">
                                        {`${information?.data?.Name}`}
                                    </p>
                                    <p className="bank-info-description">
                                        {information?.data?.Street}
                                        {' '}
                                        {information?.data?.StreetNumber}
                                    </p>
                                    <p className="bank-info-description">
                                        {information?.data?.PostalCode}
                                        {' '}
                                        {`${information?.data?.City || ''}, ${information?.data?.State || ''}`}
                                    </p>
                                    <p className="bank-info-description">
                                        {information?.data?.Country}
                                    </p>
                                </div>
                            </Column>
                            <Column size="6">
                                <div className="group-title">
                                    <Title type={2}>{t('onBoarding.bank')}</Title>
                                    <p className="bank-info-description">{bankInfo.BankName}</p>
                                    <p className="bank-info-description">
                                        {bankInfo.BankAdress?.AddressLine1}
                                        {' '}
                                        {bankInfo.BankAdress?.AddressLine2}
                                    </p>
                                    <p className="bank-info-description">
                                        {bankInfo.BankAdress?.AddressLine3}
                                        {' '}
                                        {bankInfo.BankAdress?.AddressLine4}
                                    </p>
                                    <p className="bank-info-description">
                                        {bankInfo.BankAdress?.Country}
                                    </p>
                                </div>
                            </Column>
                        </Row>
                        <Row className="documents-info">
                            <Column size="12">
                                <div className="group-title documents-title">
                                    <Title type={3}>{t('onBoarding.documentsTitle')}</Title>
                                    <Paragraph type="secondary">
                                        {t('onBoarding.documentsText')}
                                    </Paragraph>
                                </div>
                            </Column>
                            <Column size="12">
                                <div className="documents-button-group">
                                    <Button type="secondary" size="small" loading={isLoadingIC} onClick={saveInvestmentContract}>
                                        {t('onBoarding.downloadContact')}
                                    </Button>
                                    <Button type="secondary" size="small" loading={isLoadingProtocol} onClick={saveAdvisoryProtocol}>
                                        {t('onBoarding.downloadAdvisoryProtocol')}
                                    </Button>
                                </div>
                            </Column>
                        </Row>
                    </div>
                </ContentBox>
                <ContentBox className="buttons">
                    <Button type="primary" size="small" onClick={clearAndNext}>
                        {t('onBoarding.next')}
                    </Button>
                </ContentBox>
            </Preloader>
        </div>
    );
}

Completion.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            newPortfolioData: PropTypes.shape({
                productId: PropTypes.number,
                currencyId: PropTypes.number,
                portfolioId: PropTypes.number,
                modelPortfolioId: PropTypes.number,
                proposalId: PropTypes.number,
                allocations: PropTypes.shape({
                    Allocation: PropTypes.number,
                    InstrumentId: PropTypes.number,
                }),
            }),
        }),
    }).isRequired,
};

Completion.defaultProps = {
};

export default Completion;
