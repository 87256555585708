import {
    useCallback, useReducer,
} from 'react';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useFormatting, getUTCDate } from 'locale';
import { useSelector } from 'react-redux';
import { memberProfileSelector } from 'redux/auth/authSelectors';
import { adaptInteractionDetails, adaptInteractions, adaptInteraction } from 'adaptors/interactionsAdapt';
import ServerError from 'errors/ServerError';
import {
    interactionModifySelector,
    interactionsDataSelector,
    useInteractionSelector,
    interactionFieldsSelector,
} from 'domain/Interactions';
import { useTranslation } from 'react-i18next';
import { compareDates, getPreviousDay } from 'utils/datetime';

const getPersonName = (persons, personId, member, t) => {
    const person = persons?.find(({ id }) => (id === personId));

    if (person?.name) {
        return person.name;
    }
    if (personId === member.Id) {
        return `${member.FirstName} ${member.LastName}`;
    }

    return t('interaction.hidden');
};

const initialState = {
    data: [],
    needUpdate: true,
    previewTab: 'log-entries',
    listTab: 'upcoming',
    isGetting: false,
    error: null,
    firstLoad: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setNeedUpdate':
            return { ...state, needUpdate: action.payload };
        case 'setPreviewTab':
            return { ...state, previewTab: action.payload };
        case 'setListTab':
            return { ...state, listTab: action.payload };
        case 'setIsGetting':
            return { ...state, isGetting: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setFirstLoad':
            return { ...state, firstLoad: action.payload };
        default:
            return state;
    }
};

const defParams = {
    SortColumn: 'DateFrom',
};

const sortColumnMessaging = {
    Date: 'DateFrom',
    Subject: 'InteractionTypeId',
};

export const useInteractions = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const {
        listTab, needUpdate, isGetting, data, previewTab, error, firstLoad,
    } = state;

    const { getFormattedDate } = useFormatting();
    const member = useSelector(memberProfileSelector);
    const { t, i18n: { language } } = useTranslation();

    const {
        getInteractions,
        getInteractionDetails,
    } = useInteractionSelector(interactionsDataSelector);
    const {
        getInteractionTopics,
        getInteractionTypes,
        dataInteractionTopics: topics,
        dataInteractionTypes: types,
        isLoadingInteractionTopics,
        isLoadingInteractionTypes,
    } = useInteractionSelector(interactionFieldsSelector);
    const {
        deleteInteraction,
        updateInteraction,
    } = useInteractionSelector(interactionModifySelector);

    const args = useCallback((params) => {
        const upcomingDate = new Date();
        const prevDay = getPreviousDay(upcomingDate);

        upcomingDate.setHours(0, 0, 0, 0);
        prevDay.setHours(23, 59, 59);

        if (listTab === 'upcoming') {
            return {
                ...defParams,
                DateFrom: upcomingDate,
                IsCanceled: false,
                pageNumber: params?.currentPage || 1,
                pageSize: params?.pageSize,
                SortDescending: params?.sortOrder ? params?.sortOrder === 'desc' : false,
                SortColumn: params?.sortColumn ? sortColumnMessaging[params?.sortColumn] : 'DateFrom',
            };
        }
        if (listTab === 'past') {
            return {
                ...defParams,
                DateTo: prevDay,
                IsCanceled: false,
                pageNumber: params?.currentPage || 1,
                pageSize: params?.pageSize,
                SortDescending: params?.sortOrder === 'desc',
                SortColumn: params?.sortColumn ? sortColumnMessaging[params?.sortColumn] : 'DateFrom',
            };
        }
        if (listTab === 'canceled') {
            return {
                ...defParams,
                IsCanceled: true,
                pageNumber: params?.currentPage || 1,
                pageSize: params?.pageSize,
                SortDescending: params?.sortOrder === 'desc',
                SortColumn: params?.sortColumn ? sortColumnMessaging[params?.sortColumn] : 'DateFrom',
            };
        }

        return {
            ...defParams,
            IsCanceled: false,
            pageNumber: params?.currentPage || 1,
            pageSize: params?.pageSize,
            SortDescending: params?.sortOrder === 'desc',
            SortColumn: sortColumnMessaging[params?.sortColumn],
        };
    }, [listTab]);

    const getData = useCallback((params) => new Promise(async (resolve, reject) => {
        let localTypes;
        let localTopics;

        try {
            dispatch({ type: 'setIsGetting', payload: true });
            if (types.length < 1 && topics.length < 1) {
                localTopics = await getInteractionTopics();
                localTypes = await getInteractionTypes();
            }
            const interactions = await getInteractions(args(params));

            try {
                const listOfInteractions = adaptInteractions({
                    data: interactions?.Interactions,
                    types: types.length > 1 ? types : localTypes,
                    topics: topics.length > 1 ? topics : localTopics,
                });

                dispatch({ type: 'setIsGetting', payload: false });
                dispatch({ type: 'setData', payload: listOfInteractions });

                resolve({
                    data: listOfInteractions,
                    total: interactions?.TotalNumberOfResults,
                    filters: params?.filters,
                    currentPage: params?.currentPage,
                    sorting: {
                        sortOrder: params?.sortOrder || 'asc',
                        sortColumn: params?.sortColumn || 'Date',
                    },
                });
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: reject,
                    setLoading: (val) => dispatch({ type: 'setIsGetting', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }), [getFormattedDate, topics, types, args, dispatch]);

    const getDataCallback = () => {
        dispatch({ type: 'setNeedUpdate', payload: false });
    };

    const getPreview = useCallback((id) => new Promise(async (resolve, reject) => {
        if (needUpdate || isGetting) return;
        if (id && data.length > 0) {
            try {
                const item = data.find((i) => i.id === id);

                const memberClientsParams = {
                    Page: 1,
                    PageSize: 10000,
                };

                const preview = await getInteractionDetails({
                    interactionParams: id,
                    journalsParams: id,
                    memberClientsParams,
                });

                const {
                    journals: journalsData, memberClients, interaction, membersProfile,
                } = preview || {};

                const memberClientsAdapted = (memberClients || [])?.map((i) => (
                    { id: i.id, name: i.personalInformation?.displayName }));

                const membersProfileAdapted = (membersProfile || [])?.map((i) => (
                    { id: i.MemberId, name: `${i.FirstName} ${i.LastName}` }));

                const persons = [...memberClientsAdapted, ...membersProfileAdapted];

                const journals = journalsData?.map((i) => ({
                    ...i,
                    CreatorId: i.CreatorId === -1 ? t('interaction.system') : getPersonName(persons, i.CreatorId, member, t),
                }));

                const initiated = getPersonName(persons, interaction?.InitiatedBy, member, t);
                const participants = interaction?.Participants?.map((i) => getPersonName(
                    persons, i?.PersonId, member, t,
                ));

                if (item && interaction) {
                    try {
                        resolve(adaptInteractionDetails({
                            data: { ...item, ...interaction },
                            topics,
                            types,
                            initiated,
                            participants,
                            journals,
                            isEditable: interaction?.IsEditable,
                        }));
                    } catch (err) {
                        reject(new AdapterError(err));
                    }
                }
            } catch (err) {
                handlerRequestCanceling(HandlerError(
                    { setError: reject, setLoading: () => {} },
                ))(err);
            }
        }
    }), [data,
        member,
        getInteractionDetails,
        topics,
        types,
        needUpdate,
        isGetting,
        dispatch,
        t,
        language,
    ]);

    const deleteItem = (id) => new Promise(async (resolve, reject) => {
        try {
            await deleteInteraction(id);
            resolve(id);
        } catch (err) {
            reject(ServerError(err));
        }
    });

    const updateItem = useCallback((item) => new Promise(async (resolve, reject) => {
        if (item?.id) {
            try {
                const patchInteractionOptions = {
                    DateFrom: item.from, DateTo: item.to, SendEmail: item.email,
                };

                const createJournalOptions = {
                    Content: item.comment,
                    RelatedTo: null,
                };

                const interaction = await updateInteraction({
                    patchInteractionOptions,
                    createJournalOptions,
                    interactionId: item.id,
                    isUpdateJournal: item.comment?.length >= 10,
                });

                try {
                    if (!compareDates(getUTCDate(interaction?.DateTo), new Date())) {
                        dispatch({ type: 'setNeedUpdate', payload: true });
                    }

                    resolve(adaptInteraction(interaction, types, topics, true));
                } catch (err) {
                    reject(AdapterError(err));
                }
            } catch (err) {
                reject(ServerError(err));
            }
        }
    }), [updateInteraction]);

    const setFirstLoad = (val) => {
        dispatch({ type: 'setFirstLoad', payload: val });
    };

    const changePreviewTab = (key) => {
        if (!isGetting) {
            dispatch({ type: 'setPreviewTab', payload: key });
        }
    };
    const changeListTab = useCallback((key) => {
        if (!isGetting) {
            dispatch({ type: 'setListTab', payload: key });
            dispatch({ type: 'setNeedUpdate', payload: true });
            setFirstLoad(true);
        }
    }, [dispatch, isGetting, setFirstLoad]);

    return {
        needUpdate,
        getData,
        getPreview,
        getDataCallback,
        changePreviewTab,
        changeListTab,
        previewTab,
        listTab,
        data,
        error,
        firstLoad,
        setFirstLoad,
        deleteItem,
        updateItem,
        isLoadingInteractionTopics,
        isLoadingInteractionTypes,
    };
};
