import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { adaptListCurrencies } from '../adaptors/adaptListCurrencies';

export const useListCurrencies = ({ adapter = adaptListCurrencies } = {}) => {
    const { i18n, t } = useTranslation();
    const [isLoading, setLoading] = useState(true);
    const [listCurrency, setListCurrency] = useState('');
    const [listCurrencies, setListCurrencies] = useState([
        { label: t('advisoryDashboard.markets.base'), value: '' },
    ]);

    useEffect(() => {
        ServiceManager.currenciesService('getCurrencies', [i18n.language])
            .then((response) => {
                try {
                    setListCurrencies(adapter(response.data, t));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                () => {
                    setLoading(false);
                },
            ));
    }, [i18n.language]);

    return {
        listCurrencies, isLoading, listCurrency, onListCurrenciesChange: setListCurrency,
    };
};
