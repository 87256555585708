import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Calendar as CalendarUI, momentLocalizer, Title, ContentBox, Loader,
} from 'ui-library';
import { getClassNames } from 'utils';
import { useTranslation } from 'react-i18next';
import { localResources } from 'utils/datetime';
import { calendarFormat } from 'utils/formatting';
import history from 'services/history';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import { datePickerLocale } from '../../constants/constants';
import PageSection from '../PageSection/PageSection';
import Preloader from '../Preloader';
import Draggable from '../Draggable/Draggable';

function Calendar({
    data, isLoading, error, drop, onRangeChange, isLoadingInteractions,
}) {
    const { i18n, t } = useTranslation();
    const titleComponent = useMemo(() => {
        if (drop) {
            return (
                <Draggable {...drop}>
                    <Title type={2}>{t('advisoryDashboard.calendar.title')}</Title>
                </Draggable>
            );
        }

        return <Title type={2}>{t('advisoryDashboard.calendar.title')}</Title>;
    }, [drop, t]);
    const localizer = useMemo(() => localResources(momentLocalizer),
        []);
    const className = useMemo(() => getClassNames('calendar-wrap', { loading: isLoadingInteractions }),
        [isLoadingInteractions]);
    const onNewEntry = () => {
        history.push('/dashboard/new-interaction');
    };

    return (
        <PageSection className={className}>
            <ContentBox underline={false}>
                {titleComponent}
                <Preloader isLoading={isLoading} error={error}>
                    <CalendarUI
                        data={data}
                        locale={i18n.language}
                        localizer={localizer}
                        {...calendarFormat}
                        more={t('calendar.more')}
                        day={t('calendar.day')}
                        week={t('calendar.week')}
                        month={t('calendar.month')}
                        today={t('calendar.today')}
                        allDay={t('calendar.allDay')}
                        datePickerLabels={datePickerLocale(t, i18n.language)}
                        onRangeChange={onRangeChange}
                    />
                    {isLoadingInteractions && <div className="calendar-loader"><Loader /></div>}
                </Preloader>
                <ButtonsBlockRow
                    primaryButton={{
                        type: 'secondary',
                        text: t('calendar.newEntry'),
                        onClick: onNewEntry,
                    }}
                />
            </ContentBox>
        </PageSection>
    );
}

Calendar.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    })),
    isLoading: PropTypes.bool,
    isLoadingInteractions: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    drop: PropTypes.shape({
        role: PropTypes.string,
        tabIndex: PropTypes.number,
        onDragStart: PropTypes.func,
    }),
    onRangeChange: PropTypes.func,
};

Calendar.defaultProps = {
    data: {
        assetClass: null,
    },
    isLoading: true,
    isLoadingInteractions: false,
    error: null,
    drop: null,
    onRangeChange: () => {},
};

export default React.memo(Calendar);
