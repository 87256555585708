import { NA, validateStringData } from 'utils/formatting';

export const getImgBase64Src = (contentBase64) => `data:image;base64,${contentBase64}`;
export const adaptClientInformation = (data, options) => {
    const t = options?.t || ((d) => d);
    const getFormattedDate = options?.getFormattedDate || ((d) => d);
    const getFormattedPhoneNumber = options?.getFormattedPhoneNumber || ((d) => d);
    const getSchemaLabelById = options?.getSchemaLabelById || ((d) => d);

    const {
        personalInformation,
        communicationMethods,
        relationshipInformation,
        investmentInformation,
    } = data || {};

    const phone = `${communicationMethods?.primaryPhoneCountryCode?.label || ''}${communicationMethods?.primaryPhoneNumber || ''}`;

    return {
        LastName: personalInformation?.lastName,
        FirstName: personalInformation?.firstName,
        Salutation: getSchemaLabelById(personalInformation?.salutation?.value),
        Name: personalInformation?.displayName || '',
        IsProspect: relationshipInformation?.isProspect || false,
        HasRiskProfile: !!investmentInformation?.chosenRiskCategory?.value,
        Contacts: [
            {
                Information: {
                    title: t('clientDashboard.overview.contacts.email'),
                    subtitle: validateStringData(communicationMethods?.primaryEmail),
                },
                Icon: {
                    type: 'envolope',
                    size: 20,
                    href: communicationMethods?.primaryEmail ? `mailto:${communicationMethods?.primaryEmail}` : undefined,
                    className: 'information-email',
                },
            },
            {
                Information: {
                    title: t('clientDashboard.overview.contacts.phone'),
                    subtitle: getFormattedPhoneNumber(validateStringData(phone)),
                },
                Icon: {
                    type: 'phone-call',
                    size: 20,
                    href: phone !== '' ? `tel:${phone}` : undefined,
                    className: 'information-phone',
                },
            },
            {
                Information: {
                    title: t('clientDashboard.overview.contacts.mobile'),
                    subtitle: getFormattedPhoneNumber(validateStringData(phone)),
                },
                Icon: {
                    type: 'phone-call',
                    size: 20,
                    href: phone !== '' ? `tel:${phone}` : undefined,
                    className: 'information-mobile',
                },
            },
            {
                Information: {
                    title: t('clientDashboard.overview.contacts.dateOfBirth'),
                    subtitle: getFormattedDate(personalInformation?.dateOfBirth),
                },
                Icon: { type: '' },
            }],
        Street: communicationMethods?.primaryAddress?.primaryAddressLine1 ?? NA,
        StreetNumber: communicationMethods?.primaryAddress?.primaryAddressLine3 ?? NA,
        City: communicationMethods?.primaryAddress?.primaryAddressLine5 ?? NA,
        PostalCode: communicationMethods?.primaryAddress?.primaryAddressLine4 ?? NA,
        Country: getSchemaLabelById(communicationMethods?.primaryAddressCountry?.value) ?? NA,
        State: communicationMethods?.primaryAddress?.primaryAddressLine6 ?? '',
        PhoneNumber: phone !== '' ? phone : NA,
        ChosenRiskCategory: getSchemaLabelById(investmentInformation?.chosenRiskCategory?.value)
            || NA,
    };
};
