import { useCallback, useMemo } from 'react';

export const NEW_PORTFOLIO = 'new-portfolio';
export const NEW_PORTFOLIO_MODIFIED = 'new-portfolio-modified';

const getItem = (key, defaultValue) => {
    try {
        return JSON.parse(sessionStorage.getItem(key)) || defaultValue;
    } catch {
        return {};
    }
};

export const useNewPortfolioData = () => {
    const newPortfolio = useMemo(() => getItem(NEW_PORTFOLIO, null), []);
    const saveNewPortfolio = useCallback((data) => {
        sessionStorage.setItem(NEW_PORTFOLIO, JSON.stringify(data));
    }, []);
    const clearNewPortfolio = useCallback(() => {
        sessionStorage.removeItem(NEW_PORTFOLIO);
    }, []);

    const modifiedPositions = useMemo(() => getItem(NEW_PORTFOLIO_MODIFIED, null), []);
    const saveModifiedPositions = useCallback((data) => {
        sessionStorage.setItem(NEW_PORTFOLIO_MODIFIED, JSON.stringify(data));
    }, []);
    const clearModifiedPositions = useCallback(() => {
        sessionStorage.removeItem(NEW_PORTFOLIO_MODIFIED);
    }, []);

    return {
        newPortfolio,
        saveNewPortfolio,
        clearNewPortfolio,
        modifiedPositions,
        saveModifiedPositions,
        clearModifiedPositions,
    };
};
