import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptMessages } from '../adapters/adaptMessages';

export const useMessages = (contactId) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const { i18n } = useTranslation();

    useEffect(() => {
        ServiceManager.messageService('getClientSentMessages', [{
            pageNumber: 1,
            pageSize: 5,
        },
        contactId])
            .then((response) => {
                try {
                    setMessages(adaptMessages(response.data));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, [i18n.language, contactId]);

    return { data: messages, isLoading, error };
};
