import React from 'react';
import { withMainLayout } from 'hocs';
import { useTranslation } from 'react-i18next';
import News from 'components/News';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import { useExternalNews } from 'hooks/useExternalNews';
import { useLoadMoreNews } from 'hooks/useLoadMoreNews';

function ExternalNews() {
    useCancelAllRequests();
    const { t } = useTranslation();
    const pageSize = 9;
    const {
        data, isLoading, error, getRssNews,
    } = useExternalNews(undefined, { rssNews: pageSize, currentPage: 1 });

    const { nextArticles, newsList } = useLoadMoreNews({
        initialNews: data, pageSize, getNewsData: getRssNews,
    });

    return (
        <News
            title={t('news.externalNews')}
            data={newsList}
            isLoading={isLoading}
            error={error}
            pageSize={pageSize}
            nextArticles={nextArticles}
            nextArticlesSize={3}
            loadMore
        />
    );
}

ExternalNews.propTypes = {};

ExternalNews.defaultProps = {};

export default withMainLayout(ExternalNews);
