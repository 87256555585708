import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import { getClientId, roundAllocations } from 'utils';
import { formatDate } from 'utils/datetime';
import { usePortfolio } from 'domain/Portfolio';
import { useClientProfile } from 'prodivers/clientProfile';
import { mapActionTypeName, QUICK_EDIT } from '../../../constants';
import { useExecuteStrategy } from '../../common/hooks/useExecuteStrategy';
import InteractionAction from '../../../containers/InteractionAction';
import { useCreateProposal } from '../../common/hooks/useCreateProposal';

function Interaction(props) {
    const {
        match: { params: { dfsClientId, portfolioId } },
    } = props;
    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();

    // Client Provider
    const { clientName } = useClientProfile();

    // Portfolio Domain
    const { portfolio: { data, isLoading, error } } = usePortfolio();

    // Hooks
    const positionsEdited = useMemo(() => JSON.parse(sessionStorage.getItem(QUICK_EDIT)) || [], []);
    const positionsEditedAllocations = useMemo(() => roundAllocations(positionsEdited
        .map((item) => ({ Id: item.Id, Allocation: item.Allocation / 100 })), 10000, 10000)
        .map((item) => ({ InstrumentId: item.id, Allocation: item.value }))
        .filter((item) => item.Allocation !== 0),
    [positionsEdited]);
    const { onCreateProposal, isCreating, errorCreating } = useCreateProposal(QUICK_EDIT);
    const { onAcceptExecute, isAccepting, errorAccepting } = useExecuteStrategy(
        clientId, dfsClientId, portfolioId, QUICK_EDIT,
    );

    // Callbacks
    const onNext = async (type) => {
        if (type === 'proposal') {
            const nameProposal = `${mapActionTypeName(QUICK_EDIT, t)} ${t('proposal.ofPortfolio')} ${data?.title} ${t('proposal.on')} ${formatDate(new Date())}`;

            await onCreateProposal({
                clientId,
                dfsClientId,
                portfolioId,
                productId: data.productId,
                constraints: data.constraints,
                allocations: positionsEditedAllocations,
                name: nameProposal,
                contactGroupId: data.contactGroupId,
            });
        } else {
            await onAcceptExecute({
                allocations: positionsEditedAllocations,
            });
        }
        sessionStorage.removeItem(QUICK_EDIT);
    };

    return (
        <Preloader isLoading={isLoading} error={error}>
            <InteractionAction
                dfsClientId={dfsClientId}
                actionType={QUICK_EDIT}
                portfolioId={portfolioId}
                portfolioTitle={data.title}
                isLoadingContinue={isAccepting || isCreating}
                errorContinue={errorAccepting || errorCreating}
                onNext={onNext}
                clientName={clientName}
            />
        </Preloader>
    );
}

Interaction.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

Interaction.defaultProps = {
};

export default Interaction;
