import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Label as LabelUI, CheckboxGroup,
    RadioButtonGroup, NumberInput,
} from 'ui-library';
import ConfirmationDisclaimer from '../ConfirmationDisclaimer/ConfirmationDisclaimer';
import './QuestionnaireQuestion.css';

const setOptions = (
    options = [],
) => options.map(({ Id, Text }) => ({
    value: Id,
    label: Text,
}));

const setCheckBoxOptions = (
    options = [],
    defaultValue = [],
    disableDefaultValue,
) => options.map(({ Id, Text }) => ({
    value: Id,
    label: Text,
    disabled: disableDefaultValue && (defaultValue || []).find((i) => i === Id),
}));

const QuestionnaireQuestion = (props) => {
    const {
        data, value, error, onChange, defaultValue, disableDefaultValue,
    } = props;

    const question = useMemo(() => {
        switch (data?.Layout) {
            case 'Radio':
                return (
                    <>
                        <LabelUI label={data?.Text} />
                        <RadioButtonGroup
                            value={value}
                            options={setOptions(data.Answers)}
                            error={error}
                            onChange={(e) => onChange(
                                { questionId: data.Id, value: +e.target.value },
                            )}
                        />
                    </>
                );
            case 'Checkbox':
                return (
                    <div className="question-group">
                        <LabelUI label={data?.Text} />
                        <CheckboxGroup
                            value={value}
                            options={
                                setCheckBoxOptions(data.Answers, defaultValue, disableDefaultValue)
                            }
                            error={error}
                            onChange={(val) => onChange({ questionId: data.Id, value: val })}
                            size="big"
                            defaultValue={defaultValue}
                        />
                    </div>
                );
            case 'Toggle':
                return (
                    <>
                        <LabelUI label={data?.GroupDescription} />
                        <ConfirmationDisclaimer
                            value={value}
                            onChange={(val) => onChange(
                                {
                                    questionId: data.Id,
                                    value: val ? data.Answers?.[0]?.Id : undefined,
                                },
                            )}
                            error={error}
                            agreeOnText={data.Answers?.[0]?.Text}
                            text={data.Text}
                        />
                    </>
                );
            default:
                if (data?.Type === 'FreeText') {
                    return (
                        <>
                            <LabelUI label={data?.Text} />
                            <NumberInput
                                value={value}
                                error={error}
                                onChange={(val) => onChange({ questionId: data.Id, value: val })}
                            />
                        </>
                    );
                }

                return null;
        }
    }, [
        data.Answers, data.Id,
        data.Layout, data.Type, error,
        onChange, value, data?.GroupDescription,
    ]);

    return (
        <div className="questionnaire-question">
            {question}
        </div>
    );
};

QuestionnaireQuestion.propTypes = {
    data: PropTypes.shape({
        Id: PropTypes.number,
        Text: PropTypes.string,
        Type: PropTypes.string,
        Layout: PropTypes.string,
        Answers: PropTypes.arrayOf(PropTypes.shape({
            Id: PropTypes.number,
            Text: PropTypes.number,
        })),
        GroupDescription: PropTypes.string,
    }),
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number),
    ]),
    error: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.arrayOf(PropTypes.number),
    disableDefaultValue: PropTypes.bool,
};

QuestionnaireQuestion.defaultProps = {
    data: undefined,
    value: undefined,
    error: undefined,
    onChange: () => {},
    defaultValue: [],
    disableDefaultValue: false,
};

export default QuestionnaireQuestion;
