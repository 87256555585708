import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Button, ContentBox, Column, Title, TextInput,
    Select, DatePicker, Infobox,
} from 'ui-library';
import { Controller } from 'react-hook-form';
import Preloader from 'components/Preloader';
import PageSection from 'components/PageSection';
import { PhoneNumber } from 'components/PhoneNumber';
import { getClientId } from 'utils';
import { getDateFormat, compareDates } from 'utils/datetime';
import { datePickerLocale } from 'constants/constants';
import { useLocale } from 'locale';
import history from 'services/history';
import { useDetails } from './hooks/useDetails';
import { useCreateProspect } from './hooks/useCreateProspect';
import './NewProspect.css';


const NewProspect = ({ match: { params: { dfsClientId } }, location }) => {
    const { t } = useTranslation();
    const { locale } = useLocale();
    const clientId = getClientId(dfsClientId);
    const {
        options, isLoadingOptions, errorOptions,
        register, control, values, formErrors, onChange, handleSubmit,
    } = useDetails(clientId);
    const { createProspect, error, isLoading } = useCreateProspect();

    const onCancel = () => {
        if (location?.state?.fromQuickAccessMenu) {
            history.goBack();
        } else {
            history.push('/clients');
        }
    };
    const onSave = async (params) => {
        await createProspect(params);
        history.push('/clients?status=Prospects');
    };

    return (
        <PageSection>
            <Preloader isLoading={isLoadingOptions} error={errorOptions}>
                <ContentBox className="client-details">
                    <Title type={2} className="underlined with-mb">{t('newProspect.title')}</Title>
                    <div className="form-content">
                        <div className="details-section information">
                            <Title type={3}>{t('clientDetails.personalInformation')}</Title>
                            <PageSection>
                                <Controller
                                    control={control}
                                    name="salutation"
                                    render={() => (
                                        <Select
                                            ref={register}
                                            name="salutation"
                                            value={values.salutation}
                                            error={formErrors.salutation}
                                            options={options.salutations}
                                            label={t('clientDetails.salutation')}
                                            onChange={onChange('salutation')}
                                            required
                                        />
                                    )}
                                />
                            </PageSection>
                            <PageSection>
                                <Controller
                                    as={<TextInput />}
                                    name="firstName"
                                    value={values.firstName}
                                    error={formErrors.firstName}
                                    label={t('clientDetails.firstName')}
                                    control={control}
                                    required
                                />
                            </PageSection>
                            <PageSection>
                                <Controller
                                    as={<TextInput />}
                                    control={control}
                                    name="middleName"
                                    value={values.middleName}
                                    error={formErrors.middleName}
                                    label={t('clientDetails.middleName')}
                                />
                            </PageSection>
                            <PageSection>
                                <Controller
                                    as={<TextInput />}
                                    control={control}
                                    name="lastName"
                                    value={values.lastName}
                                    error={formErrors.lastName}
                                    label={t('clientDetails.lastName')}
                                    required
                                />
                            </PageSection>
                            <PageSection>
                                <Controller
                                    control={control}
                                    name="gender"
                                    render={() => (
                                        <Select
                                            ref={register}
                                            name="gender"
                                            value={values.gender}
                                            error={formErrors.gender}
                                            options={options.genders}
                                            label={t('clientDetails.gender')}
                                            onChange={onChange('gender')}
                                            required
                                        />
                                    )}
                                />
                            </PageSection>
                            <PageSection>
                                <div className="row">
                                    <Column size="8">
                                        <Controller
                                            control={control}
                                            name="nationality"
                                            render={() => (
                                                <Select
                                                    ref={register}
                                                    name="nationality"
                                                    value={values.nationality}
                                                    error={formErrors.nationality}
                                                    options={options.countries}
                                                    label={t('clientDetails.nationality')}
                                                    onChange={onChange('nationality')}
                                                    hasSearch
                                                    required
                                                />
                                            )}
                                        />
                                    </Column>
                                    <Column size="4">
                                        <Controller
                                            control={control}
                                            name="language"
                                            render={() => (
                                                <Select
                                                    ref={register}
                                                    name="language"
                                                    value={values.language}
                                                    error={formErrors.language}
                                                    options={options.languages}
                                                    label={t('clientDetails.language')}
                                                    onChange={onChange('language')}
                                                    required
                                                />
                                            )}
                                        />
                                    </Column>
                                </div>
                            </PageSection>
                            <PageSection>
                                <div className="row">
                                    <Column size="6">
                                        <Controller
                                            name="birthDate"
                                            control={control}
                                            defaultValue={undefined}
                                            as={(
                                                <DatePicker
                                                    format={getDateFormat(locale)}
                                                    label={t('clientDetails.birthDate')}
                                                    onChange={onChange('birthDate')}
                                                    value={values.birthDate}
                                                    labels={datePickerLocale(t, locale)}
                                                    error={formErrors.birthDate}
                                                    disabledDate={(current) => current
                                                        && !compareDates(
                                                            new Date(), current,
                                                        )}
                                                    showToday={false}
                                                    required
                                                />
                                            )}
                                        />
                                    </Column>
                                    <Column size="6">
                                        <Controller
                                            as={<TextInput />}
                                            control={control}
                                            name="birthPlace"
                                            value={values.birthPlace}
                                            error={formErrors.birthPlace}
                                            label={t('clientDetails.birthPlace')}
                                            required
                                        />
                                    </Column>
                                </div>
                            </PageSection>
                        </div>
                        <div className="details-section address">
                            <Title type={3}>{t('clientDetails.address')}</Title>
                            <PageSection>
                                <div className="row">
                                    <Column size="8">
                                        <Controller
                                            as={<TextInput />}
                                            control={control}
                                            name="street"
                                            value={values.street}
                                            error={formErrors.street}
                                            label={t('clientDetails.street')}
                                            required
                                        />
                                    </Column>
                                    <Column size="4">
                                        <Controller
                                            as={<TextInput />}
                                            control={control}
                                            name="number"
                                            value={values.number}
                                            error={formErrors.number}
                                            label={t('clientDetails.number')}
                                            required
                                        />
                                    </Column>
                                </div>
                            </PageSection>
                            <PageSection>
                                <Controller
                                    as={<TextInput />}
                                    control={control}
                                    name="additional"
                                    value={values.additional}
                                    error={formErrors.additional}
                                    label={t('clientDetails.additional')}
                                />
                            </PageSection>
                            <PageSection>
                                <div className="row">
                                    <Column size="6">
                                        <Controller
                                            as={<TextInput />}
                                            control={control}
                                            name="postalCode"
                                            value={values.postalCode}
                                            error={formErrors.postalCode}
                                            label={t('clientDetails.postalCode')}
                                            required
                                        />
                                    </Column>
                                    <Column size="6">
                                        <Controller
                                            as={<TextInput />}
                                            control={control}
                                            name="city"
                                            value={values.city}
                                            error={formErrors.city}
                                            label={t('clientDetails.city')}
                                            required
                                        />
                                    </Column>
                                </div>
                            </PageSection>
                            <PageSection>
                                <Controller
                                    control={control}
                                    name="country"
                                    render={() => (
                                        <Select
                                            ref={register}
                                            name="country"
                                            value={values.country}
                                            error={formErrors.country}
                                            options={options.countries}
                                            label={t('clientDetails.country')}
                                            onChange={onChange('country')}
                                            hasSearch
                                            required
                                        />
                                    )}
                                />
                            </PageSection>
                        </div>
                        <div className="details-section contact">
                            <Title type={3}>{t('clientDetails.contact')}</Title>
                            <PageSection>
                                <PhoneNumber
                                    ref={register}
                                    phoneName="mobile"
                                    phoneNumberValue={values.mobile}
                                    phoneNumberError={formErrors.mobile}
                                    label={t('clientDetails.mobile')}
                                    onChangePhoneNumber={onChange('mobile')}
                                    countryCodeValue={values.countryCode}
                                    countryCodeName="countryCode"
                                    onChangeCountryCode={onChange('countryCode')}
                                    countryCodeError={formErrors.countryCode}
                                    required
                                />
                            </PageSection>
                            <PageSection>
                                <Controller
                                    as={<TextInput />}
                                    control={control}
                                    name="email"
                                    value={values.email}
                                    error={formErrors.email}
                                    label={t('clientDetails.email')}
                                    required
                                />
                            </PageSection>
                        </div>
                    </div>
                    {error && <Infobox error accent>{error.message}</Infobox>}
                    <div className="text-sm-right button-group">
                        <Button
                            type="secondary"
                            size="small"
                            loading={isLoading}
                            onClick={onCancel}
                        >
                            {t('clientDetails.cancel')}
                        </Button>
                        <Button type="primary" size="small" loading={isLoading} onClick={handleSubmit(onSave)}>
                            {t('clientDetails.save')}
                        </Button>
                    </div>
                </ContentBox>
            </Preloader>
        </PageSection>
    );
};

NewProspect.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ dfsClientId: PropTypes.string }),
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({ step: PropTypes.number, fromQuickAccessMenu: PropTypes.bool }),
    }),
};

NewProspect.defaultProps = {
    location: {
        state: {},
    },
};

export default NewProspect;
