import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHeader } from './header/useHeader';

const LayoutContext = createContext({});
const LayoutProvider = (props) => {
    const { children } = props;
    const { headerState, initHeaderState, changeHeaderState } = useHeader();

    const value = useMemo(() => ({
        headerState,
        initHeaderState,
        changeHeaderState,
    }), [headerState, initHeaderState, changeHeaderState]);

    return (
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    );
};

LayoutProvider.propTypes = {
    children: PropTypes.node,
};

LayoutProvider.defaultProps = {
    children: null,
};

export {
    LayoutContext,
    LayoutProvider,
};
