import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Title } from 'ui-library';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import Infobox from 'ui-library/dist/components/Infobox';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useFIdentity } from './hooks/useFIdentity';
import OnBoardingBaseTemplate from '../../components/OnBoardingBaseTemplate';

import './Contract.css';

const Contract = ({
    changeStep, contactId,
}) => {
    const {
        onFIdentity, isLoading, error, isFIdentityLoading, emptyDataError,
    } = useFIdentity({ contactId });
    const { t } = useTranslation();
    const { saveFIdentityInProgress } = useOnBoardingSelector(onBoardingDataSelector);

    const onContinue = async () => {
        try {
            saveFIdentityInProgress(true);
            const result = await onFIdentity();

            if (result?.processUrl) {
                window.location.href = `${global.AppConfig.FIDENTITY_REDIRECT_URL}${result?.processUrl}`;
            }
        } catch (err) {
            history.push('/error');
        }
    };

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.contract.identificationSignature')}
            error={error}
            isLoading={isLoading}
            className="contract"
            prevButton={{
                onClick: () => { changeStep(6, 'tax'); },
            }}
            nextButton={{
                loading: isFIdentityLoading,
                onClick: onContinue,
                text: t('onBoarding.contract.startProcess'),
            }}
        >
            <div className="contract">
                <Infobox accent className="description-info">{t('onBoarding.contract.description')}</Infobox>
                <Title type={3} className="list-title">{`${t('onBoarding.howItWorks')}:`}</Title>
                <Paragraph type="secondary" className="list">
                    <ol>
                        <li>{t('onBoarding.contract.scanTheFidentity')}</li>
                        <li>{t('onBoarding.scanClientsDocument')}</li>
                        <li>{t('onBoarding.provideClientsPrivate')}</li>
                    </ol>
                </Paragraph>
                {emptyDataError && <Infobox error>{emptyDataError.message}</Infobox>}
            </div>
        </OnBoardingBaseTemplate>
    );
};

Contract.propTypes = {
    contactId: PropTypes.number.isRequired,
    changeStep: PropTypes.func.isRequired,
};

export default Contract;
