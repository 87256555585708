/* eslint-disable no-undef */
import { useCallback, useState, useMemo } from 'react';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useLanguageId } from 'hooks/useLanguageId';
import { formatDate } from 'utils/datetime';

export const file = (data) => ({
    stringFile: atob(data.Base64Content),
    fileType: 'pdf',
});

export const useDownloadReport = (contactId, contactName) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const { getLanguageId } = useLanguageId();

    const fileName = useMemo(() => `${t('clientDashboard.overview.investmentOverview')} - ${contactName} - ${formatDate()}.pdf`, [contactName]);

    const downloadReport = useCallback(() => {
        setLoading(true);
        getLanguageId()
            .then((language) => {
                const params = {
                    TemplateMetadataKey: 'InvestmentOverview',
                    LanguageId: language.Id,
                    ContentType: 'application/pdf',
                    Parameters: {
                        ContactId: contactId,
                        TimeFrame: 'm',
                        LanguageCode: i18n.language,
                    },
                    IncludePlainProperties: false,
                    PageOrientation: 'Landscape',
                };

                ServiceManager.commonContent('generateTemplates', [params]).then((response) => {
                    try {
                        const byteString = file(response.data).stringFile;
                        const buffer = new ArrayBuffer(byteString.length);
                        const dataBuffer = new Uint8Array(buffer);

                        for (let i = 0; i < byteString.length; i += 1) {
                            dataBuffer[i] = byteString.charCodeAt(i);
                        }
                        const newBlob = new Blob([buffer],
                            {
                                type: file(response.data).fileType,
                            });

                        FileSaver.saveAs(
                            newBlob,
                            fileName,
                        );
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                    .catch(handlerRequestCanceling(
                        HandlerError({ setLoading, setError }),
                    ));
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setLoading, setError }),
            ));
    }, [contactId, contactName]);

    return {
        downloadReport, error, isLoading, fileName,
    };
};
