import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EmptyContent from 'components/EmptyContent';
import WidgetError from 'components/WidgetError';
import {
    Button, Title, Paragraph, Toggle, Label as LabelUI,
} from 'ui-library';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useKnowledgeExperience } from '../../hooks/useKnowledgeExperience';
import { useExpandUniverse } from './hooks/useExpandUniverse';
import { collectAnswers } from '../../adapters/adaptKnowledgeExperience';

import './ExpandUniverse.css';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';

const ExpandUniverse = ({ contactId, onPageChange }) => {
    const { t } = useTranslation();
    const { product } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        results: data, resultsAdapted, errorResults, isLoadingResults, getQuestionnaireResults,
        isSending, errorSending, sendKnowledgeExperience,
    } = useKnowledgeExperience({ contactId, productId: +product?.id });
    const {
        values, disabledToggle, toggledValues, onDownload, onToggle,
    } = useExpandUniverse(resultsAdapted?.negative);
    const answers = useMemo(() => collectAnswers(data), [data]);

    useEffect(() => {
        getQuestionnaireResults();
    }, [getQuestionnaireResults]);

    const onNextClick = async () => {
        const answersNew = {
            ...answers,
            [resultsAdapted.id]: answers?.[resultsAdapted.id]
                ? [...answers?.[resultsAdapted.id], ...values] : [...values],
        };

        await sendKnowledgeExperience(answersNew, data, true);
        onPageChange('results');
    };

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.expandYourInstrumentUniverse')}
            error={errorResults}
            isLoading={isLoadingResults}
            className="expand-universe"
            prevButton={{
                onClick: () => onPageChange('results'),
            }}
            nextButton={{
                loading: isSending,
                onClick: onNextClick,
            }}
        >
            <EmptyContent data={data?.Groups?.[0]} text={t('advisoryDashboard.noData')}>
                <Title type={3}>{t('onBoarding.howItWorks')}</Title>
                <Paragraph type="secondary">
                    <ol>
                        <li>{t('onBoarding.downloadEachDocumentOfInstrument')}</li>
                        <li>{t('onBoarding.itContainsAllImportantInformation')}</li>
                        <li>{t('onBoarding.readDocumentCarefullyAndConfirm')}</li>
                        <li>{t('onBoarding.inCaseYouHaveQuestions')}</li>
                    </ol>
                </Paragraph>
                {(resultsAdapted?.negative || []).map((item) => (
                    <div className="instrument-block">
                        <Title type={3}>{item.Text}</Title>
                        <Paragraph type="secondary">
                            {`${t('onBoarding.pleaseDownloadDocumentBelow')} ${item.Text}.`}
                        </Paragraph>
                        <Button type="secondary" size="small" onClick={onDownload(item)}>
                            {`${t('onBoarding.downloadFactsheet')} ${item.Text}`}
                        </Button>
                        <LabelUI label={t('confirmation.confirmation')} />
                        <div className="toggle-block">
                            <Toggle
                                value={toggledValues[item.Id]}
                                disabled={disabledToggle[item.Id]}
                                onChange={onToggle(item)}
                            />
                            <span>{t('onBoarding.IConfirmToHaveReadAndCompleted')}</span>
                        </div>
                    </div>
                ))}
            </EmptyContent>
            {errorSending && <WidgetError accent error message={errorSending.message} />}
        </OnBoardingBaseTemplate>
    );
};

ExpandUniverse.propTypes = {
    contactId: PropTypes.number.isRequired,
    onPageChange: PropTypes.func,
};

ExpandUniverse.defaultProps = {
    onPageChange: () => { },
};

export default ExpandUniverse;
