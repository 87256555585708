import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    BreadCrumbs,
    Column,
    ContentBox, Modal,
    Row,
    Title,
} from 'ui-library';
import Preloader from 'components/Preloader';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import { Link } from 'react-router-dom';
import { getClientId } from 'utils';
import history from 'services/history';
import {
    portfolioModifySelector, portfolioSelector, usePortfolioSelector, tradingCardSelector,
} from 'domain/Portfolio';
import DetailedOrdersAccordion from '../../../components/DetailedOrdersAccordion';
import { useStrategyDocuments } from '../../../hooks/useStrategyDocuments';
import { useSecurityTransactions } from '../../common/hooks/useSecurityTransactions';
import { mapActionTypeName, MODIFY } from '../../../constants';
import { useModify } from '../hooks/useModify';
import { onPreventRedirect } from '../../common/utils';

function DetailedOrders(props) {
    const { match: { params: { dfsClientId, portfolioId } } } = props;
    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        dataRaw: dataTradingCard, isLoading: isLoadingTradingCard, error: errorTradingCard,
        getModifiedPositions,
    } = usePortfolioSelector(portfolioModifySelector);

    useEffect(() => {
        getModifiedPositions();
    }, [getModifiedPositions]);

    // Hooks
    const backURL = useMemo(() => `/client/${dfsClientId}/portfolios/${portfolioId}`, [dfsClientId, portfolioId]);
    const baseURL = useMemo(() => `${backURL}/modify`, [backURL]);
    const basePosUrl = `/client/${dfsClientId}/portfolios/${portfolioId}/modify/position/${data?.productId}`;
    const { modelData } = useModify({
        portfolio: data,
        portfolioPositions: dataRaw?.Positions,
        positionsModified: dataTradingCard?.OrderBookEntries,
        baseUrl: basePosUrl,
    });
    const {
        securityTransactionsData, isLoadingSecurityTransactions, errorSescurityTransactions,
    } = useSecurityTransactions({
        currencyId: data?.currencyId,
        investmentAllocations: modelData?.investmentAllocations,
        sourceAllocations: data?.investmentAllocations,
        amount: data?.portfolioValue,
        portfolioCurrency: data?.currency,
        portfolioId,
        productID: data?.productId,
        clientId,
        dfsClientId,
        linkToOverview: true,
        actionType: MODIFY,
    });
    const orders = {
        isLoadingOrders: isLoadingSecurityTransactions,
        errorOrders: errorSescurityTransactions,
        data: securityTransactionsData.confirmationData,
    };
    const {
        documents: documentsData, errorDocuments, isLoadingDocuments,
    } = useStrategyDocuments(data?.productId);
    const documents = { isLoadingDocuments, errorDocuments, data: documentsData };

    // Callbacks
    const onBack = () => {
        history.push(`${baseURL}/confirmation`);
    };
    const onContinue = () => {
        history.push(`${baseURL}/interaction`);
    };

    // Portfolio Domain
    const { deleteTradingCard } = usePortfolioSelector(tradingCardSelector);

    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                sessionStorage.removeItem(MODIFY);
                deleteTradingCard(clientId, portfolioId);
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };

    return (
        <Row className="confirmation-page detailed-orders">
            <Column size="12">
                <Preloader
                    isLoading={isLoading || isLoadingTradingCard}
                    error={error || errorTradingCard}
                >
                    <ContentBox>
                        <div className="row">
                            <div className="col-sm-12">
                                <BreadCrumbs current={t('clientDashboard.portfolio.ordersToBeExecuted')}>
                                    <Link
                                        to={`/client/${dfsClientId}/portfolios`}
                                        onClick={(e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios`, onCancel)}
                                    >
                                        {t('clientDashboard.portfolio.portfolios')}
                                    </Link>
                                    <Link
                                        to={`/client/${dfsClientId}/portfolios/${portfolioId}`}
                                        onClick={(e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/${portfolioId}`, onCancel)}
                                    >
                                        {data.title}
                                    </Link>
                                    <Link to={baseURL}>{mapActionTypeName(MODIFY, t)}</Link>
                                    <Link to={`${baseURL}/confirmation`}>{t('confirmation.title')}</Link>
                                </BreadCrumbs>
                                <Title className="mb-0" type={1}>
                                    {t('clientDashboard.portfolio.ordersToBeExecuted')}
                                </Title>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <DetailedOrdersAccordion
                                orders={orders}
                                documents={documents}
                            />
                        </div>
                        <ButtonsBlockRow
                            leftButton={{
                                text: t('confirmation.back'),
                                onClick: onBack,
                            }}
                            primaryButton={{
                                text: t('confirmation.continue'),
                                disabled: !!errorSescurityTransactions,
                                onClick: onContinue,
                            }}
                        />
                    </ContentBox>
                </Preloader>
            </Column>
        </Row>
    );
}

DetailedOrders.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

DetailedOrders.defaultProps = {
};

export default DetailedOrders;
