/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Accordion, AccordionPanel as Panel, Table, ContentBox, Title, Row, Column, Button, BreadCrumbs,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CURRENT_PAGE, PAGE_SIZE, TOTAL_PAGES } from 'constants/constants';
import Preloader from 'components/Preloader';
import Overview from '../../common/components/Overview';
import Transactions from '../../common/components/Transactions';
import Allocation from '../../common/components/Allocation';
import { getPositionsColumns } from '../../common/constants';
import { useSecurityTransactions } from '../hooks/useSecurityTransactions';

function ModelPortfolioView({
    data,
    error,
    isLoading,
    clientId,
    dfsClientId,
    returnOnRiskProfile,
    isCreatingLoading,
    createPortfolio,
    cancelPortfolio,
}) {
    const { t } = useTranslation();
    const {
        securityTransactionsData, isLoadingSecurityTransactions, errorSescurityTransactions,
    } = useSecurityTransactions(
        data.currency.Id,
        data.investmentAllocations,
        data.overview.portfolioValue,
        clientId,
    );

    return (
        <Row className="single-portfolio">
            <Column size="12">
                <Preloader
                    isLoading={isLoading}
                    error={error}
                >
                    <ContentBox underline={false} className="mb-0">
                        <BreadCrumbs current={data.backTitle}>
                            <Link to="#" onClick={returnOnRiskProfile}>
                                {t('portfolioRiskProfile.title')}
                            </Link>
                        </BreadCrumbs>
                        <Title className="mb-0" type={1}>
                            {data.title}
                        </Title>
                    </ContentBox>
                    <Accordion defaultActiveKey={['1', '2', '3', '4']}>
                        <Panel header={t('clientDashboard.portfolio.overview')} key="1">
                            <Overview
                                productId={data?.productId}
                                dfsClientId={dfsClientId}
                                data={data.overview}
                                disableChangeStrategy
                            />
                        </Panel>
                        <Panel header={t('clientDashboard.portfolio.allocation')} key="2" className="allocation">
                            <Allocation
                                data={data.investmentAllocation}
                                isLoading={isLoading}
                                error={error}
                            />
                        </Panel>
                        <Panel header={t('clientDashboard.portfolio.positions')} key="4" className="positions">
                            <Table
                                className="positions-table"
                                data={data.positions}
                                columns={getPositionsColumns(t)}
                                paging={{
                                    total: TOTAL_PAGES,
                                    currentPage: CURRENT_PAGE,
                                    pageSize: PAGE_SIZE,
                                }}
                            />
                        </Panel>
                        <Panel header={t('clientDashboard.portfolio.ordersToBeExecuted')} className="security-transactions" key="4">
                            <Transactions
                                data={securityTransactionsData}
                                isLoading={isLoadingSecurityTransactions}
                                error={errorSescurityTransactions}
                            />
                        </Panel>
                    </Accordion>
                    <ContentBox>
                        <div className="buttons-block">
                            <Row className="mb-0">
                                <Column size={['sm-12']} className="text-sm-right button-group">
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={createPortfolio}
                                        disabled={isCreatingLoading}
                                    >
                                        {t('portfolioRiskProfile.create')}
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={cancelPortfolio}
                                        disabled={isCreatingLoading}
                                    >
                                        {t('portfolioRiskProfile.cancel')}
                                    </Button>
                                </Column>
                            </Row>
                        </div>
                    </ContentBox>
                </Preloader>
            </Column>
        </Row>
    );
}

ModelPortfolioView.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        backTitle: PropTypes.string,
        productId: PropTypes.number,
        currency: PropTypes.shape({
            Id: PropTypes.number,
        }),
        overview: PropTypes.shape({
            portfolioValue: PropTypes.number,
        }),
        investmentAllocation: PropTypes.shape({
            chart: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string,
                data: PropTypes.shape({
                    [PropTypes.string]: PropTypes.arrayOf(PropTypes.shape({
                        name: PropTypes.string,
                        value: PropTypes.number,
                    })),
                }),
            })),
            currency: PropTypes.string,
        }),
        positions: PropTypes.arrayOf(PropTypes.shape({
            valuation: PropTypes.string,
            allocation: PropTypes.string,
            name: PropTypes.string,
            icon: PropTypes.string,
            children: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.shape({
                    label: PropTypes.string,
                    maxLength: PropTypes.number,
                }),
                isin: PropTypes.string,
                currency: PropTypes.string,
                valuation: PropTypes.string,
                allocation: PropTypes.string,
                securityId: PropTypes.number,
                performance: PropTypes.string,
                latestPrice: PropTypes.number,
                actions: PropTypes.shape({
                    value: PropTypes.string,
                }),
            })),
        })),
        investmentAllocations: PropTypes.arrayOf(PropTypes.shape({
            Allocation: PropTypes.string,
            InstrumentId: PropTypes.number,
        })),
    }).isRequired,
    clientId: PropTypes.number.isRequired,
    dfsClientId: PropTypes.string.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    errorCreating: PropTypes.shape({
        message: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    isCreatingLoading: PropTypes.bool,
    createPortfolio: PropTypes.func,
    cancelPortfolio: PropTypes.func,
    returnOnRiskProfile: PropTypes.func,
};

ModelPortfolioView.defaultProps = {
    error: null,
    errorCreating: null,
    isLoading: false,
    isCreatingLoading: false,
    createPortfolio: () => {},
    cancelPortfolio: () => {},
    returnOnRiskProfile: () => {},
};

export default ModelPortfolioView;
