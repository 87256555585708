export const validate = (values) => {
    const errors = {};

    if (values.subject !== undefined && !values.subject) {
        errors.subject = 'messages.validation.subjectWarning';
    }

    if (values.contactReason !== undefined && !values.contactReason) {
        errors.contactReason = 'messages.validation.reasonWarning';
    }

    if (values.message !== undefined && !values.message) {
        errors.message = 'messages.validation.messageWarning';
    }

    return errors;
};
