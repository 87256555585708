import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ContentBox from 'ui-library/dist/components/ContentBox';
import {
    Modal, Paragraph, Title, Column,
} from 'ui-library';
import history from 'services/history';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import { useFormatting } from 'locale';
import { Row } from 'ui-library/dist/components/Grid';
import { usePaymentsData } from '../../hooks/usePaymentsData';

const InternationalReview = (props) => {
    const {
        dfsClientId, payment, onPrev, onCancel,
    } = props;

    const { getPayment, clearPayments } = usePaymentsData();
    const { getFormattedDate, getFormattedCurrency } = useFormatting();
    const data = getPayment(payment);
    const { t } = useTranslation();

    const onSuccess = useCallback(() => {
        Modal.confirm({
            title: t('payments.executePaymentTitle'),
            content: t('payments.executePaymentContent'),
            okText: t('payments.execute'),
            onOk: () => {
                clearPayments();
                history.push(`/client/${dfsClientId}/payments/confirmation?type=payment`);
            },
            onCancel: async () => { },
            cancelText: t('payments.cancel'),
            className: 'close-client-modal',
        });
    }, [dfsClientId]);

    return (
        <>
            <ContentBox className="review-payment international-review">
                <div className="payments-header">
                    <Title type={2}>{t('payments.reviewYourInternational')}</Title>
                </div>
                <div className="review-payment-content">
                    <div className="review-payment-description">
                        <Paragraph type="secondary">{t('payments.paymentReviewDescription')}</Paragraph>
                    </div>
                    <Title type={3} className="payment-details-title">
                        {t('payments.yourPaymentDetails')}
                    </Title>
                    <Row className="review-payment-details">
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.amount')}</Title>
                                <p className="review-payment-info">{getFormattedCurrency(data?.amount, { currency: data?.currency?.label })}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.executionDate')}</Title>
                                <p className="review-payment-info">{getFormattedDate(data?.executionDate)}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.personalNote')}</Title>
                                <p className="review-payment-info">{data?.personalNote || t('payments.none')}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.messageForPayee')}</Title>
                                <p className="review-payment-info">{data?.messageForPayee || t('payments.none')}</p>
                            </div>
                        </Column>
                        {!data?.withoutIban
                            ? (
                                <Column size="6">
                                    <div className="group-title">
                                        <Title type={2}>{t('payments.iban')}</Title>
                                        <p className="review-payment-info">{data?.iban}</p>
                                    </div>
                                </Column>
                            )
                            : (
                                <>
                                    <Column size="6">
                                        <div className="group-title">
                                            <Title type={2}>{t('payments.accountNumber')}</Title>
                                            <p className="review-payment-info">{data?.accountNumber}</p>
                                        </div>
                                    </Column>
                                    <Column size="6">
                                        <div className="group-title">
                                            <Title type={2}>{t('payments.swiftCode')}</Title>
                                            <p className="review-payment-info">{data?.swiftCode}</p>
                                        </div>
                                    </Column>
                                    {data?.bankCode && (
                                        <Column size="6">
                                            <div className="group-title">
                                                <Title type={2}>{t('payments.bankCode')}</Title>
                                                <p className="review-payment-info">{data?.bankCode}</p>
                                            </div>
                                        </Column>
                                    )}
                                </>
                            )
                        }
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.bankDetails')}</Title>
                                <p className="review-payment-info">{data?.bankName}</p>
                                <p className="review-payment-info">{`${data?.bankPostCode} ${data?.bankCity}`}</p>
                                <p className="review-payment-info">{data?.bankCountry?.label}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.payee')}</Title>
                                <p className="review-payment-info">{data?.payeeName}</p>
                                <p className="review-payment-info">{data?.payeeAddressLine}</p>
                                <p className="review-payment-info">{`${data?.payeePostCode} ${data?.payeeCity}`}</p>
                                <p className="review-payment-info">{data?.payeeCountry?.label}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.txnCost')}</Title>
                                <p className="review-payment-info">{data?.txnCost?.label}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.debitAccount')}</Title>
                                <p className="review-payment-info">{data?.debitAccount?.label}</p>
                            </div>
                        </Column>
                    </Row>
                </div>
            </ContentBox>
            <ButtonsBlockRow
                leftButton={{
                    text: t('payments.back'),
                    onClick: onPrev,
                }}
                additionalButton={{
                    text: t('payments.cancel'),
                    onClick: onCancel,
                    type: 'danger',
                }}
                primaryButton={{
                    text: t('payments.confirmAndSubmit'),
                    onClick: onSuccess,
                }}
            />
        </>
    );
};

InternationalReview.propTypes = {
    payment: PropTypes.string.isRequired,
    onPrev: PropTypes.func.isRequired,
    dfsClientId: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,

};

InternationalReview.defaultProps = {
};

export default InternationalReview;
