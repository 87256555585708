import React, {
    useMemo, useCallback, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
    Title, ContentBox, Button,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import { useDashboardConfig } from 'pages/AdvisoryDashboard/hooks/useDashboardConfig';
import { useCustomRefHeightResizable } from 'hooks/useCustomRefHeightResizable';
import NewsItem from './NewsItem';
import NewsItemSkeleton from './NewsItemSkeleton';
import PageSection from '../PageSection/PageSection';
import Preloader from '../Preloader';
import EmptyContent from '../EmptyContent';
import Draggable from '../Draggable/Draggable';
import WidgetResize from '../WidgetResize';

import './News.css';

function News({
    data,
    isLoading,
    error,
    withLink,
    drop,
    isResizable,
    title,
    pageSize,
    nextArticles,
    loadMore,
    nextArticlesSize,
    isAnnouncements,
}) {
    const [isLimited, setLimit] = useState(false);
    const { elemHeight, getHeight } = useCustomRefHeightResizable();
    const [page, setPage] = useState(1);
    const { widgetSize, saveWidgetSize } = useDashboardConfig('News');
    const { t, i18n } = useTranslation();
    const [isLoadingItems, setIsLoadingItems] = useState(false);
    const [listSize, setListSize] = useState(pageSize);

    const slice = useCallback(
        (arr) => {
            if (loadMore) {
                return arr.slice(0, listSize);
            }

            const startIndex = isResizable ? 0 : (page - 1) * pageSize;
            const lastIndex = isResizable ? widgetSize : page * pageSize;

            return arr.slice(startIndex, lastIndex);
        },
        [widgetSize, isResizable, page, pageSize, i18n.language, listSize],
    );

    const contentTitle = useMemo(() => {
        const sectionTitle = title || t('advisoryDashboard.news.title');
        const titleText = withLink
            ? <Title type={2} link="/news/external">{sectionTitle}</Title>
            : <Title type={2}>{sectionTitle}</Title>;

        if (drop) return (<Draggable {...drop}>{titleText}</Draggable>);

        return titleText;
    }, [withLink, drop, i18n.language, title]);

    const onLoadMore = async () => {
        if (listSize === data.length) {
            setIsLoadingItems(true);
            setListSize(listSize + nextArticlesSize);

            const nextNews = await nextArticles(data);

            setIsLoadingItems(!nextNews);
        }

        if (listSize < data.length) {
            setListSize(listSize + nextArticlesSize);
        }
    };

    const renderLoadMoreButton = useMemo(() => {
        if (
            (isAnnouncements && listSize >= data.length)
            || (!isLoadingItems && listSize > data.length)
            || !loadMore
        ) return null;

        return (
            <div className="load-more">
                <Button
                    type="secondary"
                    text={t('news.LoadMore')}
                    className="load-more--btn"
                    onClick={onLoadMore}
                    loading={isLoadingItems}
                    size="small"
                >
                    {t('news.LoadMore')}
                </Button>
            </div>
        );
    }, [data, listSize, isLoadingItems]);

    useEffect(() => {
        if (!isResizable) {
            const lastAvailablePage = Math.ceil(data.length / pageSize);

            if (data.length > 0 && lastAvailablePage < page) {
                setPage(lastAvailablePage);
            }
        }
    }, [i18n.language, data.length]);


    return (
        <PageSection>
            <ContentBox
                className={`news resize-widget ${isLimited ? 'limited-size' : ''}`}
                underline={false}
            >

                {contentTitle}

                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data} text={t('advisoryDashboard.news.noNews')}>
                        <WidgetResize
                            onLimited={setLimit}
                            setWidgetSize={saveWidgetSize}
                            widgetSize={widgetSize}
                            SkeletonComponent={NewsItemSkeleton}
                            elementHeight={elemHeight}
                            dataLength={data.length}
                            isResizable={isResizable}
                        >
                            <div
                                className="news-list"
                                ref={getHeight}
                            >
                                {slice(data).map((item, i) => (
                                    <NewsItem key={`news-${(i + 1).toString()}`} {...item} mini={withLink} />
                                ))}
                            </div>
                        </WidgetResize>
                    </EmptyContent>
                    {renderLoadMoreButton}
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

News.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        date: PropTypes.string,
        content: PropTypes.string,
        link: PropTypes.string,
    })),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    withLink: PropTypes.bool,
    isResizable: PropTypes.bool,
    drop: PropTypes.shape({
        role: PropTypes.string,
        tabIndex: PropTypes.number,
        onDragStart: PropTypes.func,
    }),
    title: PropTypes.string,
    pageSize: PropTypes.number,
    nextArticles: PropTypes.func,
    nextArticlesSize: PropTypes.number,
    loadMore: PropTypes.bool,
    isAnnouncements: PropTypes.bool,
};

News.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
    withLink: false,
    isResizable: false,
    drop: null,
    title: null,
    pageSize: 3,
    nextArticles: () => {},
    nextArticlesSize: 3,
    loadMore: false,
    isAnnouncements: false,
};

export default React.memo(News);
