import React from 'react';
import { RadioButtonGroup as RadioButtonGroupUI } from 'ui-library';
import './RadioButtonGroup.css';

const RadioButtonGroup = (props) => (
    <RadioButtonGroupUI
        className="radio-button-group"
        horizontal
        {...props}
    />
);

export default RadioButtonGroup;
