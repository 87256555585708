import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/formatting';

const CellValue = (value) => (
    value === undefined
        ? null
        : formatCurrency(value)
);

CellValue.propTypes = {
    value: PropTypes.number.isRequired,
};

CellValue.defaultProps = {};

export default CellValue;
