import React, { useCallback, useEffect, useMemo } from 'react';
import { useLayout } from 'prodivers/layout';
import { Modal } from 'ui-library';
import { useTranslation } from 'react-i18next';
import LogoWithClientName from 'components/LogoWithClientName';
import { useClientProfile } from 'prodivers/clientProfile';
import history from 'services/history';
import { genarateDFSClientId } from 'utils';
import CreateNewPortfolioProgressBar from '../components/CreateNewPortfolioProgressBar';
import { useNewPortfolioData } from './useNewPortfolioData';

export const useNewPortfolioLayout = ({ current = 0 } = {}) => {
    const { initHeaderState } = useLayout();
    const { t } = useTranslation();
    // Client Provider
    const { clientId, clientName, isProspect } = useClientProfile();
    const dfsClientId = genarateDFSClientId(clientId);

    const { clearNewPortfolio, clearModifiedPositions } = useNewPortfolioData();

    const newPortfolioClose = useCallback(() => {
        Modal.confirm({
            title: t('confirm.closeProcess'),
            content: t('confirm.closeModalText'),
            okText: t('clientDashboard.yes'),
            onOk: () => {
                clearNewPortfolio();
                clearModifiedPositions();
                history.push(`/client/${dfsClientId}/portfolios`);
            },
            cancelText: t('clientDashboard.no'),
            className: 'close-new-portfolio-modal',
        });
    }, [clearModifiedPositions, clearNewPortfolio, dfsClientId, t]);

    const headComponent = useMemo(() => (
        <CreateNewPortfolioProgressBar current={current} />
    ), [current]);

    useEffect(() => {
        initHeaderState({
            clientName,
            dfsClientId,
            link: null,
            content: LogoWithClientName,
            contentProps: {
                clientName,
                dfsClientId,
                onClientNameClick: newPortfolioClose,
            },
            leftButton: { type: 'close', label: t('clientDashboard.riskProfile.closeProcess') },
            onLeftButtonClick: newPortfolioClose,
            rightButton: '',
            onRightButtonClick: null,
            containerClassName: 'NewPortfolio',
            headComponent,
        });
    }, [clientName, isProspect, dfsClientId, initHeaderState, t, headComponent, newPortfolioClose]);
};
