import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import { Select } from 'ui-library';
import { uniqBy } from 'lodash/fp';

export const CountrySelect = ({
    value, onChange, ...rest
}) => {
    const filteredOptions = useMemo(() => uniqBy('label', getCountries().map((country) => ({
        label: `+${getCountryCallingCode(country)}`,
        value: country,
    }
    )).sort((a, b) => a.label - b.label)), [getCountries, getCountryCallingCode]);

    const options = useMemo(() => {
        if (value) {
            if (filteredOptions.find((i) => i.value === value)) {
                return filteredOptions;
            }

            return [...filteredOptions, {
                label: `+${getCountryCallingCode(value)}`,
                value,
            }];
        }

        return filteredOptions;
    }, [filteredOptions, value]);

    return (
        <Select
            {...rest}
            value={value}
            onChange={onChange}
            options={options}
            className="CountrySelect"
        />
    );
};

CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    defaultCountry: PropTypes.string,
};


CountrySelect.defaultProps = {
    value: '',
    defaultCountry: '',
    onChange: () => {},
};
