import { checkNullData, genarateDFSClientId } from 'utils';

export const adaptClientList = (data) => {
    if (checkNullData(data)) return [];

    return data.map(({ Id, FirstName, LastName }) => {
        const internalId = genarateDFSClientId(Id);

        return {
            id: Id,
            name: `${FirstName} ${LastName}`,
            link: `/client/${internalId}`,
        };
    });
};

export const adaptClientListForWidget = (data) => {
    if (checkNullData(data)) return [];

    return data.map(({ id, personalInformation: { displayName } }) => {
        const internalId = genarateDFSClientId(id);

        return {
            id,
            name: displayName,
            link: `/client/${internalId}`,
        };
    });
};
