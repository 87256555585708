import { isNil } from 'lodash/fp';
import { formatDate } from 'utils/datetime';
import { dateTimeFormats } from 'locale';
import { checkNullData } from '../utils';

const reduceRecipients = (recipients) => recipients
    .reduce((accu, recipientItem, index, arr) => {
        if (index > 2) {
            return accu;
        }
        if (index === 2) {
            return `${accu} and ${arr.length - 2} more`;
        }
        if (index === 0) {
            return `${recipientItem.FullName}`;
        }

        return `${accu}, ${recipientItem.FullName}`;
    }, '');

export const adaptMessages = (data, memberId, { type } = {}) => {
    if (checkNullData(data)) return [];

    return data.map(({
        Id,
        CreateDate,
        IsActive,
        IsRead: isReadCommon,
        IsDeleted,
        Subject,
        SenderId,
        SenderFullName,
        RecipientFullName,
        Text,
        CategoryName,
        Recipients,
    }, index) => {
        const recipientObj = Recipients.find(
            ({ RecipientId }) => Number(RecipientId) === Number(memberId),
        ) || {};
        const isRead = memberId && !isReadCommon
            ? (memberId === SenderId || Boolean(recipientObj.IsRead))
            : isReadCommon;

        return {
            id: Id,
            date: formatDate(CreateDate),
            isActive: IsActive,
            isDeleted: IsDeleted,
            title: Subject,
            senderId: SenderId,
            isSelected: index === 0,
            isChecked: false,
            senderFullName: SenderFullName,
            recipientFullName: RecipientFullName,
            content: Text,
            categoryName: CategoryName,
            recipients: Recipients.map(({
                RecipientId: recipientId,
                FullName: recipientFullName,
            }) => ({
                recipientId,
                recipientFullName,
            })),
            Subject: {
                title: Subject,
                recipients: type === 'inbox' ? SenderFullName : reduceRecipients(Recipients),
                isRead,
            },
            Date: {
                date: CreateDate,
                isReplied: false,
            },
        };
    });
};

export const adaptMessageDetails = (data, memberId, getFormattedDate, { type } = {}) => {
    const recipientObj = data.Recipients?.find(
        ({ RecipientId }) => Number(RecipientId) === Number(memberId),
    ) || {};
    const isRead = memberId && !data.IsRead
        ? (memberId === data.SenderId || Boolean(recipientObj.IsRead))
        : data.IsRead;

    const date = getFormattedDate(data.CreateDate, {
        hour: dateTimeFormats.TWO_DIGIT,
        minute: dateTimeFormats.TWO_DIGIT,
        second: dateTimeFormats.TWO_DIGIT,
    });

    return (
        isNil(data) ? null : {
            isFull: true,
            id: data.Id,
            date,
            isActive: data.IsActive,
            isDeleted: data.IsDeleted,
            title: data.Subject,
            content: data.Text,
            subtypeId: data.SubtypeId,
            subtypeName: data.SubtypeName,
            sourceId: data.SourceId,
            sourceName: data.SourceName,
            senderId: data.SenderId,
            senderFullName: data.SenderFullName,
            Subject: {
                title: data.Subject,
                recipients: type === 'inbox' ? data.SenderFullName : reduceRecipients(data.Recipients),
                isRead,
            },
            recipients: data.Recipients && data.Recipients.map(({
                RecipientId,
                FullName,
                IsRead: RecipientIsRead,
                IsDeleted: RecipientIsDeleted,
                ThreadId,
                CampaignId,
                ThreadShortName,
            }) => ({
                recipientId: RecipientId,
                recipientFullName: FullName,
                isRead: RecipientIsRead,
                isDeleted: RecipientIsDeleted,
                threadId: ThreadId,
                campaignId: CampaignId,
                threadShortName: ThreadShortName,
            })),
            attachments: data.Attachments && data.Attachments.map(({
                FileName,
                DocumentId,
            }) => ({
                fileName: FileName,
                documentId: DocumentId,
            })),
        }
    );
};
