import { useCallback, useEffect, useState } from 'react';
import AdapterError from 'errors/AdapterError';
import { getfromDataObject } from 'utils/formatting';

export const useDraggableWidgets = (dashboard) => {
    const widgetList = [
        { id: '1', type: 'Overview' },
        { id: '2', type: 'Tasks' },
        { id: '3', type: 'News' },
        { id: '4', type: 'InvestmentPerformance' },
        { id: '5', type: 'Calendar' },
        { id: '6', type: 'NextUp' },
        { id: '7', type: 'ClientSearch' },
        { id: '8', type: 'Markets' },
        { id: '9', type: 'Messages' },
        { id: '10', type: 'InvestmentAllocation' },
    ];
    const [widths, setWidth] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [columns, setColumns] = useState([]);

    const parseConfig = (config) => {
        const parsed = JSON.parse(config);

        return parsed.map(({ column, className, blocks }) => ({
            id: column.toString(),
            size: className,
            blocks: blocks.map((block) => block.id.toString()),
        }));
    };
    const generateConfig = (config) => {
        const generated = config.map((column) => ({
            column: +column.id,
            className: column.size,
            blocks: column.blocks.map((block, index) => {
                const widget = widgetList.find(({ id }) => id === block);

                return {
                    id: +block,
                    type: widget.type,
                    className: column.size,
                    order: index + 1,
                };
            }),
        }));

        return JSON.stringify(generated);
    };

    const getWidgets = useCallback((column) => {
        if (column.length === 0 && widgetList.length === 0) return [];

        return column.blocks.map((widgetId) => widgetList.find(({ id }) => widgetId === id));
    }, [widgetList]);
    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result;

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const start = columns.find(({ id }) => id === source.droppableId);
        const finish = columns.find(({ id }) => id === destination.droppableId);

        if (start.id === finish.id) {
            const newTaskIds = Array.from(start.blocks);

            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newColumn = { ...start, blocks: newTaskIds };
            const newState = columns.map((item) => (item.id === newColumn.id ? newColumn : item));

            setColumns(newState);
            global.localStorage.setItem('dashboard-config', generateConfig(newState));

            return;
        }

        // Moving from one list to another
        const startBlocksIds = Array.from(start.blocks);
        const finishBlocksIds = Array.from(finish.blocks);

        startBlocksIds.splice(source.index, 1);
        finishBlocksIds.splice(destination.index, 0, draggableId);
        const newStart = { ...start, blocks: startBlocksIds };
        const newFinish = { ...finish, blocks: finishBlocksIds };
        const newState = columns.map((item) => {
            if (item.id === newStart.id) return newStart;
            if (item.id === newFinish.id) return newFinish;

            return item;
        });

        setColumns(newState);
        global.localStorage.setItem('dashboard-config', generateConfig(newState));
    };

    useEffect(() => {
        try {
            const config = global.localStorage.getItem('dashboard-config');
            const configParsed = config ? parseConfig(config) : [
                { id: '1', size: ['lg-8'], blocks: ['1', '2', '3', '4', '5'] },
                { id: '2', size: ['lg-4'], blocks: ['6', '7', '8', '9', '10'] },
            ];

            setColumns(configParsed);
            setLoading(false);
        } catch (err) {
            setError(new AdapterError(err));
        }
    }, []);

    useEffect(() => {
        if (dashboard.current && !isLoading) {
            const rows = [...getfromDataObject(dashboard.current, 'children[0].children', [])];

            setWidth(rows.map((item) => item.offsetWidth && item.offsetWidth - 30));
        }
    }, [isLoading]);

    return {
        isLoading, error, columns, widgetList, widths, getWidgets, onDragEnd,
    };
};
