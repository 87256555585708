import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Infobox, RadioButtonGroup } from 'ui-library';
import Preloader from 'components/Preloader';
import ProductItem from 'components/ProductItem';
import { useOnSubmit } from '../../hooks/useOnSubmit';
import './ProductSelection.css';

const ProductSelection = ({
    submit, answers, sendData, productsData, isLoading, errorProducts, onSelectProduct,
}) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(answers);
    const showError = useOnSubmit(submit, sendData, selected, !!selected);
    const onChange = ({ target: { value } }) => {
        setSelected(value);
        onSelectProduct(productsData.products.find(({ id }) => id === +value));
    };
    const options = useMemo(() => productsData.products.map((item) => ({
        value: item.id.toString(),
        label: ProductItem(item),
    })), [productsData]);

    return (
        <div className="product-selection">
            <Preloader isLoading={isLoading} error={errorProducts}>
                <RadioButtonGroup
                    label=""
                    value={selected}
                    options={options}
                    onChange={onChange}
                />
                {showError && <Infobox error>{t('clientDashboard.riskProfile.selectAnswer')}</Infobox>}
            </Preloader>
        </div>
    );
};

ProductSelection.propTypes = {
    productsData: PropTypes.shape({
        infobox: PropTypes.string,
        products: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            image: PropTypes.string,
            description: PropTypes.string,
        })),
    }),
    submit: PropTypes.bool,
    answers: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    isLoading: PropTypes.bool,
    errorProducts: PropTypes.shape({
        message: PropTypes.string,
    }),
    sendData: PropTypes.func,
    onSelectProduct: PropTypes.func,
};

ProductSelection.defaultProps = {
    productsData: {
        products: [],
    },
    submit: false,
    answers: null,
    isLoading: false,
    errorProducts: null,
    sendData: () => {},
    onSelectProduct: () => {},
};

export default ProductSelection;
