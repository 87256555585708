import React, {
    useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import { ResizeHeight } from 'components/ResizeHeight';
import { checkAllowElementsCount } from 'components/ResizeHeight/utils';
import { notNegativeNumber } from 'components/ResizeHeight/types';
import './WidgetResize.css';

const WidgetResize = ({
    children,
    widgetSize,
    setWidgetSize,
    SkeletonComponent,
    minElementCount,
    maxElementCount,
    elementHeight,
    onLimited,
    isResizable,
    autoUpdateHeight,
    dataLength,
    footerSlot,
    ...props
}) => {
    const [skeletonData, setSkeletonData] = useState([]);
    const onResizeHeight = useCallback(({ elementCount }) => {
        onLimited(false);
        let item = 0;

        const newsItems = Array(elementCount).fill(null).map(() => {
            item += 1;


            return item;
        });

        setSkeletonData(newsItems);
    }, []);

    const onResizeHeightEnd = useCallback(({ elementCount, updateHeight }) => {
        onLimited(false);
        setWidgetSize(elementCount);
        setSkeletonData([]);
        if (autoUpdateHeight) {
            updateHeight();
        }
    }, []);


    if (isResizable) {
        return (
            <ResizeHeight
                onLimitHeight={() => onLimited(true)}
                onResizeHeight={onResizeHeight}
                onResizeHeightEnd={onResizeHeightEnd}
                elementCount={widgetSize}
                minElementCount={checkAllowElementsCount(dataLength, minElementCount)}
                maxElementCount={checkAllowElementsCount(dataLength, maxElementCount)}
                elementHeight={elementHeight}
                footerSlot={(
                    footerSlot
                    || <hr className="dashboard-divider" />
                )}
                {...props}

            >
                {children}
                <div className="skeleton-components">
                    {SkeletonComponent && skeletonData.map((value) => (
                        <SkeletonComponent key={value} height={elementHeight} />
                    ))}
                </div>
            </ResizeHeight>
        );
    }


    return children;
};

WidgetResize.propTypes = {
    children: PropTypes.node,
    footerSlot: PropTypes.node,
    SkeletonComponent: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
    ]),
    setWidgetSize: PropTypes.func,
    onLimited: PropTypes.func,
    isResizable: PropTypes.bool,
    autoUpdateHeight: PropTypes.bool,
    dataLength: PropTypes.number,
    widgetSize: notNegativeNumber,
    minElementCount: notNegativeNumber,
    maxElementCount: notNegativeNumber,
    elementHeight: notNegativeNumber,
};

WidgetResize.defaultProps = {
    children: null,
    footerSlot: null,
    SkeletonComponent: null,
    onLimited: () => {
    },
    setWidgetSize: () => {
    },
    isResizable: true,
    autoUpdateHeight: true,
    dataLength: 0,
    widgetSize: 0,
    minElementCount: 2,
    maxElementCount: 5,
    elementHeight: 1,
};

export default WidgetResize;
