import {
    formatBigNumber, getfromDataObject, NA, percentIsZero, validateData, validateNumber,
} from 'utils/formatting';
import {
    genarateDFSClientId, getParent, roundAllocations, sum,
} from 'utils';
import { sortAssets } from 'utils/sortingAllocation';
import { adaptAllocationGroups, buildAllocationGroup } from 'adaptors/adaptAllocationGroups';
import { adaptProjection } from 'adaptors/adaptAllocations';
import { isLiquidity } from 'utils/portfolio';
import history from 'services/history';
import {
    BAR, LIST, PIE, PIE_SA,
} from 'constants/constants';

export const redirectToReadOnlyPositionDetails = (baseUrl, positionId, tabId) => history.push(`${baseUrl}/${positionId}/${tabId}/overview`);

const groupPositions = (data, {
    baseUrl,
    portfolioCurrency,
    t,
    getFormattedNumber,
    getFormattedCurrency,
}) => {
    const roundedAllocation = data?.length ? roundAllocations(data) : [];
    const groupToValues = (data || []).reduce((obj, item) => {
        const securityData = item.Security;
        const accumulator = { ...obj };
        const roundedAlloc = roundedAllocation.find((n) => n.id === securityData.Id).value;

        const accParent = getParent(item.Security.AssetClass);
        const currency = validateData(securityData.Currency.CurrencyCode);
        const name = securityData.Type.Id === 1 ? `${t('common.liquidity')} ${currency}` : validateData(securityData.Name);

        accumulator[accParent.Name] = obj[accParent.Name] || [];
        accumulator[accParent.Name].push({
            parentId: accParent.Id,
            isin: validateData(securityData.Isin),
            securityId: validateNumber(securityData.Id),
            url: '',
            name: {
                label: name,
                link: isLiquidity(securityData) ? undefined : `${baseUrl}/${securityData.Id}/2/overview`,
                maxLength: 34,
            },
            allocationData: roundedAlloc,
            allocation:
                `${getFormattedNumber(roundedAlloc, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
            currency,
            units: getFormattedNumber(validateNumber(item.Quantity), {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            performance: validateNumber(item.Performance),
            latestPrice: getFormattedNumber(
                validateNumber(item.ValuationPrice),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            valuation: getFormattedCurrency(
                validateNumber(item.InvestmentValuePortfolioCurrency),
                {
                    currency: portfolioCurrency,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            subAssetClass: getfromDataObject(item.Security.AssetClass, 'Name'),
            type: getfromDataObject(item.Security.Type, 'Name'),
            country: getfromDataObject(item.Security.Country, 'Name'),
            region: getfromDataObject(item.Security.Country.Continent, 'Name'),
            subSector: getfromDataObject(item.Security.Sector, 'Name'),
            sector: getParent(item.Security.Sector).Name,
            fxRate: getFormattedNumber(item.FxRate, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 4,
            }),
            unrealizedGainLoss: getFormattedNumber(item.MonetaryPerformance, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            costPrice: isLiquidity(securityData) ? NA : getFormattedNumber(item.BuyPrice, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            valuationInSecurityCurrency: getFormattedCurrency(
                validateNumber(item.InvestmentValueSecurityCurrency),
                {
                    currency: item.Security.Currency.CurrencyCode,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            valuationData: validateNumber(item.InvestmentValuePortfolioCurrency),
            performanceCCY: getFormattedNumber(validateNumber(item.MonetaryPerformance),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }),
            actions: isLiquidity(securityData)
                ? {}
                : {
                    value: 'action',
                    actions: [
                        {
                            text: t('clientDashboard.portfolio.showMoreDetails'),
                            onClick: () => redirectToReadOnlyPositionDetails(
                                baseUrl, securityData.Id, 1,
                            ),
                        },
                        {
                            text: t('clientDashboard.portfolio.showPerformance'),
                            onClick: () => redirectToReadOnlyPositionDetails(
                                baseUrl, securityData.Id, 2,
                            ),
                        },
                    ],
                },
        });

        return accumulator;
    }, {});

    let groups = Object.keys(groupToValues).map((key) => {
        const groupMembersData = groupToValues[key];

        const allocation = getFormattedNumber(
            percentIsZero(formatBigNumber(sum(groupMembersData, 'allocationData'))),
        );

        return {
            id: groupMembersData[0].parentId,
            valuation: getFormattedCurrency(sum(groupMembersData, 'valuationData'), {
                currency: portfolioCurrency,
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            allocation: `${getFormattedNumber(allocation, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            })}%`,
            allocationData: sum(groupMembersData, 'allocationData'),
            name: key,
            children: groupMembersData,
            icon: 'action',
            actions: {},
        };
    });

    groups = groups.map((groupItem) => {
        const resultGroupItem = { ...groupItem };

        resultGroupItem.valuation = groupItem.valuation;

        resultGroupItem.children = resultGroupItem.children.map((groupMemberItem) => {
            const resultGroupMemberItem = groupMemberItem;

            resultGroupMemberItem.performance = (groupMemberItem.performance * 100);
            resultGroupMemberItem.valuation = (groupMemberItem.valuation);
            resultGroupMemberItem.icon = 'action';

            return resultGroupMemberItem;
        });

        return resultGroupItem;
    });

    return sortAssets(groups);
};

export const adaptPortfolioDetails = (data, options = {}) => {
    const { clientId } = options;
    const t = options?.t || ((d) => d);
    const getFormattedDate = options?.getFormattedDate || ((d) => d);
    const getFormattedNumber = options?.getFormattedNumber || ((d) => d);
    const getFormattedCurrency = options?.getFormattedCurrency || ((d) => d);

    let currency;
    let positions = [];
    let currencyId;
    let customAttributes = {};
    const dfsClientId = genarateDFSClientId(clientId);
    const productID = getfromDataObject(data, 'Product.Id');
    const baseUrl = `/client/${dfsClientId}/portfolios/${data?.Id}/position/${productID}`;

    try {
        customAttributes = JSON.parse(data.CustomAttributes);
    } catch (e) {
        // Do not throw error
    }

    if (data && data?.Currency) {
        currency = data?.Currency.CurrencyCode;
        currencyId = data?.Currency.Id;
    } else if (data && data?.BaseCurrency) {
        currency = data?.BaseCurrency.CurrencyCode;
        currencyId = data?.Currency.Id;
    }

    if (data && data?.Positions) {
        positions = data?.Positions;
    } else if (data && data?.ConsolidatedPositions) {
        positions = data?.ConsolidatedPositions;
    }

    return {
        id: data?.Id,
        backTitle: getfromDataObject(data, 'Name'),
        title: getfromDataObject(data, 'Name'),
        currency,
        currencyId,
        contactGroupId: data?.ContactGroupId,
        uid: data?.UId,
        benchmarkId: data?.BenchmarkId,
        createDate: getFormattedDate(data?.CreateDate),
        portfolioValue: data?.CurrentValue,
        investedAmount: data?.InvestedAmount,
        securitiesValue: data?.SecuritiesValue,
        modelPortfolioId: data?.ModelPortfolioId,
        esgPortfolioId: customAttributes?.esg,
        overview: {
            portfolioValue: data?.CurrentValue,
            currency,
            strategy: getfromDataObject(data, 'RiskCategory.Name'),
            product: getfromDataObject(data, 'Product.Name'),
            performance: validateNumber(data?.CurrentPerformance) * 100,
            sharpRatio: data?.SharpeRatio,
            expectedReturn: validateNumber(data?.CalculatedExpectedReturn) * 100,
            expectedRisk: validateNumber(data?.CalculatedRisk) * 100,
            firstInvestment: getFormattedDate(data?.FirstInvestmentDate),
            modelPortfolio: data?.ModelPortfolioName,
        },
        riskCategoryId: getfromDataObject(data, 'RiskCategory.Id'),
        constraints: data?.OptimizationConstraints || {},
        investmentAllocation: {
            chart: adaptAllocationGroups({
                positions,
                currency,
                t,
                getFormattedCurrency,
                chartTypesList: [PIE, PIE_SA, BAR, LIST],
            }),
            currency,
        },
        investmentAllocations: (data?.Positions || []).map((item) => ({
            InstrumentId: item.Security.Id,
            Allocation: Number(item.Allocation),
        })),
        productId: productID,
        projection: adaptProjection({
            data: positions,
            CurrencyId: currencyId,
            ProjectionYears: 5,
            InitialInvestmentAmount: data?.CurrentValue,
        }),
        positions: groupPositions(data?.Positions, {
            portfolioCurrency: currency,
            baseUrl,
            t,
            getFormattedNumber,
            getFormattedCurrency,
        }),
        riskReturn: [
            +(validateNumber(data?.CalculatedRisk) * 100),
            +(validateNumber(data?.CalculatedExpectedReturn) * 100),
        ],
        riskReturnMatrix: {
            x: [validateNumber(data?.RiskCategory?.Min) * 100,
                validateNumber(data?.RiskCategory?.Max) * 100],
        },
        allocationGroups: buildAllocationGroup(
            positions, currency, t, data?.SecuritiesValue,
        ),
        HistoricalPerformance: data?.HistoricalPerformance,
        isBreached: data?.IsBreached,
        portfolioStatusId: data?.PortfolioStatusId,
        hasPendingOrders: data?.HasPendingOrders,
    };
};
