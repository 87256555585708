import { adaptPositions } from './adaptPositions';

export const aggregateModifiedPositions = (options = {}) => {
    const {
        positionsBase = [],
        positionsCurrent = [],
        adaptOptions,
    } = options;
    const positionsBaseObj = positionsBase.reduce((acc, item) => ({
        ...acc, [item?.Security?.Id]: item,
    }), {});
    const positions = adaptPositions(positionsCurrent, adaptOptions)
        .map(({ children, ...parent }) => ({
            ...parent,
            children: children.map(({ name, nameNew, ...item }) => ({
                ...item,
                name: {
                    ...name,
                    value: name.value || name.label,
                },
                nameNew: {
                    ...nameNew,
                    label: name.value || name.label,
                    value: name.value || name.label,
                    isNew: !positionsBaseObj[item.securityId],
                },
                isNew: !positionsBaseObj[item.securityId],
            })),
        }));
    const positionsChanged = positionsCurrent.map((item) => ({
        securityId: item?.Security?.Id,
        value: (item.Allocation * 100).toFixed(2),
    }));

    return {
        positions,
        positionsChanged,
    };
};
