import { renderers } from 'ui-library';
import {
    CellLink,
    CellPercent,
    CellWithMoreActions,
    CellAdaptedDate,
    CellWithSubtextAndTooltip,
    CellUrgency,
    CellStatus,
} from 'components/renderers';
import {
    sorterByDate,
    sorterString,
    sorterNumber,
    sorterStatusColor,
    SORT_DIRECTION_ASC,
} from 'utils/sorting';
import { getDateFormat, getDateTimeFormat } from '../utils/datetime';

const {
    CellWithSubtext, CellWithIcon, CellWithButtonIcon, CellTooltiped, CellWithCheckboxLinked,
} = renderers;

export const MAX_HANDHELD_VIEWPORT_SIZE = 768;
export const MOBILE_VIEWPORT_MIN_WIDTH = 540;
export const TABLET_VIEWPORT_MIN_WIDTH = 768;
export const MAX_TWO_COLUMN_GRID_WIDTH = 992;

export const CHANGE_STRATEGY = 'change-strategy';
export const CHANGE_MODEL = 'change-model';
export const MODIFY_PORTFOLIO = 'modify-portfolio';
export const CREATE_PORTFOLIO = 'create-portfolio';

export const CURRENT_PAGE = 1;
export const PAGE_SIZE = 8;
export const TOTAL_PAGES = 10;
export const ALL_PAGES = 1000;

export const PRODUCT_OFFERS_ID = 2;

export const PAYMENTS = 'payments';
export const PAYMENT = 'payment';

export const messagesColumns = (t, getFormattedDate, getFormattedTime) => [
    {
        key: 'Subject',
        title: t('advisoryDashboard.messages.subject'),
        sortable: true,
        sorter: sorterString('Subject.title'),
        render: CellWithSubtext,
        width: 140,
        className: 'table-cell__subject',
    },
    {
        key: 'Date',
        title: t('advisoryDashboard.messages.date'),
        sortable: true,
        render: CellAdaptedDate(t, getFormattedDate, getFormattedTime),
        sorter: sorterByDate('Date'),
        width: 86,
        className: 'table-cell__date text-right',
    },
];

export const messagesSorting = { key: 'Date', direction: 'desc' };

export const documentsColumns = (t, getFormattedDate) => [
    {
        key: 'Icon',
        render: CellWithButtonIcon,
        width: 20,
    },
    {
        key: 'Name',
        title: t('advisoryDashboard.documents.name'),
        render: CellTooltiped,
        sortable: true,
        sorter: sorterString('Name.value'),
    },
    {

        key: 'Date',
        title: t('advisoryDashboard.documents.date'),
        width: 90,
        minWidth: 90,
        className: 'text-right table-cell__date',
        render: CellAdaptedDate(t, getFormattedDate),
        sortable: true,
        sorter: sorterByDate('Date'),
    },
];

export const documentsSorting = { key: 'Date', direction: 'desc' };

export const notesColumns = (t, getFormattedDate) => [
    {
        key: 'Note',
        title: t('advisoryDashboard.notes.note'),
        render: CellWithSubtext,
        width: 140,
        className: 'table-cell__subject',
        sortable: true,
        sorter: sorterString('Note.title'),
    },
    {
        key: 'Date',
        title: t('advisoryDashboard.notes.date'),
        defaultSortOrder: 'descend',
        render: CellAdaptedDate(t, getFormattedDate),
        sortable: true,
        sorter: sorterByDate('Date'),
        width: 86,
        className: 'table-cell__date text-right',
    },
];

export const notesSorting = { key: 'Date', direction: 'asc' };

export const overviewSelectOptions = [
    'Month', 'Year',
];

export const TRANSACTION_TYPE = {
    1: 'transactions.credit',
    2: 'transactions.debit',
};

export const portfoliosColumns = (t, getFormattedNumber) => [
    {
        key: 'Name',
        title: t('portfoliosColumns.name'),
        sortable: true,
        sorter: sorterString('Name.label'),
        render: CellLink,
    },
    {
        key: 'Product',
        title: t('portfoliosColumns.product'),
        sorter: sorterString('Product'),
    },
    {
        key: 'Profile',
        title: t('portfoliosColumns.profile'),
        sorter: sorterString('Profile'),
    },
    {
        key: 'Status',
        title: t('portfolios.columns.status'),
        sorter: sorterStatusColor('Status'),
        sortable: true,
        render: CellStatus,
    },
    {
        key: 'ExternalId',
        title: t('portfoliosColumns.externalId'),
        hidden: true,
    },
    {
        key: 'Value',
        className: 'text-right',
        title: t('portfoliosColumns.value'),
        sorter: sorterNumber('Value'),
    },
    {
        key: 'Performance',
        title: t('portfoliosColumns.preformanceSI'),
        className: 'text-right',
        width: 153,
        render: CellPercent(getFormattedNumber),
        sorter: sorterNumber('Performance'),
    },
    {
        title: '',
        className: 'text-right table-cell__actions',
        key: 'Actions',
        render: CellWithMoreActions,
        width: 30,
        minWidth: 30,
        titleType: 'columns',
        fixed: 'right',
        headerRenderer: CellWithIcon,
    },
];

export const infoboxText = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.';

export const LIQUIDITY_TYPE = 'Liquidity';

export const PIE = 'pie';
export const PIE_SA = 'pieSa';
export const BAR = 'bar';
export const LIST = 'list';
export const LINE = 'line';
export const SUSTAINABILITY = 'sustainability';
export const RISKRETURN = 'riskReturn';
export const BANDWIDTH = 'bandwidth';
export const PROJECTION = 'projection';

export const allocationColumns = (t) => [
    {
        key: 'name',
        title: t('allocationColumns.name'),
        sortable: true,
        sorter: sorterString('name'),
        defaultSortOrder: 'descend',
        minWidth: 140,
    },
    {
        key: 'value',
        title: t('allocationColumns.value'),
        width: 200,
        minWidth: 150,
        sortable: true,
        className: 'text-right',
        sorter: sorterNumber('value'),
    },
    {
        key: 'percent',
        title: t('allocationColumns.allocation'),
        width: 150,
        sortable: true,
        className: 'text-right',
        sorter: sorterNumber('percent'),
    },
];

export const investmentAllocation = (title, t) => [
    {
        key: 'name',
        title,
        width: '65%',
        className: 'allocation-name',
    },
    {
        key: 'allocation',
        title: t('allocationColumns.allocation'),
        sortable: true,
        sorter: sorterNumber('allocation'),
        className: 'text-right',
    },
];

const TASK_URGENCY_ORDER = ['Low', 'Medium', 'High'];

export const tasks = (t, formatDate, full, urgencyFilters, isMiniTasks = true) => [
    {
        key: 'Subject',
        title: t('advisoryDashboard.tasks.tableTitle'),
        sortable: true,
        sorter: isMiniTasks ? sorterString('Subject.title') : sorterString('Subject.subtitle'),
        render: CellWithSubtextAndTooltip,
    },
    {
        key: 'type',
        title: t('advisoryDashboard.tasks.tableType'),
        sortable: true,
        filterable: true,
        sorter: sorterString('type'),
        className: 'text-right',
        width: 150,
    },
    {
        key: 'date',
        title: t('advisoryDashboard.tasks.tableDueDate'),
        sortable: true,
        sorter: sorterByDate('date'),
        className: 'text-right table-cell_date',
        width: full ? 150 : 90,
        render: CellAdaptedDate(t, formatDate),
    },
    {
        key: 'urgency',
        title: t('advisoryDashboard.tasks.tableUrgency'),
        render: CellUrgency,
        filterable: true,
        filters: urgencyFilters,
        onFilter: (value, row) => row.urgency.text === value,
        sortable: true,
        sorter: (data, { direction }) => data.sort((a, b) => {
            if (direction === SORT_DIRECTION_ASC) {
                return TASK_URGENCY_ORDER.indexOf(b?.urgency?.type)
                    - TASK_URGENCY_ORDER.indexOf(a?.urgency?.type);
            }

            return TASK_URGENCY_ORDER.indexOf(a?.urgency?.type)
            - TASK_URGENCY_ORDER.indexOf(b?.urgency?.type);
        }),
        className: 'text-right',
        width: full ? 150 : 70,
    },
];

export const tasksSorting = { key: 'date', direction: 'asc' };

export const projectionOptional = (t, yTitle, yAxisFormat, tooltipFormat) => ({
    yTitle,
    yAxisFormat,
    tooltipFormat,
    topLabel: t('clientDashboard.portfolio.veryStrongStock'),
    bottomLabel: t('clientDashboard.portfolio.veryWeakStock'),
    middleLabel: t('clientDashboard.portfolio.expectedValue'),
    middleTopLabel: t('clientDashboard.portfolio.strongStock'),
    middleBottomLabel: t('clientDashboard.portfolio.weakStock'),
});

export const lineChartLabels = (t) => ({
    months: t('months').split('_'),
    shortMonths: t('shortMonths').split('_'),
});

export const datePickerLocale = (t, locale) => ({
    lang: {
        locale,
        placeholder: 'Select date',
        rangePlaceholder: ['Start date', 'End date'],
        today: t('calendar.today'),
        now: t('calendar.now'),
        backToToday: t('calendar.backToToday'),
        ok: t('calendar.ok'),
        cancel: t('calendar.cancel'),
        clear: t('calendar.clear'),
        month: t('calendar.month'),
        year: t('calendar.year'),
        timeSelect: t('calendar.selectTime'),
        dateSelect: t('calendar.selectDate'),
        monthSelect: t('calendar.chooseMonth'),
        yearSelect: t('calendar.chooseYear'),
        decadeSelect: t('calendar.chooseDecade'),
        yearFormat: 'YYYY',
        dateFormat: getDateFormat(locale),
        dayFormat: 'D',
        dateTimeFormat: getDateTimeFormat(locale),
        monthFormat: 'MMMM',
        monthBeforeYear: true,
        previousMonth: t('calendar.prevMonth'),
        // 'Previous month (PageUp)',
        nextMonth: t('calendar.nextMonth'),
        // 'Next month (PageDown)',
        previousYear: t('calendar.lastYear'),
        // 'Last year (Control + left)',
        nextYear: t('calendar.nextYear'),
        // 'Next year (Control + right)',
        previousDecade: t('calendar.lastDecade'),
        // 'Last decade',
        nextDecade: t('calendar.nextDecade'),
        // 'Next decade',
        previousCentury: t('calendar.lastCentury'),
        // 'Last century',
        nextCentury: t('calendar.nextCentury'),
        // 'Next century',
    },
    timePickerLocale: {
        placeholder: t('calendar.selectTime'),
        // 'Select time',
    },
    dateFormat: getDateFormat(locale),
    dateTimeFormat: getDateTimeFormat(locale),
    weekFormat: 'YYYY-wo',
    monthFormat: 'MM-YYYY',
});

// TODO: check translation when Profile component will be uncommented
//  in src\components\SideNavigation\SideNavigation.jsx
export const profileColumns = (t) => [
    {
        key: 'Information',
        render: CellWithSubtext(t),
        width: 'calc(100% - 10px)',
    },
    {
        key: 'Icon',
        width: 10,
        render: CellWithIcon,
    },
];

// TODO: unused code below, to be deleted after approval
export const getProspectColumns = (t) => ([
    {
        key: 'internalId',
        title: t('clients.columns.internalId'),
        sorter: sorterString('internalId.label'),
        render: CellWithCheckboxLinked,
    },
    {
        key: 'name',
        title: t('clients.columns.name'),
        sortable: true,
        sorter: sorterString('name'),
    },
    {
        key: 'type',
        title: t('clients.columns.type'),
        sorter: sorterString('type'),
    },
    {
        key: 'country',
        title: t('clients.columns.country'),
        filterable: true,
        sorter: sorterString('country'),
    },
    {
        key: 'rm',
        title: t('clients.columns.rm'),
        filterable: true,
        sorter: sorterString('rm'),
    },
]);

export const IBAN = 'IBAN';
