import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { Accordion, AccordionPanel as Panel } from 'ui-library';
import InstrumentDocumentsTable from 'components/InstrumentDocumentsTable';
import { useDocuments, useInstrumentData } from 'domain/Instrument';
import withInstrumentAccess from 'hocs/withInstrumentAccess';
import { adaptInstrumentDocs } from 'adaptors/adaptInstrumentDocs';
import Overview from 'components/Instrument/Overview';
import DetailsPerformance from 'components/PositionDetailsPerformace';
import KeyData from 'components/Instrument/KeyData';
import {
    BondId, INTEGER, PERCENTAGE, StructuredProductId,
} from 'constants/instrument';
import { usePositionsModify } from '../../hooks/usePositionsModify';
import { adaptPositionsModify } from '../../adapters/adaptPositionsModify';
import { useReviewData } from '../../hooks/useReviewData';
import { usePositionsToSave } from './hooks/usePositionsToSave';
import OnBoardingBaseTemplate from '../../components/OnBoardingBaseTemplate';

function PositionDetailsPage(props) {
    const {
        contactId, onPageChange, match: { params: { positionId } },
    } = props;
    const { t, i18n: { language } } = useTranslation();
    const { getFormattedNumber, getFormattedDate } = useFormatting();
    const { saveWithNewPositions } = usePositionsToSave();
    const {
        data: reviewData, isLoading: isLoadingReviewData, error: reviewDataError,
    } = useReviewData(contactId);

    // Instrument Domain
    const {
        data: dataDocs, isLoading: isLoadingDocs, error: errorDocs, getDocuments,
        errorDocument, getDocumentById,
    } = useDocuments({ instrumentId: positionId });
    const documents = useMemo(() => adaptInstrumentDocs({
        data: dataDocs, getFormattedDate, t, getDocumentById, positionId, language,
    }), [dataDocs, getFormattedDate, t, getDocumentById, positionId, language]);
    const {
        data, isLoading, error, getCommon,
        dataPerformance, isLoadingPerformance, errorPerformance, getPerformance,
        dataBenchmark, isLoadingBenchmark, errorBenchmark,
    } = useInstrumentData({ instrumentId: positionId });

    // Data
    const currencyID = data?.currency?.id || undefined;
    const additionalBenchmark = useMemo(() => ({
        name: dataBenchmark.name,
        tradingPriceOfUnderlying: dataBenchmark.tradingPrice,
        calculatedRiskOfUnderlying: dataBenchmark.risk,
    }), [dataBenchmark]);
    const priceType = useMemo(() => ([BondId, StructuredProductId].includes(data.typeId)
        ? PERCENTAGE : INTEGER), [data.typeId]);
    const positionsData = useMemo(() => adaptPositionsModify(
        reviewData?.positions, { t, getFormattedNumber },
    ), [reviewData.positions, t, getFormattedNumber]);
    const { positionsChanged } = usePositionsModify(isLoadingReviewData, positionsData);

    // Effects
    useEffect(() => {
        getCommon();
    }, [getCommon]);
    useEffect(() => {
        if (data.currency?.id) getPerformance({ currencyId: data.currency?.id });
    }, [data.currency, getPerformance]);
    useEffect(() => {
        if (data.uId) getDocuments({ instrumentUId: data.uId });
    }, [data.uId, getDocuments]);

    // Callbacks
    const handleNext = () => {
        saveWithNewPositions(reviewData, data, onPageChange, positionsChanged?.positions);
    };

    return (
        <OnBoardingBaseTemplate
            title={data.name}
            className="goal-review on-boarding_position-details"
            isLoading={isLoading || isLoadingReviewData
                || isLoadingBenchmark || isLoadingPerformance}
            error={error || reviewDataError || errorBenchmark}
            prevButton={{
                onClick: () => onPageChange('add-position'),
            }}
            nextButton={{
                text: t('onBoarding.add'),
                onClick: handleNext,
            }}
        >
            <Accordion defaultActiveKey={['1', '2', '3']}>
                <Panel header={t('position.overview')} key="1">
                    <Overview
                        data={data}
                        priceType={priceType}
                        positionId={positionId}
                        performance={dataPerformance}
                        hideHelpText
                    />
                </Panel>
                <Panel header={t('position.performance')} key="2" className="performance">
                    <DetailsPerformance
                        positionId={positionId}
                        currencyId={currencyID}
                        currency={data.currency?.isoCode}
                        priceType={priceType}
                        isPortfolioProposed
                        error={errorPerformance}
                    />
                </Panel>
                <Panel header={t('position.keyData')} key="3" className="key-data">
                    <KeyData
                        positionId={positionId}
                        additionalData={{
                            ...additionalBenchmark,
                            calculatedRisk: dataPerformance?.Volatility,
                            calculatedExpectedReturn: dataPerformance?.ExpectedReturn,
                            sharpeRatio: dataPerformance?.SharpeRatio,
                        }}
                    />
                </Panel>
                <Panel header={t('position.documents')} key="4" className="documents table-without-last-row-padding table-without-head-row-padding">
                    <InstrumentDocumentsTable
                        data={documents}
                        error={errorDocs}
                        isLoading={isLoadingDocs}
                        errorDocument={errorDocument}
                    />
                </Panel>
            </Accordion>
        </OnBoardingBaseTemplate>
    );
}

PositionDetailsPage.propTypes = {
    contactId: PropTypes.number,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            positionId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    onPageChange: PropTypes.func,
};

PositionDetailsPage.defaultProps = {
    contactId: undefined,
    onPageChange: () => { },
};

export default withInstrumentAccess(PositionDetailsPage);
