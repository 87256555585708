import {
    useCallback, useEffect, useMemo,
} from 'react';
import set from 'lodash/set';
import get from 'lodash/get';
import { useEditCustomer, useSchema } from 'domain/ClientService';

const ONBOARDING_STAGE_PATH = 'relationshipInformation.onboardingStage';

export const useStage = (options = {}) => {
    const { clientId } = options;

    // Get Stages
    const {
        dataListsKey: stages,
        errorListsKey: errorStages,
        isLoadingListsKey: isLoadingStages,
        getSchemaListByKey,
    } = useSchema({ loadInitially: false });

    useEffect(() => {
        getSchemaListByKey({ key: 'prospectOnboardingStatus' });
    }, [getSchemaListByKey]);

    // Client stage
    const {
        contactDetails,
        contactDetailsError: errorClientStage,
        contactDetailsIsLoading: isLoadingClientStage,
        getContactDetails,

        saveContactDetailsError: errorSetClientStage,
        saveContactDetailsIsLoading: isLoadingSetClientStage,
        saveContactDetails,
    } = useEditCustomer({ contactId: clientId });
    const clientStage = useMemo(() => {
        const stageId = get(contactDetails?.details, ONBOARDING_STAGE_PATH);

        return (stages || []).findIndex((item) => +(item.id || item.value) === +stageId);
    }, [contactDetails?.details, stages]);

    // Client stage callbacks
    const getClientStage = useCallback(() => getContactDetails(
        { data: { selectors: [ONBOARDING_STAGE_PATH] } },
    ), [getContactDetails]);
    const setClientStage = useCallback((step) => {
        const stageId = (stages || [])?.[step]?.id || (stages || [])?.[step]?.value;
        const details = set({}, ONBOARDING_STAGE_PATH, stageId);

        return saveContactDetails({ data: { details } });
    }, [saveContactDetails, stages]);

    return {
        stages,
        errorStages,
        isLoadingStages,
        clientStage,
        errorClientStage,
        isLoadingClientStage,
        getClientStage,
        errorSetClientStage,
        isLoadingSetClientStage,
        setClientStage,
    };
};
