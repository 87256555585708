import { parseXml } from 'utils/xmlParser';

export const adaptDocuments = (data, { language } = {}) => (data?.results || [])
    .slice(0, 5)
    .map((item) => {
        const name = item.retrieveFileAsName || parseXml(item.documentMetadata.name)?.root?.[language]?.['#text'];

        return {
            id: item.id,
            ownerId: item.ownerId,
            Name: {
                value: name || '',
                maxLength: 17,
            },
            Date: item.createDate,
            Icon: {
                type: 'document',
                size: 20,
            },
        };
    });
