import { useEffect, useState } from 'react';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import { useTranslation } from 'react-i18next';
import handlerRequestCanceling from '../utils/handlerRequestCanceling';

const questionnaireTypeId = 1;

export const useQuestionnaires = ({ productId } = {}) => {
    const { i18n } = useTranslation();
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({ Groups: [{ Steps: [{ Questions: [{}] }] }] });

    useEffect(() => {
        if (!productId) return;

        setLoading(true);
        const questionnairesData = {
            Language: i18n.language,
            ProductId: productId,
            ContactTypeId: 1,
            QuestionnaireTypeId: questionnaireTypeId,
        };

        ServiceManager.compliance('filterQuestionnaires', [questionnairesData])
            .then((response) => {
                try {
                    if (response.data[0]) setData(response.data[0]);
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, [i18n.language, productId]);

    return {
        data, isLoading, error, questionnaireTypeId,
    };
};
