import { getParent, roundAllocations, sum } from 'utils';
import { sortAssets } from 'utils/sortingAllocation';
import { validateData, validateNumber } from 'utils/formatting';
import { isLiquidity } from '../../common/utils';

export const adaptPositions = ({
    positions = [], baseUrl, t, getFormattedNumber, getFormattedCurrency,
}) => {
    const roundedAllocation = positions.length && roundAllocations(positions);

    return positions.map((item) => {
        const securityData = item.Security;
        const currency = validateData(securityData.Currency.CurrencyCode);
        const name = securityData.Type.Id === 1 ? `${t('common.liquidity')} ${currency}` : validateData(securityData.Name);
        const roundedAllocationData = item.Allocation
            ? roundedAllocation.find((n) => n.id === securityData.Id).value
            : 0;

        return {
            id: securityData.Id,
            Id: securityData.Id,
            key: `${item.name}-${securityData.Id}`,
            Name: {
                label: name,
                link: isLiquidity(securityData) ? undefined : `${baseUrl}/${securityData.Id}`,
                maxLength: 50,
            },
            ISIN: validateData(securityData.Isin),
            Value: getFormattedNumber(item.InvestmentValuePortfolioCurrency, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            Units: getFormattedNumber(
                validateData(item.Quantity),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            Currency: currency,
            LatestPrice: getFormattedNumber(validateNumber(item.ValuationPrice), {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            ValuationInSecurityCurrency: getFormattedCurrency(
                validateNumber(item.InvestmentValueSecurityCurrency),
                {
                    currency: item.Security.Currency.CurrencyCode,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            UnrealizedGainLoss: getFormattedNumber(item.MonetaryPerformance, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            Performance: validateNumber(item.Performance) * 100,
            Allocation: roundedAllocationData,
            New: { value: roundedAllocationData },
            Icon: {},
            parentName: getParent(item.Security.AssetClass).Name,
            parentId: getParent(item.Security.AssetClass).Id,
            plainValue: validateNumber(item.InvestmentValuePortfolioCurrency),
            AllocationData: roundedAllocationData,
            NewAllocationData: roundedAllocationData,
        };
    });
};

const groupPositions = ({
    positions,
    changes,
    changesNonManual,
    onChange,
    onIncrease,
    onDecrease,
    getFormattedNumber,
}) => {
    const existingData = changes.reduce((acc, item) => ({
        ...acc, [item.Security?.Id || item?.Id]: item,
    }), {});
    const groupToValues = positions.reduce((obj, item) => {
        const accumulator = { ...obj };
        const newAllocationData = changesNonManual.find(({ Id }) => Id === item.id)
            || existingData[item.id];
        const newAllocation = newAllocationData
            ? parseFloat(newAllocationData.Allocation)
            : item.Allocation;
        const accParent = item.parentName;

        accumulator[accParent] = obj[accParent] || [];
        accumulator[accParent].push({
            ...item,
            Allocation: `${getFormattedNumber(item.Allocation, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            })}%`,
            NewAllocationData: newAllocation,
            New: {
                value: newAllocation.toFixed(2),
                onChange: onChange(item.id),
            },
            Icon: {
                ...item.Icon,
                onIncrease: () => onIncrease(item.id),
                onDecrease: () => onDecrease(item.id),
            },
        });

        return accumulator;
    }, {});

    const parentsIds = Object.keys(groupToValues).map((key) => ({
        key, id: groupToValues[key]?.[0]?.parentId,
    }));

    return sortAssets(parentsIds).map(({ key, id }) => {
        const groupMembersData = groupToValues[key];

        return {
            id,
            Name: key,
            New: { value: '' },
            Icon: {},
            children: groupMembersData,
            plainValue: sum(groupMembersData, 'plainValue'),
            AllocationDataSum: sum(groupMembersData, 'AllocationData'),
        };
    });
};

const formatFooter = (Value, Old, New, { t, getFormattedNumber }) => ({
    Name: t('clientDashboard.portfolio.footer.total'),
    ISIN: '',
    Value: getFormattedNumber(Value, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }),
    Units: '',
    Currency: '',
    LatestPrice: '',
    Performance: undefined,
    ValuationInSecurityCurrency: '',
    UnrealizedGainLoss: '',
    Allocation: `${getFormattedNumber(Old, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })}%`,
    New: `${getFormattedNumber(New, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })}%`,
    Icon: {},
});

export const adaptQuickEditPositions = ({
    baseUrl, t, getFormattedNumber, getFormattedCurrency, portfolioValue,
}) => ({
    positions, changes, changesNonManual, dataSummary, onChange, onIncrease, onDecrease,
}) => ({
    positions: groupPositions({
        positions,
        changes,
        changesNonManual,
        onChange,
        onIncrease,
        onDecrease,
        baseUrl,
        t,
        getFormattedNumber,
        getFormattedCurrency,
    }),
    footer: formatFooter(
        portfolioValue, dataSummary?.Old, dataSummary?.New, { t, getFormattedNumber },
    ),
});
