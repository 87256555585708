import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { getfromDataObject } from 'utils/formatting';
import { useFormatting } from 'locale';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { adaptModelPortfolio } from '../../../adapters/adaptModelPortfolio';

export const useComparePortfolio = (contactId, productId, options) => {
    const { i18n, t } = useTranslation();
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({ overview: {}, positions: [] });
    const [, setPortfolioId] = useState(null);
    const params = { language: i18n.language };
    const { getRiskCategory } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
    } = useFormatting();

    const getComparedPortfolioId = useCallback(async () => {
        setLoading(true);
        setError(null);

        const chosenRiskCategory = await getRiskCategory();
        // TODO: Remove hardcode (fixed for demos)
        const hardCodedProduct = +productId === 4 ? 6 : productId;

        const { data: modelPortfolios } = await ServiceManager.portfolioManagement('getModelPortfolios', [hardCodedProduct, params]);
        const modelPortfolioIdx = modelPortfolios
            .findIndex((item) => item.RiskCategory.Id === chosenRiskCategory?.Id);
        const modelPortfolio = modelPortfolios[modelPortfolioIdx !== 4 ? modelPortfolioIdx + 1 : 0];
        const modelPortfolioId = getfromDataObject(modelPortfolio, 'Id', undefined);

        setPortfolioId(modelPortfolioId);

        return modelPortfolioId;
    }, [i18n.language]);

    useEffect(() => {
        setError(null);
        setLoading(true);

        getComparedPortfolioId().then(((modelPortfolioId) => {
            ServiceManager.portfolioManagement('getModelPortfolio', [modelPortfolioId, params])
                .then((response) => {
                    try {
                        setData(adaptModelPortfolio(response.data, {
                            ...options,
                            t,
                            getFormattedDate,
                            getFormattedNumber,
                            getFormattedCurrency,
                        }));
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch(handlerRequestCanceling(
                    HandlerError({ setLoading, setError }),
                ));
        }));
    }, [i18n.language]);

    return { error, data, isLoading };
};
