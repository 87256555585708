import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import Comment from '../components/Comment';
import { existingInteractionColumns } from '../constants';

import './ExistingInteraction.css';

const ExistingInteraction = ({
    data, selectedRows, onRowChange, comment, onCommentChange, register, errors,
}) => {
    const { t } = useTranslation();
    const { getFormattedDate, getFormattedTime } = useFormatting();

    return (
        <div className="existing-interaction">
            <Table
                data={data}
                rowSelection={{
                    type: 'radio',
                    selectedRowKeys: selectedRows,
                    onChange: onRowChange,
                }}
                noDataText={t('advisoryDashboard.noData')}
                columns={existingInteractionColumns(t, getFormattedDate, getFormattedTime)}
            />
            <Comment
                error={errors.comment}
                register={register}
                value={comment}
                onChange={onCommentChange}
                className="existing-interaction-comment"
                editable
            />
        </div>
    );
};

ExistingInteraction.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string.isRequired,
            id: PropTypes.number,
            link: PropTypes.string,
            maxLength: PropTypes.number,
            onChange: PropTypes.func,
        }),
        topic: PropTypes.string,
        from: PropTypes.string,
    })),
    comment: PropTypes.string,
    selectedRows: PropTypes.arrayOf(PropTypes.string),
    onRowChange: PropTypes.func,
    onCommentChange: PropTypes.func,
    register: PropTypes.func,
    errors: PropTypes.shape({
        comment: PropTypes.string,
        interactionId: PropTypes.string,
    }),
};

ExistingInteraction.defaultProps = {
    data: [],
    comment: '',
    selectedRows: [],
    onRowChange: () => {},
    onCommentChange: () => {},
    register: null,
    errors: {},
};

export default ExistingInteraction;
