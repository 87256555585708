import { renderers } from 'ui-library';
import { sorterByDate, sorterNumber, sorterString } from 'utils/sorting';
import { CellPercent, CellSwitch, CellWithMoreActions } from 'components/renderers';

const { CellWithIcon } = renderers;

export const getPositionsColumns = (t, getFormattedNumber) => ([
    {
        key: 'name',
        title: t('clientDashboard.portfolio.columns.name'),
        sorter: sorterString('name'),
        sortable: true,
        render: CellSwitch,
        width: 250,
        minWidth: 250,
    },
    {
        title: t('clientDashboard.portfolio.columns.isin'),
        key: 'isin',
        sortable: true,
        sorter: sorterString('isin'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.type'),
        key: 'type',
        sortable: true,
        sorter: sorterString('type'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.units'),
        key: 'units',
        width: 75,
        minWidth: 75,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('units'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.costPrice'),
        key: 'costPrice',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('costPrice'),
        width: 100,
        minWidth: 100,
    },
    {
        title: t('clientDashboard.portfolio.columns.currency'),
        key: 'currency',
        sortable: true,
        sorter: sorterString('currency'),
        className: 'currency-cel',
        width: 80,
        minWidth: 80,
    },
    {
        title: t('clientDashboard.portfolio.columns.latestPrice'),
        key: 'latestPrice',
        width: 107,
        minWidth: 107,
        sortable: true,
        sorter: sorterNumber('latestPrice'),
        className: 'text-right',
    },
    {
        key: 'performance',
        title: t('clientDashboard.portfolio.columns.perfSI'),
        className: 'text-right',
        width: 95,
        minWidth: 95,
        sortable: true,
        sorter: sorterNumber('performance'),
        render: CellPercent(getFormattedNumber),
    },
    {
        title: t('clientDashboard.portfolio.columns.subAssetClass'),
        key: 'subAssetClass',
        width: 200,
        minWidth: 200,
        sortable: true,
        sorter: sorterString('subAssetClass'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.country'),
        key: 'country',
        width: 250,
        minWidth: 250,
        sortable: true,
        sorter: sorterString('country'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.region'),
        key: 'region',
        width: 150,
        minWidth: 150,
        sortable: true,
        sorter: sorterString('region'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.sector'),
        key: 'sector',
        width: 100,
        minWidth: 100,
        sortable: true,
        sorter: sorterString('sector'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.subSector'),
        key: 'subSector',
        width: 200,
        minWidth: 200,
        sortable: true,
        sorter: sorterString('subSector'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.valuationInSecurityCurrency'),
        className: 'text-right',
        key: 'valuationInSecurityCurrency',
        sortable: true,
        sorter: sorterNumber('valuationInSecurityCurrency'),
        width: 200,
        minWidth: 200,
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.fxRate'),
        key: 'fxRate',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('fxRate'),
        width: 150,
        minWidth: 150,
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.unrealizedGainLoss'),
        key: 'unrealizedGainLoss',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('unrealizedGainLoss'),
        width: 200,
        minWidth: 200,
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.allocation'),
        key: 'allocation',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('allocation'),
        width: 110,
        minWidth: 110,
    },
    {
        title: t('clientDashboard.portfolio.columns.valueData'),
        className: 'text-right',
        key: 'valuation',
        sortable: true,
        sorter: sorterNumber('valuation'),
        width: 150,
        minWidth: 150,
    },
    {
        title: '',
        className: 'table-cell__actions',
        key: 'actions',
        render: CellWithMoreActions,
        width: 30,
        minWidth: 30,
        titleType: 'columns',
        headerRenderer: CellWithIcon,
    },
]);

export const cashFlowsColumns = (t, currency) => [
    {
        key: 'KeyValue',
        title: '',
        width: '25%',
    },
    {
        key: 'LastMonth',
        title: `${t('portfolios.cashFlowsColumns.lastMonth')}${currency ? ` (${currency})` : ''}`,
        width: '25%',
        className: 'text-right',
    },
    {
        key: 'YTD',
        title: `${t('portfolios.cashFlowsColumns.YTD')}${currency ? ` (${currency})` : ''}`,
        width: '25%',
        className: 'text-right',
    },
    {
        key: 'SinceInception',
        title: `${t('portfolios.cashFlowsColumns.sinceInception')}${currency ? ` (${currency})` : ''}`,
        width: '25%',
        className: 'text-right',
    },
];
export const cashFlowsRowMarginIdx = [1, 2, 3, 4, 6];

export const getSecurityTransactionsColumns = (t, currency) => ([
    {
        key: 'Date',
        title: t('clientDashboard.portfolio.columns.date'),
        sortable: true,
        sorter: sorterByDate('Date'),
        defaultSortOrder: 'ascend',
        width: 100,
    },
    {
        key: 'Name',
        title: t('clientDashboard.portfolio.columns.name'),
        render: CellSwitch,
        sortable: true,
        sorter: sorterString('Name.label'),
        width: 200,
    },
    {
        key: 'Isin',
        title: t('position.columns.isin'),
        hidden: true,
        sorter: sorterString('Isin'),
    },
    {
        key: 'SubAssetClass',
        title: t('position.columns.subAssetClass'),
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('SubAssetClass'),
    },
    {
        key: 'Country',
        title: t('position.columns.country'),
        width: 250,
        minWidth: 250,
        hidden: true,
        sorter: sorterString('Country'),
    },
    {
        key: 'Region',
        title: t('position.columns.region'),
        width: 150,
        minWidth: 150,
        hidden: true,
        sorter: sorterString('Region'),
    },
    {
        key: 'Sector',
        title: t('position.columns.sector'),
        width: 100,
        minWidth: 100,
        hidden: true,
        sorter: sorterString('Sector'),
    },
    {
        key: 'SubSector',
        title: t('position.columns.subSector'),
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('SubSector'),
    },
    {
        key: 'Type',
        title: t('clientDashboard.portfolio.columns.type'),
        sortable: true,
        sorter: sorterString('Type'),
    },
    {
        key: 'Status',
        title: t('portfolios.columns.status'),
        sorter: sorterString('Status'),
        hidden: true,
    },
    {
        key: 'Number',
        title: t('clientDashboard.portfolio.columns.number'),
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Number'),
    },
    {
        key: 'Price',
        title: t('clientDashboard.portfolio.columns.price'),
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Price'),
    },
    {
        key: 'FxRate',
        title: t('clientDashboard.portfolio.columns.fxRate'),
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('FxRate'),
    },
    {
        key: 'Value',
        title: `${t('clientDashboard.portfolio.columns.valueData')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Value'),
    },
    {
        key: 'Icon',
        title: '',
        titleType: 'columns',
        width: 20,
        minWidth: 20,
        className: 'text-right',
    },
]);

export const getCashTransactionsColumns = (t, currency) => ([
    {
        key: 'Date',
        title: t('clientDashboard.portfolio.columns.date'),
        sortable: true,
        sorter: sorterByDate('Date'),
        defaultSortOrder: 'ascend',
        width: 100,
    },
    {
        key: 'Name',
        title: t('clientDashboard.portfolio.columns.name'),
        render: CellSwitch,
        sortable: true,
        sorter: sorterString('Name.label'),
        width: 200,
    },
    {
        key: 'BookingText',
        title: t('clientDashboard.portfolio.columns.bookingText'),
        sortable: true,
        sorter: sorterString('BookingText'),
        defaultSortOrder: 'ascend',
    },
    {
        key: 'Credit',
        title: `${t('clientDashboard.portfolio.columns.credit')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Credit'),
    },
    {
        key: 'Debit',
        title: `${t('clientDashboard.portfolio.columns.debit')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Debit'),
    },
    {
        key: 'Balance',
        title: `${t('clientDashboard.portfolio.columns.balance')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Balance'),
    },
]);
