import React from 'react';
import PropTypes from 'prop-types';
import { Navigation, NavigationLink } from 'ui-library';
import { useTranslation } from 'react-i18next';

function SideNavigation({ isOpen, current, onClose }) {
    const { t } = useTranslation();

    return (
        <Navigation
            isOpen={isOpen}
            onClose={onClose}
        >
            <NavigationLink to="/dashboard" homeLink={current === '/dashboard'}>{t('navigation.sideNav.dashboard')}</NavigationLink>
            <NavigationLink to="/clients" homeLink={current.includes('clients')}>{t('navigation.sideNav.clients')}</NavigationLink>
            <NavigationLink to="/messages" homeLink={current === '/messages'}>{t('navigation.sideNav.messages')}</NavigationLink>
            <NavigationLink to="/tasks" homeLink={current === '/tasks'}>{t('navigation.sideNav.tasks')}</NavigationLink>
            <NavigationLink to="/news/announcements" homeLink={current.includes('announcements')}>{t('navigation.sideNav.announcements')}</NavigationLink>
            <NavigationLink to="/news/research" homeLink={current.includes('research')}>{t('navigation.sideNav.research')}</NavigationLink>
            <NavigationLink to="/news/external" homeLink={current.includes('external')}>{t('navigation.sideNav.externalNews')}</NavigationLink>
            {
                (global.AppConfig.REACT_APP_SURFLY_URL?.length ?? 0) !== 0 && (
                    <NavigationLink to="/support" homeLink={current === '/support'}>{t('navigation.sideNav.support')}</NavigationLink>
                )
            }
            {/* eslint-disable-next-line max-len */}
            {/* <NavigationLink to="/portfolios" homeLink={current === '/portfolios'}>Portfolios</NavigationLink>
            <NavigationLink to="/">Productivity</NavigationLink>
            <NavigationLink to="/">Publish</NavigationLink>
            <NavigationLink to="/">Admin</NavigationLink>
            <NavigationLink to="/">Profile</NavigationLink> */}
        </Navigation>
    );
}

SideNavigation.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    current: PropTypes.string.isRequired,
};

SideNavigation.defaultProps = {
    isOpen: false,
    onClose: () => {},
};

export default SideNavigation;
