import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Header } from 'ui-library';
import { useLayout } from 'prodivers/layout';
import { getClassNames } from 'utils';
import Footer from '../Footer';
import PageTitle from '../PageTitle';
import TopNavigation from '../TopNavigation';
import './Layout.css';

const Layout = (props) => {
    const { children } = props;
    const { t } = useTranslation();
    const {
        headerState: {
            link,
            content: Content,
            contentProps = {},
            clientName,
            dfsClientId,
            leftButton,
            rightButton,
            navigation,
            profileInfo,
            navigationTablet,
            quickAccessMenuComponent: QuickAccessMenuComponent,
            showClientTitle,
            showTopNavigation,
            headComponent,
            containerClassName,
            onLeftButtonClick,
            onRightButtonClick,
        },
    } = useLayout();

    return (
        <>
            <Header
                link={link}
                title={t('navigation.title')}
                content={Content && <Content {...contentProps} />}
                navigation={navigation}
                profileInfo={profileInfo}
                navigationTablet={navigationTablet}
                quickAccessMenu={QuickAccessMenuComponent && <QuickAccessMenuComponent />}
                leftButton={leftButton}
                rightButton={rightButton}
                onLeftButtonClick={onLeftButtonClick}
                onRightButtonClick={onRightButtonClick}
            />
            <main className={getClassNames('container', containerClassName)}>
                {showClientTitle && <PageTitle className="client-name">{clientName}</PageTitle>}
                {(dfsClientId && showTopNavigation) && <TopNavigation clientId={dfsClientId} />}
                {headComponent}
                {children}
            </main>
            <Footer />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node,
};

Layout.defaultProps = {
    children: null,
};

export default Layout;
