import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'ui-library';
import history from 'services/history';
import ClientsPagination from './ClientsPagination';


const ClientsTable = ({
    columns,
    data,
    paging = {},
    sort = {},
    onChangeSort,
    onChangeFilter,
    onChangePagination,
    tab,
}) => {
    const { t } = useTranslation();

    const onRowClick = (record) => ({
        onClick: () => {
            history.push(`/client/${record.internalId.label}/dashboard`, {
                fromTab: tab || 'clients',
                clientName: record.name,
            });
        },
    });


    return (
        <>
            <Table
                noDataText={t('clients.noClients')}
                onChangeSort={onChangeSort}
                onChangeFilter={onChangeFilter}
                columns={columns}
                paging={{ pageSize: paging.pageSize }}
                data={data}
                sorting={sort}
                onRow={onRowClick}
                headerControls={{
                    selectControlsLabel: {
                        selectAll: t('headerControls.selectAll'),
                        reset: t('headerControls.reset'),
                    },
                }}
            />
            <ClientsPagination
                total={paging.totalCount}
                currentPage={paging.currentPage}
                pageSize={paging.pageSize}
                onChange={onChangePagination}
            />
        </>
    );
};


ClientsTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    paging: PropTypes.objectOf({
        pageSize: PropTypes.number,
        totalCount: PropTypes.number,
        currentPage: PropTypes.number,
    }),
    sort: PropTypes.objectOf({
        key: PropTypes.string,
        direction: PropTypes.string,
    }),
    tab: PropTypes.string,
    onChangeSort: PropTypes.func,
    onChangeFilter: PropTypes.func,
    onChangePagination: PropTypes.func,

};
ClientsTable.defaultProps = {
    columns: [],
    data: [],
    paging: {
        pageSize: 0,
        totalCount: 0,
        currentPage: 0,
    },
    sort: {
        key: '',
        direction: '',
    },
    tab: '',
    onChangeSort: () => {
    },
    onChangeFilter: () => {
    },
    onChangePagination: () => {
    },
};

export default ClientsTable;
