import React from 'react';
import { withMainLayout } from 'hocs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import News from 'components/News';
import { useAnnouncements } from 'hooks/useAnnouncements';
import { useLoadMoreNews } from 'hooks/useLoadMoreNews';

function Announcements() {
    const { t } = useTranslation();
    const pageSize = 9;
    const {
        data, isLoading, error, getAnnouncements,
    } = useAnnouncements();
    const { nextArticles, newsList } = useLoadMoreNews({
        initialNews: data, pageSize, getNewsData: getAnnouncements,
    });

    return (
        <News
            title={t('news.announcements')}
            data={newsList}
            isLoading={isLoading}
            error={error}
            pageSize={pageSize}
            nextArticles={nextArticles}
            nextArticlesSize={3}
            isAnnouncements
            loadMore
        />
    );
}

Announcements.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
    }).isRequired,
};

Announcements.defaultProps = {};

export default withMainLayout(Announcements);
