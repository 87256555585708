import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, gql } from '@apollo/client';
import SM from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import { subtractUnits, getDaysOfYear } from 'utils/datetime';
import { useSecurityTypeIndex } from 'hooks/useSecurityTypeIndex';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useFormatting } from 'locale';
import { adaptMarkets } from '../adapters/adaptMarkets';

const MARKETS_INDEXES = gql`
    query GetMarketsIndexes($indexId: Int!) {
        pagedSecurities(take: 20 where: { type: { id: { eq: $indexId } } }){
            items {
                id
                name
                tradingPrice
                type { id }
                securityStockExchanges { ticker }
            }
        }
    }
`;

export const useMarkets = (configuration, listCurrency) => {
    const { i18n: { language } } = useTranslation();
    const { getFormattedNumber } = useFormatting();
    const { typeId, isLoadingTypeId, errorTypeId } = useSecurityTypeIndex();

    const [error, setError] = useState(null);
    const [markets, setMarkets] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const [
        getIndexes,
        { loading, error: errorIndexes, data: dataIndexes },
    ] = useLazyQuery(MARKETS_INDEXES, { variables: { indexId: typeId } });
    const setParamsToKPI = useCallback((InstrumentId) => ({
        InstrumentSet: {
            CurrencyId: listCurrency,
            Allocations: [{ InstrumentId, Allocation: 1 }],
            AllocationType: 'Percentage',
        },
        Days: getDaysOfYear(),
        CalculationInterval: 'Monthly',
    }), [listCurrency]);
    const getMarketsIndexesExt = useCallback(async ({ SecurityIds, BaseCurrency }) => {
        const filterSecurities = {
            SecurityIds,
            StartDate: subtractUnits(new Date(), 2, 'month'),
            Language: language,
        };

        if (BaseCurrency) filterSecurities.BaseCurrency = BaseCurrency;

        try {
            return SM.instrumentsService('postSecurities', [filterSecurities]);
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            )(err);
        }

        return null;
    }, [language, setError, setLoading]);

    // Effects
    useEffect(() => {
        if (typeId) getIndexes();
    }, [typeId, getIndexes]);

    useEffect(() => {
        if (dataIndexes?.pagedSecurities?.items) {
            (async () => {
                setError(null);
                setLoading(true);
                const SecurityIds = dataIndexes.pagedSecurities.items.map(({ id }) => id);
                const response = await getMarketsIndexesExt({
                    SecurityIds,
                    BaseCurrency: listCurrency === '' ? undefined : listCurrency,
                });
                let calculationsWithCurrency = null;

                if (listCurrency !== '') {
                    try {
                        calculationsWithCurrency = await Promise.all(SecurityIds
                            .map(async (id) => ({
                                id, ...(await SM.performanceService('calculateKPIPerformance', [setParamsToKPI(id)])).data,
                            })));
                    } catch (err) {
                        handlerRequestCanceling(
                            HandlerError({ setError, setLoading }),
                        )(err);
                    }
                }

                try {
                    setMarkets(adaptMarkets({
                        data: dataIndexes.pagedSecurities.items,
                        dataPriceHistory: response?.data,
                        calculations: calculationsWithCurrency,
                        getFormattedNumber,
                    }));
                    setLoading(false);
                } catch (err) {
                    handlerRequestCanceling(
                        () => {
                            setError(new AdapterError(err));
                            setLoading(false);
                        },
                    )(err);
                }
            })();
        }
    }, [dataIndexes, listCurrency, getFormattedNumber, getMarketsIndexesExt, setParamsToKPI]);

    return {
        data: markets,
        isLoading: isLoadingTypeId || loading || isLoading,
        error: errorTypeId || errorIndexes || error,
    };
};
