import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    ContentBox,
} from 'ui-library';
import PageHeader from 'components/PageHeader';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import { getClientId } from 'utils';
import { CHANGE_MODEL, CHANGE_STRATEGY } from 'constants/constants';
import history from 'services/history';
import { useModelChange } from 'hooks/useModelChange';
import { useModelPortfolio, portfolioSelector, usePortfolioSelector } from 'domain/Portfolio';
import DetailedOrdersAccordion from '../../../components/DetailedOrdersAccordion';
import { useStrategyDocuments } from '../../../hooks/useStrategyDocuments';
import { useSecurityTransactions } from '../../common/hooks/useSecurityTransactions';
import { onPreventRedirect } from '../../common/utils';
import './DetailedOrders.css';

function DetailedOrders(props) {
    const { match: { params: { dfsClientId, portfolioId } } } = props;
    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();
    const modelId = JSON.parse(sessionStorage.getItem(CHANGE_MODEL));

    // Portfolio Domain
    const { data, isLoading, error } = usePortfolioSelector(portfolioSelector);
    const {
        data: modelPortfolio, isLoading: isLoadingModel, error: errorModel, getModelPortfolio,
    } = useModelPortfolio();

    useEffect(() => {
        getModelPortfolio(modelId);
    }, [getModelPortfolio, modelId]);

    // Hooks
    const { onModelReset } = useModelChange();
    const {
        securityTransactionsData, isLoadingSecurityTransactions, errorSescurityTransactions,
    } = useSecurityTransactions({
        currencyId: data?.currencyId,
        investmentAllocations: modelPortfolio?.investmentAllocations,
        sourceAllocations: data?.investmentAllocations,
        amount: data?.portfolioValue,
        portfolioCurrency: data?.currency,
        portfolioId,
        productID: data?.productId,
        clientId,
        dfsClientId,
        actionType: CHANGE_STRATEGY,
    });
    const orders = {
        isLoadingOrders: isLoadingSecurityTransactions,
        errorOrders: errorSescurityTransactions,
        data: securityTransactionsData.confirmationData,
    };
    const {
        documents: documentsData, errorDocuments, isLoadingDocuments,
    } = useStrategyDocuments(data.productId);
    const documents = { isLoadingDocuments, errorDocuments, data: documentsData };

    // Callbacks
    const onStrategyReset = () => {
        sessionStorage.removeItem(CHANGE_STRATEGY);
        onModelReset();
    };
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                onStrategyReset();
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const backURL = useMemo(() => `/client/${dfsClientId}/portfolios/${portfolioId}`, [dfsClientId, portfolioId]);
    const onBack = () => {
        history.push(`${backURL}/change-model`);
    };
    const onContinue = () => {
        history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/change-model/interaction`);
    };

    return (
        <ContentBox className="change-model confirmation-page detailed-orders">
            <Preloader isLoading={isLoading || isLoadingModel} error={error || errorModel}>
                <PageHeader
                    title={t('clientDashboard.portfolio.ordersToBeExecuted')}
                    breadCrumbsCurrent={t('clientDashboard.portfolio.ordersToBeExecuted')}
                    breadCrumbsChildren={[
                        {
                            to: `/client/${dfsClientId}/portfolios`,
                            label: t('clientDashboard.portfolios.title'),
                            onClick: (e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios`, onCancel),
                        },
                        {
                            to: `/client/${dfsClientId}/portfolios/${portfolioId}`,
                            label: data.title,
                            onClick: (e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/${portfolioId}`, onCancel),
                        },
                        {
                            to: `/client/${dfsClientId}/portfolios/${portfolioId}/change-model`,
                            label: t('overview.changeModel'),
                        },
                    ]}
                />
                <DetailedOrdersAccordion
                    orders={orders}
                    documents={documents}
                />
                <ButtonsBlockRow
                    leftButton={{
                        text: t('confirmation.back'),
                        onClick: onBack,
                    }}
                    primaryButton={{
                        text: t('confirmation.continue'),
                        disabled: !!errorSescurityTransactions,
                        onClick: onContinue,
                    }}
                />
            </Preloader>
        </ContentBox>
    );
}

DetailedOrders.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

DetailedOrders.defaultProps = {
};

export default DetailedOrders;
