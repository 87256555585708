import React from 'react';
import { withMainLayout } from 'hocs';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import { useInitWidgets } from './hooks/useInitWidgets';
import AdvisoryDashboard from './AdvisoryDashboard';

const AdvisoryDashboardContainer = () => {
    useCancelAllRequests();
    const data = useInitWidgets();

    return (
        <AdvisoryDashboard data={data} />
    );
};

export default withMainLayout(AdvisoryDashboardContainer);
