import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import { adaptProducts } from 'adaptors/adaptProducts';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useFormatting, useLocale } from 'locale';

export const useProducts = (step) => {
    const [products, setProducts] = useState({ products: [] });
    const [errorProducts, setErrorProducts] = useState(null);
    const [isLoadingProducts, setLoadingProducts] = useState(false);
    const { t, i18n } = useTranslation();

    const { getFormattedNumber } = useFormatting();
    const { locale } = useLocale();

    useEffect(() => {
        if (step === undefined || step === 3) {
            setErrorProducts(null);
            setLoadingProducts(true);

            ServiceManager.commonService('getGeneralSettings')
                .then(({ data: globalSettings }) => {
                    ServiceManager.commonService('getCampaignsCurrentConfiguration', [i18n.language])
                        .then((response) => {
                            try {
                                setProducts(adaptProducts(response.data,
                                    globalSettings, t, getFormattedNumber));
                                setLoadingProducts(false);
                            } catch (err) {
                                throw new AdapterError(err);
                            }
                        })
                        .catch(handlerRequestCanceling(
                            HandlerError({
                                setLoading: setLoadingProducts,
                                setError: setErrorProducts,
                            }),
                        ));
                })
                .catch(handlerRequestCanceling(
                    HandlerError({
                        setLoading: setLoadingProducts,
                        setError: setErrorProducts,
                    }),
                ));
        }
    }, [step, i18n.language, locale]);

    return {
        products, isLoadingProducts, errorProducts,
    };
};
