import React from 'react';
import PropTypes from 'prop-types';
import Preloader from 'components/Preloader';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import { usePortfolio } from 'domain/Portfolio';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject,
} from 'constants/portfolioProducts';
import './PortfolioOverview.css';
import { Column, Row } from 'ui-library';
import { useClientLayout } from '../../../../hooks/useClientLayout';
import PortfolioAdvisory from './products/PortfolioAdvisory';
import PortfolioDiscretionary from './products/PortfolioDiscretionary';
import PortfolioExecutionOnly from './products/PortfolioExecutionOnly';

const PortfolioByProduct = generateObject({
    [ADVISORY]: PortfolioAdvisory,
    [DISCRETIONARY]: PortfolioDiscretionary,
    [EXECUTION_ONLY]: PortfolioExecutionOnly,
});

function PortfolioOverview(props) {
    useCancelAllRequests();
    useClientLayout();

    const { portfolio: { productId, error } } = usePortfolio();
    const PageLoader = (
        <Row>
            <Column size="12">
                <Preloader isLoading={productId === undefined} error={error} />
            </Column>
        </Row>
    );
    const PortfolioPage = productId === undefined
        ? () => PageLoader : PortfolioByProduct[productId] || PortfolioAdvisory;

    return (
        <PortfolioPage {...props} />
    );
}

PortfolioOverview.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

PortfolioOverview.defaultProps = {};

export default PortfolioOverview;
