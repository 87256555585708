import { checkNullData } from 'utils';
import { step3Data, step4Data } from '../mockData';

export const adaptQuestionnaire = (data, t) => {
    if (checkNullData(data)) {
        return {
            questions: { Groups: [{ Steps: [{ Questions: [{}] }] }] },
            results: [step3Data(t), step4Data],
        };
    }

    const questionnaire = data.find(({ Id }) => Id === 11);

    return {
        questions: { ...questionnaire },
        results: [step3Data(t), step4Data],
    };
};
