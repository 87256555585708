import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ContentBox,
    Modal,
    Paragraph,
    Title,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';

import './Preview.css';

function Preview({
    deletePreview,
    updatePreview,
    error,
    isLoading,
    item,
    showDelete,
    deleteText,
    deleteTitle,
    renderActions,
    renderMetadata,
    renderAttachments,
    renderContentItem,
    renderButtons,
    deleteCallback,
    getPreview,
}) {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const onCancel = () => {
        setShowModal(false);
    };
    const onDelete = () => {
        setShowModal(true);
    };
    const onOk = async () => {
        await deletePreview(item.id);
        setShowModal(false);
    };

    return (
        <ContentBox underline={false} className="preview-content pb-0">
            <Preloader isLoading={isLoading} error={error}>
                {item
                    && (
                        <>
                            <div className="preview-content__inner">
                                <Title type={3}>{item.title}</Title>
                                {renderMetadata && (
                                    <div className="preview-content-metadata">
                                        {renderMetadata(item)}
                                    </div>
                                )}
                                {renderAttachments(item)}
                                {typeof renderContentItem === 'function' ? renderContentItem(item) : (
                                    <Paragraph className="border-top border-bottom">
                                        {item.content}
                                    </Paragraph>
                                )}
                                <Modal
                                    title={deleteTitle}
                                    visible={showModal}
                                    okText={t('messages.confirm')}
                                    cancelText={t('messages.cancel')}
                                    onOk={() => onOk()}
                                    onCancel={() => onCancel()}
                                >
                                    {deleteText}
                                </Modal>
                            </div>

                            {typeof renderButtons === 'function' ? (
                                <div className="preview-buttons">
                                    <div className="text-sm-right button-group">
                                        {showDelete && (
                                            <Button type="danger" size="small" onClick={onDelete}>
                                                {t('messages.delete')}
                                            </Button>
                                        )}
                                        {renderButtons(item, {
                                            deletePreview,
                                            updatePreview,
                                            deleteCallback,
                                            getPreview,
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <div className="preview-buttons">
                                    <div className="text-sm-right button-group">
                                        {showDelete && (
                                            <Button type="danger" size="small" onClick={onDelete}>
                                                {t('messages.delete')}
                                            </Button>
                                        )}
                                        {renderActions(item)}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
            </Preloader>
        </ContentBox>
    );
}

Preview.propTypes = {
    deletePreview: PropTypes.func.isRequired,
    updatePreview: PropTypes.func.isRequired,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    isLoading: PropTypes.bool.isRequired,
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string,
        content: PropTypes.string,
        attachments: PropTypes.arrayOf(PropTypes.shape({
            fileName: PropTypes.string,
        })),
    }),
    showDelete: PropTypes.bool,
    deleteTitle: PropTypes.string,
    deleteText: PropTypes.string,
    renderActions: PropTypes.func,
    renderMetadata: PropTypes.func,
    renderAttachments: PropTypes.func,
    renderContentItem: PropTypes.func,
    renderButtons: PropTypes.func,
    deleteCallback: PropTypes.func,
    getPreview: PropTypes.func,
};

Preview.defaultProps = {
    item: null,
    error: null,
    showDelete: false,
    deleteText: '',
    deleteTitle: '',
    renderActions: () => { },
    renderMetadata: () => { },
    renderAttachments: () => { },
    renderContentItem: null,
    renderButtons: null,
    deleteCallback: () => {},
    getPreview: () => {},
};

export default Preview;
