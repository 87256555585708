import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import { getClientId } from 'utils';
import {
    portfolioModifySelector,
    portfolioSelector,
    usePortfolioSelector,
} from 'domain/Portfolio';
import { formatDate } from 'utils/datetime';
import { useClientProfile } from 'prodivers/clientProfile';
import { mapActionTypeName, MODIFY } from '../../../constants';
import { useExecuteStrategy } from '../../common/hooks/useExecuteStrategy';
import InteractionAction from '../../../containers/InteractionAction';
import { useModify } from '../hooks/useModify';
import { useCreateProposal } from '../../common/hooks/useCreateProposal';

function Interaction(props) {
    const {
        match: { params: { dfsClientId, portfolioId } },
    } = props;
    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();

    // Client Provider
    const { clientName } = useClientProfile();

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        dataRaw: dataTradingCard, isLoading: isLoadingTradingCard, error: errorTradingCard,
        getModifiedPositions,
    } = usePortfolioSelector(portfolioModifySelector);

    useEffect(() => {
        getModifiedPositions();
    }, [getModifiedPositions]);

    // Hooks
    const { onCreateProposal, isCreating, errorCreating } = useCreateProposal(MODIFY);
    const { onAcceptExecute, isAccepting, errorAccepting } = useExecuteStrategy(
        clientId, dfsClientId, portfolioId, MODIFY,
    );
    const { modifiedData, modelData, clearModifiedLocally } = useModify({
        portfolio: data,
        portfolioPositions: dataRaw?.Positions,
        positionsModified: dataTradingCard?.OrderBookEntries,
    });

    // Callbacks
    const onNext = async (type) => {
        clearModifiedLocally();
        if (type === 'proposal') {
            const nameProposal = `${mapActionTypeName(MODIFY, t)} ${t('proposal.ofPortfolio')} ${data?.title} ${t('proposal.on')} ${formatDate(new Date())}`;

            await onCreateProposal({
                clientId,
                dfsClientId,
                portfolioId,
                productId: data.productId,
                constraints: data.constraints,
                allocations: modelData?.investmentAllocationsRound,
                name: nameProposal,
                contactGroupId: data.contactGroupId,
            });
        } else {
            onAcceptExecute({
                allocations: modifiedData?.positionsAllocation,
            });
        }
    };

    return (
        <Preloader isLoading={isLoading || isLoadingTradingCard} error={error || errorTradingCard}>
            <InteractionAction
                dfsClientId={dfsClientId}
                actionType={MODIFY}
                portfolioId={portfolioId}
                portfolioTitle={data.title}
                isLoadingContinue={isAccepting || isCreating}
                errorContinue={errorAccepting || errorCreating}
                onNext={onNext}
                clientName={clientName}
                executeOnly
            />
        </Preloader>
    );
}

Interaction.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            type: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

Interaction.defaultProps = {
};

export default Interaction;
