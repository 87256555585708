import React from 'react';
import { usePortfolioActionsLayout } from '../hooks/usePortfolioActionsLayout';

const withPortfolioActionsLayout = (WrappedComponent) => (props) => {
    usePortfolioActionsLayout();

    return <WrappedComponent {...props} />;
};

export default withPortfolioActionsLayout;
