import { sorterStringCommon } from 'utils/sorting';

export const adaptCommon = (data) => (data || []).map((item) => ({
    value: item.Id,
    label: item.Name,
}));

export const adaptCountries = (data) => (data || []).map((item) => ({
    value: item.ThreeLetterIsoCode,
    label: item.Name,
})).sort(sorterStringCommon('label'));

export const adaptCountriesWithCode = (data) => (data || []).map((item) => ({
    value: item.ThreeLetterIsoCode,
    label: item.Name,
    code: item.CountryCode,
    id: item.Id,
})).sort(sorterStringCommon('label'));

export const adaptDocumentTypes = (data) => (data || []).map((item) => ({
    value: item.Key,
    label: item.Name,
}));

export const adaptLanguages = (data) => (data || []).map((item) => ({
    value: item.CultureCode,
    label: item.Name,
}));
