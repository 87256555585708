import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useEditCustomer, useSchema } from 'domain/ClientService';
import { mandatoryFields } from 'domain/ClientService/constants';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

export const useDynamicCRM = (options) => {
    const { contactId, selectors } = options;
    const [isUploading, setIsUploading] = useState(false);
    const [errorUpload, setErrorUpload] = useState();
    const [errorValidation, setErrorValidation] = useState([]);

    const { t } = useTranslation();
    const {
        data: schema, error, isLoading, getSchema,
        contactDetails, contactDetailsError, contactDetailsIsLoading, getContactDetails,
        saveContactDetailsError, saveContactDetailsIsLoading, saveContactDetails,
    } = useEditCustomer({ contactId });
    const {
        errorLists, isLoadingLists, getSchemasLists,
    } = useSchema();

    // Data
    const data = useMemo(() => {
        let dataReturn = null;

        try {
            dataReturn = JSON.parse(schema?.schema);
        } catch { /* Do not throw error */ }

        return dataReturn;
    }, [schema]);
    const submission = useMemo(() => contactDetails?.details, [contactDetails]);

    // Callbacks
    const validateData = useCallback((dataToSave) => {
        setErrorValidation([]);

        const mandatorySelectors = selectors
            .filter((key) => Object.keys(mandatoryFields).includes(key));

        if (mandatorySelectors.length > 0) {
            const fields = mandatorySelectors
                .map((selector) => Object.keys(mandatoryFields[selector])
                    .filter((fieldKey) => {
                        const fieldPath = `${selector}.${mandatoryFields[selector][fieldKey]}`;
                        const valueToSave = get(dataToSave, fieldPath);
                        const valueExist = get(submission, fieldPath);
                        const isEmpty = (val) => !val || val.length === 0;

                        return valueToSave !== undefined
                            ? isEmpty(valueToSave)
                            : isEmpty(valueExist);
                    })).flat();

            if (fields.length > 0) {
                setErrorValidation(fields.map((item) => t(`onBoarding.identification.validation.${item}`)));
                throw new Error();
            }

            return true;
        }

        return true;
    }, [t, selectors, submission]);
    const mergeArrayFields = useCallback((dataToSave) => {
        const arraysInData = selectors.map((selector) => Object
            .keys(dataToSave[selector] || {})
            .filter((key) => Array.isArray(dataToSave[selector][key]))
            .map((key) => `${selector}.${key}`)).flat();
        const details = cloneDeep(dataToSave);

        arraysInData.forEach((key) => {
            const newValue = get(details, key);
            const oldValue = get(submission, key);
            const indexes = Math.max(newValue?.length, oldValue?.length, 0);
            const mergedFields = [];

            for (let i = 0; i < indexes; i += 1) {
                mergedFields[i] = {};
                if (oldValue?.[i]) mergedFields[i] = { ...oldValue[i] };
                if (newValue?.[i]) mergedFields[i] = { ...mergedFields[i], ...newValue[i] };
            }
            set(details, key, mergedFields);
        });

        return details;
    }, [selectors, submission]);
    const saveData = useCallback(async (dataToSave) => {
        if (validateData(dataToSave) && Object.keys(dataToSave)?.length > 0) {
            const details = mergeArrayFields(dataToSave);

            await saveContactDetails({ data: { details } });
        }
    }, [validateData, mergeArrayFields, saveContactDetails]);
    const uploadFiles = useCallback(async (files) => {
        if (!files?.length) return;

        setIsUploading(true);
        setErrorUpload(null);

        try {
            await Promise.all(files
                .filter((file) => file?.file)
                .map((file) => SM.documents('postDocument', [file])));

            setIsUploading(false);
        } catch (err) {
            if (err.response?.status === 422) {
                const { data: { code, message } } = err.response;

                if (code === 'ValidationFailed') {
                    setErrorUpload({ message });
                }
            } else {
                setErrorUpload(err);
            }
            setIsUploading(false);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);

    // Effects
    useEffect(() => {
        getSchema({ data: { selectors } });
    }, [getSchema]);
    useEffect(() => {
        getContactDetails({ data: { selectors } });
    }, [getContactDetails]);
    useEffect(() => {
        getSchemasLists();
    }, [getSchemasLists]);

    return {
        data,
        submission,
        error: error || errorLists || contactDetailsError,
        isLoading: isLoading || isLoadingLists || contactDetailsIsLoading,
        isUploading,
        errorUpload,
        uploadFiles,
        errorValidation,
        errorSave: saveContactDetailsError,
        isSaving: saveContactDetailsIsLoading,
        saveData,
    };
};
