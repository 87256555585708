import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useFormatting, useLocale } from 'locale';
import { adaptSecurityTransactions } from '../../../adapters/adaptSecurityTransactions';

export const useSecurityTransactions = (
    {
        currencyId,
        investmentAllocations,
        sourceAllocations,
        amount,
        portfolioCurrency,
        portfolioId,
        productID,
        clientId,
        dfsClientId,
        actionType,
        linkToOverview,
    },
) => {
    const [errorSescurityTransactions, setError] = useState(null);
    const [isLoadingSecurityTransactions, setLoading] = useState(true);
    const [securityTransactionsData, setData] = useState({});
    const { i18n, t } = useTranslation();
    const { locale } = useLocale();
    const { getFormattedDate, getFormattedNumber, getFormattedCurrency } = useFormatting();

    useEffect(() => {
        setError(null);
        setLoading(true);

        if (investmentAllocations === undefined || amount === undefined) {
            setError({ message: t('clientDashboard.changeStrategy.goBackToChangeStrategy') });
            setLoading(false);

            return;
        }

        const dataSimulate = {
            CurrencyId: currencyId,
            SourceAmount: amount,
            TargetInstrumentAllocations: investmentAllocations,
            SourceInstrumentAllocations: sourceAllocations,
        };

        ServiceManager.portfolioManagement('postPortfolioOrderbookSimulate', [clientId, { language: i18n.language }, dataSimulate])
            .then((response) => {
                try {
                    setData(adaptSecurityTransactions({
                        data: response.data,
                        portfolioId,
                        productID,
                        dfsClientId,
                        actionType,
                        t,
                        getFormattedDate,
                        getFormattedNumber,
                        getFormattedCurrency,
                        linkToOverview,
                    }));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    // eslint-disable-next-line max-len
    }, [productID, currencyId, investmentAllocations, amount, portfolioCurrency, i18n.language, locale]);

    return { securityTransactionsData, isLoadingSecurityTransactions, errorSescurityTransactions };
};
