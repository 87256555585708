import React from 'react';
import PropTypes from 'prop-types';
import PortfoliosTable from 'components/Portfolios';
import { getClientId } from 'utils';
import history from 'services/history';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import { useProposals } from 'hooks/useProposals';
import { CREATE_PORTFOLIO } from 'constants/constants';
import { usePortfolios } from './hooks/usePortfolios';
import { useClientLayout } from '../../hooks/useClientLayout';
import './Portfolios.css';

function Portfolios(props) {
    useCancelAllRequests();
    useClientLayout();

    const { match: { params: { dfsClientId } } } = props;
    const clientId = getClientId(dfsClientId);

    const { hasNewProposals, ...proposalsObj } = useProposals(clientId);

    const portfolios = usePortfolios(clientId, dfsClientId, proposalsObj);
    const onNewClick = () => history.push(`/client/${dfsClientId}/portfolios/risk-profile`);
    const onOpenNewPortfolio = () => { sessionStorage.removeItem(CREATE_PORTFOLIO); history.push(`/client/${dfsClientId}/portfolios/new-portfolio`); };

    return (
        <PortfoliosTable
            {...portfolios}
            onNewClick={onNewClick}
            onOpenNewPortfolio={onOpenNewPortfolio}
        />
    );
}

Portfolios.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ dfsClientId: PropTypes.string.isRequired }),
    }).isRequired,
};

Portfolios.defaultProps = {};

export default Portfolios;
