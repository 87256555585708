import { get } from 'lodash/fp';

export const ACTIVE = 'active';
export const ACTION_REQUIRED = 'actionRequired';
export const NEW_PROPOSAL = 'newProposal';
export const BLOCKED = 'blocked';
export const CLOSED = 'closed';
export const PENDING_ORDERS = 'pendingOrders';
export const NOT_IMPLEMENTED = 'notImplemented';
export const IN_EXECUTION = 'inExecution';
export const CONSTRAINTS_BREACHED = 'constraintsBreached';
export const UNKNOWN = 'unknown';

export const PORTFOLIO_NOT_IMPLEMENTED_ID = 1;
export const PORTFOLIO_IN_EXECUTION = 2;
export const PORTFOLIO_BLOCKED_ID = 4;
export const PORTFOLIO_CLOSED_ID = 5;

export const STATUSES_IMPORTANCE = [
    ACTION_REQUIRED,
    IN_EXECUTION,
    NOT_IMPLEMENTED,
    PENDING_ORDERS,
    CONSTRAINTS_BREACHED,
    NEW_PROPOSAL,
    ACTIVE,
    BLOCKED,
    CLOSED,
    UNKNOWN,
];
export const sortStatuses = (data) => data
    .sort((a, b) => STATUSES_IMPORTANCE.indexOf(a) - STATUSES_IMPORTANCE.indexOf(b));

export const STATUSES_SORT_ORDER = [
    ACTION_REQUIRED,
    IN_EXECUTION,
    NOT_IMPLEMENTED,
    PENDING_ORDERS,
    CONSTRAINTS_BREACHED,
    NEW_PROPOSAL,
    ACTIVE,
    BLOCKED,
    CLOSED,
    UNKNOWN,
];
export const sortByStatus = (data, key) => data.sort((a, b) => STATUSES_SORT_ORDER
    .indexOf(get(key, a)) - STATUSES_SORT_ORDER.indexOf(get(key, b)));

export const portfolioStatuses = (t) => ({
    [ACTIVE]: t('portfolio.status.active'),
    [ACTION_REQUIRED]: t('portfolio.status.actionRequired'),
    [PENDING_ORDERS]: t('portfolio.status.pendingOrders'),
    [CONSTRAINTS_BREACHED]: t('portfolio.status.constraintsBreached'),
    [NEW_PROPOSAL]: t('portfolio.status.newProposal'),
    [NOT_IMPLEMENTED]: t('portfolio.status.notImplemented'),
    [IN_EXECUTION]: t('portfolio.status.inExecution'),
    [BLOCKED]: t('portfolio.status.blocked'),
    [CLOSED]: t('portfolio.status.closed'),
    [UNKNOWN]: t('portfolio.status.unknown'),
});

export const proposalsStatuses = {
    1: ACTIVE,
    2: NEW_PROPOSAL,
    3: ACTIVE,
    4: ACTIVE,
    5: ACTIVE,
};

export const portfolioStatusesColors = {
    [ACTIVE]: 'green',
    [ACTION_REQUIRED]: 'red',
    [PENDING_ORDERS]: 'yellow',
    [NOT_IMPLEMENTED]: 'yellow',
    [IN_EXECUTION]: 'yellow',
    [NEW_PROPOSAL]: 'red',
    [CONSTRAINTS_BREACHED]: 'red',
    [BLOCKED]: 'black',
    [CLOSED]: 'black',
};

export const portfolioStatusesIcons = {
    [ACTIVE]: 'success',
    [ACTION_REQUIRED]: 'error',
    [PENDING_ORDERS]: 'error',
    [NOT_IMPLEMENTED]: 'error',
    [IN_EXECUTION]: 'error',
    [NEW_PROPOSAL]: 'error',
    [CONSTRAINTS_BREACHED]: 'error',
    [BLOCKED]: 'error',
    [CLOSED]: 'error',
};

export const getPortfolioStatus = ({
    portfolioStatusId, isBreached, isOrderBookBlocked, proposalStatus,
}) => {
    switch (portfolioStatusId) {
        case 1: return NOT_IMPLEMENTED;
        case 2: return IN_EXECUTION;
        case 3: {
            if (isOrderBookBlocked) {
                return PENDING_ORDERS;
            }
            if (isBreached) {
                return CONSTRAINTS_BREACHED;
            }
            if (proposalStatus) {
                return proposalStatus;
            }

            return ACTIVE;
        }
        case 4: return BLOCKED;
        case 5: return CLOSED;
        default: return UNKNOWN;
    }
};
