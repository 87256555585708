import withClientTable from '../hocs/withClientTable';
import ClientsTable from '../components/ClientsTable';
import { tableColumnsClient, tableColumnsProspect } from '../constants';

export const ClientList = withClientTable(
    ClientsTable, false, tableColumnsClient,
);
export const ProspectusList = withClientTable(
    ClientsTable, true, tableColumnsProspect,
);
