import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    investorPerformanceSelectValues, YTD, investorProposedPerformanceSelectValues,
} from 'filters/investementPerformanceFilters';
import {
    LineChart, TabPane, Tabs, TabsExtraItem,
} from 'ui-library';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import PageSection from 'components/PageSection';
import CustomRangeSelect from 'components/CustomRangeSelect';
import { useFormatting } from 'locale';
import { lineChartLabels } from 'constants/constants';
import {
    chartValueFormatCurrency, formatNumberRounding, formatWithDate,
} from 'utils/formatting';
import { INTEGER, PERCENTAGE } from 'constants/instrument';
import { getPeriodByFilter } from '../../utils';
import { usePriceHistory } from './hooks/usePriceHistory';

const chartValueFormat = {
    symbol: '%',
    precision: 2,
    thousand: ',',
    decimal: '.',
    format: '%v%s',
};

const filterNamespace = 'clientDashboard.assetDevelopment.filters';
const getInvestorPerformanceSelectOptions = (t, isPortfolioProposed) => (
    (isPortfolioProposed
        ? investorProposedPerformanceSelectValues
        : investorPerformanceSelectValues).map((item) => ({
        ...item,
        label: t(`${filterNamespace}.${item.value}`),
    }))
);

const DetailsPerformanceGraph = ({
    currency,
    positionId,
    currencyId,
    priceType,
    isPortfolioProposed,
}) => {
    const { t } = useTranslation();
    const {
        getFormattedNumber, getFormattedXAxisFormat, getFormattedCurrency, getFormattedDate,
    } = useFormatting();
    const [currentFilter, setCurrentFilter] = useState(YTD);

    // Price
    const adaptValuePrice = useCallback((item) => item.Price, []);
    const adaptValuePerformance = useCallback((item) => item.CumPerf * 100, []);
    const { startDate, endDate } = getPeriodByFilter(currentFilter);
    const { data, isLoading, error } = usePriceHistory({
        positionId, currencyId, startDate, endDate, adaptValuePrice, adaptValuePerformance,
    });

    // Handlers
    const tooltipFormat = {
        performance: (number, date) => formatWithDate(`${getFormattedNumber(number)}%`, date, getFormattedDate),
        price: (number, date) => (priceType === PERCENTAGE ? formatWithDate(
            `${getFormattedNumber(number)}%`,
            date,
            getFormattedDate,
        ) : formatWithDate(
            getFormattedCurrency(number, { currency }),
            date,
            getFormattedDate,
        )),
    };
    const yAxisFormat = {
        performance: ({ value }) => `${getFormattedNumber(value)}%`,
        price: ({ value }) => (priceType === PERCENTAGE
            ? `${getFormattedNumber(value)}%`
            : formatNumberRounding(value, getFormattedNumber)),
    };
    const onFilterChange = (value) => {
        setCurrentFilter(value);
    };

    return (
        <PageSection className="performance">
            <Tabs
                defaultActiveKey="1"
                style={{
                    overflow: 'visible',
                }}
                tabBarExtraContent={(
                    <TabsExtraItem>
                        <CustomRangeSelect
                            onChange={onFilterChange}
                            options={
                                getInvestorPerformanceSelectOptions(t, isPortfolioProposed)
                            }
                            width={160}
                            value={currentFilter}
                        />
                    </TabsExtraItem>
                )}
            >
                <TabPane tab={t('position.performance')} key="1" className="performance-tab">
                    <Preloader isLoading={isLoading} error={error}>
                        <EmptyContent data={data.performance?.[0]?.data} text={t('clientDashboard.portfolioEdit.noDataFound')}>
                            <LineChart
                                data={data.performance}
                                height={252}
                                valueFormat={chartValueFormat}
                                tooltipFormat={tooltipFormat.performance}
                                yAxisFormat={yAxisFormat.performance}
                                xAxisFormat={getFormattedXAxisFormat}
                                labels={lineChartLabels(t)}
                            />
                        </EmptyContent>
                    </Preloader>
                </TabPane>
                <TabPane tab={t('position.price')} key="2" className="price-tab">
                    <Preloader isLoading={isLoading} error={error}>
                        <EmptyContent data={data.price?.[0]?.data} text={t('clientDashboard.portfolioEdit.noDataFound')}>
                            <LineChart
                                data={data.price}
                                height={252}
                                yTitle={priceType !== PERCENTAGE ? currency : ''}
                                valueFormat={chartValueFormatCurrency}
                                tooltipFormat={tooltipFormat.price}
                                yAxisFormat={yAxisFormat.price}
                                xAxisFormat={getFormattedXAxisFormat}
                                labels={lineChartLabels(t)}
                            />
                        </EmptyContent>
                    </Preloader>
                </TabPane>
            </Tabs>
        </PageSection>
    );
};

DetailsPerformanceGraph.propTypes = {
    currency: PropTypes.string,
    positionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currencyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    priceType: PropTypes.oneOfType([PERCENTAGE, INTEGER]),
    isPortfolioProposed: PropTypes.bool,
};

DetailsPerformanceGraph.defaultProps = {
    currency: '',
    positionId: undefined,
    currencyId: undefined,
    priceType: INTEGER,
    isPortfolioProposed: false,
};

const DetailsPerformance = ({
    error,
    ...restProps
}) => {
    const { t } = useTranslation();

    if (error?.type === 'API ERROR') {
        return <p className="widget__empty">{t('position.performanceNoHistory')}</p>;
    }

    return (
        <DetailsPerformanceGraph {...restProps} />
    );
};

DetailsPerformance.propTypes = {
    error: PropTypes.shape({
        type: PropTypes.string,
    }),
};

DetailsPerformance.defaultProps = {
    error: {},
};

export default DetailsPerformance;
