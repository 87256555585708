import React from 'react';
import PropTypes from 'prop-types';
import history from 'services/history';
import { useSelector } from 'react-redux';
import { memberIdSelector } from 'redux/auth/authSelectors';
import { useTranslation } from 'react-i18next';
import { Button, Column, Title } from 'ui-library';
import { ListPreview, Preview } from 'templates/ListPreview';
import { PageNotFound } from 'components/PageError';
import { useDownloadFile } from 'hooks/useDownloadFile';
import Metadata from 'components/Messages/Metadata';
import Attachments from 'components/Messages/Attachments';
import ListComponent from 'components/Messages/ListComponent';
import { withMainLayout } from 'hocs';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import { useMessaging } from './hooks/useMessaging';

import './Messages.css';

const MessagesNew = ({ match }) => {
    useCancelAllRequests();
    const { t } = useTranslation();
    const memberId = useSelector(memberIdSelector);
    const { params: { messageId } } = match;
    const {
        tab,
        needUpdate,
        getData,
        onSearch,
        changeTab,
        getPreview,
        deleteItem,
        getDataCallback,
        data: messages,
    } = useMessaging(memberId);
    const { downloadFile } = useDownloadFile(memberId);

    if (memberId === undefined) {
        return (<PageNotFound />);
    }

    const messagesLength = messages?.length || 0;

    const onNewMessage = () => {
        history.push('/messages/new');
    };
    const onReply = ({
        id, subtypeId, recipients, title,
    }) => {
        history.push(
            `/messages/reply/${id}`,
            {
                title,
                memberId,
                subtypeId,
                threadId: recipients[0].threadId,
            },
        );
    };

    const titleComponent = (
        <>
            <Column size={['sm-4']}>
                <Title type={2}>{t('messages.title')}</Title>
            </Column>
            <Column size={['sm-8']} className="text-sm-right button-group">
                <Button type="primary" size="small" onClick={onNewMessage}>
                    {t('messages.newMessage')}
                </Button>
            </Column>
        </>
    );

    const listComponent = (propsComponent) => (
        <div className="messages-list">
            <ListComponent
                tab={tab}
                needUpdate={needUpdate}
                changeTab={changeTab}
                useDynamicSorting
                onNewMessage={onNewMessage}
                {...propsComponent}
            />
        </div>
    );
    const renderActions = (propsComponent) => (tab === 'inbox' && (
        <Button type="secondary" size="small" onClick={() => onReply(propsComponent)}>
            {t('messages.reply')}
        </Button>
    ));
    const renderMetadata = (propsCmp) => Metadata({ ...propsCmp, t });
    const renderAttachments = (propsCmp) => Attachments({ ...propsCmp, downloadFile, t });

    return (
        <div className="messages-page">
            <ListPreview
                getData={getData}
                onSearch={onSearch}
                preSelect={messageId}
                getPreview={getPreview}
                listComponent={listComponent}
                titleComponent={titleComponent}
                getDataCallback={getDataCallback}
                dataLength={messagesLength}
                searchBarActive
            >
                {(previewProps) => (

                    <Preview
                        {...previewProps}
                        deleteItem={deleteItem}
                        deleteTitle={t('messages.deleteSelected')}
                        deleteText={t('messages.deleteCurrentContent')}
                        renderActions={renderActions}
                        renderMetadata={renderMetadata}
                        renderAttachments={renderAttachments}
                        showDelete
                    />
                )}
            </ListPreview>
        </div>
    );
};

MessagesNew.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ messageId: PropTypes.string }),
    }),
};

MessagesNew.defaultProps = {
    match: { params: {} },
};

export default withMainLayout(MessagesNew);
