import { renderers } from 'ui-library';
import { CellPercent, CellWithMoreActions, CellSwitch } from 'components/renderers';
import { sorterByDate, sorterNumber, sorterString } from 'utils/sorting';
import CellValue from '../SinglePortfolioView/renderers/CellValue';

const { CellWithIcon, CellTooltiped } = renderers;

export const EXECUTION_ONLY = 'Execution Only';
export const EXECUTION_ONLY_ID = 4;

export const getCashFlowsColumns = (t) => ([
    {
        key: 'Account',
        title: t('clientDashboard.portfolio.columns.account'),
        render: CellTooltiped,
        sortable: true,
        minWidth: 290,
    },
    {
        key: 'Date',
        title: t('clientDashboard.portfolio.columns.date'),
        sortable: true,
        sorter: sorterByDate('Date'),
        defaultSortOrder: 'ascend',
        width: 187,
        minWidth: 90,
    },
    {
        key: 'Type',
        title: t('clientDashboard.portfolio.columns.type'),
        minWidth: 90,
        sortable: true,
    },
    {
        key: 'Currency',
        title: t('clientDashboard.portfolio.columns.currency'),
        minWidth: 50,
        sortable: true,
    },
    {
        key: 'Amount',
        title: t('clientDashboard.portfolio.columns.amount'),
        className: 'text-right',
        minWidth: 150,
        sortable: true,
        sorter: sorterNumber('Amount'),
    },
]);

export const ORDER_TYPE = {
    1: 'orderType.buy',
    2: 'orderType.sell',
};

export const getPositionsColumns = (t) => ([
    {
        key: 'name',
        title: t('clientDashboard.portfolio.columns.name'),
        sortable: true,
        render: CellSwitch,
        width: 150,
        minWidth: 150,
    },
    {
        title: t('clientDashboard.portfolio.columns.isin'),
        key: 'isin',
        sortable: true,
        sorter: sorterString('isin'),
    },
    {
        title: t('clientDashboard.portfolio.columns.type'),
        key: 'type',
        sortable: true,
        sorter: sorterString('type'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.units'),
        key: 'units',
        width: 75,
        minWidth: 75,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('units'),
        render: CellValue,
    },
    {
        title: t('clientDashboard.portfolio.columns.currency'),
        key: 'currency',
        sortable: true,
        sorter: sorterString('currency'),
        className: 'currency-cel',
        width: 80,
        minWidth: 80,
    },
    {
        title: t('clientDashboard.portfolio.columns.latestPrice'),
        key: 'latestPrice',
        width: 107,
        minWidth: 107,
        sortable: true,
        sorter: sorterNumber('latestPrice'),
        className: 'text-right',
        render: CellValue,
    },
    {
        key: 'performance',
        title: t('clientDashboard.portfolio.columns.performanceSI'),
        className: 'text-right',
        width: 155,
        minWidth: 155,
        sortable: true,
        sorter: sorterNumber('performance'),
        render: CellPercent,
    },
    {
        title: t('clientDashboard.portfolio.columns.subAssetClass'),
        key: 'subAssetClass',
        width: 200,
        minWidth: 200,
        sortable: true,
        sorter: sorterString('subAssetClass'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.country'),
        key: 'country',
        width: 250,
        minWidth: 250,
        sortable: true,
        sorter: sorterString('country'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.region'),
        key: 'region',
        width: 150,
        minWidth: 150,
        sortable: true,
        sorter: sorterString('region'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.sector'),
        key: 'sector',
        width: 100,
        minWidth: 100,
        sortable: true,
        sorter: sorterString('sector'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.subSector'),
        key: 'subSector',
        width: 200,
        minWidth: 200,
        sortable: true,
        sorter: sorterString('subSector'),
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.costPrice'),
        key: 'costPrice',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('costPrice'),
        width: 150,
        minWidth: 150,
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.valuationInSecurityCurrency'),
        className: 'text-right',
        key: 'valuationInSecurityCurrency',
        sortable: true,
        sorter: sorterNumber('valuationInSecurityCurrency'),
        width: 200,
        minWidth: 200,
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.fxRate'),
        key: 'fxRate',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('fxRate'),
        width: 150,
        minWidth: 150,
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.unrealizedGainLoss'),
        key: 'unrealizedGainLoss',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('unrealizedGainLoss'),
        width: 200,
        minWidth: 200,
        hidden: true,
    },
    {
        title: t('clientDashboard.portfolio.columns.allocation'),
        key: 'allocation',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('allocation'),
        width: 120,
        minWidth: 120,
    },
    {
        title: t('clientDashboard.portfolio.columns.valueData'),
        className: 'text-right',
        key: 'valuation',
        sortable: true,
        sorter: sorterNumber('valuation'),
        width: 150,
        minWidth: 150,
    },
    {
        title: '',
        className: 'text-right table-cell__actions',
        key: 'actions',
        render: CellWithMoreActions,
        width: 40,
        minWidth: 40,
        titleType: 'columns',
        fixed: 'right',
        headerRenderer: CellWithIcon,
    },
]);

export const getTransactionsColumns = (t) => ([
    {
        key: 'Date',
        title: t('clientDashboard.portfolio.columns.date'),
        sortable: true,
        sorter: sorterByDate('Date'),
        defaultSortOrder: 'ascend',
        width: 100,
    },
    {
        key: 'Name',
        title: t('clientDashboard.portfolio.columns.name'),
        render: CellSwitch,
        sortable: true,
        sorter: sorterString('Name.label'),
        width: 200,
    },
    {
        key: 'Isin',
        title: t('position.columns.isin'),
        hidden: true,
        sorter: sorterString('Isin'),
    },
    {
        key: 'SubAssetClass',
        title: t('position.columns.subAssetClass'),
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('SubAssetClass'),
    },
    {
        key: 'Country',
        title: t('position.columns.country'),
        width: 250,
        minWidth: 250,
        hidden: true,
        sorter: sorterString('Country'),
    },
    {
        key: 'Region',
        title: t('position.columns.region'),
        width: 150,
        minWidth: 150,
        hidden: true,
        sorter: sorterString('Region'),
    },
    {
        key: 'Sector',
        title: t('position.columns.sector'),
        width: 100,
        minWidth: 100,
        hidden: true,
        sorter: sorterString('Sector'),
    },
    {
        key: 'SubSector',
        title: t('position.columns.subSector'),
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('SubSector'),
    },
    {
        key: 'Type',
        title: t('clientDashboard.portfolio.columns.type'),
        sortable: true,
        sorter: sorterString('Type'),
    },
    {
        key: 'Status',
        title: t('portfolios.columns.status'),
        sorter: sorterString('Status'),
        hidden: true,
    },
    {
        key: 'Number',
        title: t('clientDashboard.portfolio.columns.number'),
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Number'),
    },
    {
        key: 'Price',
        title: t('clientDashboard.portfolio.columns.price'),
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Price'),
    },
    {
        key: 'FxRate',
        title: t('clientDashboard.portfolio.columns.fxRate'),
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('FxRate'),
    },
    {
        key: 'Total',
        title: t('clientDashboard.portfolio.columns.total'),
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Total'),
    },
    {
        key: 'Icon',
        title: '',
        titleType: 'columns',
        width: 20,
        minWidth: 20,
        className: 'text-right',
    },
]);

// TODO: unused code bellow, delete after approval
export const DISCRETIONARY = 'Discretionary';

export const assetClassesPieChartData = [
    {
        name: 'Cash',
        value: 11340.010,
    },
    {
        name: 'Equities',
        value: 51780.010,
    },
    {
        name: 'Bonds',
        value: 36880.010,
    },
    {
        name: 'Others',
        value: 10080.010,
    },
];

export const invPrfLineChartData = [
    {
        data: [
            [1167609600000, -1],
            [1167696000000, 1],
            [1167782400000, 2.5],
            [1167868800000, 1],
            [1167955200000, 0.5],
            [1168214400000, 3],
        ],
    },
];

export const invPrfLineChartValueFormat = {
    symbol: '%',
    precision: 0,
    thousand: ',',
    decimal: '.',
    format: '%v%s',
};

export const invPrfSelectOptions = [
    'YTD', 'Last 12 months', '2 Years', '5 Years', 'Max',
];

export const positionsData = [
    {
        Name: 'Bonds',
        Product: 'Execution only',
        Profile: 'Conservative',
        Value: '2.130.291,10',
        Performance: '+24.59%',
        Icon: 'action',
        subRows: [
            {
                Name: 'RC EUROP STOCK THIRTEEN SMP CR0849899652',
                Product: 'Execution only',
                Profile: 'Conservative',
                Value: '2.130.291,10',
                Performance: '+24.59%',
                Icon: 'action',
            },
            {
                Name: 'CR EUROP STOCK THIRTEEN SMP CR0865999652',
                Product: 'Execution only',
                Profile: 'Conservative',
                Value: '2.130.291,10',
                Performance: '+24.59%',
                Icon: 'action',
            },
        ],
    },
    {
        Name: 'Equities',
        Product: 'Discretionary',
        Profile: 'Balanced',
        Value: '771.277,90',
        Performance: '+5.98%',
        Icon: 'action',
    },
];
