import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { NotificationProvider } from 'additiv-react-signalr';
import './configs/setupAdditivServices';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemingProvider } from 'theming';
import { themesVariables } from 'utils/configureTheme';
import AuthenticationProvider from 'domain/Auth/AuthProvider';
import * as serviceWorker from './serviceWorker';
import store from './store';

import 'styles';

import App from './App';

import './translations/i18n';

import { LocaleProvider } from './locale';
import { UnreadMessagesProvider } from './prodivers/unreadMessages';
import { LayoutProvider } from './prodivers/layout';
import ErrorHandler from './errors';
import SM from './services/ServiceManager';

const errorHandlerLog = (errorModel) => {
    const styles = {
        title: 'color: brown; font-size: 16px; font-weight: bold;',
        type: 'color: white; background-color: brown; font-size: 14px; font-weight: bold; border-radius: 3px;',
        stack: 'color: brown',
    };

    console.error(`%c⚠️ New Error with type: %c ${errorModel.type} \n%c${errorModel.stack}`, styles.title, styles.type, styles.stack);
};

ErrorHandler.configure({
    log: errorHandlerLog,
    DFSLoggerConfig: {
        enabled: true,
        endpointFunc: (data) => SM.middleware('log', [data]),
    },
});

ReactDOM.render((
    <Provider store={store}>
        <LocaleProvider>
            <LayoutProvider>
                <ThemingProvider
                    themesVariables={themesVariables}
                    saveToLocalStorage
                >
                    <AuthenticationProvider>
                        <UnreadMessagesProvider>
                            <NotificationProvider>
                                <App />
                            </NotificationProvider>
                        </UnreadMessagesProvider>
                    </AuthenticationProvider>
                </ThemingProvider>
            </LayoutProvider>
        </LocaleProvider>

    </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
