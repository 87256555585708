import React, { useEffect, useState } from 'react';
import InteractionDetails from 'templates/Interaction/containers/InteractionDetails';
import PropTypes from 'prop-types';
import { getUTCDate, useFormatting, useLocale } from 'locale';
import {
    Infobox, Table, TabPane, Tabs, Textarea,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useSelector } from 'react-redux';
import { memberProfileSelector } from 'redux/auth/authSelectors';
import { getDatesDifference } from 'utils/datetime';
import { LOG_ENTRIES_COLUMNS } from '../constants';

function PreviewContent(props) {
    const {
        data, editMode, setEditData, tab, changeTab,
        columns, createEntryMode, formErrors, setFormErrors,
    } = props;

    const [values, setValues] = useState({
        comment: data.comment,
        to: data.to,
        from: data.from,
        participants: data.participants,
        initiated: data.initiated,
        topic: data.topic,
        type: data.type,
        email: false,
    });

    const { getFormattedDate, getFormattedTime } = useFormatting();
    const { t } = useTranslation();
    const { locale } = useLocale();
    const member = useSelector(memberProfileSelector);

    const verifyFormData = (formValues) => {
        const errors = [];

        // Dates from < to
        if (getDatesDifference(formValues.to, formValues.from) < 0) {
            errors.push(t('interaction.validation.fromBeforeTo'));
        }

        setFormErrors(errors);
    };
    const onChange = (type) => (value) => {
        const newValue = value?.target?.checked ?? value;
        const newValues = { ...values, [type]: newValue };

        verifyFormData(newValues);
        setValues(newValues);
    };
    const onChangeComment = (e) => {
        const { value } = e.currentTarget;

        setValues((prev) => ({
            ...prev,
            comment: value,
        }));
    };

    useEffect(() => {
        if (!editMode || !createEntryMode) {
            setValues({
                comment: '',
                to: data.to,
                from: data.from,
                participants: data.participants,
                initiated: data.initiated,
                topic: data.topic,
                type: data.type,
                journals: data.journals,
                email: false,
            });
            setFormErrors([]);
        }
    }, [
        createEntryMode,
        data.comment,
        data.from,
        data.initiated,
        data.journals,
        data.participants,
        data.to,
        data.topic,
        data.type,
        editMode]);

    useEffect(() => {
        if (editMode || createEntryMode) {
            const journals = [{
                message: values.comment,
                time: getUTCDate(new Date()),
                createdBy: `${member.FirstName} ${member.LastName}`,
            }, ...data.journals];

            setEditData({
                ...data,
                from: values.from,
                to: values.to,
                comment: values.comment,
                DateFrom: getUTCDate(values.from),
                DateTo: getUTCDate(values.to),
                Date: {
                    date: getUTCDate(values.from),
                    isReplied: false,
                },
                journals,
                email: values.email,
            });
        }
    }, [
        editMode,
        data,
        values.from,
        values.to,
        values.comment,
        setEditData,
        createEntryMode,
        member.FirstName,
        member.LastName,
        values.email,
    ]);

    return (
        <Tabs activeKey={tab} defaultActiveKey="log-entries" onChange={changeTab}>
            <TabPane tab={t('interaction.logEntries')} key="log-entries" className="log-entries">
                <Preloader isLoading={false} error={null}>
                    <EmptyContent data={data.journals} text={t('advisoryDashboard.noData')}>
                        {createEntryMode ? (
                            <div className="add-entry">
                                <Textarea
                                    value={values.comment}
                                    label={t('interaction.newLogEntries')}
                                    error={values.comment.length < 10 && t('interaction.commentsHelpText')}
                                    helpText={t('interaction.commentsHelpText')}
                                    placeholder={t('interaction.commentsPlaceholder')}
                                    onChange={onChangeComment}
                                    labelInfobox
                                />
                            </div>
                        ) : (
                            <Table
                                columns={columns({
                                    t, getFormattedDate, getFormattedTime, locale,
                                })}
                                data={data.journals}
                            />
                        )}
                    </EmptyContent>
                </Preloader>
            </TabPane>
            <TabPane tab={t('interaction.interactionDetails')} key="interaction-details">
                <InteractionDetails
                    data={{
                        topics: [],
                        initiated: [],
                        participants: [],
                        types: [],
                    }}
                    values={values}
                    editableFields={{ from: editMode, to: editMode, email: editMode }}
                    onChange={onChange}
                    onCommentChange={onChangeComment}
                    showComment={false}
                    convertToUTC
                />
                {editMode && formErrors.length > 0 && (
                    <Infobox accent error>
                        {formErrors.map((err) => (
                            <>
                                {err}
                                <br />
                            </>
                        ))}
                    </Infobox>
                )}
            </TabPane>
        </Tabs>
    );
}

PreviewContent.propTypes = {
    data: PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
        initiated: PropTypes.string,
        participants: PropTypes.arrayOf(PropTypes.string),
        type: PropTypes.string,
        topic: PropTypes.string,
        comment: PropTypes.string,
        journalId: PropTypes.number,
        id: PropTypes.number,
        journals: PropTypes.arrayOf(PropTypes.shape({
            message: PropTypes.string,
            time: PropTypes.string,
            createdBy: PropTypes.string,
        })),
    }),
    editMode: PropTypes.bool,
    setEditData: PropTypes.func,
    tab: PropTypes.string.isRequired,
    changeTab: PropTypes.func.isRequired,
    columns: PropTypes.func,
    createEntryMode: PropTypes.bool,
    formErrors: PropTypes.arrayOf(PropTypes.string),
    setFormErrors: PropTypes.func,
};

PreviewContent.defaultProps = {
    data: {},
    editMode: false,
    setEditData: () => {},
    columns: LOG_ENTRIES_COLUMNS,
    createEntryMode: false,
    formErrors: [],
    setFormErrors: () => {},
};

export default PreviewContent;
