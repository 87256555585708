import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'ui-library';
import {
    OVERVIEW_SIZE_MEDIUM, OverviewItem, OverviewPanel,
} from 'components/Overview';
import { TYPE_CURRENCY, TYPE_PERCENT } from 'utils/formatting';
import { EXECUTION_ONLY_ID } from '../constants';

function Overview(props) {
    const {
        data, portfolioId, productId, dfsClientId, disableChangeStrategy, disableChangeModel,
    } = props;
    const { t } = useTranslation();
    const showStrategy = useMemo(() => productId !== EXECUTION_ONLY_ID, [productId]);

    const strategyRender = useMemo(() => {
        if (!showStrategy) return null;

        if (disableChangeStrategy) {
            return (
                <OverviewItem
                    title={t('clientDashboard.portfolio.strategy')}
                    value={data.strategy}
                    size={OVERVIEW_SIZE_MEDIUM}
                />
            );
        }

        return (
            <OverviewItem
                title={t('clientDashboard.portfolio.strategy')}
                value={data.strategy}
                linkLabel={t('clientDashboard.portfolio.changeStrategy')}
                link={`/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy`}
                size={OVERVIEW_SIZE_MEDIUM}
            />
        );
    }, [showStrategy, disableChangeStrategy, t, data.strategy, dfsClientId, portfolioId]);
    const modelPortfolioRender = useMemo(() => {
        if (!showStrategy) return null;

        if (disableChangeModel) {
            return (
                <OverviewItem
                    title={t('clientDashboard.portfolio.modelPortfolio')}
                    value={data.modelPortfolio}
                    size={OVERVIEW_SIZE_MEDIUM}
                />
            );
        }

        return (
            <OverviewItem
                title={t('clientDashboard.portfolio.modelPortfolio')}
                value={data.modelPortfolio}
                linkLabel={t('clientDashboard.portfolio.changeModel')}
                link={`/client/${dfsClientId}/portfolios/${portfolioId}/change-model`}
                size={OVERVIEW_SIZE_MEDIUM}
            />
        );
    }, [showStrategy, disableChangeModel, t, data.modelPortfolio, dfsClientId, portfolioId]);

    return (
        <OverviewPanel>
            <Row>
                <Column className="overview-column" size={['md-6', 'sm-6', 'lg-4']}>
                    <OverviewItem
                        title={t('clientDashboard.portfolio.portfolioValue')}
                        value={data.portfolioValue}
                        type={TYPE_CURRENCY}
                        currency={data.currency}
                        size={OVERVIEW_SIZE_MEDIUM}
                    />
                    <OverviewItem
                        title={t('clientDashboard.portfolio.product')}
                        value={data.product}
                        size={OVERVIEW_SIZE_MEDIUM}
                    />
                </Column>
                <Column className="overview-column" size={['md-6', 'sm-6', 'lg-4']}>
                    <OverviewItem
                        title={t('clientDashboard.portfolio.performanceSI')}
                        value={data.performance}
                        type={TYPE_PERCENT}
                        size={OVERVIEW_SIZE_MEDIUM}
                        colored
                    />
                    {strategyRender}
                </Column>
                <Column className="overview-column" size={['md-6', 'sm-6', 'lg-4']}>
                    <OverviewItem
                        title={t('clientDashboard.portfolio.firstInvestment')}
                        value={data.firstInvestment}
                        size={OVERVIEW_SIZE_MEDIUM}
                    />
                    {modelPortfolioRender}
                </Column>
            </Row>
        </OverviewPanel>
    );
}

Overview.propTypes = {
    data: PropTypes.shape({
        portfolioValue: PropTypes.number,
        currency: PropTypes.string,
        strategy: PropTypes.string,
        modelPortfolio: PropTypes.string,
        product: PropTypes.string,
        performance: PropTypes.number,
        sharpRatio: PropTypes.number,
        expectedReturn: PropTypes.number,
        expectedRisk: PropTypes.number,
        firstInvestment: PropTypes.string,
    }),
    portfolioId: PropTypes.string.isRequired,
    productId: PropTypes.number.isRequired,
    dfsClientId: PropTypes.string.isRequired,
    disableChangeStrategy: PropTypes.bool,
    disableChangeModel: PropTypes.bool,
};

Overview.defaultProps = {
    data: {},
    disableChangeStrategy: false,
    disableChangeModel: false,
};

export default Overview;
