import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RangeSlider, Infobox } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { useOnSubmit } from '../hooks/useOnSubmit';

const InvestmentHorizon = ({
    data, submit, answers, sendData,
}) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(answers);
    const onChangeSlider = (value) => setSelected(data[0].Answers[value].Id);
    const marks = useMemo(
        () => data[0].Answers.reduce((acc, { Text }, i) => (
            { ...acc, [i]: Text }
        ), {}),
        [data],
    );
    const showError = useOnSubmit((submit), sendData, selected, !!selected);
    const selectedIndex = useMemo(
        () => {
            const index = data[0].Answers.findIndex(({ Id }) => Id === selected);

            return index === -1 ? undefined : index;
        },
        [selected, data],
    );

    return (
        <div className="investment-horizon">
            {showError && <Infobox error>{t('clientDashboard.riskProfile.selectAnswer')}</Infobox>}
            {
                data[0] && (
                    <RangeSlider
                        marks={marks}
                        min={0}
                        max={data[0].Answers.length - 1}
                        included={false}
                        onChange={onChangeSlider}
                        value={selectedIndex}
                        dotHidden
                    />
                )
            }
            {data[0].Description && <Infobox accent>{data[0].Description}</Infobox>}
        </div>
    );
};

InvestmentHorizon.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        Id: PropTypes.number,
        Description: PropTypes.string,
        Answers: PropTypes.arrayOf(PropTypes.shape({
            Id: PropTypes.number,
            Text: PropTypes.string,
        })),
    })),
    submit: PropTypes.bool,
    answers: PropTypes.shape({
        [PropTypes.string]: PropTypes.any,
    }),
    sendData: PropTypes.func,
};

InvestmentHorizon.defaultProps = {
    data: [],
    submit: false,
    answers: null,
    sendData: () => { },
};

export default InvestmentHorizon;
