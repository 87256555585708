/* eslint-disable no-param-reassign,no-multi-assign */
import { isUndefined } from 'lodash/fp';
import { getParent, roundAllocations } from 'utils';
import { sortAllocations } from 'utils/sortingAllocation';
import {
    allocationPercentFormat,
    formatPercentage,
    NA,
    formatCurrencyWithPrecision,
} from 'utils/formatting';
import {
    BAR, LIST, PIE, PIE_SA,
} from 'constants/constants';


const adaptChartTypes = (
    data,
    currency,
    chartTypesList,
    getFormattedCurrency,
) => ({
    [PIE]: chartTypesList.includes(PIE) ? { data } : undefined,
    [PIE_SA]: chartTypesList.includes(PIE_SA) ? { data } : undefined,
    [BAR]: chartTypesList.includes(BAR)
        ? {
            data: [{
                data: data.map(({ value, color }) => ({ y: value, color })),
            }],
            labels: data.map(({ name }) => name),
        }
        : undefined,
    [LIST]: chartTypesList.includes(LIST)
        ? {
            data: data.map((item) => ({
                name: item.name,
                value: getFormattedCurrency
                    ? getFormattedCurrency(item?.value, { currency })
                    : formatCurrencyWithPrecision(item?.value, 0, currency),
                percent: allocationPercentFormat(item.percent),
                children: item.children,
            })),
        }
        : undefined,
});

const getAmount = (amount, percentage, investmentValuePortfolioCurrency, securitiesValue) => {
    if (investmentValuePortfolioCurrency) {
        return (amount || 0) + investmentValuePortfolioCurrency;
    }
    if (!isUndefined(securitiesValue)) {
        return percentage * securitiesValue / 100;
    }

    return NA;
};

const getChildren = (
    name, allocation, investmentValuePortfolioCurrency, securitiesValue, id, percent, color,
) => {
    const val = investmentValuePortfolioCurrency
        || (!isUndefined(securitiesValue) ? allocation * securitiesValue : undefined);

    return {
        id,
        name,
        color,
        percent: isUndefined(percent) ? allocation * 100 : percent,
        value: isUndefined(val) ? 0 : val,
    };
};

const getPercentage = (percentage, allocation) => (percentage || 0) + allocation;

export const buildAllocationGroup = (positions, currencyPortfolio, t, securitiesValue) => {
    const percents = (roundAllocations(positions, 100, 1) || []).reduce((acc, item) => ({
        ...acc,
        [item.id]: item.value,
    }), {});

    return positions.reduce((obj, item) => {
        const allocation = percents[item.Security.Id]?.value || item.Allocation * 100;

        if (item.Security.AssetClass) {
            const assetName = getParent(item.Security.AssetClass).Name;
            const asset = obj.asset[assetName] = obj.asset[assetName] || {
                children: [],
            };
            const assetInd = asset.children
                .findIndex((child) => child.id === item.Security.AssetClass.Id);
            const color = getParent(item.Security.AssetClass).Color
                || item.Security.AssetClass.Color;
            const child = getChildren(
                item.Security.AssetClass.Name, item.Allocation,
                item.InvestmentValuePortfolioCurrency, securitiesValue, item.Security.AssetClass.Id,
                percents[item.Security.Id]?.value, color,
            );

            asset.id = getParent(item.Security.AssetClass)?.Id;
            asset.color = color;
            asset.percentage = getPercentage(asset.percentage, allocation);
            asset.amount = getAmount(
                asset.amount, asset.percentage,
                item.InvestmentValuePortfolioCurrency, securitiesValue,
            );

            if (assetInd !== -1) {
                asset.children[assetInd].percent += child.percent;
                asset.children[assetInd].value += child.value;
            } else {
                asset.children.push(child);
            }
        }

        if (item.Security.Type) {
            const typeName = getParent(item.Security.Type).Name;
            const type = obj.type[typeName] = obj.type[typeName] || {};

            type.percentage = getPercentage(type.percentage, allocation);
            type.amount = getAmount(
                type.amount, type.percentage,
                item.InvestmentValuePortfolioCurrency, securitiesValue,
            );
        }

        if (item.Security.Currency !== undefined) {
            const currencyName = item.Security.Currency.Name;
            const currency = obj.currency[currencyName] = obj.currency[currencyName] || {};

            currency.percentage = getPercentage(currency.percentage, allocation);
            currency.amount = getAmount(
                currency.amount, currency.percentage,
                item.InvestmentValuePortfolioCurrency, securitiesValue,
            );
        }

        if (item.Security.Country !== undefined && item.Security.Country.Continent !== undefined) {
            const countryName = item.Security.Country.Continent.Name;
            const country = obj.country[countryName] = obj.country[countryName] || {};

            country.percentage = getPercentage(country.percentage, allocation);
            country.amount = getAmount(
                country.amount, country.percentage,
                item.InvestmentValuePortfolioCurrency, securitiesValue,
            );
        }

        if (item.Security.Sector !== undefined) {
            const sectorName = getParent(item.Security.Sector).Name;
            const sector = obj.sector[sectorName] = obj.sector[sectorName] || {
                children: [],
            };
            const sectorInd = sector.children
                .findIndex((child) => child.id === item.Security.Sector.Id);
            const color = getParent(item.Security.Sector).Color
                || item.Security.Sector.Color;
            const child = getChildren(
                item.Security.Sector.Name, item.Allocation,
                item.InvestmentValuePortfolioCurrency, securitiesValue, item.Security.Sector.Id,
                percents[item.Security.Id]?.value, color,
            );

            sector.color = color;
            sector.percentage = getPercentage(sector.percentage, allocation);
            sector.amount = getAmount(
                sector.amount, sector.percentage,
                item.InvestmentValuePortfolioCurrency, securitiesValue,
            );

            if (sectorInd !== -1) {
                sector.children[sectorInd].percent += child.percent;
                sector.children[sectorInd].value += child.value;
            } else {
                sector.children.push(child);
            }
        }

        return obj;
    }, {
        asset: {},
        type: {},
        currency: {},
        country: {},
        sector: {},
    });
};

export const adaptAllocationGroups = ({
    positions, currency, t, securitiesValue,
    chartTypesList = [PIE_SA, BAR, LIST],
    comparedAllocations,
    getFormattedCurrency,
}) => {
    const groups = buildAllocationGroup(positions, currency, t, securitiesValue);

    const gruopArrays = Object.keys(groups).map((groupKey) => {
        const group = groups[groupKey];

        const formatedCurrency = (value, curr) => {
            if (getFormattedCurrency && value) {
                return getFormattedCurrency(value, {
                    curr,
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                });
            } if (value) {
                return formatCurrencyWithPrecision(value, 0, curr);
            }


            return NA;
        };

        return sortAllocations(
            groupKey,
            Object.keys(group).map((key) => ({
                id: group[key].id,
                name: key,
                color: group[key].color,
                value: group[key].amount,
                percent: group[key].percentage,
                children: group[key].children
                    ? group[key].children.map((item) => ({
                        ...item,
                        percent: allocationPercentFormat(item.percent),
                        value: formatedCurrency(item?.value, currency),
                    }))
                    : undefined,
                label: formatPercentage(group[key].percentage),
            })),
            Object.keys(comparedAllocations?.[groupKey] || {}),
        );
    });

    return [
        {
            name: t('allocationTabs.assetClasses'),
            data: adaptChartTypes(gruopArrays[0], currency, chartTypesList, getFormattedCurrency),
        },
        {
            name: t('allocationTabs.type'),
            data: adaptChartTypes(gruopArrays[1], currency, chartTypesList, getFormattedCurrency),
        },
        {
            name: t('allocationTabs.currencies'),
            data: adaptChartTypes(gruopArrays[2], currency, chartTypesList, getFormattedCurrency),
        },
        {
            name: t('allocationTabs.regions'),
            data: adaptChartTypes(gruopArrays[3], currency, chartTypesList, getFormattedCurrency),
        },
        {
            name: t('allocationTabs.sectors'),
            data: adaptChartTypes(gruopArrays[4], currency, chartTypesList, getFormattedCurrency),
        },
    ];
};
