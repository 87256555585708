import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    TextInput, Paragraph, Title, NumberInput, Select,
    DatePicker, ProjectionChart, Icon, Column, Infobox,
} from 'ui-library';
import isNumber from 'lodash/isNumber';
import { Controller } from 'react-hook-form';
import {
    formatDate, setYearToDate, yearFormat, compareDates, addYearsToDate,
} from 'utils/datetime';
import Preloader from 'components/Preloader';
import { useFormatting, useLocale } from 'locale';
import { datePickerLocale } from 'constants/constants';
import {
    goalCreationSelector, onBoardingDataSelector, useOnBoardingSelector,
} from 'domain/OnBoarding';
import { useModelPortfolio } from 'domain/Portfolio';
import { adaptCurrencies } from 'adaptors/adaptCurrencies';
import { adaptRecurrences } from './adaptors/adaptGoalData';
import { useGoalForm } from './hooks/useGoalForm';
import { useGoalPerformance } from '../../hooks/useGoalPerformance';
import { adaptProducts } from '../../../ProductSelection/adaptors/adaptProducts';
import './GoalForm.css';

const GoalForm = (props) => {
    const {
        active,
        dataCurrencies,
        productId,
        isSubmitted,
        placeholders: prefilledData,
        defaultData,
        onChange: onChangeProp,
        onSubmit,
    } = props;

    const { t } = useTranslation();
    const { getFormattedNumber } = useFormatting();
    const { numberSeparators, locale } = useLocale();
    const [isPositiveGoal, setIsPositiveGoal] = useState(false);
    const [showPositiveGoalError, setShowPositiveGoalError] = useState(false);

    // OnBoarding Domain
    const { productOffer } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        data: modelPortfolioObj,
        dataRecurrences, isLoadingRecurrences, errorRecurrences, getRecurrences,
    } = useOnBoardingSelector(goalCreationSelector);

    // Portfolio Domain
    const {
        dataRaw, isLoading: isLoadingModel, error: errorModel, getModelPortfolio,
    } = useModelPortfolio();

    const minimumInvestmentValue = useMemo(() => {
        const products = adaptProducts(dataCurrencies, { t, getFormattedNumber, productOffer });

        return (products || []).find(
            (i) => i.id === productId,
        )?.minimumInvestmentValue;
    },
    [dataCurrencies, getFormattedNumber, productOffer]);

    // Hooks
    const {
        control, values: goalState, formErrors, onChange, handleSubmit,
    } = useGoalForm({ defaultData, minimumInvestmentValue });

    // Data
    const {
        goalName,
        targetValue,
        targetDate,
        initialInvestment,
        recurringPaymentFrequency,
        recurringPayment,
        selectedCurrency,
    } = goalState;
    const listCurrency = useMemo(() => {
        const products = adaptProducts(dataCurrencies, { t, getFormattedNumber, productOffer });
        const currencies = (products || []).find((i) => i.id === productId)?.currencies || [];

        return adaptCurrencies(currencies);
    }, [dataCurrencies, t, getFormattedNumber, productOffer, productId]);
    const defaultCurrency = useMemo(() => (listCurrency.length === 1 ? listCurrency[0] : null),
        [listCurrency]);
    const listRecurrenceTypes = useMemo(() => adaptRecurrences(dataRecurrences, t),
        [dataRecurrences, t]);
    const defaultRecurrenceTypes = useMemo(() => listRecurrenceTypes.find(({ value }) => value === 'Monthly'),
        [listRecurrenceTypes]);
    const selectAfter = useMemo(() => {
        if (listCurrency.length > 0) {
            return (
                <Controller
                    name="selectedCurrency"
                    control={control}
                    defaultValue={selectedCurrency?.value}
                    render={({ value }) => (
                        <Select
                            options={listCurrency}
                            value={value}
                            onChange={(val) => onChange('selectedCurrency')(
                                listCurrency.find((item) => item.value === val),
                            )}
                        />
                    )}
                />

            );
        }

        return null;
    }, [listCurrency, control, selectedCurrency, formErrors.selectedCurrency, onChange]);
    const listRecurrenceTypesOptions = useMemo(() => listRecurrenceTypes.filter(({ value }) => value === 'Monthly'), // TODO: Remove filter AT-937
        [listRecurrenceTypes]);
    const optimizeDisabled = useMemo(() => (goalName && targetValue && targetDate
        && isNumber(+initialInvestment) && isNumber(+recurringPayment)),
    [goalName, targetValue, targetDate, initialInvestment, recurringPayment]);
    const formHasErrors = useMemo(() => Object.keys(formErrors || {}).length > 0, [formErrors]);

    // Helpers hooks
    const {
        isLoadingProjection, projectionResponse,
        dataExternal, chartData, defaultMinMax, projectionOptional,
    } = useGoalPerformance({ goalState, dataPortfolio: dataRaw });

    // Effects
    useEffect(() => {
        if (selectedCurrency?.value) {
            getModelPortfolio(modelPortfolioObj?.Id, { currencyId: selectedCurrency?.value });
        }
    }, [getModelPortfolio, modelPortfolioObj, selectedCurrency?.value]);
    useEffect(() => {
        getRecurrences();
    }, [getRecurrences]);
    useEffect(() => {
        if (projectionResponse) {
            const values = projectionResponse[projectionResponse.length - 1].Values;
            const value = values[values.length - 1].Value;

            if (targetValue && 1.01 * value > +targetValue) {
                setIsPositiveGoal(true);
                setShowPositiveGoalError(false);
            } else {
                setIsPositiveGoal(false);
                setShowPositiveGoalError(true);
            }
        }
    }, [targetValue, projectionResponse]);
    useEffect(() => {
        if (!selectedCurrency && defaultCurrency) onChange('selectedCurrency')(defaultCurrency);
    }, [defaultCurrency, onChange, selectedCurrency]);
    useEffect(() => {
        if (!recurringPaymentFrequency?.value) {
            onChange('recurringPaymentFrequency')({
                value: defaultRecurrenceTypes?.value,
                label: defaultRecurrenceTypes?.label,
                id: defaultRecurrenceTypes?.id,
            });
        }
    }, [recurringPaymentFrequency, defaultRecurrenceTypes, onChange]);

    useEffect(() => {
        if (active && isSubmitted) {
            handleSubmit((data) => onSubmit(data, isPositiveGoal))();
        }
    }, [active, isSubmitted, handleSubmit, onSubmit, isPositiveGoal]);
    useEffect(() => {
        if (active) onChangeProp({ goalState, optimizeDisabled, isPositiveGoal });
    }, [active, onChangeProp, goalState, optimizeDisabled, isPositiveGoal]);

    // Callbacks
    const onChangeRecurringPaymentFrequency = useCallback((v) => {
        const i = listRecurrenceTypes.find(({ value }) => value === v);

        onChange('recurringPaymentFrequency')({ label: i.label, value: i.value, id: i.id });
    }, [listRecurrenceTypes, onChange]);

    const showGoalError = useMemo(
        () => (showPositiveGoalError && (!formHasErrors || !isLoadingProjection)),
        [showPositiveGoalError, formHasErrors, isLoadingProjection],
    );

    if (!active) return null;

    return (
        <Preloader
            isLoading={active && isLoadingRecurrences}
            error={errorModel || errorRecurrences}
        >
            <div className="goal-form">
                <div className="goal-details">
                    <div className="group-title">
                        <Title type={3}>{t('onBoarding.goal.detailsTitle')}</Title>
                        <Paragraph type="secondary">{t('onBoarding.goal.detailsText')}</Paragraph>
                    </div>
                    <Controller
                        name="goalName"
                        control={control}
                        render={({ value }) => (
                            <TextInput
                                name="goalName"
                                label={t('onBoarding.goal.goalName')}
                                labelInfobox
                                onChange={(e) => onChange('goalName')(e.currentTarget.value)}
                                helpText={t('onBoarding.goal.goalNameText')}
                                error={formErrors.goalName}
                                value={value}
                                placeholder={`${prefilledData ? `${t('onBoarding.goal.my')} ${prefilledData?.name} ${t('onBoarding.goal')}` : ''} `}
                            />
                        )}
                    />
                    <div className="target-value">
                        <Controller
                            name="targetValue"
                            control={control}
                            render={({ value }) => (
                                <NumberInput
                                    name="targetValue"
                                    label={t('onBoarding.goal.targetValue')}
                                    addonAfter={selectAfter}
                                    onChange={onChange('targetValue')}
                                    value={value}
                                    withSelectBox
                                    labelInfobox
                                    helpText={t('onBoarding.goal.targetValueText')}
                                    error={formErrors?.targetValue || formErrors?.selectedCurrency}
                                    placeholder={getFormattedNumber(
                                        prefilledData?.targetValue || '', {
                                            maximumFractionDigits: 0,
                                            minimumFractionDigits: 0,
                                        },
                                    )}
                                    {...numberSeparators}
                                />
                            )}
                        />
                    </div>
                    <Controller
                        name="targetDate"
                        control={control}
                        defaultValue={targetDate}
                        render={({ value }) => (
                            <DatePicker
                                name="targetDate"
                                label={t('onBoarding.goal.targetYear')}
                                defaultValue={targetDate}
                                value={value}
                                picker="year"
                                format={yearFormat}
                                onChange={(date) => onChange('targetDate')(
                                    setYearToDate(new Date(), formatDate(date, yearFormat)),
                                )}
                                disabledDate={(current) => current
                                    && compareDates(new Date(), current)}
                                labels={datePickerLocale(t, locale)}
                                labelInfobox
                                placeholder={prefilledData?.targetYears
                                    ? addYearsToDate(
                                        new Date(), prefilledData?.targetYears, yearFormat,
                                    )
                                    : ''}
                                helpText={t('onBoarding.goal.targetDateText')}
                                error={formErrors.targetDate}
                            />
                        )}
                    />
                </div>
                <div className="investment-details">
                    <div className="group-title">
                        <Title type={3}>{t('onBoarding.goal.investmentDetails')}</Title>
                        <Paragraph
                            type="secondary"
                        >
                            {t('onBoarding.goal.investmentDetailsParagraph')}
                        </Paragraph>
                    </div>
                    <Controller
                        name="initialInvestment"
                        control={control}
                        render={({ value }) => (
                            <NumberInput
                                name="initialInvestment"
                                label={t('onBoarding.goal.initialInvestment')}
                                addonAfter={selectedCurrency?.label}
                                value={value}
                                onChange={onChange('initialInvestment')}
                                labelInfobox
                                helpText={t('onBoarding.goal.initialInvestmentText')}
                                error={formErrors.initialInvestment}
                                validation={{
                                    minValue: 0,
                                }}
                                placeholder={getFormattedNumber(
                                    prefilledData?.initialInvestment || '', {
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                    },
                                )}
                                {...numberSeparators}
                            />
                        )}
                    />
                    <Controller
                        name="recurringPaymentFrequency"
                        control={control}
                        render={({ value }) => (
                            <Select
                                name="recurringPaymentFrequency"
                                label={t('onBoarding.goal.recurringPaymentFrequency')}
                                options={listRecurrenceTypesOptions}
                                value={
                                    listRecurrenceTypesOptions.length === 1
                                        ? listRecurrenceTypesOptions[0].value
                                        : value
                                }
                                onChange={onChangeRecurringPaymentFrequency}
                                withInfobox
                                helpText={t('onBoarding.goal.recurringPaymentFrequencyText')}
                                error={formErrors.recurringPaymentFrequency}
                            />
                        )}
                    />
                    <div className="recurring-payment">
                        <Controller
                            name="recurringPayment"
                            control={control}
                            render={({ value }) => (
                                <NumberInput
                                    name="recurringPayment"
                                    label={t('onBoarding.goal.recurringPayment')}
                                    addonAfter={selectedCurrency?.label}
                                    value={value}
                                    onChange={onChange('recurringPayment')}
                                    labelInfobox
                                    helpText={t('onBoarding.goal.recurringPaymentText')}
                                    error={formErrors.recurringPayment}
                                    placeholder={getFormattedNumber(
                                        prefilledData?.recurringPaymentAmount || '', {
                                            maximumFractionDigits: 0,
                                            minimumFractionDigits: 0,
                                        },
                                    )}
                                    {...numberSeparators}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="result">
                    <div className="group-title">
                        <Title type={3}>{t('onBoarding.goal.result')}</Title>
                        {isPositiveGoal ? (
                            <div className="positive">
                                <div className="icon">
                                    <Icon type="check-filled" size={16} />
                                </div>
                                <Paragraph type="secondary">{t('onBoarding.goal.resultTextFirst')}</Paragraph>
                            </div>
                        )
                            : (
                                <div className="negative">
                                    <div className="icon">
                                        <div className="negative-icon" />
                                    </div>
                                    <Paragraph type="secondary">{t('onBoarding.goal.resultTextSecond')}</Paragraph>
                                </div>
                            )
                        }
                    </div>
                    <div className="chart">
                        <Column size={['xl-9', 'md-12']}>
                            <Preloader isLoading={isLoadingProjection || isLoadingModel}>
                                <ProjectionChart
                                    data={chartData || []}
                                    showLegend
                                    dataExternal={dataExternal || []}
                                    colorScheme="positiveAndNegative"
                                    legendLabels={[t('onBoarding.goal.expectedCase'), t('onBoarding.goal.goodScenario'), t('onBoarding.goal.badScenario')]}
                                    {...defaultMinMax}
                                    {...projectionOptional}
                                    yearsText={t('charts.years')}
                                />
                            </Preloader>
                        </Column>
                    </div>
                </div>
            </div>
            {(showGoalError) && <Infobox error>{t('onBoarding.goal.positiveGoal')}</Infobox>}
        </Preloader>
    );
};

GoalForm.propTypes = {
    active: PropTypes.bool,
    dataCurrencies: PropTypes.shape({
        ProductOffers: PropTypes.arrayOf(PropTypes.shape({
            Id: PropTypes.number,
            Products: PropTypes.arrayOf(PropTypes.shape({
                Id: PropTypes.number,
                Name: PropTypes.string,
                Image: PropTypes.string,
                Description: PropTypes.string,
            })),
            Currencies: PropTypes.arrayOf(PropTypes.shape({
                Id: PropTypes.number,
                CurrencyCode: PropTypes.string,
            })),
        })),
    }),
    productId: PropTypes.number,
    isSubmitted: PropTypes.bool,
    placeholders: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string, PropTypes.number,
        ]),
    }),
    defaultData: PropTypes.shape({
        goalName: PropTypes.string,
        targetValue: PropTypes.string,
        targetDate: PropTypes.string,
        initialInvestment: PropTypes.string,
        recurringPayment: PropTypes.string,
        selectedCurrency: PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
        }),
        recurringPaymentFrequency: PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    }),
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
};

GoalForm.defaultProps = {
    active: true,
    dataCurrencies: null,
    productId: null,
    isSubmitted: false,
    placeholders: null,
    defaultData: {},
    onChange: () => {},
    onSubmit: () => {},
};

export default GoalForm;
