import React, { useCallback, useEffect, useMemo } from 'react';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import Preloader from 'components/Preloader';
import {
    Column, ContentBox, NumberInput, Paragraph, Row, Select, Title, Button, Modal, Infobox,
} from 'ui-library';
import { Controller } from 'react-hook-form';
import { useClientProfile } from 'prodivers/clientProfile';
import LoadingOverlap from 'components/LoadingOverlap';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import { useTranslation } from 'react-i18next';
import { useFormatting, useLocale } from 'locale';
import './CreateNewPortfolio.css';
import PropTypes from 'prop-types';
import history from 'services/history';
import { validateStringData } from 'utils/formatting';
import { getClientId, checkNullData } from 'utils';
import RiskDisclaimer from 'components/RiskDisclaimer/RiskDisclaimer';
import { useNewPortfolio } from './hooks/useNewPortfolio';
import { useNewPortfolioData } from './hooks/useNewPortfolioData';
import { useNewPortfolioLayout } from './hooks/useNewPortfolioLayout';

function CreateNewPortfolio(props) {
    useCancelAllRequests();
    useNewPortfolioLayout({ current: 0 });

    const { match: { params: { dfsClientId } } } = props;
    const clientId = getClientId(dfsClientId);
    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/new-portfolio`, [dfsClientId]);

    const { t } = useTranslation();
    const { numberSeparators } = useLocale();
    const { getFormattedPhoneNumber, getFormattedNumber } = useFormatting();

    // Client Provider
    const { ...information } = useClientProfile();

    // New Portfolio - Local data
    const { clearNewPortfolio, clearModifiedPositions } = useNewPortfolioData();

    // New Portfolio hook
    const {
        productOptions,
        strategyDisabled,
        strategyOptionsByProduct,
        isLoadingProducts,
        errorProducts,
        listCurrencies,
        showRiskDisclaimer,
        clientRiskProfile,
        isLoadingStrategy,
        state,
        strategyList,
        isLoadingModelList,
        errorModelList,
        control,
        values,
        formErrors,
        onChange,
        handleSubmit,
        minimumAmount,
    } = useNewPortfolio({ clientId, loadInitially: true });

    // Data
    const defaultCurrency = useMemo(() => listCurrencies?.find((item) => item?.label === 'USD'), [listCurrencies]);
    const amountSelectAfter = useMemo(() => (
        <Controller
            name="selectedCurrency"
            control={control}
            render={({ value }) => (
                <Select
                    options={listCurrencies}
                    value={value}
                    onChange={onChange('selectedCurrency')}
                    withInfobox
                />
            )}
        />
    ), [listCurrencies, onChange]);
    const renderRiskDisclaimer = useCallback(() => (
        <RiskDisclaimer
            PRP={values?.strategy?.label}
            CRP={clientRiskProfile?.ChosenRiskCategory?.Name}
        />
    ), [values?.strategy, clientRiskProfile?.ChosenRiskCategory]);

    // Effects
    useEffect(() => {
        if (!values?.selectedCurrency?.value
            && defaultCurrency?.value) onChange('selectedCurrency')(defaultCurrency?.value);
    }, [defaultCurrency, onChange, values?.selectedCurrency?.value]);

    // Handlers/Callbacks
    const onContinue = async () => {
        if (!showRiskDisclaimer) {
            history.push(`${baseUrl}/modify`);

            return;
        }

        Modal.confirm({
            title: t('portfolio.disclaimer'),
            content: t('portfolio.disclaimerText'),
            okText: t('portfolio.yes'),
            onOk: async () => {
                history.push(`${baseUrl}/modify`);
            },
            cancelText: t('portfolio.no'),
        });
    };
    const onBack = () => {
        history.push(`/client/${dfsClientId}/portfolios`);
        clearNewPortfolio();
        clearModifiedPositions();
    };

    const selectedProductLabel = (productOptions || []).find(
        (i) => i.value === values?.product?.value,
    )?.label;

    const portfolioAmountHelpText = useMemo(() => (minimumAmount
        ? `${t('portfolio.minimumInvestment.helpText.part1')} ${selectedProductLabel} ${t('portfolio.minimumInvestment.helpText.part2')} ${getFormattedNumber(minimumAmount, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        })}` : undefined), [values?.product?.value, getFormattedNumber, minimumAmount, selectedProductLabel]);

    const modelPortfolioDisabled = useMemo(() => (
        checkNullData(values?.strategy)
        || strategyDisabled[values?.product?.value]
        || isLoadingModelList
    ), [values, isLoadingModelList]);

    return (
        <div className="new-portfolio">
            <Preloader
                isLoading={information?.isLoading
                    || isLoadingProducts}
                error={errorProducts}
            >
                <ContentBox>
                    <header className="new-portfolio-header">
                        <Title type={2}>{t('portfolio.createNewPortfolio')}</Title>
                    </header>
                    <div className="new-portfolio-content">
                        <Row className="information">
                            <Column size="12">
                                <Paragraph type="secondary">
                                    {t('portfolio.createNewPortfolio.infoText')}
                                </Paragraph>
                            </Column>
                            <Column size="12">
                                <Title type={3}>{information?.data?.Name}</Title>
                                <Paragraph type="secondary">
                                    {getFormattedPhoneNumber(
                                        validateStringData(information?.data?.PhoneNumber),
                                    )}
                                </Paragraph>
                            </Column>
                        </Row>
                        {clientRiskProfile?.ChosenRiskCategory && (
                            <Row className="profile">
                                <Column size="6">
                                    <Title type={3}>{t('portfolio.clientRiskProfile')}</Title>
                                    <Paragraph type="secondary">
                                        {clientRiskProfile?.ChosenRiskCategory?.Name}
                                    </Paragraph>
                                    <Button
                                        type="secondary"
                                        size="small"
                                        className="redo-risk-profile"
                                        onClick={() => history.push(`/client/${dfsClientId}/risk-profile`)}
                                    >
                                        {t('portfolio.redoRiskProfile')}
                                    </Button>
                                </Column>
                            </Row>
                        )}
                        <Row className="create-form">
                            <Column size="6">
                                <Controller
                                    name="portfolioAmount"
                                    control={control}
                                    render={({ value }) => (
                                        <NumberInput
                                            name="portfolioAmount"
                                            label={t('portfolio.portfolioAmount')}
                                            value={value}
                                            onChange={onChange('portfolioAmount')}
                                            addonAfter={amountSelectAfter}
                                            error={formErrors.portfolioAmount}
                                            validation={{
                                                minValue: 0,
                                            }}
                                            labelInfobox
                                            helpText={portfolioAmountHelpText}
                                            withSelectBox
                                            {...numberSeparators}
                                        />
                                    )}
                                />
                            </Column>
                            <Column size="6">
                                <Controller
                                    name="product"
                                    control={control}
                                    render={({ value }) => (
                                        <Select
                                            name="product"
                                            label={t('portfolio.product')}
                                            options={productOptions}
                                            value={value}
                                            onChange={(opt) => {
                                                if (opt !== values?.product?.value) {
                                                    onChange('product')(opt);
                                                }
                                            }}
                                            withInfobox
                                            error={formErrors.product}
                                            helpText={t('portfolio.product.helpText')}
                                        />
                                    )}
                                />
                            </Column>
                        </Row>
                        <Row className="amount">
                            <Column size="6">
                                <LoadingOverlap isLoading={isLoadingStrategy}>
                                    <Controller
                                        name="strategy"
                                        control={control}
                                        render={({ value }) => (
                                            <Select
                                                name="strategy"
                                                label={t('portfolio.strategy')}
                                                options={
                                                    strategyOptionsByProduct[values?.product?.value]
                                                    ?? []}
                                                value={value}
                                                onChange={(opt) => {
                                                    if (opt !== values?.strategy?.value) {
                                                        onChange('strategy')(opt);
                                                    }
                                                }}
                                                withInfobox
                                                error={formErrors.strategy}
                                                helpText={t('portfolio.strategy.helpText')}
                                                disabled={checkNullData(values?.product)
                                                    || strategyDisabled[values?.product?.value]
                                                    || isLoadingStrategy}
                                            />
                                        )}
                                    />
                                </LoadingOverlap>
                            </Column>
                            <Column size="6">
                                <LoadingOverlap isLoading={isLoadingModelList}>
                                    <Controller
                                        name="model"
                                        control={control}
                                        render={() => (

                                            <Select
                                                name="model"
                                                label={t('clientDashboard.portfolio.modelPortfolio')}
                                                options={strategyList || []}
                                                value={values?.model}
                                                onChange={onChange('model')}
                                                withInfobox
                                                error={!modelPortfolioDisabled && formErrors.model}
                                                helpText={t('portfolio.modelPortfolio.helpText')}
                                                disabled={modelPortfolioDisabled}
                                            />
                                        )}
                                    />
                                </LoadingOverlap>
                            </Column>
                        </Row>
                        {showRiskDisclaimer && renderRiskDisclaimer()}
                    </div>
                    <ButtonsBlockRow
                        leftButton={{
                            text: t('portfolio.back'),
                            onClick: onBack,
                        }}
                        primaryButton={{
                            text: t('portfolio.continue'),
                            onClick: handleSubmit(onContinue),
                        }}
                    >
                        {errorModelList && <Infobox error>{errorModelList?.message}</Infobox>}
                    </ButtonsBlockRow>
                </ContentBox>
            </Preloader>
        </div>
    );
}

CreateNewPortfolio.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

CreateNewPortfolio.defaultProps = {
};

export default CreateNewPortfolio;
