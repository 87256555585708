import {
    GET_LIST_STARTED,
    GET_LIST_SUCCEEDED,
    GET_LIST_FAILED,
    FILTER_STARTED,
    FILTER_SUCCEEDED,
    FILTER_FAILED,
    SELECT_ITEM,
    GET_PREVIEW_STARTED,
    GET_PREVIEW_SUCCEEDED,
    GET_PREVIEW_FAILED,
    DELETE_PREVIEW_STARTED,
    DELETE_PREVIEW_SUCCEEDED,
    PREVIEW_CLEAR,
    EDIT_PREVIEW_SUCCEEDED,
    EDIT_PREVIEW_STARTED,
    EDIT_PREVIEW_FAILED,
    SET_CURRENT_PAGE_SUCCEEDED,
} from './actions';
import { PAGE_SIZE } from './constants';

export const initialState = {
    data: [],
    list: [],
    filters: [],
    preview: undefined,
    selected: undefined,
    errorList: null,
    errorPreview: null,
    isListLoading: false,
    isPreviewLoading: false,
    pageSize: PAGE_SIZE,
    currentPage: 1,
};

export function reducer(state = initialState, action) {
    let list = [];

    switch (action.type) {
        case GET_LIST_STARTED:
            return {
                ...state,
                list: [],
                data: [],
                preview: undefined,
                selected: undefined,
                errorList: null,
                isListLoading: true,
            };
        case GET_LIST_SUCCEEDED:
            return {
                ...state,
                data: action.payload?.data || action.payload,
                total: action.payload?.total || action.payload?.length,
                sorting: action.payload?.sorting || {},
                pageSize: action.payload?.pageSize || state.pageSize,
                currentPage: action.payload?.currentPage || state.currentPage,
                list: action.payload?.data || action.payload,
                isListLoading: false,
            };
        case GET_LIST_FAILED:
            return {
                ...state,
                data: [],
                list: [],
                errorList: action.payload,
                isListLoading: false,
            };
        case FILTER_STARTED:
            return {
                ...state,
                isListLoading: true,
                preview: undefined,
                selected: undefined,
            };
        case FILTER_SUCCEEDED:
            return {
                ...state,
                list: action.payload?.data || action.payload.data,
                total: action.payload?.total || action.payload?.length,
                filters: action.payload?.filters || action.payload?.length,
                isListLoading: false,
            };
        case FILTER_FAILED:
            return {
                ...state,
                data: [],
                list: [],
                errorList: action.payload,
                isListLoading: false,
            };
        case SELECT_ITEM:
            return {
                ...state,
                list: state.list.map((item) => ({
                    ...item,
                    isSelected: item.id === action.payload,
                })),
                preview: undefined,
                selected: action.payload,
            };
        case PREVIEW_CLEAR:
            return {
                ...state,
                preview: undefined,
                selected: undefined,
                errorPreview: null,
                isPreviewLoading: false,
            };
        case GET_PREVIEW_STARTED:
            return {
                ...state,
                preview: undefined,
                errorPreview: null,
                isPreviewLoading: true,
            };
        case GET_PREVIEW_SUCCEEDED:
            return {
                ...state,
                list: state.list.map((item) => (item?.id === action.payload?.id
                    ? { ...item, ...action.payload, isSelected: true }
                    : { ...item, isSelected: false })),
                preview: action.payload,
                isPreviewLoading: false,
            };
        case GET_PREVIEW_FAILED:
            return {
                ...state,
                preview: undefined,
                errorPreview: action.payload,
                isPreviewLoading: false,
            };
        case DELETE_PREVIEW_STARTED:
            return {
                ...state,
                selected: action.payload,
            };
        case DELETE_PREVIEW_SUCCEEDED:
            list = state.list.reduce((current, item) => {
                if (item.id !== action.payload) {
                    current.push(item);
                }

                return current;
            }, []);

            return {
                ...state,
                list,
                total: list.length,
                preview:
                    action.payload === state.selected ? undefined : state.preview,
                selected: action.payload === state.selected
                    ? undefined : state.selected,
                errorList: null,
                errorPreview: null,
                isPreviewLoading: false,
            };
        case EDIT_PREVIEW_STARTED:
            return {
                ...state,
                errorPreview: null,
                isPreviewLoading: true,
            };
        case EDIT_PREVIEW_SUCCEEDED:
            return {
                ...state,
                data: state.data.map((item) => (item?.id === action.payload?.id
                    ? { ...action.payload, isSelected: true }
                    : { ...item, isSelected: false })),
                list: state.list.map((item) => (item?.id === action.payload?.id
                    ? { ...action.payload, isSelected: true }
                    : { ...item, isSelected: false })),
                preview: action.payload,
                isPreviewLoading: false,
            };
        case EDIT_PREVIEW_FAILED:
            return {
                ...state,
                preview: undefined,
                errorPreview: action.payload,
                isPreviewLoading: false,
            };
        case SET_CURRENT_PAGE_SUCCEEDED:
            return {
                ...state,
                currentPage: action.payload,
            };
        default:
            return state;
    }
}
