import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useProps, useStore } from '../store-provider';
import Preview from '../components/Preview';
import {
    deletePreview, updatePreview, getPreviewData, getListData,
} from '../actions';

const PreviewContainer = ({
    deleteItem,
    updateItem,
    getPreview,
    ...props
}) => {
    const dispatch = useDispatch();
    const store = useStore();
    const { getData, getDataCallback } = useProps();
    const deleteCallback = useMemo(() => getListData(dispatch, store, getData, getDataCallback),
        [getListData, dispatch, store, getData, getDataCallback]);

    return (
        <Preview
            {...props}
            item={store.preview}
            error={store.errorPreview}
            isLoading={store.isPreviewLoading}
            deletePreview={deletePreview(dispatch, store, deleteItem, deleteCallback)}
            updatePreview={updatePreview(dispatch, updateItem)}
            getPreview={getPreviewData(dispatch, getPreview)}
            deleteCallback={deleteCallback}
        />
    );
};

PreviewContainer.propTypes = {
    deleteItem: PropTypes.func,
    updateItem: PropTypes.func,
    getPreview: PropTypes.func,
};

PreviewContainer.defaultProps = {
    deleteItem: () => {},
    updateItem: () => {},
    getPreview: () => {},
};

export default PreviewContainer;
