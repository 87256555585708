export const adaptInteractions = (data, { types, topics }) => {
    const typesAdapted = types.reduce((acc, item) => ({
        ...acc,
        [item.Id]: item.Name,
    }), {});
    const topicsAdapted = topics.reduce((acc, item) => ({
        ...acc,
        [item.Id]: item.Name,
    }), {});

    return data
        .sort((a, b) => new Date(b.DateFrom) - new Date(a.DateFrom))
        .map((item) => ({
            key: item.Id,
            type: {
                name: 'existing_interaction',
                value: typesAdapted[item.InteractionTypeId] || '',
            },
            topic: topicsAdapted[item.InteractionTopicId] || '',
            from: item.DateFrom,
        }));
};
