import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PageVariant } from 'ui-library';
import PageVariantWrapper from 'components/PageVariantWrapper';
import Preloader from 'components/Preloader';
import { getParamsFromUrl } from 'utils';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useSuccess } from '../hooks/useSuccess';

function Success(props) {
    const { onNext, onPageChange, contactId } = props;
    const fidentityUserId = getParamsFromUrl('fidentityUserId');
    const documentName = getParamsFromUrl('documentName');
    const documentId = getParamsFromUrl('documentId');
    const { clearFIdentityInProgress } = useOnBoardingSelector(onBoardingDataSelector);

    useEffect(() => {
        clearFIdentityInProgress();
    }, [clearFIdentityInProgress]);

    const { t } = useTranslation();

    const { onSaveDocument, isLoading, error } = useSuccess(
        {
            fidentityUserId, documentName, contactId, documentId,
        },
    );

    useEffect(() => {
        if (!fidentityUserId || !documentName || !documentId) {
            onPageChange('');
        } else {
            onSaveDocument();
        }
    }, [onSaveDocument, fidentityUserId, documentName, documentId]);

    return (
        <Preloader isLoading={isLoading} error={error}>
            <PageVariantWrapper className="contract-result">
                <PageVariant
                    type="success"
                    title={t('confirm.title')}
                    message={t('onBoarding.contract.success.message')}
                    actionButtonLabel={t('confirm.button')}
                    onActionButtonClick={() => {
                        onNext();
                    }}
                />
            </PageVariantWrapper>
        </Preloader>
    );
}

Success.propTypes = {
    onNext: PropTypes.func.isRequired,
    contactId: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

Success.defaultProps = {};

export default Success;
