import { getVariables } from 'utils/configureTheme';

export const customizationObject = {
    padding: '0',
    fontColor: getVariables()?.['--primary-default'],
    dataLabelFontColor: getVariables()?.['--primary-default'],
    fontFamily: 'Helvetica, Arial, sans-serif',
    gFontFamily: 'Roboto',
    baseFontSize: '16px',
    tables: {
        headerFontColor: getVariables()?.['--primary-default'],
        bodyFontColor: getVariables()?.['--primary-default'],
        borderColor: getVariables()?.['--secondary-lighter'],
    },
};
export const SECTIONS = 'KEY_SCORES';
export const SCORES_IDS = 'AIR_POLLUTION, BIODIVERSITY_LOSS, CLIMATE_CHANGE, EMPLOYEE_FATALITIES, GENDER_INEQUALITY, JOB_CREATION, TRANSPARENCY, WASTE_MANAGEMENT, WATER_SCARCITY';

export const PORTFOLIO_NAME = 'Hybrid Wealth';
