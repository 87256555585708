import React, { useEffect, useMemo } from 'react';
import {
    Accordion, AccordionPanel as Panel, ContentBox, Modal,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getClientId } from 'utils';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import PageHeader from 'components/PageHeader';
import PageSection from 'components/PageSection';
import history from 'services/history';
import { useFormatting } from 'locale';
import DetailsPerformance from 'components/PositionDetailsPerformace';
import KeyData from 'components/Instrument/KeyData';
import Overview from 'components/Instrument/Overview';
import {
    INTEGER, PERCENTAGE, BondId, StructuredProductId,
} from 'constants/instrument';
import InstrumentDocumentsTable from 'components/InstrumentDocumentsTable';
import { useDocuments, useInstrumentData } from 'domain/Instrument';
import { adaptInstrumentDocs } from 'adaptors/adaptInstrumentDocs';
import { PORTFOLIO_IN_EXECUTION, PORTFOLIO_NOT_IMPLEMENTED_ID } from 'constants/portfolioStatuses';
import { tradingCardSelector, usePortfolioSelector } from 'domain/Portfolio';
import {
    mapActionTypeName, MODIFY, QUICK_EDIT, CHANGE_STRATEGY,
} from '../../constants';
import { adaptPortfolioDetails } from './adapters/adaptPortfolioDetails';
import { usePortfolioDetails } from '../common/hooks/usePortfolioDetails';
import { onPreventRedirect } from '../common/utils';

function PositionDetailsOverview(props) {
    const {
        match: {
            params: {
                positionId, portfolioId, tabId, dfsClientId,
            },
            url,
        },
    } = props;
    const clientId = getClientId(dfsClientId);
    const { t, i18n: { language } } = useTranslation();
    const { getFormattedDate } = useFormatting();

    // Instrument Domain
    const {
        data: dataDocs, isLoading: isLoadingDocs, error: errorDocs, getDocuments,
        errorDocument, getDocumentById,
    } = useDocuments({ instrumentId: positionId });
    const documents = useMemo(() => adaptInstrumentDocs({
        data: dataDocs, getFormattedDate, t, getDocumentById, positionId, language,
    }), [dataDocs, getFormattedDate, t, getDocumentById, positionId, language]);
    const {
        data, isLoading, error, getCommon,
        dataPerformance, isLoadingPerformance, errorPerformance, getPerformance,
        dataBenchmark, isLoadingBenchmark, errorBenchmark,
    } = useInstrumentData({ instrumentId: positionId });

    // Portfolio Data
    const {
        dataPortfolioDetails, isLoadingPortfolioDetails, errorPortfolioDetails,
    } = usePortfolioDetails({
        adaptPortfolioDetails, clientId, portfolioId, positionId, securityTypeId: data?.typeId,
    });

    // Data
    const currencyID = dataPortfolioDetails.currencyId || undefined;
    const prevPage = url.split('/')[5];
    const actionType = mapActionTypeName(prevPage, t);
    const defaultActiveKeyList = tabId === '1' ? ['1'] : ['1', '2', '3'];
    const portfolioLink = useMemo(() => {
        if (dataPortfolioDetails?.portfolioStatusId
            === PORTFOLIO_NOT_IMPLEMENTED_ID) {
            return undefined;
        }
        if (dataPortfolioDetails?.portfolioStatusId
            === PORTFOLIO_IN_EXECUTION) {
            return `/client/${dfsClientId}/portfolios/${portfolioId}/pending-orders`;
        }

        return `/client/${dfsClientId}/portfolios/${portfolioId}`;
    }, [dataPortfolioDetails, dfsClientId, portfolioId]);
    const additionalBenchmark = useMemo(() => ({
        name: dataBenchmark.name,
        tradingPriceOfUnderlying: dataBenchmark.tradingPrice,
        calculatedRiskOfUnderlying: dataBenchmark.risk,
    }), [dataBenchmark]);
    const priceType = useMemo(() => ([BondId, StructuredProductId].includes(data.typeId)
        ? PERCENTAGE : INTEGER), [data.typeId]);
    const portfolioData = useMemo(() => ({
        price: dataPortfolioDetails.additionalPrice,
        units: dataPortfolioDetails.units,
        quantity: dataPortfolioDetails.quantity,
        currency: dataPortfolioDetails.priceCurrency,
        costPrice: dataPortfolioDetails.costPrice,
        costPriceInCurrency: dataPortfolioDetails.additionalCostPrice,
        totalValue: dataPortfolioDetails.totalValueTop,
        totalValueInCurrency: dataPortfolioDetails.additionalTotalValue,
        allocation: dataPortfolioDetails.portfolioAllocation,
        performanceSi: dataPortfolioDetails.performanceSi,
        performanceAbsolute: dataPortfolioDetails.absolutePerformance,
    }), [dataPortfolioDetails]);

    // Effects
    useEffect(() => {
        getCommon();
    }, [getCommon]);
    useEffect(() => {
        if (data.currency?.id) getPerformance({ currencyId: data.currency?.id });
    }, [data.currency, getPerformance]);
    useEffect(() => {
        if (data.uId) getDocuments({ instrumentUId: data.uId });
    }, [data.uId, getDocuments]);

    const { deleteTradingCard } = usePortfolioSelector(tradingCardSelector);

    const onCancel = (u) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                if (prevPage === MODIFY) {
                    sessionStorage.removeItem(MODIFY);
                    deleteTradingCard(clientId, portfolioId);
                }
                if (prevPage === QUICK_EDIT) {
                    sessionStorage.removeItem(QUICK_EDIT);
                }
                if (prevPage === CHANGE_STRATEGY) {
                    sessionStorage.removeItem(CHANGE_STRATEGY);
                }
                history.push(u);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };

    return (
        <PageSection className="position-details-overview">
            <Preloader
                isLoading={isLoadingPortfolioDetails || isLoading
                    || isLoadingBenchmark || isLoadingPerformance}
                error={errorPortfolioDetails || error || errorBenchmark}
            >
                <ContentBox underline={false}>
                    <PageHeader
                        title={data?.name}
                        breadCrumbsCurrent={data?.name}
                        breadCrumbsChildren={[
                            {
                                to: `/client/${dfsClientId}/portfolios/`,
                                label: t('clientDashboard.portfolioEdit.portfolios'),
                                onClick: actionType
                                    ? (e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/`, onCancel) : undefined,
                            },
                            {
                                to: portfolioLink,
                                label: dataPortfolioDetails?.portfolioName,
                                onClick: actionType
                                    ? (e) => onPreventRedirect(e, portfolioLink, onCancel)
                                    : undefined,
                            },
                            {
                                to: actionType ? `/client/${dfsClientId}/portfolios/${portfolioId}/${prevPage}` : undefined,
                                label: actionType,
                            },
                        ]}
                    />
                    <div className="content-wrapper">
                        <Accordion defaultActiveKey={defaultActiveKeyList}>
                            <Panel header={t('position.overview')} key="1" className="overview">
                                <Overview
                                    data={data}
                                    priceType={priceType}
                                    positionId={positionId}
                                    performance={dataPerformance}
                                    portfolioData={portfolioData}
                                />
                            </Panel>
                            <Panel header={t('position.performance')} key="2" className="performance">
                                <DetailsPerformance
                                    positionId={positionId}
                                    currencyId={currencyID}
                                    currency={dataPortfolioDetails.currency}
                                    priceType={priceType}
                                    error={errorPerformance}
                                />
                            </Panel>
                            <Panel header={t('position.keyData')} key="3" className="key-data">
                                <KeyData
                                    positionId={+positionId}
                                    additionalData={{
                                        ...additionalBenchmark,
                                        calculatedRisk: dataPerformance?.Volatility,
                                        calculatedExpectedReturn: dataPerformance?.ExpectedReturn,
                                        sharpeRatio: dataPerformance?.SharpeRatio,
                                    }}
                                />
                            </Panel>
                            <Panel header={t('position.documents')} key="4" className="documents table-without-last-row-padding table-without-head-row-padding">
                                <InstrumentDocumentsTable
                                    data={documents}
                                    error={errorDocs}
                                    isLoading={isLoadingDocs}
                                    errorDocument={errorDocument}
                                />
                            </Panel>
                        </Accordion>
                    </div>
                    <ButtonsBlockRow
                        leftButton={{
                            text: t('position.back'),
                            onClick: () => history.goBack(),
                        }}
                    />
                </ContentBox>
            </Preloader>
        </PageSection>
    );
}

PositionDetailsOverview.propTypes = {
    match: PropTypes.shape({
        params: {
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
            positionId: PropTypes.string.isRequired,
            productId: PropTypes.string.isRequired,
            tabId: PropTypes.string.isRequired,
        },
        url: PropTypes.string,
        path: PropTypes.string,
    }).isRequired,
};

PositionDetailsOverview.defaultProps = {};

export default PositionDetailsOverview;
