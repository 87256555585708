import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    ContentBox, Modal, Title, Paragraph, TabPane, Tabs, Infobox, ProgressBar, Step,
} from 'ui-library';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import PageHeader from 'components/PageHeader';
import { getClientId } from 'utils';
import history from 'services/history';
import { ExistingInteraction, InteractionDetails } from 'templates/Interaction';
import { useTypesAndTopicsInteraction } from 'hooks/useTypesAndTopicsInteraction';
import { useNewInteraction } from 'hooks/useNewInteraction';
import { step4Data } from 'constants/riskProfileMockData';
import { useClientProfile } from 'prodivers/clientProfile';
import { useExistingInteraction } from 'hooks/useExistingInteraction';
import {
    chosenRiskSelector, formDataSelector, questionnaireSelector, useRiskProfileSelector,
} from 'domain/RiskProfile';
import { useWithInvestmentOverview } from '../../../Dashboard/hooks/useWithInvestmentOverview';
import './Interaction.css';

const setProgressBarSteps = (data, t) => [
    ...(data.map((item) => (
        <Step key={item.Name} title={item.Name} />
    ))),
    <Step key={step4Data(t).key} title={step4Data(t).title} />,
];

function InteractionAction(props) {
    const { match: { params: { dfsClientId } } } = props;

    // Client Provider
    const { clientName } = useClientProfile();

    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();
    const [isSending, setSending] = useState(false);
    const [interactionType, setInteractionType] = useState('existing');

    // Hooks
    const {
        isLoading: isLoadingTypesAndTopics, types, topics,
    } = useTypesAndTopicsInteraction(true);
    const {
        data: existInteractionData,
        isLoading: isLoadingExist,
        error: errorExist,
        onExistInteractionChange,
        errorUpdating,
        updateInteraction,
        onExistInteractionCommentChange,
        formErrors: existInteractionFormErrors,
        onExistInteractionHandleSubmit,
        values,
        register: existInteractioRegister,
    } = useExistingInteraction(clientId, isLoadingTypesAndTopics, types, topics);
    const {
        register,
        data: newInteractionData,
        isLoading: isLoadingNew,
        error: errorNew,
        values: newInteractionValues,
        formErrors,
        onInteractionChange,
        onNewInteractionCommentChange,
        onNewInteractionHandleSubmit,
        errorCreating: errorCreatingInteraction,
        createInteraction,
        isSearching,
        onSearch,
        control,
    } = useNewInteraction(clientId, isLoadingTypesAndTopics, types, topics, clientName);

    useEffect(() => {
        if (!isLoadingExist && existInteractionData.length === 0) {
            setInteractionType('new');
        }
    }, [isLoadingExist, existInteractionData]);

    // Risks Profile Domain
    const {
        selectedRisk: chosenRisk, clearSelectedRisk, clearData,
    } = useRiskProfileSelector(formDataSelector);

    // ProductId selection
    const {
        data: dataOverview, isLoading: isLoadingOverview, error: errorOverview,
    } = useWithInvestmentOverview({ clientId });
    const productId = useMemo(() => dataOverview?.productIds?.[0], [dataOverview.productIds]);

    // Risks Profile Domain
    const {
        data: steps, isLoading, error, getQuestionnaire,
    } = useRiskProfileSelector(questionnaireSelector);
    const {
        isLoading: isSetting, error: errorSetChosenRisk, setChosenRisk,
    } = useRiskProfileSelector(chosenRiskSelector);

    useEffect(() => {
        if (productId) getQuestionnaire({ productId });
    }, [getQuestionnaire, productId]);

    // Callbacks
    const onConfirm = () => (params) => {
        const modal = Modal.confirm({
            title: t('clientDashboard.confirm'),
            content: t('clientDashboard.changeStrategy.interactionConfirmContent'),
            okText: t('clientDashboard.yes'),
            cancelText: t('clientDashboard.no'),
            onOk: async () => {
                modal.destroy();

                setSending(true);
                try {
                    if (interactionType === 'existing') await updateInteraction();
                    else await createInteraction(params);
                    await setChosenRisk(chosenRisk);
                    clearSelectedRisk();
                    clearData();
                    setSending(false);
                    history.push(`/client/${dfsClientId}/dashboard`);
                } catch (e) {
                    setSending(false);
                }
            },
            className: 'accept-interaction',
        });
    };
    const onSubmit = (e) => {
        e.preventDefault();
    };
    const onConfirmClick = () => () => {
        if (interactionType === 'existing') onExistInteractionHandleSubmit(onConfirm())();
        else onNewInteractionHandleSubmit(onConfirm())();
    };
    const onBack = () => {
        history.push(`/client/${dfsClientId}/risk-profile`, { step: 3 });
    };

    return (
        <div className="interaction">
            <Preloader
                isLoading={isLoading || isLoadingOverview}
                error={error || errorOverview}
            >
                <ProgressBar current={3}>
                    {setProgressBarSteps(steps.Groups.slice(0, 3), t)}
                </ProgressBar>
                <PageHeader
                    title={t('clientDashboard.interaction')}
                />
                <form onSubmit={onSubmit}>
                    <ContentBox className="interaction-content">
                        <div className="content-wrapper">
                            <Title type={3}>{t('clientDashboard.interaction')}</Title>
                            <Paragraph type="secondary">{t('clientDashboard.interactionDescription')}</Paragraph>
                            <Tabs activeKey={interactionType} onChange={setInteractionType}>
                                <TabPane tab={t('clientDashboard.existingInteraction')} key="existing">
                                    <Preloader isLoading={isLoadingExist} error={errorExist}>
                                        <ExistingInteraction
                                            data={existInteractionData}
                                            comment={values.comment}
                                            selectedRows={[values.interactionId]}
                                            onRowChange={onExistInteractionChange}
                                            onCommentChange={onExistInteractionCommentChange}
                                            register={existInteractioRegister}
                                            errors={existInteractionFormErrors}
                                        />
                                    </Preloader>
                                </TabPane>
                                <TabPane tab={t('clientDashboard.newInteraction')} key="new">
                                    <Preloader isLoading={isLoadingNew} error={errorNew}>
                                        <InteractionDetails
                                            data={newInteractionData}
                                            errors={formErrors}
                                            values={newInteractionValues}
                                            onChange={onInteractionChange}
                                            onCommentChange={onNewInteractionCommentChange}
                                            isSearching={isSearching}
                                            onSearch={onSearch}
                                            register={register}
                                            control={control}
                                            editable
                                            showCommentHelpText
                                        />
                                    </Preloader>
                                </TabPane>
                            </Tabs>
                        </div>
                        {errorUpdating && <Infobox error>{errorUpdating.message}</Infobox>}
                        {errorCreatingInteraction && (
                            <Infobox error>{errorCreatingInteraction.message}</Infobox>
                        )}
                        {errorSetChosenRisk && (
                            <Infobox error>{errorSetChosenRisk.message}</Infobox>
                        )}
                    </ContentBox>
                    <ButtonsBlockRow
                        leftButton={{
                            text: t('confirmation.back'),
                            loading: isSending || isSetting,
                            onClick: onBack,
                        }}
                        primaryButton={
                            {
                                text: t('riskProfile.finish'),
                                loading: isSending || isSetting,
                                onClick: onConfirmClick(),
                            }
                        }
                    >
                        {(interactionType === 'existing' && existInteractionFormErrors.interactionId) && (
                            <Infobox error className="interaction-select-error">
                                {existInteractionFormErrors.interactionId}
                            </Infobox>
                        )}
                    </ButtonsBlockRow>
                </form>
            </Preloader>
        </div>
    );
}

InteractionAction.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

InteractionAction.defaultProps = {
};

export default InteractionAction;
