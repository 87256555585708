import {
    useCallback, useReducer,
} from 'react';
import ServiceManager from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useTranslation } from 'react-i18next';
import ServerError from 'errors/ServerError';
import { gql, useApolloClient } from '@apollo/client';
import { withCSContext } from 'services/ApolloClient';

const SEARCH_CONTACTS_DATA = gql`
    query AllContactsData(
        $take: Int
        $search: String
    ) {
    contacts(
        includeTotalCount: true
        take: $take
        where: { personalInformation: { displayName: { contains: $search } } }
    ) {
        totalCount
        items {
            id
            personalInformation {
                displayName
            }
        }
    }
}`;

const initialState = {
    interactionTopics: [],
    errorInteractionTopics: null,
    isLoadingInteractionTopics: false,
    interactionTypes: [],
    errorInteractionTypes: null,
    isLoadingInteractionTypes: false,
    isLoadingInteractionDetails: false,
    participants: null,
    errorParticipants: null,
    isLoadingParticipants: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setInteractionTopicsData':
            return { ...state, interactionTopics: action.payload };
        case 'setInteractionTopicsError':
            return { ...state, errorInteractionTopics: action.payload };
        case 'setInteractionTopicsIsLoading':
            return { ...state, isLoadingInteractionTopics: action.payload };
        case 'setInteractionTypesData':
            return { ...state, interactionTypes: action.payload };
        case 'setInteractionTypesError':
            return { ...state, errorInteractionTypes: action.payload };
        case 'setInteractionTypesIsLoading':
            return { ...state, isLoadingInteractionTypes: action.payload };
        case 'setParticipantsData':
            return { ...state, participants: action.payload };
        case 'setParticipantsError':
            return { ...state, errorParticipants: action.payload };
        case 'setParticipantsIsLoading':
            return { ...state, isLoadingParticipants: action.payload };
        case 'setMemberClientsData':
            return { ...state, memberClients: action.payload };
        case 'setMemberClientsError':
            return { ...state, errorMemberClients: action.payload };
        case 'setMemberClientsIsLoading':
            return { ...state, isLoadingMemberClients: action.payload };
        default:
            return state;
    }
};


export const useInteractionFields = (interactionFieldsOptions = {}) => {
    const {
        memberId,
    } = (interactionFieldsOptions || {});

    const [state, dispatch] = useReducer(reducer, initialState);
    const { i18n: { language } } = useTranslation();
    const client = useApolloClient();

    // Callbacks
    const getInteractionTopics = useCallback(async () => {
        dispatch({ type: 'setInteractionTopicsError', payload: null });
        dispatch({ type: 'setInteractionTopicsIsLoading', payload: true });

        try {
            const response = await ServiceManager.contactInteraction('getInteractionTopics', [language]);

            dispatch({ type: 'setInteractionTopicsData', payload: response?.data });
            dispatch({ type: 'setInteractionTopicsIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setInteractionTopicsError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setInteractionTopicsIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [language]);

    const getInteractionTypes = useCallback(async () => {
        dispatch({ type: 'setInteractionTypesError', payload: null });
        dispatch({ type: 'setInteractionTypesIsLoading', payload: true });

        try {
            const response = await ServiceManager.contactInteraction('getInteractionTypes', [language]);

            dispatch({ type: 'setInteractionTypesData', payload: response?.data });
            dispatch({ type: 'setInteractionTypesIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setInteractionTypesError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setInteractionTypesIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [language]);

    const getParticipantsData = useCallback(async (options = {}) => {
        const { membersLookupParams, contactsByFilterParams } = (options || {});

        dispatch({ type: 'setParticipantsError', payload: null });
        dispatch({ type: 'setParticipantsIsLoading', payload: true });
        try {
            const [members, contactsRaw] = await Promise.all([
                ServiceManager.memberManagement('membersLookup', [membersLookupParams]),
                client.query({
                    query: SEARCH_CONTACTS_DATA,
                    variables: {
                        search: contactsByFilterParams?.Search,
                        take: contactsByFilterParams?.Limit,
                        skip: contactsByFilterParams?.Offset,
                    },
                    context: withCSContext({ queryParams: { memberId } }),
                }),
            ]);

            const { data: { contacts: { items } } } = contactsRaw;
            const contacts = (items || []).map((item) => ({
                Id: item.id,
                clientName: item.personalInformation.displayName,
            }));

            dispatch({
                type: 'setParticipantsData',
                payload: {
                    contacts: contacts || [],
                    members: members?.data?.Members,
                },
            });
            dispatch({ type: 'setParticipantsIsLoading', payload: false });

            return {
                contacts: contacts || [],
                members: members?.data?.Members,
            };
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setParticipantsError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setParticipantsIsLoading', payload: val }),
                }),
            )(err);

            return null;
        }
    }, [memberId]);

    const getMemberClientsData = useCallback(async (options) => {
        const { memberClientsParams } = (options || {});

        dispatch({ type: 'setMemberClientsError', payload: null });
        dispatch({ type: 'setMemberClientsIsLoading', payload: true });
        try {
            const response = await ServiceManager.contactInteraction('getMemberClients', [memberId, memberClientsParams]);

            dispatch({
                type: 'setMemberClientsData',
                payload: response?.data,
            });
            dispatch({ type: 'setMemberClientsIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setMemberClientsError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setMemberClientsIsLoading', payload: val }),
                }),
            )(err);

            return null;
        }
    }, [memberId]);

    return {
        getInteractionTopics,
        dataInteractionTopics: state.interactionTopics,
        errorInteractionTopics: state.errorInteractionTopics,
        isLoadingInteractionTopics: state.isLoadingInteractionTopics,
        getInteractionTypes,
        dataInteractionTypes: state.interactionTypes,
        errorInteractionTypes: state.errorInteractionTypes,
        isLoadingInteractionTypes: state.isLoadingInteractionTypes,
        getParticipantsData,
        dataParticipants: state.participants,
        errorParticipants: state.errorParticipants,
        isLoadingParticipants: state.isLoadingParticipants,
        getMemberClientsData,
        dataMemberClients: state.memberClients,
        errorMemberClients: state.errorMemberClients,
        isLoadingMemberClients: state.isLoadingMemberClients,
    };
};
