import { useCallback, useReducer } from 'react';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import SM from 'services/ServiceManager';

const CREATION_DELAY = 1000;
const initialState = {
    data: null,
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setCreateContact':
            return { ...state, createContact: action.payload };
        case 'setCreateContactError':
            return { ...state, createContactError: action.payload };
        case 'setCreateContactIsLoading':
            return { ...state, createContactIsLoading: action.payload };
        case 'setContactDetails':
            return { ...state, contactDetails: action.payload };
        case 'setContactDetailsError':
            return { ...state, contactDetailsError: action.payload };
        case 'setContactDetailsIsLoading':
            return { ...state, contactDetailsIsLoading: action.payload };
        case 'setSaveContactDetails':
            return { ...state, saveContactDetails: action.payload };
        case 'setSaveContactDetailsError':
            return { ...state, saveContactDetailsError: action.payload };
        case 'setSaveContactDetailsIsLoading':
            return { ...state, saveContactDetailsIsLoading: action.payload };
        default:
            return state;
    }
};

export const useEditCustomer = (options = {}) => {
    const { contactId: contactIdOption } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    // Callbacks
    const getSchema = useCallback(async ({ data } = {}) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const response = await SM.middleware('postCSSchemaEditCutomer', [data]);

            dispatch({ type: 'setData', payload: response?.data });
            dispatch({ type: 'setIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);
    const createContact = useCallback(({ data } = {}) => new Promise((resolve, reject) => {
        dispatch({ type: 'setCreateContactError', payload: null });
        dispatch({ type: 'setCreateContactIsLoading', payload: true });

        SM.clientService('postContact', [data]).then((response) => {
            setTimeout(() => {
                dispatch({ type: 'setCreateContact', payload: response?.data });
                dispatch({ type: 'setCreateContactIsLoading', payload: false });

                resolve(response?.data);
            }, CREATION_DELAY);
        }).catch((err) => {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setCreateContactError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setCreateContactIsLoading', payload: val }),
                }),
            )(err);

            reject(err.type !== undefined ? err : new ServerError(err));
        });
    }), []);
    const getContactDetails = useCallback(async ({ contactId = contactIdOption, data } = {}) => {
        dispatch({ type: 'setContactDetailsError', payload: null });
        dispatch({ type: 'setContactDetailsIsLoading', payload: true });

        try {
            const response = await SM.middleware('postCSContactDetails', [contactId, data]);

            dispatch({ type: 'setContactDetails', payload: response?.data });
            dispatch({ type: 'setContactDetailsIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setContactDetailsError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setContactDetailsIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);
    const saveContactDetails = useCallback(async ({ contactId = contactIdOption, data } = {}) => {
        dispatch({ type: 'setSaveContactDetailsError', payload: null });
        dispatch({ type: 'setSaveContactDetailsIsLoading', payload: true });

        try {
            const response = await SM.clientService('patchContact', [contactId, data]);

            dispatch({ type: 'setSaveContactDetails', payload: response?.data });
            dispatch({ type: 'setSaveContactDetailsIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setSaveContactDetailsError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setSaveContactDetailsIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getSchema,
        createContactData: state.createContact,
        createContactError: state.createContactError,
        createContactIsLoading: state.createContactIsLoading,
        createContact,
        contactDetails: state.contactDetails,
        contactDetailsError: state.contactDetailsError,
        contactDetailsIsLoading: state.contactDetailsIsLoading,
        getContactDetails,
        saveDetails: state.saveContactDetails,
        saveContactDetailsError: state.saveContactDetailsError,
        saveContactDetailsIsLoading: state.saveContactDetailsIsLoading,
        saveContactDetails,
    };
};
