import React, { useEffect, useMemo } from 'react';
import {
    Accordion, AccordionPanel as Panel, ContentBox, Modal,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getClientId, getClassNames } from 'utils';
import { useDocuments, useInstrumentData } from 'domain/Instrument';
import { adaptInstrumentDocs } from 'adaptors/adaptInstrumentDocs';
import InstrumentDocumentsTable from 'components/InstrumentDocumentsTable';
import Preloader from 'components/Preloader';
import PageHeader from 'components/PageHeader';
import PageSection from 'components/PageSection';
import withInstrumentAccess from 'hocs/withInstrumentAccess';
import { useFormatting } from 'locale';
import KeyData from 'components/Instrument/KeyData';
import Overview from 'components/Instrument/Overview';
import {
    BondId, INTEGER, PERCENTAGE, StructuredProductId,
} from 'constants/instrument';
import history from 'services/history';
import { tradingCardSelector, usePortfolioSelector } from 'domain/Portfolio';
import DetailsPerformance from 'components/PositionDetailsPerformace';
import TradeCard from './components/TradeCard';
import { adaptPortfolioDetails } from './adapters/adaptPortfolioDetails';
import { adaptTradingCardPosition } from './adapters/adaptTradingCardPosition';
import { usePortfolioDetails } from '../common/hooks/usePortfolioDetails';
import { useTradingCard } from '../../hooks/useTradingCard';
import './PositionDetails.css';
import { mapActionTypeName, MODIFY } from '../../constants';
import { onPreventRedirect } from '../common/utils';

function PositionDetails(props) {
    const {
        match: {
            params: {
                positionId, portfolioId, tabId, dfsClientId, extraTabId, productId,
            },
            url,
        },
    } = props;
    const clientId = getClientId(dfsClientId);
    const { t, i18n: { language } } = useTranslation();
    const { getFormattedDate } = useFormatting();

    // Instrument Domain
    const {
        data: dataDocs, isLoading: isLoadingDocs, error: errorDocs, getDocuments,
        errorDocument, getDocumentById,
    } = useDocuments({ instrumentId: positionId });
    const documents = useMemo(() => adaptInstrumentDocs({
        data: dataDocs, getFormattedDate, t, getDocumentById, positionId, language,
    }), [dataDocs, getFormattedDate, t, getDocumentById, positionId, language]);
    const {
        data, isLoading, error, getCommon,
        dataPerformance, isLoadingPerformance, errorPerformance, getPerformance,
        dataBenchmark, isLoadingBenchmark, errorBenchmark,
    } = useInstrumentData({ instrumentId: positionId });

    // Portfolio Data
    const {
        dataPortfolioDetails, isLoadingPortfolioDetails, errorPortfolioDetails,
    } = usePortfolioDetails({
        adaptPortfolioDetails,
        clientId,
        portfolioId,
        positionId,
        securityTypeId: data?.typeId,
    });

    // Trading card
    const {
        dataTradingCard, isLoadingTradingCard,
    } = useTradingCard(adaptTradingCardPosition, clientId, portfolioId, positionId);

    // Data
    const currencyID = data?.currency?.id || dataPortfolioDetails?.currencyId || undefined;

    const prevPage = url?.split('/')[5];
    const actionType = mapActionTypeName(prevPage, t);

    const defaultActiveKeyList = useMemo(() => {
        if (extraTabId === '3') return ['1', '3', '4'];
        if (tabId === '1') return ['1'];
        if (tabId === '2') return ['1', '2'];

        return ['1', '2', '3'];
    }, [tabId, extraTabId]);
    const overviewClassName = useMemo(() => getClassNames(
        'overview', { 'overview-instrument-smaller': !dataPortfolioDetails?.quantity },
    ), [dataPortfolioDetails.quantity]);
    const additionalBenchmark = useMemo(() => ({
        name: dataBenchmark.name,
        tradingPriceOfUnderlying: dataBenchmark.tradingPrice,
        calculatedRiskOfUnderlying: dataBenchmark.risk,
    }), [dataBenchmark]);
    const priceType = useMemo(() => ([BondId, StructuredProductId].includes(data.typeId)
        ? PERCENTAGE : INTEGER), [data.typeId]);
    const portfolioData = useMemo(() => ({
        price: dataPortfolioDetails.additionalPrice,
        units: dataPortfolioDetails.units,
        quantity: dataPortfolioDetails.quantity,
        currency: dataPortfolioDetails.priceCurrency,
        portfolioCurrency: dataPortfolioDetails.positionCurrency,
        costPrice: dataPortfolioDetails.costPrice,
        costPriceInCurrency: dataPortfolioDetails.additionalCostPrice,
        totalValue: dataPortfolioDetails.totalValueTop,
        totalValueInCurrency: dataPortfolioDetails.additionalTotalValue,
        allocation: dataPortfolioDetails.portfolioAllocation,
        performanceSi: dataPortfolioDetails.performanceSi,
        performanceAbsolute: dataPortfolioDetails.absolutePerformance,
    }), [dataPortfolioDetails]);
    const currencyIso = data?.currency?.isoCode || portfolioData.currency || undefined;

    // Effects
    useEffect(() => {
        getCommon();
    }, [getCommon]);
    useEffect(() => {
        if (data.currency?.id) getPerformance({ currencyId: data.currency?.id });
    }, [data.currency, getPerformance]);
    useEffect(() => {
        if (data.uId) getDocuments({ instrumentUId: data.uId });
    }, [data.uId, getDocuments]);

    const { deleteTradingCard } = usePortfolioSelector(tradingCardSelector);

    const onCancel = (u) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                if (prevPage === MODIFY) {
                    sessionStorage.removeItem(MODIFY);
                    deleteTradingCard(clientId, portfolioId);
                }

                history.push(u);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };

    const isHelpTextHidden = useMemo(() => {
        const { currency, portfolioCurrency } = portfolioData;

        return currency === portfolioCurrency;
    }, [portfolioData]);

    const calculatedTradingPrice = useMemo(() => data.tradingPrice
        * (0.01 ** +(data.isPricePercentage || 0)) * (data.factor || 1),
    [data.tradingPrice, data.isPricePercentage, data.factor]);

    return (
        <PageSection className="position-details-overview">
            <Preloader
                isLoading={isLoadingPortfolioDetails || isLoading
                    || isLoadingBenchmark || isLoadingTradingCard || isLoadingPerformance}
                error={errorPortfolioDetails || error || errorBenchmark}
            >
                <ContentBox underline={false}>
                    <PageHeader
                        title={data?.name}
                        breadCrumbsCurrent={data?.name}
                        breadCrumbsChildren={[
                            {
                                to: `/client/${dfsClientId}/portfolios/`,
                                label: t('clientDashboard.portfolioEdit.portfolios'),
                                onClick: actionType
                                    ? (e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/`, onCancel) : undefined,
                            },
                            {
                                to: `/client/${dfsClientId}/portfolios/${portfolioId}`,
                                label: dataPortfolioDetails?.portfolioName,
                                onClick: actionType
                                    ? (e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/${portfolioId}`, onCancel)
                                    : undefined,
                            },
                            {
                                to: `/client/${dfsClientId}/portfolios/${portfolioId}/modify`,
                                label: t('clientDashboard.portfolioEdit.title'),
                            },
                        ]}
                    />
                    <div className="content-wrapper">
                        <Accordion defaultActiveKey={defaultActiveKeyList}>
                            <Panel header={t('position.overview')} key="1" className={overviewClassName}>
                                <Overview
                                    data={data}
                                    priceType={priceType}
                                    positionId={positionId}
                                    performance={dataPerformance}
                                    portfolioData={portfolioData}
                                    hideHelpText={isHelpTextHidden}
                                />
                            </Panel>
                            <Panel header={t('position.performance')} key="2" className="performance">
                                <DetailsPerformance
                                    positionId={positionId}
                                    currencyId={currencyID}
                                    currency={currencyIso}
                                    priceType={priceType}
                                    error={errorPerformance}
                                />
                            </Panel>
                            <Panel header={t('position.keyData')} key="3" className="key-data">
                                <KeyData
                                    positionId={positionId}
                                    additionalData={{
                                        ...additionalBenchmark,
                                        calculatedRisk: dataPerformance?.Volatility,
                                        calculatedExpectedReturn: dataPerformance?.ExpectedReturn,
                                        sharpeRatio: dataPerformance?.SharpeRatio,
                                    }}
                                />
                            </Panel>
                            <Panel header={t('position.documents')} key="5" className="documents table-without-last-row-padding table-without-head-row-padding">
                                <InstrumentDocumentsTable
                                    data={documents}
                                    error={errorDocs}
                                    isLoading={isLoadingDocs}
                                    errorDocument={errorDocument}
                                />
                            </Panel>
                            <Panel header={t('position.trade')} key="4" className="trade">
                                <TradeCard
                                    clientId={clientId}
                                    portfolioId={portfolioId}
                                    portfolioName={dataPortfolioDetails.portfolioName}
                                    positionId={positionId}
                                    productId={productId}
                                    quantity={dataPortfolioDetails.quantity}
                                    price={calculatedTradingPrice}
                                    tradingUnit={data?.tradingUnit}
                                    roundingLot={data?.roundingLot}
                                    defaultData={dataTradingCard}
                                    portfolioCurrency={
                                        data?.currency?.isoCode || dataPortfolioDetails.currency
                                    }
                                    currency={dataPortfolioDetails.positionCurrency}
                                    dfsClientId={dfsClientId}
                                />
                            </Panel>
                        </Accordion>
                    </div>
                </ContentBox>
            </Preloader>
        </PageSection>
    );
}

PositionDetails.propTypes = {
    match: PropTypes.shape({
        params: {
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
            positionId: PropTypes.string.isRequired,
            productId: PropTypes.string.isRequired,
            tabId: PropTypes.string.isRequired,
            extraTabId: PropTypes.string,
        },
        url: PropTypes.string.isRequired,
        path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string,
    }).isRequired,
};

PositionDetails.defaultProps = {};

export default withInstrumentAccess(PositionDetails);
