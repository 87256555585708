import {
    memberIdSelector,
} from 'redux/auth/authSelectors';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    gql, useLazyQuery, useApolloClient,
} from '@apollo/client';
import { withCSContext } from 'services/ApolloClient';
import ServerError from 'errors/ServerError';

const ALL_CONTACTS_DATA = gql`
    query AllContactsData(
        $take: Int
        $withExternalId: Boolean = false
    ) {
    contacts(
        includeTotalCount: true,
        take: $take
    ) {
        totalCount
        items {
            id
            personalInformation {
                displayName
            }
            relationshipInformation @include(if: $withExternalId) {
                externalId
            }
        }
    }
}`;

const SEARCH_CONTACTS_DATA = gql`
    query SearchContactsData(
        $search: String
    ) {
    contacts(
        includeTotalCount: true
        where: { personalInformation: { displayName: { contains: $search } } }
    ) {
        totalCount
        items {
            id
            personalInformation {
                displayName
            }
        }
    }
}`;

export const useAllContacts = (options = {}) => {
    const memberId = useSelector(memberIdSelector);
    const client = useApolloClient();
    const {
        withExternalId, take, loadInitially = true,
    } = options;
    const [
        getAllContacts, {
            data: contactsRawData,
            loading: isLoadingContacts,
            error: contactsError,
        },
    ] = useLazyQuery(ALL_CONTACTS_DATA, {
        variables: {
            withExternalId: !!withExternalId,
            take,
        },
        context: withCSContext({ queryParams: { memberId } }),
    });

    const getContactsByName = async (search) => {
        try {
            const { data: { contacts: { items } } } = await client.query({
                query: SEARCH_CONTACTS_DATA,
                variables: { search },
                context: withCSContext({ queryParams: { memberId } }),
            });

            return items;
        } catch (err) {
            throw err.type !== undefined ? err : new ServerError(err);
        }
    };

    useEffect(() => {
        if (loadInitially) getAllContacts();
    }, [loadInitially]);

    return {
        contactsRawData,
        getContactsByName,
        isLoadingContacts,
        contactsError,
    };
};
