import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { adaptPositions } from '../../../adapters/adaptPositions';

export const usePositionsToSave = () => {
    const { t, i18n: { language } } = useTranslation();
    const { getModifiedPositions, saveModifiedPositions } = useOnBoardingSelector(
        onBoardingDataSelector,
    );
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();

    const saveWithNewPositions = (
        reviewData,
        securityData,
        onPageChange,
        positionsChanged,
    ) => {
        const prevGroupedPositions = isEmpty(getModifiedPositions) ? adaptPositions(
            reviewData?.positionsRaw, {
                portfolioCurrency: reviewData?.currency,
                portfolioValue: reviewData?.portfolioValue,
                isNew: false,
                getFormattedNumber,
                getFormattedCurrency,
                t,
            },
        ) : (getModifiedPositions?.positions || []);

        const prevPositionsChanged = isEmpty(getModifiedPositions)
            ? positionsChanged : getModifiedPositions?.positionsChanged;

        const newPosition = {
            Security: securityData,
            Allocation: securityData?.Allocation || '0.00',
        };

        const newPositionsChanged = [
            ...prevPositionsChanged,
            {
                securityId: securityData.Id || securityData.id,
                value: securityData?.Allocation ? (securityData?.Allocation * 100).toFixed(2) : '0.00',
            },
        ];

        const adapted = adaptPositions([newPosition], {
            portfolioCurrency: reviewData?.currency,
            portfolioValue: reviewData?.portfolioValue,
            isNew: true,
            getFormattedNumber,
            getFormattedCurrency,
            language,
            t,
        });

        const foundParent = prevGroupedPositions.filter((i) => i.id === adapted[0].id);

        if (!foundParent[0]) {
            saveModifiedPositions({
                positions: [...prevGroupedPositions, ...adapted],
                positionsChanged: newPositionsChanged,
            });

            onPageChange('modify');

            return;
        }

        const foundItem = foundParent[0].children
            .filter((i) => i.securityId === (securityData.Id || securityData.id));

        if (foundItem.length > 0) {
            onPageChange('modify');

            return;
        }

        const newParentChildren = [...foundParent[0].children, adapted[0].children[0]];
        const newParents = prevGroupedPositions.filter((i) => i.id !== adapted[0].id);

        newParents.push({
            ...foundParent[0],
            children: newParentChildren,
        });

        saveModifiedPositions({
            positions: newParents,
            positionsChanged: newPositionsChanged,
        });

        onPageChange('modify');
    };

    return { saveWithNewPositions };
};
