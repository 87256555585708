import React, { useMemo } from 'react';
import { FooterMini } from 'ui-library';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'translations/component/LanguageSwitcher';

import './Footer.css';

const { AppConfig, BuildInfoConfig } = global;

const Footer = () => {
    const { t } = useTranslation();
    const devVersion = useMemo(() => (process.env.NODE_ENV !== 'development' && BuildInfoConfig
        ? `${BuildInfoConfig.BUILD || ''}${BuildInfoConfig.COMMIT ? `.${BuildInfoConfig.COMMIT}` : ''}`
        : null), []);
    const version = useMemo(() => (process.env.NODE_ENV === 'development' ? '' : `
        ${AppConfig.RELEASE_VERSION || ''}${devVersion ? `.${devVersion}` : ''}
        ${AppConfig.RELEASE_VERSION_CTP ? ` ${t('footer.runningOnCtp')} ${AppConfig.RELEASE_VERSION_CTP}` : ''}
    `), [devVersion, t]);

    return (
        <FooterMini
            title={t('footer.copyright', { date: new Date().getFullYear() })}
        >
            <LanguageSwitcher />
            <div>
                <b>{t('footer.title')}</b>
                {` ${version}`}
                <br />
                {t('footer.copyright', { date: new Date().getFullYear() })}
            </div>
        </FooterMini>
    );
};

export default Footer;
