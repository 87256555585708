export const adaptFormForView = (data) => {
    if (!data) return data;

    return {
        ...data,
        personalInformation: {
            ...data.personalInformation,
            identification: {
                ...data.personalInformation?.identification?.[0],
            },
        },
    };
};

export const adaptFormForSave = (data) => {
    if (!data) return data;

    return {
        ...data,
        personalInformation: {
            ...data.personalInformation,
            identification: [{
                ...data.personalInformation?.identification,
            }],
        },
    };
};
