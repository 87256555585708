import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Modal, Infobox, Accordion, AccordionPanel as Panel, Table,
} from 'ui-library';
import EmptyContent from 'components/EmptyContent';
import { useTranslation } from 'react-i18next';
import Allocation from 'templates/Portfolio/components/Allocation';
import AllocationCompare from 'components/AllocationCompare';
import Analysis from 'components/Analysis';
import Preloader from 'components/Preloader';
import { useFormatting } from 'locale';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import { goalSummarySelector, onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import RiskBandwidthDisclaimer from 'components/RiskBandwidthDisclaimer/RiskBandwidthDisclaimer';
import { getPositionsColumns, getComparePositionsColumns } from '../constants';
import { useReviewData } from '../../../../../hooks/useReviewData';
import { useSaveProposals } from '../../../../../hooks/useSaveProposals';
import { useReviewWithProposal } from '../hooks/useReviewWithProposal';
import OnBoardingBaseTemplate from '../../../../../components/OnBoardingBaseTemplate';
import { useGoalReach } from '../../../hooks/useGoalReach';
import { useCheckRiskProfile } from '../../../../../../ClientOverview/pages/Portfolios/hooks/useCheckRiskProfile';
import ReviewPageOverview from '../../../../../components/ReviewPageOverview';

function ReviewPageAdvisory({
    onPageChange, onPrev, contactId, location: { pathname }, clientId,
}) {
    const { t } = useTranslation();
    const { getFormattedNumber } = useFormatting();
    const optionsAdditional = useMemo(() => ({
        nameLength: 35, positionLink: `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`,
    }), [pathname]);

    // Hooks
    const {
        getGoalDetails,
        createdProposalId,
        saveProposalId,
        getModifiedPositions,
        getRiskCategory,
        getGoalChanges,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        updateProposal, isLoadingUpdate, errorUpdate,
    } = useOnBoardingSelector(goalSummarySelector);

    // Data
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const isNoGoal = useMemo(() => goalDetails.selectedGoal === 'no-goal', [goalDetails.selectedGoal]);
    const goalChanges = useMemo(() => (Number.isInteger(+getGoalChanges())
        ? getGoalChanges() : undefined), [getGoalChanges]);
    const isComparison = getModifiedPositions?.positions?.length || goalChanges;
    const { saveProposal, isCreatingProposal, errorCreatingProposal } = useSaveProposals(contactId);
    const { data, isLoading: isLoadingReviewData, error: errorReview } = useReviewData(
        contactId, optionsAdditional,
    );
    const {
        isLoading, error, reviewData, positions,
    } = useReviewWithProposal(
        contactId,
        isComparison ? createdProposalId : null,
        { reviewData: data, isLoadingReviewData, errorReview },
        optionsAdditional,
    );
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(reviewData, isLoading, true);
    const comparisonData = isComparison ? reviewData : null;
    const comparisonPositions = isComparison ? positions : reviewData.positions;
    const {
        analysisData,
        isLoadingModel, errorModel,
        volatility,
        onPerformanceChange, performanceSelected, projectionResponse,
    } = useAnalysisDetails({
        portfolio: data,
        strategy: comparisonData,
        positions: comparisonPositions,
        isLoading,
        benchmark,
        isEdit: isComparison,
        additionalOptions: { benchmarkSelected, withPerformanceBenchmarks: true },
    });

    const {
        dataExternal, isGoalReach,
    } = useGoalReach({
        goalState: goalDetails,
        projectionResponse,
    });

    // Risk Profile Check
    const [riskCategory, setRiskCategory] = useState(null);
    const [showRiskDisclaimer, setShowRiskDisclaimer] = useState(false);

    useEffect(() => {
        (async () => {
            if (goalDetails) {
                setRiskCategory({ Id: data.riskCategory, Name: data.riskCategoryName });
            } else {
                const risk = await getRiskCategory();

                setRiskCategory(risk);
            }
        })();
    }, [getRiskCategory, data.riskCategory, data.riskCategoryName, goalDetails]);
    const {
        compareVolatility, clientRiskBandwidth,
    } = useCheckRiskProfile(clientId, data.productId);
    const riskCategoryId = riskCategory?.Id;
    const riskCategoryName = riskCategory?.Name;

    useEffect(() => {
        setShowRiskDisclaimer(false);

        if (volatility) {
            compareVolatility(volatility, riskCategoryId).then((passed) => {
                setShowRiskDisclaimer(!passed);
            });
        }
    }, [compareVolatility, volatility, riskCategoryId]);

    // Callbacks
    const onConfirm = () => {
        Modal.confirm({
            title: t('onBoarding.confirmInvestment'),
            content: t('onBoarding.confirmInvestmentText'),
            okText: t('onBoarding.confirm'),
            onOk: async () => {
                if (typeof createdProposalId !== 'number') {
                    const savedProposalData = await saveProposal(
                        contactId,
                        data,
                        data?.investmentAllocations,
                    );

                    if (savedProposalData?.createdProposalId) {
                        saveProposalId(savedProposalData.createdProposalId);
                        onPageChange('summary');
                    }
                } else {
                    const params = {
                        BenchmarkId: {
                            DoUpdate: true,
                            Value: data.benchmarkId,
                        },
                    };

                    await updateProposal(createdProposalId, params);
                }

                onPageChange('summary');
            },
            cancelText: t('onBoarding.cancel'),
        });
    };

    const onNextClick = () => {
        if (!showRiskDisclaimer) {
            onConfirm();

            return;
        }

        Modal.confirm({
            title: t('clientDashboard.disclaimer'),
            content: t('clientDashboard.changeStrategy.riskProfileContent'),
            okText: t('clientDashboard.yes'),
            cancelText: t('clientDashboard.no'),
            onOk: () => {
                onConfirm();
            },
            className: 'accept-risk-profile',
        });
    };
    const onModify = () => {
        onPageChange('modify');
    };
    const onStrategyChange = () => {
        onPageChange('change-strategy');
    };
    const onModelChange = () => {
        onPageChange('change-model');
    };
    const onOptimize = () => {
        onPageChange('optimize');
    };

    // Renderers
    const isLoadingData = useMemo(() => isLoading || isLoadingReviewData,
        [isLoading, isLoadingReviewData]);
    const errorData = useMemo(() => error || errorReview, [error, errorReview]);
    const AllocationRender = useMemo(() => {
        const Component = comparisonData?.investmentAllocation ? AllocationCompare : Allocation;

        return (
            <Component
                data={data.investmentAllocation}
                dataNew={comparisonData?.investmentAllocation}
                isLoading={isLoadingData}
                error={errorData}
            />
        );
    }, [
        data.investmentAllocation,
        comparisonData?.investmentAllocation,
        isLoadingData,
        errorData,
    ]);

    const renderRiskDisclaimer = () => (
        <div className="risk-bandwidt-disclaimer">
            <RiskBandwidthDisclaimer
                risk={volatility}
                min={clientRiskBandwidth?.Min}
                max={clientRiskBandwidth?.Max}
                CRP={riskCategoryName}
            />
        </div>
    );

    return (
        <OnBoardingBaseTemplate
            data={data}
            error={error}
            isLoading={isLoadingData}
            title={goalDetails.goalName}
            className="on-boarding_review goal-review"
            classNameContent="stepper-content-full"
            prevButton={{
                loading: isCreatingProposal || isLoadingUpdate,
                onClick: onPrev,
                disabled: isLoadingData,
            }}
            additionalComponent={(
                <>
                    {!isNoGoal && (
                        <Button type="secondary" size="small" disabled={isLoading} onClick={onOptimize}>
                            {t('onBoarding.goal.optimize')}
                        </Button>
                    )}
                    <Button type="secondary" size="small" disabled={isLoading} onClick={onModify}>
                        {t('onBoarding.modify')}
                    </Button>
                </>
            )}
            nextButton={{
                text: t('onBoarding.confirmInvestmentMix'),
                loading: isCreatingProposal || isLoadingUpdate,
                disabled: isLoading,
                onClick: onNextClick,
            }}
            topInfoboxContent={!isGoalReach && !isLoadingModel && !isNoGoal && (
                <div className="goal-reached">
                    <Infobox accent>{t('onBoarding.cannotReachedGoal')}</Infobox>
                </div>
            )}
            buttonsRowError={(
                <>
                    {errorCreatingProposal && (
                        <Infobox error>{errorCreatingProposal.message}</Infobox>
                    )}
                    {errorUpdate && <Infobox error>{errorUpdate.message}</Infobox>}
                </>
            )}
        >
            <EmptyContent data={data} text={t('advisoryDashboard.noData')}>
                <Preloader isLoading={isLoading} error={error}>
                    {showRiskDisclaimer && renderRiskDisclaimer()}
                    <ReviewPageOverview
                        data={reviewData.overview}
                        onStrategyChange={onStrategyChange}
                        onModelChange={onModelChange}
                    />
                    <Accordion defaultActiveKey={['2', '3', '4']}>
                        <Panel header={t('clientDashboard.portfolio.allocation')} key="2" className="allocation">
                            {AllocationRender}
                        </Panel>
                        <Panel header={t('clientDashboard.portfolio.analysis')} key="3" className="investment-performance">
                            <Analysis
                                data={analysisData}
                                onFilterChange={onPerformanceChange}
                                benchmarkOptions={benchmarkOptions}
                                benchmarkSelected={benchmarkSelected}
                                onBenchmarkChange={onBenchmarkChange}
                                isLoadingBenchmarks={isLoadingBenchmark}
                                performanceSelected={performanceSelected}
                                isLoading={isLoadingModel}
                                error={errorModel}
                                oneProjectionColumn={!isComparison}
                                projectionDataExternal={dataExternal}
                                isPortfolioProposed
                                withPerformanceBenchmarks
                            />
                        </Panel>
                        <Panel header={t('clientDashboard.portfolio.positions')} key="4" className="positions">
                            <Table
                                expandedColumn="name"
                                className="positions-table"
                                data={comparisonPositions}
                                columns={isComparison
                                    ? getComparePositionsColumns(t)
                                    : getPositionsColumns(t, getFormattedNumber)
                                }
                                headerControls={{
                                    selectControlsLabel: {
                                        selectAll: t('headerControls.selectAll'),
                                        reset: t('headerControls.reset'),
                                    },
                                }}
                                defaultExpandAllRows
                            />
                        </Panel>
                    </Accordion>
                </Preloader>
            </EmptyContent>
        </OnBoardingBaseTemplate>
    );
}

ReviewPageAdvisory.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
    onPrev: PropTypes.func,
    onPageChange: PropTypes.func,
    clientId: PropTypes.number.isRequired,
};

ReviewPageAdvisory.defaultProps = {
    onPrev: () => {
    },
    onPageChange: () => {
    },
};

export default ReviewPageAdvisory;
