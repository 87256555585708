import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getDatesDifference, getTimestamp } from 'utils/datetime';
import { useProjection } from 'hooks/useProjection';
import { defaultMinMaxForEmptyData } from 'constants/goalCreation';
import { adaptInstrumentAllocations } from 'adaptors/adaptInstrumentAllocations';

export const useGoalPerformance = (options = {}) => {
    const {
        goalState,
        optimizeGoalData,
        dataPortfolio,
        selectedOptimizeGoal,
    } = options;
    const { t } = useTranslation();

    const instrumentAllocations = useMemo(() => adaptInstrumentAllocations(dataPortfolio),
        [dataPortfolio]);
    const endDate = useMemo(() => (selectedOptimizeGoal === 'TargetYears'
        ? optimizeGoalData?.targetDate : goalState?.targetDate) || null,
    [selectedOptimizeGoal, optimizeGoalData?.targetDate, goalState?.targetDate]);

    const performanceData = useMemo(() => {
        if (goalState?.selectedCurrency?.value
            && +goalState?.initialInvestment >= 0
            && instrumentAllocations.length > 0
            && goalState?.targetDate
            && +goalState?.recurringPayment >= 0) {
            return {
                InitialInvestmentAmount: +optimizeGoalData?.initialInvestment === 0
                    || +goalState.initialInvestment === 0 ? 1 : +optimizeGoalData?.initialInvestment
                    || +goalState.initialInvestment,
                MonthlyContributionAmount: +optimizeGoalData?.recurringPayment === 0
            || +goalState.recurringPayment === 0 ? 0 : +optimizeGoalData?.recurringPayment
                || +goalState.recurringPayment,
                ProjectionYears: Math.ceil(getDatesDifference(
                    new Date(optimizeGoalData?.targetDate || goalState.targetDate),
                    new Date(),
                    'month',
                ) / 12),
                CurrencyId: goalState.selectedCurrency.value,
                InstrumentAllocations: instrumentAllocations,
            };
        }

        return null;
    }, [
        goalState?.selectedCurrency?.value,
        goalState?.initialInvestment,
        instrumentAllocations,
        goalState?.targetDate,
        goalState?.recurringPayment,
        optimizeGoalData?.targetDate,
        optimizeGoalData?.initialInvestment,
        optimizeGoalData?.recurringPayment,
    ]);

    const {
        projection, projectionResponse, isLoadingProjection,
    } = useProjection({
        portfolio: performanceData,
        currency: goalState?.selectedCurrency?.label || 'USD',
        endDate,
        additionalOptions: { withDelay: true },
    });
    const defaultMinMax = useMemo(() => (projection?.charts?.[0]?.length > 0
        ? {} : defaultMinMaxForEmptyData), [projection?.charts]);
    const dataExternal = useMemo(() => {
        if (goalState?.targetDate
            && goalState?.targetValue
            && projectionResponse) {
            const values = projectionResponse[projectionResponse.length - 1].Values;
            const projectionDate = values?.[values.length - 1]?.Date;

            const date = getTimestamp(
                optimizeGoalData?.targetDate || goalState.targetDate,
            ) < getTimestamp(projectionDate)
                ? getTimestamp(optimizeGoalData?.targetDate || goalState.targetDate)
                : getTimestamp(projectionDate);

            return (
                [{
                    name: t('onBoarding.goal.yourGoal'),
                    data: [[date, Number(optimizeGoalData?.targetValue || goalState.targetValue)]],
                    type: 'scatter',
                }]
            );
        }


        return [];
    }, [
        goalState?.targetValue,
        goalState?.targetDate,
        optimizeGoalData?.targetValue,
        optimizeGoalData?.targetDate,
        projectionResponse,
    ]);

    return {
        defaultMinMax,
        dataExternal,
        chartData: projection?.charts?.[0],
        projectionOptional: projection?.optional,
        projectionResponse,
        isLoadingProjection,
    };
};
