import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Column, Row } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { getClientId } from 'utils';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import history from 'services/history';
import { PageNotFound } from 'components/PageError';
import InfoboxWithButton from 'components/InfoboxWithButton';
import InvestmentAllocation from 'components/InvestmentAllocation';
import Messages from 'components/Messages';
import Documents from 'components/Documents';
import Portfolios from 'components/Portfolios';
import Tasks from 'components/Tasks';
import { useProposals } from 'hooks/useProposals';
import { useClientRiskCategory } from 'hooks/useClientRiskCategory';
import { CREATE_PORTFOLIO } from 'constants/constants';
import { useClientProfile } from 'prodivers/clientProfile';
import { adaptRiskInformation } from './adapters/adaptRiskInformation';
import Overview from './compnents/Overview';
import AssetDevelopment from './compnents/AssetDevelopment';
import Information from './compnents/Information';
import { useWithInvestmentOverview } from './hooks/useWithInvestmentOverview';
import { useMessages } from './hooks/useMessages';
import { useWithDocuments } from './hooks/useWithDocuments';
import { useDownloadReport } from './hooks/useDownloadReport';
import { useParentsAllocations } from './hooks/useParentsAllocations';
import { useClientTasks } from './hooks/useClientTasks';
import { useClientLayout } from '../../hooks/useClientLayout';
import './Dashboard.css';
import { useGridDimensions } from './hooks/useGridDimensions';

function Dashboard(props) {
    useCancelAllRequests();
    useClientLayout();

    const { t } = useTranslation();
    const { isOneCol } = useGridDimensions();
    const {
        match: { params: { dfsClientId } },
    } = props;
    // Client Provider
    const { clientName, ...dataClient } = useClientProfile();
    const clientId = getClientId(dfsClientId);
    const {
        clientRiskCategory, hasRiskProfile, isRiskCategoryLoading, error: riskCategoryError,
    } = useClientRiskCategory(clientId);

    const { ...proposalsObj } = useProposals(clientId);
    const { data, isLoading, error } = useWithInvestmentOverview({
        clientId, dfsClientId, proposals: proposalsObj, usePortfolioOverview: true,
    });

    const clientInformation = useMemo(() => (
        adaptRiskInformation({
            dataClient,
            clientRiskProfile: clientRiskCategory,
            hasRiskProfile,
            hasProducts: data?.productIds?.length > 0,
            t,
        })
    ), [dataClient, clientRiskCategory, hasRiskProfile, t, data?.productIds]);

    const {
        investmentAllocation, isLoadingAllocation, errorAllocation,
    } = useParentsAllocations(data.positions, isLoading, data.overview.currency);
    const messages = useMessages(clientId);
    const documents = useWithDocuments(clientId);
    const {
        downloadReport, isLoading: isReportLoading, fileName,
    } = useDownloadReport(clientId, clientName);
    const { tasks } = useClientTasks(clientId);

    const messagesDefaultSize = useMemo(() => (messages?.data.length < 5
        ? messages?.data.length : 5),
    [messages?.data?.length]);
    const documentsDefaultSize = useMemo(() => (documents?.data.length < 5
        ? documents?.data.length : 5),
    [documents?.data?.length]);
    const onNewClick = () => history.push(`/client/${dfsClientId}/portfolios/risk-profile`);
    const onOpenNewPortfolio = () => { sessionStorage.removeItem(CREATE_PORTFOLIO); history.push(`/client/${dfsClientId}/portfolios/new-portfolio`); };

    const onRowClick = ({ id }) => ({
        onClick: () => { history.push(`/client/${dfsClientId}/messages/${id}`); },
    });

    if (clientId === undefined) {
        return (<PageNotFound />);
    }

    const onBoarding = () => history.push(`/onboarding/${dfsClientId}`, { clientName });
    const renderProspectInfobox = () => (
        <InfoboxWithButton buttonText={t('clients.onBoardingToWealth')} buttonProps={{ onClick: onBoarding }}>
            {t('clientDashboard.onboardProspect')}
        </InfoboxWithButton>
    );

    const defineRiskProfile = () => history.push(`/client/${dfsClientId}/risk-profile`);
    const renderInfobox = () => (
        <InfoboxWithButton buttonText={t('clientDashboard.defineRiskProfile')} buttonProps={{ onClick: defineRiskProfile }}>
            {t('clientDashboard.noRiskProfile')}
        </InfoboxWithButton>
    );
    const renderOverviewAndInfo = () => (
        <>
            <Overview
                key="dashboard__overview"
                data={data.overview}
                isLoading={isLoading}
                error={error}
                downloadReport={downloadReport}
                downloadReportDisabled={!clientName || clientName === ''}
                isReportLoading={isReportLoading}
                fileName={fileName}
            />
            <Information
                key="dashboard__information"
                {...clientInformation}
                isLoading={isLoading || isRiskCategoryLoading}
                error={clientInformation.error || riskCategoryError}
            />
        </>
    );

    return (
        <div className="client-overview dashboard">
            {dataClient?.data?.IsProspect && renderProspectInfobox()}
            {(!hasRiskProfile && !dataClient?.data?.IsProspect) && renderInfobox()}
            <Row>
                <Column size="lg-8" key="dashboard__first-col">
                    {isOneCol && renderOverviewAndInfo()}
                    <Tasks
                        key="dashboard__tasks"
                        {...tasks}
                        disableLinks
                        noDataText="clientDashboard.tasks.noData"
                    />
                    <InvestmentAllocation
                        key="dashboard__investment-allocation"
                        data={investmentAllocation}
                        isLoading={isLoadingAllocation}
                        error={errorAllocation}
                    />
                    <AssetDevelopment
                        key="dashboard__asset-development"
                        clientId={clientId}
                        currency={data.overview.currency}
                    />
                    <Portfolios
                        key="dashboard__portfolios"
                        data={data.portfolios}
                        isLoading={isLoading}
                        error={error}
                        onNewClick={onNewClick}
                        onOpenNewPortfolio={onOpenNewPortfolio}
                        link={`/client/${dfsClientId}`}
                        pageSize={5}
                    />
                </Column>
                <Column size="lg-4" key="dashboard__seconds-col">
                    {!isOneCol && renderOverviewAndInfo()}
                    <Messages
                        key="dashboard__messages"
                        {...messages}
                        link={`/client/${dfsClientId}`}
                        onRow={onRowClick}
                        defaultSize={messagesDefaultSize}
                        withoutHeader
                    />
                    <Documents
                        key="dashboard__documents"
                        {...documents}
                        defaultSize={documentsDefaultSize}
                        link={`/client/${dfsClientId}`}
                    />
                </Column>
            </Row>
        </div>
    );
}

Dashboard.propTypes = {
    hasRiskProfile: PropTypes.bool.isRequired,
    clientName: PropTypes.string.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string,
        }),
    }).isRequired,
};

Dashboard.defaultProps = {
};

export default Dashboard;
