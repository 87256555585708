import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import { useFormatting } from 'locale';

export const useGetInteractions = (adaptInteractions, {
    contactId,
    memberId,
    params = {},
    loadInitially = false,
    typesAndTopics = {},
    options,
}) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const { t } = useTranslation();
    const { getFormattedDate, getFormattedTime } = useFormatting();

    const getInteractions = useCallback(async () => {
        setError(null);
        setLoading(true);
        try {
            const response = memberId
                ? await ServiceManager.contactInteraction('getMemberInteractions', [memberId, params, true])
                : await ServiceManager.contactInteraction('getInteractions', [contactId, params]);

            try {
                const listOfInteractions = adaptInteractions(
                    response.data?.Interactions,
                    {
                        topics: typesAndTopics.topics,
                        types: typesAndTopics.types,
                        getFormattedDate,
                        getFormattedTime,
                        t,
                        ...options,
                    },
                );

                setData(listOfInteractions);
                setLoading(false);

                return listOfInteractions;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            setError(err.type !== undefined ? err : new ServerError(err));

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [
        adaptInteractions,
        contactId,
        t,
        getFormattedDate,
        getFormattedTime,
        memberId,
        options,
        params,
        typesAndTopics.topics,
        typesAndTopics.types,
    ]);

    useEffect(() => {
        if (!loadInitially) return;

        if (typesAndTopics.error) {
            setError(typesAndTopics.error);

            return;
        }

        if (!typesAndTopics.isLoading) {
            getInteractions();
        }
    }, [
        getInteractions,
        typesAndTopics.error,
        typesAndTopics.isLoading,
        loadInitially,
    ]);

    return {
        data,
        error,
        isLoading,
        getInteractions,
    };
};
