import React, {
    useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
    ContentBox, Title, Row, Column, TabPane, Tabs, Button,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import { getParamsFromUrl } from 'utils';
import Preloader from 'components/Preloader';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import PageSection from '../../components/PageSection';
import { ClientSearchBar } from './components/ClientSearchBar';
import history from '../../services/history';
import { connectClientsSchema } from './provider/ClientsSchemaProvider';
import { ClientList, ProspectusList } from './containers/TablesContainer';
import './Clients.css';

function Clients(props) {
    useCancelAllRequests();
    const { t } = useTranslation();
    const status = getParamsFromUrl('status');
    const { location } = props;
    const [tab, setTab] = useState(location?.state?.fromTab || null);
    const [search, setSearch] = useState('');

    const onNewProspect = () => {
        history.push('/onboarding/register');
    };

    const clientTitle = useMemo(() => {
        if (status) {
            if (tab === 'clients') {
                return t('advisoryDashboard.overview.clients');
            }
            if (tab === 'prospects') {
                return t('advisoryDashboard.overview.prospects');
            }
            if (status === t('advisoryDashboard.overview.clients')) {
                setTab('clients');

                return t('advisoryDashboard.overview.clients');
            }
            if (status === t('advisoryDashboard.overview.prospects')) {
                setTab('prospects');

                return t('advisoryDashboard.overview.prospects');
            }
        }

        return t('clients.title');
    }, [t, status, tab]);

    const onSearch = (value) => {
        setSearch(value);
    };

    return (
        <PageSection className="client-page">
            <ClientSearchBar
                onSearch={onSearch}
            />
            <ContentBox>
                <Row>
                    <Column size="9">
                        <Title className="title">{clientTitle}</Title>
                    </Column>
                    <Column size="3" className="new-prospect-button">
                        <Button
                            type="secondary"
                            size="small"
                            onClick={onNewProspect}
                        >
                            {t('clients.newClient')}
                        </Button>
                    </Column>
                </Row>
                <Preloader isLoading={false} error={null}>
                    <Tabs activeKey={tab} className="Tabs-bar" defaultActiveKey="clients" onChange={setTab}>
                        <TabPane tab={t('advisoryDashboard.overview.clients')} key="clients">
                            <ClientList search={search} tab={tab} />
                        </TabPane>
                        <TabPane tab={t('advisoryDashboard.overview.prospects')} key="prospects">
                            <ProspectusList search={search} tab={tab} />
                        </TabPane>
                    </Tabs>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

Clients.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            fromTab: PropTypes.string,
        }),
    }),
};

Clients.defaultProps = {
    location: {
        state: {},
    },
};
export default connectClientsSchema(Clients);
