/* global FileReader */
import _ from 'lodash';
import { isUndefined } from 'lodash/fp';


export const getMetadataReferenceId = (data) => {
    const searchItem = _.find(data, (item) => item.DocumentReferenceId === 'MessagingAttachments' && item.IsActive === true);

    if (searchItem !== undefined) {
        return searchItem.DocumentReferenceId;
    }

    return undefined;
};

export const returnIndexByName = (data, itemToUpload) => (
    _.findIndex(data, (item) => (item.name === itemToUpload.name))
);

export const adaptFiles = (filesUploaded, dataToUpload) => {
    const adapted = {};

    adapted.filesUploaded = filesUploaded;
    Array.from(dataToUpload).forEach((item) => {
        const reader = new FileReader();

        reader.readAsDataURL(item);
        reader.onload = (e) => {
            const splites = e.target.result.split(';base64,');

            // eslint-disable-next-line no-param-reassign, prefer-destructuring
            item.file = splites[1];

            // eslint-disable-next-line no-param-reassign, prefer-destructuring
            item.customAttributes = splites[0];
        };
        const indexPos = returnIndexByName(adapted.filesUploaded, item);

        if (indexPos !== -1) {
            adapted.filesUploaded[indexPos] = item;
        } else {
            adapted.filesUploaded.push(item);
        }
    });

    return adapted;
};

export const checkFilesWithoutContent = (data) => _.find(data, (item) => isUndefined(item.File));
