import React from 'react';
import { TextInput } from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';


export const ClientSearchBar = ({ onSearch }) => {
    const { t } = useTranslation();


    return (
        <TextInput
            placeholder={t('clients.searchClient')}
            onChange={onSearch}
            search
        />
    );
};

ClientSearchBar.propTypes = {
    onSearch: PropTypes.func.isRequired,
};

ClientSearchBar.defaultProps = {};
