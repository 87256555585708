import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from 'utils/ErrorBoundary';
import Documents from './Documents';
import { useClientLayout } from '../../hooks/useClientLayout';

const Router = (props) => {
    useClientLayout();
    const { match: { path } } = props;
    const withProps = (Component) => (defProps) => <Component {...props} {...defProps} />;
    const { t } = useTranslation();

    return (
        <ErrorBoundary t={t}>
            <Switch>
                <Route exact path={`${path}`} render={withProps(Documents)} />
                <Redirect from="*" to="/404" />
            </Switch>
        </ErrorBoundary>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
