import React from 'react';
import { useContact } from '../hooks/useContact';
import ClientServiceContext from './context';
import { useSchema } from '../hooks/useSchema';
import { useEditCustomer } from '../hooks/useEditCustomer';
import { useContactActivation } from '../hooks/useContactActivation';

const ClientServiceProvider = (options) => {
    const {
        options: {
            contactId,
        },
        children,
    } = options;
    const contact = useContact({ contactId });
    const schema = useSchema({ loadInitially: false });
    const editCustomer = useEditCustomer();
    const contactActivation = useContactActivation({ contactId });

    const state = {
        contactId, contact, schema, editCustomer, contactActivation,
    };
    const getValue = (callback) => callback(state);

    return (
        <ClientServiceContext.Provider
            value={{ ...state, getValue }}
        >
            {children}
        </ClientServiceContext.Provider>
    );
};

ClientServiceProvider.propTypes = {
};

ClientServiceProvider.defaultProps = {
};

export default ClientServiceProvider;
