import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import UnderConstruction from 'components/UnderConstruction';
import ConfirmationPage from 'components/ConfirmationPage';
import { ClientProfileProvider } from 'prodivers/clientProfile';
import withRiskProfileAccess from 'hocs/withRiskProfileAccess';
import PortfoliosRouter from './pages/Portfolios/Router';
import Details from './pages/Details';
import Documents from './pages/Documents/Router';
import Dashboard from './pages/Dashboard/Dashboard';
import RiskProfile from './pages/RiskProfile/RiskProfileContainer';
import KnowledgeExperience from './pages/KnowledgeExperience';
import Messages from './pages/Messages/Router';
import Interactions from './pages/Interactions/Router';
import Confirmation from './pages/Confirmation/Confirmation';
import Payments from './pages/Payments/Router';
import { getClientId } from '../../utils';
import CloseClient from './components/CloseClient';

const Router = (props) => {
    const {
        match: { path, url, params: { dfsClientId } },
        location: { state },
    } = props;
    const clientId = getClientId(dfsClientId);
    const withProps = (Component) => (defProps) => <Component {...props} {...defProps} />;

    return (
        <ClientProfileProvider
            clientId={clientId}
            clientName={state?.clientName}
            isProspect={state?.isProspect}
            withAdditionalInfo
        >
            <CloseClient url={url} state={state} />
            <Switch {...props}>
                <Route exact path={`${path}/dashboard`} render={withProps((Dashboard))} />
                <Route path={`${path}/risk-profile`} component={withRiskProfileAccess((RiskProfile))} />
                <Route path={`${path}/confirm`} component={(Confirmation)} />
                <Route path={`${path}/success-page`} component={(ConfirmationPage)} />
                <Route path={`${path}/knowledge-experience`} component={(KnowledgeExperience)} />
                <Route path={`${path}/portfolios`} component={PortfoliosRouter} />
                <Route exact path={`${path}/details`} render={withProps((Details))} />
                <Route exact path={`${path}/goals`} component={(UnderConstruction)} />
                <Route path={`${path}/messages`} render={withProps((Messages))} />
                <Route exact path={`${path}/documents`} render={withProps((Documents))} />
                <Route path={`${path}/interactions`} render={withProps((Interactions))} />
                <Route path={`${path}/payments`} render={withProps((Payments))} />
                <Redirect exact from="" to={{ pathname: `${path}/dashboard`, state }} />
                <Redirect from="*" to="/404" />
            </Switch>
        </ClientProfileProvider>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
        }),
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        state: PropTypes.shape({
            clientName: PropTypes.string,
            isProspect: PropTypes.bool,
        }),
    }).isRequired,
};

Router.defaultProps = {
};

export default Router;
