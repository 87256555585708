import React from 'react';
import { PageVariant } from 'ui-library';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import Preloader from 'components/Preloader';
import PageVariantWrapper from 'components/PageVariantWrapper';
import { useProposals } from 'hooks/useProposals';
import { useWithInvestmentOverview } from '../pages/ClientOverview/pages/Dashboard/hooks/useWithInvestmentOverview';
import { getClientId } from '../utils';

const withRiskProfileAccess = (Component) => (props) => {
    // eslint-disable-next-line react/prop-types
    const { match: { params: { dfsClientId } } } = props;
    const { t } = useTranslation();
    const clientId = getClientId(dfsClientId);
    const { ...proposalsObj } = useProposals(clientId);
    const { data, isLoading, error } = useWithInvestmentOverview({
        clientId, dfsClientId, proposals: proposalsObj, usePortfolioOverview: true,
    });

    if (data?.productIds?.length > 0) return <Component {...props} />;

    return (
        <Preloader isLoading={isLoading} error={error}>
            <PageVariantWrapper>
                <PageVariant
                    type="error"
                    title={t('error.title')}
                    message={t('common.pageNotFound')}
                    actionButtonLabel={t('confirmation.back')}
                    onActionButtonClick={history.goBack}
                />
            </PageVariantWrapper>
        </Preloader>
    );
};

export default withRiskProfileAccess;
