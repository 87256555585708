import React, { useEffect, useMemo, useState } from 'react';
import {
    ContentBox, Modal, Accordion, AccordionPanel as Panel, Infobox,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import { getClientId } from 'utils';
import Preloader from 'components/Preloader';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import StrategyOverview from 'components/StrategyOverview';
import {
    usePortfolioSelector,
    portfolioSelector,
    orderBookSelector,
} from 'domain/Portfolio';
import { CHANGE_STRATEGY } from 'constants/constants';
import Analysis from 'components/Analysis/Analysis';
import PageHeader from 'components/PageHeader';
import PositionsCompare from 'components/PositionsCompare';
import AllocationCompare from 'components/AllocationCompare';
import { useModelList } from 'hooks/useModelList';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import { useModelChange } from 'hooks/useModelChange';
import { useStrategyList } from 'hooks/useStrategyList';
import RiskDisclaimer from 'components/RiskDisclaimer/RiskDisclaimer';
import { onPreventRedirect } from '../../common/utils';
import { useCheckRiskProfile } from '../../../hooks/useCheckRiskProfile';

function ChangeStrategyDiscretionary(props) {
    const { match: { params: { portfolioId, dfsClientId } } } = props;
    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();
    const [errorSameStrategy, setErrorSameStrategy] = useState(false);
    const [strategy, setStrategy] = useState(JSON.parse(sessionStorage.getItem(CHANGE_STRATEGY)));

    // Portfolio Domain
    const { data, dataRaw, isLoading } = usePortfolioSelector(portfolioSelector);
    const {
        data: orderBook, isLoading: isLoadingOrderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    useEffect(() => {
        getOrderBook();
    }, [getOrderBook]);

    // Hooks
    const baseUrl = `/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy/position/${data?.productId}`;
    const {
        strategyList, isLoadingStrategyList, errorStrategyList,
    } = useStrategyList(data?.productId);
    const {
        data: modelList, isLoading: isLoadingModelList, error: errorModelList,
    } = useModelList({ productId: data?.productId, riskCategoryId: strategy });
    const {
        modelData, positions, modelId,
        isLoadingModelData, errorModelData, onModelChange, onModelReset,
    } = useModelChange({ portfolio: data, portfolioPositions: dataRaw?.Positions, baseUrl });
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);
    const strategyForAnalysis = useMemo(() => (modelData?.overview ? modelData
        : null), [modelData]);

    useEffect(() => {
        if (modelList.length) {
            if (modelList.find(({ value }) => value === modelId)) return;
            onModelChange(modelList[0]?.value);
        }
    }, [modelList, modelId, onModelChange]);

    const {
        analysisData, isLoadingModel, errorModel, onPerformanceChange, performanceSelected,
    } = useAnalysisDetails({
        clientId,
        portfolio: data,
        strategy: strategyForAnalysis,
        positions,
        isLoading,
        benchmark,
        isLoadingBenchmark,
    });

    // Risk Profile Check
    const [showRiskDisclaimer, setShowRiskDisclaimer] = useState(false);
    const { comparePortfolioRisk } = useCheckRiskProfile(clientId);
    const { riskCategoryId, riskCategoryName } = useMemo(() => ({
        riskCategoryId: dataRaw?.RiskCategory?.Id,
        riskCategoryName: dataRaw?.RiskCategory?.Name,
    }), [dataRaw?.RiskCategory]);

    useEffect(() => {
        setShowRiskDisclaimer(false);

        if (modelData?.riskCategory) {
            setShowRiskDisclaimer(
                !comparePortfolioRisk(modelData.riskCategory, riskCategoryId),
            );
        }
    }, [comparePortfolioRisk, modelData?.riskCategory, riskCategoryId]);

    // Callbacks
    const onStrategyChange = (val) => {
        setStrategy(val);
        setErrorSameStrategy(false);
        sessionStorage.setItem(CHANGE_STRATEGY, JSON.stringify(val));
    };
    const onStrategyReset = () => {
        sessionStorage.removeItem(CHANGE_STRATEGY);
        onModelReset();
    };
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                onStrategyReset();
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onDiscardChanges = () => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                onStrategyReset();
                history.push(`/client/${dfsClientId}/portfolios/${portfolioId}`);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onConfirm = () => {
        if (data?.modelPortfolioId === +modelId || !modelId) {
            setErrorSameStrategy(true);

            return;
        }

        if (!showRiskDisclaimer) {
            history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy/orders`);

            return;
        }

        Modal.confirm({
            title: t('clientDashboard.disclaimer'),
            content: t('clientDashboard.changeStrategy.riskProfileContent'),
            okText: t('clientDashboard.yes'),
            cancelText: t('clientDashboard.no'),
            onOk: () => {
                history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy/orders`);
            },
            className: 'accept-risk-profile',
        });
    };

    // Renderers
    const currentAllocations = data.investmentAllocation;
    const newAllocations = modelData?.investmentAllocation || currentAllocations;
    const AllocationRender = useMemo(() => (
        <AllocationCompare
            data={currentAllocations}
            dataNew={newAllocations}
            isLoading={isLoading}
        />
    ), [currentAllocations, newAllocations, isLoading]);
    const renderRiskDisclaimer = () => (
        <RiskDisclaimer
            PRP={modelData?.overview?.strategy}
            CRP={riskCategoryName}
        />
    );

    return (
        <ContentBox className="change-strategy">
            <Preloader
                isLoading={isLoadingStrategyList || isLoadingOrderBook}
                error={errorStrategyList || errorOrderBook}
            >
                <PageHeader
                    title={t('overview.changeStrategy')}
                    breadCrumbsCurrent={t('overview.changeStrategy')}
                    breadCrumbsChildren={[
                        {
                            to: `/client/${dfsClientId}/portfolios`,
                            label: t('clientDashboard.portfolios.title'),
                            onClick: (e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios`, onCancel),
                        },
                        {
                            to: `/client/${dfsClientId}/portfolios/${portfolioId}`,
                            label: data.title,
                            onClick: (e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/${portfolioId}`, onCancel),
                        },
                    ]}
                />
                {showRiskDisclaimer && !isLoading && renderRiskDisclaimer()}
                <div className="mb-0 mt-5 overview change-strategy-overview">
                    <StrategyOverview
                        data={data.overview}
                        isLoading={isLoadingStrategyList}
                        error={errorStrategyList}
                        isLoadingModel={isLoadingModelList}
                        errorModel={errorModelList}
                        selectedStrategy={strategy}
                        strategyList={strategyList}
                        onStrategyChange={onStrategyChange}
                        selectedModel={modelId}
                        modelList={modelList}
                        onModelChange={onModelChange}
                    />
                </div>
                <Preloader isLoading={isLoadingModelData} error={errorModelData}>
                    <Accordion defaultActiveKey={['1', '2', '3']}>
                        <Panel header={t('clientDashboard.portfolio.allocation')} key="1" className="allocation">
                            {AllocationRender}
                        </Panel>
                        <Panel header={t('clientDashboard.portfolio.analysis')} key="2" className="analysis">
                            <Analysis
                                data={analysisData}
                                onFilterChange={onPerformanceChange}
                                benchmarkOptions={benchmarkOptions}
                                benchmarkSelected={benchmarkSelected}
                                onBenchmarkChange={onBenchmarkChange}
                                isLoadingBenchmarks={isLoadingBenchmark}
                                performanceSelected={performanceSelected}
                                isLoading={isLoadingModel}
                                error={errorModel}
                            />
                        </Panel>
                        <Panel header={t('clientDashboard.portfolio.positions')} className="positions table-without-last-row-padding" key="3">
                            <PositionsCompare
                                data={{ positions }}
                                isLoading={isLoading}
                                defaultExpandAllRows
                            />
                        </Panel>
                    </Accordion>
                </Preloader>
                <ButtonsBlockRow
                    leftButton={{
                        type: 'danger',
                        text: t('confirmation.cancel'),
                        onClick: onDiscardChanges,
                    }}
                    primaryButton={{
                        text: t('confirmation.continue'),
                        loading: isLoadingModelData,
                        disabled: orderBook,
                        onClick: onConfirm,
                    }}
                >
                    {errorSameStrategy && <Infobox error>{t('validation.errorChangeSameStrategy')}</Infobox>}
                </ButtonsBlockRow>
            </Preloader>
        </ContentBox>
    );
}

ChangeStrategyDiscretionary.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

ChangeStrategyDiscretionary.defaultProps = {
};

export default ChangeStrategyDiscretionary;
