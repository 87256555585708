import React from 'react';
import { SkeletonLine } from 'ui-library';
import './TableSkeleton.css';

const TableSkeleton = () => (
    <div className="TableSkeleton">
        <div>
            <div>
                <SkeletonLine width={120} />
            </div>
            <div>
                <SkeletonLine width={150} />

            </div>
        </div>
        <div>
            <SkeletonLine width={80} />
        </div>
    </div>
);

export default TableSkeleton;
