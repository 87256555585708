import React from 'react';
import PropTypes from 'prop-types';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import { Button, Column, Title } from 'ui-library';
import { useSelector } from 'react-redux';
import { memberIdSelector } from 'redux/auth/authSelectors';
import { ListPreview, Preview } from 'templates/ListPreview';
import { PageError } from 'components/PageError';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import Metadata from 'components/Messages/Metadata';
import Attachments from 'components/Messages/Attachments';
import ListComponent from 'components/Messages/ListComponent';
import { getClientId } from 'utils';
import { useMessaging } from './hooks/useMessaging';
import './Messages.css';

const MessagesNew = ({ match }) => {
    useCancelAllRequests();

    const { t } = useTranslation();
    const { params: { messageId, dfsClientId } } = match;
    const clientId = getClientId(dfsClientId);
    const memberId = useSelector(memberIdSelector);
    const {
        tab,
        needUpdate,
        getData,
        onSearch,
        changeTab,
        getPreview,
        deleteItem,
        getDataCallback,
        data: messages,
    } = useMessaging(clientId, memberId);
    const { downloadFile } = useDownloadFile(clientId);

    if (clientId === undefined) {
        return (<PageError />);
    }

    const messagesLength = messages?.length || 0;

    const onNewMessage = () => {
        history.push(`/client/${dfsClientId}/messages/new`);
    };
    const onReply = ({
        id, subtypeId, recipients, title,
    }) => {
        history.push(
            `/client/${dfsClientId}/messages/reply/${id}`,
            {
                title,
                memberId,
                subtypeId,
                threadId: recipients[0].threadId,
            },
        );
    };

    const titleComponent = (
        <>
            <Column size={['sm-4']}>
                <Title type={2}>{t('messages.title')}</Title>
            </Column>
            <Column size={['sm-8']} className="text-sm-right button-group">
                <Button type="primary" size="small" onClick={onNewMessage}>
                    {t('messages.newMessage')}
                </Button>
            </Column>
        </>
    );

    const listComponent = (props) => (
        <div className="messages-list">
            <ListComponent
                tab={tab}
                needUpdate={needUpdate}
                changeTab={changeTab}
                useDynamicSorting
                onNewMessage={onNewMessage}
                {...props}
            />
        </div>
    );
    const renderActions = (props) => (tab === 'inbox' && (
        <Button type="secondary" size="small" onClick={() => onReply(props)}>
            {t('messages.reply')}
        </Button>
    ));
    const renderMetadata = (props) => Metadata({ ...props, t });
    const renderAttachments = (props) => Attachments({ ...props, downloadFile, t });

    return (
        <div className="messages-page">
            <ListPreview
                getData={getData}
                onSearch={onSearch}
                preSelect={messageId}
                getPreview={getPreview}
                listComponent={listComponent}
                titleComponent={titleComponent}
                getDataCallback={getDataCallback}
                dataLength={messagesLength}
                searchBarActive
            >
                {(previewProps) => (
                    <Preview
                        {...previewProps}
                        deleteItem={deleteItem}
                        deleteTitle={t('messages.deleteSelected')}
                        deleteText={t('messages.deleteCurrentContent')}
                        renderActions={renderActions}
                        renderMetadata={renderMetadata}
                        renderAttachments={renderAttachments}
                        showDelete
                    />
                )}
            </ListPreview>
        </div>
    );
};

MessagesNew.propTypes = {
    memberId: PropTypes.number,
    match: PropTypes.shape({
        params: { messageId: PropTypes.string },
    }),
};

MessagesNew.defaultProps = {
    memberId: undefined,
    match: { params: {} },
};

export default MessagesNew;
