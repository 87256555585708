import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';

const adaptData = (data) => (data.map(({
    InstrumentId, InstrumentTradeType, Quantity,
}) => ({
    InstrumentId,
    InstrumentTradeType,
    Quantity,
})));

const uniteData = (data, dataTrading) => (data.OrderBookEntries.map((item) => {
    const tradeCardItem = dataTrading.PortfolioTradingCart.Items
        .find(({ InstrumentId }) => InstrumentId === item.Security.Id);

    return {
        ...item,
        tradeData: tradeCardItem,
    };
}));

export const useTradingCard = (adaptTradingCard, clientId, portfolioId, positionId) => {
    const { i18n } = useTranslation();
    const [errorTradingCard, setError] = useState(null);
    const [isLoadingTradingCard, setLoading] = useState(true);
    const [dataTradingCard, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        const params = { language: i18n.language };

        ServiceManager.portfolioManagement('getTradingCard', [portfolioId, clientId, params])
            .then((response) => {
                try {
                    ServiceManager.portfolioManagement('tradingCardSimulate', [clientId, portfolioId, { language: i18n.language }, {
                        Trades: adaptData(response.data.PortfolioTradingCart.Items),
                    }]).then((responseData) => {
                        try {
                            const united = uniteData(responseData.data, response.data);

                            setData(adaptTradingCard(united, { positionId }));
                            setLoading(false);
                        } catch (errResp) {
                            throw new AdapterError(errResp);
                        }
                    }).catch(handlerRequestCanceling(
                        HandlerError({ setError, setLoading }),
                    ));
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, [i18n.language]);

    return { dataTradingCard, isLoadingTradingCard, errorTradingCard };
};
