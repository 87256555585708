import { useEffect, useMemo, useState } from 'react';
import { getUtcDate, dateTimeFormat } from 'utils/datetime';
import { useGetInteractions } from 'hooks/useGetInteractions';
import { useSelector } from 'react-redux';
import { memberIdSelector } from 'redux/auth/authSelectors';
import { adaptNextUp } from '../adapters/adaptNextUp';

export const useNextUp = (contacts, typesAndTopics) => {
    const [isLoading, setLoading] = useState(true);
    const memberId = useSelector(memberIdSelector);

    const params = useMemo(() => ({
        DateFrom: getUtcDate(new Date(), dateTimeFormat),
        IsCanceled: false,
        SortColumn: 'DateFrom',
        SortDescending: false,
        PageNumber: 1,
        PageSize: 10,
    }), []);
    const options = useMemo(() => ({
        contacts: contacts.data, memberId,
    }), [contacts.data, memberId]);
    const {
        data, error, isLoading: isLoadingInteractions, getInteractions,
    } = useGetInteractions(adaptNextUp, {
        params,
        memberId,
        typesAndTopics,
        options,
    });

    useEffect(() => {
        if (!contacts.isLoading && !typesAndTopics.isLoading) {
            getInteractions();
        }
    }, [contacts.isLoading, getInteractions, typesAndTopics.isLoading]);

    useEffect(() => {
        setLoading(isLoadingInteractions);
    }, [isLoadingInteractions]);

    return { data, isLoading, error };
};
