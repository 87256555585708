import React from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from 'utils';

import './Draggable.css';

function Draggable({
    children, className, withoutBorder, ...rest
}) {
    const draggableClassName = getClassNames(
        'draggable', { 'draggable-without_border': withoutBorder }, className,
    );

    return (
        <div className={draggableClassName} {...rest}>
            {children}
        </div>
    );
}

Draggable.propTypes = {
    className: PropTypes.string,
    withoutBorder: PropTypes.bool,
    children: PropTypes.node,
};

Draggable.defaultProps = {
    className: null,
    withoutBorder: false,
    children: null,
};

export default React.memo(Draggable);
