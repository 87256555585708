import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Title, ContentBox, SearchBar } from 'ui-library';
import history from 'services/history';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import Draggable from 'components/Draggable/Draggable';

import './ClientSearch.css';

function ClientSearch({
    data, names, isLoading, error, drop, searchContacts,
}) {
    const { t } = useTranslation();
    const onSelect = (value) => {
        const client = data.find(({ name }) => name === value[value.length - 1]);

        if (client) history.push(client.link, { clientName: client.name });
    };
    const handleSearch = (value) => searchContacts(value);
    const isDropdownOpen = useMemo(() => names.length > 0, [names]);

    return (
        <PageSection className="client-search">
            <ContentBox>
                <Draggable {...drop} withoutBorder>
                    <Title type={2} link="/clients">{t('advisoryDashboard.clientSearch.title')}</Title>
                </Draggable>
                <Preloader isLoading={isLoading} error={error}>
                    <SearchBar
                        data={names}
                        onSelect={onSelect}
                        onSearch={handleSearch}
                        placeholder={t('advisoryDashboard.clientSearch.enterName')}
                        dropdownClassName="client-search"
                        disableFilters
                        isDropdownOpen={isDropdownOpen}
                    />
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

ClientSearch.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            link: PropTypes.string,
        }),
    ),
    names: PropTypes.arrayOf(PropTypes.string),
    searchContacts: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    drop: PropTypes.shape({
        role: PropTypes.string,
        tabIndex: PropTypes.number,
        onDragStart: PropTypes.func,
    }),
};

ClientSearch.defaultProps = {
    data: [],
    names: [],
    searchContacts: () => [],
    isLoading: true,
    error: null,
    drop: null,
};

export default React.memo(ClientSearch);
