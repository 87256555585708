import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Security from 'additiv-services/dist/services/core/security';
import { setup } from 'additiv-services';
import { Loader } from 'ui-library';
import OIDCAuthenticationProvider from 'additiv-react-oidc-client';
import { isAuthenticatedSelector } from '../../redux/auth/authSelectors';
import history from '../../services/history';
import { getUser } from '../../redux/auth/authActions';
import config from '../../configs/idenityServerConfig';

interface AuthenticationProviderProps {
    children: React.ReactChild | React.ReactChild[] | null,
}

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
    const [isUserLoading, setUserLoading] = useState(false);
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(isAuthenticatedSelector);

    const getUserData = async (user) => {
        if (isAuthenticated) return;

        setUserLoading(true);
        await dispatch(getUser({
            profileId: user?.profile?.mid,
        }));

        history.push('/dashboard');
        setUserLoading(false);
    };

    const updateToken = (user) => {
        setup({
            bearerAccessToken: `Bearer ${user?.access_token}`,
        });
    };

    const onLogin = (user) => {
        updateToken(user);
        getUserData(user);
    };

    const onLogout = async () => {
        global.sessionStorage.removeItem('redux_sessionstorage_auth');
        try {
            await Security.invalidateAllAccessTokens();
        } catch (e) {
            console.log('Member access token has already expired', e);
        }
    };

    return (
        <OIDCAuthenticationProvider
            configuration={config}
            loadingComponent={<Loader />}
            onUserLoaded={onLogin}
            onLogout={onLogout}
        >
            {isUserLoading ? <Loader /> : children}
        </OIDCAuthenticationProvider>
    );
};

export default AuthenticationProvider;
