import React, { useMemo } from 'react';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    LineChart, Select, Title, ContentBox, Row, Column,
} from 'ui-library';

import { useFormatting } from 'locale';
import { lineChartLabels } from 'constants/constants';
import PageSection from '../PageSection/PageSection';
import EmptyContent from '../EmptyContent';
import Draggable from '../Draggable/Draggable';

function InvestmentPerformance({
    data,
    isLoading,
    error,
    onFilterChange,
    currentFilter,
    filters,
    title,
    chartValueFormat,
    yAxisFormat,
    currency,
    drop,
}) {
    const { t, i18n } = useTranslation();
    const titleComponent = useMemo(() => {
        if (drop) return (<Draggable {...drop}><Title type={2}>{title}</Title></Draggable>);

        return <Title type={2}>{title}</Title>;
    }, [drop, i18n.language]);

    const { getFormattedXAxisFormat } = useFormatting();

    return (
        <PageSection>
            <ContentBox underline={false}>
                <Row className="mb-0">
                    <Column size="8">{titleComponent}</Column>
                    <Column size="4">
                        <Select
                            onChange={onFilterChange}
                            options={filters}
                            width={160}
                            value={currentFilter}
                        />
                    </Column>
                </Row>
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent
                        data={data && data[0].data}
                        text={t('advisoryDashboard.investmentPerformance.tabsNotFound.overall')}
                    >
                        <LineChart
                            data={data}
                            height={252}
                            yTitle={currency.CurrencyCode}
                            yAxisFormat={yAxisFormat}
                            valueFormat={chartValueFormat}
                            tooltipFormat={data[0].tooltipFormat}
                            xAxisFormat={getFormattedXAxisFormat}
                            labels={lineChartLabels(t)}
                        />
                    </EmptyContent>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

InvestmentPerformance.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.arrayOf(
            [PropTypes.number, PropTypes.number],
        ),
        data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        tooltipFormat: PropTypes.func,
    })),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    onFilterChange: PropTypes.func.isRequired,
    currentFilter: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            [PropTypes.string]: PropTypes.any,
        }),
    ])).isRequired,
    title: PropTypes.string.isRequired,
    yAxisFormat: PropTypes.func,
    chartValueFormat: PropTypes.shape({
        symbol: PropTypes.string,
        precision: PropTypes.number,
        thousand: PropTypes.string,
        decimal: PropTypes.string,
        format: PropTypes.string,
    }).isRequired,
    currency: PropTypes.shape({
        Id: PropTypes.number,
        Name: PropTypes.string,
        Symbol: PropTypes.string,
        CurrencyCode: PropTypes.string,
    }),
    drop: PropTypes.shape({
        role: PropTypes.string,
        tabIndex: PropTypes.number,
        onDragStart: PropTypes.func,
    }),
};

InvestmentPerformance.defaultProps = {
    data: undefined,
    isLoading: true,
    error: null,
    yAxisFormat: null,
    currency: { CurrencyCode: '' },
    drop: null,
};

export default InvestmentPerformance;
