import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { formatDate, getTimestamp } from 'utils/datetime';
import ServerError from 'errors/ServerError';
import { useTranslation } from 'react-i18next';
import {
    ADVISORY, DEPOSITS, DISCRETIONARY, EXECUTION_ONLY, generateObject,
} from 'constants/portfolioProducts';
import { useEditCustomer, useSchema } from 'domain/ClientService';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { genarateDFSClientId } from 'utils';
import ServiceManager from 'services/ServiceManager';
import { setup } from 'additiv-services';
import { useInvestmentContract } from '../../../hooks/useInvestmentContract';
import { adaptCountries } from '../../../adapters/adaptCountries';
import { blobFileToBase64 } from '../utils';

const selectors = [
    'personalInformation.salutation',
    'personalInformation.gender',
    'personalInformation.firstName',
    'personalInformation.lastName',
    'personalInformation.middleName',
    'personalInformation.dateOfBirth',
    'personalInformation.identification.countryOfNationality',
    'personalInformation.identification.countryOfIssuance',
    'personalInformation.identification.documentType',
    'communicationMethods.primaryAddressCountry',
];

const salutationConverter = {
    Male: 'Herr',
    Female: 'Frau',
};

const idTypeConverter = {
    IdentityCard: 'ID_CARD',
    Passport: 'PASSPORT',
    'Identity Card': 'ID_CARD',
};

const FIDENTITY = 'fidentity';

const BENCHMARK_ID = 57;

export const useFIdentity = ({ contactId }) => {
    const [isFIdentityLoading, setFIdentityLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [emptyDataError, setEmptyDataError] = useState(null);
    const [countries, setCountries] = useState([]);
    const [document, setDocument] = useState('');

    const { i18n, t } = useTranslation();

    const {
        getCreatedGoal, ReviewData, getGoalChanges,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const goalChanges = useMemo(() => (!!Number.isInteger(+getGoalChanges())), [getGoalChanges]);

    // generate Investment Contract
    const createdGoal = useMemo(getCreatedGoal, [getCreatedGoal]);
    const showBenchmark = useMemo(() => generateObject({
        [ADVISORY]: true,
        [DISCRETIONARY]: true,
        [EXECUTION_ONLY]: false,
        [DEPOSITS]: true,
    }).getByName(createdGoal.ProductName), [createdGoal.ProductName]);

    const {
        benchmarkSelected, isLoadingBenchmark,
    } = useBenchmarks({
        productId: createdGoal.ProductId,
        currencyId: createdGoal.CurrencyId,
        benchmarkId: ReviewData?.benchmarkId || BENCHMARK_ID, // TODO: remove hardcoded id
    }, false, showBenchmark);

    const {
        data: dataIC, isLoading: isLoadingIC, error: errorIC, contractId, documentId,
    } = useInvestmentContract({
        contactId,
        details: createdGoal,
        benchmarkSelected,
        isStrategyChanged: goalChanges,
    });

    // Contact Details
    const {
        getContactDetails,
        error: errorContactDetails, isLoading: isLoadingContactDetails, contactDetails,
    } = useEditCustomer({ contactId });

    const {
        dataLists, errorLists, isLoadingLists, getSchemasLists,
    } = useSchema();

    useEffect(() => {
        getContactDetails({ data: { selectors } });
    }, [getContactDetails]);

    useEffect(() => {
        setLoading(true);

        ServiceManager.commonService('getCountries', [i18n.language]).then((response) => {
            try {
                setCountries(adaptCountries(response.data));
                setLoading(false);
            } catch (err) {
                throw new AdapterError(err);
            }
        })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, [i18n.language]);

    useEffect(() => {
        getSchemasLists();
    }, [getSchemasLists]);

    useEffect(() => {
        blobFileToBase64(dataIC).then((data) => setDocument(data.replace(/^data:application\/[a-z]+;base64,/, '')));
    }, [
        dataIC,
    ]);

    const submission = useMemo(() => contactDetails?.details, [contactDetails]);
    const personalInformation = submission?.personalInformation;
    const communicationMethods = submission?.communicationMethods;

    const getLabelFromList = useCallback((id) => (dataLists || window.additiv?.FormIoCommonDataSource?.PureList?.items)?.find((i) => i.id === +id)?.label || '', [dataLists]);
    const getCountryCode = useCallback((country) => countries?.find((i) => i.name === country)?.threeLetterIsoCode || '', [countries]);

    const onFIdentity = useCallback(async () => {
        try {
            setError(null);
            setFIdentityLoading(true);

            const accessTokenResp = await ServiceManager.Security('getAccessTokenForExtension', [{ payload: { type: FIDENTITY } }]);

            const bearerFIdentityAccessToken = `Bearer ${accessTokenResp?.data?.accessToken}`;

            setup({ bearerFIdentityAccessToken });

            let params = {};
            const extId = `${personalInformation?.firstName}.${personalInformation?.lastName}.${getTimestamp()}`;
            const documentName = `${t('onBoarding.investmentContract')} - ${personalInformation?.firstName} ${personalInformation?.lastName} - ${formatDate(new Date())}`;

            try {
                params = {
                    extId,
                    lang: i18n.language,
                    customer: {
                        title: salutationConverter[getLabelFromList(personalInformation?.gender)],
                        name: personalInformation?.lastName,
                        firstname: personalInformation?.firstName,
                        middlename: personalInformation?.middleName,
                        nationality: getCountryCode(
                            getLabelFromList(
                                personalInformation?.identification?.[0]?.countryOfNationality,
                            ),
                        ),
                        dateOfBirth: formatDate(personalInformation?.birthDate),
                        idType: idTypeConverter[getLabelFromList(personalInformation
                            ?.identification?.[0]?.documentType)],
                        idIssuingCountry: getCountryCode(
                            getLabelFromList(
                                personalInformation?.identification?.[0]?.countryOfIssuance,
                            ),
                        ),
                        countryOfResidence: getCountryCode(
                            getLabelFromList(
                                communicationMethods?.primaryAddressCountry,
                            ),
                        ),
                    },
                    case: 'onlineid',
                    processSteps: [{ type: 'DocumentRegistration' }, { type: 'Signature' }],
                    noValidation: true,
                    documentUris: [{
                        id: contractId,
                        type: 'signature',
                        data: document,
                        title: documentName,
                        signature_position: {
                            x: 300,
                            y: 300,
                            page: 1,
                        },
                    }],
                    systemUrls: [
                        {
                            url: `${window.location.origin}/onboarding/${genarateDFSClientId(contactId)}/step/7/success?fidentityUserId=${extId}&documentName=${documentName}&documentId=${documentId}`,
                            type: 'redirect',
                            state: 'success',
                        },
                        {
                            url: `${window.location.origin}/onboarding/${genarateDFSClientId(contactId)}/step/7/error`,
                            type: 'redirect',
                            state: 'error',
                        },
                    ],
                };
            } catch (err) {
                throw new AdapterError(err);
            }

            const isEmptyData = Object.keys(Object.fromEntries(
                Object.entries({ ...params.customer, ...params.documentUris[0] })
                    .filter((item) => item[0] !== 'middlename' && !item[1]),
            ));

            if (isEmptyData.length > 0) {
                setEmptyDataError({ message: `${t('onBoarding.missingDataError')}: ${isEmptyData.map((item) => item).join(', ')}` });

                setFIdentityLoading(false);

                return null;
            }

            const response = await ServiceManager.fIdentity('postFidentity', [params]);

            setFIdentityLoading(false);

            return response.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading: setFIdentityLoading }),
            )(err);
            throw err.type !== undefined ? err : new ServerError(err);
        }
    },
    [personalInformation,
        communicationMethods, contactId,
        getLabelFromList, getCountryCode,
        document, contractId, t]);

    return {
        error: error || errorContactDetails || errorIC || errorLists,
        isLoading: isLoading
            || isLoadingContactDetails
            || isLoadingBenchmark
            || isLoadingIC
            || isLoadingLists,
        isFIdentityLoading,
        onFIdentity,
        emptyDataError,
    };
};
