import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    BreadCrumbs, Table, ContentBox, Title, Button, Infobox, Modal,
} from 'ui-library';
import history from 'services/history';
import { getClientId } from 'utils';
import PageSection from 'components/PageSection';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import {
    orderBookSelector, portfolioSelector, usePortfolioSelector, tradingCardSelector,
    portfolioModifySelector,
} from 'domain/Portfolio';
import RiskBandwidthDisclaimer from 'components/RiskBandwidthDisclaimer/RiskBandwidthDisclaimer';
import { getModifyColumns } from '../constants';
import { MODIFY } from '../../../constants';
import { onPreventRedirect } from '../../common/utils';
import { useCheckRiskProfile } from '../../../hooks/useCheckRiskProfile';
import { useModify } from '../hooks/useModify';

function ModifyPortfolioDiscretionary(props) {
    const { match: { params: { dfsClientId, portfolioId } } } = props;
    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();
    const [isLoadingModify, setIsLoading] = useState(true);
    const [noChangesError, setNoChangesError] = useState(false);
    const [negativeCashAmount, setNegativeCashAmount] = useState(false);

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        deleteTradingCard, isDeleting, errorDeleting,
    } = usePortfolioSelector(tradingCardSelector);
    const {
        dataRaw: dataTradingCard, isLoading: isLoadingTradingCard, error: errorTradingCard,
        getModifiedPositions,
        risk, isLoadingRisk, getRisk,
    } = usePortfolioSelector(portfolioModifySelector);
    const {
        data: orderBook, isLoading: isLoadingOrderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    useEffect(() => {
        getOrderBook().then(() => getModifiedPositions()).then(() => {
            setIsLoading(false);
        });
    }, [getOrderBook, getModifiedPositions]);

    // Hooks
    const {
        compareVolatility, clientRiskBandwidth,
    } = useCheckRiskProfile(clientId, data.productId);
    const { riskCategoryId, riskCategoryName } = useMemo(() => ({
        riskCategoryId: dataRaw?.RiskCategory?.Id,
        riskCategoryName: dataRaw?.RiskCategory?.Name,
    }), [dataRaw?.RiskCategory]);

    const baseUrl = `/client/${dfsClientId}/portfolios/${portfolioId}/modify/position/${data?.productId}`;
    const { modifiedData } = useModify({
        portfolio: data,
        portfolioPositions: isLoadingModify ? [] : dataRaw?.Positions,
        positionsModified: dataTradingCard?.OrderBookEntries,
        baseUrl,
    });

    useEffect(() => {
        if (data?.currencyId && modifiedData?.positionsAllocation?.length > 0) {
            getRisk({
                currencyId: data?.currencyId,
                positions: modifiedData?.positionsAllocation,
            });
        }
    }, [data.currencyId, getRisk, modifiedData.positionsAllocation]);

    // Risk Profile Check
    const [showRiskDisclaimer, setShowRiskDisclaimer] = useState(false);

    useEffect(() => {
        setShowRiskDisclaimer(false);

        if (risk?.Volatility) {
            compareVolatility(risk.Volatility, riskCategoryId).then((passed) => {
                setShowRiskDisclaimer(!passed);
            });
        }
    }, [compareVolatility, risk.Volatility, riskCategoryId]);

    useEffect(() => {
        if (!modifiedData?.isPositiveCashAmount) {
            setNegativeCashAmount(true);
        }
    }, [modifiedData?.isPositiveCashAmount, setNegativeCashAmount]);

    // Callbacks
    const onAddPosition = () => {
        history.push(
            `/client/${dfsClientId}/portfolios/${portfolioId}/modify/add-position`,
            {
                portfolioName: data.title,
                productId: data.productId,
            },
        );
    };
    const onContinue = () => {
        if (dataTradingCard?.OrderBookEntries?.length === 0) {
            setNoChangesError(true);

            return;
        }
        if (!modifiedData?.isPositiveCashAmount) {
            return;
        }

        sessionStorage.setItem(MODIFY, JSON.stringify({
            positions: modifiedData.positionsAllocation,
        }));
        history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/modify/confirmation`);
    };
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                sessionStorage.removeItem(MODIFY);
                deleteTradingCard(clientId, portfolioId);
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };

    // Renderers
    const disableSubmitButton = orderBook || isLoadingRisk;
    const isLoadingCommon = isLoading || isLoadingModify
        || isLoadingTradingCard || isDeleting || isLoadingOrderBook;
    const renderRiskDisclaimer = () => (
        <RiskBandwidthDisclaimer
            risk={risk?.Volatility}
            min={clientRiskBandwidth?.Min}
            max={clientRiskBandwidth?.Max}
            CRP={riskCategoryName}
        />
    );

    return (
        <PageSection className="modify-portfolio">
            <Preloader
                isLoading={isLoadingCommon}
                error={error || errorTradingCard || errorDeleting || errorOrderBook}
            >
                <ContentBox underline={false}>
                    <BreadCrumbs current={t('clientDashboard.portfolioEdit.title')}>
                        <Link
                            to={`/client/${dfsClientId}/portfolios`}
                            onClick={
                                (e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/`, onCancel)
                            }
                        >
                            {t('clientDashboard.portfolioEdit.portfolios')}
                        </Link>
                        <Link
                            to={`/client/${dfsClientId}/portfolios/${portfolioId}`}
                            onClick={
                                (e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/${portfolioId}`, onCancel)
                            }
                        >
                            {data.title}
                        </Link>
                    </BreadCrumbs>
                    <div className="modify-portfolio__header">
                        <Title type={1}>{t('clientDashboard.portfolioEdit.title')}</Title>
                        <Button type="secondary" size="small" onClick={onAddPosition}>
                            {t('clientDashboard.portfolioEdit.addPosition')}
                        </Button>
                    </div>
                    {showRiskDisclaimer && renderRiskDisclaimer()}
                    <EmptyContent data={data.positions} text={t('clientDashboard.portfolioEdit.noDataFound')}>
                        <div className="content-wrapper">
                            <Table
                                columns={getModifyColumns(t)}
                                data={modifiedData.positions}
                                expandedColumn="Name"
                                defaultExpandAllRows
                                headerControls={{
                                    selectControlsLabel: {
                                        selectAll: t('headerControls.selectAll'),
                                        reset: t('headerControls.reset'),
                                    },
                                }}
                            />
                        </div>
                        <ButtonsBlockRow
                            leftButton={{
                                type: 'danger',
                                text: t('clientDashboard.portfolioEdit.cancel'),
                                onClick: () => onCancel(`/client/${dfsClientId}/portfolios/${portfolioId}`),
                            }}
                            primaryButton={{
                                text: t('clientDashboard.portfolioEdit.continue'),
                                disabled: disableSubmitButton,
                                onClick: onContinue,
                            }}
                        >
                            {negativeCashAmount && (<Infobox error accent>{t('clientDashboard.portfolioEdit.infoTextModify')}</Infobox>)}
                            {noChangesError && (<Infobox error accent>{t('clientDashboard.portfolioModify.noChangesError')}</Infobox>)}
                        </ButtonsBlockRow>
                    </EmptyContent>
                </ContentBox>
            </Preloader>
        </PageSection>
    );
}

ModifyPortfolioDiscretionary.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

ModifyPortfolioDiscretionary.defaultProps = {};

export default ModifyPortfolioDiscretionary;
