import React from 'react';
import { useClientLayout } from '../../../hooks/useClientLayout';

const withClientLayout = (WrappedComponent) => (props) => {
    useClientLayout();

    return <WrappedComponent {...props} />;
};

export default withClientLayout;
