import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, Column, FileInput, Row, Title,
} from 'ui-library';
import Avatar from 'ui-library/dist/components/Avatar';
import FieldControl from 'components/FieldControl/FieldControl';
import useFormatting from 'locale/useFormatting';
import PropTypes from 'prop-types';
import { NA } from 'utils/formatting';

export const EditForm = (props) => {
    const {
        data, onPictureChange, profilePicture, setUploadParams, menuRefs,
    } = props;
    const { getFormattedPhoneNumber } = useFormatting();
    const { t } = useTranslation();

    return (
        <div className="form-content">
            <div className="edit-profile-section" ref={menuRefs['personal-information']}>
                <Title type={3}>{t('editProfile.personalInformation')}</Title>
                <Row>
                    <Column size="6">
                        <FieldControl
                            label={t('editProfile.firstName')}
                            value={data?.firstName}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column size="6">
                        <FieldControl
                            label={t('editProfile.lastName')}
                            value={data?.lastName}
                        />

                    </Column>
                </Row>
                <Row>
                    <Column size="8">
                        <FieldControl
                            label={t('editProfile.profilePicture')}
                            value={profilePicture}
                            renderValue={(image) => (
                                <Avatar
                                    name={data?.firstName}
                                    src={image}
                                    size="large"
                                />
                            )}
                        />
                        <div className="upload-buttons">
                            <FileInput
                                accept=".jpg, .png, .svg, .bmp, .tiff, .jpeg"
                                uploadText={t('editProfile.upload')}
                                onChange={onPictureChange}
                                buttonType="primary"
                                showFileList={false}
                            />
                            <Button
                                type="danger"
                                size="small"
                                onClick={() => setUploadParams({ ImageContentBase64: '' })}
                            >
                                {t('editProfile.delete')}
                            </Button>
                        </div>
                    </Column>
                </Row>
            </div>
            <div className="edit-profile-section" ref={menuRefs['business-information']}>
                <Title type={3}>{t('editProfile.businessInformation')}</Title>
                <Row>
                    <Column size="6">
                        <FieldControl
                            label={t('editProfile.email')}
                            value={data?.email}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column size="6">
                        <FieldControl
                            label={t('editProfile.phone')}
                            value={getFormattedPhoneNumber(data?.phone)}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column size="6">
                        <FieldControl
                            label={t('editProfile.mobile')}
                            value={getFormattedPhoneNumber(data?.mobile)}
                        />
                    </Column>
                </Row>
            </div>
            <div className="edit-profile-section" ref={menuRefs['system-information']}>
                <Title type={3}>{t('editProfile.systemInformation')}</Title>
                <Row>
                    <Column size="6">
                        <FieldControl
                            label={t('editProfile.bankBranch')}
                            value={data?.bankBranch}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column size="7">
                        <FieldControl
                            label={t('editProfile.internalId')}
                            value={(
                                <input
                                    readOnly
                                    tabIndex="-1"
                                    className="internalIdField"
                                    value={data?.internalId}
                                />
                            )}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column size="6">
                        <FieldControl
                            label={t('editProfile.superior')}
                            value={data?.superiorNames}
                            renderValue={(arr) => (arr?.length > 0
                                ? arr?.map((i) => <div>{i}</div>) : NA)}
                        />
                    </Column>
                </Row>
            </div>
        </div>
    );
};

EditForm.propTypes = {
    data: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        superiorIds: PropTypes.arrayOf(PropTypes.number),
        internalId: PropTypes.number,
        bankBranch: PropTypes.string,
        mobile: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        superiorNames: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    profilePicture: PropTypes.string.isRequired,
    onPictureChange: PropTypes.func.isRequired,
    setUploadParams: PropTypes.func.isRequired,
    menuRefs: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ])).isRequired,
};
