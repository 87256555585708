import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptQuestionnaire } from '../adapters/adaptQuestionnaire';

export const useQuestionnaires = () => {
    const { i18n, t } = useTranslation();
    const [data, setData] = useState({
        questions: { Groups: [{ Steps: [{ Questions: [{}] }] }] },
        results: [],
    });
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setError(null);
        setLoading(true);

        ServiceManager.compliance('filterQuestionnaires', [{ Language: i18n.language }])
            .then((response) => {
                try {
                    setData(adaptQuestionnaire(response.data, t));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, [i18n.language]);

    return {
        data, isLoading, error,
    };
};
