import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChangeStrategyRouter from './pages/ChangeStrategy/Router';
import ChangeModelRouter from './pages/ChangeModel/Router';
import ModifyPortfolioRouter from './pages/ModifyPortfolio/Router';
import QuickEdit from './pages/QuickEdit/Router';
import PositionDetailsOverview from './pages/PositionDetails/PositionDetailsOverview';
import RebalanceRouter from './pages/Rebalance/Router';
import { usePortfolioActionsLayout } from './hooks/usePortfolioActionsLayout';
import PositionDetails from './pages/PositionDetails/PositionDetails';

const PortfolioActionsRouter = (props) => {
    usePortfolioActionsLayout();
    const { match: { path } } = props;
    const withProps = (Component) => (defProps) => <Component {...props} {...defProps} />;

    return (
        <Switch>
            <Route
                exact
                path={`${path}/quick-edit/position/:productId/:positionId`}
                component={PositionDetailsOverview}
            />
            <Route
                exact
                path={`${path}/quick-edit/position/:productId/:positionId/:tabId`}
                component={PositionDetailsOverview}
            />
            <Route
                exact
                path={`${path}/change-strategy/position/:productId/:positionId/:tabId`}
                component={PositionDetailsOverview}
            />
            <Route
                exact
                path={`${path}/change-model/position/:productId/:positionId/:tabId`}
                component={PositionDetailsOverview}
            />
            <Route
                exact
                path={`${path}/rebalance/position/:productId/:positionId/:tabId`}
                component={PositionDetailsOverview}
            />
            <Route
                exact
                path={`${path}/modify/position/:productId/:positionId/:tabId`}
                component={PositionDetailsOverview}
            />
            <Route
                exact
                path={`${path}/modify/position/:productId/:positionId/:tabId/overview`}
                component={PositionDetailsOverview}
            />
            <Route
                exact
                path={`${path}/modify/position/:productId/:positionId/:tabId/:extraTabId`}
                render={withProps(PositionDetails)}
            />
            <Route path={`${path}/modify`} render={withProps(ModifyPortfolioRouter)} />
            <Route path={`${path}/quick-edit`} render={withProps(QuickEdit)} />
            <Route path={`${path}/change-strategy`} render={withProps(ChangeStrategyRouter)} />
            <Route path={`${path}/change-model`} render={withProps(ChangeModelRouter)} />
            <Route path={`${path}/rebalance`} render={withProps(RebalanceRouter)} />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

PortfolioActionsRouter.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

PortfolioActionsRouter.defaultProps = {};

export default PortfolioActionsRouter;
