import React from 'react';
import PageSection from 'components/PageSection/PageSection';
import { withMainLayout } from 'hocs';
import Tasks from 'components/Tasks';
import { useTasks } from 'hooks/useTasks';
import { useTickets } from 'hooks/useTickets';
import { useOverviewClients } from 'hooks/useOverviewClients';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';

import './Tasks.css';

function TasksPage() {
    useCancelAllRequests();

    const tickets = useTickets();
    const contacts = useOverviewClients();
    const tasks = useTasks(tickets, contacts);

    return (
        <PageSection className="tasks-page">
            <Tasks {...tasks} fullPage />
        </PageSection>
    );
}

TasksPage.propTypes = {
};

TasksPage.defaultProps = {
};

export default withMainLayout(TasksPage);
