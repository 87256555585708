import { getfromDataObject, validateNumber } from 'utils/formatting';
import { roundAllocations } from 'utils';
import {
    BAR, LIST, PIE, PIE_SA,
} from 'constants/constants';
import { adaptAllocationGroups, buildAllocationGroup } from 'adaptors/adaptAllocationGroups';
import { adaptPositions } from './adaptPositions';

export const getAllocations = (data) => {
    const roundedAllocations = data?.length ? roundAllocations(data, 1000, 1000) : [];
    let allocations = [];

    allocations = roundedAllocations.map(({ id, value }) => ({
        Allocation: Number(value),
        InstrumentId: id,
        Notes: [],
        IsNotAdvised: false,
    })).filter(({ Allocation }) => Allocation !== 0);

    return allocations;
};

export const adaptProjection = ({
    data, CurrencyId, ProjectionYears, InitialInvestmentAmount, MonthlyContributionAmount,
}) => {
    const roundedAllocation = data?.length ? roundAllocations(data, 1000, 1000) : [];
    const InstrumentAllocations = roundedAllocation.map(({ id, value }) => ({
        Allocation: Number(value),
        InstrumentId: id,
    })).filter(({ Allocation }) => Allocation !== 0);

    return {
        CurrencyId,
        ProjectionYears,
        InstrumentAllocations,
        InitialInvestmentAmount,
        MonthlyContributionAmount,
    };
};

export const adaptModelPortfolio = (data, {
    productId,
    productName,
    currency: currencyInit,
    portfolioValue,
    t,
    projectionYears,
    getFormattedDate,
    getFormattedNumber,
    getFormattedCurrency,
    nameLength,
    bandwidthTitle,
    riskReturnTitle,
    performanceTitle,
    positionLink,
    comparedAllocations,
    recurringPayment,
}) => {
    const currency = currencyInit?.label;
    const currencyId = currencyInit?.value;
    let positions = [];

    if (data && data.Positions) {
        positions = data.Positions;
    } else if (data && data.ConsolidatedPositions) {
        positions = data.ConsolidatedPositions;
    }

    return {
        isProposed: true,
        modelPortfolioId: data?.Id,
        benchmarkId: data?.BenchmarkId,
        backTitle: getfromDataObject(data, 'Name'),
        title: getfromDataObject(data, 'Name'),
        currency,
        currencyId,
        overview: {
            portfolioValue,
            currency,
            strategy: getfromDataObject(data, 'RiskCategory.Name'),
            product: productName,
            performance: '- -',
            sharpRatio: data?.SharpeRatio,
            expectedReturn: data?.CalculatedExpectedReturn * 100,
            expectedRisk: data?.CalculatedRisk * 100,
            firstInvestment: getFormattedDate(data?.FirstInvestmentDate),
            modelPortfolio: data?.Name,
        },
        constraints: data?.OptimizationConstraints || {},
        investmentAllocation: {
            chart: adaptAllocationGroups({
                securitiesValue: portfolioValue,
                comparedAllocations,
                chartTypesList: [PIE, PIE_SA, BAR, LIST],
                positions,
                currency,
                t,
                getFormattedNumber,
                getFormattedCurrency,
            }),
            currency,
        },
        investmentAllocations: getAllocations(data?.Positions),
        productId,
        productName,
        projection: adaptProjection({
            data: positions,
            CurrencyId: currencyId,
            ProjectionYears: projectionYears || 5,
            InitialInvestmentAmount: portfolioValue,
            MonthlyContributionAmount: recurringPayment,
        }),
        positionsRaw: data?.Positions,
        positions: adaptPositions(data?.Positions, {
            portfolioValue,
            portfolioCurrency: currency,
            nameLength,
            isNew: false,
            positionLink,
            getFormattedNumber,
            getFormattedCurrency,
            t,
        }),
        portfolioValue,
        positionIDS: data?.Positions?.map((i) => i?.Security?.Id),
        riskReturn: [+(data?.CalculatedRisk * 100), +(data?.CalculatedExpectedReturn * 100)],
        riskReturnMatrix: {
            x: [validateNumber(data?.RiskCategory?.Min) * 100,
                validateNumber(data?.RiskCategory?.Max) * 100],
        },
        bandwidthTitle: bandwidthTitle || t('confirmation.current'),
        riskReturnTitle: riskReturnTitle || t('onBoarding.suggested'),
        performanceTitle,
        allocationGroups: buildAllocationGroup(
            positions, currency, t, data?.SecuritiesValue,
        ),
        optimizationConstraints: data?.OptimizationConstraints,
        riskCategory: getfromDataObject(data, 'RiskCategory.Id'),
        riskCategoryName: getfromDataObject(data, 'RiskCategory.Name'),
    };
};
