import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    Button, Column, FormGroup, Infobox, Label, Modal,
    NumberInput, RadioButtonGroup, Row, Select, DatePicker,
} from 'ui-library';
import history from 'services/history';
import { Controller } from 'react-hook-form';
import { getDatesDifference, getDateFormat } from 'utils/datetime';
import Preloader from 'components/Preloader';
import PageSection from 'components/PageSection';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import { checkNullData } from 'utils';
import { formatBigNumber, formatCurrencyWithPrecision } from 'utils/formatting';
import { calcRoundingLot } from 'utils/priceCalculation';
import { useLocale } from 'locale';
import { datePickerLocale } from 'constants/constants';
import { useAccounts } from 'hooks/useAccounts';
import { useExchangeRates } from '../hooks/useExchangeRates';
import { useTradeCardForm } from '../hooks/useTradeCardForm';
import { useAcceptTradeCard } from '../hooks/useAcceptTradeCard';
import {
    orderTypeOptions, orderValues, validityOptions,
    BUY, SELL, MARKET, LIMIT, STOP, GOOD_TILL_DATE,
} from '../constants';
import { useModify } from '../../ModifyPortfolio/hooks/useModify';

const TradeCard = ({
    clientId, portfolioId, dfsClientId, positionId, quantity,
    currency, portfolioCurrency, price, tradingUnit, roundingLot,
    defaultData, portfolioName, productId,
}) => {
    const { t } = useTranslation();
    const { numberSeparators, locale } = useLocale();
    const today = new Date();
    const isInPortfolio = useMemo(() => quantity > 0 || !checkNullData(defaultData),
        [quantity, defaultData]);
    const precision = useMemo(() => (roundingLot > 0
        ? calcRoundingLot(roundingLot) : 0), [roundingLot]);
    const roundedQuantity = useMemo(
        () => Math.floor(quantity * (10 ** precision)) / (10 ** precision),
        [quantity, precision],
    );
    const { getModifiedByIdLocally, saveModifiedLocally } = useModify();

    const orderValuesList = orderValues(t, quantity, defaultData);
    const modificationsValuesList = [
        { label: t('position.clientRequest'), value: 1 },
        { label: t('position.advisorRecommendation'), value: 0 },
    ];
    const {
        exchangeRate, isLoadingExchangeRate, errorExchangeRate,
    } = useExchangeRates(currency, portfolioCurrency);
    const {
        data, isLoading, error,
    } = useAccounts(clientId, portfolioId);
    const [showModal, setShowModal] = useState(false);

    const defaultDataForm = useMemo(() => ({ orderType: MARKET, order: BUY, ...defaultData }),
        [defaultData]);
    const {
        register, control, values, formErrors, onChange, handleSubmit,
    } = useTradeCardForm({ defaultData: defaultDataForm, price, tradingUnit });

    const backLink = `/client/${dfsClientId}/portfolios/${portfolioId}/modify`;
    const {
        onAccept, isAccepting, errorAccepting,
    } = useAcceptTradeCard(backLink);

    const formatter = (value) => {
        const parsedValue = value ? parseFloat(value.replace(',', '')) : value;

        if (parsedValue === 0) {
            return value;
        }
        if (values.order === SELL && parsedValue > quantity) {
            return roundedQuantity;
        }
        if (value === '') {
            return undefined;
        }

        return value;
    };

    // Callbacks
    const onContinue = (params) => {
        saveModifiedLocally({ id: positionId, IsNotAdvised: !!values.modificationType });
        onAccept(clientId, portfolioId, dfsClientId, {
            positionId,
            ...params,
        });
    };

    const onOk = () => {
        setShowModal(false);
        if (isInPortfolio) {
            history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/modify`);
        } else {
            history.push(
                `/client/${dfsClientId}/portfolios/${portfolioId}/modify/add-position`,
                { portfolioName, productId },
            );
        }
    };

    const onSetMaxQuantity = () => {
        onChange('tradeQuantity')(roundedQuantity);
        onChange('tradeQuantity')(roundedQuantity);
    };

    // Renderers
    const renderInfobox = () => (
        (values.orderType)
            ? (
                <Infobox accent>
                    {values.order === BUY && values.orderType === MARKET && t('position.infoboxTexts.0')}
                    {values.order === BUY && values.orderType === LIMIT && t('position.infoboxTexts.1')}
                    {values.order === BUY && values.orderType === STOP && t('position.infoboxTexts.3')}
                    {values.order === SELL && values.orderType === MARKET && t('position.infoboxTexts.4')}
                    {values.order === SELL && values.orderType === LIMIT && t('position.infoboxTexts.5')}
                    {values.order === SELL && values.orderType === STOP && t('position.infoboxTexts.7')}
                </Infobox>
            )
            : ''
    );

    const renderOrderTypeInfobox = () => {
        const tradeQuantityVal = Number(values.tradeQuantity)
            ? price * values.tradeQuantity : 0;
        const limitVal = Number(values.limit)
        && values.tradeQuantity
            ? values.tradeQuantity * values.limit : 0;
        const stopVal = Number(values.stop)
        && values.tradeQuantity
            ? values.tradeQuantity * values.stop : 0;

        return (
            <>
                {
                    values.orderType
                    && (
                        <Infobox accent>
                            <div className="title-3">
                                {t('position.approximateTradingValue')}
                            </div>
                            <div>
                                {` ${t('position.infoboxOrderTypeTexts.0')} `}
                                <b>{`${formatCurrencyWithPrecision(tradeQuantityVal, 2, portfolioCurrency)}`}</b>
                                {
                                    portfolioCurrency !== currency
                                    && ` (${formatCurrencyWithPrecision(tradeQuantityVal * exchangeRate, 2, currency)})`
                                }
                            </div>
                            {
                                [LIMIT, STOP].includes(values.orderType)
                                && (
                                    <div>
                                        {` ${t('position.infoboxOrderTypeTexts.1')} `}
                                        <b>{`${formatCurrencyWithPrecision(limitVal, 2, portfolioCurrency)}`}</b>
                                        {
                                            portfolioCurrency !== currency
                                            && ` (${formatCurrencyWithPrecision(limitVal * exchangeRate, 2, currency)})`
                                        }
                                    </div>
                                )
                            }
                            {
                                values.orderType === STOP
                                && (
                                    <div>
                                        {` ${t('position.infoboxOrderTypeTexts.2')} `}
                                        <b>{`${formatCurrencyWithPrecision(stopVal, 2, portfolioCurrency)}`}</b>
                                        {
                                            portfolioCurrency !== currency
                                            && ` (${formatCurrencyWithPrecision(stopVal * exchangeRate, 2, currency)})`
                                        }
                                    </div>
                                )
                            }
                        </Infobox>
                    )
                }
            </>
        );
    };

    const renderAvailablePriceInfobox = () => (
        <>
            {t('position.currentTradingPriceIs')}
            &nbsp;
            { formatCurrencyWithPrecision(price, 2, portfolioCurrency)}
        </>
    );

    const renderQuantityInfobox = () => (
        <>
            {formatBigNumber(roundedQuantity, precision)}
            &nbsp;
            {t('position.availableQuantityInfoText')}
        </>
    );

    return (
        <PageSection>
            <Modal
                title={t('position.abortOrder')}
                visible={showModal}
                okText={t('position.abortOrder')}
                cancelText={t('position.cancel')}
                onOk={() => onOk()}
                onCancel={() => setShowModal(false)}
            >
                {t('position.abortOrderText')}
            </Modal>
            <div className="trade">
                <Preloader
                    isLoading={isLoading || isLoadingExchangeRate || isAccepting}
                    error={error || errorExchangeRate || errorAccepting}
                >
                    <Row>
                        <Column size="3">
                            <FormGroup>
                                <Label
                                    htmlFor={positionId}
                                    label={t('position.order')}
                                />
                                <Controller
                                    name="order"
                                    control={control}
                                    defaultValue={orderValuesList[0].value}
                                    render={({ value }) => (
                                        <RadioButtonGroup
                                            id={positionId}
                                            ref={register}
                                            name="order"
                                            value={value}
                                            error={formErrors.order}
                                            defaultValue={orderValuesList[0].value}
                                            options={orderValuesList}
                                            onChange={(e) => onChange('order')(e.target.value)}
                                            horizontal
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Column>
                    </Row>
                    <Row>
                        <Column size="12">
                            <FormGroup>
                                <Label
                                    htmlFor={positionId}
                                    label={t('position.modificationDueTo')}
                                />
                                <Controller
                                    name="modificationType"
                                    control={control}
                                    defaultValue={+getModifiedByIdLocally(positionId)?.IsNotAdvised
                                        || 0}
                                    render={({ value }) => (
                                        <RadioButtonGroup
                                            id="modificationType"
                                            value={value}
                                            error={formErrors.modificationType}
                                            options={modificationsValuesList}
                                            onChange={(e) => onChange('modificationType')(+e.target.value)}
                                            horizontal
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Column>
                    </Row>
                    <Row className="bottom-row">
                        <Column size={['lg-6', '8']} className="main-form">
                            <Row>
                                <Column size="6">
                                    <Controller
                                        control={control}
                                        name="orderType"
                                        render={() => (
                                            <Select
                                                ref={register}
                                                name="orderType"
                                                placeholder={t('position.pleaseSelect')}
                                                label={t('position.orderType')}
                                                value={values.orderType}
                                                onChange={onChange('orderType')}
                                                options={orderTypeOptions(t)}
                                                withInfobox={false}
                                                error={formErrors.orderType}
                                                required
                                            />
                                        )}
                                    />
                                </Column>
                                <Column size="6" className="quantity">
                                    <Controller
                                        name="tradeQuantity"
                                        control={control}
                                        render={({ value }) => (
                                            <NumberInput
                                                name="tradeQuantity"
                                                placeholder={t('position.quantity')}
                                                addonAfter={
                                                    quantity > 0 && values.order === SELL
                                                        ? (
                                                            <Button type="link" size="small" onClick={onSetMaxQuantity}>
                                                                {t('position.max')}
                                                            </Button>
                                                        )
                                                        : ''
                                                }
                                                label={t('position.quantity')}
                                                min={0}
                                                max={values.order === SELL ? quantity : undefined}
                                                precision={precision}
                                                onChange={(val) => onChange('tradeQuantity')(formatter(val))}
                                                {...numberSeparators}
                                                formatter={formatter}
                                                value={value}
                                                addonAfterBorder={false}
                                                error={formErrors.tradeQuantity}
                                                required
                                            >
                                                {values.order === SELL ? renderQuantityInfobox() : `${t('position.minimalTradingQuantityIs')} ${tradingUnit}`}
                                            </NumberInput>
                                        )}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column size="6" className="limitPrice">
                                    <Controller
                                        name="limit"
                                        control={control}
                                        render={({ value }) => (
                                            <NumberInput
                                                name="limit"
                                                addonAfter={portfolioCurrency}
                                                label={t('position.limitPrice')}
                                                disabled={![LIMIT, STOP].includes(values.orderType)}
                                                onChange={(v) => {
                                                    onChange('limit')(v === '' ? undefined : v);
                                                }}
                                                value={value || ''}
                                                error={[LIMIT, STOP].includes(values.orderType)
                                                && formErrors.limit}
                                                helpText={t(`position.helpText.limit${values.order}`)}
                                                labelInfobox
                                                {...numberSeparators}
                                            >
                                                {renderAvailablePriceInfobox()}
                                            </NumberInput>
                                        )}
                                    />
                                </Column>
                                <Column size="6" className="stopPrice">
                                    <Controller
                                        name="stop"
                                        control={control}
                                        render={({ value }) => (
                                            <NumberInput
                                                ref={register}
                                                name="stop"
                                                addonAfter={portfolioCurrency}
                                                label={t('position.stopPrice')}
                                                disabled={values.orderType !== STOP}
                                                value={value || ''}
                                                error={values.orderType === STOP
                                                && formErrors.stop}
                                                onChange={(v) => {
                                                    onChange('stop')(v === '' ? undefined : v);
                                                }}
                                                helpText={t(`position.helpText.stop${values.order}`)}
                                                labelInfobox
                                                {...numberSeparators}
                                            >
                                                {renderAvailablePriceInfobox()}
                                            </NumberInput>
                                        )}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column size="12">
                                    <Controller
                                        control={control}
                                        name="fundingAccount"
                                        render={() => (
                                            <Select
                                                ref={register}
                                                name="fundingAccount"
                                                placeholder={t('position.pleaseSelect')}
                                                value={values.fundingAccount}
                                                options={data}
                                                onChange={onChange('fundingAccount')}
                                                label={t('position.fundingAccount')}
                                                withInfobox={false}
                                                error={formErrors.fundingAccount}
                                                required
                                            />
                                        )}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column size="6" className="validity">
                                    <Controller
                                        control={control}
                                        name="validity"
                                        render={() => (
                                            <Select
                                                ref={register}
                                                name="validity"
                                                placeholder={t('position.pleaseSelect')}
                                                value={values.validity}
                                                options={validityOptions(t)}
                                                onChange={onChange('validity')}
                                                label={t('position.validity')}
                                                withInfobox={false}
                                                error={formErrors.validity}
                                                required
                                            />
                                        )}
                                    />
                                </Column>
                                <Column size="6">
                                    {
                                        values.validity === GOOD_TILL_DATE
                                        && (
                                            <Controller
                                                name="validityDate"
                                                control={control}
                                                render={({ value }) => (
                                                    <DatePicker
                                                        name="validityDate"
                                                        value={value}
                                                        popupPosition="top"
                                                        disabledDate={(curr) => curr
                                                            && getDatesDifference(curr, today) <= 0}
                                                        onChange={onChange('validityDate')}
                                                        label={t('position.date')}
                                                        withInfobox={false}
                                                        format={getDateFormat(locale)}
                                                        labels={datePickerLocale(t, locale)}
                                                        error={formErrors.validityDate}
                                                    />
                                                )}
                                            />
                                        )
                                    }
                                </Column>
                            </Row>
                        </Column>
                        <Column size={['lg-6', '4']}>
                            {renderInfobox()}
                        </Column>
                    </Row>
                </Preloader>
                {renderOrderTypeInfobox()}
                <ButtonsBlockRow
                    additionalButton={{
                        text: t('position.cancel'),
                        onClick: () => setShowModal(true),
                    }}
                    primaryButton={{
                        text: t('position.continue'),
                        loading: isAccepting,
                        onClick: handleSubmit(onContinue),
                    }}
                />
            </div>
        </PageSection>
    );
};

TradeCard.propTypes = {
    defaultData: PropTypes.shape({
        order: PropTypes.string,
        orderType: PropTypes.string,
        quantity: PropTypes.number,
        limit: PropTypes.number,
        stop: PropTypes.number,
        fundingAccount: PropTypes.number,
        validity: PropTypes.string,
    }),
    clientId: PropTypes.number.isRequired,
    dfsClientId: PropTypes.string.isRequired,
    currency: PropTypes.string,
    portfolioName: PropTypes.string,
    portfolioCurrency: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
    tradingUnit: PropTypes.number.isRequired,
    roundingLot: PropTypes.number.isRequired,
    positionId: PropTypes.string.isRequired,
    portfolioId: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
};

TradeCard.defaultProps = {
    defaultData: undefined,
    currency: '',
    portfolioName: '',
    portfolioCurrency: '',
    price: 0,
    quantity: 0,
};

export default TradeCard;
