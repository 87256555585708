import React, {
    createContext, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { memberIdSelector } from 'redux/auth/authSelectors';
import ServiceManager from 'services/ServiceManager';
import defaultImg from 'assets/images/avatar/AdvisorMale.jpg';
import Service from 'additiv-services';
import { useTranslation } from 'react-i18next';
import { getImageMime } from 'utils';
import handlerRequestCanceling from '../../utils/handlerRequestCanceling';
import HandlerError from '../../errors/HandlerError';
import AdapterError from '../../errors/AdapterError';
import { parseXml } from '../../utils/xmlParser';

const ManagerProfileContext = createContext({});

const { Provider } = ManagerProfileContext;

const ManagerProfileProvider = (options) => {
    const [error, setError] = useState(null);
    const [cancelError, setCancelError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({ email: '', name: '', image: '' });
    const memberId = useSelector(memberIdSelector);
    const { i18n: { language } } = useTranslation();

    const {
        options: { pathname },
        children,
    } = options;

    const getMemberProfile = useCallback(async () => {
        if (memberId) {
            setLoading(true);
            Promise.all([
                ServiceManager.contactManagement('getMembersProfile', [memberId]),
                ServiceManager.memberManagement('getMemberPicture', [memberId]),
            ])
                .then((response) => response.map((item) => item.data))
                .then((response) => {
                    try {
                        setData({
                            email: response[0]?.Email,
                            image: response[1]?.ImageContentBase64 || data.image ? `data:${getImageMime(response[1]?.ImageContentBase64 || data.image)};base64,${response[1]?.ImageContentBase64 || data.image}` : defaultImg,
                            name: `${response[0]?.FirstName} ${response[0]?.LastName}`,
                            firstName: response[0]?.FirstName,
                            lastName: response[0]?.LastName,
                            phone: response[0]?.Phone,
                            mobile: response[0]?.Mobile,
                            bankBranch: parseXml(response[0]?.Branch?.Name)?.root?.[language]?.['#text'],
                            internalId: response[0]?.InternalUserId,
                            superiorIds: response[0]?.SuperiorIds,
                            hasProfilePicture: response[0]?.HasProfilePicture,
                        });
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch((err) => {
                    handlerRequestCanceling(
                        HandlerError({ setError, setLoading }),
                    )(err);

                    if (Service.isCanceled(err)) {
                        setCancelError(true);
                        setLoading(false);
                    }
                });
        }
    }, [memberId, language]);

    useEffect(() => {
        if (!data.name && !isLoading) {
            (async () => {
                await getMemberProfile();
                setCancelError(false);
            })();
        }
    }, [getMemberProfile, data?.name, pathname, cancelError, isLoading]);

    return (
        <Provider value={{
            data, error, isLoading, getMemberProfile,
        }}
        >
            {children}
        </Provider>
    );
};

ManagerProfileProvider.propTypes = {
    options: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        children: PropTypes.node,
    }).isRequired,
};

ManagerProfileProvider.defaultProps = {};

export {
    ManagerProfileProvider,
    ManagerProfileContext,
};
