import React from 'react';
import PropTypes from 'prop-types';
import InvestmentAllocationMini from 'components/InvestmentAllocationMini';
import InvestmentAllocation from './InvestmentAllocation';

function InvestmentAllocationWrapper({ mini, ...props }) {
    return mini ? <InvestmentAllocationMini {...props} /> : <InvestmentAllocation {...props} />;
}

InvestmentAllocationWrapper.propTypes = {
    mini: PropTypes.bool,
};

InvestmentAllocationWrapper.defaultProps = {
    mini: true,
};

export default React.memo(InvestmentAllocationWrapper);
