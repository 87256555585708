import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    ContentBox, Table, Tabs, TabPane,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import Preloader from 'components/Preloader';
import PageSection from 'components/PageSection';
import EmptyContent from 'components/EmptyContent';
import { getClientId } from 'utils';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileSize';
import { getColumns } from './constants';
import { useDocuments } from './hooks/useDocuments';
import { DocumentUpload } from './components/DocumentUpload';
import './Documents.css';

function Documents(props) {
    useCancelAllRequests();

    const {
        match: { params: { dfsClientId } },
        location: { state },
    } = props;
    const currentPage = state?.currentPage || 1;
    const currentTab = state?.currentTab || 'personal';

    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();
    const { isTabletSize } = useDetectedMobileDevice();
    const [currentPagination, setCurrentPagination] = useState(currentPage);
    const [tab, setTab] = useState(currentTab);
    const [uploadedId, setUploadedId] = useState(0);
    const [docError, setDocError] = useState(null);

    const {
        data: fullData,
        generalData,
        isLoading,
        isGeneralLoading,
        error: useDocumentsError,
        pageSize,
        personalPaging,
        generalPaging,
    } = useDocuments({
        clientId,
        currentPagination,
        tab,
        fileUploaded: uploadedId,
    });

    useEffect(() => {
        setDocError(useDocumentsError);
        const errMsg = useDocumentsError?.error?.response?.data?.message?.toLowerCase();

        // if server could not find file, show custom message correctly
        if (errMsg?.includes('not found') || errMsg?.includes('not exist')) {
            setDocError({
                ...docError,
                message: t('clientDashboard.documents.noDocFound'),
            });
        }
    }, [useDocumentsError]);

    const onChangeTabs = (key) => {
        setTab(key);
        setCurrentPagination(1);

        // clear any exisitng erros on tab change
        setDocError(null);
    };

    const onChangePage = useCallback((paging) => {
        setCurrentPagination(paging?.currentPage);
    }, [setCurrentPagination]);

    return (
        <PageSection className="documents-page">
            <PageHeader title={t('advisoryDashboard.documents.title')}>
                <DocumentUpload contactId={clientId} onUpload={setUploadedId} />
            </PageHeader>
            <ContentBox className="documents-list">
                <Tabs activeKey={tab} className="Tabs-bar" defaultActiveKey="personal" onChange={onChangeTabs}>
                    <TabPane
                        tab={t('advisoryDashboard.documents.personal')}
                        key="personal"
                    >
                        <Preloader
                            isLoading={isLoading}
                            error={docError}
                            letChildrenToRenderOnError
                        >
                            <EmptyContent data={fullData} text={t('advisoryDashboard.documents.noDocuments')}>
                                <Table
                                    columns={getColumns(t, isTabletSize)}
                                    data={fullData}
                                    paging={{
                                        total: personalPaging?.TotalCount || 0,
                                        pageSize,
                                        currentPage: currentPagination,
                                    }}
                                    headerControls={{
                                        selectControlsLabel: {
                                            selectAll: t('headerControls.selectAll'),
                                            reset: t('headerControls.reset'),
                                        },
                                    }}
                                    onChange={onChangePage}
                                />
                            </EmptyContent>
                        </Preloader>
                    </TabPane>
                    <TabPane
                        tab={t('advisoryDashboard.documents.general')}
                        key="general"
                    >
                        <Preloader
                            isLoading={isGeneralLoading}
                            error={docError}
                            letChildrenToRenderOnError
                        >
                            <EmptyContent data={generalData} text={t('advisoryDashboard.documents.noDocuments')}>
                                <Table
                                    columns={getColumns(t, isTabletSize)}
                                    data={generalData}
                                    paging={{
                                        total: generalPaging?.TotalCount || 0,
                                        pageSize,
                                        currentPage: currentPagination,
                                    }}
                                    headerControls={{
                                        selectControlsLabel: {
                                            selectAll: t('headerControls.selectAll'),
                                            reset: t('headerControls.reset'),
                                        },
                                    }}
                                    onChange={onChangePage}
                                />
                            </EmptyContent>
                        </Preloader>
                    </TabPane>
                </Tabs>
            </ContentBox>
        </PageSection>
    );
}

Documents.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ dfsClientId: PropTypes.string.isRequired }),
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            currentPage: PropTypes.number,
            currentTab: PropTypes.string,
        }),
    }),
};

Documents.defaultProps = {
    location: {
        state: {},
    },
};

export default Documents;
