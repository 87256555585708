import { checkNullData } from 'utils';
import {
    dateFormat, getTimestamp, getFirstDayOfYear, getDatesDifference,
} from 'utils/datetime';

export const adaptOverviewTotal = (data, currencyObj) => {
    if (checkNullData(data)) return { value: 0, currency: '', percent: 0 };

    const firstDayYear = getFirstDayOfYear();
    const diff = data
        .map(({ Date }) => getDatesDifference(Date, firstDayYear))
        .filter((i) => i >= 0);
    const minDiff = Math.min(...diff);
    const firstDayValue = data.find(
        ({ Date }) => getTimestamp(Date) === getTimestamp(firstDayYear, dateFormat) + minDiff,
    );

    const value = data.pop().Value;
    const percent = firstDayValue ? (((value / firstDayValue.Value) - 1) * 100) : 0;
    const currency = currencyObj.CurrencyCode;

    return { value, currency, percent };
};
