import { checkNullData } from 'utils';

export const adaptListCurrencies = (data, t) => {
    if (checkNullData(data)) return [{ label: t('advisoryDashboard.markets.base'), value: '' }];

    return [
        { label: t('advisoryDashboard.markets.base'), value: '' },
        ...data
            .map(({ Id, Name }) => ({ label: Name, value: Id }))
            .sort((a, b) => a?.label.localeCompare(b?.label)),
    ];
};
