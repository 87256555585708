import { useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';

export const useCustomRefHeightResizable = (querySelector = null) => {
    const resizeContent = useRef({});
    const [elemHeight, setElemHeight] = useState();
    const setHeight = () => {
        const {
            children = [],
        } = resizeContent.current;
        const heights = [...children].map((item) => item.offsetHeight);
        const sum = heights.reduce((a, b) => a + b, 0);
        const avgHeight = (sum / heights.length) || 0;

        setElemHeight(avgHeight || 0);
    };
    const getHeight = (node) => {
        if (querySelector) resizeContent.current = node?.querySelector('table tbody');
        else resizeContent.current = node;

        if (resizeContent.current?.firstChild) {
            setHeight();
        }
    };

    useEffect(() => {
        const throttleSetHeight = throttle(setHeight, 330);

        global.addEventListener('resize', throttleSetHeight);


        return () => {
            global.removeEventListener('resize', throttleSetHeight);
        };
    }, []);

    return { elemHeight, getHeight };
};
