import { get } from 'lodash';
import { formatBigNumber } from 'utils/formatting';
import { checkNullData } from 'utils';

export const adaptCurrencies = (data) => {
    if (checkNullData(data)) return [];

    return data.map((item) => ({
        BaseCurrency: item.BaseCurrencyCode,
        QuoteCurrency: item.QuoteCurrencyCode,
        BaseQuoteCurrency: `${item.BaseCurrencyCode}/${item.QuoteCurrencyCode}`,
        Rate: formatBigNumber(get(item, 'Price'), 4),
        Change: item.Kpis.OneDay * 100,
        ChangeYTD: item.Kpis.Ytd * 100,
        Performance: {
            performance: item.Kpis.OneDay * 100,
            value: formatBigNumber(get(item, 'Price'), 4),
        },
    }));
};
