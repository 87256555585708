import { useEffect, useMemo, useState } from 'react';
import { LiquidityId } from 'constants/instrument';

export const usePositionsConstrains = ({ positions, optimizationConstraints }) => {
    const [positionsConstraints, setPositionsConstraints] = useState({});

    const liquidityConstraintsValue = useMemo(() => (optimizationConstraints
        ?.AssetClassConstraints?.find(
            (item) => item.AssetClass.Id === LiquidityId,
        )?.MinWeight || 0) * 100, [optimizationConstraints]);

    const liquidityConstraints = useMemo(() => !!(positions?.positions?.find(
        (position) => position.children?.find(
            (item) => item.typeId === LiquidityId,
        ),
    )?.children || []).find((item) => +item.newAllocationData < +liquidityConstraintsValue),
    [liquidityConstraintsValue, positions]);

    useEffect(() => {
        setPositionsConstraints(
            { isConstraints: liquidityConstraints, constraintsValue: liquidityConstraintsValue },
        );
    }, [
        liquidityConstraintsValue,
        liquidityConstraints,
        setPositionsConstraints,
    ]);

    return {
        positionsConstraints,
    };
};
