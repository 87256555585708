import { checkNullData } from 'utils';

export const adaptMessages = (data) => {
    if (checkNullData(data)) return null;

    return data.Data
        .map((item) => ({
            id: item.Id,
            Subject: {
                title: item.SenderFullName,
                subtitle: item.Subject,
            },
            Date: item.CreateDate,
        }));
};
