import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore } from '../store-provider';
import {
    getListData, selectItem, clearPreview, setCurrentPage,
} from '../actions';
import ListTable from '../components/ListTable';

const ListTableContainer = ({
    getData,
    getPreview,
    needUpdate,
    getDataCallback,
    onRowSelect,
    isLoading,
    ...props
}) => {
    const dispatch = useDispatch();
    const store = useStore();

    useEffect(() => {
        if (needUpdate === true) clearPreview(dispatch);
    }, [needUpdate]);

    return (
        <ListTable
            {...props}
            data={store.list}
            total={store.total}
            error={store.errorList}
            sorting={store.sorting}
            isLoading={isLoading || store.isListLoading}
            currentPage={store.currentPage}
            needUpdate={needUpdate}
            getData={getListData(dispatch, store, getData, getDataCallback)}
            onRowSelect={(id) => {
                onRowSelect();
                selectItem(dispatch, store, getPreview)(id);
            }}
            setStoreCurrentPage={(currPage) => setCurrentPage(dispatch)(currPage)}
        />
    );
};

ListTableContainer.propTypes = {
    getData: PropTypes.func,
    getPreview: PropTypes.func,
    needUpdate: PropTypes.bool,
    isLoading: PropTypes.bool,
    getDataCallback: PropTypes.func,
    onRowSelect: PropTypes.func,
};

ListTableContainer.defaultProps = {
    getData: () => {},
    getPreview: () => {},
    needUpdate: false,
    getDataCallback: () => {},
    onRowSelect: () => {},
    isLoading: false,
};

export default ListTableContainer;
