import { renderers } from 'ui-library';
import { CellPDFView, CellSwitch } from 'components/renderers';
import { sorterNumber, sorterString } from 'utils/sorting';
import CellValue from 'templates/Portfolio/components/CellValue';

const { CellWithButtonIcon } = renderers;

export const getTradesColumns = (t) => ([
    {
        key: 'nameNew',
        title: t('clientDashboard.portfolio.columns.name'),
        sortable: true,
        render: CellSwitch,
        width: 250,
        minWidth: 250,
    },
    {
        title: t('clientDashboard.portfolio.columns.currency'),
        key: 'currency',
        sortable: true,
        sorter: sorterString('currency'),
        className: 'currency-cel',
        width: 80,
        minWidth: 80,
    },
    {
        title: t('clientDashboard.portfolio.columns.latestPrice'),
        key: 'latestPrice',
        width: 107,
        minWidth: 107,
        sortable: true,
        sorter: sorterNumber('latestPrice'),
        className: 'text-right',
        render: CellValue,
    },
    {
        title: t('clientDashboard.portfolio.columns.units'),
        className: 'text-right',
        key: 'units',
        sortable: true,
        sorter: sorterNumber('units'),
        width: 90,
        minWidth: 90,
    },
    {
        title: t('clientDashboard.portfolio.columns.valueData'),
        className: 'text-right',
        key: 'valuation',
        sortable: true,
        sorter: sorterNumber('valuation'),
        width: 150,
        minWidth: 150,
    },
    {
        title: t('clientDashboard.portfolio.columns.allocation'),
        key: 'newAllocation',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('allocation'),
        width: 120,
        minWidth: 120,
    },
    {
        title: t('clientDashboard.portfolio.columns.fee'),
        key: 'fee',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('fee'),
        width: 60,
        minWidth: 60,
    },
]);

export const getDocumentsColumns = (t) => ([
    {
        key: 'icon',
        title: '',
        render: CellWithButtonIcon,
        width: 30,
        minWidth: 30,
    },
    {
        key: 'name',
        title: t('clientDashboard.documents.name'),
        render: CellPDFView,
        sortable: true,
        sorter: sorterString('name.value'),
        lastSorter: true,
    },
]);
