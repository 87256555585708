import React from 'react';
import {
    Title, ContentBox, Loader,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import {
    OverviewPanel,
} from 'components/Overview/index';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import PropTypes from 'prop-types';
import OverviewItem from './OverviewItem';
import {
    OVERVIEW_SIZE_LAGRE,
    OVERVIEW_SIZE_SMALL,
    TYPE_CURRENCY,
    TYPE_PERCENT,
} from './constants';
import './OverviewItem.css';

function Overview({
    data,
    isLoading,
    error,
    downloadReport,
    downloadReportDisabled,
    isReportLoading,
    fileName,
}) {
    const { t } = useTranslation();

    const handleDownload = (event) => {
        event.preventDefault();
        downloadReport();
    };

    return (
        <PageSection>
            <ContentBox className="overview overview-client-mini" underline={false}>
                <Title type={2} className="overview__header">
                    {t('clientDashboard.overview.investmentOverview')}
                </Title>
                <Preloader isLoading={isLoading} error={error}>
                    <OverviewPanel>
                        <OverviewItem
                            currency={data.currency}
                            title={t('advisoryDashboard.overview.totalAUM')}
                            type={TYPE_CURRENCY}
                            value={data.totalValue}
                            isDefaultFormattedValue
                            isCurrencyBeforeValue
                            currencySize={OVERVIEW_SIZE_LAGRE}
                        />
                        <OverviewItem
                            size={OVERVIEW_SIZE_SMALL}
                            className="overview-item-mini"
                            currency={data.currency}
                            title={t('clientDashboard.overview.cash')}
                            type={TYPE_CURRENCY}
                            value={data.cashAmount}
                            isDefaultFormattedValue
                            isCurrencyBeforeValue
                        />
                        <OverviewItem
                            size={OVERVIEW_SIZE_SMALL}
                            className="overview-item-mini"
                            currency={data.currency}
                            title={t('clientDashboard.overview.securities')}
                            type={TYPE_CURRENCY}
                            value={data.securityValue}
                            isDefaultFormattedValue
                            isCurrencyBeforeValue
                        />
                        <OverviewItem
                            size={OVERVIEW_SIZE_SMALL}
                            className="overview-item-mini"
                            title={t('clientDashboard.overview.performanceSI')}
                            type={TYPE_PERCENT}
                            value={data.performanceValue}
                            colored
                        />
                    </OverviewPanel>
                </Preloader>
                <div className="overview-client-mini__button__container">
                    <a
                        href={fileName}
                        className="button button-small button-secondary"
                        disabled={downloadReportDisabled || isReportLoading}
                        onClick={handleDownload}
                        download
                    >
                        { isReportLoading ? <Loader /> : t('clientDashboard.overview.downloadInvestmentReport')}
                    </a>
                </div>
            </ContentBox>
        </PageSection>
    );
}

Overview.propTypes = {
    data: PropTypes.shape({
        securityValue: PropTypes.number,
        cashAmount: PropTypes.number,
        performanceAmount: PropTypes.number,
        totalValue: PropTypes.number,
        performanceValue: PropTypes.number,
        currency: PropTypes.string,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    downloadReport: PropTypes.func,
    downloadReportDisabled: PropTypes.bool,
    isReportLoading: PropTypes.bool,
    fileName: PropTypes.string,
};

Overview.defaultProps = {
    error: null,
    downloadReport: () => {},
    downloadReportDisabled: false,
    isReportLoading: false,
    fileName: null,
};

export default Overview;
