import { setup } from 'additiv-services';

setup({
    withBearerApplicationAccessToken: true,
    GATEWAY_API_URL: process.env.REACT_APP_GATEWAY_API_URL,
    SERVICES_URL_SUFFIX: global.AppConfig.SERVICES_URL_SUFFIX,
    DMS_URL_SUFFIX: global.AppConfig.DMS_URL_SUFFIX,
    CUSTOM_URL_SUFFIX: global.AppConfig.CUSTOM_URL_SUFFIX,
    ENGAGEMENT_CONTENT_URL_SUFFIX: global?.AppConfig?.ENGAGEMENT_CONTENT_URL_SUFFIX,
    CLIENT_SERVICE_URL_SUFFIX: global.AppConfig.CLIENT_URL_SUFFIX,
    FIDENTITY_URL_SUFFIX: global?.AppConfig?.FIDENTITY_URL_SUFFIX,
    CLIENT_SERVICE_MEMBER_URL_SUFFIX: global.AppConfig.CLIENT_MEMBER_URL_SUFFIX,
});
