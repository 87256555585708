import { useState } from 'react';

export const usePagination = () => {
    const [page, setPage] = useState(1);

    const onPaginationChange = ({ currentPage }) => {
        setPage(currentPage);
    };

    return { page, onPaginationChange };
};
