/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Title, Paragraph, Tooltip } from 'ui-library';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';

import './ColorSettings.css';

export default function ColorSettings({ colors, set }) {
    const [active, setActive] = useState('');

    const handleCloseColorPicker = () => setActive('');

    const handleColorClick = (name) => {
        if (active === name) return handleCloseColorPicker();

        return setActive(name);
    };

    const handleChange = (color, groupName, name) => {
        set('colors', {
            ...colors,
            [groupName]: {
                ...colors[groupName],
                [name]: color.hex,
            },
        });
    };

    const renderColorGroup = (name, values) => (
        <div className="color-group" key={name}>
            <Title type={3}>{name}</Title>

            <div className="color-group__items">
                {Object.keys(values).map((i) => (
                    <div className="color-box" key={i}>
                        <Tooltip
                            overlayClassName="color-box__picker-tooltip"
                            visible={active === i}
                            trigger="click"
                            placement="rightBottom"
                            title={(
                                <SketchPicker
                                    color={values[i]}
                                    onChange={(v) => handleChange(v, name, i)}
                                />
                            )}
                        >
                            <button
                                type="button"
                                onClick={() => handleColorClick(i)}
                                className="color-box__color"
                                style={{
                                    backgroundColor: values[i],
                                }}
                            />
                        </Tooltip>

                        <Paragraph>{i}</Paragraph>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <>
            {Object.keys(colors).map((group) => renderColorGroup(group, colors[group]))}

            {active && <div className="color-picker-cover" onClick={handleCloseColorPicker} />}
        </>
    );
}

ColorSettings.propTypes = {
    set: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    colors: PropTypes.any.isRequired,
};
