import { createContext } from 'react';

const initialContext = {
    contactId: undefined,
    memberId: undefined,
    interactionsData: {
        getInteractions: null,
        dataInteractions: null,
        errorInteractions: null,
        isLoadingInteractions: false,
        getInteractionDetails: null,
        dataInteractionDetails: null,
        errorInteractionDetails: null,
        isLoadingInteractionDetails: false,
    },
    interactionModify: {
        deleteInteraction: null,
        errorDeleteInteraction: null,
        dataDeleteInteraction: null,
        isLoadingDeleteInteraction: false,
        updateInteraction: null,
        errorUpdateInteraction: null,
        dataUpdateInteraction: null,
        isLoadingUpdateInteraction: false,
        createInteraction: null,
        errorCreateInteraction: null,
        dataCreateInteraction: null,
        isLoadingCreateInteraction: false,
        updateInteractionJournal: null,
        errorUpdateInteractionJournal: null,
        dataUpdateInteractionJournal: null,
        isLoadingUpdateInteractionJournal: false,
    },
    interactionFields: {
        getInteractionTopics: null,
        dataInteractionTopics: null,
        errorInteractionTopics: null,
        isLoadingInteractionTopics: false,
        getInteractionTypes: null,
        dataInteractionTypes: null,
        errorInteractionTypes: null,
        isLoadingInteractionTypes: false,
        getParticipantsData: null,
        dataParticipants: null,
        errorParticipants: null,
        isLoadingParticipants: false,
        getMemberClientsData: null,
        dataMemberClients: null,
        errorMemberClients: null,
        isLoadingMemberClients: false,
    },
};

const InteractionsContext = createContext(initialContext);

export default InteractionsContext;
