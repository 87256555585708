import React from 'react';
import PropTypes from 'prop-types';
import { Title } from 'ui-library';
import './PageTitle.css';

function PageTitle({ children }) {
    return (
        <Title className="page-title">{children}</Title>
    );
}

PageTitle.propTypes = {
    children: PropTypes.node,
};

PageTitle.defaultProps = {
    children: null,
};

export default PageTitle;
