import React, {
    useEffect, useState,
} from 'react';
import {
    Accordion, AccordionPanel as Panel, Row,
    Column, Infobox, Table,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import {
    orderBookSelector,
    portfolioSelector,
    usePortfolioSelector,
} from 'domain/Portfolio';
import PageHeader from 'components/PageHeader';
import history from 'services/history';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import PropTypes from 'prop-types';
import { getOrdersColumns } from '../../constants/columns';
import { useClientLayout } from '../../../../hooks/useClientLayout';
import './PendingOrders.css';

function PendingOrders(props) {
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState(['1']);

    useClientLayout();
    const {
        match: { params: { dfsClientId } },
        location: { state },
    } = props;
    const fromReview = state?.fromReview || false;

    // Portfolio Domain
    const {
        data, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        data: orderBook, isLoading: isLoadingOrderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    useEffect(() => {
        if (data?.productId) {
            getOrderBook({ adaptOptions: { productId: data?.productId } });
        }
    }, [data?.productId, getOrderBook]);

    const handleBackClick = () => {
        const baseBackUrl = `/client/${dfsClientId}`;
        const fromOrigin = state && state?.fromOrigin;
        const currentPage = state && state?.currentPage;
        const currentTab = state && state?.currentTab;

        if (fromReview) {
            history.push(`/client/${dfsClientId}/risk-profile`, { step: 3 });

            return;
        }

        if (!fromOrigin) {
            history.push(`${baseBackUrl}/portfolios/`);

            return;
        }

        history.push(`${baseBackUrl}/${fromOrigin}/`, { currentPage, currentTab });
    };

    return (
        <Row className="single-portfolio pending-orders-portfolio">
            <Column size="12">
                <Preloader
                    isLoading={isLoading || isLoadingOrderBook}
                    error={error || errorOrderBook}
                >
                    {orderBook && <Infobox>{t('clientDashboard.portfolio.orderBookMessage')}</Infobox>}
                    <PageHeader
                        title={data?.title}
                        breadCrumbsCurrent={data.backTitle}
                        breadCrumbsChildren={[
                            {
                                to: `/client/${dfsClientId}/portfolios`,
                                label: t('clientDashboard.portfolios.title'),
                            },
                        ]}
                    />
                    <div className="portfolio-content">
                        <Accordion activeKeys={activeKey} onChange={setActiveKey}>
                            <Panel header={t('clientDashboard.portfolio.pendingOrder')} className="pending-order" key="1">
                                <Table
                                    className="positions-table"
                                    data={orderBook || []}
                                    columns={getOrdersColumns(t)}
                                    expandedColumn="name"
                                    defaultExpandAllRows
                                />
                            </Panel>
                        </Accordion>
                    </div>
                    <ButtonsBlockRow
                        leftButton={{
                            text: t('payments.back'),
                            onClick: handleBackClick,
                        }}
                    />
                </Preloader>
            </Column>
        </Row>
    );
}

PendingOrders.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
        }),
    }).isRequired,
    location: PropTypes.shape({
        state: {
            fromReview: PropTypes.bool,
            fromOrigin: PropTypes.string,
            currentPage: PropTypes.string,
            currentTab: PropTypes.string,
        },
    }).isRequired,
};

PendingOrders.defaultProps = {};

export default PendingOrders;
