import {
    memberIdSelector,
} from 'redux/auth/authSelectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import ServiceManager from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';

export const useContactsCount = () => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [clients, setClients] = useState({ value: '' });
    const [prospects, setProspects] = useState({ value: '' });
    const memberId = useSelector(memberIdSelector);

    useEffect(() => {
        setLoading(true);

        ServiceManager.contactManagement('getContactsCount', [memberId, { hierarchy: true }])
            .then(({ data }) => {
                try {
                    setClients({ value: data.Privates + data.Corporates });
                    setProspects({ value: data.PrivatesProspects });
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, []);

    return {
        clients, prospects, isLoading, error,
    };
};
