import { useContext } from 'react';
import ClientServiceContext from './context';

const useSelector = (callback) => {
    const { getValue } = useContext(ClientServiceContext);

    if (getValue === undefined) {
        throw new Error('useClientService can only be used inside ClientServiceContext');
    }

    return getValue(callback);
};

export default useSelector;
