import { isNil } from 'lodash/fp';
import { checkNullData } from 'utils';

export const adaptInteraction = (item, types, topics, isSelected) => {
    const type = types?.find((i) => i.Id === item.InteractionTypeId);
    const topic = topics?.find((i) => i.Id === item.InteractionTopicId);

    if (item) {
        return {
            id: item.Id,
            isSelected,
            DateFrom: item.DateFrom,
            DateTo: item.DateTo,
            InitiatedBy: item.InitiatedBy,
            CreatedBy: item.CreatedBy,
            Participants: item.Participants,
            OtherParticipants: item.OtherParticipants,
            InteractionTypeId: item.InteractionTypeId,
            InteractionTopicId: item.InteractionTopicId,
            FirstReminder: item.FirstReminder,
            SecondReminder: item.SecondReminder,
            IsCanceled: item.IsCanceled,
            IsRestrictedAccess: item.IsRestrictedAccess,
            Subject: {
                title: type?.Name,
                recipients: topic?.Name,
                isRead: true,
            },
            Date: {
                date: item.DateFrom,
                isReplied: false,
            },
        };
    }


    return null;
};

export const adaptInteractions = ({ data, topics, types } = {}) => {
    if (checkNullData(data)) return [];

    return data?.map(({
        DateFrom,
        InteractionTypeId,
        InteractionTopicId,
        Id,
        DateTo,
        InitiatedBy,
        CreatedBy,
        Participants,
        OtherParticipants,
        FirstReminder,
        SecondReminder,
        IsCanceled,
        IsRestrictedAccess,
    }, index) => adaptInteraction({
        DateFrom,
        InteractionTypeId,
        InteractionTopicId,
        Id,
        DateTo,
        InitiatedBy,
        CreatedBy,
        Participants,
        OtherParticipants,
        FirstReminder,
        SecondReminder,
        IsCanceled,
        IsRestrictedAccess,
    }, types, topics, index === 0));
};


export const adaptInteractionDetails = ({
    data, topics, types, comment, initiated, participants, journals, isEditable,
}) => {
    const topic = topics?.find((i) => i.Id === data?.InteractionTopicId);
    const type = types?.find((i) => i.Id === data?.InteractionTypeId);

    return (isNil(data) ? null : {
        id: data.id,
        title: topic?.Name,
        DateFrom: data.DateFrom,
        DateTo: data.DateTo,
        InitiatedBy: data.InitiatedBy,
        CreatedBy: data.CreatedBy,
        Participants: data.Participants,
        OtherParticipants: data.OtherParticipants,
        InteractionTypeId: data.InteractionTypeId,
        InteractionTopicId: data.InteractionTopicId,
        FirstReminder: data.FirstReminder,
        SecondReminder: data.SecondReminder,
        IsCanceled: data.IsCanceled,
        IsRestrictedAccess: data.IsRestrictedAccess,
        Subject: {
            title: type?.Name,
            recipients: topic?.Name,
            isRead: true,
        },
        Date: {
            date: data.DateFrom,
            isReplied: false,
        },
        type: type?.Name,
        topic: topic?.Name,
        comment: comment?.Content || '',
        journalId: comment?.JournalId,
        initiated,
        participants,
        from: data.DateFrom,
        to: data.DateTo,
        journals: journals?.map((item) => ({
            message: item.Content,
            time: item.CreateDate,
            createdBy: item.CreatorId,
        })),
        isEditable,
    });
};
