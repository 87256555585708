import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'ui-library';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { getClassNames } from 'utils';

function DashboardColumn({
    column, widgets, widths, widgetsObject,
}) {
    const width = useCallback(
        (columnOver) => (widths.length ? widths[columnOver - 1] : undefined),
        [widths],
    );

    return (
        <Column size={column.size} key={column.id}>
            <Droppable key={column.id} droppableId={column.id}>
                {({ innerRef, placeholder }, { isDraggingOver }) => (
                    <div
                        ref={innerRef}
                        className={isDraggingOver ? 'droppable' : ''}
                    >
                        {widgets.map((widget, index) => (
                            <Draggable key={widget.id} index={index} draggableId={widget.id}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                    >
                                        <div
                                            className={getClassNames('widget-wrapper', { dragging: snapshot.isDragging })}
                                            style={{ width: width(snapshot.draggingOver) }}
                                        >
                                            {widgetsObject({
                                                drop: provided.dragHandleProps,
                                                mini: snapshot.draggingOver === '2' || column.id === '2',
                                            })[widget.type]}
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {placeholder}
                    </div>
                )}
            </Droppable>
        </Column>
    );
}

DashboardColumn.propTypes = {
    column: PropTypes.shape({
        id: PropTypes.string,
        size: PropTypes.string,
        blocks: PropTypes.arrayOf(PropTypes.string),
    }),
    widgets: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.string,
        }),
    ),
    widths: PropTypes.arrayOf(PropTypes.number),
    widgetsObject: PropTypes.func,
};

DashboardColumn.defaultProps = {
    column: {},
    widgets: [],
    widths: [],
    widgetsObject: () => {},
};

export default DashboardColumn;
