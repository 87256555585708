import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ContentBox from 'ui-library/dist/components/ContentBox';
import {
    Column, Modal, Paragraph, Title,
} from 'ui-library';
import history from 'services/history';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import { useFormatting } from 'locale';
import { Row } from 'ui-library/dist/components/Grid';
import { usePaymentsData } from '../../hooks/usePaymentsData';

const DomesticReview = (props) => {
    const {
        dfsClientId, payment, onPrev, onCancel,
    } = props;

    const { getPayment, clearPayments } = usePaymentsData();

    const data = getPayment(payment);
    const { t } = useTranslation();
    const { getFormattedDate, getFormattedCurrency } = useFormatting();

    const onSubmit = useCallback(() => {
        Modal.confirm({
            title: t('payments.executePaymentTitle'),
            content: t('payments.executePaymentContent'),
            okText: t('payments.execute'),
            onOk: () => {
                clearPayments();
                history.push(`/client/${dfsClientId}/payments/confirmation?type=payment`);
            },
            onCancel: async () => { },
            cancelText: t('payments.cancel'),
            className: 'close-client-modal',
        });
    }, [dfsClientId]);

    return (
        <>
            <ContentBox className="review-payment domestic-review">
                <div className="payments-header">
                    <Title type={2}>{t('payments.reviewYourDomestic')}</Title>
                </div>
                <div className="review-payment-content">
                    <div className="review-payment-description">
                        <Paragraph type="secondary">{t('payments.paymentReviewDescription')}</Paragraph>
                    </div>
                    <Title type={3} className="payment-details-title">
                        {t('payments.yourPaymentDetails')}
                    </Title>
                    <Row className="review-payment-details">
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.amount')}</Title>
                                <p className="review-payment-info">{getFormattedCurrency(data?.amount, { currency: data?.currency?.label })}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.executionDate')}</Title>
                                <p className="review-payment-info">{getFormattedDate(data?.executionDate)}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.personalNote')}</Title>
                                <p className="review-payment-info">{data?.personalNote || t('payments.none')}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.messageForPayee')}</Title>
                                <p className="review-payment-info">{data?.messageForPayee || t('payments.none')}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.iban')}</Title>
                                <p className="review-payment-info">{data?.iban}</p>
                            </div>
                        </Column>
                        {data?.showBankDetails && (
                            <Column size="6">
                                <div className="group-title">
                                    <Title type={2}>{t('payments.bankDetails')}</Title>
                                    <p className="review-payment-info">{data?.bankName}</p>
                                    <p className="review-payment-info">{`${data?.bankPostCode} ${data?.bankCity}`}</p>
                                    <p className="review-payment-info">{data?.bankCountry?.label}</p>
                                </div>
                            </Column>
                        )}
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.payee')}</Title>
                                <p className="review-payment-info">{data?.payeeName}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('payments.debitAccount')}</Title>
                                <p className="review-payment-info">{data?.debitAccount?.label}</p>
                            </div>
                        </Column>
                    </Row>
                </div>
            </ContentBox>
            <ButtonsBlockRow
                leftButton={{
                    text: t('payments.back'),
                    onClick: onPrev,
                }}
                additionalButton={{
                    text: t('payments.cancel'),
                    onClick: onCancel,
                    type: 'danger',
                }}
                primaryButton={{
                    text: t('payments.confirmAndSubmit'),
                    onClick: onSubmit,
                }}
            />
        </>
    );
};

DomesticReview.propTypes = {
    payment: PropTypes.string.isRequired,
    onPrev: PropTypes.func.isRequired,
    dfsClientId: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
};

DomesticReview.defaultProps = {
};

export default DomesticReview;
