import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getClientId } from 'utils';
import { clearClient } from 'prodivers/clientProfile';
import { useMainLayout } from '../hooks/useMainLayout';

const withMainLayout = (
    WrappedComponent,
) => {
    const MainLayout = (props) => {
        const {
            match: { params: { dfsClientId } },
            location: { pathname },
        } = props;
        const clientId = getClientId(dfsClientId);

        useEffect(() => clearClient(), []);
        useMainLayout({ pathname });

        return (
            <WrappedComponent
                dfsClientId={dfsClientId}
                clientId={clientId}
                {...props}
            />
        );
    };

    MainLayout.propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                dfsClientId: PropTypes.string,
            }),
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
    };

    return MainLayout;
};

export default withMainLayout;
