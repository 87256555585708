import React from 'react';
import PropTypes from 'prop-types';
import { renderers } from 'ui-library';

const { CellWithLinkIcon, CellWithHyperlinkIcon } = renderers;

const CellWithLinkOrHyperlinkIcon = ({
    href, link, type, size, className,
}) => {
    if (href !== undefined) {
        return (
            <CellWithHyperlinkIcon href={href} type={type} size={size} className={className} />
        );
    }

    if (!link) return null;

    return (
        <CellWithLinkIcon type={type} href={link} size={size} className={className} />
    );
};

CellWithLinkOrHyperlinkIcon.propTypes = {
    href: PropTypes.string,
    link: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
};

CellWithLinkOrHyperlinkIcon.defaultProps = {
    href: undefined,
    link: undefined,
    type: undefined,
    size: undefined,
    className: undefined,
};

export default CellWithLinkOrHyperlinkIcon;
