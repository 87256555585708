import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import { getClientId } from 'utils';
import { CHANGE_MODEL } from 'constants/constants';
import { formatDate } from 'utils/datetime';
import { useClientProfile } from 'prodivers/clientProfile';
import { modelPortfolioSelector, portfolioSelector, usePortfolioSelector } from 'domain/Portfolio';
import { useModelChange } from 'hooks/useModelChange';
import { useCreateProposal } from '../../common/hooks/useCreateProposal';
import { mapActionTypeName } from '../../../constants';
import { useExecuteStrategy } from '../../common/hooks/useExecuteStrategy';
import InteractionAction from '../../../containers/InteractionAction';

function Interaction(props) {
    const {
        match: { params: { dfsClientId, portfolioId } },
    } = props;
    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();
    const modelId = JSON.parse(sessionStorage.getItem(CHANGE_MODEL));
    const { onModelReset } = useModelChange();

    // Client Provider
    const { clientName } = useClientProfile();

    // Portfolio Domain
    const { data, isLoading, error } = usePortfolioSelector(portfolioSelector);
    const {
        data: modelPortfolio, isLoading: isLoadingModel, error: errorModel, getModelPortfolio,
    } = usePortfolioSelector(modelPortfolioSelector);

    useEffect(() => {
        getModelPortfolio(modelId);
    }, [getModelPortfolio, modelId]);

    const { onCreateProposal, isCreating, errorCreating } = useCreateProposal(CHANGE_MODEL);
    const { onAcceptExecute, isAccepting, errorAccepting } = useExecuteStrategy(
        clientId, dfsClientId, portfolioId, CHANGE_MODEL,
    );

    // Callbacks
    const onNext = async (type) => {
        onModelReset();
        if (type === 'proposal') {
            const nameProposal = `${mapActionTypeName(CHANGE_MODEL, t)} ${t('proposal.ofPortfolio')} ${data?.title} ${t('proposal.on')} ${formatDate(new Date())}`;

            onCreateProposal({
                clientId,
                dfsClientId,
                portfolioId,
                productId: data.productId,
                constraints: modelPortfolio.constraints,
                allocations: modelPortfolio.investmentAllocations,
                name: nameProposal,
                modelPortfolioId: modelId,
                contactGroupId: data.contactGroupId,
            });
        } else {
            const executeParams = { modelPortfolioId: Number(modelId) };

            onAcceptExecute(executeParams);
        }
    };

    return (
        <Preloader isLoading={isLoading || isLoadingModel} error={error || errorModel}>
            <InteractionAction
                dfsClientId={dfsClientId}
                actionType={CHANGE_MODEL}
                portfolioId={portfolioId}
                portfolioTitle={data.title}
                isLoadingContinue={isAccepting || isCreating}
                errorContinue={errorAccepting || errorCreating}
                onNext={onNext}
                clientName={clientName}
            />
        </Preloader>
    );
}

Interaction.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

Interaction.defaultProps = {
};

export default Interaction;
