import { useEffect, useState } from 'react';
import { useOverviewTotal } from './useOverviewTotal';
import { useContactsCount } from './useContactsCount';

export const useOverview = (configuration) => {
    const total = useOverviewTotal(configuration);
    const contactsCount = useContactsCount();
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({ total: {}, clients: {}, prospects: {} });

    useEffect(() => {
        setError(total.error || contactsCount.error);
        setLoading(total.isLoading || contactsCount.isLoading);
        setData({
            total: total.data,
            clients: contactsCount.clients,
            prospects: contactsCount.prospects,
        });
    }, [total.isLoading, contactsCount.isLoading]);

    return {
        data,
        isLoading,
        error,
    };
};
