import { get } from 'lodash';
import { checkNullData, genarateDFSClientId } from 'utils';
import {
    dateTimeStandardFormat,
    formatDate,
    getDatesDifference,
} from 'utils/datetime';

const isFewDaysEvent = (event) => getDatesDifference(event.DateTo, event.DateFrom, 'days') > 0;
const isAllDayEvent = (event) => getDatesDifference(event.DateTo, event.DateFrom, 'minutes') === 1439;
const convertDateString = (event, { getFormattedDate, getFormattedTime }) => {
    const date = getFormattedDate(event.DateFrom);
    const dateEnd = getFormattedDate(event.DateTo);
    const startTime = getFormattedTime(event.DateFrom);
    const endTime = getFormattedTime(event.DateTo);
    const isFewDays = isFewDaysEvent(event);

    return `${date}${isFewDays ? ` - ${dateEnd}` : ''}<br/>From ${startTime} to ${endTime}`;
};

const MEETING_ID = 1;
const CALL_ID = 2;
const MESSAGE_ID = 3;
const CHAT_ID = 4;
const eventColorByType = {
    [MEETING_ID]: 'green',
    [CALL_ID]: 'turquoise',
    [MESSAGE_ID]: 'purple',
    [CHAT_ID]: undefined,
};

export const adaptEvents = (events, {
    types, topics, getFormattedTime = (d) => d, getFormattedDate = (d) => d,
}) => {
    if (checkNullData(events)) return [];

    const typesAdapted = types ? types.reduce((acc, item) => ({
        ...acc,
        [item.Id]: item.Name,
    }), {}) : {};
    const topicsAdapted = topics ? topics.reduce((acc, item) => ({
        ...acc,
        [item.Id]: item.Name,
    }), {}) : {};

    return events.map((item) => ({
        id: get(item, 'Id'),
        key: `${item.Id}-${topicsAdapted[item.InteractionTopicId]}`,
        title: `${typesAdapted[item.InteractionTypeId] || ''}: ${topicsAdapted[item.InteractionTopicId] || ''}`,
        color: eventColorByType[item.InteractionTypeId],
        startDate: formatDate(item.DateFrom, dateTimeStandardFormat),
        endDate: formatDate(item.DateTo, dateTimeStandardFormat),
        dateString: convertDateString(item, { getFormattedDate, getFormattedTime }),
        contacts: item.Participants
            .filter((i) => !i.IsMember)
            .map((i) => ({
                name: `${i.FirstName} ${i.LastName}`,
                link: {
                    pathname: `/client/${genarateDFSClientId(i.Id)}`,
                    state: { clientName: `${i.FirstName} ${i.LastName}` },
                },
            })),
        subject: get(item, 'Description'),
        allDay: isAllDayEvent(item),
        fewDays: isFewDaysEvent(item),
    }));
};
