import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'ui-library';

const ClientsPagination = ({
    total, currentPage, pageSize, onChange,
}) => {
    const [memorizedTotal, setMemorizedTotal] = useState(total);


    useEffect(() => {
        if (total !== undefined) {
            setMemorizedTotal(total);
        }
    }, [total]);

    if (!memorizedTotal || memorizedTotal < pageSize) {
        return null;
    }


    return (
        <Pagination
            total={memorizedTotal}
            currentPage={currentPage}
            pageSize={pageSize}
            onChange={onChange}
        />
    );
};

ClientsPagination.propTypes = {
    total: PropTypes.number,
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    onChange: PropTypes.func,

};
ClientsPagination.defaultProps = {
    total: 0,
    pageSize: 0,
    currentPage: 0,
    onChange: () => {
    },
};

export default ClientsPagination;
