import { useEffect, useState } from 'react';
import useResizeLocalStorage from 'utils/useResizeLocalStorage';


export const useDashboardConfig = (nameWidget, defaultSize = 3) => {
    const [size, setSize] = useResizeLocalStorage(nameWidget);
    const [widgetSize, setWidgetSize] = useState(size || defaultSize);

    useEffect(() => { setWidgetSize(defaultSize); }, [defaultSize]);

    const saveWidgetSize = (itemsToResize) => {
        setSize(itemsToResize);
        setWidgetSize(itemsToResize);
    };


    return { widgetSize, saveWidgetSize };
};
