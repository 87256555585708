import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    TabPane, Tabs, Title, ContentBox, Table, TabsExtraItem, Select,
} from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import Draggable from 'components/Draggable/Draggable';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useFormatting } from 'locale';
import {
    marketsColumns,
    marketsColumnsMini,
    marketsSorting,
    currenciesColumns,
    currenciesColumnsMini,
    currenciesSorting,
} from '../constants/constants';

import './Markets.css';

function Markets({
    listCurrencies,
    isLoading,
    listCurrency,
    onListCurrenciesChange,
    markets,
    currencies,
    withLink,
    mini,
    drop,
}) {
    const { t, i18n } = useTranslation();
    const { getFormattedNumber } = useFormatting();
    const [tab, changeTab] = useState('markets');
    const titleComponent = useMemo(() => {
        if (drop) {
            return (
                <Draggable {...drop}>
                    <Title
                        type={2}
                        link={withLink ? '/dashboard/markets' : null}
                    >
                        {t('advisoryDashboard.markets.title')}
                    </Title>
                </Draggable>
            );
        }

        return (
            <Title
                type={2}
                link={withLink ? '/dashboard/markets' : null}
            >
                {t('advisoryDashboard.markets.title')}
            </Title>
        );
    }, [drop, i18n.language]);

    return (
        <PageSection className="markets">
            <ContentBox underline={false}>
                {titleComponent}
                <Preloader isLoading={isLoading}>
                    <Tabs
                        onChange={changeTab}
                        defaultActiveKey="markets"
                        tabBarExtraContent={(tab === 'markets' && !mini) && (
                            <TabsExtraItem>
                                <Select
                                    onChange={onListCurrenciesChange}
                                    disabled={markets.isLoading}
                                    options={listCurrencies}
                                    value={listCurrency}
                                    width={200}
                                    hasSearch
                                />
                            </TabsExtraItem>
                        )}
                    >
                        <TabPane tab={t('advisoryDashboard.markets.tabs.markets')} key="markets">
                            <Preloader isLoading={markets.isLoading} error={markets.error}>
                                <EmptyContent data={markets.data} text={t('advisoryDashboard.noData')}>
                                    <Table
                                        data={markets.data}
                                        columns={mini
                                            ? marketsColumnsMini(t, getFormattedNumber)
                                            : marketsColumns(t, getFormattedNumber)}
                                        sorting={mini ? marketsSorting : null}
                                        paging={{ pageSize: 4 }}
                                        mini={mini}
                                        headerControls={{
                                            sortByLabel: t('headerControls.sortBy'),
                                        }}
                                    />
                                </EmptyContent>
                            </Preloader>
                        </TabPane>
                        <TabPane tab={t('advisoryDashboard.markets.tabs.currencies')} key="currencies">
                            <Preloader isLoading={currencies.isLoading} error={currencies.error}>
                                <EmptyContent data={currencies.data} text={t('advisoryDashboard.noData')}>
                                    <Table
                                        data={currencies.data}
                                        columns={mini
                                            ? currenciesColumnsMini(t, getFormattedNumber)
                                            : currenciesColumns(t, getFormattedNumber)}
                                        sorting={mini ? currenciesSorting : null}
                                        paging={{ pageSize: 4 }}
                                        mini={mini}
                                        headerControls={{
                                            sortByLabel: t('headerControls.sortBy'),
                                        }}
                                    />
                                </EmptyContent>
                            </Preloader>
                        </TabPane>
                    </Tabs>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

Markets.propTypes = {
    listCurrencies: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    isLoading: PropTypes.bool,
    listCurrency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onListCurrenciesChange: PropTypes.func,
    markets: PropTypes.shape({
        data: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                Index: PropTypes.string,
                Ticker: PropTypes.string,
                Last: PropTypes.string,
                Change: PropTypes.number,
                ChangeYTD: PropTypes.number,
                Subject: PropTypes.shape({
                    title: PropTypes.string,
                    subtitle: PropTypes.string,
                }),
                Performance: PropTypes.shape({
                    performance: PropTypes.number,
                    value: PropTypes.string,
                }),
            }),
        ),
        isLoading: PropTypes.bool,
        error: PropTypes.string,
    }),
    currencies: PropTypes.shape({
        data: PropTypes.arrayOf(
            PropTypes.shape({
                BaseCurrency: PropTypes.string,
                QuoteCurrency: PropTypes.string,
                BaseQuoteCurrency: PropTypes.string,
                Rate: PropTypes.string,
                Change: PropTypes.number,
                ChangeYTD: PropTypes.number,
                Performance: PropTypes.shape({
                    performance: PropTypes.number,
                    value: PropTypes.string,
                }),
            }),
        ),
        isLoading: PropTypes.bool,
        error: PropTypes.string,
    }),
    withLink: PropTypes.bool,
    mini: PropTypes.bool,
    drop: PropTypes.shape({
        role: PropTypes.string,
        tabIndex: PropTypes.number,
        onDragStart: PropTypes.func,
    }),
};

Markets.defaultProps = {
    listCurrencies: [],
    isLoading: true,
    listCurrency: '',
    onListCurrenciesChange: () => {
    },
    markets: {
        data: [],
        isLoading: true,
        error: null,
    },
    currencies: {
        data: [],
        isLoading: true,
        error: null,
    },
    withLink: true,
    mini: false,
    drop: null,
};

export default React.memo(Markets);
