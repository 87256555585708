import { useEffect, useState } from 'react';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { useTranslation } from 'react-i18next';
import { adaptConfiguration } from '../adapters/adaptConfiguration';

export const useConfiguration = () => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({ currency: {}, products: [] });
    const { i18n } = useTranslation();

    useEffect(() => {
        setLoading(true);
        setError(null);

        ServiceManager.commonService('getGeneralSettings')
            .then((response1) => {
                const currencyObj = response1.data.find(({ Key }) => Key === 'DefaultCurrencyId');

                ServiceManager.commonService('getCampaignsCurrentConfiguration', [i18n.language])
                    .then(({ data: { ProductOffers } }) => {
                        try {
                            setData(adaptConfiguration(ProductOffers, currencyObj));
                            setLoading(false);
                        } catch (err) {
                            throw new AdapterError(err);
                        }
                    });
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, [i18n.language]);

    return { data, isLoading, error };
};
