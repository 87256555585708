import React, { useState } from 'react';
import {
    ContentBox, Title, Button, Row, Column,
} from 'ui-library';
import { useTranslation } from 'react-i18next';

import { withMainLayout } from 'hocs';
import { setThemeVariables } from 'utils/configureTheme';

import ColorSettings from './components/ColorSettings';
import { getInitialValuesFromRoot, adaptColorsToSave, setValuesToRoot } from './utils';
import { CUSTOM_PROPS } from './constants';

import './Settings.css';

function Settings() {
    const { t } = useTranslation();
    const [customProps, setCustomProps] = useState(getInitialValuesFromRoot(CUSTOM_PROPS));

    const setNewGroup = (name, values) => {
        setCustomProps((prev) => ({
            ...prev,
            [name]: values,
        }));
    };

    const handleSave = () => {
        // Edit this method if you want customize smt except colors

        const colors = adaptColorsToSave(customProps.colors);

        setValuesToRoot(colors);
        global.localStorage.setItem('customVars', JSON.stringify(colors));
    };

    const handleReset = () => {
        global.localStorage.removeItem('customVars');

        setThemeVariables();

        setCustomProps(getInitialValuesFromRoot(CUSTOM_PROPS));
    };

    return (
        <ContentBox className="settings-page">
            <Title>{t('settings.title')}</Title>

            <ColorSettings colors={customProps.colors} set={setNewGroup} />

            <Row className="buttons">
                <Column size={12} className="text-sm-right">
                    <Button type="default" onClick={handleReset}>
                        {t('settings.reset')}
                    </Button>
                    <Button type="primary" onClick={handleSave}>
                        {t('settings.save')}
                    </Button>
                </Column>
            </Row>
        </ContentBox>
    );
}

export default withMainLayout(Settings);
