import { useMemo } from 'react';

import { useOverviewClients } from 'hooks/useOverviewClients';
import { useTickets } from 'hooks/useTickets';
import { useTasks } from 'hooks/useTasks';
import { adaptTasks } from '../adapters/adaptTasks';


export const useClientTasks = (clientId) => {
    const contacts = useOverviewClients();
    const tickets = useTickets();
    const tasks = useTasks(tickets, contacts, 5);

    return useMemo(() => ({
        tasks: {
            ...tasks,
            data: adaptTasks(tasks?.data, clientId),
        },
    }), [tasks]);
};
