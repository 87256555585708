import React, {
    useState, useRef, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { notNegativeNumber } from './types';
import './ResizeHeight.css';

const scrollResize = (clientY) => {
    if (clientY + 40 >= global.innerHeight) {
        global.scrollBy(0, 20);
    }
    if (clientY - 40 <= 0) {
        global.scrollBy(0, -20);
    }
};

const longPress = 300; // ms

export const ResizeHeight = (props) => {
    const {
        onResizeHeightStart,
        onResizeHeight,
        onResizeHeightEnd,
        onLimitHeight,
        elementHeight,
        elementCount,
        minElementCount,
        maxElementCount,
        minHeight,
        children,
        footerSlot,
    } = props;
    const [changeHeight, setChangeHeight] = useState(false);
    const resizeSettings = useRef({
        height: elementHeight * elementCount + minHeight,
        onMouseDownY: 0,
        elementCount: 0,
        timerId: null,
    });

    const resizeWrapper = useRef(null);
    const resizeContent = useRef(null);
    const isDifferenceElementCount = useCallback(
        (count) => +resizeSettings.current.elementCount !== count,
        [],
    );

    useEffect(() => {
        const currentHeight = elementHeight * elementCount + minHeight;

        resizeSettings.current.height = currentHeight;
        resizeWrapper.current.style.height = `${currentHeight}px`;
    }, [elementHeight, elementCount, minHeight]);

    const onHeightChange = useCallback(
        (pageY) => {
            const currentHeight = (
                resizeSettings.current.height - resizeSettings.current.onMouseDownY + pageY
            );

            if (currentHeight <= elementHeight * minElementCount + minHeight) {
                resizeWrapper.current.style.height = `${elementHeight * minElementCount + minHeight}px`;
                if (isDifferenceElementCount(minElementCount)) {
                    resizeSettings.current.elementCount = minElementCount;
                    onLimitHeight({ currentHeight, elementCount: minElementCount });
                }
            } else if (currentHeight >= elementHeight * maxElementCount + minHeight) {
                resizeWrapper.current.style.height = `${elementHeight * maxElementCount + minHeight}px`;
                if (isDifferenceElementCount(maxElementCount)) {
                    resizeSettings.current.elementCount = maxElementCount;
                    onLimitHeight({ currentHeight, elementCount: maxElementCount });
                }
            } else {
                resizeWrapper.current.style.height = `${currentHeight}px`;
                const count = pageY - resizeSettings.current.onMouseDownY > 0
                    ? Math.ceil((pageY - resizeSettings.current.onMouseDownY) / elementHeight) : 0;

                if (isDifferenceElementCount(count)) {
                    const currentResize = {
                        currentHeight,
                        resizeHeight: pageY - resizeSettings.current.onMouseDownY,
                        elementCount: count,
                    };

                    onResizeHeight(currentResize);
                    resizeSettings.current.elementCount = count;
                }
            }
        }, [resizeWrapper, resizeSettings, onResizeHeight, elementHeight],
    );

    const onMouseMove = useCallback(
        (event) => {
            onHeightChange(event.pageY);
        }, [onHeightChange],
    );

    const onTouchMove = useCallback(
        (event) => {
            if (!event.touches[0]) {
                return;
            }

            const { pageY, clientY } = event.touches[0];

            scrollResize(clientY);
            onHeightChange(pageY);
        }, [onHeightChange],
    );

    const updateHeight = () => {
        resizeSettings.current.height = resizeContent.current.clientHeight;
        resizeWrapper.current.style.height = `${resizeContent.current.clientHeight}px`;
    };


    const onHeightChangeEnd = useCallback(
        () => {
            clearTimeout(resizeSettings.current.timerId);
            document.documentElement.style.overflowY = '';
            document.documentElement.style.webkitOverflowScrolling = '';
            document.documentElement.style.userSelect = '';
            global.removeEventListener('mousemove', onMouseMove, false);
            global.removeEventListener('mouseup', onHeightChangeEnd, false);
            global.removeEventListener('touchmove', onTouchMove, false);
            global.removeEventListener('touchend', onHeightChangeEnd, false);
            const countElem = (
                Math.round((resizeWrapper.current.clientHeight - minHeight) / elementHeight)
            );
            const height = (countElem * elementHeight) + minHeight;

            resizeSettings.current.height = height;
            resizeSettings.current.elementCount = countElem;
            resizeWrapper.current.style.height = `${height}px`;
            onResizeHeightEnd({
                height,
                updateHeight,
                elementCount: countElem,
            });
        }, [resizeWrapper, onResizeHeightEnd, elementHeight, resizeContent],
    );
    const onHeightChangeStart = useCallback(
        (pageY) => {
            setChangeHeight(true);

            resizeSettings.current.onMouseDownY = pageY;
            resizeSettings.current.elementCount = 0;
            onResizeHeightStart(resizeWrapper.current.clientHeight);
        }, [resizeWrapper, onResizeHeightStart],
    );

    const onMouseDown = useCallback(
        (event) => {
            onHeightChangeStart(event.pageY);
            global.addEventListener('mousemove', onMouseMove, false);
            global.addEventListener('mouseup', onHeightChangeEnd, false);
        }, [onMouseMove, onHeightChangeEnd, onHeightChangeStart],
    );

    const onTouchDown = useCallback((event) => {
        event.persist();
        event.preventDefault();
        document.documentElement.style.overflowY = 'hidden';
        document.documentElement.style.webkitOverflowScrolling = 'touch';
        document.documentElement.style.userSelect = 'none';
        resizeSettings.current.timerId = setTimeout(() => {
            if (!event.touches && !event.touches[0]) {
                return;
            }
            onHeightChangeStart(event.touches[0].pageY);
            global.addEventListener('touchmove', onTouchMove, false);
            global.addEventListener('touchend', onHeightChangeEnd, false);
        }, longPress);
    }, [onTouchMove, onHeightChangeEnd, onHeightChangeStart]);

    return (
        <div
            className={
                `ResizeHeight ${changeHeight ? 'height-change-start' : ''}`
            }
            style={{
                height: resizeSettings.current.height,
            }}
            ref={resizeWrapper}
        >
            <div className="resize-content">
                <div ref={resizeContent}>
                    {children}
                </div>
            </div>
            <div
                className="resize-bottom"
            >
                <div>
                    {footerSlot}
                </div>
                <div
                    className="resize-line"
                >

                    <div
                        className="resize-button"
                        role="button"
                        tabIndex="0"
                        onMouseDown={onMouseDown}
                        onTouchStart={onTouchDown}
                    >
                        <div className="resize-icon" />
                    </div>
                </div>
            </div>
        </div>
    );
};


ResizeHeight.propTypes = {
    onResizeHeightStart: PropTypes.func,
    onResizeHeight: PropTypes.func,
    onResizeHeightEnd: PropTypes.func,
    onLimitHeight: PropTypes.func,
    elementHeight: notNegativeNumber,
    elementCount: notNegativeNumber,
    minElementCount: notNegativeNumber,
    maxElementCount: notNegativeNumber,
    minHeight: notNegativeNumber,
    children: PropTypes.node,
    footerSlot: PropTypes.node,
};


ResizeHeight.defaultProps = {
    onResizeHeightStart: () => {
    },
    onResizeHeight: () => {
    },
    onResizeHeightEnd: () => {
    },
    onLimitHeight: () => {
    },
    elementHeight: 1,
    elementCount: 0,
    minElementCount: 0,
    maxElementCount: 0,
    minHeight: 0,
    children: null,
    footerSlot: null,
};
