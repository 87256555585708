import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash/fp';
import { Link } from 'react-router-dom';
import {
    BreadCrumbs, Table, ContentBox, Title, Modal, Infobox,
} from 'ui-library';
import history from 'services/history';
import { getClientId } from 'utils';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import PageSection from 'components/PageSection';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import AddPositionSearchForm from 'components/AddPositionSearchForm';
import { LIQUIDITY_TYPE, PAGE_SIZE } from 'constants/constants';
import { tradingCardSelector, usePortfolioSelector } from 'domain/Portfolio';
import { useSecuritiesSearch } from 'hooks/useSecuritiesSearch';
import { adaptSecurities } from 'adaptors/adaptSecurities';
import { useRatings } from 'hooks/useRatings';
import { useCurrencies } from '../hooks/useCurrencies';
import { usePagination } from '../../../../common/hooks/usePagination';
import { useSecurityTypes } from '../hooks/useSecurityTypes';
import { getSecuritiesColumns } from '../constants';
import { onPreventRedirect } from '../../../../common/utils';
import { MODIFY } from '../../../../../constants';

function AddPositionDiscretionary(props) {
    useCancelAllRequests();

    const { t, i18n: { language } } = useTranslation();
    const { page, onPaginationChange } = usePagination();
    const { location, match: { params: { portfolioId, dfsClientId } } } = props;
    const [searchCriteria, setSearchCriteria] = useState({
        CurrencyIds: undefined,
        SearchTerm: undefined,
        SecurityTypeIds: undefined,
        RatingAgencyIds: undefined,
        RatingIds: undefined,
        Page: page,
    });
    const clientId = getClientId(dfsClientId);

    // Portfolio Domain
    const { deleteTradingCard } = usePortfolioSelector(tradingCardSelector);

    // Hooks
    const {
        register, handleSubmit, setValue, watch,
    } = useForm();
    const { dataCurrency, isLoadingCurrency, errorCurrency } = useCurrencies();
    const { dataSecurityTypes, isLoadingSecurityTypes, errorSecurityTypes } = useSecurityTypes();
    const {
        dataRatingAgencies, dataRatings, getRatings,
        isLoadingRatingAgencies, errorRatingAgencies, isLoadingRatings,
    } = useRatings();

    const searchOptions = useMemo(() => (searchCriteria.SecurityTypeIds?.length ? {
        ...searchCriteria,
        PageSize: PAGE_SIZE,
        isActive: true,
        isInvestable: true,
        contactId: clientId,
    } : null), [searchCriteria, clientId]);
    const {
        dataSecurities, totalSecurities, isLoadingSecurities, errorSecurities,
    } = useSecuritiesSearch({
        adaptSecurities: (d) => adaptSecurities(d, { language, baseUrl: `/client/${dfsClientId}/portfolios/${portfolioId}/modify/position/${location.state.productId}/`, baseUrlEnd: '/1/3' }),
        productId: location.state.productId,
        params: searchOptions,
    });
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedError, setCheckedError] = useState(false);

    if (isLoadingSecurities && checkedItem) {
        setCheckedItem(null);
    }

    useEffect(() => {
        if (dataSecurityTypes?.length) {
            setSearchCriteria((arr) => ({
                ...arr, SecurityTypeIds: dataSecurityTypes.map(({ value }) => value),
            }));
        }
    }, [dataSecurityTypes]);
    useEffect(() => {
        if (checkedItem) {
            setCheckedError(false);
        }
    }, [checkedItem]);
    useEffect(() => {
        register({ name: 'SearchTerm' });
        register({ name: 'SecurityTypeIds' });
        register({ name: 'CurrencyIds' });
        register({ name: 'RatingAgencyIds' });
        register({ name: 'RatingIds' });
    }, [register]);

    /* Ratings */
    const values = watch();
    const [dataRatingsByAgencyIdsCached, setDataRatingsByAgencyIdsCached] = useState({});
    const ratingAgencyId = values?.RatingAgencyIds;

    useEffect(() => {
        setValue('RatingIds', undefined);
    }, [ratingAgencyId]);

    useEffect(() => {
        if (dataRatings?.length > 0) {
            const obj = dataRatings?.reduce((acc, item) => ({
                ...acc,
                [item?.ratingAgencyId]: dataRatings,
            }), {});

            setDataRatingsByAgencyIdsCached((prev) => ({
                ...prev,
                ...obj,
            }));
        }
    }, [dataRatings]);

    useEffect(() => {
        if (ratingAgencyId && !dataRatingsByAgencyIdsCached[ratingAgencyId]) {
            getRatings(ratingAgencyId);
        }
    }, [ratingAgencyId, getRatings, dataRatingsByAgencyIdsCached]);

    // Callbacks
    const onAddPosition = () => {
        if (checkedItem) {
            const securityData = find(['id', checkedItem], dataSecurities);
            const redirectUrl = securityData.Type === LIQUIDITY_TYPE
                ? `/client/${dfsClientId}/portfolios/${portfolioId}/modify/position/${location.state.productId}/${checkedItem}/2/overview`
                : `/client/${dfsClientId}/portfolios/${portfolioId}/modify/position/${location.state.productId}/${checkedItem}/1/3`;

            history.push(redirectUrl);
        } else {
            setCheckedError(true);
        }
    };
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                sessionStorage.removeItem(MODIFY);
                deleteTradingCard(clientId, portfolioId);
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onCheck = useCallback((items) => {
        setCheckedItem(items[0]);
    }, [setCheckedItem]);
    const onChange = useCallback((pagination) => {
        onPaginationChange(pagination);

        if (pagination?.currentPage !== searchCriteria.Page && !isLoadingSecurities) {
            setSearchCriteria((arr) => ({ ...arr, Page: pagination?.currentPage }));
        }
    }, [onPaginationChange, searchCriteria.Page, isLoadingSecurities]);
    const handleFilterSubmit = useCallback((data) => {
        onPaginationChange({ currentPage: 1 });
        setSearchCriteria((arr) => ({
            ...arr,
            ...data,
            RatingAgencyIds: data.RatingAgencyIds ? [+data.RatingAgencyIds] : undefined,
            SecurityTypeIds: data.SecurityTypeIds?.length
                ? data.SecurityTypeIds
                : dataSecurityTypes.map(({ value }) => value),
            Page: 1,
        }));
    }, [onPaginationChange, dataSecurityTypes]);

    return (
        <PageSection className="add-position">
            <Preloader
                isLoading={isLoadingCurrency || isLoadingSecurityTypes || isLoadingRatingAgencies}
                error={errorCurrency || errorSecurityTypes || errorRatingAgencies}
            >
                <ContentBox underline={false}>
                    <BreadCrumbs current={t('clientDashboard.portfolioEdit.addPosition')}>
                        <Link
                            to={`/client/${dfsClientId}/portfolios/`}
                            onClick={
                                (e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/`, onCancel)
                            }
                        >
                            {t('clientDashboard.portfolioEdit.portfolios')}
                        </Link>
                        <Link
                            to={`/client/${dfsClientId}/portfolios/${portfolioId}`}
                            onClick={
                                (e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/${portfolioId}`, onCancel)
                            }
                        >
                            {location.state.portfolioName}
                        </Link>
                        <Link to={`/client/${dfsClientId}/portfolios/${portfolioId}/modify`}>
                            {t('clientDashboard.portfolioEdit.title')}
                        </Link>
                    </BreadCrumbs>
                    <Title type={1}>{t('clientDashboard.portfolioEdit.addPosition')}</Title>
                    <div className="content-wrapper">
                        <AddPositionSearchForm
                            searchDisabled={isLoadingSecurities}
                            onSearch={handleSubmit(handleFilterSubmit)}
                            onChangeISIN={(e) => setValue('SearchTerm', e?.target?.value)}
                            typeOptions={dataSecurityTypes}
                            onChangeType={(v) => setValue('SecurityTypeIds', v)}
                            currencyOptions={dataCurrency}
                            onChangeCurrency={(v) => setValue('CurrencyIds', v)}
                            ratingAgencyOptions={dataRatingAgencies || []}
                            onChangeRatingAgency={(v) => setValue('RatingAgencyIds', v)}
                            ratingOptions={dataRatingsByAgencyIdsCached[ratingAgencyId] || []}
                            onChangeRating={(v) => setValue('RatingIds', v)}
                            isLoadingRatings={isLoadingRatings}
                            ratingAgencyValue={ratingAgencyId}
                            ratingValue={values?.RatingIds}
                        />
                        <Preloader isLoading={isLoadingSecurities} error={errorSecurities}>
                            <EmptyContent data={dataSecurities} text={t('addPosition.noSecurities')}>
                                <Table
                                    columns={getSecuritiesColumns(t)}
                                    data={dataSecurities}
                                    onChange={onChange}
                                    rowSelection={{
                                        type: 'radio',
                                        onChange: onCheck,
                                    }}
                                    paging={{
                                        total: totalSecurities,
                                        currentPage: page,
                                        pageSize: PAGE_SIZE,
                                    }}
                                    headerControls={{
                                        selectControlsLabel: {
                                            selectAll: t('headerControls.selectAll'),
                                            reset: t('headerControls.reset'),
                                        },
                                    }}
                                />
                            </EmptyContent>
                        </Preloader>
                    </div>
                    {checkedError && (
                        <Infobox error className="validation-error">
                            <div>{t('portfolios.validation.addPosition')}</div>
                        </Infobox>
                    )}
                    <ButtonsBlockRow
                        leftButton={{
                            text: t('clientDashboard.portfolioEdit.cancel'),
                            onClick: () => history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/modify`),
                        }}
                        primaryButton={{
                            text: t('clientDashboard.portfolioEdit.continue'),
                            disabled: (!checkedItem && !dataSecurities.length)
                                || checkedItem === -1,
                            onClick: onAddPosition,
                        }}
                    />
                </ContentBox>
            </Preloader>
        </PageSection>
    );
}

AddPositionDiscretionary.propTypes = {
    match: PropTypes.shape({
        params: {
            portfolioId: PropTypes.string.isRequired,
            dfsClientId: PropTypes.string.isRequired,
        },
        path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        state: {
            portfolioName: PropTypes.string.isRequired,
        },
    }).isRequired,
};

AddPositionDiscretionary.defaultProps = {};

export default AddPositionDiscretionary;
