import {
    memberIdSelector,
} from 'redux/auth/authSelectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPeriodByFilter } from 'utils';
import AdapterError from 'errors/AdapterError';
import ServiceManager from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptOverviewTotal } from '../adapters/adaptOverviewTotal';
import { YTD } from '../constants/overviewFilters';

export const useOverviewTotal = (configuration) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({ value: '', percent: '', currency: '' });
    const { i18n } = useTranslation();
    const memberId = useSelector(memberIdSelector);

    useEffect(() => {
        const { startDate, endDate } = getPeriodByFilter(YTD);
        const params = startDate === undefined ? {} : {
            StartDate: startDate,
            EndDate: endDate,
        };

        setLoading(true);

        if (!configuration.isLoading) {
            ServiceManager.performanceService('getInvestmentPerformance', [memberId, {
                Language: i18n.language,
                ...params,
            }])
                .then((response) => {
                    try {
                        setData(adaptOverviewTotal(response.data, configuration.data.currency));
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch(handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                ));
        }
    }, [i18n.language, configuration.isLoading]);

    return { data, isLoading, error };
};
