import {
    useState, useEffect, useMemo, useCallback,
} from 'react';
import axios from 'axios';
import HandlerError from 'errors/HandlerError';
import { useManagerProfile } from '../../prodivers/managerProfile';

const API_KEY = global?.AppConfig?.REACT_APP_SURFLY_API_KEY;
const SURFLY_API_URL = global?.AppConfig?.REACT_APP_SURFLY_API_URL;
const EMBED_URL = global?.AppConfig?.REACT_APP_SURFLY_URL;
const HISTORY_URL = `${EMBED_URL}/history/`;
const QUEUE_URL = `${EMBED_URL}/queue/`;
const START_URL = `${EMBED_URL}/start/`;

export const useSurfly = () => {
    const [historyUrl, setHistoryUrl] = useState();
    const [startUrl, setStartUrl] = useState();
    const [queueUrl, setQueueUrl] = useState();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { data: { name, email } } = useManagerProfile();
    const agentData = useMemo(() => ({ name, email }), [name, email]);
    const accessTokenUrl = `${SURFLY_API_URL}/agents/access-token/?api_key=${API_KEY}`;

    const setSurflyURLs = useCallback((token) => {
        setStartUrl(`${START_URL}?agent_token=${token}`);
        setQueueUrl(`${QUEUE_URL}?agent_token=${token}`);
        setHistoryUrl(`${HISTORY_URL}?agent_token=${token}`);
    }, [START_URL, QUEUE_URL, HISTORY_URL]);

    // Request Agent token to build embed urls to add to iframes
    useEffect(() => {
        setLoading(true);
        if (agentData.name && agentData.email) {
            axios.post(accessTokenUrl, agentData)
                .then(({ data: { agent_token: agentToken } }) => {
                    setSurflyURLs(agentToken);
                    setLoading(false);
                })
                .catch((err) => {
                    HandlerError({ setError, setLoading })(err);
                });
        }
    }, [agentData, accessTokenUrl]);

    return {
        startUrl,
        queueUrl,
        historyUrl,
        isLoading,
        error,
    };
};
