import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PageVariant } from 'ui-library';
import history from 'services/history';
import PageVariantWrapper from 'components/PageVariantWrapper';

function Confirmation(props) {
    const { t } = useTranslation();

    const { match: { params: { dfsClientId } }, location: { search } } = props;
    const payment = search?.substr(1).split('=')?.[1];

    return (
        <PageVariantWrapper>
            <PageVariant
                type="success"
                title={t('payments.success')}
                message={payment ? t('payments.executePaymentSuccessContent') : t('payments.executeTransferSuccessContent')}
                actionButtonLabel={t('payments.executeOkText')}
                onActionButtonClick={() => history.push(`/client/${dfsClientId}/dashboard`)}
            />
        </PageVariantWrapper>
    );
}

Confirmation.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
        }),
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string,
    }).isRequired,
};

Confirmation.defaultProps = {};

export default Confirmation;
