import React from 'react';
import PropTypes from 'prop-types';
import { formatBigNumber } from '../../utils/formatting';

const RiskReturnTooltip = ({
    x, y, t, getFormattedNumber,
}) => (
    <div>
        <div className="tooltip-row">
            <b>{t('clientDashboard.portfolio.expectedReturn')}</b>
            <span className="value">
                {getFormattedNumber ? getFormattedNumber(y) : formatBigNumber(y)}
                %
            </span>
        </div>
        <div className="tooltip-row">
            <b>{t('clientDashboard.portfolio.expectedRisk')}</b>
            <span className="value">
                {getFormattedNumber ? getFormattedNumber(x) : formatBigNumber(x)}
                %
            </span>
        </div>
    </div>
);

RiskReturnTooltip.propTypes = {
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    t: PropTypes.func,
    getFormattedNumber: PropTypes.func,
};

RiskReturnTooltip.defaultProps = {
    t: (str) => str,
    getFormattedNumber: (n) => n,
};

export default RiskReturnTooltip;
