import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { Row } from 'ui-library';
import Preloader from 'components/Preloader';
import { createUniqueKey } from 'utils';
import { useDraggableWidgets } from './hooks/useDraggableWidgets';
import DashboardColumn from './components/DashboardColumn';
import WidgetsObject from './components/WidgetsObject';

import './AdvisoryDashboard.css';

const AdvisoryDashboard = ({ data }) => {
    const dashboard = useRef(null);
    const widgetsObject = WidgetsObject(data);
    const {
        isLoading, error, columns, widths, getWidgets, onDragEnd,
    } = useDraggableWidgets(dashboard);

    return (
        <Preloader isLoading={isLoading} error={error}>
            <div className="advisory-dashboard dashboard" ref={dashboard}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Row>
                        {columns.map((column, index) => (
                            <DashboardColumn
                                key={createUniqueKey(index, column)}
                                column={column}
                                widths={widths}
                                widgets={getWidgets(column)}
                                widgetsObject={widgetsObject}
                            />
                        ))}
                    </Row>
                </DragDropContext>
            </div>
        </Preloader>
    );
};

const hooksResponse = PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    error: PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string,
    }),
});

AdvisoryDashboard.propTypes = {
    data: PropTypes.shape({
        overview: hooksResponse,
        tasks: hooksResponse,
        news: hooksResponse,
        configuration: hooksResponse,
        calendar: hooksResponse,
        nextUp: hooksResponse,
        clients: hooksResponse,
        listCurrencies: hooksResponse,
        markets: hooksResponse,
        currencies: hooksResponse,
        messages: hooksResponse,
        notes: hooksResponse,
        allocation: hooksResponse,
        onRowClick: PropTypes.func,
        onNotesRowClick: PropTypes.func,
    }).isRequired,
};

export default AdvisoryDashboard;
