export const TYPE_CURRENCY = 'currency';
export const TYPE_PERCENT = 'percent';
export const TYPE_NUMBER = 'number';
export const TYPE_TEXT = 'text';

export const VALUE_TYPES = [
    TYPE_CURRENCY,
    TYPE_PERCENT,
    TYPE_NUMBER,
    TYPE_TEXT,
];

export const OVERVIEW_SIZE_SMALL = 'small';
export const OVERVIEW_SIZE_LAGRE = 'large';

export const OVERVIEW_SIZES = [
    OVERVIEW_SIZE_SMALL,
    OVERVIEW_SIZE_LAGRE,
];
