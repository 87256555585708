import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ADVISORY, DEPOSITS, DISCRETIONARY, EXECUTION_ONLY, generateObject,
} from 'constants/portfolioProducts';
import {
    useStage,
    useProducts,
    useOnBoardingData,
    useSaveAndUpdateGoal,
    useGoalCreation,
    useGoalSummary,
    useSignedDocument,
} from 'domain/OnBoarding';
import { useModelPortfolio } from 'domain/Portfolio';
import { getAllocations } from 'adaptors/adaptAllocations';
import { formatDate } from 'utils/datetime';
import { useAccounts } from 'hooks/useAccounts';
import { useFormatting } from 'locale';
import { genarateDFSClientId } from 'utils';
import { aggregateModifiedPositions } from '../adapters/aggregateModifiedPositions';
import { useKnowledgeExperience } from '../pages/KnowledgeExperience/hooks/useKnowledgeExperience';

const STAGE_DATA_TIMEOUT = 1000;

export const useOnBoardingStage = (options = {}) => {
    const {
        clientId, changeStep = () => {},
    } = options;
    const { t, i18n: { language } } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();

    const [riskCategory, setRiskCategory] = useState(null);
    const [loadingDelay, setLoadingDelay] = useState(true);
    const {
        fIdentityInProgress,
        saveProductOffer,
        saveProduct,
        saveProposalId,
        getRiskCategory,
        saveKnowledgeAndExperience,
        saveGoalDetails,
        saveGoalChanges,
        saveModifiedPositions,
        saveReviewData,
        saveCreatedGoal,
        saveIban,
        saveSignedDocumentId,
    } = useOnBoardingData({ clientId });

    // Get stage
    const {
        stages = [],
        clientStage,
        errorClientStage,
        isLoadingClientStage,
        getClientStage,
    } = useStage({ clientId });

    useEffect(() => {
        if (stages?.length > 0) getClientStage();
    }, [getClientStage, stages]);
    useEffect(() => {
        if (isLoadingClientStage) {
            setTimeout(() => setLoadingDelay(false), STAGE_DATA_TIMEOUT);
        }
    }, [isLoadingClientStage]);

    // 1 step - product
    const {
        product,
        productError,
        productIsLoading,
        getProductData,
    } = useProducts({ clientId });

    useEffect(() => {
        if (clientStage >= 1) getProductData();
    }, [clientStage, getProductData]);
    useEffect(() => {
        if (product?.product) {
            saveProductOffer(product.productOffer);
            saveProduct(product.product);
            saveProposalId(product.proposal?.Id);
        }
    }, [product, saveProduct, saveProductOffer, saveProposalId]);

    // 2 step - risk profile
    useEffect(() => {
        if (clientStage >= 2) {
            (async () => {
                try {
                    setLoadingDelay(true);
                    const risk = await getRiskCategory();

                    setRiskCategory(risk);
                    setLoadingDelay(false);
                } catch (err) { /* Don't throw an error */
                }
            })();
        }
    }, [clientStage, getRiskCategory]);

    // 3 step - K&E
    const {
        resultsAdapted, errorResults, isLoadingResults, getQuestionnaireResults,
    } = useKnowledgeExperience({ contactId: clientId, productId: +product?.product?.id });

    useEffect(() => {
        if (clientStage >= 3) getQuestionnaireResults();
    }, [clientStage, getQuestionnaireResults]);
    useEffect(() => {
        if (clientStage >= 3 && resultsAdapted?.id) {
            saveKnowledgeAndExperience({
                hasPositiveInstruments: resultsAdapted?.positive?.length > 0,
            });
        }
    }, [clientStage, resultsAdapted, saveKnowledgeAndExperience]);

    // 4 step - Goal
    const {
        goal, isLoadingGetGoal, getGoal,
    } = useSaveAndUpdateGoal({ clientId });
    const goalDetails = useMemo(() => {
        const { proposal } = product;

        return {
            id: goal?.Id,
            goalName: proposal?.Name,
            selectedGoal: `${goal?.GoalTemplateId || 'no-goal'}`,
            selectedCurrency: {
                value: proposal?.CalculationCurrency?.Id,
                label: proposal?.CalculationCurrency?.CurrencyCode,
            },
            recurringPaymentFrequency: {
                value: 'Monthly', label: 'Monthly',
            },
            initialInvestment: proposal?.RecommendedAmount,
            targetValue: goal?.TargetAmount,
            targetDate: goal?.DueDate,
            recurringPayment: goal?.Funding?.Amount,
        };
    }, [goal, product]);
    const {
        data: modelPortfolioObj, error: errorModelPortfolio,
        isLoading: isLoadingModelPortfolio, getModelPortfolioId,
    } = useGoalCreation({ productId: +product?.product?.id, riskCategoryId: riskCategory?.Id });
    const {
        isLoading: isLoadingModel, error: errorModel, getModelPortfolio,
    } = useModelPortfolio();
    const optionsForPositions = useMemo(() => ({
        portfolioValue: goalDetails.initialInvestment,
        portfolioCurrency: goalDetails.selectedCurrency.label,
        isNew: false,
        nameLength: 35,
        positionLink: `/onboarding/${genarateDFSClientId(clientId)}/step/5/modify/position`,
        getFormattedNumber,
        getFormattedCurrency,
        language,
        t,
    }), [clientId, getFormattedCurrency, getFormattedNumber, goalDetails, language, t]);

    useEffect(() => {
        if (clientStage >= 4) getGoal();
    }, [clientStage, getGoal]);
    useEffect(() => {
        if (clientStage >= 4 && goalDetails.goalName) {
            saveGoalDetails(goalDetails);
        }
    }, [clientStage, goalDetails, saveGoalDetails]);
    useEffect(() => {
        if (clientStage >= 4) getModelPortfolioId();
    }, [clientStage, getModelPortfolioId]);
    useEffect(() => {
        if (clientStage >= 4 && product.proposal && !isLoadingModelPortfolio) {
            const { proposal } = product;
            const { Id: modelId } = modelPortfolioObj || {};

            if (!proposal.ModelPortfolioId) {
                (async () => {
                    const model = modelId ? await getModelPortfolio(modelId) : { Positions: [] };
                    const positions = aggregateModifiedPositions({
                        positionsBase: model?.Positions,
                        positionsCurrent: proposal.Positions,
                        adaptOptions: optionsForPositions,
                    });

                    saveModifiedPositions(positions);
                })();
            } else if (modelId && proposal.ModelPortfolioId !== modelId) {
                saveGoalChanges(proposal.ModelPortfolioId.toString());
            }
        }
    }, [clientStage, modelPortfolioObj, product, optionsForPositions, isLoadingModelPortfolio]);

    // 5 step - Review
    useEffect(() => {
        if (clientStage >= 5 && product.proposal) {
            const { proposal } = product;

            saveReviewData({
                modelPortfolioId: proposal?.ModelPortfolioId,
                benchmarkId: proposal?.BenchmarkId,
            });
        }
    }, [clientStage, product, saveReviewData]);

    // 6 step - Identification/Personal
    const {
        dataPortfolio, errorPortfolio, isLoadingPortfolio, getCreatedPortfolio,
    } = useGoalSummary({ clientId });
    const goalPortfolioId = useMemo(() => goal?.Portfolios?.[0]?.Id, [goal]);
    const {
        dataRaw: accounts,
        isLoading: isLoadingAccounts,
        errorAccounts,
    } = useAccounts(clientId, goalPortfolioId || dataPortfolio?.Id);
    const Iban = useMemo(() => (accounts || [])?.filter(
        ({ Account: { Type } }) => Type.Id === 1,
    )?.[0]?.Account?.Iban, [accounts]);

    useEffect(() => {
        if (clientStage >= 6 && goalDetails.goalName && !goalPortfolioId) {
            getCreatedPortfolio();
        }
    }, [clientStage, getCreatedPortfolio, goalPortfolioId, goalDetails.goalName]);
    useEffect(() => {
        if (clientStage >= 6 && goalDetails.goalName && product.proposal) {
            const { proposal } = product;
            const projectionYears = formatDate(goalDetails.targetDate, 'YYYY') - formatDate(new Date(), 'YYYY');
            const createdGoal = {
                Years: projectionYears,
                ProductId: +product?.product?.id,
                ProductName: product?.product?.name,
                CurrencyId: +goalDetails?.selectedCurrency?.value,
                Allocations: getAllocations(proposal?.Positions),
                ModelPortfolioId: proposal?.ModelPortfolioId,
                PortfolioProposalId: proposal?.Id,
                PortfolioId: goalPortfolioId || dataPortfolio?.Id,
            };

            saveCreatedGoal(createdGoal);
        }
    }, [clientStage, goalDetails, goalPortfolioId, dataPortfolio?.Id, product, saveCreatedGoal]);
    useEffect(() => {
        if (clientStage >= 6 && Iban) {
            saveIban(Iban);
        }
    }, [Iban, clientStage, saveIban]);

    // 7 step - Contract
    const {
        data: document, error: errorDocument, isLoading: isLoadingDocument, getSignedDocument,
    } = useSignedDocument({ clientId });

    useEffect(() => {
        if (clientStage >= 7) getSignedDocument();
    }, [clientStage, getSignedDocument]);
    useEffect(() => {
        if (clientStage >= 7 && document) {
            saveSignedDocumentId(document?.id);
        }
    }, [clientStage, document, saveSignedDocumentId]);

    // Loading, error handling
    const isLoading = isLoadingClientStage || loadingDelay
        || productIsLoading
        || isLoadingResults
        || isLoadingGetGoal
        || isLoadingModelPortfolio
        || isLoadingModel
        || isLoadingPortfolio
        || isLoadingAccounts
        || isLoadingDocument;
    const error = errorClientStage
        || productError
        || errorResults
        || errorModelPortfolio
        || errorModel
        || errorPortfolio
        || errorAccounts
        || errorDocument;

    // Step changing
    const hasRiskProfileStep = useMemo(() => generateObject({
        [ADVISORY]: true,
        [DISCRETIONARY]: true,
        [EXECUTION_ONLY]: false,
        [DEPOSITS]: true,
    }), []);

    useEffect(() => {
        if (fIdentityInProgress === true) return;
        if (clientStage > 0 && !isLoading && product?.product?.name) {
            if (clientStage === 1 && hasRiskProfileStep.getByName(product.product.name) === false) {
                setTimeout(() => changeStep(3), STAGE_DATA_TIMEOUT);
            } else {
                setTimeout(() => changeStep(clientStage), STAGE_DATA_TIMEOUT);
            }
        }
    }, [fIdentityInProgress, changeStep, clientStage, hasRiskProfileStep, isLoading, product]);

    return {
        isLoading,
        error,
    };
};
