import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { editAllocationsForExecute } from '../../../adapters/adaptPortfolioData';
import { MODIFY } from '../../../constants';

const executeProcessByAction = (action, clientId, portfolioId, params) => {
    if (action === MODIFY) {
        return new Promise((resolve, reject) => {
            ServiceManager.portfolioManagement('postTradingCartOrder', [clientId, portfolioId])
                .then(() => {
                    ServiceManager.portfolioManagement('deleteTradingCartOrder', [clientId, portfolioId])
                        .then((response) => {
                            resolve(response);
                        }).catch((error) => reject(error));
                }).catch((error) => reject(error));
        });
    }

    return ServiceManager.portfolioManagement('postPortfolioRebalance', [clientId, portfolioId, params]);
};

export const useExecuteStrategy = (
    clientId, dfsClientId, portfolioId, actionType,
) => {
    const [isAccepting, setAccepting] = useState(false);
    const [errorAccepting, setError] = useState(null);

    const { t } = useTranslation();

    const onAcceptExecute = useCallback(({ modelPortfolioId, allocations }) => {
        setAccepting(true);
        setError(null);

        const params = {
            ModelPortfolioId: allocations ? null : modelPortfolioId,
            InstrumentAllocations: allocations ? editAllocationsForExecute(allocations) : [],
        };

        executeProcessByAction(actionType, clientId, portfolioId, params)
            .then(() => {
                try {
                    setAccepting(false);
                    history.push(
                        `/client/${dfsClientId}/success-page`,
                        {
                            title: t('confirm.title'),
                            message: t('confirmation.acceptExecuteContent'),
                            confirmButton: t('confirmation.backToPortfolio'),
                            redirectURL: `/client/${dfsClientId}/portfolios/${portfolioId}`,
                        },
                    );
                } catch (err) {
                    setAccepting(false);
                    setError(err);
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading: setAccepting }),
            ));
    }, [clientId, dfsClientId, portfolioId]);

    return { onAcceptExecute, isAccepting, errorAccepting };
};
