import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import update from 'lodash/update';
import history from 'services/history';
import FormIo from 'components/FormIo';
import { Infobox, Modal, Button } from 'ui-library';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { useRegistration } from '../../hooks/useRegistration';
import './Registration.css';

const Registration = (props) => {
    const { onNext, onPageChange } = props;
    const { t } = useTranslation();
    const [formio, setFormio] = useState();
    const [isValid, setIsValid] = useState(true);
    const [submissionData, setSubmissionData] = useState({});
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    // Helper hooks
    const {
        data: schema, isLoading, error,
        errorSave, isSaving, saveData,
    } = useRegistration();

    // Callbacks
    const onChange = useCallback(({ isValid: isValidForm }, { changes }) => {
        (changes || []).forEach((change) => {
            if (!change?.component?.disabled && !change?.flags?.noValidate) {
                setSubmissionData((obj) => update(
                    obj,
                    change.instance.path,
                    () => change.value,
                ));
            }
        });
        setIsValid(isValidForm);
    }, []);
    const onPrevClick = useCallback(() => {
        history.goBack();
    }, []);
    const onNextClick = useCallback(async () => {
        await formio.submit();

        if (!isValid) return;

        setShowConfirmationModal(true);
    }, [formio, isValid, onNext, onPageChange, saveData, submissionData, t]);

    const startOnBoarding = useCallback(async () => {
        try {
            const { contactId, clientName } = await saveData(submissionData);

            onNext({ contactId, clientName });
        } catch (e) {
            console.error(e);
        } finally {
            setShowConfirmationModal(false);
        }
    }, [submissionData, onNext]);

    const createProspect = useCallback(async () => {
        try {
            await saveData(submissionData);
            setShowConfirmationModal(false);
            onPageChange('success');
        } catch (e) {
            console.error(e);
        } finally {
            setShowConfirmationModal(false);
        }
    }, [submissionData, onPageChange]);

    return (
        <>
            <OnBoardingBaseTemplate
                title={t('onBoarding.addNewClient')}
                error={error}
                isLoading={isLoading}
                classNameContent="registration"
                nextButton={{
                    loading: isSaving,
                    onClick: onNextClick,
                }}
                prevButton={{
                    loading: isSaving,
                    onClick: onPrevClick,
                }}
                buttonsRowError={errorSave && (<Infobox error>{errorSave.message}</Infobox>)}
            >
                <FormIo
                    form={schema}
                    options={{
                        readOnly: false,
                        noAlerts: false,
                        renderMode: 'HWM',
                    }}
                    onChange={onChange}
                    formReady={setFormio}
                />
            </OnBoardingBaseTemplate>
            <Modal
                title={t('clientDashboard.confirm')}
                visible={showConfirmationModal}
                footer={(
                    <div>
                        <Button
                            type="primary"
                            onClick={startOnBoarding}
                        >
                            {t('registration.startOnBoarding')}
                        </Button>
                        <Button
                            type="secondary"
                            onClick={createProspect}
                        >
                            {t('registration.createProspect')}
                        </Button>
                        <Button
                            type="secondary"
                            onClick={() => {
                                setShowConfirmationModal(false);
                            }}
                        >
                            {t('confirmation.cancel')}
                        </Button>
                    </div>
                )}
            >
                {t('registration.registrationConfirmContent')}
            </Modal>
        </>
    );
};

Registration.propTypes = {
    onNext: PropTypes.func,
    onPageChange: PropTypes.func,
};

Registration.defaultProps = {
    onNext: () => { },
    onPageChange: () => { },
};

export default Registration;
