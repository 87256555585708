import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { merge } from 'lodash';
import { useFormatting } from '../../../locale';
import { useClientsSchema } from '../provider/ClientsSchemaProvider';
import { useClientList } from '../../../hooks/useClientList';
import { ClientSchemaManager } from '../utils/ClientSchemaManager';
import { adaptClientList } from '../adapters/adaptClientList';
import Preloader from '../../../components/Preloader';
import { COUNT_OF_PAGE, REJECTED_STATUS } from '../constants';

const withClientTable = (Component, isProspects, sourceColumns) => ({ search = '', ...props }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState([]);
    const [sort, setSort] = useState({
        key: '',
        direction: '',
    });
    const {
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
    } = useFormatting();
    const {
        schemasColumns: {
            data: schemasData,
            isLoading: schemasLoading,
        },
        schemasLists,
    } = useClientsSchema();
    const { t } = useTranslation();

    const columns = useMemo(
        () => (
            ClientSchemaManager.generateColumns({
                dataList: schemasLists?.data,
                columns: sourceColumns(getFormattedNumber),
                fields: schemasData?.fields,
            })
        ),
        [schemasLists, schemasData, sourceColumns],
    );

    const onChangeSort = ({ key, direction }) => {
        setSort(key
            ? { key, direction }
            : {});
    };

    const where = useMemo(
        () => (merge(
            {
                relationshipInformation: {
                    isProspect: { eq: isProspects },
                    contactStatus: { label: { neq: REJECTED_STATUS } },
                },
                personalInformation: { displayName: { contains: search } },
            },
            ClientSchemaManager.generateFilters(
                filters.map((filter) => ({
                    ...filter, value: filter.value.map((id) => id),
                })),
                columns,
            ),
        )),
        [filters, isProspects, columns, search],
    );

    const order = useMemo(() => (
        ClientSchemaManager.generateSortValue({
            key: sort.key,
            direction: sort?.direction,
            columns,
        })
    ), [sort, columns]);

    const {
        data = [],
        isLoading,
        totalCount,
        getCommon,
        error,
    } = useClientList(
        {
            includeTotalCount: true,
            skip: (currentPage - 1) * COUNT_OF_PAGE,
            take: currentPage * COUNT_OF_PAGE,
            where,
            order,
        },
        `${ClientSchemaManager.generateContactFragment(columns)}`,
    );

    useEffect(() => {
        if (!schemasLoading) {
            getCommon();
        }
    }, [schemasLoading]);

    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
    }, [search]);

    const onFilter = (filter) => {
        const uniqFilters = filters.filter(
            ({ key }) => key !== filter.key,
        );

        if (filter.value.length > 0) {
            setFilters([...uniqFilters, filter]);
        } else {
            setFilters(uniqFilters);
        }
    };

    const adaptedData = adaptClientList({
        t,
        data,
        columns,
        isLoading,
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
        pageSize: COUNT_OF_PAGE,
    });

    return (
        <Preloader isLoading={false} error={error}>
            <Component
                data={adaptedData}
                columns={columns}
                sort={sort}
                paging={{
                    totalCount,
                    currentPage,
                    pageSize: COUNT_OF_PAGE,
                }}
                onChangePagination={setCurrentPage}
                onChangeFilter={onFilter}
                onChangeSort={onChangeSort}
                {...props}
            />
        </Preloader>
    );
};

export default withClientTable;
