/* eslint-disable max-len */
export const orderBreak = [4, 2];

export const stepMockData = (t) => [
    [
        {
            name: 'assets',
            title: t('riskProfile.assets'),
            description: t('riskProfile.assetsDescription'),
            questions: [
                'liquidity',
                'investmentsHoldings',
                'realEstate',
                'otherAssets',
            ],
        },
        {
            name: 'liabilities',
            title: t('riskProfile.liabilities'),
            description: t('riskProfile.liabilitiesDescription'),
            questions: [
                'mortgages',
                'otherCredits',
            ],
        },
        {
            title: t('riskProfile.result'),
            label: t('riskProfile.netAssets'),
            description: t('riskProfile.netAssetsResultDescription'),
        },
    ],
    [
        {
            name: 'income',
            title: t('riskProfile.income'),
            description: t('riskProfile.incomeDescription'),
            questions: [
                'annualRecurringIncome',
                'incomeFromInvestments',
            ],
        },
        {
            name: 'expenses',
            title: t('riskProfile.expenses'),
            description: t('riskProfile.expensesDescription'),
            questions: [
                'costOfLiving',
                'expectedTaxes',
            ],
        },
        {
            title: t('riskProfile.result'),
            label: t('riskProfile.cashFlow'),
            description: t('riskProfile.incomeExpensesResultDescription'),
        },
    ],
];

export const step4Data = (t) => ({
    key: 'Review',
    title: t('riskProfile.review'),
    sections: [
        {
            title: t('riskProfile.riskWillingness'),
            description: [
                t('riskProfile.riskWillingnessRiskLow'),
                t('riskProfile.riskWillingnessRiskRatherLow'),
                t('riskProfile.riskWillingnessRiskMedium'),
                t('riskProfile.riskWillingnessRiskRatherHigh'),
                t('riskProfile.riskWillingnessRiskHigh'),
            ],
            redoButton: true,
        },
        {
            title: t('riskProfile.riskCapacity'),
            description: [
                t('riskProfile.riskCapacityRiskLow'),
                t('riskProfile.riskCapacityRiskRatherLow'),
                t('riskProfile.riskCapacityRiskMedium'),
                t('riskProfile.riskCapacityRiskRatherHigh'),
                t('riskProfile.riskCapacityRiskHigh'),
            ],
            redoButton: true,
        },
        {
            title: t('riskProfile.riskProfile'),
            description: [
                t('riskProfile.riskProfileRiskLow'),
                t('riskProfile.riskProfileRiskRatherLow'),
                t('riskProfile.riskProfileRiskMedium'),
                t('riskProfile.riskProfileRiskRatherHigh'),
                t('riskProfile.riskProfileRiskHigh'),
            ],
            changeRisk: true,
        },
        /* {
            title: 'Knowledge & experience',
            description: [
                'Lorem ipsum dolor sit amet, aliquyam erat consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat sed diam voluptua consetetur.',
                'Lorem ipsum dolor sit amet, aliquyam erat consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat sed diam voluptua consetetur.',
                'Lorem ipsum dolor sit amet, aliquyam erat consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat sed diam voluptua consetetur.',
                'Lorem ipsum dolor sit amet, aliquyam erat consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat sed diam voluptua consetetur.',
                'Lorem ipsum dolor sit amet, aliquyam erat consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat sed diam voluptua consetetur.',
            ],
            redoButton: true,
            href: true,
        }, */
    ],
    infobox: [
        t('riskProfile.infoBoxLower'),
        t('riskProfile.infoBoxHigher'),
    ],
});
