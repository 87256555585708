import {
    useCallback, useEffect, useMemo, useReducer, useState,
} from 'react';
import {
    object, date, number, string, array,
} from 'yup';
import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { memberProfileSelector } from 'redux/auth/authSelectors';
import { adaptParticipants, adaptTopics, adaptTypes } from 'adaptors/adaptNewInteraction';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
    momentWithCeilMinutes, addMinutesToDate, dateTimeFormat, getDatesDifference,
} from '../utils/datetime';
import { useInteractionModify, useInteractionFields } from '../domain/Interactions';

const initialState = {
    data: {},
    error: null,
    isLoading: false,
    errorCreating: null,
    values: {},
    formErrors: {},
    isSearching: {},
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setErrorCreating':
            return { ...state, errorCreating: action.payload };
        case 'setValues':
            return { ...state, values: action.payload };
        case 'setFormErrors':
            return { ...state, formErrors: action.payload };
        case 'setIsSearching':
            return { ...state, isSearching: action.payload };
        default: return state;
    }
};
let searchParticipantsTimer;

export const useNewInteraction = (
    contactId, isLoadingTypesAndTopics, types, topics, clientName,
) => {
    const { t, i18n: { language } } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);

    const member = useSelector(memberProfileSelector);
    const [clients, setClients] = useState([contactId]);
    const [allMembers, setMembers] = useState([member.Id]);

    const participantsValidate = useCallback((value) => {
        if (value.length) {
            const isClient = value
                ?.find((i) => clients?.find((c) => c === i));

            const isMember = value
                ?.find((i) => allMembers?.find((c) => c === i));

            return !!isClient && !!isMember;
        }

        return false;
    }, [clients, allMembers]);

    const schema = useMemo(() => object().shape({
        from: date().required(t('interaction.validation.fillDateTime')).test('from-before-to', t('interaction.validation.fromBeforeTo'), function (value) {
            return getDatesDifference(this?.parent?.to, value) >= 0;
        }),
        to: date().required(t('interaction.validation.fillDateTime')),
        initiated: number().required(t('validation.mandatoryField')),
        participants: array().required(t('validation.mandatoryField'))
            .test('participants-moreThanOne', t('interaction.validation.participantsMoreThanOne'), participantsValidate),
        type: number().required(t('validation.mandatoryField')),
        topic: number().required(t('validation.mandatoryField')),
        comment: string().required(t('validation.mandatoryField')).min(10, t('interaction.commentsHelpText')),
    }), [t, participantsValidate]);
    const resolver = useYupValidationResolver(schema);
    const {
        register, handleSubmit, errors, setValue, reset, formState, trigger, control, watch,
    } = useForm({ resolver });

    useEffect(() => {
        register('from');
        register('to');
    }, [register]);

    // Initial
    const values = watch();

    const { getParticipantsData } = useInteractionFields(
        { memberId: member.Id },
    );
    const { createInteraction: createInteractionBase } = useInteractionModify(
        { clientId: contactId },
    );
    const participants = useMemo(() => {
        if (contactId && !clientName) return [];

        return adaptParticipants(contactId
            ? [{ Id: contactId, clientName }, member] : [member], contactId);
    }, [clientName, contactId, member]);
    const initiated = useMemo(() => {
        if (contactId && !clientName) return [];

        return adaptParticipants(contactId
            ? [{ Id: contactId, clientName }, member] : [member]);
    }, [clientName, contactId, member]);
    const getInitialValues = useCallback(() => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });
        if (!isLoadingTypesAndTopics && participants.length > 0) {
            try {
                const topicsAdapted = adaptTopics(topics);
                const defaultValues = {
                    from: momentWithCeilMinutes(),
                    to: momentWithCeilMinutes(addMinutesToDate(
                        new Date(), 60, dateTimeFormat,
                    )),
                    participants: contactId ? [contactId, member.Id] : [member.Id],
                };

                dispatch({
                    type: 'setData',
                    payload: {
                        initiated,
                        participants,
                        types: adaptTypes(types),
                        topics: topicsAdapted,
                    },
                });
                dispatch({ type: 'setValues', payload: defaultValues });
                dispatch({ type: 'setIsLoading', payload: false });

                return defaultValues;
            } catch (err) {
                throw new AdapterError(err);
            }
        }

        return null;
    }, [contactId, participants, isLoadingTypesAndTopics, topics, types, member.Id]);

    useEffect(() => {
        const initialValues = getInitialValues();

        if (initialValues) reset(initialValues);
    }, [getInitialValues, reset]);

    // Validation
    const formErrors = Object.keys(errors || {}).reduce((acc, key) => ({
        ...acc, [key]: errors[key].message,
    }), {});


    // Participants dynamic search
    const getParticipants = useCallback((search) => new Promise(async (resolve, reject) => {
        dispatch({ type: 'setIsSearching', payload: { ...state.isSearching, participants: true } });
        try {
            const membersLookupParams = { lookUpTerm: search, language };
            const contactsByFilterParams = { Search: search, Offset: 0, Limit: 10 };

            const { members, contacts } = await getParticipantsData(
                { membersLookupParams, contactsByFilterParams },
            );

            setClients((data) => [...data, ...contacts.map((i) => i.Id)]);
            setMembers((data) => [...data, ...members.map((i) => i.MemberId)]);

            const adaptedParticipants = adaptParticipants([...contacts, ...members]);

            resolve(adaptedParticipants);
        } catch (err) {
            reject(err);
        }
    }), [language, state.isSearching]);
    const onSearch = useCallback((type) => (value) => {
        if (type === 'participants') {
            clearTimeout(searchParticipantsTimer);
            const currentParticipants = uniqBy(state.data[type]
                .filter((item) => state.values[type].includes(item.value)), 'value');

            if (value.length >= 3) {
                dispatch({ type: 'setIsSearching', payload: { ...state.isSearching, participants: true } });
                searchParticipantsTimer = setTimeout(() => {
                    getParticipants(value).then((data) => {
                        dispatch({
                            type: 'setData',
                            payload: { ...state.data, participants: uniqBy([...currentParticipants, ...data], 'value') },
                        });
                        dispatch({ type: 'setIsSearching', payload: { ...state.isSearching, participants: false } });
                    });
                }, 500);
            } else if (value.length === 0) {
                dispatch({ type: 'setData', payload: { ...state.data, participants: currentParticipants } });
                dispatch({ type: 'setIsSearching', payload: { ...state.isSearching, participants: false } });
            }
        }
    }, [getParticipants, state.data, state.isSearching, state.values]);

    // Callbacks
    const onInteractionChange = useCallback((type) => (value) => {
        dispatch({ type: 'setFormErrors', payload: [] });
        let newValue = value;

        if (type === 'initiated') {
            if (value === null) {
                newValue = undefined;
            }
        }

        if (type === 'email') {
            newValue = value?.target ? value?.target.checked : value;
        }

        setValue(type, newValue);
        dispatch({ type: 'setValues', payload: { ...state.values, [type]: newValue } });
        if (formState.isSubmitted) {
            trigger(type);
            if (type === 'to') trigger('from');
        }
    }, [setValue, trigger, state.values, formState.isSubmitted]);
    const onNewInteractionCommentChange = useCallback((e) => {
        setValue('comment', e.target.value);
        if (formState.isSubmitted) {
            trigger('comment');
        }
    }, [state.values, dispatch, trigger, setValue]);

    // Creation
    const createInteraction = useCallback((params) => new Promise((resolve, reject) => {
        dispatch({ type: 'setErrorCreating', payload: null });

        const postInteractionOptions = {
            DateFrom: new Date(params.from).toUTCString(),
            DateTo: new Date(params.to).toUTCString(),
            InitiatedById: params.initiated,
            Participants: params.participants,
            InteractionTypeId: params.type,
            InteractionTopicId: params.topic,
            SendEmail: params?.email ?? false,
        };
        const journalContactId = contactId
            || state.values.participants.filter((item) => item !== member.Id
                && !allMembers?.find((i) => i === item))?.[0];

        const postJournalOptions = { Content: values.comment };

        try {
            const response = createInteractionBase(
                { journalContactId, postInteractionOptions, postJournalOptions },
            );

            resolve(response);
        } catch (err) {
            dispatch({ type: 'setErrorCreating', payload: new ServerError(err) });
            reject(err);
        }
    }),
    [
        contactId,
        member.Id,
        values.comment,
        state.values.from,
        state.values.initiated,
        state.values.participants,
        state.values.to,
        state.values.topic,
        state.values.type,
        state.values.email,
    ]);

    return {
        register,
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        errorCreating: state.errorCreating,
        values: {
            ...state.values,
            comment: values.comment,
        },
        formErrors,
        onInteractionChange,
        onNewInteractionCommentChange,
        onNewInteractionHandleSubmit: handleSubmit,
        createInteraction,
        isSearching: state.isSearching,
        onSearch,
        control,
    };
};
