export const fileToString = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (readerError) => reject(readerError);
});

export const adaptFiles = async (contactId, files, multiple = false) => {
    const adaptFile = async (file) => {
        // eslint-disable-next-line no-useless-catch
        try {
            const { name } = file;
            const fileString = await fileToString(file);
            const splits = fileString.split(';base64,');

            return {
                originalFilename: name,
                retrieveFileAsName: name,
                ownerIds: [contactId],
                metadataReferenceId: 'ClientDataDocument',
                file: splits[1],
            };
        } catch (error) {
            throw error;
        }
    };
    const filesAdapted = await Promise.all(await files.map(adaptFile));

    return multiple ? filesAdapted : filesAdapted?.[0];
};
