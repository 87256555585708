import { checkNullData } from 'utils';
import { validateNumber } from 'utils/formatting';
import advisoryImg from 'assets/images/products/AdvisoryImg.jpg';
import executionImg from 'assets/images/products/ExecutionImg.jpg';
import discretionaryImg from 'assets/images/products/DiscretionaryImg.jpg';
import { PRODUCT_OFFERS_ID } from '../constants/constants';

const getProductImage = {
    6: { image: advisoryImg },
    4: { image: executionImg },
    7: { image: discretionaryImg },
};

const getProductDescription = (item, currencyCode = '', getFormattedNumber) => item.Description
    .replace('[Fee]', `${getFormattedNumber(validateNumber(item.Fees[0]?.FeeValue) * 100)}%`)
    .replace('[Min.Investment]', getFormattedNumber((item.MinimumInvestmentValue)))
    .replace('[PlatformCurrency]', currencyCode);

export const adaptProducts = (data, globalSettings, t, getFormattedNumber, productOffer) => {
    if (checkNullData(data) || checkNullData(data.ProductOffers)) {
        return {
            Name: t('portfolioRiskProfile.productSelection'),
            title: t('portfolioRiskProfile.clients'),
            products: [],
        };
    }

    const productOfferId = productOffer?.id || PRODUCT_OFFERS_ID;

    const currencyObj = globalSettings.find(({ Key }) => Key === 'DefaultCurrencyId');
    const currencyId = +currencyObj?.Value;

    const { Products, Currencies } = data.ProductOffers.find(
        ({ Id }) => Id === Number(productOfferId),
    );
    const currency = currencyId && Currencies.find(({ Id }) => Id === currencyId);

    return {
        Name: t('portfolioRiskProfile.productSelection'),
        title: t('portfolioRiskProfile.clients'),
        products: Products.map(({
            Id, Name, MinimumInvestmentValue, ...rest
        }) => ({
            id: Id,
            name: Name,
            description: getProductDescription(rest, currency?.CurrencyCode, getFormattedNumber),
            minimumInvestmentValue: MinimumInvestmentValue,
            ...getProductImage[Id],
        })),
        currencies: Currencies,
    };
};
