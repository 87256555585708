import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    ContentBox, Modal, Paragraph, Title,
} from 'ui-library';
import { getClientId } from 'utils';
import history from 'services/history';
import PageSection from 'components/PageSection';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import { InteractionDetails } from 'templates/Interaction';
import { useTypesAndTopicsInteraction } from 'hooks/useTypesAndTopicsInteraction';
import { useNewInteraction } from 'hooks/useNewInteraction';
import { useClientProfile } from 'prodivers/clientProfile';
import './NewInteraction.css';

const NewInteraction = ({ match }) => {
    const { t } = useTranslation();
    const { params: { dfsClientId } } = match;
    const clientId = getClientId(dfsClientId);
    const [isSending, setSending] = useState(false);

    // Client Provider
    const { clientName } = useClientProfile();

    // Hooks
    const {
        isLoading: isLoadingTypesAndTopics, types, topics,
    } = useTypesAndTopicsInteraction(true);
    const {
        register,
        data,
        isLoading,
        error,
        formErrors,
        values: newInteractionValues,
        onInteractionChange,
        onNewInteractionCommentChange,
        onNewInteractionHandleSubmit,
        createInteraction,
        isSearching,
        onSearch,
        control,
    } = useNewInteraction(clientId, isLoadingTypesAndTopics, types, topics, clientName);

    // Callbacks
    const onCancel = () => {
        Modal.confirm({
            title: t('interaction.cancelInteraction'),
            content: t('interaction.cancelInteractionContent'),
            okText: t('clientDashboard.yes'),
            cancelText: t('clientDashboard.no'),
            onOk: () => {
                history.push(`/client/${dfsClientId}/interactions`);
            },
            className: 'accept-interaction',
        });
    };
    const onCreate = (params) => {
        const modal = Modal.confirm({
            title: t('interaction.createInteraction'),
            content: t('interaction.createInteractionContent'),
            okText: t('clientDashboard.yes'),
            cancelText: t('clientDashboard.no'),
            onOk: async () => {
                modal.destroy();

                setSending(true);
                try {
                    await createInteraction(params);
                    setSending(false);
                    history.push(`/client/${dfsClientId}/interactions`);
                } catch (e) {
                    setSending(false);
                }
            },
            className: 'accept-interaction',
        });
    };

    return (
        <PageSection className="new-interaction">
            <ContentBox>
                <Title type={1} className="underlined">
                    {t('interaction.newInteraction')}
                </Title>
            </ContentBox>
            <ContentBox className="interaction-content">
                <div className="content-wrapper">
                    <Preloader isLoading={isLoading} error={error}>
                        <Paragraph type="secondary">{t('interaction.newInteraction.description')}</Paragraph>
                        <InteractionDetails
                            data={data}
                            errors={formErrors}
                            values={newInteractionValues}
                            commentHelpText={t('interaction.newInteraction.commentText')}
                            onChange={onInteractionChange}
                            onCommentChange={onNewInteractionCommentChange}
                            isSearching={isSearching}
                            onSearch={onSearch}
                            register={register}
                            control={control}
                            editable
                            showCommentHelpText
                        />
                    </Preloader>
                </div>
            </ContentBox>
            <ButtonsBlockRow
                leftButton={{
                    text: t('confirmation.cancel'),
                    onClick: onCancel,
                }}
                primaryButton={{
                    text: t('confirmation.continue'),
                    loading: isSending,
                    onClick: onNewInteractionHandleSubmit(onCreate),
                }}
            />
        </PageSection>
    );
};

NewInteraction.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ dfsClientId: PropTypes.string }),
    }),
};

NewInteraction.defaultProps = {
    match: { params: {} },
};

export default NewInteraction;
