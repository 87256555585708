import {
    memberIdSelector,
} from 'redux/auth/authSelectors';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPeriodByFilter } from 'utils';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { useFormatting, useLocale } from 'locale';
import { adaptInvestmentPerformance } from '../adapters/adaptInvestmentPerformance';

export const useInvestmentPerformance = (filter, configuration, isTabletSize) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([{ data: [] }]);
    const { i18n } = useTranslation();
    const memberId = useSelector(memberIdSelector);

    const { getFormattedCurrency, getFormattedDate } = useFormatting();
    const { locale } = useLocale();

    useEffect(() => {
        const { startDate, endDate } = getPeriodByFilter(filter);
        const params = startDate === undefined ? {} : {
            StartDate: startDate,
            EndDate: endDate,
        };

        setLoading(true);

        if (!configuration.isLoading) {
            ServiceManager.performanceService('getInvestmentPerformance', [memberId, {
                Language: i18n.language,
                ...params,
            }])
                .then((response) => {
                    try {
                        setData(adaptInvestmentPerformance(
                            response.data,
                            configuration.data.currency,
                            startDate,
                            filter,
                            isTabletSize,
                            getFormattedCurrency,
                            getFormattedDate,
                        ));

                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch(handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                ));
        }
    }, [i18n.language, filter, configuration.isLoading, locale]);

    return { data, isLoading, error };
};
