import { useCallback } from 'react';
import FileSaver from 'file-saver';
import ServiceManager from 'services/ServiceManager';

export const useDownloadFile = (memberId) => {
    const downloadFile = useCallback((attachmentId, messageId) => () => {
        ServiceManager.messageService('getMessageAttachment', [memberId, messageId, attachmentId])
            .then((response) => {
                const byteString = atob(response.data.Content);
                const buffer = new ArrayBuffer(byteString.length);
                const data = new Uint8Array(buffer);

                for (let i = 0; i < byteString.length; i += 1) {
                    data[i] = byteString.charCodeAt(i);
                }
                const newBlob = new Blob([buffer],
                    {
                        type: response.data.MimeType,
                    });

                FileSaver.saveAs(newBlob, response.data.FileName);
            });

        return false;
    }, []);

    return { downloadFile };
};
