import React, {
    useEffect,
    useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, Step } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { PAYMENT } from 'constants/constants';
import { usePaymentsData } from '../hooks/usePaymentsData';

const Steps = ({
    children, step,
}) => {
    const { t } = useTranslation();

    const { getPaymentCode } = usePaymentsData();
    const [payment, setPayment] = useState(getPaymentCode());

    useEffect(() => {
        window.addEventListener('itemInserted', (e) => {
            if (e.key === PAYMENT) {
                setPayment(JSON.parse(e.value));
            }
        });
    }, [setPayment]);

    const steps = useMemo(() => [
        {
            title: payment === '2' ? t('payments.setupTransfer') : t('payments.setupPayment'),
        },
        {
            title: payment === '2' ? t('payments.reviewTransfer') : t('payments.reviewPayment'),
        },
    ], [payment, t]);

    return (
        <>
            {step !== 2 && (
                <div className="payments-progress">
                    <ProgressBar current={step}>
                        {steps.map((item) => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </ProgressBar>
                </div>
            )}
            {children}
        </>
    );
};

Steps.propTypes = {
    step: PropTypes.number.isRequired,
    children: PropTypes.node,
};

Steps.defaultProps = {
    children: null,
};

export default Steps;
