import React from 'react';
import {
    Column, Paragraph, SkeletonLine, Title,
} from 'ui-library';
import PropTypes from 'prop-types';

const NewsItemSkeleton = ({ height }) => (
    <div className="news-item row" style={{ height }}>
        <Column size={['md-3', 'sm-12']} className="news-item--image__container">
            <SkeletonLine width="100%" height={110} className="news-item--image" />
        </Column>
        <Column size={['md-9', 'sm-12']} className="news-item--content__container">
            <div className="news-item--title">
                <Title type={4}>
                    <SkeletonLine width={300} />
                </Title>
                <Paragraph className="news-item--date">
                    <SkeletonLine />
                </Paragraph>
            </div>

            <div className="news-item--content paragraph_secondary">
                <SkeletonLine />
                <SkeletonLine />
            </div>
            <Paragraph className="news-item--link" type="secondary">
                <SkeletonLine width={80} />
            </Paragraph>
        </Column>
    </div>
);

NewsItemSkeleton.propTypes = {
    height: PropTypes.number,

};

NewsItemSkeleton.defaultProps = {
    height: 0,
};
export default NewsItemSkeleton;
