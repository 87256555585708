import React from 'react';
import PropTypes from 'prop-types';
import { Title, ContentBox } from 'ui-library';
import { useTranslation } from 'react-i18next';
import Draggable from 'components/Draggable/Draggable';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import { getClassNames } from 'utils';
import { TYPE_CURRENCY, TYPE_NUMBER, TYPE_PERCENT } from 'utils/formatting';
import { OverviewPanel, OverviewItem } from 'components/Overview/index';

function Overview({
    data: { total, clients, prospects }, isLoading, error, drop, mini,
}) {
    const { t } = useTranslation();
    const className = getClassNames('overview', { 'overview-mini': mini });

    return (
        <PageSection>
            <ContentBox className={className} underline={false}>
                <Draggable {...drop}>
                    <Title type={2} className="overview__header">
                        {t('advisoryDashboard.overview.title')}
                    </Title>
                </Draggable>
                <OverviewPanel>
                    <Preloader isLoading={isLoading} error={error}>
                        <OverviewItem
                            title={t('advisoryDashboard.overview.totalAUM')}
                            type={TYPE_CURRENCY}
                            value={total.value}
                            currency={total.currency}
                            icon="total-aum"
                        />
                        <OverviewItem
                            title={t('advisoryDashboard.overview.performance')}
                            type={TYPE_PERCENT}
                            value={total.percent}
                            icon={total.percent < 0 ? 'arrow-down' : 'arrow-up'}
                            iconColored
                        />
                        <OverviewItem
                            title={t('advisoryDashboard.overview.clients')}
                            itemLink={`/clients?status=${t('advisoryDashboard.overview.clients')}`}
                            type={TYPE_NUMBER}
                            value={clients.value}
                            icon="clients"
                        />
                        <OverviewItem
                            title={t('advisoryDashboard.overview.prospects')}
                            itemLink={`/clients?status=${t('advisoryDashboard.overview.prospects')}`}
                            type={TYPE_NUMBER}
                            value={prospects.value}
                            icon="prospects"
                        />
                    </Preloader>
                </OverviewPanel>
            </ContentBox>
        </PageSection>
    );
}

Overview.propTypes = {
    data: PropTypes.shape({
        total: PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            currency: PropTypes.string,
        }),
        clients: PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        prospects: PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    mini: PropTypes.bool,
    drop: PropTypes.shape({
        role: PropTypes.string,
        tabIndex: PropTypes.number,
        onDragStart: PropTypes.func,
    }),
};

Overview.defaultProps = {
    data: {
        total: {},
        clients: {},
        prospects: {},
    },
    isLoading: true,
    error: null,
    mini: false,
    drop: null,
};

export default React.memo(Overview);
