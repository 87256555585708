import { NA, validateNumber } from 'utils/formatting';

const getLast = (item) => (item.PriceHistory && item.PriceHistory.length
    ? [...item.PriceHistory].pop().Price
    : 0);

const getPerformance = ({ tradingPrice, priceHistory = [], calculation }) => {
    if (calculation !== undefined) {
        const perf = calculation.HistoricalPerformance && calculation.HistoricalPerformance[0];

        return {
            Change: priceHistory.length ? priceHistory.pop().Performance * 100 : NA,
            ChangeYTD: perf ? perf.Values.pop().Value * 100 : NA,
        };
    }
    if (priceHistory.length === 0) return { Change: 0, ChangeYTD: 0 };

    const firstValue = priceHistory?.[0]?.Price;
    const prevValue = priceHistory.pop()?.Price;

    return {
        Change: validateNumber(tradingPrice / prevValue - 1) * 100,
        ChangeYTD: validateNumber(tradingPrice / firstValue - 1) * 100,
    };
};

export const adaptMarkets = (options) => {
    const {
        data, dataPriceHistory: dataPriceHistoryArr, calculations: calculationsArr,
    } = (options || {});
    const getFormattedNumber = options?.getFormattedNumber || ((d) => d);
    const calculations = (calculationsArr || []).reduce((acc, item) => ({
        ...acc, [item.id]: item,
    }), {});
    const dataPriceHistory = (dataPriceHistoryArr || []).reduce((acc, item) => ({
        ...acc, [item.Id]: item,
    }), {});

    return (data || [])
        .map((item) => {
            const itemPriceHistory = dataPriceHistory[item.id];
            const { Change, ChangeYTD } = getPerformance({
                tradingPrice: item.tradingPrice,
                priceHistory: [...itemPriceHistory.PriceHistory],
                calculation: calculations[item.id],
            });

            return {
                id: item.id,
                Index: item?.name || NA,
                Ticker: item?.securityStockExchanges?.[0]?.ticker || NA,
                Last: getFormattedNumber(getLast(itemPriceHistory)),
                Subject: {
                    title: item?.securityStockExchanges?.[0]?.ticker || NA,
                    subtitle: item?.name || NA,
                    maxLength: 25,
                },
                Performance: {
                    performance: Change,
                    value: getFormattedNumber(item.tradingPrice),
                },
                Change,
                ChangeYTD,
            };
        })
        .sort((a, b) => a.Index.localeCompare(b.Index));
};
