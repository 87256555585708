import {
    compareDates,
    isSameDay,
} from 'utils/datetime';

export const isNeedToAddFlatLine = (chartStartDate, date) => compareDates(
    date, chartStartDate,
);

export const adaptStartDate = (chartStartDate, date) => {
    // if data exist in date exists
    if (isSameDay(chartStartDate, date)) return chartStartDate;
    // if no data and it's XXXX-01-01, return XXXX-01-02 because of Jan 1st is day-off
    // if (isSameDay(chartStartDate, getFirstDayOfYear())) return getSecondDayOfYear();

    return chartStartDate;
};
