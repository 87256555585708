import { isNil, orderBy, find } from 'lodash/fp';
import { formatDate } from 'utils/datetime';
import {
    GET_NOTES_STARTED,
    GET_NOTES_SUCCEEDED,
    GET_NOTES_FAILED,
    GET_NOTE_DETAILS_STARTED,
    GET_NOTE_DETAILS_SUCCEEDED,
    GET_NOTE_DETAILS_FAILED,
    DELETE_NOTES_STARTED,
    DELETE_NOTES_SUCCEEDED, SELECT_NOTE,
} from '../actions/actions';

const defaultState = {
    notes: [],
    selected: undefined,
    error: null,
    currentNote: null,
};

const adaptNotes = (data) => {
    const sortedData = orderBy(['CreateDate'], ['desc'], data);

    return sortedData.map(({
        Id,
        // ContactId,
        ContactName,
        // LegalEntityName,
        EndTime,
        Description,
        Title,
    }, index) => ({
        id: Id,
        date: EndTime,
        title: Title,
        isSelected: index === 0,
        senderFullName: ContactName,
        content: Description,
    }));
};

const currentNoteDataSelector = (data) => (data.length ? data[0] : null);

const notesDetailsAdapter = (data) => (
    isNil(data)
        ? null
        : {
            id: data.Id,
            date: formatDate(data.EndTime),
            title: data.Title,
            senderFullName: data.ContactName,
            content: data.Description,
        }
);

const initState = defaultState;

export default function reducer(state = initState, action = {}) {
    let currentNote;

    switch (action.type) {
        case DELETE_NOTES_STARTED:
            return {
                ...state,
                selected: action.payload,
            };
        case DELETE_NOTES_SUCCEEDED:
            return {
                ...state,
                error: null,
                currentNote:
                    action.payload === state.selected ? undefined : state.currentNote,
                selected: action.payload === state.selected ? undefined : state.selected,
                isMessageContentLoading: undefined,
                notes: state.notes.reduce((current, message) => {
                    if (message.id !== action.payload) {
                        current.push(message);
                    }

                    return current;
                }, []),
            };
        case SELECT_NOTE:
            return {
                ...state,
                selected: action.payload,
                notes: state.notes.map((note) => ({
                    ...note,
                    isSelected: note.id === action.payload,
                })),
                currentNote: find((note) => (note.id === action.payload), state.notes),
            };
        case GET_NOTE_DETAILS_STARTED:
            return {
                ...state,
                error: null,
                isMessageContentLoading: true,
            };
        case GET_NOTE_DETAILS_SUCCEEDED:
            currentNote = notesDetailsAdapter(action.payload.data);

            return {
                ...state,
                isMessageContentLoading: false,
                notes: state.notes.map((message) => ({
                    ...message,
                    isRead: message.id === currentNote.id ? true : message.id,
                })),
                currentNote,
            };
        case GET_NOTE_DETAILS_FAILED:
            return {
                ...state,
                isMessageContentLoading: false,
                error: action.payload,
            };
        case GET_NOTES_STARTED:
            return {
                ...state,
                error: null,
                notes: [],
                isNotesLoading: true,
            };
        case GET_NOTES_SUCCEEDED:
            return {
                ...state,
                isNotesLoading: false,
                notes: adaptNotes(action.payload.data),
                currentNote: notesDetailsAdapter(currentNoteDataSelector(action.payload.data)),
            };
        case GET_NOTES_FAILED:
            return {
                ...state,
                isNotesLoading: false,
                error: action.payload,
                notes: [],
            };
        default:
            return state;
    }
}
