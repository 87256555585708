import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useFormatting } from 'locale';
import { adaptParentsAllocations } from '../adapters/adaptParentsAllocations';

export const useParentsAllocations = (data, isLoading, currency) => {
    const { getFormattedCurrency } = useFormatting();
    const [errorAllocation, setError] = useState(null);
    const [isLoadingAllocation, setLoading] = useState(true);
    const [investmentAllocation, setData] = useState(
        { chart: [{ data: { pieSa: {}, bar: {}, list: {} } }] },
    );
    const { i18n, t } = useTranslation();

    useEffect(() => {
        setLoading(true);

        if (!isLoading) {
            Promise.all([
                ServiceManager.commonService('getSectors', [i18n.language]),
                ServiceManager.commonService('getAssetClasses', [i18n.language]),
            ])
                .then((response) => response.map((item) => item.data))
                .then((response) => {
                    try {
                        setData(adaptParentsAllocations(
                            data, response[0], response[1], currency, t, getFormattedCurrency,
                        ));
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch((err) => {
                    setData(adaptParentsAllocations(
                        data,
                        [],
                        [],
                        currency,
                        t,
                        getFormattedCurrency,
                    ));
                    handlerRequestCanceling(HandlerError({ setError, setLoading }))(err);
                });
        }
    }, [isLoading, i18n.language]);

    return { investmentAllocation, isLoadingAllocation, errorAllocation };
};
