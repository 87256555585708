import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { CURRENT_PAGE, PAGE_SIZE, TOTAL_PAGES } from 'constants/constants';
import { getTransactionsColumns } from '../constants';

function Transactions({ data, isLoading, error }) {
    const { t } = useTranslation();

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent data={data.confirmationData} text={t('confirmation.noTransactionsFound')}>
                <Table
                    data={data.confirmationData}
                    columns={getTransactionsColumns(t)}
                    expandedColumn="Name"
                    paging={{
                        total: TOTAL_PAGES,
                        currentPage: CURRENT_PAGE,
                        pageSize: PAGE_SIZE,
                    }}
                />
            </EmptyContent>
        </Preloader>
    );
}

Transactions.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Date: PropTypes.string,
        Type: PropTypes.string,
        FxRate: PropTypes.number,
        Total: PropTypes.string,
        Number: PropTypes.number,
        Price: PropTypes.number,
    })),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};

Transactions.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
};

export default Transactions;
