import React, { memo, useCallback, useEffect } from 'react';
import throttle from 'lodash/throttle';
import { useNotificationConext } from 'additiv-react-signalr';
import SM from 'services/ServiceManager';
import { useSecure } from 'additiv-react-oidc-client';
import { generateAccessToken, NOTIFICATION_SCOPE } from '../services/accessToken';

const ON_UNAUTHORIZED_THROTTLE_TIME = 5000;
const DEBUG_LEVEL = process.env.NODE_ENV === 'development' ? 2 : 5;
const EXPIRED_TOKEN_CODE = 5;

const withSignalR = (Component) => {
    const ComponentMemo = memo(Component);

    return (props) => {
        const { oidcUser = {} } = useSecure();

        const jwt = oidcUser?.access_token;
        const {
            start,
            stop,
        } = useNotificationConext();

        const onUnauthorized = useCallback(throttle(async () => {
            try {
                stop();

                const response = await SM.Security('refreshConnectHubToken');

                start({
                    url: global?.AppConfig?.NOTIFICATION_HUB,
                    accessToken: response.data?.result?.accessToken,
                    debugLevel: DEBUG_LEVEL,
                    onUnauthorized,
                });
            } catch (err) {
                console.error('Error of refreshing notification access token');
            }
        }, ON_UNAUTHORIZED_THROTTLE_TIME), []);

        useEffect(() => {
            if (jwt === null || jwt === undefined) {
                return () => {
                };
            }
            generateAccessToken({
                type: NOTIFICATION_SCOPE,
                access_Token: jwt,
            }).then((data) => {
                if (data?.apiError?.code === EXPIRED_TOKEN_CODE) onUnauthorized();
                else if (data?.result?.accessToken) {
                    start({
                        url: global?.AppConfig?.NOTIFICATION_HUB,
                        accessToken: data?.result?.accessToken,
                        debugLevel: DEBUG_LEVEL,
                        onUnauthorized,
                    });
                }
            }).catch(() => {
                console.error('Error of getting notification access token');
            });

            return () => {
                stop();
            };
        }, [jwt, onUnauthorized]);

        return <ComponentMemo {...props} />;
    };
};

export default withSignalR;
