import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRandomString, getRandomNumber } from 'utils';
import { formatDate } from '../utils/datetime';
import ServiceManager from '../services/ServiceManager';
import handlerRequestCanceling from '../utils/handlerRequestCanceling';
import HandlerError from '../errors/HandlerError';

export const useCreateAccount = (contactId, options) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    const createAccount = useCallback(async () => {
        if (!contactId && !options) return null;

        setLoading(true);
        setError(null);
        const accountNumberBase = +getRandomNumber(); // TODO: change account number to real
        const accountNumberCustody = +getRandomNumber(); // TODO: change to real custody account nr
        const IbanChars = getRandomString(); // TODO: change IBAN chars to real

        try {
            const accountParams = (accountType, accountNumber) => ({
                Name: `${options.goalName} ${t(accountType === 1 ? 'onBoarding.cashAccount' : 'onBoarding.custodyAccount')}`,
                Iban: `${IbanChars}${accountNumber}`,
                AccountNumber: accountNumber,
                CurrencyId: options.currency.value,
                AccountType: accountType,
                Product: options.productId,
                OpeningDate: formatDate(new Date()),
                IsActive: true,
                BranchId: 1, // TODO: Remove hardcode. AT-849
                Status: 1, // TODO: Remove hardcode. AT-849
            });

            const cashAccountParams = accountParams(1, accountNumberBase);
            const { data: cashAccountResponse } = await ServiceManager.accountsService('createAccount', [contactId, cashAccountParams]);
            const custodyAccountParams = accountParams(2, accountNumberCustody);
            const { data: custodyAccountResponse } = await ServiceManager.accountsService('createAccount', [contactId, custodyAccountParams]);

            return { cashAccountResponse, custodyAccountResponse };
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            )(err);

            return null;
        }
    }, [contactId, options, t]);

    return { isLoading, error, createAccount };
};
