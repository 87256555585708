import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptProductFee } from 'adaptors/adaptProductFee';

export const useProductFee = (productId, portfolioValue) => {
    const { i18n } = useTranslation();
    const [fee, setFee] = useState(null);
    const [errorFee, setErrorFee] = useState(null);
    const [isLoadingFee, setLoadingFee] = useState(true);

    useEffect(() => {
        ServiceManager.commonService('getProductsDetails', [productId, i18n.language])
            .then((response) => {
                try {
                    setFee(adaptProductFee(
                        response.data, +portfolioValue,
                    ));
                    setLoadingFee(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setLoading: setLoadingFee, setError: setErrorFee }),
            ));
    }, [i18n.language]);

    return { fee, errorFee, isLoadingFee };
};
