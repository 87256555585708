import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject, DEPOSITS,
} from 'constants/portfolioProducts';
import { getfromDataObject } from 'utils/formatting';
import { useFormatting } from 'locale';
import { isNumber } from 'lodash';
import { adaptModelPortfolio } from '../adapters/adaptModelPortfolio';
import { adaptStrategyList } from '../adapters/adaptStrategyList';
import { onBoardingDataSelector, useOnBoardingSelector } from '../../../domain/OnBoarding';

export const useModelPortfolio = (
    {
        productId, productName, contactId, currency,
        portfolioValue, projectionYears, recurringPayment, modelPortfolioId, ...rest
    },
) => {
    const { i18n, t } = useTranslation();
    const { goalDetails, getRiskCategory } = useOnBoardingSelector(onBoardingDataSelector);
    const [isLoading, setLoading] = useState(true);
    const [isLoadingRawData, setLoadingRawData] = useState(true);
    const [error, setError] = useState(null);
    const [rawData, setRawData] = useState({});
    const [strategyList, setStrategyList] = useState([]);
    const [modelPortfolioData, setModelPortfolioData] = useState(
        { overview: {}, isProposed: true },
    );

    const [expectedReturn, setExpectedReturn] = useState();
    const [instrumentAllocations, setInstrumentAllocations] = useState([]);
    const [modelPortfolioIdData, setModelPortfolioId] = useState(modelPortfolioId);
    const {
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
    } = useFormatting();
    const shouldLoadModelPortfolio = useMemo(() => generateObject({
        [ADVISORY]: true,
        [DISCRETIONARY]: true,
        [EXECUTION_ONLY]: false,
        [DEPOSITS]: true,
    }).getByName(productName), [productName]);

    const getModelPortfolios = useCallback(async () => {
        if (!shouldLoadModelPortfolio) {
            setLoadingRawData(false);

            return;
        }

        setLoading(true);
        setLoadingRawData(true);

        try {
            if (contactId) {
                const chosenRiskCategory = await getRiskCategory();
                // TODO: Remove hardcode (fixed for demos)
                // const hardCodedProduct = +productId === 4 ? 6 : productId;
                const params = { language: i18n.language, riskCategoryId: chosenRiskCategory?.Id };

                const modelPortfolios = await ServiceManager.portfolioManagement('getModelPortfolios', [productId, params]);

                if (!modelPortfolioIdData) {
                    const modelPortfolio = modelPortfolios.data?.[0];

                    if (!modelPortfolio) {
                        setLoadingRawData(false);

                        return;
                    }
                    const modelId = getfromDataObject(modelPortfolio, 'Id', undefined);

                    setModelPortfolioId(modelId);
                }

                try {
                    setStrategyList(adaptStrategyList(modelPortfolios.data));
                } catch (err) {
                    handlerRequestCanceling(
                        () => {
                            setError(new AdapterError(err));
                            setLoading(false);
                        },
                    )(err);
                }
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);
        }
    }, [
        contactId,
        getRiskCategory,
        i18n.language,
        productId,
        shouldLoadModelPortfolio,
    ]);

    const getModelPortfolio = useCallback(async () => {
        if (!shouldLoadModelPortfolio) {
            setLoadingRawData(false);

            return;
        }

        setLoading(true);
        setLoadingRawData(true);
        const params = { language: i18n.language, currencyId: currency?.value };

        if (goalDetails?.selectedCurrency?.value) {
            params.currencyId = currency?.value || goalDetails?.selectedCurrency?.value;
        }

        try {
            if (modelPortfolioIdData) {
                let modelData = { data: {} };

                if (modelPortfolioIdData && !Number.isNaN(+modelPortfolioIdData)) {
                    modelData = await ServiceManager.portfolioManagement('getModelPortfolio', [modelPortfolioIdData, params]);
                }

                try {
                    const instrumentAllocationsMapped = modelData
                        && modelData.data
                        && modelData.data.Positions
                            .map(
                                (item) => (
                                    {
                                        InstrumentId: item.Security.Id,
                                        Allocation: item.Allocation,
                                    }),
                            );

                    setRawData(modelData.data);
                    setExpectedReturn(modelData.data?.CalculatedExpectedReturn);
                    setInstrumentAllocations(instrumentAllocationsMapped);
                    setLoadingRawData(false);
                } catch (err) {
                    handlerRequestCanceling(
                        () => {
                            setError(new AdapterError(err));
                            setLoading(false);
                        },
                    )(err);
                }
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);
        }
    }, [
        goalDetails?.selectedCurrency?.value,
        i18n.language,
        currency?.value,
        shouldLoadModelPortfolio,
        modelPortfolioIdData,
    ]);

    useEffect(() => {
        getModelPortfolios();
    }, [getModelPortfolios]);

    useEffect(() => {
        getModelPortfolio();
    }, [getModelPortfolio]);

    useEffect(() => {
        if (!isLoadingRawData && currency?.value && isNumber(+portfolioValue)) {
            setModelPortfolioData(adaptModelPortfolio(rawData, {
                productId,
                currency,
                portfolioValue: +portfolioValue,
                t,
                productName,
                projectionYears,
                getFormattedDate,
                getFormattedNumber,
                getFormattedCurrency,
                recurringPayment,
                ...rest,
            }));
            setLoading(false);
        }
    }, [
        isLoadingRawData,
        rawData,
        currency?.value,
        portfolioValue,
        productName,
        projectionYears,
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
        recurringPayment,
    ]);

    return {
        isLoading,
        instrumentAllocations,
        error,
        expectedReturn,
        modelPortfolioData,
        strategyList,
        rawData,
        modelPortfolioIdData,
    };
};
