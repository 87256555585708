import { useCallback, useEffect, useMemo } from 'react';
import { useLayout } from 'prodivers/layout';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import { Modal } from 'ui-library';
import LogoWithClientName from 'components/LogoWithClientName';
import { useClientProfile } from 'prodivers/clientProfile';
import { genarateDFSClientId } from 'utils';
import { useOnBoardingData } from 'domain/OnBoarding';
import { useFormData } from 'domain/RiskProfile';

export const useOnBoardingLayout = () => {
    const { initHeaderState } = useLayout();
    const { t } = useTranslation();
    const { clearData, clearSelectedRisk } = useFormData();
    const { clearAllOnBoarding } = useOnBoardingData();
    // Client Provider
    const { clientId, clientName } = useClientProfile();
    const dfsClientId = genarateDFSClientId(clientId);
    const isRegister = useMemo(() => global.location.pathname.includes('register'), []);
    const isRegisterSuccess = useMemo(() => global.location.pathname.includes('register/success'), [global.location.pathname]);

    const onBoardingClose = useCallback(({ url }) => {
        Modal.confirm({
            title: t('clientDashboard.riskProfile.closeProcess'),
            content: t('onBoarding.closeModalText'),
            okText: t('clientDashboard.yes'),
            onOk: () => {
                clearData();
                clearSelectedRisk();
                clearAllOnBoarding();
                if (isRegister) history.goBack();
                else history.push(url || `/clients?status=${t('advisoryDashboard.overview.prospects')}`, { clientName });
            },
            cancelText: t('clientDashboard.no'),
            className: 'close-client-modal',
        });
    }, [t, clearData, clearSelectedRisk, clearAllOnBoarding, isRegister, clientName]);

    useEffect(() => {
        if (!isRegister && !clientName) return;

        initHeaderState({
            link: null,
            content: LogoWithClientName,
            contentProps: {
                clientName,
                dfsClientId,
                onClientNameClick: () => onBoardingClose({ url: `/client/${dfsClientId}` }),
            },
            leftButton: isRegisterSuccess ? '' : { type: 'close', label: t('clientDashboard.riskProfile.closeProcess') },
            onLeftButtonClick: onBoardingClose,
            rightButton: '',
            onRightButtonClick: null,
        });
    },
    [clientName, onBoardingClose, initHeaderState, t, dfsClientId, isRegister, isRegisterSuccess]);

    useEffect(() => history.listen((location) => {
        if (!location.pathname.includes('onboarding')) {
            clearData();
            clearSelectedRisk();
            clearAllOnBoarding();
        }
    }), [clearAllOnBoarding, clearData, clearSelectedRisk]);
};
