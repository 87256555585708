import ServiceManager from 'services/ServiceManager';

export const SET_CLIENT = 'messages/SET_CLIENT';
export const setClientId = (id) => ({
    type: SET_CLIENT, payload: id,
});

export const SELECT_NOTE = 'messages/SELECT_NOTE';
export const selectNote = (id) => ({
    type: SELECT_NOTE, payload: id,
});

export const GET_NOTES_STARTED = 'notes/GET_NOTES_STARTED';
export const GET_NOTES_SUCCEEDED = 'notes/GET_NOTES_SUCCEEDED';
export const GET_NOTES_FAILED = 'notes/GET_NOTES_FAILED';
export const getNotes = (contactId) => (dispatch) => {
    dispatch({ type: GET_NOTES_STARTED });

    ServiceManager.relationshipManagement('getNotes', [contactId, 18])
        .then((response) => {
            dispatch({ type: GET_NOTES_SUCCEEDED, payload: response });
        })
        .catch((error) => {
            dispatch({ type: GET_NOTES_FAILED, payload: error });
        });
};

export const DELETE_NOTES_STARTED = 'notes/DELETE_NOTES_STARTED';
export const DELETE_NOTES_SUCCEEDED = 'notes/DELETE_NOTES_SUCCEEDED';
export const DELETE_NOTES_FAILED = 'notes/DELETE_NOTES_FAILED';
// eslint-disable-next-line
export const deleteNotes = (id, contactId) => ((dispatch) => {
    /* dispatch({ type: DELETE_NOTES_STARTED, payload: id });
    ServiceManager.messageService('deleteMessage', [id, contactId])
        .then(() => {
            dispatch({ type: DELETE_NOTES_SUCCEEDED, payload: id });
        })
        .catch((error) => {
            dispatch({ type: DELETE_NOTES_FAILED, payload: error });
        }); */
});

export const GET_NOTE_DETAILS_STARTED = 'notes/GET_NOTES_DETAILS_STARTED';
export const GET_NOTE_DETAILS_SUCCEEDED = 'notes/GET_NOTES_DETAILS_SUCCEEDED';
export const GET_NOTE_DETAILS_FAILED = 'notes/GET_NOTES_DETAILS_FAILED';
export const getNoteDetails = (id) => (dispatch) => {
    dispatch(selectNote(id));
};
