import { useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';

const PROPOSAL_TIMEOUT = 1500;
const initialState = {
    isLoadingProposal: false,
    errorLoadingProposal: null,
    proposalData: {},
};

function reducer(state, action) {
    switch (action.type) {
        case 'setData':
            return {
                ...state,
                proposalData: action.payload.data,
                isLoadingProposal: action.payload.isLoading,
            };
        case 'setErr':
            return {
                ...state,
                errorLoadingProposal: action.payload.error,
            };
        case 'setIsLoading':
            return {
                ...state,
                isLoadingProposal: action.payload.isLoading,
            };

        default: return state;
    }
}

export const usePortfolioProposal = (contractId, proposalId, adaptData, options) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { i18n } = useTranslation();

    const setError = (error) => {
        dispatch({ type: 'setErr', payload: { error } });
    };
    const setIsLoading = (isLoading) => {
        dispatch({ type: 'setIsLoading', payload: { isLoading } });
    };

    useEffect(() => {
        if (!options) return;
        if (typeof proposalId !== 'number') return;

        setIsLoading(true);
        setError(null);

        setTimeout(() => {
            ServiceManager.portfolioManagement('getPortfolioProposal', [contractId, proposalId, { language: i18n.language }])
                .then(({ data }) => {
                    try {
                        dispatch({
                            type: 'setData',
                            payload: {
                                data: adaptData(data, options),
                                isLoading: false,
                            },
                        });
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch(handlerRequestCanceling(
                    HandlerError({ setError, setLoading: setIsLoading }),
                ));
        }, PROPOSAL_TIMEOUT);
    }, [options, contractId, proposalId, i18n.language]);

    return state;
};
