import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PageVariant } from 'ui-library';
import PageVariantWrapper from 'components/PageVariantWrapper';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';

function Success(props) {
    const { onNext } = props;

    const {
        getGoalDetails,
    } = useOnBoardingSelector(onBoardingDataSelector);

    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);

    const { t } = useTranslation();

    return (
        <PageVariantWrapper className="contract-result">
            <PageVariant
                type="success"
                title={t('onBoarding.review.success.title')}
                message={`${t('onBoarding.review.success.message.part1')} ${goalDetails?.goalName} ${t('onBoarding.review.success.message.part2')}`}
                actionButtonLabel={t('onBoarding.review.success.button')}
                onActionButtonClick={() => {
                    onNext();
                }}
            />
        </PageVariantWrapper>
    );
}

Success.propTypes = {
    onNext: PropTypes.func.isRequired,
};

Success.defaultProps = {};

export default Success;
