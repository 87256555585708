import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Button, Column, Infobox, Paragraph, Title, Row,
} from 'ui-library';
import { useQuestionnaires } from 'hooks/useQuestionnaires';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useBankInfo } from 'hooks/useBankInfo';
import { useClientProfile } from 'prodivers/clientProfile';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject, DEPOSITS,
} from 'constants/portfolioProducts';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useEditCustomer, useSchema } from 'domain/ClientService';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { useAdvisoryProtocol } from '../../../../hooks/useAdvisoryProtocol';
import './FundingPage.css';
import { useSaveDocuments } from '../../../../hooks/useSaveDocument';

const ACCEPTED = 'accepted';

function FundingPage(props) {
    const { contactId, onNext } = props;
    const { t } = useTranslation();
    const { signedDocumentId } = useOnBoardingSelector(onBoardingDataSelector);
    // Client Provider
    const { clientName, ...information } = useClientProfile();

    const {
        getCreatedGoal, clearCreatedGoal, Iban, clearIban, clearData, ReviewData,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const createdGoal = useMemo(getCreatedGoal, [getCreatedGoal]);
    const { questionnaireTypeId } = useQuestionnaires();
    const { isLoadingBankInfo, errorBankInfo, bankInfo } = useBankInfo({ contactId });

    // Hook for client data saving
    const {
        saveContactDetailsError, saveContactDetailsIsLoading, saveContactDetails,
    } = useEditCustomer({ contactId });
    const {
        errorListsKey: errorStages, isLoadingListsKey: isLoadingStages, getSchemaListByKey,
    } = useSchema({ loadInitially: false });

    useEffect(() => {
        (async () => {
            try {
                const statuses = await getSchemaListByKey({ key: 'contactStatus' });
                const contactStatus = statuses
                    .find((item) => item?.label.toLowerCase() === ACCEPTED);

                saveContactDetails({
                    data: {
                        details: {
                            relationshipInformation: {
                                isProspect: false,
                                contactStatus: contactStatus?.id || contactStatus?.value,
                            },
                        },
                    },
                });
            } catch (err) { /* Don't throw an error */
            }
        })();
    }, [saveContactDetails]);

    // Data by product
    const showBenchmark = useMemo(() => generateObject({
        [ADVISORY]: true,
        [DISCRETIONARY]: true,
        [EXECUTION_ONLY]: false,
        [DEPOSITS]: true,
    }).getByName(createdGoal.ProductName), [createdGoal.ProductName]);

    const {
        benchmarkSelected, isLoadingBenchmark,
    } = useBenchmarks({
        productId: createdGoal.ProductId,
        currencyId: createdGoal.CurrencyId,
        benchmarkId: ReviewData?.benchmarkId,
    }, false, showBenchmark);

    const {
        saveDocument, isLoading: isLoadingIC, error,
    } = useSaveDocuments({ documentId: signedDocumentId });
    const {
        saveAdvisoryProtocol, isLoading: isLoadingProtocol, error: errorProtocol,
    } = useAdvisoryProtocol({
        contactId,
        clientName,
        details: createdGoal,
        benchmarkSelected,
        questionnaireTypeId,
    });

    const clearAndNext = () => {
        clearData();
        clearCreatedGoal();
        clearIban();
        onNext(clientName);
    };

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.congratulations')}
            className="completion"
            classNameContent="completion-content"
            isLoading={isLoadingBenchmark || isLoadingBankInfo
                || saveContactDetailsIsLoading || isLoadingStages}
            error={errorBankInfo || errorStages}
            nextButton={{
                text: t('onBoarding.done'),
                onClick: clearAndNext,
            }}
            buttonsRowError={(
                <>
                    {saveContactDetailsError && (
                        <Infobox error>{saveContactDetailsError?.message}</Infobox>
                    )}
                    {error && <Infobox error>{error?.message}</Infobox>}
                    {errorProtocol && <Infobox error>{errorProtocol?.message}</Infobox>}
                </>
            )}
        >
            <Row>
                <Column size="12">
                    <Paragraph type="secondary" className="completion-text">
                        {t('onBoarding.completionText')}
                    </Paragraph>
                </Column>
                <Column size="12">
                    <div className="group-title">
                        <Title type={3}>{t('onBoarding.bankDetails')}</Title>
                        <Paragraph type="secondary">
                            {t('onBoarding.bankDetailsText')}
                        </Paragraph>
                    </div>
                </Column>
            </Row>
            <Row className="bank-info">
                <Column size="6">
                    <div className="group-title">
                        <Title type={2}>{t('onBoarding.iban')}</Title>
                        <p className="bank-info-description">{Iban || ''}</p>
                    </div>
                </Column>
                <Column size="6">
                    <div className="group-title">
                        <Title type={2}>{t('onBoarding.bic')}</Title>
                        <p className="bank-info-description">{bankInfo.BIC}</p>
                    </div>
                </Column>
                <Column size="6">
                    <div className="group-title">
                        <Title type={2}>{t('onBoarding.beneficiary')}</Title>
                        <p className="bank-info-description">
                            {information?.data?.Name}
                        </p>
                        <p className="bank-info-description">
                            {information?.data?.Street}
                            {' '}
                            {information?.data?.StreetNumber}
                        </p>
                        <p className="bank-info-description">
                            {information?.data?.PostalCode}
                            {' '}
                            {`${information?.data?.City}, ${information?.data?.State}`}
                        </p>
                        <p className="bank-info-description">
                            {information?.data?.Country}
                        </p>
                    </div>
                </Column>
                <Column size="6">
                    <div className="group-title">
                        <Title type={2}>{t('onBoarding.bank')}</Title>
                        <p className="bank-info-description">{bankInfo.BankName}</p>
                        <p className="bank-info-description">
                            {bankInfo.BankAdress?.AddressLine1}
                            {' '}
                            {bankInfo.BankAdress?.AddressLine2}
                        </p>
                        <p className="bank-info-description">
                            {bankInfo.BankAdress?.AddressLine3}
                            {' '}
                            {bankInfo.BankAdress?.AddressLine4}
                        </p>
                        <p className="bank-info-description">
                            {bankInfo.BankAdress?.Country}
                        </p>
                    </div>
                </Column>
            </Row>
            <Row className="documents-info">
                <Column size="12">
                    <div className="group-title documents-title">
                        <Title type={3}>{t('onBoarding.documentsTitle')}</Title>
                        <Paragraph type="secondary">
                            {t('onBoarding.documentsText')}
                        </Paragraph>
                    </div>
                </Column>
                <Column size="12">
                    <div className="documents-button-group">
                        <Button type="secondary" size="small" loading={isLoadingIC} onClick={saveDocument}>
                            {t('onBoarding.downloadContact')}
                        </Button>
                        <Button type="secondary" size="small" loading={isLoadingProtocol} onClick={saveAdvisoryProtocol}>
                            {t('onBoarding.downloadAdvisoryProtocol')}
                        </Button>
                    </div>
                </Column>
            </Row>
        </OnBoardingBaseTemplate>
    );
}

FundingPage.propTypes = {
    contactId: PropTypes.number.isRequired,
    onNext: PropTypes.func,
    information: PropTypes.shape({
        data: PropTypes.shape({
            Name: PropTypes.string,
            Street: PropTypes.string,
            StreetNumber: PropTypes.string,
            City: PropTypes.string,
            PostalCode: PropTypes.string,
            Country: PropTypes.string,
            State: PropTypes.string,
        }),
    }),
};

FundingPage.defaultProps = {
    onNext: () => {},
    information: null,
};

export default FundingPage;
