import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { getPeriodByFilter } from 'utils';
import { useFormatting } from 'locale';
import { adaptAssetDevelopment } from '../adapters/adaptAssetDevelopment';

export const useWithAssetDevelopment = (filter, clientId, currency) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({ performance: [{ data: [] }], assets: [{ data: [] }] });
    const { i18n } = useTranslation();

    const { getFormattedCurrency, getFormattedNumber, getFormattedDate } = useFormatting();

    useEffect(() => {
        if (!currency) return;

        const { startDate, endDate } = getPeriodByFilter(filter);
        const params = startDate === undefined ? {} : {
            from: startDate,
            to: endDate,
            language: i18n.language,
        };

        setLoading(true);

        ServiceManager.portfolioManagement('getInvestmentOverviewPerformance', [clientId, params])
            .then((response) => {
                try {
                    setData(adaptAssetDevelopment(
                        response.data.Values,
                        currency,
                        startDate,
                        filter,
                        getFormattedNumber,
                        getFormattedCurrency,
                        getFormattedDate,
                    ));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, [clientId, filter, currency, i18n.language]);

    return { data, isLoading, error };
};
