import { useEffect, useMemo, useState } from 'react';
import AdapterError from 'errors/AdapterError';
import ServiceManager from 'services/ServiceManager';
import HandlerError from 'errors/HandlerError';
import { useTranslation } from 'react-i18next';
import {
    adaptTasks, adaptUrgencyFilters, adaptWithContactsForWidget,
} from '../pages/AdvisoryDashboard/adapters/adaptTasks';
import handlerRequestCanceling from '../utils/handlerRequestCanceling';

export const useTasks = (tickets, contacts, quantity) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [rawData, setRawData] = useState([]);
    const [data, setData] = useState([]);
    const { i18n, t } = useTranslation();


    useEffect(() => {
        if (!contacts.isLoading && !tickets.isLoading) {
            ServiceManager.commonService('getSeverityLevels')
                .then((response) => {
                    try {
                        setRawData(response.data);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch(handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                ));
        } else {
            setError(tickets.error || contacts.error);
            setLoading(tickets.isLoading || contacts.isLoading);
        }
    }, [tickets.isLoading, contacts.isLoading]);

    useEffect(() => {
        if (rawData.length > 0) {
            try {
                setData(adaptWithContactsForWidget(
                    adaptTasks(tickets.data, rawData, quantity, t),
                    contacts.data,
                ));

                setLoading(false);
            } catch (err) {
                throw new AdapterError(err);
            }
        }
    }, [rawData, i18n.language]);

    const urgencyFilters = useMemo(() => adaptUrgencyFilters(rawData, t), [rawData, t]);

    return {
        data, isLoading, error, urgencyFilters,
    };
};
