import React from 'react';
import PropTypes from 'prop-types';
import InteractionsContext from './context';
import { useInteractionsData } from '../hooks/useInteractionsData';
import { useInteractionModify } from '../hooks/useInteractionModify';
import { useInteractionFields } from '../hooks/useInteractionFields';

const InteractionsProvider = (options) => {
    const {
        options: {
            clientId,
            memberId,
        },
        children,
    } = options;

    const interactionsData = useInteractionsData({
        clientId,
        memberId,
    });
    const interactionModify = useInteractionModify({ clientId });
    const interactionFields = useInteractionFields({ memberId });

    const state = {
        clientId,
        memberId,
        interactionsData,
        interactionModify,
        interactionFields,
    };
    const getValue = (callback) => callback(state);

    return (
        <InteractionsContext.Provider
            value={{ ...state, getValue }}
        >
            {children}
        </InteractionsContext.Provider>
    );
};

InteractionsProvider.propTypes = {
    options: PropTypes.shape({
        clientId: PropTypes.number.isRequired,
        memberId: PropTypes.number.isRequired,
    }).isRequired,
};

InteractionsProvider.defaultProps = {};

export default InteractionsProvider;
