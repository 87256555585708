import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from 'utils';
import { useFormatting } from 'locale';
import { formatValueByType, getColorClass } from 'utils/formatting';
import {
    OVERVIEW_SIZE_LAGRE, OVERVIEW_SIZE_SMALL, OVERVIEW_SIZES, TYPE_TEXT, VALUE_TYPES,
} from './constants';

function OverviewItem({
    title,
    value,
    size,
    type,
    colored,
    currency,
    currencySize,
    isCurrencyBeforeValue,
    className: containerClassName,
    isDefaultFormattedValue,
}) {
    const { getFormattedNumber } = useFormatting();

    const wrapperClassName = getClassNames(
        'overview-item',
        containerClassName,
    );

    const className = getClassNames(
        getColorClass(colored ? value : 0, 'overview-item__value'),
        `overview-item__value_${size}`,
    );

    const currencyClassName = getClassNames(
        getColorClass(colored ? value : 0, 'overview-item__currency'),
        `overview-item__currency_${currencySize}`,
        `overview-item__currency_align_${isCurrencyBeforeValue ? 'left' : 'right'}`,
    );

    const CurrencyComponent = useMemo(() => (currency !== null ? (
        <span className={currencyClassName}>
            {currency}
        </span>
    ) : null), [currency, currencyClassName, type]);

    return (
        <div className={wrapperClassName}>
            <div className="overview-item__text">
                {title && <span className="overview-item__title">{title}</span>}
                <div>
                    {isCurrencyBeforeValue && CurrencyComponent}
                    <span className={className}>
                        {formatValueByType(
                            value, type, colored, getFormattedNumber, 2, isDefaultFormattedValue,
                        )}
                    </span>
                    {!isCurrencyBeforeValue && CurrencyComponent}
                </div>
            </div>
        </div>
    );
}

OverviewItem.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOf(OVERVIEW_SIZES),
    type: PropTypes.oneOf(VALUE_TYPES),
    colored: PropTypes.bool,
    currency: PropTypes.string,
    isCurrencyBeforeValue: PropTypes.bool,
    currencySize: PropTypes.oneOf(OVERVIEW_SIZES),
    className: PropTypes.string,
    isDefaultFormattedValue: PropTypes.bool,
};

OverviewItem.defaultProps = {
    value: null,
    size: OVERVIEW_SIZE_LAGRE,
    isCurrencyBeforeValue: false,
    currencySize: OVERVIEW_SIZE_SMALL,
    type: TYPE_TEXT,
    colored: false,
    currency: '',
    className: '',
    isDefaultFormattedValue: false,
};

export default OverviewItem;
