export const adaptStrategyList = (data) => {
    const adaptedData = [];

    data.forEach(({ Id, RiskCategory, IsActive }) => IsActive && adaptedData.push(
        {
            label: RiskCategory.Name,
            value: Id,
        },
    ));

    return adaptedData;
};
