import { useMemo } from 'react';
import { formatDate } from 'utils/datetime';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useModelPortfolio } from './useModelPortfolio';

export const useReviewData = (contactId, optionsAdditional) => {
    const {
        product, getGoalDetails,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const goalDetails = getGoalDetails();
    const projectionYears = formatDate(goalDetails.targetDate, 'YYYY') - formatDate(new Date(), 'YYYY');

    const options = useMemo(
        () => ({
            productId: +product.id,
            productName: product.name,
            contactId,
            currency: goalDetails.selectedCurrency,
            portfolioValue: +goalDetails.initialInvestment,
            portfolioSecuritiesValue: +goalDetails.initialInvestment,
            recurringPayment: +goalDetails.recurringPayment,
            projectionYears,
            ...optionsAdditional,
        }),
        [
            optionsAdditional,
            product.id,
            product.name,
            contactId,
            goalDetails.selectedCurrency,
            goalDetails.initialInvestment,
            goalDetails.recurringPayment,
            projectionYears,
        ],
    );

    const {
        modelPortfolioData, rawData, isLoading, error, strategyList,
    } = useModelPortfolio(options);

    return {
        data: modelPortfolioData,
        rawData,
        strategyList,
        isLoading,
        error,
    };
};
