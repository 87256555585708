import { renderers } from 'ui-library';
import { CellPDFView, CellSwitch } from 'components/renderers';
import { sorterByDate, sorterNumber, sorterString } from 'utils/sorting';

const { CellWithButtonIcon } = renderers;

export const getOrdersColumns = (t) => ([
    {
        key: 'Name',
        title: t('clientDashboard.portfolio.columns.name'),
        render: CellSwitch,
        width: 200,
        sortable: true,
        sorter: sorterString('Name'),
    },
    {
        key: 'Date',
        title: t('clientDashboard.portfolio.columns.date'),
        sortable: true,
        sorter: sorterByDate('Date'),
        defaultSortOrder: 'ascend',
        width: 100,
    },
    {
        key: 'Type',
        title: t('clientDashboard.portfolio.columns.type'),
        sortable: true,
        sorter: sorterString('Type'),
    },
    {
        key: 'Number',
        title: t('clientDashboard.portfolio.columns.number'),
        className: 'text-right',
        sorter: sorterNumber('Number'),
    },
    {
        key: 'Price',
        title: t('clientDashboard.portfolio.columns.price'),
        className: 'text-right',
        sorter: sorterNumber('Price'),
    },
    {
        key: 'FxRate',
        title: t('clientDashboard.portfolio.columns.fxRate'),
        className: 'text-right',
        sorter: sorterNumber('FxRate'),
    },
    {
        key: 'Total',
        title: t('clientDashboard.portfolio.columns.total'),
        className: 'text-right',
        sorter: sorterNumber('Total'),
    },
    {
        title: t('clientDashboard.portfolio.columns.fee'),
        key: 'Fee',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Fee'),
        width: 40,
        minWidth: 40,
    },
]);

export const getPendingOrdersColumns = (t) => ([
    {
        key: 'Name',
        title: t('clientDashboard.portfolio.columns.name'),
        render: CellSwitch,
        width: 200,
        sortable: true,
        sorter: sorterString('Name'),
    },
    {
        key: 'Date',
        title: t('clientDashboard.portfolio.columns.date'),
        sortable: true,
        sorter: sorterByDate('Date'),
        defaultSortOrder: 'ascend',
        width: 100,
    },
    {
        key: 'Type',
        title: t('clientDashboard.portfolio.columns.type'),
        sortable: true,
        sorter: sorterString('Type'),
    },
    {
        key: 'Number',
        title: t('clientDashboard.portfolio.columns.number'),
        className: 'text-right',
        sorter: sorterNumber('Number'),
    },
    {
        key: 'Price',
        title: t('clientDashboard.portfolio.columns.price'),
        className: 'text-right',
        sorter: sorterNumber('Price'),
    },
    {
        key: 'FxRate',
        title: t('clientDashboard.portfolio.columns.fxRate'),
        className: 'text-right',
        sorter: sorterNumber('FxRate'),
    },
    {
        key: 'Total',
        title: t('clientDashboard.portfolio.columns.total'),
        className: 'text-right',
        sorter: sorterNumber('Total'),
    },
]);

export const getDocumentsColumns = (t) => ([
    {
        key: 'icon',
        title: '',
        render: CellWithButtonIcon,
        width: 30,
        minWidth: 30,
    },
    {
        key: 'name',
        title: t('clientDashboard.documents.name'),
        render: CellPDFView,
        sortable: true,
        sorter: sorterString('name.value'),
        lastSorter: true,
    },
]);
