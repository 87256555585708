import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import { withSecure } from 'additiv-react-oidc-client';
import { withMainLayout } from '../hocs';
import { AdvisoryDashboardRouter } from './AdvisoryDashboard';
import ClientOverview from './ClientOverview';
import { Surfly } from './Surfly';
import Clients from './Clients';
import NewProspect from './NewProspect';
import Settings from './Settings';
import UnderConstructionWithHeaderAndFooter from '../components/UnderConstruction';
import Messages from './Messages/Router';
import Tasks from './Tasks/Tasks';
import News from './News/Router';
import OnBoarding from './OnBoarding/Router';
import { PageNotFound } from '../components/PageError';
import { ManagerProfileProvider } from '../prodivers/managerProfile';
import EditProfile from './EditProfile';

const Router = (props) => {
    const { match: { path }, location: { pathname } } = props;
    const ClientOverviewRouter = useMemo(() => withSecure(ClientOverview), []);
    const OnBoardingRouter = useMemo(() => withSecure(OnBoarding), []);

    return (
        <ManagerProfileProvider options={{ pathname }}>
            <Layout {...props}>
                <Switch>
                    <Route
                        path={`${path}settings`}
                        component={withSecure(Settings)}
                    />
                    <Route
                        path={`${path}dashboard`}
                        component={withSecure(AdvisoryDashboardRouter)}
                    />
                    {
                        (global.AppConfig.REACT_APP_SURFLY_URL?.length ?? 0) !== 0 && (
                            <Route
                                path={`${path}support`}
                                component={withSecure(Surfly)}
                            />
                        )
                    }
                    <Route
                        path={`${path}client/:dfsClientId`}
                        component={ClientOverviewRouter}
                    />
                    {/* <Route path={`${path}auth`} component={Auth} /> */}
                    <Route exact path={`${path}clients`} component={withSecure(withMainLayout(Clients))} />
                    <Route
                        exact
                        path={`${path}clients/new-prospect`}
                        component={withSecure(withMainLayout(NewProspect))}
                    />
                    <Route
                        exact
                        path={`${path}notes`}
                        component={withSecure(UnderConstructionWithHeaderAndFooter)}
                    />
                    <Route
                        exact
                        path={`${path}notes/new`}
                        component={withSecure(UnderConstructionWithHeaderAndFooter)}
                    />
                    <Route path={`${path}messages`} component={withSecure(Messages)} />
                    {/* <Route exact path="/messages/new" component={NewMessage} /> */}
                    <Route
                        exact
                        path={`${path}documents`}
                        component={withSecure(UnderConstructionWithHeaderAndFooter)}
                    />
                    <Route
                        exact
                        path={`${path}tasks`}
                        component={withSecure(Tasks)}
                    />
                    <Route
                        path={`${path}news`}
                        component={withSecure(News)}
                    />
                    <Route
                        path={`${path}edit-profile`}
                        component={withSecure(withMainLayout(EditProfile))}
                    />
                    <Route
                        exact
                        path={`${path}new-client`}
                        component={withSecure(UnderConstructionWithHeaderAndFooter)}
                    />
                    <Route
                        exact
                        path={`${path}portfolios`}
                        component={withSecure(UnderConstructionWithHeaderAndFooter)}
                    />
                    <Route
                        path={`${path}onboarding`}
                        component={OnBoardingRouter}
                    />

                    <Redirect exact from={path} to={`${path}dashboard`} />
                    <Redirect exact from={`${path}login`} to={`${path}dashboard`} />
                    <Redirect exact from={`${path}auth`} to={`${path}dashboard`} />
                    <Route exact path={`${path}404`} component={withSecure(PageNotFound)} />
                    <Redirect from="*" to="/404" />
                </Switch>
            </Layout>
        </ManagerProfileProvider>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
