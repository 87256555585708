import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionPanel as Panel,
    Table,
    ContentBox,
    Button,
    Infobox,
    Tabs,
    TabPane,
    Dropdown,
    MenuItem,
    Icon,
    Select,
} from 'ui-library';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import { getClientId, createUniqueKey } from 'utils';
import { useFormatting } from 'locale';
import { InvestmentAllocation } from '@additiv-widgets/investment-allocation';
import Preloader from 'components/Preloader';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import Analysis from 'components/Analysis';
import PageHeader from 'components/PageHeader';
import {
    orderBookSelector,
    portfolioSelector, usePortfolioSelector, useTransactions,
} from 'domain/Portfolio';
import { cashFlowsSelector, transactionsSelector } from 'domain/Portfolio/selectors';
import { PORTFOLIO_BLOCKED_ID } from 'constants/portfolioStatuses';
import { useContactGroups } from 'hooks/useContactGroups';
import CashFlows from '../components/CashFlows';
import Transactions from '../components/Transactions';
import TransactionsCash from '../components/TransactionsCash';
import Overview from '../../common/components/Overview';
import { getPositionsColumns } from '../constants';
import Allocation from '../../common/components/Allocation';
import { getPendingOrdersColumns } from '../../../constants/columns';
import { usePagination } from '../../common/hooks/usePagination';

function PortfolioAdvisory(props) {
    const { match: { params: { dfsClientId, portfolioId } } } = props;
    const { t } = useTranslation();
    const { getFormattedNumber } = useFormatting();
    const clientId = getClientId(dfsClientId);
    const { isMobile } = useWindowDimensions();

    // Portfolio Domain
    const {
        dataRaw, data, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        data: orderBook, isLoading: isLoadingOrderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    useEffect(() => {
        if (data?.productId) {
            getOrderBook({ adaptOptions: { productId: data?.productId } });
        }
    }, [data?.productId, getOrderBook]);

    // Hooks
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);

    const {
        analysisData, onPerformanceChange, performanceSelected,
    } = useAnalysisDetails({
        clientId,
        portfolio: data,
        positions: data.positions,
        positionsRaw: dataRaw.Positions,
        isLoadingBenchmark,
        benchmark,
        additionalOptions: { usePortfolioPerformance: true },
    });
    const {
        data: accessPolicyContacts,
        isLoading: isLoadingAccessPolicyContacts,
        error: errorAccessPolicyContacts,
    } = useContactGroups({ contactId: clientId, contactGroupId: data?.contactGroupId });

    // Transactions logic TR
    const { page: TRPage, onPaginationChange: TROnPaginationChange } = usePagination(3);
    const TROptionsParams = useMemo(() => ({
        page: TRPage,
        PageSize: 10,
        SecurityType: 'Instruments',
    }), [TRPage]);
    const { data: portfolio } = usePortfolioSelector(portfolioSelector);
    const {
        data: TRData, isLoading: TRIsLoading, error: TRError, getTransactions: TRGetTransactions,
    } = usePortfolioSelector(transactionsSelector);
    const TRAdaptOptions = useMemo(() => ({
        baseUrl: `/client/${dfsClientId}/portfolios/${portfolioId}/position/${portfolio?.productId}`,
    }), [portfolio?.productId, portfolioId]);

    useEffect(() => {
        TRGetTransactions({ optionsParams: TROptionsParams, adaptOptions: TRAdaptOptions });
    }, [TRGetTransactions, TROptionsParams, TRAdaptOptions]);

    // Transactions cash logic TRC
    const { page: TRCPage, onPaginationChange: TRCOnPaginationChange } = usePagination(3);
    const {
        data: TRCData,
        isLoading: TRCIsLoading,
        error: TRCError,
        getTransactions: TRCGetTransactions,
    } = useTransactions({ clientId, portfolioId });
    const TRCOptionsParams = useMemo(() => ({
        page: TRCPage,
        PageSize: 10,
        SecurityType: 'Liquidity',
    }), [TRCPage]);
    const TRCAdaptOptions = useMemo(() => ({
        page: TRCPage,
        PageSize: 10,
        baseUrl: `/client/${dfsClientId}/portfolios/${portfolioId}/position/${portfolio?.productId}`,
    }), [TRCPage, portfolio?.productId, portfolioId]);

    useEffect(() => {
        TRCGetTransactions({ optionsParams: TRCOptionsParams, adaptOptions: TRCAdaptOptions });
    }, [TRCGetTransactions, TRCOptionsParams, TRCAdaptOptions]);

    // Cash flows CF
    const {
        data: CFData,
        isLoading: CFIsLoading,
        error: CFError, getCashFlows: CFGetCashFlows,
    } = usePortfolioSelector(cashFlowsSelector);
    const CFAdaptOptions = useMemo(() => ({
        historicalPerformance: portfolio?.HistoricalPerformance?.[0]?.Values,
    }), [portfolio]);

    useEffect(() => {
        if (CFAdaptOptions?.historicalPerformance) {
            CFGetCashFlows({ adaptOptions: CFAdaptOptions });
        }
    }, [CFGetCashFlows, CFAdaptOptions]);

    const disableAction = useMemo(
        () => isLoadingOrderBook || orderBook || data?.portfolioStatusId === PORTFOLIO_BLOCKED_ID,
        [isLoadingOrderBook, orderBook, data?.portfolioStatusId],
    );

    const portfolioActions = useMemo(() => [{
        text: t('clientDashboard.portfolio.rebalance'),
        onClick: () => history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/rebalance`),
    }, {
        text: t('clientDashboard.portfolio.quickEdit'),
        onClick: () => history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/quick-edit`),
    }, {
        text: t('clientDashboard.portfolio.modify'),
        onClick: () => history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/modify`),
    }, {
        text: `${t('clientDashboard.portfolio.changeStrategy')}`,
        onClick: () => history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy`),
    }, {
        text: `${t('clientDashboard.portfolio.changeModel')}`,
        onClick: () => history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/change-model`),
    }], [dfsClientId, portfolioId]);

    const portfolioActionOptions = portfolioActions.map((item, index) => ({
        value: index,
        label: item.text,
    }));
    const doPortfolioAction = useCallback((selectedIndex) => {
        portfolioActions[selectedIndex]?.onClick?.();
    }, [portfolioActions]);

    return (
        <ContentBox className="single-portfolio">
            <Preloader
                isLoading={isLoading || isLoadingAccessPolicyContacts}
                error={error || errorOrderBook || errorAccessPolicyContacts}
            >
                {orderBook && <Infobox>{t('clientDashboard.portfolio.orderBookMessage')}</Infobox>}
                <PageHeader
                    title={data?.title}
                    breadCrumbsCurrent={data?.backTitle}
                    breadCrumbsChildren={[
                        {
                            to: `/client/${dfsClientId}/portfolios`,
                            label: t('clientDashboard.portfolios.title'),
                        },
                    ]}
                    description={(accessPolicyContacts || []).map(
                        (item) => (
                            <div className="access-policy">
                                <span>{item?.firstName}</span>
                                <span>{item?.lastName}</span>
                                <span>{`(${item?.accessPolicy})`}</span>
                            </div>
                        ),
                    )}
                >
                    <div className="buttons-block">
                        {!isMobile ? (
                            <Select
                                disabled={disableAction}
                                placeholder={`${t('clientDashboard.portfolio.columns.actions')}`}
                                onChange={doPortfolioAction}
                                options={portfolioActionOptions}
                                width={166}
                            />
                        ) : (
                            <Dropdown
                                placement="bottomRight"
                                overlayClassName="mt-20"
                                title={(
                                    <Button
                                        disabled={disableAction}
                                        type="secondary"
                                        size="small"
                                        className="actionBox"
                                        loading={isLoadingOrderBook}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Icon role="button" tabIndex={0} type="action" />
                                    </Button>
                                )}
                            >
                                {
                                    portfolioActions && portfolioActions.map((action, index) => (
                                        <MenuItem
                                            key={createUniqueKey(index, action.text)}
                                            onClick={action.onClick}
                                        >
                                            {action.text}
                                        </MenuItem>
                                    ))
                                }
                            </Dropdown>
                        )}
                    </div>
                </PageHeader>
                <Overview
                    portfolioId={portfolioId}
                    productId={data?.productId}
                    dfsClientId={dfsClientId}
                    data={data.overview}
                    disableChangeStrategy
                    disableChangeModel
                />
                <Accordion defaultActiveKey={['2', '3', '4']}>
                    <Panel header={t('clientDashboard.portfolio.allocation')} key="2" className="allocation">
                        <InvestmentAllocation showTitle={false} />
                    </Panel>
                    <Panel
                        header={t('clientDashboard.portfolio.analysis')}
                        key="3"
                        className="investment-performance"
                    >
                        <Analysis
                            data={analysisData}
                            onFilterChange={onPerformanceChange}
                            benchmarkOptions={benchmarkOptions}
                            benchmarkSelected={benchmarkSelected}
                            onBenchmarkChange={onBenchmarkChange}
                            isLoadingBenchmarks={isLoadingBenchmark}
                            performanceSelected={performanceSelected}
                            oneProjectionColumn
                            showSustainabilityTab
                        />
                    </Panel>
                    <Panel header={t('clientDashboard.portfolio.positions')} key="4" className="positions">
                        <Table
                            expandedColumn="name"
                            className="positions-table"
                            data={data.positions}
                            columns={getPositionsColumns(t, getFormattedNumber)}
                            defaultExpandAllRows
                        />
                    </Panel>
                    <Panel header={t('clientDashboard.portfolio.transactionsAndCashFlows')} className="security-transactions" key="5">
                        <Tabs className="transactions_cash-flows table-without-last-row-padding" defaultActiveKey="transactions">
                            <TabPane tab={t('clientDashboard.portfolio.securityTransactions')} key="transactions">
                                <Transactions
                                    isLoading={TRIsLoading}
                                    page={TRPage}
                                    onPaginationChange={TROnPaginationChange}
                                    data={TRData}
                                    error={TRError}
                                    portfolio={portfolio}
                                />
                            </TabPane>
                            <TabPane tab={t('clientDashboard.portfolio.cashTransactions')} key="cash-transactions">
                                <TransactionsCash
                                    isLoading={TRCIsLoading}
                                    page={TRCPage}
                                    onPaginationChange={TRCOnPaginationChange}
                                    data={TRCData}
                                    error={TRCError}
                                    portfolio={portfolio}
                                />
                            </TabPane>
                            <TabPane tab={t('clientDashboard.portfolio.cashFlows')} key="cashFlows">
                                <CashFlows
                                    isLoading={CFIsLoading}
                                    data={CFData}
                                    error={CFError}
                                    portfolio={portfolio}
                                />
                            </TabPane>
                        </Tabs>
                    </Panel>
                    <Panel header={t('clientDashboard.portfolio.pendingOrder')} className="pending-order" key="7">
                        <Table
                            className="positions-table"
                            data={orderBook || []}
                            columns={getPendingOrdersColumns(t)}
                            expandedColumn="name"
                            defaultExpandAllRows
                        />
                    </Panel>
                </Accordion>
            </Preloader>
        </ContentBox>
    );
}

PortfolioAdvisory.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        state: {
            fromReview: PropTypes.bool,
        },
    }).isRequired,
};

PortfolioAdvisory.defaultProps = {};

export default PortfolioAdvisory;
