import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Column, ContentBox, Row } from 'ui-library';
import { useTranslation } from 'react-i18next';
import PageSection from 'components/PageSection/PageSection';
import SearchBar from '../SearchBar';

import './ListPreview.css';

const ListPreview = ({
    search,
    filters,
    onSearch,
    titleComponent,
    listComponent,
    preSelect,
    getData,
    getPreview,
    getDataCallback,
    children,
    searchBarActive,
    dataLength,
}) => {
    const { t } = useTranslation();
    const SearchBarRender = useMemo(() => searchBarActive && (
        <SearchBar
            names={search}
            selected={filters}
            onSelect={onSearch}
            placeholder={t('messages.searchPlaceholder')}
        />
    ), [searchBarActive, search, filters, onSearch, t]);

    return (
        <PageSection className="list-preview">
            {SearchBarRender}
            <ContentBox underline={false}>
                <Row>
                    {titleComponent}
                </Row>
                <Row className="list-preview__content">
                    <Column size={(dataLength === undefined || dataLength > 0) ? 'lg-5' : 'lg-12'} className="list-preview__list">
                        {listComponent({
                            getData, getPreview, getDataCallback, preSelect,
                        })}
                    </Column>
                    {(dataLength === undefined || dataLength > 0) && (
                        <Column size="lg-7 preview-container">
                            {children({ getPreview })}
                        </Column>
                    )}
                </Row>
            </ContentBox>
        </PageSection>
    );
};

ListPreview.propTypes = {
    search: PropTypes.arrayOf(PropTypes.string),
    filters: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.func,
    preSelect: PropTypes.number,
    listComponent: PropTypes.func.isRequired,
    titleComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    searchBarActive: PropTypes.bool,
    getData: PropTypes.func,
    onSearch: PropTypes.func,
    getPreview: PropTypes.func,
    getDataCallback: PropTypes.func,
    dataLength: PropTypes.number,
};

ListPreview.defaultProps = {
    search: [],
    filters: [],
    preSelect: undefined,
    children: () => {},
    searchBarActive: false,
    getData: () => { },
    onSearch: () => { },
    getPreview: () => { },
    getDataCallback: () => { },
    dataLength: undefined,
};

export default ListPreview;
