import React from 'react';
import { useNewPortfolioLayout } from '../hooks/useNewPortfolioLayout';

const withNewPortfolioLayout = (Component) => (props) => {
    const { isSummary } = props;

    useNewPortfolioLayout({ current: isSummary ? 2 : 1 });

    return <Component {...props} />;
};

export default withNewPortfolioLayout;
