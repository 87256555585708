import React, {
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
    PageVariant, ProgressBar, Step,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileSize';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useClientProfile } from 'prodivers/clientProfile';
import { useStepsByProduct } from './hooks/useStepControl';
import { useStepControl } from './hooks/useStepByProduct';
import { useOnBoardingLayout } from './hooks/useOnBoardingLayout';
import { useOnBoardingStage } from '../../hooks/useOnBoardingStage';

const OnBoardingRouterTemplate = (props) => {
    const {
        pageKey, step, changeStep, children,
    } = props;
    const { t } = useTranslation();
    const { isTabletSize } = useDetectedMobileDevice();
    const {
        product, createdGoal,
    } = useOnBoardingSelector(onBoardingDataSelector);

    useOnBoardingLayout();

    // Client Provider
    const { clientId } = useClientProfile();

    // Data
    const chosenProduct = useMemo(() => product?.id || createdGoal?.ProductId,
        [createdGoal.ProductId, product.id]);
    const chosenProductName = useMemo(() => product?.name || createdGoal?.ProductName,
        [createdGoal.ProductName, product.name]);

    // Steps Control
    const {
        isLoading: isLoadingStage, error,
    } = useOnBoardingStage({ clientId, changeStep });
    const {
        isLoading, haveMissingData, missingStepNumber, missingStep,
    } = useStepControl({ step, pageKey, changeStep });
    const {
        progressBarCurrent, progressBarSteps, missingStepTitle,
    } = useStepsByProduct({ step, chosenProductName, missingStep });

    // Renderer
    const renderContent = useMemo(() => (haveMissingData
        ? (
            <PageVariant
                title={t('onBoarding.error')}
                type="error"
                message={t('onBoarding.missingDataFromPreviousStep')}
                actionButtonLabel={`${t('onBoarding.backToStep')} ${missingStepTitle}`}
                onActionButtonClick={() => changeStep(missingStepNumber)}
            />
        )
        : children),
    [haveMissingData, missingStepNumber, t, missingStepTitle, children, changeStep]);

    return (
        <Preloader isLoading={isLoading || isLoadingStage} error={error}>
            <main className="container">
                <div className="on-boarding">
                    {(step > 1 && chosenProduct) && (
                        <ProgressBar size={isTabletSize && 'small'} current={progressBarCurrent}>
                            {Object.keys(progressBarSteps || {}).map((key) => (
                                <Step key={key} title={progressBarSteps[key]} />
                            ))}
                        </ProgressBar>
                    )}
                    <Preloader isLoading={isLoading}>
                        {renderContent}
                    </Preloader>
                </div>
            </main>
        </Preloader>
    );
};

OnBoardingRouterTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    pageKey: PropTypes.string.isRequired,
    step: PropTypes.number.isRequired,
    changeStep: PropTypes.func.isRequired,
};

OnBoardingRouterTemplate.defaultProps = {
};

export default OnBoardingRouterTemplate;
