import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    ContentBox, Modal, Title, Paragraph, TabPane, Tabs, Infobox,
} from 'ui-library';
import { CHANGE_MODEL, CHANGE_STRATEGY } from 'constants/constants';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import PageHeader from 'components/PageHeader';
import { getClientId } from 'utils';
import history from 'services/history';
import { ExistingInteraction, InteractionDetails } from 'templates/Interaction';
import { useTypesAndTopicsInteraction } from 'hooks/useTypesAndTopicsInteraction';
import { useNewInteraction } from 'hooks/useNewInteraction';
import { useExistingInteraction } from 'hooks/useExistingInteraction';
import { useChangeStrategyConfig } from 'hooks/useChangeStrategyConfig';
import { tradingCardSelector, usePortfolioSelector } from 'domain/Portfolio';
import { onPreventRedirect } from '../../pages/common/utils';
import { mapActionTypeName, MODIFY, QUICK_EDIT } from '../../constants';

import './InteractionAction.css';

function InteractionAction(props) {
    const {
        portfolioTitle,
        dfsClientId,
        portfolioId,
        actionType,
        errorContinue,
        isLoadingContinue,
        onNext,
        clientName,
        executeOnly,
    } = props;
    const backURL = useMemo(() => `/client/${dfsClientId}/portfolios/${portfolioId}`, [dfsClientId, portfolioId]);
    const clientId = getClientId(dfsClientId);
    const isPortfolioEdit = [QUICK_EDIT, MODIFY].includes(actionType);
    const { t } = useTranslation();
    const [isSending, setSending] = useState(false);
    const [interactionType, setInteractionType] = useState('existing');

    // Hooks
    const {
        isLoading: isLoadingTypesAndTopics, types, topics,
    } = useTypesAndTopicsInteraction(true);
    const {
        data: existInteractionData,
        isLoading: isLoadingExist,
        error: errorExist,
        onExistInteractionChange,
        errorUpdating,
        updateInteraction,
        onExistInteractionCommentChange,
        formErrors: existInteractionFormErrors,
        onExistInteractionHandleSubmit,
        values,
        register: existInteractioRegister,
    } = useExistingInteraction(clientId, isLoadingTypesAndTopics, types, topics);
    const {
        register,
        data: newInteractionData,
        isLoading: isLoadingNew,
        error: errorNew,
        values: newInteractionValues,
        formErrors,
        onInteractionChange,
        onNewInteractionCommentChange,
        onNewInteractionHandleSubmit,
        errorCreating: errorCreatingInteraction,
        createInteraction,
        isSearching,
        onSearch,
        control,
    } = useNewInteraction(clientId, isLoadingTypesAndTopics, types, topics, clientName);

    useEffect(() => {
        if (!isLoadingExist && existInteractionData.length === 0) {
            setInteractionType('new');
        }
    }, [isLoadingExist, existInteractionData]);

    const { deleteTradingCard } = usePortfolioSelector(tradingCardSelector);

    const { showExecute, showCreateProposal } = useChangeStrategyConfig();

    // Callbacks
    const onConfirm = (type) => (params) => {
        const modal = Modal.confirm({
            title: t('clientDashboard.confirm'),
            content: t('clientDashboard.changeStrategy.interactionConfirmContent'),
            okText: t('clientDashboard.yes'),
            cancelText: t('clientDashboard.no'),
            onOk: async () => {
                modal.destroy();

                setSending(true);
                try {
                    if (interactionType === 'existing') await updateInteraction();
                    else await createInteraction(params);
                    await onNext(type);
                    setSending(false);
                    sessionStorage.removeItem(QUICK_EDIT);
                } catch (e) {
                    setSending(false);
                }
            },
            className: 'accept-interaction',
        });
    };
    const onSubmit = (e) => {
        e.preventDefault();
    };
    const onConfirmClick = (type) => () => {
        if (interactionType === 'existing') onExistInteractionHandleSubmit(onConfirm(type))();
        else onNewInteractionHandleSubmit(onConfirm(type))();
    };
    const onBack = () => {
        history.push(`${backURL}/${actionType}/orders`);
    };
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                if (actionType === MODIFY) {
                    sessionStorage.removeItem(MODIFY);
                    deleteTradingCard(clientId, portfolioId);
                }
                if (actionType === QUICK_EDIT) {
                    sessionStorage.removeItem(QUICK_EDIT);
                }
                sessionStorage.removeItem(CHANGE_STRATEGY);
                sessionStorage.removeItem(CHANGE_MODEL);
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };

    return (
        <form onSubmit={onSubmit}>
            <div className="interaction">
                <PageHeader
                    title={t('clientDashboard.interaction')}
                    breadCrumbsCurrent={t('clientDashboard.interaction')}
                    breadCrumbsChildren={[
                        {
                            to: `/client/${dfsClientId}/portfolios`,
                            onClick: (e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios`, onCancel),
                            label: t('clientDashboard.portfolio.portfolios'),
                        },
                        {
                            to: backURL,
                            onClick: (e) => onPreventRedirect(e, backURL, onCancel),
                            label: portfolioTitle,
                        },
                        {
                            to: `${backURL}/${actionType}`,
                            label: mapActionTypeName(actionType, t),
                        },
                        {
                            to: isPortfolioEdit ? `${backURL}/${actionType}/confirmation` : undefined,
                            label: t('confirmation.title'),
                        },
                        {
                            to: `${backURL}/${actionType}/orders`,
                            label: t('clientDashboard.portfolio.ordersToBeExecuted'),
                        },
                    ]}
                />
                <ContentBox className="interaction-content">
                    <div className="content-wrapper">
                        <Title type={3}>{t('clientDashboard.interaction')}</Title>
                        <Paragraph type="secondary">{t('clientDashboard.interactionDescription')}</Paragraph>
                        <Tabs activeKey={interactionType} onChange={setInteractionType}>
                            <TabPane tab={t('clientDashboard.existingInteraction')} key="existing">
                                <Preloader isLoading={isLoadingExist} error={errorExist}>
                                    <ExistingInteraction
                                        data={existInteractionData}
                                        comment={values.comment}
                                        selectedRows={[values.interactionId]}
                                        onRowChange={onExistInteractionChange}
                                        onCommentChange={onExistInteractionCommentChange}
                                        register={existInteractioRegister}
                                        errors={existInteractionFormErrors}
                                    />
                                </Preloader>
                            </TabPane>
                            <TabPane tab={t('clientDashboard.newInteraction')} key="new">
                                <Preloader isLoading={isLoadingNew} error={errorNew}>
                                    <InteractionDetails
                                        data={newInteractionData}
                                        errors={formErrors}
                                        values={newInteractionValues}
                                        onChange={onInteractionChange}
                                        onCommentChange={onNewInteractionCommentChange}
                                        isSearching={isSearching}
                                        onSearch={onSearch}
                                        register={register}
                                        control={control}
                                        editable
                                        showCommentHelpText
                                    />
                                </Preloader>
                            </TabPane>
                        </Tabs>
                    </div>
                    {errorUpdating && <Infobox error>{errorUpdating.message}</Infobox>}
                    {errorCreatingInteraction && (
                        <Infobox error>{errorCreatingInteraction.message}</Infobox>
                    )}
                    {errorContinue && <Infobox error>{errorContinue.message}</Infobox>}
                </ContentBox>
                <ButtonsBlockRow
                    leftButton={{
                        text: t('confirmation.back'),
                        loading: isSending || isLoadingContinue,
                        onClick: onBack,
                    }}
                    additionalButton={(executeOnly || !showExecute) ? null : {
                        text: t('confirmation.execute'),
                        loading: isSending || isLoadingContinue,
                        onClick: onConfirmClick('execute'),
                    }}
                    primaryButton={executeOnly
                        ? {
                            text: t('confirmation.execute'),
                            loading: isSending || isLoadingContinue,
                            onClick: onConfirmClick('execute'),
                        }
                        : {
                            text: t('confirmation.createProposal'),
                            loading: isSending || isLoadingContinue,
                            onClick: onConfirmClick('proposal'),
                        }}
                >
                    {(interactionType === 'existing' && existInteractionFormErrors.interactionId) && (
                        <Infobox error className="interaction-select-error">
                            {existInteractionFormErrors.interactionId}
                        </Infobox>
                    )}
                </ButtonsBlockRow>
            </div>
        </form>
    );
}

InteractionAction.propTypes = {
    portfolioTitle: PropTypes.string.isRequired,
    dfsClientId: PropTypes.number.isRequired,
    portfolioId: PropTypes.number.isRequired,
    actionType: PropTypes.string.isRequired,
    errorContinue: PropTypes.shape({
        message: PropTypes.string,
    }),
    isLoadingContinue: PropTypes.bool,
    onNext: PropTypes.func,
    clientName: PropTypes.string,
    executeOnly: PropTypes.bool,
};

InteractionAction.defaultProps = {
    errorContinue: null,
    isLoadingContinue: false,
    onNext: () => {},
    clientName: '',
    executeOnly: false,
};

export default InteractionAction;
