import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Table, ContentBox, Infobox,
} from 'ui-library';
import history from 'services/history';
import { getClientId } from 'utils';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import PageSection from 'components/PageSection';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import PageHeader from 'components/PageHeader';
import EmptyContent from 'components/EmptyContent';
import AddPositionSearchForm from 'components/AddPositionSearchForm';
import { PAGE_SIZE } from 'constants/constants';
import { useSecuritiesSearch } from 'hooks/useSecuritiesSearch';
import { usePagination } from 'hooks/usePagination';
import { adaptSecurities } from 'adaptors/adaptSecurities';
import { useRatings } from 'hooks/useRatings';
import { useCurrencies } from './hooks/useCurrencies';
import { useSecurityTypes } from './hooks/useSecurityTypes';
import { getSecuritiesColumns } from './constants';
import { useNewPortfolioData } from '../../hooks/useNewPortfolioData';
import { useNewPortfolioLayout } from '../../hooks/useNewPortfolioLayout';
import './AddPosition.css';

function AddPosition(props) {
    useCancelAllRequests();
    useNewPortfolioLayout({ current: 1 });

    const { t, i18n: { language } } = useTranslation();
    const { page, onPaginationChange } = usePagination();
    const { match: { params: { dfsClientId } } } = props;
    const [searchCriteria, setSearchCriteria] = useState({
        CurrencyIds: undefined,
        SearchTerm: undefined,
        SecurityTypeIds: undefined,
        Page: page,
    });
    const clientId = getClientId(dfsClientId);
    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/new-portfolio`, [dfsClientId]);

    // New Portfolio - Local data
    const { newPortfolio } = useNewPortfolioData();
    const productId = useMemo(() => newPortfolio?.product?.value, [newPortfolio]);

    // Hooks
    const {
        register, handleSubmit, setValue, watch,
    } = useForm();
    const { dataCurrency, isLoadingCurrency, errorCurrency } = useCurrencies();
    const { dataSecurityTypes, isLoadingSecurityTypes, errorSecurityTypes } = useSecurityTypes();
    const {
        dataRatingAgencies, dataRatings,
        getRatings, isLoadingRatingAgencies, errorRatingAgencies, isLoadingRatings,
    } = useRatings();

    const searchOptions = useMemo(() => (searchCriteria.SecurityTypeIds?.length ? {
        ...searchCriteria,
        PageSize: PAGE_SIZE,
        isActive: true,
        isInvestable: true,
        contactId: clientId,
    } : null), [searchCriteria, clientId]);
    const {
        dataSecurities, totalSecurities, isLoadingSecurities, errorSecurities,
    } = useSecuritiesSearch({
        adaptSecurities: (d) => adaptSecurities(d, { language, baseUrl: `${baseUrl}/add-position/` }),
        productId,
        params: searchOptions,
    });
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedError, setCheckedError] = useState(false);

    if (isLoadingSecurities && checkedItem) {
        setCheckedItem(null);
    }

    useEffect(() => {
        if (dataSecurityTypes?.length) {
            setSearchCriteria((arr) => ({
                ...arr, SecurityTypeIds: dataSecurityTypes.map(({ value }) => value),
            }));
        }
    }, [dataSecurityTypes]);
    useEffect(() => {
        if (checkedItem) {
            setCheckedError(false);
        }
    }, [checkedItem]);
    useEffect(() => {
        register({ name: 'SearchTerm' });
        register({ name: 'SecurityTypeIds' });
        register({ name: 'CurrencyIds' });
        register({ name: 'RatingAgencyIds' });
        register({ name: 'RatingIds' });
    }, [register]);

    /* Ratings */
    const values = watch();
    const [dataRatingsByAgencyIdsCached, setDataRatingsByAgencyIdsCached] = useState({});
    const ratingAgencyId = values?.RatingAgencyIds;

    useEffect(() => {
        setValue('RatingIds', undefined);
    }, [ratingAgencyId]);

    useEffect(() => {
        if (dataRatings?.length > 0) {
            const obj = dataRatings?.reduce((acc, item) => ({
                ...acc,
                [item?.ratingAgencyId]: dataRatings,
            }), {});

            setDataRatingsByAgencyIdsCached((prev) => ({
                ...prev,
                ...obj,
            }));
        }
    }, [dataRatings]);

    useEffect(() => {
        if (ratingAgencyId && !dataRatingsByAgencyIdsCached[ratingAgencyId]) {
            getRatings(ratingAgencyId);
        }
    }, [ratingAgencyId, getRatings, dataRatingsByAgencyIdsCached]);
    // Callbacks
    const onAddPosition = () => {
        if (checkedItem) {
            history.push(`${baseUrl}/add-position/${checkedItem}`);
        } else {
            setCheckedError(true);
        }
    };
    const onCheck = useCallback((items) => {
        setCheckedItem(items[0]);
    }, [setCheckedItem]);
    const onChange = useCallback((pagination) => {
        onPaginationChange(pagination);

        if (pagination?.currentPage !== searchCriteria.Page && !isLoadingSecurities) {
            setSearchCriteria((arr) => ({ ...arr, Page: pagination?.currentPage }));
        }
    }, [onPaginationChange, searchCriteria.Page, isLoadingSecurities]);
    const handleFilterSubmit = useCallback((data) => {
        onPaginationChange({ currentPage: 1 });
        setSearchCriteria((arr) => ({
            ...arr,
            ...data,
            RatingAgencyIds: data.RatingAgencyIds ? [+data.RatingAgencyIds] : undefined,
            SecurityTypeIds: data.SecurityTypeIds?.length
                ? data.SecurityTypeIds
                : dataSecurityTypes.map(({ value }) => value),
            Page: 1,
        }));
    }, [onPaginationChange, dataSecurityTypes]);

    return (
        <PageSection className="add-position">
            <Preloader
                isLoading={isLoadingCurrency || isLoadingSecurityTypes || isLoadingRatingAgencies}
                error={errorCurrency || errorSecurityTypes || errorRatingAgencies}
            >
                <PageHeader title={`${t('portfolio.createNewPortfolio')} - ${t('clientDashboard.portfolioEdit.addPosition')}`} />
                <ContentBox underline={false}>
                    <div className="content-wrapper">
                        <AddPositionSearchForm
                            searchDisabled={isLoadingSecurities}
                            onSearch={handleSubmit(handleFilterSubmit)}
                            onChangeISIN={(e) => setValue('SearchTerm', e?.target?.value)}
                            typeOptions={dataSecurityTypes}
                            onChangeType={(v) => setValue('SecurityTypeIds', v)}
                            currencyOptions={dataCurrency}
                            onChangeCurrency={(v) => setValue('CurrencyIds', v)}
                            ratingAgencyOptions={dataRatingAgencies || []}
                            onChangeRatingAgency={(v) => setValue('RatingAgencyIds', v)}
                            ratingOptions={dataRatingsByAgencyIdsCached[ratingAgencyId] || []}
                            onChangeRating={(v) => setValue('RatingIds', v)}
                            isLoadingRatings={isLoadingRatings}
                            ratingAgencyValue={ratingAgencyId}
                            ratingValue={values?.RatingIds}
                        />
                        <Preloader isLoading={isLoadingSecurities} error={errorSecurities}>
                            <EmptyContent data={dataSecurities} text={t('addPosition.noSecurities')}>
                                <Table
                                    columns={getSecuritiesColumns(t)}
                                    data={dataSecurities}
                                    onChange={onChange}
                                    rowSelection={{
                                        type: 'radio',
                                        onChange: onCheck,
                                    }}
                                    paging={{
                                        total: totalSecurities,
                                        currentPage: page,
                                        pageSize: PAGE_SIZE,
                                    }}
                                    headerControls={{
                                        selectControlsLabel: {
                                            selectAll: t('headerControls.selectAll'),
                                            reset: t('headerControls.reset'),
                                        },
                                    }}
                                />
                            </EmptyContent>
                        </Preloader>
                    </div>
                    {checkedError && (
                        <Infobox error className="validation-error">
                            <div>{t('portfolios.validation.addPosition')}</div>
                        </Infobox>
                    )}
                    <ButtonsBlockRow
                        leftButton={{
                            text: t('clientDashboard.portfolioEdit.cancel'),
                            onClick: () => history.push(`${baseUrl}/modify`),
                        }}
                        primaryButton={{
                            text: t('clientDashboard.portfolioEdit.continue'),
                            disabled: (!checkedItem && !dataSecurities.length)
                                || checkedItem === -1,
                            onClick: onAddPosition,
                        }}
                    />
                </ContentBox>
            </Preloader>
        </PageSection>
    );
}

AddPosition.propTypes = {
    match: PropTypes.shape({
        params: {
            portfolioId: PropTypes.string.isRequired,
            dfsClientId: PropTypes.string.isRequired,
        },
        path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        state: {
            portfolioName: PropTypes.string.isRequired,
        },
    }).isRequired,
};

AddPosition.defaultProps = {};

export default AddPosition;
