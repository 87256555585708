import { orderBy } from 'lodash/fp';
import {
    IndexId, LiquidityId, MortgageId, CoreTypeId, SatelliteTypeId,
} from 'constants/instrument';

export const adaptData = (data, { withLiquidity } = {}) => orderBy(
    'label',
    'asc',
    (data || [])
        .filter(({ Id }) => ![
            withLiquidity ? null : LiquidityId, MortgageId, IndexId, CoreTypeId, SatelliteTypeId,
        ].includes(Id))
        .map(({ Id, Name }) => ({ label: Name, value: Id })),
);
