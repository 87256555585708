import { sortAssets } from 'utils/sortingAllocation';

export const adaptModifiedSecurities = (
    positions, { positionLink, maxLength } = {},
) => sortAssets(positions || [])
    .map((group) => ({
        ...group,
        children: group.children ? group.children.map((item) => {
            const nameProps = item.typeId !== 1 && positionLink
                ? { link: positionLink && `${positionLink}/${item.securityId}`, label: item.name.value }
                : { label: undefined };

            return {
                ...item,
                key: `${item.name.value}-${item.securityId}`,
                name: { maxLength, ...item.name, ...nameProps },
                nameNew: { maxLength, ...item.nameNew, ...nameProps },
            };
        }) : [],
    }));
