import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { getPositionsColumns } from 'constants/portfolio';

function PositionsCompare({
    data, isLoading, error, columns, defaultExpandAllRows,
}) {
    const { t } = useTranslation();

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent data={data} text={t('confirmation.noTransactionsFound')}>
                <Table
                    data={data.positions}
                    columns={columns || getPositionsColumns(t)}
                    expandedColumn="name"
                    defaultExpandAllRows={defaultExpandAllRows}
                />
            </EmptyContent>
        </Preloader>
    );
}

PositionsCompare.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        AllocationD: PropTypes.shape({
            currentValue: PropTypes.string,
            newValue: PropTypes.string,
        }),
        ValueData: PropTypes.shape({
            currentValue: PropTypes.string,
            newValue: PropTypes.string,
        }),
    })),
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        render: PropTypes.func,
    })),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    defaultExpandAllRows: PropTypes.bool,
};

PositionsCompare.defaultProps = {
    data: [],
    isLoading: true,
    columns: null,
    error: null,
    defaultExpandAllRows: false,
};

export default PositionsCompare;
