import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    ContentBox, Modal, Title, Paragraph, TabPane, Tabs, Infobox,
} from 'ui-library';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import PageHeader from 'components/PageHeader';
import { getClientId, getRandomNumber } from 'utils';
import { formatDate } from 'utils/datetime';
import history from 'services/history';
import { ExistingInteraction, InteractionDetails } from 'templates/Interaction';
import { useTypesAndTopicsInteraction } from 'hooks/useTypesAndTopicsInteraction';
import { useNewInteraction } from 'hooks/useNewInteraction';
import { useClientProfile } from 'prodivers/clientProfile';
import { useExistingInteraction } from 'hooks/useExistingInteraction';
import { useNewPortfolioData } from '../../hooks/useNewPortfolioData';
import { useCreatePortfolio } from '../../hooks/useCreatePortfolio';
import { useNewPortfolioLayout } from '../../hooks/useNewPortfolioLayout';
import { mapActionTypeName, NEW_PORTFOLIO } from '../../../../constants';
import { useCreateProposal } from '../../../common/hooks/useCreateProposal';

function InteractionAction(props) {
    useNewPortfolioLayout({ current: 3 });

    const { match: { params: { dfsClientId } } } = props;

    // Client Provider
    const { clientName } = useClientProfile();

    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();
    const [isSending, setSending] = useState(false);
    const [interactionType, setInteractionType] = useState('existing');

    // Hooks
    const {
        isLoading: isLoadingTypesAndTopics, types, topics,
    } = useTypesAndTopicsInteraction(true);
    const {
        data: existInteractionData,
        isLoading: isLoadingExist,
        error: errorExist,
        onExistInteractionChange,
        errorUpdating,
        updateInteraction,
        onExistInteractionCommentChange,
        formErrors: existInteractionFormErrors,
        onExistInteractionHandleSubmit,
        values,
        register: existInteractioRegister,
    } = useExistingInteraction(clientId, isLoadingTypesAndTopics, types, topics);
    const {
        register,
        data: newInteractionData,
        isLoading: isLoadingNew,
        error: errorNew,
        values: newInteractionValues,
        formErrors,
        onInteractionChange,
        onNewInteractionCommentChange,
        onNewInteractionHandleSubmit,
        errorCreating: errorCreatingInteraction,
        createInteraction,
        isSearching,
        onSearch,
        control,
    } = useNewInteraction(clientId, isLoadingTypesAndTopics, types, topics, clientName);

    useEffect(() => {
        if (!isLoadingExist && existInteractionData.length === 0) {
            setInteractionType('new');
        }
    }, [isLoadingExist, existInteractionData]);

    // New Portfolio - Local data
    const { newPortfolio, modifiedPositions } = useNewPortfolioData();
    const paramsToCreate = useMemo(() => ({
        name: getRandomNumber(6),
        amount: newPortfolio?.portfolioAmount,
        productId: newPortfolio?.product?.value,
        currencyId: newPortfolio?.portfolioCurrency?.value,
        strategyId: newPortfolio?.strategy?.value,
        allocationsOrder: modifiedPositions?.raw?.length ? {
            Allocations: modifiedPositions?.raw
                .map((item) => ({ instrumentId: item.Id, allocation: item.Allocation / 100 })),
        } : undefined,
    }), [newPortfolio, modifiedPositions]);

    // Create New Portfolio
    const {
        createPortfolio, isLoading: isLoadingCreate, error: errorCreate,
        updateProposal, errorUpdate, isLoadingUpdate,
    } = useCreatePortfolio({ clientId, params: paramsToCreate });

    // Proposal
    const { onCreateProposal } = useCreateProposal(NEW_PORTFOLIO);
    const handleCreateProposal = async (portfolioId) => {
        const nameProposal = `${mapActionTypeName(NEW_PORTFOLIO, t)} ${paramsToCreate?.name} ${t('proposal.on')} ${formatDate(new Date())}`;

        const proposalId = await onCreateProposal({
            clientId,
            dfsClientId,
            portfolioId,
            productId: newPortfolio?.product?.value,
            allocations: paramsToCreate?.allocationsOrder?.Allocations,
            name: nameProposal,
            modelPortfolioId: newPortfolio?.model,
        });

        return proposalId;
    };

    // Callbacks
    const onConfirm = () => (params) => {
        const modal = Modal.confirm({
            title: t('clientDashboard.confirm'),
            content: t('clientDashboard.changeStrategy.interactionConfirmContent'),
            okText: t('clientDashboard.yes'),
            cancelText: t('clientDashboard.no'),
            onOk: async () => {
                modal.destroy();

                setSending(true);
                try {
                    const createdPortfolio = await createPortfolio();

                    if (interactionType === 'existing') await updateInteraction();
                    else await createInteraction(params);

                    const proposalId = await handleCreateProposal(createdPortfolio?.Id);
                    const paramsToUpdate = {
                        PortfolioProposalStatusId: {
                            DoUpdate: true,
                            Value: 3,
                        },
                    };

                    await updateProposal(proposalId, paramsToUpdate);

                    setSending(false);
                    history.push({
                        pathname: `/client/${dfsClientId}/portfolios/new-portfolio/completion`,
                        state: {
                            newPortfolioData: {
                                ...paramsToCreate,
                                allocations: paramsToCreate?.allocationsOrder?.Allocations,
                                modelPortfolioId: Number(newPortfolio?.model),
                                portfolioId: createdPortfolio?.Id,
                                proposalId,
                            },
                        },
                    });
                } catch (e) {
                    setSending(false);
                }
            },
            className: 'accept-interaction',
        });
    };
    const onSubmit = (e) => {
        e.preventDefault();
    };
    const onConfirmClick = () => () => {
        if (interactionType === 'existing') onExistInteractionHandleSubmit(onConfirm())();
        else onNewInteractionHandleSubmit(onConfirm())();
    };
    const onBack = () => {
        history.push(`/client/${dfsClientId}/portfolios/new-portfolio/summary`);
    };

    return (
        <form onSubmit={onSubmit}>
            <div className="interaction">
                <PageHeader
                    title={t('clientDashboard.interaction')}
                />
                <ContentBox className="interaction-content">
                    <div className="content-wrapper">
                        <Title type={3}>{t('clientDashboard.interaction')}</Title>
                        <Paragraph type="secondary">{t('clientDashboard.interactionDescription')}</Paragraph>
                        <Tabs activeKey={interactionType} onChange={setInteractionType}>
                            <TabPane tab={t('clientDashboard.existingInteraction')} key="existing">
                                <Preloader isLoading={isLoadingExist} error={errorExist}>
                                    <ExistingInteraction
                                        data={existInteractionData}
                                        comment={values.comment}
                                        selectedRows={[values.interactionId]}
                                        onRowChange={onExistInteractionChange}
                                        onCommentChange={onExistInteractionCommentChange}
                                        register={existInteractioRegister}
                                        errors={existInteractionFormErrors}
                                    />
                                </Preloader>
                            </TabPane>
                            <TabPane tab={t('clientDashboard.newInteraction')} key="new">
                                <Preloader isLoading={isLoadingNew} error={errorNew}>
                                    <InteractionDetails
                                        data={newInteractionData}
                                        errors={formErrors}
                                        values={newInteractionValues}
                                        onChange={onInteractionChange}
                                        onCommentChange={onNewInteractionCommentChange}
                                        isSearching={isSearching}
                                        onSearch={onSearch}
                                        register={register}
                                        control={control}
                                        editable
                                        showCommentHelpText
                                    />
                                </Preloader>
                            </TabPane>
                        </Tabs>
                    </div>
                    {errorUpdating && <Infobox error>{errorUpdating.message}</Infobox>}
                    {errorCreatingInteraction && (
                        <Infobox error>{errorCreatingInteraction.message}</Infobox>
                    )}
                    {errorUpdate && <Infobox error>{errorUpdate.message}</Infobox>}
                    {errorCreate && <Infobox error>{errorCreate.message}</Infobox>}
                </ContentBox>
                <ButtonsBlockRow
                    leftButton={{
                        text: t('confirmation.back'),
                        loading: isSending || isLoadingCreate || isLoadingUpdate,
                        onClick: onBack,
                    }}
                    primaryButton={
                        {
                            text: t('portfolio.createPortfolio'),
                            loading: isSending || isLoadingCreate || isLoadingUpdate,
                            onClick: onConfirmClick(),
                        }
                    }
                >
                    {(interactionType === 'existing' && existInteractionFormErrors.interactionId) && (
                        <Infobox error className="interaction-select-error">
                            {existInteractionFormErrors.interactionId}
                        </Infobox>
                    )}
                </ButtonsBlockRow>
            </div>
        </form>
    );
}

InteractionAction.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

InteractionAction.defaultProps = {
};

export default InteractionAction;
