import advisoryImg from 'assets/images/products/AdvisoryImg.jpg';
import executionImg from 'assets/images/products/ExecutionImg.jpg';
import discretionaryImg from 'assets/images/products/DiscretionaryImg.jpg';

export const stepValues = ['low', 'rather low', 'medium', 'rather high', 'high'];

export const riskReturnValues = [
    { data: [2, 8, 15, 39, 45] },
    { data: [-1, -3, -8, -25, -30] },
];

export const step3Data = (t) => ({
    Name: 'Review',
    title: 'Review',
    sections: [
        {
            title: 'Your investment horizon',
            description: t('portfolioRiskProfile.investmentHorizonDesc'),
            redoButton: true,
        },
        {
            title: 'Your risk / return expectation',
            description: t('portfolioRiskProfile.riskReturnExpectationDesc'),
            redoButton: true,
        },
        {
            title: 'Recommended Portfolio Profile',
            description: t('portfolioRiskProfile.recommendedPortfolioDesc'),
        },
    ],
    infobox: [
        'The examination of your risk tolerance and risk capacity has yielded different results. This is a fairly normal scenario. We are recommending that you select the lower risk profile.',
        'The examination of your risk tolerance and risk capacity has yielded different results. This is a fairly normal scenario. Since your risk capacity is rated higher, the choice of a higher risk level would also be justifiable.',
    ],
});

export const step4Data = {
    Name: 'Product selection',
    title: 'Clients',
    products: [
        {
            id: 1,
            name: 'Execution only',
            image: executionImg,
            description: 'You choose the "Execution only" option. This allows you to take sole control of the assets invested in this portfolio. You waive the need for advice from a member of your advisory team and execute the investment decisions autonomously.',
        },
        {
            id: 2,
            name: 'Discretionary mandate',
            image: discretionaryImg,
            description: 'You choose the Discretionary Mandate. You are delegating all investment decisions in connection with this portfolio to the asset manager. Investments are made within the limits of the determined client and investment profile.',
        },
        {
            id: 3,
            name: 'Advisory mandate',
            image: advisoryImg,
            description: 'You have opted for the advisory mandate. You will be supported by our experts. We will monitor your portfolio and notify you of deviations and provide tailored advice. The investment decisions are made by you.\n',
        },
    ],
};

export const steps = {
    questions: {
        Groups: [
            {
                Id: 9,
                Name: 'Investment Horizon',
                Description: 'Investment Horizon',
                Steps: [
                    {
                        Id: 9,
                        Name: 'Investment Horizon',
                        Questions: [
                            {
                                Id: 61,
                                Type: 'SingleAnswer',
                                Layout: 'Radio',
                                Text: 'When do you plan to use a substantial amount of the invested money?',
                                Description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.',
                                Order: 1,
                                Answers: [
                                    {
                                        Id: 306,
                                        Text: 'Less than 3 years',
                                        Order: 1,
                                    },
                                    {
                                        Id: 307,
                                        Text: 'In 3 to 6 years',
                                        Order: 2,
                                    },
                                    {
                                        Id: 308,
                                        Text: 'In 6 to 9 years',
                                        Order: 3,
                                    },
                                    {
                                        Id: 309,
                                        Text: 'In 9 to 12 years',
                                        Order: 4,
                                    },
                                    {
                                        Id: 310,
                                        Text: 'More than 12 years',
                                        Order: 5,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                Id: 10,
                Name: 'Risk / Return Expectation',
                Description: 'Risk / Return Expectation',
                Steps: [
                    {
                        Id: 10,
                        Name: 'Risk / Return Expectation',
                        Questions: [
                            {
                                Id: 61,
                                Type: 'SingleAnswer',
                                Layout: 'Radio',
                                Text: 'When do you plan to use a substantial amount of the invested money?',
                                Order: 1,
                                Answers: [
                                    {
                                        Id: 306,
                                        Text: 'low',
                                        Order: 1,
                                    },
                                    {
                                        Id: 307,
                                        Text: 'rather low',
                                        Order: 2,
                                    },
                                    {
                                        Id: 308,
                                        Text: 'medium',
                                        Order: 3,
                                    },
                                    {
                                        Id: 309,
                                        Text: 'rather high',
                                        Order: 4,
                                    },
                                    {
                                        Id: 310,
                                        Text: 'high',
                                        Order: 5,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    results: [
        step3Data,
        step4Data,
    ],
};

export const executionHardCoded = {
    Positions: [
        {
            Security: {
                Id: 22,
                Name: 'Liquidity EUR',
                TradingPrice: 1,
                AssetClass: {
                    Id: 1,
                    Name: 'Liquidity',
                    Description: 'Liquidity',
                },
                Type: {
                    Id: 1,
                    Name: 'Liquidity',
                    Description: 'Liquidity',
                },
                Sector: {
                    Id: 172,
                    Name: 'Financials',
                    Description: '',
                },
                Country: {
                    Id: 1,
                    CountryCode: 'AD',
                    Name: 'Andorra',
                    Continent: {
                        Id: 1,
                        Name: 'Europe',
                    },
                },
                Currency: {
                    Id: 2,
                    Name: 'Euro',
                    CurrencyCode: 'EUR',
                    Symbol: '€',
                },
            },
            Allocation: 1,
        },
    ],
};
