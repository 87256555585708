import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Portfolios from './Portfolios';
import PortfolioRiskProfile from './pages/PortfolioRiskProfile';
import PortfolioRouter from './PortfolioRouter';
import CreateNewPortfolioRouter from './pages/CreateNewPortfolio/Router';

const Router = (props) => {
    const { match: { path } } = props;
    const withProps = (Component) => (defProps) => <Component {...props} {...defProps} />;

    return (
        <Switch>
            <Route exact path={`${path}`} render={withProps(Portfolios)} />
            <Route exact path={`${path}/risk-profile`} component={PortfolioRiskProfile} />
            <Route path={`${path}/new-portfolio`} render={withProps(CreateNewPortfolioRouter)} />
            <Route path={`${path}/:portfolioId`} component={PortfolioRouter} />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
