import {
    useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';
import useFormatting from 'locale/useFormatting';
import { getFirstNextMonthDay, formatDateInUTCLocal } from 'utils/datetime';
import { sorterByDateCommon } from 'utils/sorting';

export const useSaveAndUpdateGoal = ({ clientId }) => {
    const { i18n: { language } } = useTranslation();
    const [saveError, setSaveError] = useState(null);
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [updateError, setUpdateError] = useState(null);
    const [updateIsLoading, setUpdateIsLoading] = useState(false);
    const [goal, setGoal] = useState(null);
    const [errorGetGoal, setErrorGetGoal] = useState(null);
    const [isLoadingGetGoal, setIsLoadingGetGoal] = useState(false);
    const {
        getFormattedDate,
    } = useFormatting();

    const saveGoal = useCallback(async (data) => {
        if (!data) return null;

        try {
            setSaveIsLoading(true);

            const params = {
                Name: data.goalName,
                TargetAmount: +data.targetValue,
                DueDate: data.targetDate,
                Status: 1,
                GoalType: 1,
                GoalTemplateId: +data.selectedGoal,
                CurrencyId: +data.selectedCurrency?.value,
                Funding: {
                    FundingTypeId: 2,
                    Amount: +data.recurringPayment,
                    CurrencyId: data.selectedCurrency?.value,
                    IsActive: true,
                    CreateDate: formatDateInUTCLocal(new Date()),
                    EndDate: data.targetDate,
                    FirstExecutionDate: formatDateInUTCLocal(getFirstNextMonthDay()),
                    RecurrenceTypeId: +data.recurringPaymentFrequency?.id,
                    IsLockedForProcessing: false,
                },
            };

            const response = await ServiceManager.goalsService('saveGoal', [clientId, language, params]);

            setSaveIsLoading(false);

            return response.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: setSaveError,
                    setLoading: setSaveIsLoading,
                }),
            )(err);

            throw err?.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, language, getFormattedDate]);

    const updateFunding = useCallback(async (goalId, data) => {
        if (!data || !goalId) return null;

        try {
            setUpdateIsLoading(true);

            const searchParams = {
                Page: 1,
                PageSize: 1,
                GoalIds: [
                    goalId,
                ],
                IsActive: true,
            };

            const { data: fundingData } = await ServiceManager.fundingManagement('searchFunding', [clientId, searchParams]);

            const funding = fundingData?.Fundings[0];

            const updateParams = {
                ...funding,
                Amount: +data.recurringPayment,
                CurrencyId: data.selectedCurrency?.value,
                EndDate: data.targetDate,
                RecurrenceTypeId: +data.recurringPaymentFrequency?.id,
            };

            const { data: updatedFunding } = await ServiceManager.fundingManagement('patchFunding', [clientId, funding?.Id, updateParams]);

            setUpdateIsLoading(false);

            return updatedFunding;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: setUpdateError,
                    setLoading: setUpdateIsLoading,
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId]);

    const updateGoal = useCallback(async (goalId, data, withFunding) => {
        if (!data || !goalId) return null;

        try {
            setUpdateIsLoading(true);

            const params = {
                Name: {
                    DoUpdate: true,
                    Value: data.goalName,
                },
                TargetAmount: {
                    DoUpdate: true,
                    Value: +data.targetValue,
                },
                DueDate: {
                    DoUpdate: true,
                    Value: data.targetDate,
                },
                GoalTemplateId: +data.selectedGoal,
                Status: 1,
                CurrencyId: {
                    DoUpdate: true,
                    Value: +data.selectedCurrency?.value,
                },
            };

            if (withFunding) {
                await updateFunding(goalId, data);
            }

            const response = await ServiceManager.goalsService('patchGoal', [clientId, goalId, language, params]);

            setUpdateIsLoading(false);

            return response.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: setUpdateError,
                    setLoading: setUpdateIsLoading,
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, language, updateFunding]);

    const getGoal = useCallback(async () => {
        if (!clientId) return null;

        try {
            setErrorGetGoal(null);
            setUpdateIsLoading(true);

            const response = await ServiceManager.goalsService('getContactGoals', [clientId, language]);
            const goalDetails = (response?.data || [])
                .sort(sorterByDateCommon('CreateDate'))
                .slice(-1)?.[0];
            let goalData = goalDetails;

            if (goalDetails?.Id) {
                const searchParams = {
                    Page: 1, PageSize: 1, GoalIds: [goalDetails?.Id], IsActive: true,
                };
                const { data: fundingData } = await ServiceManager.fundingManagement('searchFunding', [clientId, searchParams]);

                goalData = { ...goalData, Funding: fundingData?.Fundings?.[0] };
            }

            setGoal(goalData);
            setIsLoadingGetGoal(false);

            return goalData;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: setErrorGetGoal,
                    setLoading: setIsLoadingGetGoal,
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, language]);

    return {
        saveGoal,
        updateGoal,
        updateFunding,
        saveIsLoading,
        updateIsLoading,
        saveError,
        updateError,
        isLoading: saveIsLoading || updateIsLoading,
        error: saveError || updateError,
        goal,
        errorGetGoal,
        isLoadingGetGoal,
        getGoal,
    };
};
