import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { memberIdSelector } from 'redux/auth/authSelectors';
import { withCSContext } from 'services/ApolloClient';
import SM from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { useSchema } from 'domain/ClientService';
import { getImgBase64Src, adaptClientInformation } from '../adaptors/adaptClientInfo';

const CLIENT_INFORMATION = gql`
    query ClientInformation($clientId: Int!) {
        contacts(
            where: { id: { eq: $clientId } }
        ){
            items {
                id
                personalInformation {
                    firstName
                    lastName
                    displayName
                    dateOfBirth
                }
                relationshipInformation {
                    isProspect
                }
                communicationMethods {
                    primaryEmail
                    primaryPhoneCountryCode { label value }
                    primaryPhoneNumber
                    primaryAddress {
                        primaryAddressLine1
                        primaryAddressLine2
                        primaryAddressLine3
                        primaryAddressLine4
                        primaryAddressLine5
                    }
                    primaryAddressCountry { label value }
                }
                investmentInformation {
                    chosenRiskCategory { label value }
                }
            }
        }
    }
`;

export const useGetClientInfo = (options) => {
    const { clientId, withAdditionalInfo } = options;
    const { t } = useTranslation();
    const memberId = useSelector(memberIdSelector);
    const { getFormattedDate, getFormattedPhoneNumber } = useFormatting();
    const { getSchemaLabelById } = useSchema();

    const [cancelError, setCancelError] = useState(null);
    const [errorImg, setError] = useState(null);
    const [isLoadingImg, setLoading] = useState(false);
    const [img, setImg] = useState(null);

    const { loading: isLoading, error, data: dataClientRaw } = useQuery(CLIENT_INFORMATION, {
        variables: { clientId },
        context: withCSContext({ queryParams: { memberId } }),
    });
    const getClientAdditional = useCallback(async () => {
        if (withAdditionalInfo) {
            try {
                setError(null);
                setLoading(true);
                const response = await SM.contactManagement('getClientPicture', [clientId]);

                setImg(getImgBase64Src(response.data?.ImageContentBase64));
                setLoading(false);
            } catch (err) {
                handlerRequestCanceling(HandlerError({ setError, setLoading }))(err);
                setCancelError(true);
            }
        }
    }, [clientId, withAdditionalInfo]);

    useEffect(() => {
        getClientAdditional();
        setCancelError(false);
    }, [getClientAdditional, cancelError]);

    const dataClient = useMemo(() => adaptClientInformation(dataClientRaw?.contacts?.items?.[0], {
        t,
        getFormattedDate,
        getFormattedPhoneNumber,
        getSchemaLabelById,
    }), [dataClientRaw, getFormattedDate, getFormattedPhoneNumber, getSchemaLabelById, t]);
    const hasRiskProfile = useMemo(() => dataClient?.HasRiskProfile, [dataClient]);
    const data = useMemo(() => ({
        ...dataClient, Img: img,
    }), [dataClient, img]);

    return {
        data,
        error: error || errorImg,
        isLoading: isLoading || isLoadingImg,
        hasRiskProfile,
    };
};
