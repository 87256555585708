import { checkNullData } from 'utils';

export const adaptConfiguration = (productOffers, currencyObj) => {
    if (checkNullData(productOffers)) return { currency: {}, products: [] };

    const products = productOffers.map(({ Products }) => Products.map(({ Id }) => Id)).flat();
    const currency = currencyObj
        ? productOffers.map(({ Currencies }) => Currencies).flat()
            .find(({ Id }) => Id === Number(currencyObj.Value))
        : {};

    return { currency, products };
};
