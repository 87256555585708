import { adaptAllocationGroups } from 'adaptors/adaptAllocationGroups';
import { adaptProjection, getAllocations } from 'adaptors/adaptAllocations';
import { validateNumber } from 'utils/formatting';
import { adaptPositions } from './adaptPositions';

export const adaptSummary = (data, options) => {
    const {
        productId,
        productName,
        currency: currencyInit,
        portfolioValue,
        projectionYears,
        nameLength,
        bandwidthTitle,
        riskReturnTitle,
        performanceTitle,
        positionLink,
    } = options;
    const t = options?.t || ((d) => d);
    const getFormattedDate = options?.getFormattedDate || ((d) => d);
    const getFormattedNumber = options?.getFormattedNumber || ((d) => d);
    const getFormattedCurrency = options?.getFormattedCurrency || ((d) => d);
    const currency = currencyInit?.label;
    const currencyId = currencyInit?.value;
    let positions = [];

    if (data && data.Positions) {
        positions = data.Positions;
    } else if (data && data.ConsolidatedPositions) {
        positions = data.ConsolidatedPositions;
    }

    return {
        isProposed: true,
        modelPortfolioId: data?.Id,
        backTitle: data?.Name,
        title: data?.Name,
        currency,
        currencyId,
        overview: {
            portfolioValue,
            currency,
            strategy: data?.RiskCategory?.Name,
            product: productName,
            performance: '- -',
            sharpRatio: data?.SharpeRatio,
            expectedReturn: data?.CalculatedExpectedReturn * 100,
            expectedRisk: data?.CalculatedRisk * 100,
            firstInvestment: getFormattedDate(data?.FirstInvestmentDate),
        },
        constraints: data?.OptimizationConstraints || {},
        investmentAllocation: {
            chart: adaptAllocationGroups({
                securitiesValue: portfolioValue,
                positions,
                currency,
                t,
                getFormattedNumber,
                getFormattedCurrency,
            }),
            currency,
        },
        investmentAllocations: getAllocations(data?.Positions || []),
        productId,
        productName,
        projection: adaptProjection({
            data: positions,
            CurrencyId: currencyId,
            ProjectionYears: projectionYears || 5,
            InitialInvestmentAmount: portfolioValue,
        }),
        positionsRaw: data?.Positions,
        positions: adaptPositions(data?.Positions, {
            portfolioValue,
            portfolioCurrency: currency,
            nameLength,
            isNew: false,
            positionLink,
            getFormattedNumber,
            getFormattedCurrency,
            t,
        }),
        portfolioValue,
        positionIDS: data?.Positions?.map((i) => i?.Security?.Id),
        riskReturn: [+(data?.CalculatedRisk * 100), +(data?.CalculatedExpectedReturn * 100)],
        riskReturnMatrix: {
            x: [validateNumber(data?.RiskCategory?.Min) * 100,
                validateNumber(data?.RiskCategory?.Max) * 100],
        },
        bandwidthTitle: bandwidthTitle || t('confirmation.current'),
        riskReturnTitle: riskReturnTitle || t('onBoarding.modelPortfolio'),
        performanceTitle,
    };
};
