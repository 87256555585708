import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    BANDWIDTH, LINE, PROJECTION, RISKRETURN, SUSTAINABILITY,
} from 'constants/constants';
import { adaptBandwidth, adaptSustainability } from 'adaptors/adaptAnalysis';
import { useProjection } from './useProjection';
import { usePerformanceAndRiskCompare } from './usePerformanceAndRiskCompare';
import { useFormatting } from '../locale';
import BandwidthLabel from '../components/BandwidthLabel';

export const useAnalysisDetails = ({
    portfolio = {},
    strategy,
    positions,
    positionsRaw,
    benchmark,
    isEdit,
    additionalOptions,
    clientId,
    isLoadingBenchmark,
} = {
    isEdit: false,
    useAmountPerformance: false,
}) => {
    const { t } = useTranslation();
    const { getFormattedNumber } = useFormatting();

    // Other hooks
    const {
        performanceAndRisk, performanceSelected, onPerformanceChange, volatility,
    } = usePerformanceAndRiskCompare({
        clientId,
        portfolio,
        strategy,
        currencyId: portfolio.currencyId,
        benchmark,
        isLoading: isLoadingBenchmark,
        additionalOptions: {
            ...additionalOptions || {},
            isEdit,
        },
    });
    const {
        projection, isLoadingProjection, errorProjection, projectionResponse,
    } = useProjection({
        portfolio: portfolio?.projection,
        strategy: strategy?.projection,
        currency: portfolio.currency,
    });

    // Optional
    const numberFormat = useCallback((number) => getFormattedNumber(number.toFixed(0)),
        [getFormattedNumber]);
    const labelFormat = useCallback((label, values) => BandwidthLabel({
        label, values, getFormattedNumber, t,
    }), [getFormattedNumber, t]);

    // Data
    const isLoadingModel = useMemo(() => performanceAndRisk[LINE].isLoading
        || performanceAndRisk[RISKRETURN].isLoading
        || isLoadingProjection,
    [isLoadingProjection, performanceAndRisk]);
    const bandwidthData = useMemo(() => adaptBandwidth({
        portfolio, strategy, positions, numberFormat, t, labelFormat,
    }), [portfolio, strategy, positions, numberFormat, t, labelFormat]);
    const sustainabilityData = useMemo(() => adaptSustainability({
        data: positionsRaw,
        name: portfolio.title,
        currency: portfolio.currency,
        portfolioId: portfolio.esgPortfolioId,
    }), [positionsRaw, portfolio.title, portfolio.currency, portfolio.esgPortfolioId]);

    return {
        analysisData: {
            ...performanceAndRisk,
            [BANDWIDTH]: bandwidthData,
            [PROJECTION]: {
                ...projection,
                error: errorProjection,
                isLoading: isLoadingProjection,
            },
            [SUSTAINABILITY]: {
                data: sustainabilityData,
            },
        },
        volatility,
        isLoadingModel,
        performanceSelected,
        onPerformanceChange,
        projectionResponse,
    };
};
