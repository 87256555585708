import { useCallback, useEffect } from 'react';
import { Modal } from 'ui-library';
import history from 'services/history';
import { useLayout } from 'prodivers/layout';
import { useTranslation } from 'react-i18next';
import { useClientProfile } from 'prodivers/clientProfile';
import { genarateDFSClientId } from 'utils';
import LogoWithClientName from 'components/LogoWithClientName';
import { usePaymentsData } from './usePaymentsData';

export const usePaymentsLayout = () => {
    const { initHeaderState } = useLayout();
    const { t } = useTranslation();
    const { clearPayments } = usePaymentsData();
    // Client Provider
    const { clientName, clientId } = useClientProfile();
    const dfsClientId = genarateDFSClientId(clientId);

    const paymentsClose = useCallback(() => {
        Modal.confirm({
            title: t('payments.cancelPaymentTitle'),
            content: t('payments.cancelPaymentContent'),
            okText: t('payments.cancelPayment'),
            onOk: () => { clearPayments(); history.push(`/client/${dfsClientId}/dashboard`); },
            okType: 'danger',
            cancelText: t('payments.back'),
            className: 'close-client-modal',
        });
    }, [clearPayments, dfsClientId, t]);

    useEffect(() => {
        initHeaderState({
            link: null,
            clientName,
            dfsClientId,
            content: LogoWithClientName,
            contentProps: {
                clientName,
                dfsClientId,
                onClientNameClick: () => paymentsClose({ url: `/client/${dfsClientId}` }),
            },
            showClientName: true,
            leftButton: { type: 'close', label: t('clientDashboard.riskProfile.closeProcess') },
            onLeftButtonClick: paymentsClose,
            rightButton: '',
            onRightButtonClick: null,
        });
    }, [initHeaderState, clientName, dfsClientId, paymentsClose, t]);

    useEffect(() => history.listen((location) => {
        if (!location.pathname.includes('payments')) {
            clearPayments();
        }
    }), [clearPayments]);
};
