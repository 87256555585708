import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Accordion, AccordionPanel as Panel, Column, Modal, Row, Table, Infobox,
} from 'ui-library';
import history from 'services/history';
import { formatDate, yearFormat } from 'utils/datetime';
import Preloader from 'components/Preloader';
import { OverviewItem, OverviewPanel } from 'components/Overview';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject,
    DEPOSITS,
} from 'constants/portfolioProducts';
import { useProductFee } from 'hooks/useProductFee';
import { useSummaryDocuments } from 'hooks/useSummaryDocuments';
import { TYPE_PERCENT } from 'utils/formatting';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useGoalSummary } from '../../../../hooks/useGoalSummary';
import { getTradesColumns, getDocumentsColumns } from '../../../../constants';
import { useReviewData } from '../../../../hooks/useReviewData';
import { useModifiedSecurities } from '../../../../hooks/useModifiedSecurities';
import './Summary.css';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';

function Summary(props) {
    const {
        onPageChange, contactId, dfsClientId, location: { pathname },
    } = props;
    const { t } = useTranslation();
    const {
        product,
        getGoalDetails,
        getGoalChanges,
        getCreatedProposalId,
        saveReviewData,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const goalChanges = useMemo(() => (Number.isInteger(+getGoalChanges())
        ? getGoalChanges() : undefined), [getGoalChanges]);
    const positionLink = `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`;
    const { data, isLoading, error } = useReviewData(contactId, { nameLength: 35, positionLink });

    const {
        positions: modifiedPositions,
    } = useModifiedSecurities({ preview: true, positionLink });

    const projectionYears = useMemo(
        () => formatDate(goalDetails.targetDate, yearFormat) - formatDate(new Date(), yearFormat),
        [goalDetails.targetDate, formatDate],
    );
    const initData = useMemo(() => ({
        data,
        isLoading,
        error,
    }), [data, isLoading, error]);

    // Data by product
    const showStrategy = useMemo(() => generateObject({
        [ADVISORY]: true,
        [DISCRETIONARY]: true,
        [EXECUTION_ONLY]: false,
        [DEPOSITS]: true,
    }).getByName(product?.name), [product?.name]);
    const hasGoal = useMemo(() => goalDetails.selectedGoal !== 'no-goal' && generateObject({
        [ADVISORY]: true,
        [DISCRETIONARY]: true,
        [EXECUTION_ONLY]: false,
        [DEPOSITS]: true,
    }).getByName(product?.name), [product?.name]);

    const options = useMemo(
        () => ({
            productId: +product.id,
            productName: product.name,
            goalName: goalDetails.goalName,
            targetDate: goalDetails.targetDate,
            targetValue: +goalDetails.targetValue,
            selectedGoal: +goalDetails.selectedGoal,
            currency: goalDetails.selectedCurrency,
            portfolioValue: +goalDetails.initialInvestment,
            projectionYears,
            positionLink,
            nameLength: 35,
            proposalId: getCreatedProposalId,
        }),
        [
            product.id,
            product.name,
            goalDetails.goalName,
            goalDetails.targetDate,
            goalDetails.targetValue,
            goalDetails.selectedGoal,
            goalDetails.selectedCurrency,
            goalDetails.initialInvestment,
            projectionYears,
            positionLink,
            getCreatedProposalId,
        ],
    );

    const {
        summaryData, errorSummary, isLoadingSummary,
    } = useGoalSummary(contactId, goalChanges, initData, options);
    const {
        fee, isLoadingFee, errorFee,
    } = useProductFee(product.id, goalDetails.initialInvestment);
    const { documents, errorDocuments, isLoadingDocuments } = useSummaryDocuments(+product.id);

    const onAcceptSummary = () => {
        Modal.confirm({
            title: t('onBoarding.accept'),
            content: t('onBoarding.acceptInvestmentText'),
            okText: t('onBoarding.accept'),
            onOk: () => {
                saveReviewData({
                    modelPortfolioId: summaryData?.modelPortfolioId,
                    benchmarkId: summaryData?.benchmarkId,
                });
                onPageChange('success');
            },
            cancelText: t('onBoarding.cancel'),
        });
    };
    const prevAction = useMemo(() => generateObject({
        [ADVISORY]: () => onPageChange(Number.isInteger(goalChanges) ? 'change-strategy' : ''),
        [DISCRETIONARY]: () => onPageChange(Number.isInteger(goalChanges) ? 'change-strategy' : ''),
        [EXECUTION_ONLY]: () => { history.push(`/onboarding/${dfsClientId}/step/4/modify`); },
        [DEPOSITS]: () => onPageChange(Number.isInteger(goalChanges) ? 'change-strategy' : ''),
    }).getByName(product?.name), [goalChanges, onPageChange, product?.name]);
    const onPrevPage = () => {
        prevAction();
    };

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.reviewYourInvestment')}
            className="goal-review summary"
            classNameContent="stepper-content-full confirmation-page"
            isLoading={isLoadingSummary || isLoadingFee || isLoading}
            error={errorSummary || errorFee}
            data={data}
            prevButton={{
                onClick: onPrevPage,
            }}
            nextButton={{
                text: t('onBoarding.accept'),
                disabled: isLoadingSummary || isLoading,
                onClick: onAcceptSummary,
            }}
        >
            <div>
                <OverviewPanel>
                    <Row>
                        <Column size={['sm-6', 'lg-3']}>
                            <OverviewItem
                                title={hasGoal ? t('onBoarding.goal.yourGoal') : t('onBoarding.portfolioName')}
                                value={goalDetails.goalName}
                            />
                        </Column>
                        <Column size={['sm-6', 'lg-3']}>
                            <OverviewItem
                                title={t('onBoarding.product')}
                                value={product.name}
                            />
                        </Column>
                        <Column size={['sm-6', 'lg-3']}>
                            <OverviewItem
                                title={t('onBoarding.fees')}
                                value={fee}
                                type={TYPE_PERCENT}
                            />
                        </Column>
                        {showStrategy && (
                            <Column size={['sm-6', 'lg-3']}>
                                <OverviewItem
                                    title={t('overview.strategy')}
                                    value={summaryData.overview.strategy}
                                />
                            </Column>
                        )}
                    </Row>
                </OverviewPanel>
            </div>
            <div className="content-wrapper">
                <Accordion defaultActiveKey={['1', '2']}>
                    <Panel header={t('onBoarding.listOfTrades')} key="1" className="table-without-last-row-padding" dataTestId="list-of-trades">
                        <Infobox accent>{t('onBoarding.review.tradesDisclaimer')}</Infobox>
                        <Table
                            className="positions-table"
                            data={modifiedPositions?.length > 0
                                ? modifiedPositions : summaryData.positions}
                            columns={getTradesColumns(t)}
                            expandedColumn="name"
                            defaultExpandAllRows
                        />
                    </Panel>
                    <Panel header={t('onBoarding.documents')} key="2" className="documents table-without-last-row-padding" dataTestId="documents">
                        <Preloader
                            isLoading={isLoadingDocuments}
                            error={errorDocuments}
                        >
                            <Table
                                columns={getDocumentsColumns(t)}
                                data={documents}
                            />
                        </Preloader>
                    </Panel>
                </Accordion>
            </div>
        </OnBoardingBaseTemplate>
    );
}

Summary.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
    dfsClientId: PropTypes.string.isRequired,
    onPageChange: PropTypes.func,
};

Summary.defaultProps = {
    onPageChange: () => {
    },
};

export default Summary;
