const config = {
    authority: global.AppConfig.IS_URL,
    client_id: global.AppConfig.IS_CLIENT_ID,
    response_type: global.AppConfig.IS_RESPONSE_TYPE,
    scope: global.AppConfig.IS_SCOPE,
    acr_values: global.AppConfig.IS_ACR_VALUES,
    redirect_uri: `${global.location.origin}/auth/callback`,
    post_logout_redirect_uri: `${global.location.origin}/auth/postlogout`,
    silent_redirect_uri: `${global.location.origin}/auth/silent`,
    response_mode: 'fragment',
    accessTokenExpiringNotificationTime: global.SetupConfig.SESSION_TIMEOUT_WARNING_DIALOG,
};

export default config;
