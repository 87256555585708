import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button, ContentBox, ProgressBar, Step, Title,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useProducts } from 'hooks/useProducts';
import Review from '../Review';
import ProductSelection from '../ProductSelection';
import InvestmentHorizon from '../InvestmentHorizon';
import RiskReturnExpectation from '../RiskReturnExpectation';
import { useQuestionnaires } from '../../hooks/useQuestionnaires';
import { usePostQuestionnaires } from '../../hooks/usePostQuestionnaires';
import './RiskProfile.css';

const getStepComponent = (index) => {
    switch (index) {
        case 0: return InvestmentHorizon;
        case 1: return RiskReturnExpectation;
        case 2: return Review;
        case 3: return ProductSelection;
        default: return null;
    }
};

const setProgressBarSteps = (steps) => [
    ...(steps.questions && steps.questions.Groups.map((item) => (
        <Step key={item.Name} title={item.Name} />
    ))),
    ...(steps.results && steps.results.map((item) => (
        <Step key={item.Name} title={item.Name} />
    ))),
];

const getQuestion = (current, arr) => (current < 2
    ? arr.questions.Groups[current].Steps[0] : arr.results[current - 2]);

const RiskProfile = ({
    answers,
    currentStep,
    changeAnswers,
    changeCurrentStep,
    onSubmit,
    setAcceptedRisk,
    setChosenProduct,
}) => {
    const [submitOnStep, setSubmitOnStep] = useState(false);
    const { t } = useTranslation();
    const { data: steps, isLoading, error } = useQuestionnaires();
    const { risks, isLoading: isLoadingStep3 } = usePostQuestionnaires(
        currentStep, steps.questions, answers, changeAnswers,
    );
    const { products, isLoadingProducts, errorProducts } = useProducts(currentStep);

    const setNext = (isNextDisabled = currentStep !== 2) => {
        if (isNextDisabled) {
            setSubmitOnStep(true);
        } else {
            if (currentStep === 2) setAcceptedRisk(risks[2].score);

            if (currentStep === 3) onSubmit();
            else changeCurrentStep(currentStep + 1);
            setSubmitOnStep(false);
        }
    };
    const setPrev = () => changeCurrentStep(currentStep - 1);
    const saveData = (data, isValid) => {
        setSubmitOnStep(false);

        if (isValid) {
            changeAnswers((answersOld) => ([
                ...answersOld.slice(0, currentStep),
                data,
                ...answersOld.slice(currentStep + 1),
            ]));
            setNext(!isValid);
        }
    };
    const step = !isLoading && getQuestion(currentStep, steps);
    const StepComponent = getStepComponent(currentStep);

    return (
        <div className="portfolio-risk-profile">
            <Preloader isLoading={isLoading} error={error}>
                <EmptyContent data={steps} text={t('advisoryDashboard.investmentAllocation.notFount')}>
                    <ProgressBar current={currentStep}>
                        {steps.questions && setProgressBarSteps(steps)}
                    </ProgressBar>
                    <ContentBox>
                        <Title type={2}>
                            {step && (step.Questions ? step.Questions[0].Text : step.title)}
                        </Title>
                        <div className="stepper-content">
                            {step && (
                                <StepComponent
                                    data={step.Questions || step}
                                    submit={submitOnStep}
                                    current={currentStep}
                                    answers={answers[currentStep]}
                                    sendData={saveData}
                                    isLoading={isLoadingStep3 || isLoadingProducts}
                                    changeStep={changeCurrentStep}
                                    productsData={products}
                                    errorProducts={errorProducts}
                                    onSelectProduct={setChosenProduct}
                                    risks={risks}
                                />
                            )}
                        </div>
                    </ContentBox>
                    <ContentBox className="stepper-buttons">
                        {currentStep > 0 && (
                            <Button type="secondary" size="small" onClick={setPrev}>
                                Back
                            </Button>
                        )}
                        {currentStep < 3 && (
                            <Button type="primary" size="small" onClick={() => setNext()}>
                                Next
                            </Button>
                        )}
                        {currentStep === 3 && (
                            <Button type="primary" size="small" onClick={() => setNext()}>
                                Finish
                            </Button>
                        )}
                    </ContentBox>
                </EmptyContent>
            </Preloader>
        </div>
    );
};

RiskProfile.propTypes = {
    answers: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ])).isRequired,
    changeAnswers: PropTypes.func.isRequired,
    currentStep: PropTypes.number.isRequired,
    changeCurrentStep: PropTypes.func.isRequired,
    setAcceptedRisk: PropTypes.func.isRequired,
    setChosenProduct: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

RiskProfile.defaultProps = {};

export default RiskProfile;
