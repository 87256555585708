import { roundAllocations } from 'utils';

export const optimizeQuickEdit = (positions, total = 100) => {
    const newPositions = (positions || []).map(({ Id, Allocation }) => ({
        Id, Allocation: (+Allocation) / total,
    }));

    return roundAllocations(newPositions).map(({ id, value }) => ({
        Id: id, Allocation: value,
    }));
};
