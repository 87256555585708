import { checkNullData, roundItems } from 'utils';
import { sortAllocations } from 'utils/sortingAllocation';
import { allocationPercentFormat, formatCurrencyWithPrecision } from 'utils/formatting';
import {
    PIE, PIE_SA, BAR, LIST,
} from 'constants/constants';

const adaptAllocationData = (data, key, listNames, currency) => {
    if (checkNullData(data)) {
        return {
            [PIE_SA]: { data: [] },
            [BAR]: { data: [] },
            [LIST]: { data: [] },
        };
    }

    const getParent = (childId) => Object.keys(listNames)
        .find((parentKey) => listNames[parentKey].children.includes(childId));
    const groupedData = data
        .map((item) => ({ ...item, parentId: getParent(item[key].Id) }))
        .reduce((acc, item) => ({
            ...acc,
            [item.parentId]: {
                id: item.parentId,
                name: listNames[item.parentId]?.name,
                value: (acc[item.parentId]?.Value || 0) + item[key].Value,
            },
        }), {});

    const dataSorted = sortAllocations(key, Object.keys(groupedData).map((parentKey) => ({
        id: parentKey,
        name: groupedData[parentKey].name,
        value: groupedData[parentKey].value,
    })));
    const sum = dataSorted.reduce((acc, item) => acc + item.value, 0);
    const arr = dataSorted.map((item) => item.value / sum);
    const rounded = roundItems(arr, 100);

    return {
        [PIE_SA]: {
            data: dataSorted.map((item, i) => ({
                name: listNames[item.id]?.name,
                color: listNames[item.id]?.color,
                value: item.value,
                percent: rounded[i],
            })),
        },
        [BAR]: {
            data: [{
                data: dataSorted.map((item) => ({
                    y: item.value, color: listNames[item.id]?.color,
                })),
            }],
            labels: dataSorted.map((item) => listNames[item.id]?.name),
        },
        [LIST]: {
            data: dataSorted.map((item, i) => ({
                name: listNames[item.id]?.name,
                value: formatCurrencyWithPrecision(item.value, 0, currency),
                percent: allocationPercentFormat(rounded[i]),
            })),
        },
    };
};

const adaptAllocationDataMini = (data, key, listNames, currency) => {
    if (checkNullData(data)) {
        return {
            [PIE]: { data: [] },
            [BAR]: { data: [] },
            [LIST]: { data: [] },
        };
    }

    const getParent = (childId) => Object.keys(listNames)
        .find((parentKey) => listNames[parentKey].children.includes(childId));
    const groupedData = data
        .map((item) => ({ ...item, parentId: getParent(item[key].Id) }))
        .reduce((acc, item) => ({
            ...acc,
            [item.parentId]: {
                id: item.parentId,
                name: listNames[item.parentId]?.name,
                value: (acc[item.parentId]?.Value || 0) + item[key].Value,
            },
        }), {});

    const dataSorted = sortAllocations(key, Object.keys(groupedData).map((parentKey) => ({
        id: parentKey,
        name: groupedData[parentKey].name,
        value: groupedData[parentKey].value,
    })));
    const sum = dataSorted.reduce((acc, item) => acc + item.value, 0);
    const arr = dataSorted.map((item) => item.value / sum);
    const rounded = roundItems(arr, 100);

    return {
        [PIE]: {
            data: dataSorted.map((item, i) => ({
                name: listNames[item.id]?.name,
                color: listNames[item.id]?.color,
                value: item.value,
                percent: rounded[i],
            })),
            table: dataSorted.map((item, i) => ({
                name: listNames[item.id]?.name,
                allocation: allocationPercentFormat(rounded[i]),
            })),
        },
        [BAR]: {
            data: [{
                data: dataSorted.map((item) => ({
                    y: item.value, color: listNames[item.id]?.color,
                })),
            }],
            labels: dataSorted.map((item) => listNames[item.id]?.name),
        },
        [LIST]: {
            data: dataSorted.map((item, i) => ({
                name: listNames[item.id]?.name,
                value: formatCurrencyWithPrecision(item.value, 0, currency),
                percent: allocationPercentFormat(rounded[i]),
            })),
        },
    };
};

const adaptAllocation = (data, key, listNames, currency, mini) => {
    if (mini) return adaptAllocationDataMini(data, key, listNames, currency);

    return adaptAllocationData(data, key, listNames, currency);
};

export const adaptInvestmentAllocation = (data, listNames, currency, t) => {
    const isNullable = !data || checkNullData(data.AUM);

    return [
        {
            name: 'Asset classes',
            title: t('allocationTabs.assetClasses'),
            data: adaptAllocation(isNullable ? [] : data.AUM.AssetClassAllocation, 'AssetClass', listNames[0], currency),
            dataMini: adaptAllocation(isNullable ? [] : data.AUM.AssetClassAllocation, 'AssetClass', listNames[0], currency, true),
        },
        {
            name: 'Type',
            title: t('allocationTabs.type'),
            data: adaptAllocation(isNullable ? [] : data.AUM.TypeAllocation, 'Type', listNames[1], currency),
            dataMini: adaptAllocation(isNullable ? [] : data.AUM.TypeAllocation, 'Type', listNames[1], currency, true),
        },
        {
            name: 'Currency',
            title: t('allocationTabs.currencies'),
            data: adaptAllocation(isNullable ? [] : data.AUM.CurrencyAllocation, 'Currency', listNames[2], currency),
            dataMini: adaptAllocation(isNullable ? [] : data.AUM.CurrencyAllocation, 'Currency', listNames[2], currency, true),
        },
        {
            name: 'Region',
            title: t('allocationTabs.countries'),
            data: adaptAllocation(isNullable ? [] : data.AUM.CountryAllocation, 'Country', listNames[3], currency),
            dataMini: adaptAllocation(isNullable ? [] : data.AUM.CountryAllocation, 'Country', listNames[3], currency, true),
        },
        {
            name: 'Sector',
            title: t('allocationTabs.sectors'),
            data: adaptAllocation(isNullable ? [] : data.AUM.SectorAllocation, 'Sector', listNames[4], currency),
            dataMini: adaptAllocation(isNullable ? [] : data.AUM.SectorAllocation, 'Sector', listNames[4], currency, true),
        },
    ];
};
