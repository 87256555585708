import React from 'react';

const CellPerformance = (getFormattedNumber) => (value) => {
    if (value === undefined) {
        return null;
    }
    if (value.render) {
        return value.render;
    }


    return (
        <p
            className={+value >= 0 ? 'table-cell_positive' : 'table-cell_negative'}
        >
            {getFormattedNumber(value)}
            {!Number.isNaN(+value) && '%'}
        </p>
    );
};

export default CellPerformance;
