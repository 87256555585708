import {
    formatBigNumber, formatCurrency,
    getfromDataObject,
    NA,
    validateStringData,
} from 'utils/formatting';
import { formatDate } from 'utils/datetime';

export const adaptSecurityTransactions = (data) => ({
    confirmationData: data.OrderBookEntries.map(({
        CreateDate, FxRate, Security, OrderType, AmountSecurityCurrency, Quantity, Price,
    }) => ({
        Name: {
            label: validateStringData(Security.Name),
            maxLength: 40,
        },
        Date: formatDate(CreateDate),
        Id: Security.Id,
        Number: Quantity ? formatBigNumber(Quantity, 2) : NA,
        Price: Price ? formatBigNumber(Price, 2) : NA,
        Type: getfromDataObject(OrderType, 'Name'),
        FxRate: validateStringData(FxRate),
        Total: formatCurrency(AmountSecurityCurrency, Security.Currency.CurrencyCode),
    })),
});
