/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

const Attachments = ({
    id, attachments, downloadFile, t,
}) => {
    if (attachments.length === 0) return null;

    return (
        <p className="preview-content-attachments">
            <b>
                {t('messages.attachments')}
                :
                {' '}
            </b>
            {
                attachments.map(({ fileName, documentId }) => (
                    <>
                        <p className="preview-attachments">
                            <a
                                href="#"
                                onClick={downloadFile(documentId, id)}
                            >
                                {fileName}
                            </a>
                        </p>
                        ;&nbsp;&nbsp;
                    </>
                ))
            }
        </p>
    );
};

Attachments.propTypes = {
    id: PropTypes.number,
    attachments: PropTypes.arrayOf(PropTypes.shape({
        recipientFullName: PropTypes.string,
    })),
    downloadFile: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

Attachments.defaultProps = {
    id: undefined,
    attachments: [],
};

export default Attachments;
