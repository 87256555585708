import React from 'react';
import { PageVariant } from 'ui-library';
import history from 'services/history';

const PageNotFoundContent = () => (
    <div className="page-not-found">
        <PageVariant
            title="404"
            type="error"
            message="Page not found."
            actionButtonLabel="Back to Dashboard"
            onActionButtonClick={() => { history.push('/'); }}
        />
    </div>
);

export default PageNotFoundContent;
