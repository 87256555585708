import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    ContentBox, SkeletonLine, Table, Title,
} from 'ui-library';
import { documentsSorting } from 'constants/constants';
import { useFormatting } from 'locale';
import { useDashboardConfig } from 'pages/AdvisoryDashboard/hooks/useDashboardConfig';
import PageSection from '../PageSection/PageSection';
import Preloader from '../Preloader';
import EmptyContent from '../EmptyContent';
import WidgetResize from '../WidgetResize';

const DocumentsSkeleton = () => (
    <div className="DocumentsSkeleton">
        <div>
            <SkeletonLine width={120} />
        </div>
        <div>
            <SkeletonLine width={80} />
        </div>
    </div>
);

function Documents({
    data, isLoading, error, documentsColumns, link, defaultSize,
}) {
    const { t } = useTranslation();
    const { getFormattedDate } = useFormatting();
    const [isLimited, setLimit] = useState(false);
    const { widgetSize, saveWidgetSize } = useDashboardConfig('Documents', defaultSize);
    const documentsData = useMemo(() => data.slice(0, widgetSize), [data, widgetSize]);

    return (
        <PageSection>
            <ContentBox className={`documents table-without-last-row-padding resize-widget ${isLimited ? 'limited-size' : ''}`}>
                <Title type={2} link={link && `${link}/documents`}>
                    {t('advisoryDashboard.documents.title')}
                </Title>
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data} text={t('advisoryDashboard.documents.noDocuments')}>
                        <WidgetResize
                            onLimited={setLimit}
                            widgetSize={widgetSize}
                            setWidgetSize={saveWidgetSize}
                            elementHeight={54}
                            minHeight={41}
                            dataLength={data.length}
                            SkeletonComponent={DocumentsSkeleton}
                        >
                            <Table
                                data={documentsData}
                                columns={documentsColumns(t, getFormattedDate)}
                                sorting={documentsSorting}
                                mini
                                headerControls={{
                                    sortByLabel: t('headerControls.sortBy'),
                                }}
                            />
                        </WidgetResize>
                    </EmptyContent>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

Documents.propTypes = {
    documentsColumns: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.shape),
    ]).isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            Subject: PropTypes.shape({
                title: PropTypes.string,
                subtitle: PropTypes.string,
            }),
            Date: PropTypes.string,
            Icon: PropTypes.shape({
                type: PropTypes.string,
                size: PropTypes.number,
                onClickIcon: PropTypes.func,
            }),
        }),
    ),
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    defaultSize: PropTypes.number,
};

Documents.defaultProps = {
    data: null,
    isLoading: true,
    error: null,
    link: false,
    defaultSize: undefined,
};

export default Documents;
