import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InteractionsProvider } from 'domain/Interactions';
import { useClientProfile } from 'prodivers/clientProfile';
import { useSelector } from 'react-redux';
import { memberIdSelector } from 'redux/auth/authSelectors';
import Interactions from './Interactions';
import NewInteraction from './pages/NewInteraction/NewInteraction';
import { useClientLayout } from '../../hooks/useClientLayout';

const Router = (props) => {
    useClientLayout();
    const { match: { path } } = props;
    const withProps = (Component) => (defProps) => <Component {...props} {...defProps} />;
    const { clientId } = useClientProfile();
    const memberId = useSelector(memberIdSelector);

    return (
        <InteractionsProvider
            options={{
                clientId,
                memberId,
            }}
        >
            <Switch>
                <Route exact path={`${path}`} render={withProps(Interactions)} />
                <Route exact path={`${path}/new`} render={withProps(NewInteraction)} />
                <Redirect from="*" to="/404" />
            </Switch>
        </InteractionsProvider>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.displayName = 'Router';

export default Router;
