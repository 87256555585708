import { useCallback, useEffect, useState } from 'react';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { useAllContacts } from 'hooks/useAllContacts';
import { adaptClientList } from '../adapters/adaptClientList';


export const useClientList = () => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [dataChecked, setDataChecked] = useState([]);
    const {
        contactsRawData,
        isLoadingContacts,
        contactsError,
    } = useAllContacts({ withExternalId: true });


    useEffect(() => {
        setDataChecked([]);
    }, []);

    const onClientListChecked = ({ id, name }) => ({ target: { checked } }) => {
        if (checked) setDataChecked((oldArray) => [...oldArray, { id, name }]);
        else {
            setDataChecked((oldArray) => {
                const index = oldArray.findIndex((item) => item.id === id);

                return [
                    ...oldArray.slice(0, index),
                    ...oldArray.slice(index + 1),
                ];
            });
        }
    };

    useEffect(() => {
        setLoading(true);

        if (contactsError) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            );
        }

        if (!isLoadingContacts && contactsRawData) {
            try {
                const adaptedData = adaptClientList(contactsRawData.contacts.items)
                    .map((item) => ({
                        ...item,
                        internalId: {
                            ...item.internalId,
                            onCheck: onClientListChecked(item),
                        },
                    }));

                setData(adaptedData);
                setFiltered(adaptedData);
                setLoading(false);
            } catch (err) {
                throw new AdapterError(err);
            }
        }
    }, [contactsRawData, isLoadingContacts, contactsError]);

    const filterClientList = useCallback(
        (value) => {
            const searchValue = value.toLowerCase();

            setFiltered(data.filter(({ name }) => name.toLowerCase().includes(searchValue)));
        },
        [data],
    );

    const onChangeChekedList = useCallback((list) => {
        setDataChecked(list);
    }, [setDataChecked]);

    return {
        filtered,
        dataChecked,
        isLoading,
        error,
        filterClientList,
        onChangeChekedList,
    };
};
