import {
    memberIdSelector,
} from 'redux/auth/authSelectors';
import React, { useState, useEffect } from 'react';
import history from 'services/history';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';

// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty, isUndefined, get } from 'lodash/fp';
import {
    BreadCrumbs,
    Column,
    ContentBox,
    Row,
    Select,
    TextInput,
    Textarea,
    Title,
} from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import { validate } from 'components/SendMessage/validation';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import ServiceManager from 'services/ServiceManager';
import MessageService from 'additiv-services/dist/services/core/messages';
import { checkFilesWithoutContent } from './adaptor';

import { withMainLayout } from '../../../hocs';
import { formatOriginalMessage } from './utils/utils';

const initState = {
    message: undefined,
    subject: undefined,
    filesUploaded: [],
    contactReason: '1',
    isSending: false,
    errors: {},
    generalErrorMessage: null,
    messageSubTypeMap: [],
};

function ReplyMessage(props) {
    useCancelAllRequests();

    // eslint-disable-next-line react/prop-types
    const { t, i18n } = useTranslation();
    const { getFormattedDate } = useFormatting();
    const [messageSubTypeMap, setMessageSubTypeMap] = useState([]);
    const [contactReason, setContactReason] = useState('1');
    // eslint-disable-next-line no-unused-vars
    const [generalErrorMessage, setGeneralErrorMessage] = useState(null);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(undefined);
    const [originalMessage, setOriginalMessage] = useState('');
    const [subject, setSubject] = useState(get('location.state.title', props));
    const [isSending, setSending] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [filesUploaded, setFilesUploaded] = useState([]);
    const memberId = useSelector(memberIdSelector);

    useEffect(() => {
        ServiceManager.messageService('getMessageTypes', [i18n.language]).then((response) => {
            setMessageSubTypeMap(response.data[0].Subtypes);
            let subtypeId = get('location.state.subtypeId', props);

            subtypeId = subtypeId === undefined ? response.data[0].Subtypes[0].Id : subtypeId;
            initState.contactReason = get('data[0].Subtypes[0].Id', response);
            setContactReason(subtypeId);
        }).catch(console.log);
    }, [i18n.language]);

    useEffect(() => {
        ServiceManager.messageService('getMemberThreads', [
            memberId,
            {
                ThreadId: get('location.state.threadId', props),
                TextLength: 0,
                PageNumber: 1,
                PageSize: 100000,
            },
        ]).then((response) => {
            setOriginalMessage(
                formatOriginalMessage(response.data.Data[0].Messages, getFormattedDate),
            );
        }).catch(console.log);
    }, []);

    const postMessage = (dtoAttachments) => {
        const isfileWithoutContent = checkFilesWithoutContent(dtoAttachments);

        if (!isfileWithoutContent) {
            ServiceManager.messageService('replyMessage', [{
                personId: memberId,
                dtoAttachments,
                message,
                subject,
                messageId: get('match.params.messageId', props),
            }]).then((response) => {
                if (response.data.Result === MessageService.MESSAGE_NOT_ALLOWED) {
                    setSending(false);
                    setGeneralErrorMessage(t('messages.maliciousMessage'));
                } else if (response.data.Result === MessageService.SUCCESS) {
                    setMessage(initState.message);
                    setSubject(initState.subject);
                    setContactReason(messageSubTypeMap[0].Id);
                    setSending(initState.isSending);
                    setErrors(initState.errors);
                    setGeneralErrorMessage(initState.generalErrorMessage);
                    history.push('/messages');
                } else {
                    setSending(false);
                    setGeneralErrorMessage(t('messages.errorPostMessage'));
                }
            }).catch(() => {
                setSending(false);
                setGeneralErrorMessage(t('messages.errorPostMessage'));
            });
        } else {
            setSending(false);
            setGeneralErrorMessage(t('messages.fileWithoutContentMessage'));
        }
    };

    const submitMessageHandler = (e) => {
        if (isSending) {
            return;
        }
        e.preventDefault();

        const initAllValues = {
            message,
            subject,
            contactReason,
        };

        Object.keys(initAllValues).forEach((key) => {
            initAllValues[key] = isUndefined(initAllValues[key]) ? null : initAllValues[key];
        });

        if (!isEmpty(validate(initAllValues))) {
            setErrors(validate(initAllValues));

            return;
        }

        setMessage(initState.message);
        setSubject(initState.subject);
        setContactReason(initState.contactReason);
        setSending(initState.isSending);
        setErrors(initState.errors);
        setGeneralErrorMessage(initState.generalErrorMessage);

        // eslint-disable-next-line react/prop-types
        const { metadataReferenceId, contactId } = props;

        setSending(true);
        const dtoAttachments = [];

        if (filesUploaded) {
            filesUploaded.forEach((item) => {
                const dto = {
                    OriginalFilename: item.name,
                    RetrieveFileAsName: item.name,
                    File: item.file,
                    CustomAttributes: item.customAttributes,
                    MetadataReferenceId: metadataReferenceId,
                    ExternalId: 'A123',
                    ContractId: 1334,
                    OwnerId: contactId,
                };

                dtoAttachments.push(dto);
            });
        }
        postMessage(dtoAttachments);
    };

    const handleMessageInputChange = (event) => {
        const { value } = event.currentTarget;

        setMessage(value);
        setGeneralErrorMessage(null);
        setErrors(validate({
            ...errors,
            message: value,
        }));
    };

    const onCancel = () => {
        history.push('/messages');
    };

    return (
        <PageSection className="reply-message">
            <ContentBox>
                <BreadCrumbs current={t('messages.reply')}>
                    <Link to="/messages">{t('messages.title')}</Link>
                </BreadCrumbs>
                <Title type={1} className="mb-0">
                    {t('messages.reply')}
                </Title>
            </ContentBox>
            <ContentBox>
                <Preloader isLoading={false} error={null}>
                    <Row>
                        <Column size={['sm-6']}>
                            <Select
                                disabled
                                value={contactReason}
                                options={
                                    messageSubTypeMap.map(({ Id, Name }) => ({
                                        label: Name,
                                        value: Id,
                                    }))
                                }
                                label={t('messages.category')}
                                withInfobox={false}
                                required
                            />
                            <br />
                            <TextInput
                                defaultValue={subject}
                                label={t('messages.subject')}
                                disabled
                                required
                            />
                            <br />
                            <Textarea
                                label={t('messages.message')}
                                defaultValue={message}
                                error={errors && t(errors.message)}
                                onChange={handleMessageInputChange}
                                required
                            />
                            <br />
                            <Textarea
                                label={t('messages.originalMessage')}
                                value={originalMessage}
                                readonly
                                disabled
                            />
                        </Column>
                    </Row>
                    <ButtonsBlockRow
                        additionalButton={{
                            text: t('messages.cancel'),
                            onClick: onCancel,
                        }}
                        primaryButton={{
                            text: t('messages.send'),
                            onClick: submitMessageHandler,
                        }}
                    />
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

ReplyMessage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types

    // data: PropTypes.any.isRequired,
};

ReplyMessage.defaultProps = {};

export default withMainLayout(ReplyMessage);
