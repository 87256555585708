import React from 'react';
import PropTypes from 'prop-types';
import {
    TabbedNavigation, TabbedNavigationLink as Link,
} from 'ui-library';
import { useTranslation } from 'react-i18next';


function TopNavigation({ clientId }) {
    const { t } = useTranslation();

    return (
        <TabbedNavigation>
            <Link exact to={`/client/${clientId}/dashboard`}>{t('navigation.topNav.overView')}</Link>
            <Link to={`/client/${clientId}/portfolios`}>{t('navigation.topNav.portfolios')}</Link>
            <Link to={`/client/${clientId}/details`}>{t('navigation.topNav.details')}</Link>
            <Link to={`/client/${clientId}/messages`}>{t('navigation.topNav.messages')}</Link>
            <Link to={`/client/${clientId}/documents`}>{t('navigation.topNav.documents')}</Link>
            <Link to={`/client/${clientId}/interactions`}>{t('navigation.topNav.interactions')}</Link>
            <Link to={`/client/${clientId}/payments`}>{t('navigation.topNav.payments')}</Link>
        </TabbedNavigation>
    );
}

TopNavigation.propTypes = {
    clientId: PropTypes.string.isRequired,
};

TopNavigation.defaultProps = {};

export default TopNavigation;
