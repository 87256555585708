import {
    useEffect, useMemo, useState, useCallback,
} from 'react';
import SM from 'services/ServiceManager';
import { useTranslation } from 'react-i18next';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useProposals } from 'hooks/useProposals';
import { useFormatting } from 'locale';
import { downloadDmsDocument } from 'utils/downloadDmsDocument';
import { adaptDocuments, getNames } from '../adapters/adaptDocuments';
import { usePortfolios } from './usePortfolios';

export const useDocuments = ({
    clientId, currentPagination, tab, fileUploaded,
}) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [isGeneralLoading, setGeneralLoading] = useState(true);
    const [data, setData] = useState([]);
    const [generalData, setGeneralData] = useState([]);
    const [names, setNames] = useState([]);
    const [generalNames, setGeneralNames] = useState([]);
    const { t, i18n } = useTranslation();
    const { getFormattedDate } = useFormatting();
    const { data: proposals } = useProposals(clientId);
    const { getPortfolios } = usePortfolios({ clientId, proposals });
    const [pageSize, setPageSize] = useState(10);
    const [personalPaging, setPersonalPaging] = useState({});
    const [generalPaging, setGeneralPaging] = useState({});

    const commonDocParams = useMemo(() => ({
        documentTypeId: 1,
        isActive: true,
        searchBy: 'OwnerOnly',
        sortBy: 'CreateDate',
        sortOrder: 'Descending',
        page: currentPagination,
        pageSize,
    }), [currentPagination, pageSize]);

    const personalParams = useMemo(() => ({
        personIds: [clientId],
        ...commonDocParams,
    }), [clientId, fileUploaded, commonDocParams]);

    const generalParams = useMemo(() => ({
        ...commonDocParams,
        searchBy: 'IncludeShared',
        documentTypeId: 2,
    }), [fileUploaded, commonDocParams]);

    const downloadDocument = useCallback((documentId) => {
        setError(null);
        SM.documents('getDocumentById', [documentId]).then((response) => {
            try {
                if (response?.data) {
                    const { retrieveFileAsName } = response.data;

                    const { newBlob } = downloadDmsDocument(
                        response.data,
                        {
                            name: retrieveFileAsName,
                            saveFile: true,
                        },
                    );
                    const url = URL.createObjectURL(newBlob);

                    global.open(url, '_blank');
                }
            } catch (err) {
                throw new AdapterError(err);
            }
        }).catch(handlerRequestCanceling(
            HandlerError({ setError, setLoading }),
        ));
    }, []);

    const getDocuments = useCallback((docsParams) => new Promise((resolve, reject) => {
        SM.documents('postDocumentsList', [docsParams])
            .then((response) => resolve(response.data))
            .catch(reject);
    }), []);

    const handleDocumentsResponse = useCallback(async ({
        docResults,
        setDocs,
        setNamesDocs,
        setLoadingDocs,
        setPaging,
    }) => {
        const {
            page, pageSize: size, pageCount, totalCount,
        } = docResults;

        try {
            const userPortfolios = await getPortfolios({
                documents: docResults,
            });

            const adaptedData = adaptDocuments(
                docResults,
                downloadDocument,
                t,
                i18n,
                getFormattedDate,
                userPortfolios,
                tab,
            );

            setDocs(adaptedData);
            setNamesDocs(getNames(adaptedData));
            setLoadingDocs(false);
            setPaging({
                Page: page, PageSize: size, PageCount: pageCount, TotalCount: totalCount,
            });
            setPageSize(size);
        } catch (err) {
            throw new AdapterError(err);
        }
    }, [downloadDocument, t, i18n, getFormattedDate, tab]);

    useEffect(() => {
        // Personal Docs
        if (tab === 'personal') {
            setLoading(true);
            getDocuments(personalParams)
                .then((resp) => {
                    handleDocumentsResponse({
                        docResults: resp,
                        setDocs: setData,
                        setNamesDocs: setNames,
                        setLoadingDocs: setLoading,
                        setPaging: setPersonalPaging,
                    });
                })
                .catch(handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                ));
        }

        // General Docs
        if (tab === 'general') {
            setGeneralLoading(true);
            getDocuments(generalParams)
                .then((resp) => {
                    handleDocumentsResponse({
                        docResults: resp,
                        setDocs: setGeneralData,
                        setNamesDocs: setGeneralNames,
                        setLoadingDocs: setGeneralLoading,
                        setPaging: setGeneralPaging,
                    });
                })
                .catch(handlerRequestCanceling(
                    HandlerError({ setError, setGeneralLoading }),
                ));
        }
    }, [personalParams, generalParams, tab]);

    return {
        data,
        generalData,
        names: names.concat(generalNames),
        isLoading,
        isGeneralLoading,
        pageSize,
        personalPaging,
        generalPaging,
        error,
    };
};
