import history from 'services/history';
import { useNews } from 'hooks/useNews';
import { useTasks } from 'hooks/useTasks';
import { useTickets } from 'hooks/useTickets';
import { useOverviewClients } from 'hooks/useOverviewClients';
import { useTypesAndTopicsInteraction } from 'hooks/useTypesAndTopicsInteraction';
import { useListCurrencies } from 'hooks/useListCurrencies';
import { useConfiguration } from './useConfiguration';
import { useInvestmentAllocation } from './useInvestmentAllocation';
import { useMessages } from './useMessages';
import { useCalendar } from './useCalendar';
import { useNextUp } from './useNextUp';
import { useClientList } from './useClientList';
import { useOverview } from './useOverview';
import { useMarkets } from './useMarkets';
import { useCurrencies } from './useCurrencies';

export const useInitWidgets = () => {
    const configuration = useConfiguration();
    const overview = useOverview(configuration);
    const contacts = useOverviewClients();
    const allocation = useInvestmentAllocation(configuration);
    const messages = useMessages();
    const news = useNews();
    const tickets = useTickets();
    const tasks = useTasks(tickets, contacts, 5);
    const typesAndTopics = useTypesAndTopicsInteraction(true);
    const calendar = useCalendar(contacts, typesAndTopics);
    const nextUp = useNextUp(contacts, typesAndTopics);
    const clients = useClientList();
    const listCurrencies = useListCurrencies();
    const markets = useMarkets(configuration, listCurrencies.listCurrency);
    const currencies = useCurrencies();
    const onRowClick = ({ id }) => ({
        onClick: () => {
            history.push(`/messages/${id}`);
        },
    });

    return {
        overview,
        tasks,
        news,
        configuration,
        calendar,
        nextUp,
        clients,
        listCurrencies,
        markets,
        currencies,
        messages,
        allocation,
        onRowClick,
    };
};
