import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Title, Column, Paragraph,
} from 'ui-library';
import TextTruncate from 'react-text-truncate';
import { getAdaptedDate } from 'utils/formatting';
import { useFormatting } from 'locale';
import { LinkNews } from './LinkNews';


const NewsItem = (props) => {
    const {
        id, image, title, date, content, link, mini,
    } = props;
    const { t } = useTranslation();
    const { getFormattedDate, getFormattedTime } = useFormatting();

    const renderDate = useCallback((itemDate) => {
        const adapted = getAdaptedDate(itemDate, t);

        if (!Date.parse(adapted)) return getFormattedTime(itemDate);

        return getFormattedDate(adapted);
    }, [
        getAdaptedDate,
        getFormattedTime,
        getFormattedDate,
        t,
    ]);

    return (
        <div className="news-item row">
            <Column size={['md-3', 'sm-12', !mini && 'lg-2']} className="news-item--image__container">
                <LinkNews link={link} id={id} {...props}>
                    <img className="news-item--image" src={image} alt="news-img" />
                </LinkNews>
            </Column>
            <Column size={['md-9', 'sm-12', !mini && 'lg-10']} className="news-item--content__container">
                <div className="news-item--title">
                    <Title type={4}>{title}</Title>
                    <Paragraph className="news-item--date">
                        {renderDate(date)}
                    </Paragraph>
                </div>
                <div className="news-item--content paragraph_secondary">
                    <TextTruncate
                        line={2}
                        truncateText="..."
                        text={content}
                    />
                </div>
                <Paragraph className="news-item--link" type="secondary">
                    <LinkNews link={link} id={id} {...props}>
                        {!link ? t('advisoryDashboard.news.readMore') : t('advisoryDashboard.news.readMoreExternal')}
                    </LinkNews>
                </Paragraph>
            </Column>
        </div>
    );
};

NewsItem.propTypes = {
    id: PropTypes.number,
    image: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    content: PropTypes.string,
    link: PropTypes.string,
    mini: PropTypes.bool,
};

NewsItem.defaultProps = {
    id: null,
    image: null,
    title: null,
    date: null,
    content: null,
    link: null,
    mini: false,
};

export default NewsItem;
