import React, { useCallback, useEffect } from 'react';
import { useLayout } from 'prodivers/layout';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import { useClientProfile } from 'prodivers/clientProfile';
import { genarateDFSClientId } from 'utils';
import { Modal } from 'ui-library';
import { formDataSelector, useRiskProfileSelector } from 'domain/RiskProfile';
import LogoWithClientName from 'components/LogoWithClientName';

export const useRiskProfileLayout = () => {
    const { initHeaderState } = useLayout();
    const { t } = useTranslation();
    // Client Provider
    const { clientId, clientName } = useClientProfile();
    const dfsClientId = genarateDFSClientId(clientId);
    // Risks Profile Domain
    const {
        clearSelectedRisk, clearData,
    } = useRiskProfileSelector(formDataSelector);

    const riskProfileClose = useCallback(() => {
        Modal.confirm({
            title: t('clientDashboard.riskProfile.closeProcess'),
            content: t('clientDashboard.riskProfile.closeModalText'),
            okText: t('clientDashboard.yes'),
            onOk: () => {
                clearSelectedRisk();
                clearData();
                history.push(`/client/${dfsClientId}/dashboard`);
            },
            cancelText: t('clientDashboard.no'),
            className: 'close-client-modal',
        });
    }, [clearSelectedRisk, clearData, dfsClientId, t]);
    const riskProfileContinueLater = useCallback(() => {
        Modal.confirm({
            title: t('clientDashboard.riskProfile.continueLater'),
            content: t('clientDashboard.riskProfile.continueModalText'),
            okText: t('clientDashboard.yes'),
            onOk: () => history.push(`/client/${dfsClientId}/dashboard`),
            cancelText: t('clientDashboard.no'),
            className: 'close-client-modal',
        });
    }, [dfsClientId, t]);

    useEffect(() => {
        initHeaderState({
            link: null,
            clientName,
            dfsClientId,
            content: LogoWithClientName,
            contentProps: {
                clientName,
                dfsClientId,
                onClientNameClick: riskProfileClose,
            },
            showClientTitle: false,
            showTopNavigation: false,
            leftButton: { type: 'close', label: t('clientDashboard.riskProfile.closeProcess') },
            onLeftButtonClick: riskProfileClose,
            rightButton: { type: 'delay', label: t('clientDashboard.riskProfile.continueLater') },
            onRightButtonClick: riskProfileContinueLater,
            containerClassName: 'risk-profile',
        });
    }, [initHeaderState, clientName, dfsClientId, riskProfileClose, riskProfileContinueLater, t]);
};
