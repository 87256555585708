import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, Step } from 'ui-library';
import { useTranslation } from 'react-i18next';
import './CreateNewPortfolioProgressBar.css';

const CreateNewPortfolioProgressBar = (props) => {
    const { current } = props;
    const { t } = useTranslation();

    return (
        <div className="CreateNewPortfolioProgressBar">
            <ProgressBar current={current}>
                <Step key="selection" title={t('portfolio.createNewPortfolio.selection')} />
                <Step key="changes" title={t('portfolio.createNewPortfolio.changes')} />
                <Step key="review" title={t('portfolio.createNewPortfolio.review')} />
                <Step key="completion" title={t('portfolio.createNewPortfolio.completion')} />
            </ProgressBar>
        </div>
    );
};

CreateNewPortfolioProgressBar.propTypes = {
    current: PropTypes.number.isRequired,
};

CreateNewPortfolioProgressBar.defaultProps = {
};

export default CreateNewPortfolioProgressBar;
