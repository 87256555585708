import {
    formatPercentage, formatWithDate, getfromDataObject, validateNumber,
} from 'utils/formatting';
import { BAR, LIST, PIE } from 'constants/constants';
import { adaptAllocationGroups, buildAllocationGroup } from 'adaptors/adaptAllocationGroups';
import { getAllocations, adaptProjection } from 'adaptors/adaptAllocations';
import { checkNullData } from 'utils';
import { getTimestamp } from 'utils/datetime';

const adaptPerformance = (data, getFormattedNumber) => {
    if (checkNullData(data) || checkNullData(data?.Values)) return {};

    return ({
        data: data?.Values.map(({ Value, Date }) => ([
            getTimestamp(Date),
            Math.round(Value * 10000) / 100,
        ])),
        tooltipFormat: (number, date) => (getFormattedNumber
            ? formatWithDate(formatPercentage(getFormattedNumber(number)), date)
            : formatWithDate(formatPercentage(number), date)),
    });
};

export const adaptModelPortfolio = ({
    data, productID, portfolioValue, t, portfolioSecuritiesValue, comparedAllocations,
    getFormattedCurrency, getFormattedNumber, recurringPayment,
}) => {
    let positions = [];
    let currency;
    let currencyId;

    if (data?.Currency) {
        currency = data?.Currency.CurrencyCode;
        currencyId = data?.Currency.Id;
    } else if (data?.BaseCurrency) {
        currency = data?.BaseCurrency.CurrencyCode;
        currencyId = data?.BaseCurrency.Id;
    }

    if (data && data?.Positions) {
        positions = data?.Positions;
    } else if (data && data?.ConsolidatedPositions) {
        positions = data?.ConsolidatedPositions;
    }
    const securitiesValue = data?.SecuritiesValue || portfolioSecuritiesValue;

    return {
        backTitle: getfromDataObject(data, 'Name'),
        title: getfromDataObject(data, 'Name'),
        currency,
        currencyId,
        modelPortfolioId: data?.ModelPortfolioId || data?.Id,
        benchmarkId: data?.BenchmarkId,
        product: getfromDataObject(data, 'Product.Id'),
        performance: adaptPerformance(
            data?.HistoricalPerformance ? data?.HistoricalPerformance[0] : null, getFormattedNumber,
        ),
        securitiesValue: data?.SecuritiesValue || 0,
        InvestedAmount: data?.InvestedAmount || 0,
        overview: {
            currency,
            portfolioValue,
            strategy: getfromDataObject(data, 'RiskCategory.Name'),
            product: getfromDataObject(data, 'Product.Name'),
            sharpRatio: data?.SharpeRatio,
            expectedReturn: data?.CalculatedExpectedReturn * 100,
            expectedRisk: data?.CalculatedRisk * 100,
            modelPortfolio: data?.Name,
        },
        riskCategory: getfromDataObject(data, 'RiskCategory.Id'),
        productId: productID,
        investmentAllocations: getAllocations(data?.Positions || []),
        projection: adaptProjection({
            data: positions,
            CurrencyId: currencyId,
            ProjectionYears: 5,
            InitialInvestmentAmount: portfolioValue,
            MonthlyContributionAmount: recurringPayment,
        }),
        constraints: data?.OptimizationConstraints || {},
        investmentAllocation: {
            chart: securitiesValue ? adaptAllocationGroups({
                positions,
                currency,
                t,
                getFormattedCurrency,
                securitiesValue,
                chartTypesList: [PIE, BAR, LIST],
                comparedAllocations,
            }) : null,
            currency,
        },
        riskReturn: [+(data?.CalculatedRisk * 100), +(data?.CalculatedExpectedReturn * 100)],
        riskReturnMatrix: {
            x: [validateNumber(data?.RiskCategory?.Min) * 100,
                validateNumber(data?.RiskCategory?.Max) * 100],
        },
        allocationGroups: buildAllocationGroup(
            positions, currency, t, data?.SecuritiesValue || portfolioSecuritiesValue,
        ),
        Positions: positions,
    };
};
