import {
    memberIdSelector,
} from 'redux/auth/authSelectors';
import { useSelector } from 'react-redux';
import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useGetInteractions } from 'hooks/useGetInteractions';
import { getDatesDifference, subtractUnits, addUnits } from 'utils/datetime';
import { adaptEvents } from '../adapters/adaptEvents';

const defParams = {
    DateFrom: subtractUnits(new Date(), 1, 'month'),
    DateTo: addUnits(new Date(), 1, 'month'),
    IsCanceled: false,
    SortColumn: 'DateFrom',
    SortDescending: false,
    PageNumber: 1,
    PageSize: 1000,
};

export const useCalendar = (contacts, typesAndTopics) => {
    const [error, setError] = useState(null);
    const [params, setParams] = useState(defParams);
    const memberId = useSelector(memberIdSelector);

    const onRangeChange = useCallback((data) => {
        const dateStartDiff = getDatesDifference(data?.start || data, params.DateFrom, 'days');
        const dateEndDiff = getDatesDifference(params.DateTo, data?.end || data, 'days');

        if ((dateStartDiff < 0) || (dateEndDiff < 0)) {
            setParams({
                ...defParams,
                DateFrom: subtractUnits(data?.start || data, 1, 'month'),
                DateTo: addUnits(data?.end || data, 1, 'month'),
            });
        }
    }, [params.DateFrom, params.DateTo]);

    const options = useMemo(() => ({
        contacts: contacts.data, memberId,
    }), [contacts.data, memberId]);
    const {
        data, error: errorInteractions, isLoading: isLoadingInteractions, getInteractions,
    } = useGetInteractions(adaptEvents, {
        params,
        memberId,
        typesAndTopics,
        options,
    });

    useEffect(() => {
        if (!contacts.isLoading && !typesAndTopics.isLoading) {
            getInteractions();
        }
    }, [contacts.isLoading, getInteractions, typesAndTopics.isLoading]);

    useEffect(() => {
        setError(errorInteractions);
    }, [errorInteractions]);

    return {
        data,
        isLoading: false,
        isLoadingInteractions,
        error,
        onRangeChange,
    };
};
