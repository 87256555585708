import React from 'react';
import PropTypes from 'prop-types';
import { Label as UILabel } from 'ui-library';

import './FieldControl.css';

const FieldControl = ({
    label, value, editable, renderValue, editComponent,
}) => {
    if (editable) {
        return editComponent({ label, value });
    }

    return (
        <div className="field-control">
            <UILabel label={label} />
            <p>
                {renderValue(value)}
            </p>
        </div>
    );
};

FieldControl.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
    ]),
    editable: PropTypes.bool,
    editComponent: PropTypes.func,
    renderValue: PropTypes.func,
};

FieldControl.defaultProps = {
    label: '',
    value: undefined,
    editable: false,
    editComponent: () => null,
    renderValue: (v) => v,
};

export default FieldControl;
