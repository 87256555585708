import React from 'react';
import { useTranslation } from 'react-i18next';
import DarkLogoSvg from 'assets/images/logo/MobileAdvisor.svg';
import LightLogoSvg from 'assets/images/logo/MobileAdvisor-light.svg';
import MobileAdvisorLogoSvg from 'assets/images/logo/MobileAdvisor_Icon.svg';
import './Logo.css';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileSize';
import useTheme from 'theming/useTheme';

export const Logo = () => {
    const { t } = useTranslation();
    const { theme } = useTheme();

    const { isMobileSize } = useDetectedMobileDevice();

    const themeLogo = theme?.includes('prestige') ? DarkLogoSvg : LightLogoSvg;

    return (
        <div>
            <img src={isMobileSize ? MobileAdvisorLogoSvg : themeLogo} alt={t('login.title')} className="logo-icon" />
        </div>
    );
};
