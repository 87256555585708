import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptModelPortfolio } from '../adapters/adaptModelPortfolio';
import { useFormatting } from '../../../locale';

export const useGoalSummary = (
    contactId,
    goalChanges,
    { data, isLoading, error },
    options,
) => {
    const { i18n, t } = useTranslation();
    const [summaryData, setData] = useState({ overview: {} });
    const [errorSummary, setError] = useState(null);
    const [isLoadingSummary, setLoading] = useState(false);
    const {
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
    } = useFormatting();

    const isGoalChanged = useMemo(
        () => goalChanges && (+goalChanges || Object.keys(goalChanges).length > 0),
        [goalChanges],
    );

    const setInitData = useCallback(() => {
        setData(data);
        setLoading(isLoading);
        setError(error);
    }, [data, isLoading, error]);

    const getNewModel = useCallback(() => {
        const params = { language: i18n.language };

        if (Number.isInteger(+goalChanges)) {
            ServiceManager.portfolioManagement('getModelPortfolio', [goalChanges, params])
                .then((response) => {
                    try {
                        setData(adaptModelPortfolio(response.data, {
                            ...options,
                            getFormattedDate,
                            getFormattedNumber,
                            getFormattedCurrency,
                            t,
                        }));
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch(handlerRequestCanceling(
                    HandlerError({ setLoading, setError }),
                ));
        }
    }, [
        i18n.language,
        goalChanges,
        options,
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
    ]);

    useEffect(() => {
        if (isGoalChanged) {
            setError(null);
            setLoading(true);
            getNewModel();
        }
    }, [getNewModel, isGoalChanged]);

    useEffect(() => {
        if (!isGoalChanged) {
            setError(null);
            setLoading(true);
            setInitData();
        }
    }, [isGoalChanged, setInitData]);

    return {
        summaryData, errorSummary, isLoadingSummary,
    };
};
