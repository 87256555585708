import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from 'ui-library';
import Preloader from 'components/Preloader';
import './ChangeModelOverview.css';
import { OverviewInformation } from '../OverviewInformation';
import { adaptChangeModelOverview } from './adapters/adaptChangeModelOverview';

function ChangeModelOverview({
    data: { portfolioValue, currency, modelPortfolio },
    isLoading,
    error,
    onModelChange,
    modelList,
    selectedModel,
    showModel,
}) {
    const { t } = useTranslation();

    // Renderers
    const renderModelSelect = () => (
        <div className="overview-item">
            <Select
                options={modelList}
                value={selectedModel}
                label={t('overview.newModel')}
                withInfobox={false}
                placeholder={t('overview.selectNewModel')}
                onChange={onModelChange}
            />
        </div>
    );

    const changeModelOverviewRows = adaptChangeModelOverview({
        portfolioValue,
        currency,
        t,
        modelPortfolio,
        showModel,
        onModelChange,
        renderModelSelect,
    });

    return (
        <Preloader isLoading={isLoading} error={error}>
            <OverviewInformation
                rowsData={changeModelOverviewRows}
                columnSize={['sm-4']}
                panelClassName="change-model-overview"
            />
        </Preloader>
    );
}

ChangeModelOverview.propTypes = {
    data: PropTypes.shape({
        currency: PropTypes.string,
        portfolioValue: PropTypes.number,
        modelPortfolio: PropTypes.string,
    }),
    selectedModel: PropTypes.number,
    onModelChange: PropTypes.func,
    modelList: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    showModel: PropTypes.bool,
};

ChangeModelOverview.defaultProps = {
    data: {},
    selectedModel: undefined,
    isLoading: true,
    onModelChange: undefined,
    modelList: [],
    error: null,
    showModel: true,
};

export default ChangeModelOverview;
