import { useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';

const initialState = {
    data: {},
    globalSettings: null,
    error: null,
    isLoading: false,
    riskCategoriesData: [],
    riskCategoriesError: null,
    riskCategoriesIsLoading: false,
    product: {},
    productError: null,
    productIsLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setDataGlobalSettings':
            return { ...state, globalSettings: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setRiskCategoriesData':
            return { ...state, riskCategoriesData: action.payload };
        case 'setRiskCategoriesError':
            return { ...state, riskCategoriesError: action.payload };
        case 'setRiskCategoriesIsLoading':
            return { ...state, riskCategoriesIsLoading: action.payload };
        case 'setProduct':
            return { ...state, product: action.payload };
        case 'setProductError':
            return { ...state, productError: action.payload };
        case 'setProductIsLoading':
            return { ...state, productIsLoading: action.payload };
        default:
            return state;
    }
};

export const useProducts = (options) => {
    const {
        clientId,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    const { i18n: { language } } = useTranslation();

    // Callbacks
    const getProducts = useCallback(async () => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const responseGlobalSettings = await SM.commonService('getGeneralSettings');
            const response = await SM.commonService('getCampaignsCurrentConfiguration', [language]);

            dispatch({ type: 'setData', payload: response?.data });
            dispatch({ type: 'setDataGlobalSettings', payload: responseGlobalSettings?.data });
            dispatch({ type: 'setIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [language]);

    const getRiskCategories = useCallback(async (productId) => {
        dispatch({ type: 'setRiskCategoriesError', payload: null });
        dispatch({ type: 'setRiskCategoriesIsLoading', payload: true });

        try {
            const riskCategoriesResp = await SM.commonService('getRiskCategoriesByProduct', [productId, language]);

            dispatch({ type: 'setRiskCategoriesData', payload: riskCategoriesResp?.data });
            dispatch({ type: 'setRiskCategoriesIsLoading', payload: false });

            return riskCategoriesResp?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setRiskCategoriesError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setRiskCategoriesIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [language]);

    const getProductData = useCallback(async () => {
        dispatch({ type: 'setProductError', payload: null });
        dispatch({ type: 'setProductIsLoading', payload: true });

        try {
            const response = await SM.portfolioManagement('getPortfolioProposals', [clientId, { language }]);
            const proposalId = (response?.data || []).pop()?.Id;

            if (!proposalId) {
                dispatch({ type: 'setProductIsLoading', payload: false });

                return {
                    productOffer: undefined,
                    product: undefined,
                    proposalId: undefined,
                };
            }

            const responseProposal = await SM.portfolioManagement('getPortfolioProposal', [clientId, proposalId, { language }]);
            const productId = responseProposal?.data?.ProductId;
            const responseConfig = await SM.commonService('getCampaignsCurrentConfiguration', [language]);
            const productObj = (responseConfig?.data?.ProductOffers || [])
                .map((offer) => (offer?.Products || [])).flat()
                .find((product) => product?.Id === productId);
            const productOffer = { id: productObj?.ProductOfferId, name: productObj?.OfferName };
            const product = { id: productId, name: productObj?.Name };

            dispatch({ type: 'setProduct', payload: { productOffer, product, proposal: responseProposal?.data } });
            dispatch({ type: 'setProductIsLoading', payload: false });

            return { productOffer, product, proposalId };
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setProductError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setProductIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [language, clientId]);

    return {
        data: state.data,
        globalSettings: state.globalSettings,
        error: state.error,
        isLoading: state.isLoading,
        getProducts,
        getRiskCategories,
        riskCategoriesData: state.riskCategoriesData,
        riskCategoriesError: state.riskCategoriesError,
        riskCategoriesIsLoading: state.riskCategoriesIsLoading,
        product: state.product,
        productError: state.productError,
        productIsLoading: state.productIsLoading,
        getProductData,
    };
};
