import React, { createContext, useContext, useEffect } from 'react';
import Preloader from 'components/Preloader';
import { useSchemaFields } from 'hooks/useSchemaFields';
import { useSchema } from 'domain/ClientService';

const ClientsSchemaContext = createContext(null);

export const useClientsSchema = () => {
    const context = useContext(ClientsSchemaContext);

    if (!context) {
        throw new Error('useClientsSchema can only be used inside ClientsSchemaContext');
    }

    return context;
};

const ClientsSchemaProvider = ({ children }) => {
    const {
        dataLists,
        isLoadingLists,
        errorLists,
    } = useSchema({ loadInitially: true });

    const schemasColumns = useSchemaFields('editCustomer');

    return (
        <ClientsSchemaContext.Provider value={{
            schemasColumns,
            schemasLists: {
                data: dataLists,
                isLoading: isLoadingLists,
                error: errorLists,
            },
        }}
        >
            <Preloader
                isLoading={schemasColumns.isLoading}
                error={schemasColumns.error}
            >
                {children}
            </Preloader>
        </ClientsSchemaContext.Provider>
    );
};

export const connectClientsSchema = (Component) => (
    (props) => (
        <ClientsSchemaProvider>
            <Component {...props} />
        </ClientsSchemaProvider>
    )
);

export default ClientsSchemaProvider;
