import React from 'react';
import PropTypes from 'prop-types';
import './CellNextUp.css';
import history from 'services/history';
import { getClassNames } from 'utils';
import { genarateDFSClientId } from '../../../utils';

const CellNextUp = ({
    topic, time, contact, color,
}) => {
    const classNames = getClassNames('table-cell_ticket', `table-cell_ticket__color-${color}`);

    const onContactClick = (e, contactId, clientName) => {
        e.stopPropagation();
        if (contactId) history.push(`/client/${genarateDFSClientId(contactId)}`, { clientName });
    };

    return (
        <div className={classNames}>
            <b>{topic}</b>
            <p>{time}</p>
            <div className="gray-text">
                {
                    contact.map((item, index) => (
                        <button
                            type="button"
                            key={item.id}
                            className="table-cell__contact"
                            onClick={(e) => onContactClick(e, item.id, item.name)}
                        >
                            {index !== contact.length - 1 ? `${item.name},` : `${item.name}`}
                        </button>
                    ))
                }
            </div>
        </div>
    );
};

CellNextUp.propTypes = {
    topic: PropTypes.string,
    time: PropTypes.string,
    contact: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    color: PropTypes.string,
};

CellNextUp.defaultProps = {
    topic: null,
    time: null,
    contact: null,
    color: null,
};

export default CellNextUp;
