import { TYPE_CURRENCY, TYPE_PERCENT } from '../../../../../../../../../utils/formatting';

export const adaptPortfolioReviewOverview = ({
    amount, currency, t, productName, fee, strategyName,
}) => {
    const extraColumns = strategyName ? [{
        overviewItemsInfo: [{
            title: t('portfolio.strategy'),
            value: strategyName,
        }],
    }] : [];

    return [{
        columnsData: [
            {
                overviewItemsInfo: [{
                    title: t('clientDashboard.portfolio.portfolioValue'),
                    value: amount,
                    type: TYPE_CURRENCY,
                    currency,
                }],
            },
            {
                overviewItemsInfo: [{
                    title: t('portfolio.product'),
                    value: productName,
                }],
            },
            {
                overviewItemsInfo: [{
                    title: t('onBoarding.fees'),
                    value: fee,
                    type: TYPE_PERCENT,
                }],
            },
            ...extraColumns,
        ],
    },
    ];
};
