import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getClientId } from 'utils';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import { PageNotFound } from 'components/PageError';
import RiskProfile from './components/RiskProfile';
import ModelPortfolioView from './components/ModelPortfolioView';
import { useModelPortfolio } from './hooks/useModelPortfolio';
import { usePortfolioCreating } from './hooks/usePortfolioCreating';
import { useClientLayout } from '../../../../hooks/useClientLayout';

const PortfolioRiskProfile = (props) => {
    useCancelAllRequests();
    useClientLayout();

    const { match: { params: { dfsClientId } } } = props;
    const clientId = getClientId(dfsClientId);

    const [answers, setAnswers] = useState([undefined, undefined, undefined, undefined]);
    const [currentStep, setCurrentStep] = useState(0);
    const {
        setAcceptedRisk,
        setChosenProduct,
        showModelPortfolio,
        onShowModelPortfolio,
        returnOnRiskProfile,
        data: modelPortfolio,
        isLoading,
        error,
    } = useModelPortfolio(clientId);
    const {
        cancelPortfolio,
        createPortfolio,
        isLoading: isCreatingLoading,
        error: errorCreating,
    } = usePortfolioCreating(dfsClientId);

    if (clientId === undefined) {
        return (<PageNotFound />);
    }

    if (showModelPortfolio) {
        return (
            <ModelPortfolioView
                data={modelPortfolio}
                error={error}
                clientId={clientId}
                isLoading={isLoading}
                dfsClientId={dfsClientId}
                errorCreating={errorCreating}
                isCreatingLoading={isCreatingLoading}
                cancelPortfolio={cancelPortfolio}
                createPortfolio={createPortfolio}
                returnOnRiskProfile={returnOnRiskProfile}
            />
        );
    }

    return (
        <RiskProfile
            answers={answers}
            currentStep={currentStep}
            changeAnswers={setAnswers}
            changeCurrentStep={setCurrentStep}
            setAcceptedRisk={setAcceptedRisk}
            setChosenProduct={setChosenProduct}
            onSubmit={onShowModelPortfolio}
        />
    );
};

PortfolioRiskProfile.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ dfsClientId: PropTypes.string.isRequired }),
    }).isRequired,
};

PortfolioRiskProfile.defaultProps = {};

export default PortfolioRiskProfile;
