import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import AdvisoryDashboardContainer from './AdvisoryDashboardContainer';
import NewCalendarEntry from './pages/NewCalendarEntry';

const Router = (props) => {
    const { match: { path } } = props;

    return (
        <Switch>
            <Route exact path={`${path}`} component={AdvisoryDashboardContainer} />
            <Route exact path={`${path}/new-interaction`} component={NewCalendarEntry} />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
