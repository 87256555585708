import {
    BreadCrumbs, Column, Paragraph, Title,
} from 'ui-library';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './PageHeader.css';

function PageHeader(props) {
    const {
        title, description, breadCrumbsCurrent, breadCrumbsChildren, children, link,
    } = props;

    const renderTitle = useCallback(() => (
        link
            ? <Title type={1} link={link}>{title}</Title>
            : <Title type={1}>{title}</Title>
    ), [title, link]);

    return (
        <div className="PageHeader">
            <Column size={[`${children ? 'sm-6' : 'sm-12'}`]}>
                {breadCrumbsChildren.length > 0 && (
                    <div className="PageHeader-bread-crumbs">
                        <BreadCrumbs current={breadCrumbsCurrent}>
                            {breadCrumbsChildren?.map((breadCrumb) => breadCrumb.to && (
                                <Link
                                    to={breadCrumb.to}
                                    onClick={breadCrumb.onClick}
                                >
                                    {breadCrumb?.label}
                                </Link>
                            ))}
                        </BreadCrumbs>
                    </div>
                )}
                <div className="PageHeader-title">
                    { renderTitle() }
                    { description && <Paragraph>{description}</Paragraph> }
                </div>
            </Column>
            {children && (
                <Column size={['sm-6']} className="content-right">
                    {children}
                </Column>
            ) }
        </div>
    );
}
PageHeader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    breadCrumbsCurrent: PropTypes.string,
    breadCrumbsChildren: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string,
            label: PropTypes.string,
            onClick: PropTypes.func,
        }),
    ),
    children: PropTypes.node,
    link: PropTypes.string,
};

PageHeader.defaultProps = {
    title: '',
    description: '',
    breadCrumbsCurrent: null,
    breadCrumbsChildren: [],
    children: null,
    link: '',
};

export default PageHeader;
