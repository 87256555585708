import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptAccountsData } from '../pages/ClientOverview/pages/Portfolios/pages/PositionDetails/adapters/adaptAccountsData';

export const useAccounts = (clientId, portfolioId) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataRaw, setDataRaw] = useState([]);

    const { i18n } = useTranslation();


    useEffect(() => {
        if (!clientId || !portfolioId) return;
        setLoading(true);
        const params = { language: i18n.language };

        ServiceManager.portfolioManagement('getPortfolioAccounts', [clientId, portfolioId, params])
            .then((response) => {
                try {
                    setDataRaw(response.data);
                    setData(adaptAccountsData(response.data));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, [clientId, portfolioId, i18n.language]);

    return {
        data, dataRaw, isLoading, error,
    };
};
