import React from 'react';
import News from 'components/News';
import Calendar from 'components/Calendar';
import Messages from 'components/Messages';
import Tasks from 'components/Tasks';
import Overview from './Overview';
import AdvisoryInvestmentPerformance from './AdvisoryInvestmentPerformance';
import NextUp from './NextUp';
import ClientSearch from './ClientSearch';
import Markets from './Markets';
import InvestmentAllocationWrapper from './InvestmentAllocationWrapper';

const WidgetsObject = (data) => {
    const {
        overview,
        tasks,
        news,
        configuration,
        calendar,
        nextUp,
        clients,
        listCurrencies,
        markets,
        currencies,
        messages,
        allocation,
        onRowClick,
    } = data;

    const Widgets = (props) => ({
        Overview: <Overview {...overview} {...props} />,
        Tasks: <Tasks {...tasks} {...props} isResizable />,
        News: <News {...news} {...props} isResizable withLink />,
        InvestmentPerformance: (
            <AdvisoryInvestmentPerformance configuration={configuration} withLink {...props} />
        ),
        Calendar: <Calendar {...calendar} {...props} />,
        NextUp: <NextUp {...nextUp} {...props} />,
        ClientSearch: <ClientSearch {...clients} {...props} />,
        Markets: (
            <Markets
                {...listCurrencies}
                markets={markets}
                currencies={currencies}
                withLink={false}
                {...props}
            />
        ),
        Messages: <Messages
            {...messages}
            onRow={onRowClick}
            fromQuickAccessMenu
            withoutHeader
            defaultSize={5}
            {...props}
        />,
        InvestmentAllocation: <InvestmentAllocationWrapper {...allocation} {...props} />,
    });

    return Widgets;
};

export default WidgetsObject;
