import { merge } from 'lodash/fp';
import { insertIntoArray } from 'utils';
import { fieldTypes, selectMultipleType } from '../constants';
import { convertObjToGraphQLSchema, generateObjFromDataPath } from './index';

export class ClientSchemaManager {
    static #isArrayType = (isArrayItem, dataPath = '', insertValue = '') => {
        if (!isArrayItem) return dataPath;

        return insertIntoArray(dataPath.split('.'), 2, insertValue).join('.');
    };

    static #isSelectType = (fieldType) => {
        if (fieldTypes.includes(fieldType)) return '.label';

        return '';
    };

    static getDataPathByKey = (columns = []) => (
        columns.reduce((acc, column) => ({
            ...acc,
            [column.key]: `${
                ClientSchemaManager.#isArrayType(column.isArrayItem, column.dataPath, '[0]')}${ClientSchemaManager.#isSelectType(column.fieldType)}`,
        }), {})
    );

    static getFieldsByColumn = ({
        column = null,
        field = {},
        dataList = [],
    }) => {
        if (!column) return {};

        return (
            {
                sorter: false,
                onFilter: false,
                title: field?.label ?? '',
                fieldType: field.fieldType,
                isArrayItem: field.isArrayItem,
                sortable: field?.isSortable ?? false,
                filterable: field?.isFilterable ?? false,
                filters: dataList?.filter(({ listKey }) => listKey === field.listKey)
                    .map(({ label, id }) => ({ value: id, label })),
                ...column,
            }
        );
    };

    static generateColumns = ({
        columns = [],
        fields = [],
        dataList = [],
    }) => columns.map((column) => (
        this.getFieldsByColumn({
            column,
            dataList,
            field: fields?.find(({ key }) => key === column.dataPath),
        })
    ));

    static generateContactFragment = (columns) => {
        const schemaObj = columns.reduce((acc, { dataPath, fieldType }) => (
            merge(acc,
                generateObjFromDataPath(
                    dataPath,
                    fieldTypes.includes(fieldType)
                        ? { label: '', value: '' }
                        : '',
                ))
        ), {});

        return `fragment ContactItems on Contact   ${convertObjToGraphQLSchema(schemaObj)}`;
    };

    static #generateFilterValue = (fieldType = '', value = []) => {
        if (fieldType === selectMultipleType) {
            return ({ some: { value: { in: value } } });
        }

        return ({ value: { in: value } });
    };

    static generateSortValue = ({ key = '', direction = '', columns = [] }) => {
        const { fieldType, dataPath } = columns.find((item) => key === item.key) || {};

        if (!dataPath) return [];

        return [
            generateObjFromDataPath(
                dataPath,
                fieldTypes.includes(fieldType) ? { label: direction?.toUpperCase() }
                    : direction?.toUpperCase(),
            ),
        ];
    };

    static generateFilters = (rules = [], columns = []) => (
        rules.reduce((acc, item) => {
            const {
                dataPath, isArrayItem, fieldType,
            } = columns.find(({ key }) => key === item.key);

            if (!columns) {
                return acc;
            }

            return (
                merge(
                    acc,
                    generateObjFromDataPath(
                        this.#isArrayType(isArrayItem, dataPath, 'some'),
                        ClientSchemaManager.#generateFilterValue(fieldType, item.value),
                    ),
                )
            );
        }, {})
    );
}
