/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
    useCallback, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import {
    DatePicker,
    NumberInput, Paragraph, Select, Title,
} from 'ui-library';
import { Controller } from 'react-hook-form';
import ContentBox from 'ui-library/dist/components/ContentBox';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import {
    getDateFormat, formatDate, compareDates, subtractUnits, isWeekendDay,
} from 'utils/datetime';
import { datePickerLocale } from 'constants/constants';
import { useLocale } from 'locale';
import PropTypes from 'prop-types';
import { useAccountForm } from './hooks/useAccountForm';
import { usePaymentsData } from '../../hooks/usePaymentsData';
import { executionDateDefault } from '../../utils';

const AccountFrom = ({
    listCurrencies, cashAccounts, onCancel, onNext,
}) => {
    useCancelAllRequests();

    const { t } = useTranslation();
    const { locale, numberSeparators } = useLocale();
    const { getPayment, savePayment } = usePaymentsData();

    const defaultData = getPayment(2);
    const {
        control, formErrors, values, onChange, handleSubmit, register,
    } = useAccountForm({ defaultData });

    const defaultCurrency = useMemo(() => listCurrencies?.find((item) => item.label === 'USD'), [listCurrencies]);

    useEffect(() => {
        if (!defaultData?.currency?.value) onChange('currency')(defaultCurrency);
    }, [defaultCurrency, defaultData?.currency?.value, onChange]);

    const amountSelectAfter = useMemo(() => {
        if (listCurrencies.length > 0) {
            return (
                <Controller
                    name="currency"
                    control={control}
                    render={({ value }) => (
                        <Select
                            options={listCurrencies}
                            value={value}
                            error={formErrors.currency}
                            onChange={(val) => onChange('currency')(
                                listCurrencies.find((item) => item.value === val),
                            )}
                        />
                    )}
                />

            );
        }

        return null;
    }, [listCurrencies, control, onChange, formErrors.currency]);

    const onSubmit = useCallback(() => {
        savePayment({ 2: values });
        onNext();
    }, [values]);

    const debitAccountsOptions = useMemo(() => cashAccounts
        ?.filter((i) => i.value !== values?.creditAccount?.value),
    [cashAccounts, values?.creditAccount?.value]);

    const cashAccountsOptions = useMemo(() => cashAccounts
        ?.filter((i) => i.value !== values?.debitAccount?.value),
    [cashAccounts, values?.debitAccount?.value]);

    return (
        <div className="AccountForm">
            <ContentBox>
                <div className="payment-content">
                    <header className="on-boarding_header">
                        <Title type={2}>{t('payments.accountTransfer')}</Title>
                    </header>
                    {cashAccounts?.length < 2 ? (
                        <>
                            <div className="negative">
                                <div className="icon">
                                    <div className="negative-icon" />
                                </div>
                                <Paragraph>
                                    {t('payments.accountLengthError')}
                                </Paragraph>
                            </div>
                            <Paragraph className="create-account-inform">
                                {t('payments.createAccountInform')}
                            </Paragraph>
                        </>
                    ) : (
                        <>
                            <div className="debit-account">
                                <div className="group-title">
                                    <Title type={3}>{t('payments.debitAccount')}</Title>
                                </div>
                                <Select
                                    name="debitAccount"
                                    ref={register}
                                    label={t('payments.debitAccount')}
                                    options={debitAccountsOptions}
                                    value={values.debitAccount}
                                    error={formErrors.debitAccount}
                                    onChange={(val) => onChange('debitAccount')(
                                    cashAccounts?.find((item) => item.value === val),
                                    )}
                                    required
                                />
                            </div>
                            <div className="credit-account">
                                <div className="group-title">
                                    <Title type={3}>{t('payments.creditAccount')}</Title>
                                </div>
                                <Select
                                    name="creditAccount"
                                    ref={register}
                                    label={t('payments.creditAccount')}
                                    options={cashAccountsOptions}
                                    value={values.creditAccount}
                                    error={formErrors.creditAccount}
                                    onChange={(val) => onChange('creditAccount')(
                                    cashAccounts?.find((item) => item.value === val),
                                    )}
                                    required
                                />
                            </div>
                            <div className="payment-details">
                                <div className="group-title">
                                    <Title type={3}>{t('payments.transferDetails')}</Title>
                                </div>
                                <div className="amount">
                                    <Controller
                                        name="amount"
                                        control={control}
                                        render={() => (
                                            <NumberInput
                                                addonAfter={amountSelectAfter}
                                                label={t('payments.amount')}
                                                labelInfobox
                                                onChange={onChange('amount')}
                                                error={formErrors.amount}
                                                value={values.amount}
                                                withSelectBox
                                                required
                                                {...numberSeparators}
                                            />
                                        )}
                                    />
                                </div>
                                <Controller
                                    name="executionDate"
                                    control={control}
                                    defaultValue={executionDateDefault()}
                                    render={() => (
                                        <DatePicker
                                            label={t('payments.executionDate')}
                                            name="executionDate"
                                            value={values.executionDate ? formatDate(
                                                new Date(values.executionDate),
                                                getDateFormat(locale),
                                            ) : undefined}
                                            popupPosition="top"
                                            onChange={onChange('executionDate')}
                                            withInfobox={false}
                                            format={getDateFormat(locale)}
                                            labels={datePickerLocale(t, locale)}
                                            error={formErrors.executionDate}
                                            disabledDate={(current) => current
                                                && (compareDates(subtractUnits(new Date(), 1, 'minutes'), current) || isWeekendDay(current))}
                                        />
                                    )}
                                />
                            </div>
                        </>
                    )}
                </div>
            </ContentBox>
            {cashAccounts?.length > 1 && (
                <ButtonsBlockRow
                    additionalButton={{
                        text: t('payments.cancel'),
                        onClick: onCancel,
                        type: 'danger',
                    }}
                    primaryButton={{
                        text: t('confirmation.continue'),
                        onClick: handleSubmit(onSubmit),
                    }}
                />
            )}
        </div>
    );
};

AccountFrom.propTypes = {
    listCurrencies: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    cashAccounts: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    onCancel: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
};

AccountFrom.defaultProps = {
    listCurrencies: null,
    cashAccounts: null,
};

export default AccountFrom;
