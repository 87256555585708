import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ColumnChart, Infobox } from 'ui-library';
import { useTranslation } from 'react-i18next';
import RadioButtonGroup from 'components/RadioButtonGroup';
import { labelFormat } from 'utils/formatting';
import { useOnSubmit } from '../../hooks/useOnSubmit';
import { riskReturnValues } from '../../mockData';
import './RiskReturnExpectation.css';

const RiskReturnExpectation = ({
    data, submit, answers, sendData,
}) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(answers);
    const onChangeRadio = ({ target: { value } }) => setSelected(value);
    const options = useMemo(
        () => data[0].Answers.map(({ Id, Header }) => ({ label: Header, value: Id.toString() })),
        [data],
    );
    const selectedIndex = useMemo(
        () => data[0].Answers.findIndex(({ Id }) => Id === +selected),
        [selected, data],
    );
    const showError = useOnSubmit(submit, sendData, selected, !!selected);

    return (
        <div className="risk-return-expectation">
            {showError && <Infobox error>{t('clientDashboard.riskProfile.selectAnswer')}</Infobox>}
            <div className="column-chart">
                <ColumnChart
                    max={41}
                    min={-41}
                    data={riskReturnValues}
                    isSelected={selectedIndex}
                    labelFormat={labelFormat}
                    showPercentage
                />
            </div>
            <div className="column-chart__radioGroup">
                <RadioButtonGroup
                    name="risk_appetite"
                    options={options}
                    onChange={onChangeRadio}
                    defaultValue={answers}
                    horizontal
                />
            </div>
        </div>
    );
};

RiskReturnExpectation.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        Id: PropTypes.number,
        Answers: PropTypes.arrayOf(PropTypes.shape({
            Header: PropTypes.string,
        })),
    })),
    submit: PropTypes.bool,
    answers: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    sendData: PropTypes.func,
};

RiskReturnExpectation.defaultProps = {
    data: [],
    submit: false,
    answers: null,
    sendData: () => {},
};

export default RiskReturnExpectation;
