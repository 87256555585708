import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Title, Table, ContentBox, TabsExtraItem, Select, Row, Column,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import { allocationPercentFormat } from 'utils/formatting';
import {
    PIE, BAR, LIST, investmentAllocation,
} from 'constants/constants';
import { useFormatting } from 'locale';
import ChartType from 'components/ChartType';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import Draggable from '../Draggable/Draggable';

import './InvestmentAllocationMini.css';

function InvestmentAllocationMini({
    data, isLoading, error, drop,
}) {
    const { t, i18n } = useTranslation();
    const { getFormattedCurrency } = useFormatting();
    const [type, setType] = useState('Asset classes');
    const [chartView, setChartView] = useState(PIE);
    const changeView = (chartType) => () => setChartView(chartType);
    const numberFormat = (number) => getFormattedCurrency(number, {
        currency: data.currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });
    const labelFormat = (label, value) => `<label class="Form-control-label"><span>${label}</span></label>${numberFormat(value?.y || value)}`;
    const options = useMemo(
        () => data.chart.map(({ name, title }) => ({ label: title, value: name })),
        [data, type],
    );
    const chart = useMemo(
        () => data.chart.find(({ name }) => name === type) || { dataMini: { [PIE]: {} } },
        [data, type],
    );
    const titleComponent = useMemo(() => {
        if (drop) {
            return (
                <Draggable {...drop}>
                    <Title type={2}>{t('advisoryDashboard.investmentAllocation.title')}</Title>
                </Draggable>
            );
        }

        return <Title type={2}>{t('advisoryDashboard.investmentAllocation.title')}</Title>;
    }, [drop, i18n.language]);
    const additionalCss = useMemo(() => (chart.dataMini[chartView].data || []).reduce(
        (acc, { color }, idx) => ({ ...acc, [`--pie-chart-colors-${idx}`]: color }), {},
    ), [chart.dataMini[chartView].data]);

    return (
        <PageSection>
            <ContentBox className="investment-allocation investment-allocation-mini table-without-last-row-padding" style={additionalCss} underline={false}>
                {titleComponent}
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data.chart} text={t('advisoryDashboard.investmentAllocation.notFount')}>
                        <Row>
                            <Column size={['xl-6', 'lg-12', 'md-7']}>
                                <Select
                                    onChange={setType}
                                    options={options}
                                    value={type}
                                />
                            </Column>
                            <Column size={['xl-6', 'lg-12', 'md-5']} className="chart-views">
                                <TabsExtraItem
                                    icon="chart-pie"
                                    isActive={chartView === PIE}
                                    onClick={changeView(PIE)}
                                />
                                <TabsExtraItem
                                    icon="chart-bar-horizontal"
                                    isActive={chartView === BAR}
                                    onClick={changeView(BAR)}
                                />
                                <TabsExtraItem
                                    icon="list"
                                    isActive={chartView === LIST}
                                    onClick={changeView(LIST)}
                                />
                            </Column>
                        </Row>
                        <EmptyContent
                            data={chart}
                            text={t('advisoryDashboard.noData')}
                        >
                            <ChartType
                                data={chart.dataMini[chartView].data}
                                title={chart.title}
                                labels={chart.dataMini[chartView].labels}
                                currency={data.currency}
                                barHeight={50}
                                chartView={chartView}
                                labelFormat={labelFormat}
                                numberFormat={numberFormat}
                                percentFormat={allocationPercentFormat}
                                disableLegend
                            />
                            {chartView === PIE && (
                                <Table
                                    data={chart.dataMini[chartView].table}
                                    columns={investmentAllocation(chart.title, t)}
                                />
                            )}
                        </EmptyContent>
                    </EmptyContent>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

InvestmentAllocationMini.propTypes = {
    data: PropTypes.shape({
        chart: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            dataMini: PropTypes.shape({
                [PropTypes.string]: PropTypes.arrayOf(PropTypes.shape({
                    name: PropTypes.string,
                    value: PropTypes.number,
                })),
            }),
        })),
        currency: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    drop: PropTypes.shape({
        role: PropTypes.string,
        tabIndex: PropTypes.number,
        onDragStart: PropTypes.func,
    }),
};

InvestmentAllocationMini.defaultProps = {
    data: { chart: [] },
    isLoading: true,
    error: null,
    drop: null,
};

export default InvestmentAllocationMini;
