import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import { useRiskProfileLayout } from './hooks/useRiskProfileLayout';
import Interaction from './pages/Interaction';
import RiskProfileReview from './pages/RiskProfileReview';
import RiskProfileQuestionnaire from './pages/RiskProfileQuestionnaire';

const Router = (props) => {
    useCancelAllRequests();
    useRiskProfileLayout();

    const { match: { path } } = props;

    return (
        <Switch>
            <Route path={`${path}/review`} component={RiskProfileReview} />
            <Route exact path={`${path}/interaction`} component={Interaction} />
            <Route path={`${path}/:group`} component={RiskProfileQuestionnaire} />
            <Redirect to={`${path}/0`} />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
    }).isRequired,
};

Router.defaultProps = {};

export default Router;
