import { useEffect } from 'react';
import history from '../services/history';

const useLeaveRoute = (url, callback) => {
    useEffect(() => {
        const clearBlockHistory = history.block((e) => {
            const clientRegExp = new RegExp(url);

            if (!clientRegExp.test(e.pathname)) {
                callback();
            }

            return true;
        });

        return clearBlockHistory;
    }, [url, callback]);
};

export default useLeaveRoute;
