import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Column, DatePicker, Row, Select, MultiSelect, Checkbox, Label as UILabel,
} from 'ui-library';
import { getDateTimeFormat, toBaseDate } from 'utils/datetime';
import { datePickerLocale } from 'constants/constants';
import {
    useLocale, useFormatting, dateTimeFormats,
} from 'locale';
import { Controller } from 'react-hook-form';
import FieldControl from 'components/FieldControl/FieldControl';
import { optionsType } from '../constants/types';
import Comment from '../components/Comment';

import './InteractionDetails.css';

const isEmpty = (value) => !value || value.length === 0;

const InteractionDetails = ({
    register,
    data,
    values,
    errors,
    onCommentChange,
    editable,
    editableFields,
    commentHelpText,
    showCommentHelpText,
    onChange,
    showComment,
    convertToUTC,
    onSearch,
    isSearching,
    control,
}) => {
    const { getFormattedDate } = useFormatting();
    const { t } = useTranslation();
    const { locale } = useLocale();
    const dateTimeFormat = useMemo(() => (getDateTimeFormat(locale)), [locale]);
    const [formattedValues, setValues] = useState({});

    useEffect(() => {
        setValues({
            ...values,
            from: isEmpty(values.from)
                ? undefined
                : getFormattedDate(values.from, {
                    hour: dateTimeFormats.TWO_DIGIT,
                    minute: dateTimeFormats.TWO_DIGIT,
                    convertToUTC,
                }),
            to: isEmpty(values.to)
                ? undefined
                : getFormattedDate(values.to, {
                    hour: dateTimeFormats.TWO_DIGIT,
                    minute: dateTimeFormats.TWO_DIGIT,
                    convertToUTC,
                }),
        });
    }, [values.to, values.from]);
    const onChangeValue = (type) => (value) => {
        setValues((prev) => ({
            ...prev,
            [type]: value,
        }));
        onChange(type)(value);
    };

    return (
        <div className="preview-content-metadata">
            <Row>
                <Column size="6">
                    <FieldControl
                        label={t('interaction.from')}
                        value={formattedValues.from}
                        editable={editable || editableFields.from}
                        editComponent={({ value, label }) => (
                            <DatePicker
                                ref={register}
                                name="from"
                                value={value === '' ? undefined : value}
                                label={label}
                                onChange={(date) => onChangeValue('from')(toBaseDate(date))}
                                withInfobox={false}
                                format={dateTimeFormat}
                                labels={datePickerLocale(t, locale)}
                                error={errors.from}
                                showTime
                            />
                        )}
                    />
                </Column>
                <Column size="6">
                    <FieldControl
                        label={t('interaction.to')}
                        value={formattedValues.to}
                        editable={editable || editableFields.to}
                        editComponent={({ value, label }) => (
                            <DatePicker
                                ref={register}
                                name="to"
                                value={value === '' ? undefined : value}
                                label={label}
                                withInfobox={false}
                                format={dateTimeFormat}
                                onChange={(date) => onChangeValue('to')(toBaseDate(date))}
                                labels={datePickerLocale(t, locale)}
                                error={errors.to}
                                showTime
                            />
                        )}
                    />
                </Column>
            </Row>
            <Row>
                <Column size="6">
                    <FieldControl
                        label={t('interaction.initiated')}
                        value={values.initiated}
                        editable={editable || editableFields.initiated}
                        editComponent={({ value, label }) => (
                            <Controller
                                control={control}
                                name="initiated"
                                render={() => (
                                    <Select
                                        ref={register}
                                        name="initiated"
                                        value={value}
                                        label={label}
                                        options={data.initiated || []}
                                        placeholder={t('position.pleaseSelect')}
                                        onChange={onChange('initiated')}
                                        error={errors.initiated}
                                        hasSearch
                                    />
                                )}
                            />
                        )}
                    />
                </Column>
                <Column size="6">
                    <FieldControl
                        label={t('interaction.participants')}
                        value={values.participants}
                        renderValue={(arr) => arr?.map((i) => i).join(', ')}
                        editable={editable || editableFields.participants}
                        editComponent={({ value, label }) => (
                            <Controller
                                control={control}
                                name="participants"
                                render={() => (
                                    <MultiSelect
                                        ref={register}
                                        value={value}
                                        label={label}
                                        options={data.participants || []}
                                        placeholder={t('position.pleaseSelect')}
                                        onChange={onChange('participants')}
                                        error={errors.participants}
                                        isSearching={isSearching.participants}
                                        onSearch={onSearch('participants')}
                                    />
                                )}
                            />
                        )}
                    />
                </Column>
            </Row>
            <Row>
                <Column size="6">
                    <FieldControl
                        label={t('interaction.interactionType')}
                        value={values.type}
                        editable={editable || editableFields.type}
                        editComponent={({ value, label }) => (
                            <Controller
                                control={control}
                                name="type"
                                render={() => (
                                    <Select
                                        ref={register}
                                        name="type"
                                        value={value}
                                        label={label}
                                        options={data.types || []}
                                        placeholder={t('position.pleaseSelect')}
                                        onChange={onChange('type')}
                                        error={errors.type}
                                    />
                                )}
                            />
                        )}
                    />
                </Column>
                <Column size="6">
                    <FieldControl
                        label={t('interaction.interactionTopic')}
                        value={values.topic}
                        editable={editable || editableFields.topic}
                        editComponent={({ value, label }) => (
                            <Controller
                                control={control}
                                name="topic"
                                render={() => (
                                    <Select
                                        ref={register}
                                        name="topic"
                                        value={value}
                                        label={label}
                                        options={data.topics || []}
                                        placeholder={t('position.pleaseSelect')}
                                        onChange={onChange('topic')}
                                        error={errors.topic}
                                    />
                                )}
                            />
                        )}
                    />
                </Column>
                {(editable || editableFields.email) && (
                    <Column size="6">
                        <FieldControl
                            label={t('interaction.emailLabel')}
                            value={values.email}
                            editable={editable || editableFields.email}
                            editComponent={({ value, label }) => (
                                <div className="interaction-email">
                                    <UILabel
                                        label={label}
                                        labelInfobox
                                        helpText={t('interaction.emailText')}
                                    />
                                    <Checkbox
                                        ref={register}
                                        name="email"
                                        checked={value}
                                        onChange={onChange('email')}
                                        label={t('interaction.emailValue')}
                                    />
                                </div>
                            )}
                        />
                    </Column>
                )}
            </Row>
            {showComment && (
                <Comment
                    value={values.comment}
                    error={errors.comment}
                    register={register}
                    editable={editable || editableFields.comment}
                    helpText={commentHelpText}
                    showHelpText={showCommentHelpText}
                    onChange={onCommentChange}
                    className="interaction-details-comment"
                />
            )}
        </div>
    );
};

InteractionDetails.propTypes = {
    register: PropTypes.func,
    data: PropTypes.shape({
        initiated: optionsType,
        participants: optionsType,
        types: optionsType,
        topics: optionsType,
    }),
    values: PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
        initiated: PropTypes.string,
        participants: PropTypes.arrayOf(PropTypes.string),
        type: PropTypes.string,
        topic: PropTypes.string,
        comment: PropTypes.string,
        email: PropTypes.bool,
    }),
    errors: PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
        initiated: PropTypes.string,
        participants: PropTypes.arrayOf(PropTypes.string),
        type: PropTypes.string,
        topic: PropTypes.string,
        comment: PropTypes.string,
    }),
    editable: PropTypes.bool,
    editableFields: PropTypes.shape({
        from: PropTypes.bool,
        to: PropTypes.bool,
        initiated: PropTypes.bool,
        participants: PropTypes.bool,
        type: PropTypes.bool,
        topic: PropTypes.bool,
        comment: PropTypes.bool,
        email: PropTypes.bool,
    }),
    commentHelpText: PropTypes.string,
    showCommentHelpText: PropTypes.bool,
    onCommentChange: PropTypes.func,
    onChange: PropTypes.func,
    showComment: PropTypes.bool,
    convertToUTC: PropTypes.bool,
    onSearch: PropTypes.func,
    isSearching: PropTypes.shape({
        participants: PropTypes.bool,
    }),
    control: PropTypes.string,
};

InteractionDetails.defaultProps = {
    register: null,
    data: {
        initiated: [],
        participants: [],
        types: [],
        topics: [],
    },
    values: {},
    errors: {},
    editable: false,
    editableFields: {},
    commentHelpText: null,
    showCommentHelpText: false,
    onCommentChange: () => { },
    onChange: () => { },
    showComment: true,
    convertToUTC: false,
    onSearch: () => { },
    isSearching: {},
    control: '',
};

export default InteractionDetails;
