import React from 'react';
import { withMainLayout } from 'hocs';
import News from 'components/News';
import { useTranslation } from 'react-i18next';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import { useResearch } from 'hooks/useResearch';
import { useLoadMoreNews } from 'hooks/useLoadMoreNews';

function Research() {
    useCancelAllRequests();
    const { t } = useTranslation();
    const pageSize = 9;
    const {
        list: researchList, errorList, isLoadingList, getResearches,
    } = useResearch({
        listSize: pageSize, pageNumber: 1, loadListInitially: true,
    });
    const { nextArticles, newsList } = useLoadMoreNews({
        initialNews: researchList,
        pageSize,
        getNewsData: getResearches,
        skipLoading: true,
    });

    return (
        <News
            title={t('research.title')}
            isLoading={isLoadingList}
            error={errorList}
            data={newsList}
            pageSize={pageSize}
            nextArticles={nextArticles}
            nextArticlesSize={3}
            loadMore
        />
    );
}

Research.propTypes = {};

Research.defaultProps = {};

export default withMainLayout(Research);
