import { renderers } from 'ui-library';
import { CellAdaptedDate } from '../../../components/renderers';

const { CellTooltiped } = renderers;

export const existingInteractionColumns = (t, getFormattedDate, getFormattedTime) => ([
    {
        key: 'type',
        title: t('clientDashboard.existingIInteraction.type'),
        render: CellTooltiped,
        width: 200,
    },
    {
        key: 'topic',
        title: t('clientDashboard.existingIInteraction.topic'),
        filterable: true,
    },
    {
        key: 'from',
        title: t('clientDashboard.existingIInteraction.from'),
        render: CellAdaptedDate(t, getFormattedDate, getFormattedTime),
        className: 'table-cell__date text-right',
    },
]);
