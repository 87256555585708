import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PageVariant } from 'ui-library';
import PageVariantWrapper from 'components/PageVariantWrapper';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';

function Error({ onPageChange }) {
    const { t } = useTranslation();
    const { clearFIdentityInProgress } = useOnBoardingSelector(onBoardingDataSelector);

    useEffect(() => {
        clearFIdentityInProgress();
    }, [clearFIdentityInProgress]);

    return (
        <PageVariantWrapper className="contract-result">
            <PageVariant
                type="error"
                title={t('error.title')}
                message={t('onBoarding.contract.error.message')}
                actionButtonLabel={t('confirm.button')}
                onActionButtonClick={() => {
                    onPageChange('');
                }}
            />
        </PageVariantWrapper>
    );
}

Error.propTypes = {
    onPageChange: PropTypes.func.isRequired,
};

Error.defaultProps = {};

export default Error;
