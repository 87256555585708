import React from 'react';
import { checkNullData, genarateDFSClientId } from 'utils';
import history from 'services/history';
import { get } from 'lodash/fp';
import { SkeletonLine } from 'ui-library';
import { NA } from 'utils/formatting';
import { dateTimeFormats } from '../../../locale';
import { ClientSchemaManager } from '../utils/ClientSchemaManager';

const clientOverview = ({ internalId, clientName }) => () => {
    history.push(`/client/${internalId}`, { clientName, isProspect: false });
};

const onBoarding = ({ internalId, clientName }) => () => {
    history.push(`/onboarding/${internalId}`, { clientName, isProspect: true });
};

const getWithDefault = (obj = {}, path = '', formatting = (v) => v) => {
    const value = get(path, obj);

    if (![undefined, null].includes(value)) return formatting(get(path, obj));

    return NA;
};

export const adaptClientList = ({
    data = {},
    t,
    columns = [],
    isLoading,
    getFormattedDate,
    getFormattedCurrency,
    pageSize = 10,
}) => {
    if (isLoading) {
        return new Array(pageSize).fill(null).map(() => (
            columns.map(({ key, render }) => {
                if (render) {
                    return ({
                        [key]: {
                            label: <SkeletonLine width="100px" />,
                            render: <SkeletonLine width="100%" />,
                        },
                    });
                }

                return ({ [key]: <SkeletonLine width="100%" /> });
            }).reduce((a, i) => ({ ...a, ...i }), {})
        ));
    }

    if (checkNullData(data)) return [];

    const dataPath = ClientSchemaManager.getDataPathByKey(columns);

    return data.map((item) => {
        const internalId = genarateDFSClientId(item.id);
        const clientName = getWithDefault(item, dataPath.name);
        const isProspect = getWithDefault(item, dataPath.status);

        return {
            id: item.id,
            internalId: {
                label: internalId,
                link: `/client/${internalId}`,
                state: { clientName, isProspect },
            },
            externalId: getWithDefault(item, dataPath.externalId),
            name: clientName,
            type: getWithDefault(item, dataPath.type),
            status: isProspect ? t('advisoryDashboard.overview.prospects') : t('advisoryDashboard.overview.clients'),
            nationality: getWithDefault(item, dataPath.nationality),
            product: 'N/A',
            country: getWithDefault(item, dataPath.country),
            isUsPerson: getWithDefault(item, dataPath.isUsPerson),
            pep: getWithDefault(item, dataPath.pep, (v) => v?.toString()),
            lastClientContactDate: getFormattedDate(
                getWithDefault(item, dataPath.lastClientContactDate), {
                    hour: dateTimeFormats.TWO_DIGIT,
                    minute: dateTimeFormats.TWO_DIGIT,
                    second: dateTimeFormats.TWO_DIGIT,
                },
            ),
            lastClientLoginDate: getWithDefault(
                item,
                dataPath.lastClientLoginDate,
                (date) => getFormattedDate(date, {
                    hour: dateTimeFormats.TWO_DIGIT,
                    minute: dateTimeFormats.TWO_DIGIT,
                    second: dateTimeFormats.TWO_DIGIT,
                }),
            ),
            chosenRiskCategory: getWithDefault(item, dataPath.chosenRiskCategory),
            totalNumberOfPortfolios: getWithDefault(
                item, dataPath.totalNumberOfPortfolios,
            ),
            performanceYTD: getWithDefault(
                item,
                dataPath.performanceYTD,
                (value) => value * 100,
            ),

            performance1Year: getWithDefault(
                item,
                dataPath.performance1Year,
                (value) => value * 100,
            ),
            performance3Year: getWithDefault(
                item,
                dataPath.performance3Year,
                (value) => value * 100,
            ),

            performance5Year: getWithDefault(
                item,
                dataPath.performance5Year,
                (value) => value * 100,
            ),

            performanceSinceInception: getWithDefault(
                item,
                dataPath.performanceSinceInception,
                (value) => value * 100,
            ),
            reportingCurrency: getWithDefault(item, dataPath.reportingCurrency),
            totalValueOfPortfolios: getWithDefault(
                item, dataPath.totalValueOfPortfolios, getFormattedCurrency,
            ),
            cashComponentOfTotalValue: getWithDefault(
                item, dataPath.cashComponentOfTotalValue, getFormattedCurrency,
            ),
            securitiesComponentOfTotalValue: getWithDefault(
                item, dataPath.securitiesComponentOfTotalValue, getFormattedCurrency,
            ),
            activeProducts: item.investmentInformation?.activeProducts.map(({ label }) => label)?.join(', '),

            // Prospect
            onboardingStage: getWithDefault(item, dataPath.onboardingStage),

            actions: {
                value: 'action',
                actions: [
                    {
                        text: isProspect ? t('clients.onBoardingToWealth') : t('clients.clientOverview'),
                        onClick: isProspect
                            ? onBoarding({ internalId, clientName })
                            : clientOverview({ internalId, clientName }),
                    },
                ],
            },
        };
    });
};
