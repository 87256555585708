import React, { useCallback, useEffect } from 'react';
import { useLayout } from 'prodivers/layout';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import { Modal } from 'ui-library';
import { useClientProfile } from 'prodivers/clientProfile';
import { genarateDFSClientId } from 'utils';

export const useConfirmationLayout = () => {
    const { initHeaderState } = useLayout();
    const { t } = useTranslation();
    // Client Provider
    const { clientName, clientId } = useClientProfile();
    const dfsClientId = genarateDFSClientId(clientId);

    const confirmClose = useCallback(() => {
        Modal.confirm({
            title: t('confirm.closeProcess'),
            content: t('confirm.closeModalText'),
            okText: t('clientDashboard.yes'),
            onOk: () => history.push(`/client/${dfsClientId}/dashboard`),
            cancelText: t('clientDashboard.no'),
            className: 'close-client-modal',
        });
    }, [dfsClientId, t]);
    const confirmContinueLater = useCallback(() => {
        Modal.confirm({
            title: t('confirm.continueLater'),
            content: t('confirm.continueModalText'),
            okText: t('clientDashboard.yes'),
            onOk: () => history.push(`/client/${dfsClientId}/dashboard`),
            cancelText: t('clientDashboard.no'),
            className: 'close-client-modal',
        });
    }, [dfsClientId, t]);

    useEffect(() => {
        initHeaderState({
            clientName,
            dfsClientId,
            showClientTitle: true,
            showTopNavigation: true,
            leftButton: { type: 'close', label: t('confirm.closeProcess') },
            onLeftButtonClick: confirmClose,
            rightButton: { type: 'delay', label: t('confirm.continueLater') },
            onRightButtonClick: confirmContinueLater,
        });
    }, [clientName, dfsClientId, initHeaderState, confirmClose, confirmContinueLater, t]);
};
