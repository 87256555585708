import React, {
    useState, useMemo, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import { PAGE_SIZE } from 'constants/constants';
import { AddPosition } from 'templates/AddPosition';
import history from 'services/history';
import { Infobox } from 'ui-library';
import { useSecuritiesSearch } from 'hooks/useSecuritiesSearch';
import { adaptSecurities } from 'adaptors/adaptSecurities';
import { getClientId } from 'utils';
import { useForm } from 'react-hook-form';
import { useRatings } from 'hooks/useRatings';
import { getSecuritiesColumns } from '../../constants';
import {
    usePagination,
    useSecurityTypes,
    useCurrencies,
} from './hooks';
import { useReviewData } from '../../hooks/useReviewData';
import OnBoardingBaseTemplate from '../../components/OnBoardingBaseTemplate';
import './AddPosition.css';

function AddPositionPage({
    onPageChange, contactId, dfsClientId, currentStep,
}) {
    const clientId = getClientId(dfsClientId);
    const { t, i18n: { language } } = useTranslation();
    const { page, onPaginationChange } = usePagination();
    const [selected, setSelected] = useState();
    const [selectedError, setSelectedError] = useState(false);
    const { data: { productId }, isLoading, error } = useReviewData(contactId);

    const [searchCriteria, setSearchCriteria] = useState({
        CurrencyIds: undefined,
        SearchTerm: undefined,
        SecurityTypeIds: undefined,
        RatingAgencyIds: undefined,
        RatingIds: undefined,
        Page: page,
    });
    const { dataCurrency, isLoadingCurrency, errorCurrency } = useCurrencies();
    const {
        dataSecurityTypes, isLoadingSecurityTypes, errorSecurityTypes,
    } = useSecurityTypes({ withLiquidity: true });

    const {
        dataRatingAgencies, dataRatings, getRatings,
        isLoadingRatingAgencies, errorRatingAgencies, isLoadingRatings,
    } = useRatings();

    const searchOptions = useMemo(() => (searchCriteria.SecurityTypeIds?.length ? {
        ...searchCriteria,
        PageSize: PAGE_SIZE,
        isActive: true,
        isInvestable: true,
        contactId: clientId,
    } : null), [searchCriteria, clientId]);
    const baseUrl = `/onboarding/${dfsClientId}/step/${currentStep}/position-details/`;

    const {
        dataSecurities, totalSecurities, isLoadingSecurities, errorSecurities,
    } = useSecuritiesSearch({
        adaptSecurities: (d) => adaptSecurities(d, { language, baseUrl }),
        productId,
        params: searchOptions,
    });

    const {
        register, handleSubmit, setValue, watch,
    } = useForm();

    useEffect(() => {
        register({ name: 'SearchTerm' });
        register({ name: 'SecurityTypeIds' });
        register({ name: 'CurrencyIds' });
        register({ name: 'RatingAgencyIds' });
        register({ name: 'RatingIds' });
    }, [register]);

    const onChange = useCallback((pagination) => {
        onPaginationChange(pagination);

        if (pagination?.currentPage !== searchCriteria.Page && !isLoadingSecurities) {
            setSearchCriteria((arr) => ({ ...arr, Page: pagination?.currentPage }));
        }
    }, [onPaginationChange, searchCriteria.Page, isLoadingSecurities]);
    const handleFilterSubmit = useCallback((data) => {
        onPaginationChange({ currentPage: 1 });
        setSearchCriteria((arr) => ({
            ...arr,
            ...data,
            RatingAgencyIds: data.RatingAgencyIds ? [+data.RatingAgencyIds] : undefined,
            SecurityTypeIds: data.SecurityTypeIds?.length
                ? data.SecurityTypeIds
                : dataSecurityTypes.map(({ value }) => value),
            Page: 1,
        }));
    }, [onPaginationChange, dataSecurityTypes]);
    const handleSelect = (id) => {
        setSelected(id);
    };
    const handleNext = () => {
        if (selected) {
            history.push(`${baseUrl}${selected}`);
        } else {
            setSelectedError(true);
        }
    };

    useEffect(() => {
        if (dataSecurityTypes?.length) {
            setSearchCriteria((arr) => ({
                ...arr, SecurityTypeIds: dataSecurityTypes.map(({ value }) => value),
            }));
        }
    }, [dataSecurityTypes]);
    useEffect(() => {
        if (selected) {
            setSelectedError(false);
        }
    }, [selected]);

    /* Ratings */
    const values = watch();
    const [dataRatingsByAgencyIdsCached, setDataRatingsByAgencyIdsCached] = useState({});
    const ratingAgencyId = values?.RatingAgencyIds;

    useEffect(() => {
        setValue('RatingIds', undefined);
    }, [ratingAgencyId]);

    useEffect(() => {
        if (dataRatings?.length > 0) {
            const obj = dataRatings?.reduce((acc, item) => ({
                ...acc,
                [item?.ratingAgencyId]: dataRatings,
            }), {});

            setDataRatingsByAgencyIdsCached((prev) => ({
                ...prev,
                ...obj,
            }));
        }
    }, [dataRatings]);

    useEffect(() => {
        if (ratingAgencyId && !dataRatingsByAgencyIdsCached[ratingAgencyId]) {
            getRatings(ratingAgencyId);
        }
    }, [ratingAgencyId, getRatings, dataRatingsByAgencyIdsCached]);

    const columns = getSecuritiesColumns(t);

    return (
        <OnBoardingBaseTemplate
            title={t('clientDashboard.portfolioEdit.addPosition')}
            className="goal-review on-boarding_add-position"
            prevButton={{ onClick: () => onPageChange('modify') }}
            nextButton={{ onClick: handleNext }}
        >
            <Preloader
                isLoading={isLoading || isLoadingCurrency
                || isLoadingSecurityTypes || isLoadingRatingAgencies}
                error={error || errorCurrency || errorSecurityTypes || errorRatingAgencies}
            >
                <div className="add-position__content">
                    <AddPosition
                        handleFilterSubmit={handleFilterSubmit}
                        handleSelect={handleSelect}
                        register={register}
                        handleSubmit={handleSubmit}
                        isLoading={isLoadingSecurities}
                        error={errorSecurities}
                        totalSecurities={totalSecurities}
                        page={page}
                        pageSize={PAGE_SIZE}
                        securities={dataSecurities}
                        currencies={dataCurrency}
                        securityTypes={dataSecurityTypes}
                        columns={columns}
                        onChangeTable={onChange}
                        isLoadingRatings={isLoadingRatings}
                        dataRatingAgencies={dataRatingAgencies}
                        dataRatings={dataRatingsByAgencyIdsCached[ratingAgencyId] || []}
                        setValue={setValue}
                        ratingAgencyValue={ratingAgencyId}
                        ratingValue={values?.RatingIds}
                    />
                </div>
            </Preloader>
            {selectedError && (
                <Infobox error className="validation-error">
                    <div>{t('portfolios.validation.addPosition')}</div>
                </Infobox>
            )}
        </OnBoardingBaseTemplate>
    );
}

AddPositionPage.propTypes = {
    contactId: PropTypes.number.isRequired,
    currentStep: PropTypes.number.isRequired,
    dfsClientId: PropTypes.string,
    onPageChange: PropTypes.func,
};

AddPositionPage.defaultProps = {
    onPageChange: () => { },
    dfsClientId: '',
};

export default AddPositionPage;
