import { checkNullData } from 'utils';
import { projectionOptional } from 'constants/constants';
import { getDatesDifference } from '../utils/datetime';

export const findMinAndMax = (charts) => {
    if (checkNullData(charts[0]) || checkNullData(charts[1])) return {};

    const data = charts.map((chart) => chart.map((item) => item.data).flat()).flat();
    const min = Math.min(...data.map((item) => item.low || item.y));
    const max = Math.max(...data.map((item) => item.high || item.y));

    return { min, max };
};

export const adaptProjectionData = (data) => data
    .sort((a, b) => b.ProbabilityPercentage - a.ProbabilityPercentage)
    .map((group) => ({
        data: group.Values.map(({ Date: d, Value, AdditionalValue }) => {
            if (AdditionalValue === undefined) {
                return { x: +new Date(d), y: Value };
            }

            return { x: +new Date(d), low: AdditionalValue, high: Value };
        }),
    }));

export const filteredByEndDate = (data, endDate) => {
    if (endDate) {
        return data
            .map((item) => ({
                ...item,
                Values: item.Values.filter((i) => i.Date
                && getDatesDifference(i.Date, new Date(endDate), 'days') < 1),
            }));
    }

    return data;
};

export const adaptProjection = (
    data,
    strategy,
    {
        t,
        yTitle,
        yAxisFormat,
        tooltipFormat,
        endDate,
        calculateMinMax = true,
    },
) => {
    if (checkNullData(data) || checkNullData(strategy)) {
        return {
            name: t('clientDashboard.portfolio.projection'),
            charts: [],
            optional: projectionOptional(t, yTitle, yAxisFormat, tooltipFormat),
        };
    }

    const charts = [
        adaptProjectionData(filteredByEndDate(data, endDate)),
        adaptProjectionData(filteredByEndDate(strategy, endDate)),
    ];

    const minMaxParams = calculateMinMax ? findMinAndMax(charts) : {};

    return {
        title: t('clientDashboard.portfolio.projection'),
        charts,
        optional: {
            ...projectionOptional(t, yTitle, yAxisFormat, tooltipFormat),
            ...minMaxParams,
        },
    };
};
