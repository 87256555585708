import { useCallback, useEffect, useState } from 'react';
import ServiceManager from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';

export const useWithMemberProfiles = (memberIds) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const getMemberProfiles = useCallback(async () => {
        setLoading(true);

        try {
            const memberProfile = await Promise.all((memberIds || [])?.map(
                async (Id) => ({
                    ...(await ServiceManager.contactManagement('getMembersProfile', [Id])).data,
                }),
            ));

            setData(memberProfile);
            setLoading(false);

            return memberProfile;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [memberIds]);

    useEffect(() => {
        getMemberProfiles();
    }, [getMemberProfiles]);

    return { data, isLoading, error };
};
