import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import update from 'lodash/update';
import { FileInput, Infobox } from 'ui-library';
import FormIo from 'components/FormIo';
import AdapterError from 'errors/AdapterError';
import { adaptFiles } from 'adaptors/adaptFiles';
import WidgetError from 'components/WidgetError';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { useDynamicCRM } from '../../hooks/useDynamicCRM';
import { useAcceptGoal } from '../../../../hooks/useAcceptGoal';
import './TaxInformation.css';

function TaxInformation(props) {
    const {
        onPageChange, onNext, contactId,
    } = props;
    const { t } = useTranslation();
    const [formio, setFormio] = useState();
    const [isValid, setIsValid] = useState(true);
    const [submissionData, setSubmissionData] = useState({});
    const [files, setFiles] = useState([null, null]);

    // Helper hooks
    const {
        data: schema, submission, isLoading, error,
        errorSave, isSaving, saveData,
        isUploading, errorUpload, uploadFiles,
    } = useDynamicCRM({ contactId, selectors: ['taxSituation', 'sourceOfWealth'] });
    const {
        onAcceptGoal,
        isLoadingAccept: isLoadingAC,
        errorAccept: errorAC, isLoading: isLoadingGoalSummary,
    } = useAcceptGoal();

    // Data
    const submissionMemo = useMemo(() => ({ data: submission }), [submission]);

    // Callbacks
    const onFileChange = useCallback((idxToStore) => async (filesToStore) => {
        if (filesToStore.length === 0) {
            setFiles((arr) => arr.map((item, idx) => (idx !== idxToStore ? item : null)));

            return;
        }

        try {
            const fileParams = await adaptFiles(contactId, filesToStore, true);

            setFiles((arr) => arr.map((item, idx) => (idx !== idxToStore ? item : fileParams)));
        } catch (err) {
            throw new AdapterError(err);
        }
    }, [contactId]);
    const onChange = useCallback(({ isValid: isValidForm }, { changes }) => {
        (changes || []).forEach((change) => {
            if (!change?.component?.disabled && !change?.flags?.noValidate) {
                setSubmissionData((obj) => update(
                    obj,
                    change.instance.path,
                    () => change.value,
                ));
            }
        });
        setIsValid(isValidForm);
    }, []);
    const onNextClick = useCallback(async () => {
        await formio.submit();
        await onAcceptGoal();

        if (!isValid) return;

        await Promise.all([uploadFiles(files.flat()), saveData(submissionData)]);
        onNext();
    }, [files, formio, isValid, onNext, saveData, submissionData, uploadFiles, onAcceptGoal]);

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.taxInformation')}
            error={error}
            isLoading={isLoading || isLoadingGoalSummary}
            classNameContent="tax-information"
            prevButton={{
                loading: isSaving || isUploading,
                onClick: () => onPageChange('professional'),
            }}
            nextButton={{
                loading: isSaving || isUploading || isLoadingAC,
                text: t('onBoarding.continue'),
                onClick: onNextClick,
            }}
            buttonsRowError={(
                <>
                    {errorSave && (<Infobox error>{errorSave.message}</Infobox>)}
                    {errorUpload && (<Infobox error>{errorUpload.message}</Infobox>)}
                    {errorAC && <WidgetError accent error message={errorAC.message} />}
                </>
            )}
        >
            <FormIo
                form={schema}
                submission={submissionMemo}
                options={{
                    readOnly: false,
                    noAlerts: false,
                    renderMode: 'HWM',
                }}
                onChange={onChange}
                formReady={setFormio}
            />
            <div className="tax-information__upload">
                <FileInput
                    multiple
                    label={t('onBoarding.taxSituationDocument')}
                    accept=".pdf, .docx, .doc"
                    uploadText={t('advisoryDashboard.documents.selectFile')}
                    onChange={onFileChange(0)}
                    disabled={isUploading}
                />
                <FileInput
                    multiple
                    label={t('onBoarding.sourceOfWealthDocument')}
                    accept=".pdf, .docx, .doc"
                    uploadText={t('advisoryDashboard.documents.selectFile')}
                    onChange={onFileChange(1)}
                    disabled={isUploading}
                />
            </div>
        </OnBoardingBaseTemplate>
    );
}

TaxInformation.propTypes = {
    contactId: PropTypes.string.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
};

TaxInformation.defaultProps = {
};

export default TaxInformation;
