import React, {
    useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Infobox } from 'ui-library';
import {
    goalCreationSelector,
    goalSummarySelector,
    onBoardingDataSelector,
    useOnBoardingSelector,
    useStage,
} from 'domain/OnBoarding';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import './SetupPortfolio.css';
import ExoForm from '../../component/ExoForm';

function SetupPortfolio(props) {
    const { onPrev, onPageChange, contactId: clientId } = props;
    const { t } = useTranslation();
    const [formState, setFormState] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    // OnBoarding Domain
    const {
        product, saveGoalDetails, createdProposalId,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        updateProposal, isLoadingUpdate, errorUpdate,
    } = useOnBoardingSelector(goalSummarySelector);
    const {
        dataCurrencies, isLoadingCurrencies, errorCurrencies, getCurrencyCodes,
    } = useOnBoardingSelector(goalCreationSelector);

    // OnBoarding stages
    const { setClientStage } = useStage({ clientId });

    // Effects
    useEffect(() => {
        getCurrencyCodes({ productId: +product?.id });
    }, [getCurrencyCodes, product.id]);

    // Callbacks
    const onFormChange = useCallback((data) => {
        setFormState(data);
        setIsSubmitted(false);
    }, []);
    const onProposalUpdate = useCallback(async () => {
        const { goalName, initialInvestment, selectedCurrency } = formState.goalState || {};
        const params = {
            Name: {
                DoUpdate: true,
                Value: goalName,
            },
            CurrencyId: {
                DoUpdate: true,
                Value: selectedCurrency?.value,
            },
            RecommendedInvestment: {
                DoUpdate: true,
                Value: Number.parseFloat(initialInvestment),
            },
        };

        await updateProposal(createdProposalId, params);
    }, [createdProposalId, formState.goalState, updateProposal]);
    const onSubmit = (params) => {
        saveGoalDetails({ ...params });
        onPageChange('modify');
    };
    const onNextClick = useCallback(async () => {
        setIsSubmitted(true);
        await onProposalUpdate();
        await setClientStage(4);
        saveGoalDetails({ ...formState.goalState });
    }, [formState.goalState, onProposalUpdate, saveGoalDetails, setClientStage]);

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.portfolioSetup')}
            error={errorCurrencies}
            isLoading={isLoadingCurrencies}
            className="setup-portfolio"
            prevButton={{
                loading: isLoadingUpdate,
                onClick: onPrev,
            }}
            nextButton={{
                loading: isLoadingUpdate,
                onClick: onNextClick,
            }}
            buttonsRowError={(
                <>
                    {errorUpdate && <Infobox error>{errorUpdate?.message}</Infobox>}
                </>
            )}
        >
            <ExoForm
                dataCurrencies={dataCurrencies}
                isSubmitted={isSubmitted}
                productId={+product?.id}
                onChange={onFormChange}
                onSubmit={onSubmit}
            />
        </OnBoardingBaseTemplate>
    );
}

SetupPortfolio.propTypes = {
    onPrev: PropTypes.func,
    onPageChange: PropTypes.func,
    contactId: PropTypes.string.isRequired,
};

SetupPortfolio.defaultProps = {
    onPrev: () => {},
    onPageChange: () => {},
};

export default SetupPortfolio;
