import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Title, ContentBox, Tabs, TabsExtraItem, TabPane,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import { allocationPercentFormat, formatCurrencyWithPrecision } from 'utils/formatting';
import { PIE_SA, BAR, LIST } from 'constants/constants';
import ChartType from 'components/ChartType';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import Draggable from 'components/Draggable/Draggable';

function InvestmentAllocation({
    data, isLoading, error, drop,
}) {
    const { t, i18n } = useTranslation();
    const [chartView, setChartView] = useState(PIE_SA);
    const changeView = (type) => () => setChartView(type);
    const numberFormat = (number) => formatCurrencyWithPrecision(number, 0, data.currency);
    const labelFormat = (label, value) => `<label class="Form-control-label"><span>${label}</span></label>${numberFormat(value?.y || value)}`;
    const titleComponent = useMemo(() => {
        if (drop) {
            return (
                <Draggable {...drop}>
                    <Title type={2}>{t('advisoryDashboard.investmentAllocation.title')}</Title>
                </Draggable>
            );
        }

        return <Title type={2}>{t('advisoryDashboard.investmentAllocation.title')}</Title>;
    }, [drop, i18n.language]);

    return (
        <PageSection>
            <ContentBox className="investment-allocation investment-allocation-full table-without-last-row-padding" underline={false}>
                {titleComponent}
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data.chart} text={t('advisoryDashboard.investmentAllocation.notFount')}>
                        <Tabs
                            defaultActiveKey="1"
                            tabBarExtraContent={(
                                <>
                                    <TabsExtraItem
                                        icon="chart-pie"
                                        isActive={chartView === PIE_SA}
                                        onClick={changeView(PIE_SA)}
                                    />
                                    <TabsExtraItem
                                        icon="chart-bar-horizontal"
                                        isActive={chartView === BAR}
                                        onClick={changeView(BAR)}
                                    />
                                    <TabsExtraItem
                                        icon="list"
                                        isActive={chartView === LIST}
                                        onClick={changeView(LIST)}
                                    />
                                </>
                            )}
                        >
                            {
                                data.chart.map((item, i) => (
                                    <TabPane tab={item.title} key={(i + 1).toString()}>
                                        <EmptyContent
                                            key={`allocation-${(i + 1).toString()}`}
                                            data={item.data[chartView].data}
                                            text={t('advisoryDashboard.noData')}
                                        >
                                            <ChartType
                                                data={item.data[chartView].data}
                                                title={item.title}
                                                labels={item.data[chartView].labels}
                                                currency={data.currency}
                                                chartView={chartView}
                                                labelFormat={labelFormat}
                                                numberFormat={numberFormat}
                                                percentFormat={allocationPercentFormat}
                                            />
                                        </EmptyContent>
                                    </TabPane>
                                ))
                            }
                        </Tabs>
                    </EmptyContent>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

InvestmentAllocation.propTypes = {
    data: PropTypes.shape({
        chart: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            data: PropTypes.shape({
                [PropTypes.string]: PropTypes.arrayOf(PropTypes.shape({
                    name: PropTypes.string,
                    value: PropTypes.number,
                })),
            }),
        })),
        currency: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    drop: PropTypes.shape({
        role: PropTypes.string,
        tabIndex: PropTypes.number,
        onDragStart: PropTypes.func,
    }),
};

InvestmentAllocation.defaultProps = {
    data: { chart: [] },
    isLoading: true,
    error: null,
    drop: null,
};

export default InvestmentAllocation;
