import { useEffect } from 'react';
import { useLayout } from 'prodivers/layout';
import { useTranslation } from 'react-i18next';
import LogoWithClientName from 'components/LogoWithClientName';
import { useClientProfile } from 'prodivers/clientProfile';
import history from 'services/history';
import { genarateDFSClientId } from 'utils';

export const useClientLayout = () => {
    const { initHeaderState } = useLayout();
    const { t } = useTranslation();
    // Client Provider
    const { clientId, clientName, isProspect } = useClientProfile();
    const dfsClientId = genarateDFSClientId(clientId);

    useEffect(() => {
        initHeaderState({
            clientName,
            dfsClientId,
            link: null,
            content: LogoWithClientName,
            contentProps: { clientName, dfsClientId, isLogoClickable: true },
            showClientTitle: true,
            showTopNavigation: true,
            leftButton: { type: 'login', label: `${t('clientDashboard.close')} ${clientName}` },
            onLeftButtonClick: () => {
                history.push(`/clients${isProspect ? '?status=Prospects' : ''}`);
            },
            rightButton: '',
            onRightButtonClick: null,
        });
    }, [clientName, isProspect, dfsClientId, initHeaderState, t]);
};
