import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    chartValueFormatCurrency,
    chartValueFormatPercent,
    formatNumberRounding,
} from 'utils/formatting';
import PropTypes from 'prop-types';
import { investorPerformanceSelectVlaues, YTD } from 'filters/investementPerformanceFilters';
import { useFormatting } from 'locale';
import {
    LineChart, TabPane, Tabs, TabsExtraItem, Title, ContentBox,
} from 'ui-library';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import CustomRangeSelect from 'components/CustomRangeSelect';
import { lineChartLabels } from 'constants/constants';
import PageSection from 'components/PageSection';
import { useWithAssetDevelopment } from '../hooks/useWithAssetDevelopment';

const chartValueFormat = {
    performance: chartValueFormatPercent,
    assets: chartValueFormatCurrency,
};

const filterNamespace = 'clientDashboard.assetDevelopment.filters';
const getInvestorPerformanceSelectOptions = (t) => (
    investorPerformanceSelectVlaues.map((item) => ({
        ...item,
        label: t(`${filterNamespace}.${item.value}`),
    }))
);

const AssetDevelopment = ({ clientId, currency }) => {
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedXAxisFormat } = useFormatting();
    const [currentFilter, setCurrentFilter] = useState(YTD);

    // Hooks
    const { data, isLoading, error } = useWithAssetDevelopment(currentFilter, clientId, currency);

    // Helpers
    const yAxisFormat = useMemo(() => ({
        performance: ({ value }) => `${getFormattedNumber(value)}%`,
        assets: ({ value }) => formatNumberRounding(value, getFormattedNumber),
    }), [getFormattedNumber]);
    const onFilterChange = useCallback((value) => {
        setCurrentFilter(value);
    }, []);
    const filters = useMemo(() => getInvestorPerformanceSelectOptions(t), [t]);

    // Renderers
    const renderTabs = useMemo(() => (
        <Tabs
            defaultActiveKey="1"
            style={{ overflow: 'visible' }}
            tabBarExtraContent={(
                <TabsExtraItem>
                    <CustomRangeSelect
                        onChange={onFilterChange}
                        options={filters}
                        width={160}
                        value={currentFilter}
                    />
                </TabsExtraItem>
            )}
        >
            <TabPane tab={t('clientDashboard.assetDevelopment.tabs.performance')} key="1">
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent
                        data={data.performance?.[0].data}
                        text={t('advisoryDashboard.noData')}
                    >
                        <LineChart
                            data={data.performance}
                            valueFormat={chartValueFormat.performance}
                            height={300}
                            yAxisFormat={yAxisFormat.performance}
                            xAxisFormat={getFormattedXAxisFormat}
                            tooltipFormat={data.performance?.[0].tooltipFormat}
                            labels={lineChartLabels(t)}
                        />
                    </EmptyContent>
                </Preloader>
            </TabPane>
            <TabPane tab={t('clientDashboard.assetDevelopment.tabs.assets')} key="2">
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent
                        data={data.assets?.[0].data}
                        text={t('advisoryDashboard.noData')}
                    >
                        <LineChart
                            data={data.assets}
                            height={300}
                            yTitle={currency}
                            valueFormat={chartValueFormat.assets}
                            yAxisFormat={yAxisFormat.assets}
                            xAxisFormat={getFormattedXAxisFormat}
                            tooltipFormat={data.assets?.[0].tooltipFormat}
                            labels={lineChartLabels(t)}
                        />
                    </EmptyContent>
                </Preloader>
            </TabPane>
        </Tabs>
    ), [
        currency,
        currentFilter,
        data,
        error,
        filters,
        getFormattedXAxisFormat,
        isLoading,
        onFilterChange,
        t,
        yAxisFormat,
    ]);

    return (
        <PageSection>
            <ContentBox underline={false} className="asset-development">
                <Title type={2}>{t('clientDashboard.assetDevelopment.title')}</Title>
                {renderTabs}
            </ContentBox>
        </PageSection>
    );
};

AssetDevelopment.propTypes = {
    clientId: PropTypes.number.isRequired,
    currency: PropTypes.string,
};

AssetDevelopment.defaultProps = {
    currency: null,
};

export default React.memo(AssetDevelopment);
