export const adaptSecuritiesForSave = (
    groupedPositions,
    IsNotAdvised = false,
) => groupedPositions.map((group) => (
    group.children.map(({ securityId, new: { value } }) => (
        {
            InstrumentId: securityId,
            Allocation: value / 100,
            IsNotAdvised,
        }
    ))))
    .flat()
    .filter((i) => i.Allocation > 0);
