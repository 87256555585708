import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import {
    ContentBox, SkeletonLine, Table, Title,
} from 'ui-library';
import { genarateDFSClientId } from 'utils';
import Draggable from 'components/Draggable/Draggable';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useCustomRefHeightResizable } from 'hooks/useCustomRefHeightResizable';
import { nextUp } from '../../constants/constants';
import WidgetResize from '../../../../components/WidgetResize';
import { useDashboardConfig } from '../../hooks/useDashboardConfig';

import './NextUp.css';

const NextUpSkeleton = () => (
    <div className="NextUpSkeleton">
        <div className="next-up-skeleton-content">
            <SkeletonLine width={220} />
            <SkeletonLine width={80} />
            <SkeletonLine width={180} />
        </div>
    </div>
);

function NextUp({
    data, isLoading, error, drop,
}) {
    const [isLimited, setLimit] = useState(false);
    const { t } = useTranslation();
    const { elemHeight, getHeight } = useCustomRefHeightResizable('table tbody');
    const { widgetSize, saveWidgetSize } = useDashboardConfig('NextUp');

    const onRowClick = ({ contactId, clientName }) => ({
        onClick: () => {
            if (contactId) history.push(`/client/${genarateDFSClientId(contactId)}`, { clientName });
        },
    });

    const nextUpData = useMemo(() => data.slice(0, widgetSize), [data, widgetSize]);

    return (
        <PageSection className="next-up">
            <ContentBox className={`resize-widget ${isLimited ? 'limited-size' : ''}`}>
                <Draggable {...drop}>
                    <Title type={2}>{t('advisoryDashboard.nextUp.title')}</Title>
                </Draggable>

                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data} text={t('advisoryDashboard.noData')}>
                        <WidgetResize
                            onLimited={setLimit}
                            widgetSize={data.length < widgetSize ? data.length : widgetSize}
                            setWidgetSize={saveWidgetSize}
                            dataLength={data.length}
                            elementHeight={elemHeight}
                            SkeletonComponent={NextUpSkeleton}
                        >
                            <div ref={getHeight}>
                                <Table
                                    data={nextUpData}
                                    columns={nextUp}
                                    onRow={onRowClick}
                                    noHeader
                                />
                            </div>
                        </WidgetResize>
                    </EmptyContent>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

NextUp.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            ticket: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                time: PropTypes.string,
                color: PropTypes.string,
            }),
        }),
    ),
    isLoading: PropTypes.bool,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    drop: PropTypes.shape({
        role: PropTypes.string,
        tabIndex: PropTypes.number,
        onDragStart: PropTypes.func,
    }),
};

NextUp.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
    drop: null,
};

export default React.memo(NextUp);
