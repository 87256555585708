import { isEqual } from 'lodash/fp';

export const adaptPlotsAmount = (data) => {
    const PLOTS_MAX_COUNT = 500;
    const { length } = data;

    if (length <= PLOTS_MAX_COUNT) return data;

    const delta = Math.ceil(length / PLOTS_MAX_COUNT);
    const selected = [];

    for (let i = 0; i < length; i += delta) {
        selected.push(data[i]);
    }

    const last = data.slice(-1)[0];
    const selectedLast = selected.slice(-1)[0];

    if (!isEqual(last, selectedLast)) selected.push(last);

    return selected;
};
