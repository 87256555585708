import React from 'react';
import {
    Tabs, TabPane, ContentBox, Title,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import { withMainLayout } from 'hocs';
import Preloader from 'components/Preloader';
import PageSection from '../../components/PageSection';
import { useSurfly } from './useSurfly';
import './Surfly.css';

const Surfly = () => {
    const {
        startUrl,
        queueUrl,
        historyUrl,
        isLoading,
        error,
    } = useSurfly();
    const { t } = useTranslation();

    return (
        <PageSection className="support-page">
            <ContentBox>
                <Title type={2}>{t('navigation.sideNav.support')}</Title>
                <Preloader isLoading={isLoading} error={error}>
                    <Tabs className="Tabs-bar" defaultActiveKey="1">
                        <TabPane tab={t('surfly.tabStart')} key="1" isActive>
                            <div className="content-wrapper">
                                <div className="Surfly">
                                    <iframe
                                        className="frame start"
                                        title="Co-browsing"
                                        allow="camera;microphone"
                                        src={startUrl}
                                    />
                                </div>
                            </div>
                        </TabPane>

                        <TabPane tab={t('surfly.tabQueue')} key="2">
                            <div className="Surfly content-wrapper">
                                <iframe
                                    className="frame queue"
                                    title="Queue"
                                    allow="camera;microphone"
                                    src={queueUrl}
                                />
                            </div>
                        </TabPane>

                        <TabPane tab={t('surfly.tabHistory')} key="3">
                            <div className="Surfly content-wrapper">
                                <iframe
                                    className="frame history"
                                    title="History"
                                    allow="camera;microphone"
                                    src={historyUrl}
                                />
                            </div>
                        </TabPane>
                    </Tabs>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
};

export default withMainLayout(Surfly);
