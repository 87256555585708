import { useEffect, useState } from 'react';

export const useOnSubmit = (submit, sendData, formData, isValid, data) => {
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (submit) {
            sendData(formData, isValid);
            setShowError(true);
        }
    }, [submit]);

    useEffect(() => {
        setShowError(false);
    }, [data]);

    return showError;
};
