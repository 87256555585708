import { useEffect, useState } from 'react';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import { useAllContacts } from 'hooks/useAllContacts';
import { adaptOverviewClients } from 'adaptors/adaptOverviewClients';
import handlerRequestCanceling from '../utils/handlerRequestCanceling';


export const useOverviewClients = () => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({ value: '', list: [] });
    const {
        contactsRawData,
        isLoadingContacts,
        contactsError,
    } = useAllContacts();


    useEffect(() => {
        setLoading(true);

        if (contactsError) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            );
        }

        if (!isLoadingContacts && contactsRawData) {
            try {
                setData(
                    adaptOverviewClients({
                        TotalCount: contactsRawData.contacts.totalCount,
                        Contacts: contactsRawData.contacts.items,
                    }),
                );
                setLoading(false);
            } catch (err) {
                throw new AdapterError(err);
            }
        }
    }, [contactsRawData, isLoadingContacts, contactsError]);

    return { data, isLoading, error };
};
