import { PortfolioDataSource } from '@additiv-data-sources/portfolio';
import { ModelPortfolioDataSource } from '@additiv-data-sources/model-portfolio';

import container from '@additiv-modularity/di-container';

container
    .bind<PortfolioDataSource>(Symbol.for('PortfolioDataSource'))
    .toConstantValue(new PortfolioDataSource());

container
    .bind<ModelPortfolioDataSource>(Symbol.for('ModelPortfolioDataSource'))
    .toConstantValue(new ModelPortfolioDataSource());
