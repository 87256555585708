import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Infobox, Paragraph, Title } from 'ui-library';
import history from 'services/history';
import EmptyContent from 'components/EmptyContent';
import {
    chosenRiskSelector,
    formDataSelector,
    questionnaireSelector,
    riskCategoriesSelector,
    useRiskProfileSelector,
} from 'domain/RiskProfile';
import ChangeRiskModal from 'components/ChangeRiskModal';
import ReviewSection from 'components/ReviewSection';
import { genarateDFSClientId } from 'utils';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import './RiskProfileReview.css';

const SET_RISK_TIMEOUT = 1000;

const RiskProfileReview = (props) => {
    const { contactId: clientId, onNext } = props;
    const { t } = useTranslation();
    const [risksSelected, setRiskSelected] = useState();
    const [showRiskChangingModal, setShowRiskChangingModal] = useState(false);
    // OnBoarding Domain
    const {
        setRiskCategory,
    } = useOnBoardingSelector(onBoardingDataSelector);

    // Risks Profile Domain
    const {
        data, isLoading, error, getQuestionnaire,
        dataCalculate, isLoadingCalculate, errorCalculate, calculateRisk,
    } = useRiskProfileSelector(questionnaireSelector);
    const {
        data: dataRisks, isLoading: isLoadingRisks, error: errorRisks,
        getRiskCategories,
    } = useRiskProfileSelector(riskCategoriesSelector);
    const {
        selectedRisk: selectedRiskSS, saveSelectedRisk, clearSelectedRisk,
        clearData,
    } = useRiskProfileSelector(formDataSelector);
    const {
        isLoading: isSending, error: errorSetChosenRisk, setChosenRisk,
    } = useRiskProfileSelector(chosenRiskSelector);

    // Data
    const riskObj = useMemo(() => (dataRisks || [])
        .reduce((acc, risk) => ({ ...acc, [risk.Id]: risk }), {}), [dataRisks]);
    const maxAvailableRisk = useMemo(() => Math.max(1, dataCalculate?.RiskCategory?.Id),
        [dataCalculate]);
    const availableRisks = useMemo(() => (dataRisks || [])
        .filter(({ Id }) => Id <= maxAvailableRisk), [dataRisks, maxAvailableRisk]);

    // Callbacks
    const onRedo = (idx) => {
        history.push(`/onboarding/${genarateDFSClientId(clientId)}/step/2/${idx}`, { redoStep: true });
    };
    const onChangeRisk = () => {
        setShowRiskChangingModal(true);
    };
    const onChangeRiskConfirm = (currentRisk) => {
        saveSelectedRisk(currentRisk || risksSelected);
        setShowRiskChangingModal(false);
    };
    const onChangeRiskCancel = () => {
        setShowRiskChangingModal(false);
    };
    const onFinishClick = useCallback(async () => {
        const { Id, Name } = riskObj?.[risksSelected];

        await setRiskCategory({ Id, Name });

        setTimeout(async () => {
            await setChosenRisk(risksSelected);
        }, SET_RISK_TIMEOUT);

        clearSelectedRisk();
        clearData();
        onNext();
    }, [
        riskObj,
        clearData,
        clearSelectedRisk,
        onNext,
        risksSelected,
        setChosenRisk,
        setRiskCategory,
    ]);

    // Effects
    useEffect(() => {
        global.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    useEffect(() => {
        if (!data) getQuestionnaire();
    }, [data, getQuestionnaire]);
    useEffect(() => {
        calculateRisk();
    }, [calculateRisk]);
    useEffect(() => {
        getRiskCategories();
    }, [getRiskCategories]);
    useEffect(() => {
        if (selectedRiskSS && dataCalculate?.RiskCategory?.Id < selectedRiskSS) {
            setRiskSelected(dataCalculate?.RiskCategory?.Id);
        } else {
            setRiskSelected(selectedRiskSS || dataCalculate?.RiskCategory?.Id);
        }
    }, [selectedRiskSS, dataCalculate]);

    return (
        <OnBoardingBaseTemplate
            isLoading={isLoading || isLoadingCalculate || isLoadingRisks}
            error={error || errorCalculate || errorRisks}
            title={t('onBoarding.riskProfileReview')}
            className="risk-profile-container"
            nextButton={{
                text: t('confirmation.continue'),
                loading: isSending,
                onClick: onFinishClick,
            }}
            buttonsRowError={errorSetChosenRisk && (
                <Infobox accent>{errorSetChosenRisk.message}</Infobox>
            )}
        >
            <EmptyContent data={data} text={t('advisoryDashboard.noData')}>
                <div className="stepper-content-review risk-profile-review">
                    <div className="header-with-description">
                        <Title type={2}>{data?.Name}</Title>
                        <Paragraph type="secondary">{t('riskProfileReview.riskProfile.description')}</Paragraph>
                    </div>
                    <ChangeRiskModal
                        visible={showRiskChangingModal}
                        defaultRisk={dataCalculate?.RiskCategory?.Id}
                        selectedRisk={risksSelected}
                        availableRisks={availableRisks}
                        onChangeRisk={setRiskSelected}
                        onOk={onChangeRiskConfirm}
                        onCancel={onChangeRiskCancel}
                    />
                    {(dataCalculate?.Groups || []).map((group, idx) => (
                        <ReviewSection
                            title={group.Name}
                            description={riskObj?.[group?.RiskCategory?.Id]?.Description}
                            riskCategoryId={group?.RiskCategory?.Id}
                            riskCategoryName={group?.RiskCategory?.Name}
                            onRedo={() => onRedo(idx)}
                        />
                    ))}
                    <ReviewSection
                        title={t('onBoarding.riskProfile')}
                        description={riskObj?.[risksSelected]?.Description}
                        riskCategoryId={risksSelected}
                        riskCategoryName={riskObj?.[risksSelected]?.Name}
                        onChangeRisk={onChangeRisk}
                    />
                </div>
            </EmptyContent>
        </OnBoardingBaseTemplate>
    );
};

RiskProfileReview.propTypes = {
    contactId: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
};

RiskProfileReview.defaultProps = {
};

export default RiskProfileReview;
