import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import ServerError from 'errors/ServerError';

export const useTypesAndTopicsInteraction = (loadInitially) => {
    const { i18n } = useTranslation();
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(loadInitially || false);
    const [langTypes, setLangTypes] = useState(i18n.language);
    const [types, setTypes] = useState([]);
    const [langTopics, setLangTopics] = useState(i18n.language);
    const [topics, setTopics] = useState([]);

    const getTypes = useCallback(() => new Promise((resolve, reject) => {
        if (types.length && langTypes === i18n.language) {
            resolve(types);

            return;
        }
        ServiceManager.contactInteraction('getInteractionTypes', [i18n.language])
            .then((response) => {
                setLangTypes(i18n.language);
                setTypes(response.data);
                resolve(response.data);
            }).catch((err) => {
                setError(new ServerError(err));
                reject(err);
            });
    }), [i18n.language, langTypes, setLangTypes, types, setTypes]);

    const getTopics = useCallback(() => new Promise((resolve, reject) => {
        if (topics.length && langTopics === i18n.language) {
            resolve(topics);

            return;
        }
        ServiceManager.contactInteraction('getInteractionTopics', [i18n.language])
            .then((response) => {
                setLangTopics(i18n.language);
                setTopics(response.data);
                resolve(response.data);
            }).catch((err) => {
                setError(new ServerError(err));
                reject(err);
            });
    }), [i18n.language, langTopics, setLangTopics, topics, setTopics]);

    useEffect(() => {
        if (loadInitially) {
            setError(null);
            setLoading(true);
            (async () => {
                await getTypes();
                await getTopics();
                setLoading(false);
            })();
        }
    }, [i18n.language]);

    return {
        getTypes, getTopics, isLoading, types, topics, error,
    };
};
