import history from 'services/history';
import { LIQUIDITY_TYPE } from 'constants/constants';
import { getfromDataObject, validateNumber } from 'utils/formatting';
import { BondId, StructuredProductId } from 'constants/instrument';

export const isLiquidity = (item) => getfromDataObject(item, 'Type.Name') === LIQUIDITY_TYPE;

export const redirectToReadOnlyPositionDetails = (baseUrl, positionId, tabId) => history.push(`${baseUrl}/${positionId}/${tabId}/overview`);

export const onPreventRedirect = (e, url, onCancel) => {
    e.preventDefault();
    e.stopPropagation();

    onCancel(url);
};

export const adjustPrice = (price, { IsPricePercentage, Factor, Type }) => {
    let calculatedPrice = validateNumber(price)
    * (0.01 ** +(IsPricePercentage || 0)) * (Factor || 1);

    if ([BondId, StructuredProductId].includes(Type.Id)) {
        calculatedPrice *= 100;
    }

    return calculatedPrice;
};
