import { useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { adaptConstraints } from '../../../adaptors/adaptConstraints';

const initialState = {
    isCreatingProposal: false,
    errorCreatingProposal: null,
};

function reducer(state, action) {
    switch (action.type) {
        case 'setErr':
            return {
                ...state,
                errorCreatingProposal: action.payload.error,
            };
        case 'setIsCreatingProposal':
            return {
                ...state,
                isCreatingProposal: action.payload.isCreatingProposal,
            };

        default:
            return state;
    }
}

export const useSaveProposals = () => {
    const {
        getGoalDetails,
        product,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const goalDetails = getGoalDetails();
    const { i18n } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);

    const setError = (err) => {
        dispatch({
            type: 'setErr',
            payload: { error: err },
        });
    };
    const setLoading = (loading) => {
        dispatch({
            type: 'setIsCreatingProposal',
            payload: { isCreatingProposal: loading },
        });
    };


    const saveProposal = useCallback(async (contactId, contractData, InstrumentAllocations) => {
        setLoading(true);
        setError(null);
        const params = {
            Name: goalDetails?.goalName,
            RecommendedInvestment: +goalDetails?.initialInvestment,
            ProductId: +product.id,
            CurrencyId: goalDetails?.selectedCurrency?.value,
            OptimizationConstraints: adaptConstraints(contractData?.constraints),
        };

        if (contractData.modelPortfolioId) params.ModelPortfolioId = contractData.modelPortfolioId;
        else params.InstrumentAllocations = InstrumentAllocations;

        return ServiceManager.portfolioManagement('postPortfolioProposal', [contactId, i18n.language, params, {}])
            .then(async ({ data }) => {
                try {
                    setLoading(false);

                    return { createdProposalId: data?.Id };
                } catch (err) {
                    setLoading(false);
                    setError(err);
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            ));
    }, [i18n.language]);

    return { saveProposal, ...state };
};
