import { useEffect, useState } from 'react';
import AdapterError from 'errors/AdapterError';
import { adaptAnswers } from '../adapters/adaptAnswers';

export const usePostQuestionnaires = (current, steps, answers) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [risks, setRisks] = useState([]);

    useEffect(() => {
        if (current === 2) {
            setLoading(true);

            try {
                setRisks(adaptAnswers(steps, answers));
                setLoading(false);
            } catch (err) {
                setError(new AdapterError(err));
            }
        }
    }, [current, answers]);

    return {
        risks, isLoading, error,
    };
};
