import { getDaysOfYear } from 'utils/datetime';

export const YTD = 'YTD';
export const YEARS1 = 'YEARS1';
export const YEARS3 = 'YEARS3';
export const YEARS5 = 'YEARS5';
export const SINCE_INCEPTION = 'SinceInception';
export const CUSTOM_RANGE = 'CustomRange';

export const investorPerformanceSelectVlaues = [
    { value: YTD },
    { value: YEARS1 },
    { value: YEARS3 },
    { value: YEARS5 },
    { value: SINCE_INCEPTION },
];

export const investorPerformanceSelectValues = [
    { value: YTD },
    { value: YEARS1 },
    { value: YEARS3 },
    { value: YEARS5 },
    { value: SINCE_INCEPTION },
];

export const investorProposedPerformanceSelectValues = [
    { value: YTD },
    { value: YEARS1 },
    { value: YEARS3 },
    { value: YEARS5 },
];

export const filteredDataMap = {
    [YTD]: [undefined, getDaysOfYear(new Date())],
    [YEARS1]: [1, undefined],
    [YEARS3]: [3, undefined],
    [YEARS5]: [5, undefined],
    [SINCE_INCEPTION]: [undefined, undefined],
};
