import { useMemo } from 'react';
import { reduce } from 'lodash/fp';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { adaptModifiedSecurities } from '../adapters/adaptModifiedSecurities';

export const useModifiedSecurities = ({ preview, positionLink }) => {
    const { getModifiedPositions } = useOnBoardingSelector(onBoardingDataSelector);
    const positionsChanged = useMemo(() => getModifiedPositions?.positionsChanged
        ?.filter((i) => +i.value > 0).map(({ securityId, value }) => ({
            Allocation: Number(value) / 100,
            InstrumentId: securityId,
            Notes: [],
            IsNotAdvised: false,
        })), [getModifiedPositions?.positionsChanged]);

    if (!preview) return getModifiedPositions;

    const positions = reduce((accum, group) => {
        const children = group.children.filter((i) => +i.new.value > 0);

        return accum.concat({ ...group, children });
    }, [], getModifiedPositions?.positions);

    return {
        positions: adaptModifiedSecurities(positions, { positionLink })
            .filter((i) => i.children.length > 0),
        positionsChanged,
    };
};
