import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationPage from 'components/ConfirmationPage';
import { useConfirmationLayout } from './hooks/useConfirmationLayout';

const Confirmation = (props) => {
    const { location } = props;

    useConfirmationLayout();

    return (
        <ConfirmationPage location={location} />
    );
};

Confirmation.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            title: PropTypes.string,
            message: PropTypes.string,
            redirectURL: PropTypes.string,
            confirmButton: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default Confirmation;
