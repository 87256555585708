import { renderers } from 'ui-library';
import { CellSwitch } from 'components/renderers';
import { sorterNumber, sorterString } from 'utils/sorting';

const { CellWithIcon, CellWithCurrentNewValue } = renderers;

export const getModifyColumns = (t) => ([
    {
        key: 'Name',
        title: t('clientDashboard.portfolio.columns.name'),
        sortable: true,
        sorter: sorterString('Name'),
        width: 450,
        minWidth: 450,
        className: 'with-link',
        render: CellSwitch,
        defaultSortOrder: 'descending',
    },
    {
        key: 'ISIN',
        title: t('clientDashboard.portfolio.columns.isin'),
        hidden: true,
        sortable: true,
        sorter: sorterString('ISIN'),
    },
    {
        key: 'Currency',
        title: t('clientDashboard.portfolio.columns.currency'),
        hidden: true,
        sortable: true,
        sorter: sorterString('Currency'),
    },
    {
        key: 'Units',
        title: t('clientDashboard.portfolio.columns.units'),
        className: 'text-right',
        hidden: true,
        sorter: sorterNumber('Units'),
    },
    {
        key: 'LatestPrice',
        title: t('clientDashboard.portfolio.columns.latestPrice'),
        className: 'text-right',
        hidden: true,
        sorter: sorterNumber('LatestPrice'),
    },
    {
        key: 'ValueData',
        title: t('clientDashboard.portfolio.columns.valueData'),
        className: 'text-right',
        minWidth: 250,
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('confirmation.current'),
            new: t('confirmation.new'),
        }),
        sorter: sorterNumber('ValueData.currentValue'),
    },
    {
        key: 'AllocationD',
        title: t('clientDashboard.portfolio.columns.allocation'),
        className: 'text-right',
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('confirmation.current'),
            new: t('confirmation.new'),
        }),
        sorter: sorterNumber('AllocationD.currentValue'),
    },
    {
        key: 'Icon',
        title: '',
        width: 30,
        minWidth: 30,
        render: CellWithIcon,
        titleType: 'columns',
    },
]);
