import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Accordion, AccordionPanel as Panel, ContentBox,
} from 'ui-library';
import { useFormatting } from 'locale';
import { useDocuments, useInstrumentData } from 'domain/Instrument';
import { adaptInstrumentDocs } from 'adaptors/adaptInstrumentDocs';
import history from 'services/history';
import withInstrumentAccess from 'hocs/withInstrumentAccess';
import Preloader from 'components/Preloader';
import PageHeader from 'components/PageHeader';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import PositionOverview from 'components/Instrument/Overview';
import DetailsPerformance from 'components/PositionDetailsPerformace';
import InstrumentDocumentsTable from 'components/InstrumentDocumentsTable';
import KeyData from 'components/Instrument/KeyData';
import {
    BondId, INTEGER, PERCENTAGE, StructuredProductId,
} from 'constants/instrument';
import { adaptPositions } from '../Modify/adapters/adaptModifyPositions';
import './PositionDetails.css';
import { useNewPortfolioData } from '../../hooks/useNewPortfolioData';
import withNewPortfolioLayout from '../../hocs/withNewPortfolioLayout';

const PositionDetails = (props) => {
    const {
        match: { params: { dfsClientId, positionId } },
        isModify,
        isSummary,
    } = props;

    const { t, i18n: { language } } = useTranslation();
    const { getFormattedCurrency, getFormattedNumber, getFormattedDate } = useFormatting();
    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/new-portfolio`, [dfsClientId]);

    // New Portfolio - Local data
    const { newPortfolio, modifiedPositions, saveModifiedPositions } = useNewPortfolioData();
    const { amount, currency } = useMemo(() => ({
        amount: newPortfolio?.portfolioAmount,
        currency: newPortfolio?.portfolioCurrency?.label,
        productId: newPortfolio?.product?.value,
        currencyId: newPortfolio?.portfolioCurrency?.value,
        strategyId: newPortfolio?.strategy?.value,
        productName: newPortfolio?.product?.label,
    }), [newPortfolio]);

    // Instrument Domain
    const {
        data: dataDocs, isLoading: isLoadingDocs, error: errorDocs, getDocuments,
        errorDocument, getDocumentById,
    } = useDocuments({ instrumentId: positionId });
    const documents = useMemo(() => adaptInstrumentDocs({
        data: dataDocs, getFormattedDate, t, getDocumentById, positionId, language,
    }), [dataDocs, getFormattedDate, t, getDocumentById, positionId, language]);
    const {
        data, isLoading, error, getCommon,
        dataPerformance, isLoadingPerformance, errorPerformance, getPerformance,
        dataBenchmark, isLoadingBenchmark, errorBenchmark,
    } = useInstrumentData({ instrumentId: positionId });

    // Data
    const optionsForAdapt = useMemo(
        () => ({
            portfolioValue: +amount,
            portfolioCurrency: currency,
            nameLength: 35,
            isNew: true,
            positionLink: `${baseUrl}/position`,
            getFormattedCurrency,
            getFormattedNumber,
            language,
            t,
        }),
        [amount, currency, baseUrl, getFormattedCurrency, getFormattedNumber, language, t],
    );
    const positionToSave = useMemo(() => data.id && adaptPositions(
        [{ Security: data, Allocation: 0 }], optionsForAdapt,
    ), [data, optionsForAdapt]);
    const additionalBenchmark = useMemo(() => ({
        name: dataBenchmark.name,
        tradingPriceOfUnderlying: dataBenchmark.tradingPrice,
        calculatedRiskOfUnderlying: dataBenchmark.risk,
    }), [dataBenchmark]);
    const priceType = useMemo(() => ([BondId, StructuredProductId].includes(data.typeId)
        ? PERCENTAGE : INTEGER), [data.typeId]);

    // Effects
    useEffect(() => {
        getCommon();
    }, [getCommon]);
    useEffect(() => {
        if (data.currency?.id) getPerformance({ currencyId: data.currency?.id });
    }, [data.currency, getPerformance]);
    useEffect(() => {
        if (data.uId) getDocuments({ instrumentUId: data.uId });
    }, [data.uId, getDocuments]);

    // Callbacks
    const handlePrev = () => {
        if (isModify) {
            history.push(`${baseUrl}/add-position`);
        } else if (isSummary) {
            history.push(`${baseUrl}/summary`);
        } else {
            history.push(`${baseUrl}/modify`);
        }
    };
    const handleNext = () => {
        const isInModified = (modifiedPositions?.raw || [])
            .find(({ Id }) => Id === data.id);
        const isGroupInModified = (modifiedPositions?.positions || [])
            .find(({ id }) => id === positionToSave?.[0]?.id);
        const newModified = isGroupInModified
            ? modifiedPositions.positions.map((group) => ({
                ...group,
                children: isInModified ? group.children : [
                    ...group.children,
                    ...positionToSave?.[0]?.id === group.id ? positionToSave?.[0]?.children : [],
                ],
            }))
            : [...(modifiedPositions?.positions || []), ...positionToSave];
        const newModifiedRaw = isInModified ? modifiedPositions?.raw
            : [...(modifiedPositions?.raw || []), { Id: data.id, Allocation: 0 }];

        saveModifiedPositions({ positions: newModified, raw: newModifiedRaw });
        history.push(`${baseUrl}/modify`);
    };

    return (
        <div className="new-portfolio_instrument">
            <Preloader
                isLoading={isLoading || isLoadingPerformance || isLoadingBenchmark}
                error={error || errorBenchmark}
            >
                <PageHeader title={data?.name} />
                <ContentBox>
                    <Accordion defaultActiveKey={['1', '2', '3']}>
                        <Panel header={t('position.overview')} key="1">
                            <PositionOverview
                                data={data}
                                priceType={priceType}
                                positionId={positionId}
                                performance={dataPerformance}
                                hideHelpText
                            />
                        </Panel>
                        <Panel header={t('position.performance')} key="2" className="performance">
                            <DetailsPerformance
                                positionId={positionId}
                                currencyId={data?.currency?.id}
                                currency={data.currency?.isoCode}
                                priceType={priceType}
                                error={errorPerformance}
                            />
                        </Panel>
                        <Panel header={t('position.keyData')} key="3" className="key-data">
                            <KeyData
                                positionId={positionId}
                                additionalData={{
                                    ...additionalBenchmark,
                                    calculatedRisk: dataPerformance?.Volatility,
                                    calculatedExpectedReturn: dataPerformance?.ExpectedReturn,
                                    sharpeRatio: dataPerformance?.SharpeRatio,
                                }}
                            />
                        </Panel>
                        <Panel header={t('position.documents')} key="4" className="documents table-without-last-row-padding table-without-head-row-padding">
                            <InstrumentDocumentsTable
                                data={documents}
                                error={errorDocs}
                                isLoading={isLoadingDocs}
                                errorDocument={errorDocument}
                            />
                        </Panel>
                    </Accordion>
                    <ButtonsBlockRow
                        leftButton={{
                            text: t('confirmation.back'),
                            onClick: handlePrev,
                        }}
                        primaryButton={isModify && {
                            text: t('onBoarding.add'),
                            onClick: handleNext,
                        }}
                    />
                </ContentBox>
            </Preloader>
        </div>
    );
};

PositionDetails.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            positionId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    isModify: PropTypes.bool,
    isSummary: PropTypes.bool,
};

PositionDetails.defaultProps = {
    isModify: false,
    isSummary: false,
};

export default withNewPortfolioLayout(withInstrumentAccess(PositionDetails));
