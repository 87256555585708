import { customProperties as prestigeVariables } from 'ui-library/dist/styles/themes/prestige';
import { customProperties as modernVariables } from 'ui-library/dist/styles/themes/modern';

export const themesVariables = {
    prestige: prestigeVariables,
    modern: modernVariables,
};

export const getVariables = (theme) => {
    const customVars = JSON.parse(global.localStorage.getItem('customVars'));

    return {
        ...themesVariables[theme],
        ...customVars,
    };
};

export const setThemeVariables = (theme) => {
    const vars = getVariables(theme);

    document.documentElement.setAttribute('style', '');
    Object.keys(vars).forEach((key) => {
        document.documentElement.style.setProperty(key, vars[key]);
    });
};

export const changeTheme = (theme) => {
    document.documentElement.setAttribute('data-theme', theme);
    setThemeVariables(theme);
};
