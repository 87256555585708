import { useEffect, useState } from 'react';
import history from '../services/history';

// todo fix me[AT-856]
const useHistoryBlock = (url, confirm) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const clearBlockHistory = history.block((e) => {
            const clientRegExp = new RegExp(url);

            if (open) {
                return false;
            }

            if (!clientRegExp.test(e.pathname)) {
                confirm(e.pathname + e.search, e.state, setOpen);

                return false;
            }
            setOpen(false);

            return true;
        });

        return clearBlockHistory;
    }, [confirm, open, setOpen]);
};

export default useHistoryBlock;
