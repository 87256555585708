import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Paragraph, Title, Infobox,
} from 'ui-library';
import Preloader from 'components/Preloader';
import {
    goalCreationSelector, goalSummarySelector, onBoardingDataSelector, useOnBoardingSelector,
    goalSavingAndUpdatingSelector,
} from 'domain/OnBoarding';
import RadioButtonsGroup from 'components/RadioBlocks';
import { objectsDifference } from 'utils';
import { adaptGoalsTemplates } from './adaptors/adaptGoalData';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import GoalForm from '../../component/GoalForm';
import NoGoalForm from '../../component/NoGoalForm';
import './CalculatingGoal.css';

const CalculatingGoal = (props) => {
    const {
        onPrev,
        onNext,
        onPageChange,
    } = props;
    const { t } = useTranslation();
    const [formState, setFormState] = useState({});
    const [mergedFields, setMergedFields] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    // OnBoarding Domain
    const {
        product, saveGoalDetails, getGoalDetails, createdProposalId, getRiskCategory,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        updateProposal, isLoadingUpdate, errorUpdate,
    } = useOnBoardingSelector(goalSummarySelector);
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const {
        dataGoalTemplates, dataGoalPictures, isLoadingGoalTemplates, errorGoalTemplates,
        getGoalTemplates,
        data: modelPortfolioObj, getModelPortfolioId,
        dataCurrencies, isLoadingCurrencies, errorCurrencies, getCurrencyCodes,
    } = useOnBoardingSelector(goalCreationSelector);

    const [selectedGoal, setSelectedGoal] = useState(goalDetails.selectedGoal);

    const {
        saveGoal, isLoading: isSaveGoalLoading, error: saveGoalError, updateGoal,
    } = useOnBoardingSelector(goalSavingAndUpdatingSelector);

    // Data
    const noGoalTemplate = useMemo(() => ({
        value: 'no-goal', label: { id: 'no-goal', name: t('onBoarding.goal.noGoal') },
    }), [t]);
    const goalTemplates = useMemo(() => adaptGoalsTemplates(dataGoalTemplates, dataGoalPictures),
        [dataGoalTemplates, dataGoalPictures]);
    const goalOptions = useMemo(() => [
        ...goalTemplates.map((item) => ({ value: item.id.toString(), label: item })),
        noGoalTemplate,
    ], [goalTemplates, noGoalTemplate]);
    const placeholders = useMemo(() => goalTemplates.find(({ id }) => +id === +selectedGoal),
        [goalTemplates, selectedGoal]);
    const isNoGoalSelected = useMemo(() => selectedGoal === 'no-goal', [selectedGoal]);

    // Effects
    useEffect(() => {
        getGoalTemplates({ productId: +product?.id });
    }, [getGoalTemplates, product.id]);
    useEffect(() => {
        getCurrencyCodes({ productId: +product?.id });
    }, [getCurrencyCodes, product.id]);
    useEffect(() => {
        if (goalDetails) setMergedFields(goalDetails);
    }, [goalDetails]);
    useEffect(() => {
        (async () => {
            const riskCategoryId = (await getRiskCategory())?.Id;

            getModelPortfolioId({ productId: +product?.id, riskCategoryId });
        })();
    }, [getRiskCategory, getModelPortfolioId, product?.id]);

    // Callbacks
    const onChangeGoal = useCallback((value) => {
        setSelectedGoal(value);
        setIsSubmitted(false);
        setMergedFields((fields) => ({ ...fields, ...formState.goalState }));
    }, [formState.goalState]);
    const onOptimizeClick = useCallback(() => {
        saveGoalDetails({ ...formState.goalState, selectedGoal });
        onPageChange('optimize');
    }, [saveGoalDetails, formState.goalState, selectedGoal, onPageChange]);
    const onFormChange = useCallback((data) => {
        setFormState(data);
        setIsSubmitted(false);
    }, []);
    const onProposalUpdate = useCallback(async () => {
        const { goalName, initialInvestment, selectedCurrency } = formState.goalState || {};
        const params = {
            Name: {
                DoUpdate: true,
                Value: goalName,
            },
            CurrencyId: {
                DoUpdate: true,
                Value: selectedCurrency?.value,
            },
            RecommendedInvestment: {
                DoUpdate: true,
                Value: Number.parseFloat(initialInvestment),
            },
            ModelPortfolioId: {
                DoUpdate: true,
                Value: modelPortfolioObj?.Id,
            },
        };

        await updateProposal(createdProposalId, params);
    }, [createdProposalId, formState.goalState, modelPortfolioObj?.Id, updateProposal]);


    const withFundingUpdate = useMemo(() => {
        if (formState?.goalState && goalDetails) {
            const differenceValues = Object.keys(
                objectsDifference(goalDetails, formState?.goalState),
            );

            const withFunding = differenceValues.find(
                (i) => [
                    'recurringPayment',
                    'selectedCurrency',
                    'targetDate',
                    'recurringPaymentFrequency',
                ].includes(i),
            );

            return !!withFunding;
        }

        return false;
    }, [goalDetails, formState.goalState]);

    const onSubmit = useCallback(async (_, isPositiveGoal = true) => {
        if (isPositiveGoal) {
            await onProposalUpdate();

            if (selectedGoal !== 'no-goal') {
                let goal;

                if (formState.goalState?.id) {
                    goal = await updateGoal(formState.goalState?.id,
                        { ...formState.goalState }, withFundingUpdate);
                } else {
                    goal = await saveGoal({ ...formState.goalState, selectedGoal });
                }
                saveGoalDetails({ ...formState.goalState, selectedGoal, id: goal?.Id });
            } else {
                saveGoalDetails({ ...formState.goalState, selectedGoal, id: 'no-goal' });
            }

            onNext();
        }
    }, [onNext, formState.goalState, saveGoalDetails,
        onProposalUpdate, selectedGoal, formState?.isPositiveGoal, withFundingUpdate]);
    const onNextClick = useCallback(async () => {
        setIsSubmitted(true);
    }, []);

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.goal.title')}
            className="goals-selection"
            prevButton={{
                loading: isLoadingUpdate || isSaveGoalLoading,
                onClick: onPrev,
            }}
            additionalButton={!isNoGoalSelected && {
                text: t('onBoarding.goal.optimize'),
                disabled: !formState.optimizeDisabled,
                loading: isLoadingUpdate || isSaveGoalLoading,
                onClick: onOptimizeClick,
            }}
            nextButton={{
                text: t('onBoarding.continue'),
                loading: isLoadingUpdate || isSaveGoalLoading,
                onClick: onNextClick,
            }}
            buttonsRowError={(
                <>
                    {(isSubmitted && !selectedGoal) && (
                        <Infobox error>{t('onBoarding.goal.validation.goalTemplate')}</Infobox>
                    )}
                    {errorUpdate && <Infobox error>{errorUpdate?.message}</Infobox>}
                    {saveGoalError && <Infobox error>{saveGoalError?.message}</Infobox>}
                </>
            )}
        >
            <div className="goals-selection">
                <div className="select-goal">
                    <div className="group-title">
                        <Title type={3}>{t('onBoarding.goal.subTitle')}</Title>
                        <Paragraph type="secondary">{t('onBoarding.goal.text')}</Paragraph>
                    </div>
                    <Preloader isLoading={isLoadingGoalTemplates} error={errorGoalTemplates}>
                        <RadioButtonsGroup
                            name="selectedGoal"
                            value={selectedGoal}
                            legend={t('onBoarding.goal.subTitle')}
                            options={goalOptions}
                            onChange={onChangeGoal}
                        />
                    </Preloader>
                </div>
                <Preloader isLoading={selectedGoal && isLoadingCurrencies} error={errorCurrencies}>
                    <NoGoalForm
                        active={isNoGoalSelected}
                        dataCurrencies={dataCurrencies}
                        isSubmitted={isSubmitted}
                        productId={+product?.id}
                        defaultData={mergedFields}
                        onChange={onFormChange}
                        onSubmit={onSubmit}
                    />
                    <GoalForm
                        active={!!selectedGoal && !isNoGoalSelected}
                        dataCurrencies={dataCurrencies}
                        isSubmitted={isSubmitted}
                        placeholders={placeholders}
                        productId={+product?.id}
                        defaultData={mergedFields}
                        onChange={onFormChange}
                        onSubmit={onSubmit}
                    />
                </Preloader>
            </div>
        </OnBoardingBaseTemplate>
    );
};

CalculatingGoal.propTypes = {
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

CalculatingGoal.defaultProps = {
};

export default CalculatingGoal;
