import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from 'services/history';
import FundingPage from './pages/FundingPage';
import Interaction from './pages/Interaction';

const Router = (props) => {
    const { match: { url, path } } = props;
    const onPageChange = (pageKey) => {
        history.push(`${url[url.length - 1] === '/' ? url : `${url}/`}${pageKey}`);
    };
    const withProps = (Component) => (defProps) => (
        <Component {...props} {...defProps} onPageChange={onPageChange} />
    );

    return (
        <Switch>
            <Route exact path={`${path}`} render={withProps(Interaction)} />
            <Route exact path={`${path}/funding`} render={withProps(FundingPage)} />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
