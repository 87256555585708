export const getHardcodedDetails = (
    { countryOfNationality, onboardingStage, contactStatus } = {},
) => ({
    personalInformation: {
        identification: [{
            documentType: 304,
            countryOfIssuance: countryOfNationality,
            identificationNumber: 'X000000001',
            documentExpiryDate: '9999-12-31',
        }],
    },
    relationshipInformation: {
        isProspect: true,
        onboardingStage,
        contactStatus,
    },
    communicationMethods: {
        primaryAddress: {
            primaryAddressLine1: 'Riedtlistrasse 27',
            primaryAddressLine2: '8006',
            primaryAddressLine3: 'Zürich',
        },
    },
});

export const mandatoryFields = {
    personalInformation: {
        gender: 'gender',
        countryOfIssuance: 'identification.[0].countryOfIssuance',
        documentType: 'identification.[0].documentType',
        identificationNumber: 'identification.[0].identificationNumber',
        documentExpiryDate: 'identification.[0].documentExpiryDate',
    },
    communicationMethods: {
        primaryAddress: 'primaryAddress.primaryAddressLine1',
    },
};
