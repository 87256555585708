import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptData } from '../adapters/adaptData';

export const useCurrencies = () => {
    const [errorCurrency, setError] = useState(null);
    const [isLoadingCurrency, setLoading] = useState(true);
    const [dataCurrency, setData] = useState({});

    const { i18n } = useTranslation();

    useEffect(() => {
        setLoading(true);
        ServiceManager.currenciesService('getCurrencies', [i18n.language])
            .then((response) => {
                try {
                    setData(adaptData(response.data));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            ));
    }, [i18n.language]);

    return { dataCurrency, isLoadingCurrency, errorCurrency };
};
