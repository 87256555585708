import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Button, ContentBox, Modal, Table,
} from 'ui-library';
import { useClientProfile } from 'prodivers/clientProfile';
import history from 'services/history';
import { useFormatting, useLocale } from 'locale';
import { useModelPortfolio, usePortfolioQuickEdit } from 'domain/Portfolio';
import Preloader from 'components/Preloader';
import PageHeader from 'components/PageHeader';
import WidgetError from 'components/WidgetError';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import { getModifyColumns } from './constants';
import { useCreateLiquidity } from './hooks/useCreateLiquidity';
import { useNewPortfolioData } from '../../hooks/useNewPortfolioData';
import { adaptModifyPositions, adaptPositions } from './adapters/adaptModifyPositions';
import { useNewPortfolio } from '../../hooks/useNewPortfolio';
import { useNewPortfolioLayout } from '../../hooks/useNewPortfolioLayout';
import './Modify.css';

function Modify(props) {
    useNewPortfolioLayout({ current: 1 });
    const { match: { params: { dfsClientId } } } = props;
    const { t } = useTranslation();
    const { numberSeparators } = useLocale();
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();
    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/new-portfolio`, [dfsClientId]);

    // Client Provider
    const { clientId } = useClientProfile();

    // New Portfolio - Local data
    const {
        newPortfolio, modifiedPositions: modifiedData,
        saveModifiedPositions, clearModifiedPositions,
    } = useNewPortfolioData();
    const [modifiedPositions, setModifiedPositions] = useState(modifiedData);
    const {
        model, amount, currency, currencyId, productId, productName, strategyId,
    } = useMemo(() => ({
        model: newPortfolio?.model,
        amount: newPortfolio?.portfolioAmount,
        currency: newPortfolio?.portfolioCurrency?.label,
        productId: newPortfolio?.product?.value,
        currencyId: newPortfolio?.portfolioCurrency?.value,
        strategyId: newPortfolio?.strategy?.value,
        productName: newPortfolio?.product?.label,
    }), [newPortfolio]);

    // New Portfolio
    const { error, isLoading, getLiquidity } = useCreateLiquidity({ productId });

    // Portfolio Domain
    const {
        dataRaw: modelPortfolio, isLoading: isLoadingMP, error: errorMP, getModelPortfolio,
    } = useModelPortfolio();
    const {
        dataSummary, changesNonManual, changes, changesManual,
        initializePositions, onIncrease, onDecrease, onAllocationChange, onRemove,
    } = usePortfolioQuickEdit();

    const handleDelete = useCallback((securityId) => {
        Modal.confirm({
            title: t('onBoarding.remove'),
            content: t('onBoarding.deletePosition.confirmation'),
            okText: t('confirmation.confirm'),
            onOk: () => {
                const changedData = onRemove(securityId);

                saveModified({ raw: changedData?.changes });
            },
            cancelText: t('confirmation.cancel'),
        });
    }, [onRemove, saveModified, t]);

    // Data
    const optionsForAdapt = useMemo(
        () => ({
            currency,
            portfolioCurrency: currency,
            portfolioValue: +amount,
            portfolioTotalValue: +amount,
            portfolioSecuritiesValue: +amount,
            productName,
            t,
            getFormattedNumber,
            getFormattedCurrency,
            nameLength: 35,
            positionLink: `${baseUrl}/position`,
        }),
        [currency, amount, productName, baseUrl, t, getFormattedNumber, getFormattedCurrency],
    );
    const positionsModel = useMemo(() => adaptPositions(modelPortfolio?.Positions, optionsForAdapt),
        [modelPortfolio.Positions, optionsForAdapt]);
    const positionsAdapted = useMemo(() => adaptModifyPositions({
        positions: modifiedPositions?.positions?.length
            ? modifiedPositions.positions : positionsModel,
        changes,
        changesNonManual,
        dataSummary,
        positionLink: `${baseUrl}/position`,
        onChange: onAllocationChange(true),
        onIncrease,
        onDecrease,
        handleDelete,
        ...optionsForAdapt,
    }),
    [
        modifiedPositions,
        positionsModel,
        changes,
        changesNonManual,
        dataSummary,
        baseUrl,
        onAllocationChange,
        onIncrease,
        onDecrease,
        handleDelete,
        optionsForAdapt,
    ]);
    const total = +dataSummary?.New?.toFixed(2);

    // Effects
    useEffect(() => {
        (async () => {
            if (productId && strategyId && !modifiedPositions?.raw?.length && model) {
                getModelPortfolio(model, { adaptOptions: { currencyId } });
            }
        })();
    }, [currencyId, getModelPortfolio, model, modifiedPositions, productId, strategyId]);
    useEffect(() => {
        (async () => {
            if (!strategyId && !modifiedPositions?.raw?.length) {
                const liquidityData = await getLiquidity({ currencyId });
                const liquidity = { ...liquidityData, Id: liquidityData.id };
                const positionToSave = adaptPositions(
                    [{ Security: liquidity, Allocation: 1 }], { ...optionsForAdapt, isNew: true },
                );
                const modifiedPositionsWithLiquidity = {
                    positions: positionToSave,
                    raw: [{ Id: liquidity.Id, CurrencyId: currencyId, Allocation: 100 }],
                };

                setModifiedPositions(modifiedPositionsWithLiquidity);
                saveModifiedPositions(modifiedPositionsWithLiquidity);
            }
        })();
    }, [currencyId, strategyId, getLiquidity, modifiedPositions]);
    useEffect(() => {
        const modelPositions = modelPortfolio?.Positions?.length ? modelPortfolio?.Positions
            .map((item) => ({ ...item, Id: item.Security.Id, Allocation: item.Allocation * 100 }))
            : undefined;
        const basedPositions = modelPositions || (
            (modifiedPositions?.raw || [])
                .filter((item) => item.CurrencyId)
                .map((item) => ({ ...item, Allocation: 100 }))
        );

        initializePositions({
            changes: modifiedPositions?.raw?.length ? modifiedPositions.raw : null,
            positions: basedPositions,
        });
    }, [initializePositions, modelPortfolio.Positions, modifiedPositions]);

    // Callbacks
    const saveModified = useCallback(({ raw } = {}) => {
        saveModifiedPositions({
            positions: positionsAdapted.positions,
            raw: raw || changes,
        });
    }, [changes, positionsAdapted.positions, saveModifiedPositions]);
    const onAddPosition = () => {
        saveModified();
        history.push(`${baseUrl}/add-position`);
    };
    const onBack = () => {
        clearModifiedPositions();
        history.push(`${baseUrl}`);
    };
    const onNext = () => {
        if (changesNonManual.length || changesManual.length) {
            saveModified();
        }
        history.push(`${baseUrl}/summary`);
    };

    return (
        <div className="new-portfolio_modify">
            <PageHeader title={`${t('portfolio.createNewPortfolio')} - ${t('onBoarding.modify')}`}>
                <Button type="secondary" size="small" loading={isLoadingMP} onClick={onAddPosition}>
                    {t('clientDashboard.portfolioEdit.addPosition')}
                </Button>
            </PageHeader>
            <ContentBox className="modify-content">
                <Preloader
                    isLoading={isLoadingMP || isLoading}
                    error={errorMP || error}
                >
                    <Table
                        data={positionsAdapted.positions}
                        footer={positionsAdapted.footer}
                        columns={getModifyColumns(t, numberSeparators)}
                        defaultExpandAllRows
                    />
                </Preloader>
                <ButtonsBlockRow
                    leftButton={{
                        text: t('confirmation.back'),
                        onClick: onBack,
                    }}
                    primaryButton={{
                        text: t('portfolio.continue'),
                        onClick: onNext,
                    }}
                >
                    {(total !== 100 && !(isLoadingMP || isLoading)) && (
                        <WidgetError accent error message={t('portfolios.errorSum')} />
                    )}
                </ButtonsBlockRow>
            </ContentBox>
        </div>
    );
}

Modify.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

Modify.defaultProps = {
};

export default Modify;
