import { useCallback, useEffect, useMemo } from 'react';
import { portfolioTypeIds } from 'constants/portfolioTypeIds';
import { goalSummarySelector, onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { formatDate, yearFormat } from 'utils/datetime';
import {
    ADVISORY, DEPOSITS, DISCRETIONARY, EXECUTION_ONLY, generateObject,
} from 'constants/portfolioProducts';
import { useModelPortfolio } from 'domain/Portfolio';
import { adaptSummary } from '../adapters/adaptSummary';

export const useAcceptGoal = () => {
    // OnBoarding Domain
    const {
        product, getGoalDetails, getGoalChanges, getCreatedGoal, saveCreatedGoal,
        createdProposalId, saveIban, riskCategory,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        data, error: errorSummary, isLoading: isLoadingSummary, getProposal,
        errorAccept, isLoadingAccept, acceptGoalAndPortfolio,
    } = useOnBoardingSelector(goalSummarySelector);

    // Data
    const goalChanges = useMemo(() => (Number.isInteger(+getGoalChanges())
        ? getGoalChanges() : undefined), [getGoalChanges]);
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const proposalId = useMemo(() => typeof createdProposalId === 'number' && createdProposalId,
        [createdProposalId]);
    const projectionYears = useMemo(
        () => formatDate(goalDetails.targetDate, yearFormat) - formatDate(new Date(), yearFormat),
        [goalDetails.targetDate],
    );
    const optionsForAdapt = useMemo(
        () => ({
            productId: +product.id,
            productName: product.name,
            goalName: goalDetails.goalName,
            targetDate: goalDetails.targetDate,
            targetValue: +goalDetails.targetValue,
            selectedGoal: +goalDetails.selectedGoal,
            currency: goalDetails.selectedCurrency,
            portfolioValue: +goalDetails.initialInvestment,
            projectionYears,
            proposalId: createdProposalId,
        }),
        [
            product.id,
            product.name,
            goalDetails.goalName,
            goalDetails.targetDate,
            goalDetails.targetValue,
            goalDetails.selectedGoal,
            goalDetails.selectedCurrency,
            goalDetails.initialInvestment,
            projectionYears,
            createdProposalId,
        ],
    );
    const goalParams = useMemo(() => ({
        CurrencyId: goalDetails?.selectedCurrency?.value,
        GoalAttributes: {
            Name: goalDetails.goalName,
            DueDate: goalDetails.targetDate,
            TargetAmount: +goalDetails.targetValue,
            GoalTemplateId: goalDetails.selectedGoal,
        },
        PortfolioAttributes: {
            ModelPortfolioId: +goalChanges || null,
            PortfolioProposalId: !goalChanges ? proposalId : null,
            Name: goalDetails.goalName,
            ProductId: +product.id,
            InitialInvestmentAmount: +goalDetails.initialInvestment,
        },
    }), [
        goalChanges,
        goalDetails.goalName,
        goalDetails.initialInvestment,
        goalDetails.selectedCurrency?.value,
        goalDetails.selectedGoal,
        goalDetails.targetDate,
        goalDetails.targetValue,
        product.id,
        proposalId,
    ]);
    const accountParams = useMemo(() => ({
        CurrencyId: goalDetails?.selectedCurrency?.value,
        Name: goalDetails.goalName,
        ProductId: +product.id,
    }), [goalDetails?.goalName, goalDetails?.selectedCurrency?.value, product?.id]);
    const hasGoal = useMemo(() => goalDetails.selectedGoal !== 'no-goal' && generateObject({
        [ADVISORY]: true,
        [DISCRETIONARY]: true,
        [EXECUTION_ONLY]: false,
        [DEPOSITS]: true,
    }).getByName(product?.name), [product?.name]);
    const paramsToAccept = useMemo(() => ({
        CurrencyId: goalParams.CurrencyId,
        RiskCategoryId: goalParams?.PortfolioAttributes?.ModelPortfolioId ? null : riskCategory?.Id,
        ...goalParams.PortfolioAttributes,
    }), [goalParams, riskCategory]);

    // Portfolio Domain
    const {
        dataRaw: modelPortfolio, isLoading: isLoadingMP, error: errorMP, getModelPortfolio,
    } = useModelPortfolio();
    const summaryData = useMemo(() => adaptSummary(goalChanges
        ? modelPortfolio : data, optionsForAdapt),
    [goalChanges, modelPortfolio, data, optionsForAdapt]);

    // Callbacks
    const saveGoal = useCallback((dataAccept) => {
        const {
            investmentAllocations, currencyId, productId, productName,
        } = summaryData;
        const { Portfolios, Id: NewPortfolioId } = dataAccept;
        const PortfolioId = (Portfolios || [])
            .filter((item) => item.PortfolioTypeId === portfolioTypeIds.MAIN)
            .map(({ Id }) => Id)
            .slice(-1)?.[0] || NewPortfolioId;
        const createdGoal = {
            Years: projectionYears,
            ProductId: productId,
            ProductName: productName,
            CurrencyId: currencyId,
            Allocations: investmentAllocations,
            ModelPortfolioId: data?.ModelPortfolioId,
            PortfolioProposalId: createdProposalId,
            PortfolioId,
        };

        saveCreatedGoal(createdGoal);
    }, [summaryData, projectionYears, data?.ModelPortfolioId, createdProposalId, saveCreatedGoal]);
    const onAcceptGoal = useCallback(async () => {
        const createdGoalSaved = getCreatedGoal();

        if (createdGoalSaved.PortfolioId) return;
        const response = await acceptGoalAndPortfolio({
            hasGoal: false, params: paramsToAccept, accountParams,
        });

        if (response?.cashAccountResponse?.Account?.Iban) {
            await saveIban(response?.cashAccountResponse?.Account?.Iban);
        }
        await saveGoal(response);
    }, [acceptGoalAndPortfolio, accountParams, getCreatedGoal, paramsToAccept, saveGoal, saveIban]);

    // Effects
    useEffect(() => {
        if (goalChanges) {
            getModelPortfolio(goalChanges);
        } else if (proposalId) {
            getProposal(proposalId);
        }
    }, [proposalId, getProposal, getModelPortfolio, goalChanges]);

    return {
        onAcceptGoal,
        isLoading: isLoadingMP || isLoadingSummary,
        error: errorMP || errorSummary,
        isLoadingAccept,
        errorAccept,
    };
};
