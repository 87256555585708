import { filter } from 'lodash/fp';

export const adaptAccountsData = (data) => (filter(({
    Account: { Type },
}) => (Type.Id === 1), data)).map(({
    Account: { Id, Name, AccountNumber },
}) => ({
    label: `${Name ?? ''} ${AccountNumber}`,
    value: Id,
}));
