import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    ContentBox, Title, BreadCrumbs, Modal, Accordion, AccordionPanel as Panel,
} from 'ui-library';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import { getClientId } from 'utils';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import Preloader from 'components/Preloader';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import StrategyOverview from 'components/StrategyOverview';
import AllocationCompare from 'components/AllocationCompare';
import PositionsCompare from 'components/PositionsCompare';
import Analysis from 'components/Analysis';
import {
    portfolioModifySelector, portfolioSelector, tradingCardSelector, usePortfolioSelector,
} from 'domain/Portfolio';
import RiskBandwidthDisclaimer from 'components/RiskBandwidthDisclaimer/RiskBandwidthDisclaimer';
import { mapActionTypeName, MODIFY } from '../../../constants';
import { onPreventRedirect } from '../../common/utils';
import { EXECUTION_ONLY } from '../../common/constants';
import { useCheckRiskProfile } from '../../../hooks/useCheckRiskProfile';
import { useModify } from '../hooks/useModify';

function ModifyConfirmation(props) {
    useCancelAllRequests();

    const { match: { params: { portfolioId, dfsClientId } } } = props;
    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        deleteTradingCard, isDeleting, errorDeleting,
    } = usePortfolioSelector(tradingCardSelector);
    const {
        dataRaw: dataTradingCard, isLoading: isLoadingTradingCard, error: errorTradingCard,
        getModifiedPositions,
    } = usePortfolioSelector(portfolioModifySelector);

    useEffect(() => {
        getModifiedPositions();
    }, [getModifiedPositions]);

    const showStrategy = useMemo(() => (
        data?.overview
            ? data?.overview?.product !== EXECUTION_ONLY
            : true
    ), [data?.overview]);

    // Hooks
    const baseUrl = `/client/${dfsClientId}/portfolios/${portfolioId}/modify/position/${data?.productId}`;
    const { positions, modelData } = useModify({
        portfolio: data,
        portfolioPositions: dataRaw?.Positions,
        positionsModified: dataTradingCard?.OrderBookEntries,
        baseUrl,
    });
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);
    const {
        analysisData, isLoading: isLoadingAnalysis, isLoadingModel, errorModel,
        onPerformanceChange, performanceSelected, volatility,
    } = useAnalysisDetails({
        clientId,
        portfolio: data,
        strategy: modelData,
        positions,
        isLoading,
        benchmark,
        isLoadingBenchmark,
        isEdit: true,
    });

    // Risk Profile Check
    const [showRiskDisclaimer, setShowRiskDisclaimer] = useState(false);
    const {
        compareVolatility, clientRiskBandwidth,
    } = useCheckRiskProfile(clientId, data.productId);
    const riskCategoryId = dataRaw && dataRaw?.RiskCategory?.Id;
    const riskCategoryName = dataRaw && dataRaw?.RiskCategory?.Name;

    useEffect(() => {
        setShowRiskDisclaimer(false);

        if (volatility) {
            compareVolatility(volatility, riskCategoryId).then((passed) => {
                setShowRiskDisclaimer(!passed);
            });
        }
    }, [compareVolatility, volatility, riskCategoryId]);

    // Renderers
    const currentAllocations = data.investmentAllocation;
    const newAllocations = modelData
        ? (modelData.investmentAllocation)
        : currentAllocations;
    const AllocationRender = useMemo(() => (
        <AllocationCompare
            data={currentAllocations}
            dataNew={newAllocations}
            isLoading={isLoading}
            error={error}
        />
    ), [currentAllocations, newAllocations, isLoading, error]);
    const renderRiskDisclaimer = () => (
        <RiskBandwidthDisclaimer
            risk={volatility}
            min={clientRiskBandwidth?.Min}
            max={clientRiskBandwidth?.Max}
            CRP={riskCategoryName}
        />
    );

    // Callbacks
    const onCancel = (link) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                deleteTradingCard(clientId, portfolioId);
                sessionStorage.removeItem(MODIFY);
                history.push(link);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onDiscardChanges = () => {
        history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/modify`);
    };
    const onConfirm = () => {
        if (!showRiskDisclaimer) {
            history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/modify/orders`);

            return;
        }

        Modal.confirm({
            title: t('clientDashboard.disclaimer'),
            content: t('clientDashboard.changeStrategy.riskProfileContent'),
            okText: t('clientDashboard.yes'),
            cancelText: t('clientDashboard.no'),
            onOk: () => {
                history.push(`/client/${dfsClientId}/portfolios/${portfolioId}/modify/orders`);
            },
            className: 'accept-risk-profile',
        });
    };

    return (
        <Preloader
            isLoading={isLoading || isLoadingTradingCard}
            error={error || errorTradingCard || errorDeleting}
        >
            <ContentBox className="confirmation-page with-mb" underline={false}>
                <BreadCrumbs current={t('confirmation.title')}>
                    <Link
                        to={`/client/${dfsClientId}/portfolios`}
                        onClick={(e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios`, onCancel)}
                    >
                        {t('clientDashboard.portfolio.portfolios')}
                    </Link>
                    <Link
                        to={`/client/${dfsClientId}/portfolios/${portfolioId}`}
                        onClick={(e) => onPreventRedirect(e, `/client/${dfsClientId}/portfolios/${portfolioId}`, onCancel)}
                    >
                        {data.title}
                    </Link>
                    <Link to={`/client/${dfsClientId}/portfolios/${portfolioId}/modify`}>
                        {mapActionTypeName(MODIFY, t)}
                    </Link>
                </BreadCrumbs>
                <Title className="underlined with-mb" type={1}>
                    {t('confirmation.title')}
                </Title>
                {showRiskDisclaimer && renderRiskDisclaimer()}
                <div className="overview strategy-overview">
                    <StrategyOverview
                        data={data.overview}
                        dataStrategy={data.overview}
                        isLoading={isLoading}
                        error={error}
                        showStrategy={showStrategy}
                    />
                </div>
                <div className="content-wrapper confirmation">
                    <Accordion defaultActiveKey={['1', '2', '3']}>
                        <Panel header={t('clientDashboard.portfolio.allocation')} key="1" className="allocation">
                            {AllocationRender}
                        </Panel>
                        <Panel header={t('clientDashboard.portfolio.analysis')} key="2" className="analysis">
                            <Analysis
                                data={analysisData}
                                onFilterChange={onPerformanceChange}
                                benchmarkOptions={benchmarkOptions}
                                benchmarkSelected={benchmarkSelected}
                                onBenchmarkChange={onBenchmarkChange}
                                isLoadingBenchmarks={isLoadingBenchmark}
                                performanceSelected={performanceSelected}
                                isLoading={isLoadingModel}
                                error={errorModel}
                            />
                        </Panel>
                        <Panel header={t('clientDashboard.portfolio.positions')} className="positions" key="3">
                            <PositionsCompare
                                data={{ positions }}
                                isLoading={isLoading}
                                error={error}
                                defaultExpandAllRows
                            />
                        </Panel>
                    </Accordion>
                </div>
                <ButtonsBlockRow
                    leftButton={{
                        text: t('confirmation.back'),
                        loading: isDeleting,
                        onClick: onDiscardChanges,
                    }}
                    primaryButton={{
                        text: t('confirmation.continue'),
                        disabled: isLoadingAnalysis,
                        onClick: onConfirm,
                    }}
                />
            </ContentBox>
        </Preloader>
    );
}

ModifyConfirmation.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        url: PropTypes.string,
        path: PropTypes.string,
    }).isRequired,
};

ModifyConfirmation.defaultProps = {
};

export default ModifyConfirmation;
