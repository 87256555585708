import { useCallback, useMemo, useState } from 'react';
import { EXECUTION_ONLY_ID } from 'constants/portfolioProducts';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';
import { useCreateAccount } from 'hooks/useCreateAccount';
import { useBankInfo } from 'hooks/useBankInfo';

export const useCreatePortfolio = ({ clientId, params: paramsOption }) => {
    const [data, setData] = useState();
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [dataUpdate, setDataUpdate] = useState();
    const [errorUpdate, setErrorUpdate] = useState(null);
    const [isLoadingUpdate, setLoadingUpdate] = useState(false);

    const createAccountOptions = useMemo(() => ({
        goalName: paramsOption?.name,
        productId: paramsOption?.productId,
        currency: { value: paramsOption?.currencyId },
    }), [paramsOption]);

    const { i18n: { language } } = useTranslation();
    const { saveIban } = useBankInfo({ contactId: clientId });
    const { createAccount } = useCreateAccount(clientId, createAccountOptions);

    const createPortfolio = useCallback(async ({ params = paramsOption } = {}) => {
        const {
            name, amount, currencyId, productId, strategyId, allocationsOrder,
        } = params;

        if (!currencyId && !productId && !amount
            && (!strategyId || !EXECUTION_ONLY_ID.includes(strategyId))) {
            return null;
        }

        setError(null);
        setLoading(true);
        try {
            let modelPortfolio;

            const { cashAccountResponse, custodyAccountResponse } = await createAccount();

            saveIban(cashAccountResponse?.Account?.Iban);

            if (!EXECUTION_ONLY_ID.includes(productId) || !allocationsOrder) {
                const { data: modelPortfolioRes } = await SM.portfolioManagement('getModelPortfolios', [productId, { language, riskCategoryId: strategyId }]);

                modelPortfolio = modelPortfolioRes;
            }

            const portfolioOptions = {
                ModelPortfolioId: allocationsOrder ? null : modelPortfolio?.[0]?.Id,
                AllocationsOrder: allocationsOrder,
                Name: name,
                ProductId: productId,
                Accounts: [
                    {
                        Account: {
                            Id: cashAccountResponse.Account.Id,
                            IsActive: true,
                        },
                        IsDefaultCustodyAccount: false,
                        IsPayoutAccount: true,
                        IsPayinAccount: true,
                    },
                    {
                        Account: {
                            Id: custodyAccountResponse.Account.Id,
                            IsActive: true,
                        },
                        IsDefaultCustodyAccount: true,
                        IsPayoutAccount: false,
                        IsPayinAccount: false,
                    },
                ],
                PortfolioType: 'Real',
                PortfolioStatus: 'NotImplemented',
                RebalancingType: 'Automatic',
                InitialInvestmentAmount: amount,
                CurrencyId: currencyId,
                RiskCategoryId: strategyId,
            };
            const response = await SM.portfolioManagement('createPortfolio', [clientId, { language }, portfolioOptions]);

            setData(response?.data);
            setLoading(false);

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [paramsOption, createAccount, saveIban, clientId, language]);

    const updateProposal = useCallback(async (proposalId, params) => {
        setErrorUpdate(null);
        setLoadingUpdate(true);

        try {
            const response = await SM.portfolioManagement('patchPortfolioProposal', [clientId, proposalId, params]);

            setDataUpdate(response?.data);
            setLoadingUpdate(false);

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: setErrorUpdate,
                    setLoading: setLoadingUpdate,
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId]);

    return {
        data,
        error,
        isLoading,
        createPortfolio,
        dataUpdate,
        errorUpdate,
        isLoadingUpdate,
        updateProposal,
    };
};
