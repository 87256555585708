import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {
    Modal, Accordion, AccordionPanel as Panel, Infobox,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import Allocation from 'components/AllocationCompare';
import StrategyOverview from 'components/StrategyOverview';
import PositionsCompare from 'components/PositionsCompare';
import Analysis from 'components/Analysis';
import { useModelList } from 'hooks/useModelList';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useModelChange } from 'hooks/useModelChange';
import { useStrategyList } from 'hooks/useStrategyList';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import { useChangeStrategyConfig } from 'hooks/useChangeStrategyConfig';
import { CHANGE_STRATEGY } from 'constants/constants';
import { goalSummarySelector, onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import RiskDisclaimer from 'components/RiskDisclaimer/RiskDisclaimer';
import { adaptConstraints } from 'adaptors/adaptConstraints';
import { useReviewData } from '../../../../hooks/useReviewData';
import { useSaveProposals } from '../../../../hooks/useSaveProposals';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { useGoalReach } from '../../hooks/useGoalReach';
import { useCheckRiskProfile } from '../../../../../ClientOverview/pages/Portfolios/hooks/useCheckRiskProfile';
import './ChangeStrategy.css';

function ChangeStrategy(props) {
    const { onPageChange, contactId, location: { pathname } } = props;
    const { t } = useTranslation();
    const [strategy, setStrategy] = useState(JSON.parse(sessionStorage.getItem(CHANGE_STRATEGY)));
    const [errorSameStrategy, setErrorSameStrategy] = useState(false);
    const [riskCategory, setRiskCategory] = useState(null);
    const positionLink = `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`;

    // OnBoarding domain
    const {
        product, getGoalDetails, getGoalChanges, saveGoalChanges,
        clearGoalChanges, createdProposalId, saveProposalId, getRiskCategory,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        updateProposal, isLoadingUpdate, errorUpdate,
    } = useOnBoardingSelector(goalSummarySelector);
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const goalChanges = useMemo(() => (Number.isInteger(+getGoalChanges())
        ? getGoalChanges() : undefined), [getGoalChanges]);

    // Hooks
    const {
        data, isLoading, error, rawData,
    } = useReviewData(contactId, { positionLink });
    const {
        strategyList, isLoadingStrategyList, errorStrategyList,
    } = useStrategyList(+product.id);
    const {
        data: modelList, isLoading: isLoadingModelList, error: errorModelList,
    } = useModelList({ productId: +product.id, riskCategoryId: strategy });
    const fullData = useMemo(() => ({
        ...data,
        productId: +product.id,
        investedAmount: +goalDetails.initialInvestment,
        portfolioValue: +goalDetails.initialInvestment,
    }), [data, goalDetails, product]);
    const {
        modelData: strategyData, positions, modelId,
        isLoadingModelData, errorModelData, onModelChange, onModelReset,
    } = useModelChange({
        portfolio: fullData,
        portfolioPositions: rawData?.Positions,
        baseUrl: positionLink,
        modelDefault: goalChanges,
    });
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);
    const {
        analysisData, isLoadingModel, errorModel,
        onPerformanceChange, performanceSelected, projectionResponse,
    } = useAnalysisDetails({
        portfolio: data,
        strategy: strategyData,
        positions,
        isLoading,
        benchmark,
    });
    const { saveProposal, isCreatingProposal, errorCreatingProposal } = useSaveProposals(contactId);

    // Data
    const isLoadingData = useMemo(() => isLoading || isLoadingModelData,
        [isLoading, isLoadingModelData]);
    const errorData = useMemo(() => error || errorModelData,
        [error, errorModelData]);

    // Effects
    const { dataExternal, isGoalReach } = useGoalReach({
        goalState: goalDetails, projectionResponse,
    });

    useEffect(() => {
        (async () => {
            if (goalChanges) {
                setRiskCategory(rawData?.RiskCategory);
            } else {
                const risk = await getRiskCategory();

                setRiskCategory(risk);
            }
        })();
    }, [getRiskCategory, rawData, goalChanges]);
    useEffect(() => {
        if (modelList.length) {
            if (modelList.find(({ value }) => value === modelId)) return;
            onModelChange(modelList[0]?.value);
        }
    }, [modelList, modelId, onModelChange]);
    useEffect(() => {
        if (strategyData?.riskCategory) {
            setStrategy(strategyData.riskCategory.toString());
        }
    }, [strategyData?.riskCategory]);

    // Risk Profile Check
    const [showRiskDisclaimer, setShowRiskDisclaimer] = useState(false);
    const { comparePortfolioRisk } = useCheckRiskProfile();
    const { riskCategoryId, riskCategoryName } = useMemo(() => ({
        riskCategoryId: riskCategory?.Id,
        riskCategoryName: riskCategory?.Name,
    }), [riskCategory]);

    const { isStrategyWithinRiskBandwidth } = useChangeStrategyConfig();

    useEffect(() => {
        setShowRiskDisclaimer(false);
        if (strategyData?.riskCategory) {
            setShowRiskDisclaimer(!isStrategyWithinRiskBandwidth(strategyData.riskCategory));
        }
    }, [comparePortfolioRisk, riskCategoryId, strategyData?.riskCategory]);

    // Renderers
    const AllocationRender = useMemo(() => (
        <Allocation
            data={data.investmentAllocation}
            dataNew={strategyData?.investmentAllocation || data.investmentAllocation}
            isLoading={isLoadingData}
            error={errorData}
        />
    ), [data.investmentAllocation, strategyData, isLoadingData, errorData]);
    const renderRiskDisclaimer = () => (
        <RiskDisclaimer
            PRP={strategyData?.overview?.strategy
        || data.overview?.strategy}
            CRP={riskCategoryName}
        />
    );

    // Callbacks
    const onStrategyChange = (val) => {
        setStrategy(val);
        setErrorSameStrategy(false);
        sessionStorage.setItem(CHANGE_STRATEGY, JSON.stringify(val));
    };
    const onStrategyReset = () => {
        sessionStorage.removeItem(CHANGE_STRATEGY);
        onModelReset();
    };
    const onBackClick = () => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                clearGoalChanges();
                onStrategyReset();
                onPageChange('');
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onConfirm = useCallback(async () => {
        const isUpdate = typeof createdProposalId === 'number';

        if (isUpdate) {
            const params = {
                BenchmarkId: {
                    DoUpdate: true,
                    Value: strategyData.benchmarkId,
                },
                ModelPortfolioId: {
                    DoUpdate: true,
                    Value: strategyData.modelPortfolioId,
                },
                OptimizationConstraints: {
                    DoUpdate: true,
                    Value: adaptConstraints(strategyData?.constraints),
                },
            };

            await updateProposal(createdProposalId, params);
        } else {
            const savedProposalData = await saveProposal(
                contactId,
                strategyData,
                strategyData?.investmentAllocations,
            );

            if (savedProposalData?.createdProposalId) {
                saveProposalId(savedProposalData.createdProposalId);
            }
        }
        saveGoalChanges(modelId);
        onStrategyReset();
        onPageChange('');
    }, [
        contactId,
        modelId,
        onPageChange,
        saveGoalChanges,
        saveProposal,
        saveProposalId,
        strategyData,
        updateProposal,
        createdProposalId,
        onStrategyReset,
    ]);
    const onNextClick = () => {
        if (data?.modelPortfolioId === +modelId || !modelId) {
            setErrorSameStrategy(true);

            return;
        }

        if (!showRiskDisclaimer) {
            onConfirm();

            return;
        }

        Modal.confirm({
            title: t('clientDashboard.disclaimer'),
            content: t('clientDashboard.changeStrategy.riskProfileContent'),
            okText: t('clientDashboard.yes'),
            cancelText: t('clientDashboard.no'),
            onOk: onConfirm,
            className: 'accept-risk-profile',
        });
    };

    return (
        <OnBoardingBaseTemplate
            title={`${goalDetails.goalName} - ${t('overview.changeStrategy')}`}
            className="goal-review goal-change-strategy"
            classNameContent="stepper-content-full"
            prevButton={{
                loading: isCreatingProposal || isLoadingUpdate,
                disabled: isLoading,
                onClick: onBackClick,
            }}
            nextButton={{
                text: t('onBoarding.continue'),
                loading: isCreatingProposal || isLoadingUpdate,
                disabled: isLoading,
                onClick: onNextClick,
            }}
            topInfoboxContent={!isGoalReach && !isLoadingModel && !isLoadingBenchmark && (
                <div className="goal-reached">
                    <Infobox accent>{t('onBoarding.cannotReachedGoal')}</Infobox>
                </div>
            )}
            buttonsRowError={(
                <>
                    {errorSameStrategy && <Infobox error>{t('validation.errorChangeSameStrategy')}</Infobox>}
                    {errorUpdate && <Infobox error>{errorUpdate.message}</Infobox>}
                    {errorCreatingProposal
                        && (<Infobox error>{errorCreatingProposal.message}</Infobox>)}
                </>
            )}
        >
            <Preloader isLoading={isLoading} error={error}>
                <EmptyContent data={{ test: 'test' }} text={t('advisoryDashboard.noData')}>
                    <div className="mb-0 overview change-strategy-overview">
                        {showRiskDisclaimer && renderRiskDisclaimer()}
                        <StrategyOverview
                            data={data.overview}
                            dataStrategy={strategyData ? strategyData.overview : data.overview}
                            isLoading={isLoadingStrategyList}
                            error={errorStrategyList}
                            strategyList={strategyList}
                            onStrategyChange={onStrategyChange}
                            selectedStrategy={strategy}
                            selectedModel={modelId}
                            isLoadingModel={isLoadingModelList}
                            errorModel={errorModelList}
                            modelList={modelList}
                            onModelChange={onModelChange}
                        />
                    </div>
                    <div className="content-wrapper">
                        <Accordion defaultActiveKey={['1', '2', '3']}>
                            <Panel header={t('clientDashboard.portfolio.allocation')} key="1" className="allocation">
                                {AllocationRender}
                            </Panel>
                            <Panel header={t('clientDashboard.portfolio.analysis')} key="2" className="analysis">
                                <Analysis
                                    data={analysisData}
                                    onFilterChange={onPerformanceChange}
                                    benchmarkOptions={benchmarkOptions}
                                    benchmarkSelected={benchmarkSelected}
                                    onBenchmarkChange={onBenchmarkChange}
                                    isLoadingBenchmarks={isLoadingBenchmark}
                                    performanceSelected={performanceSelected}
                                    projectionDataExternal={dataExternal}
                                    isLoading={isLoadingModel || isLoadingModelData}
                                    error={errorModel || errorModelData}
                                    isPortfolioProposed
                                />
                            </Panel>
                            <Panel header={t('clientDashboard.portfolio.positions')} className="positions" key="3">
                                <PositionsCompare
                                    data={{ positions }}
                                    isLoading={isLoading}
                                    error={error}
                                    defaultExpandAllRows
                                />
                            </Panel>
                        </Accordion>
                    </div>
                </EmptyContent>
            </Preloader>
        </OnBoardingBaseTemplate>
    );
}

ChangeStrategy.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
    onPageChange: PropTypes.func,
};

ChangeStrategy.defaultProps = {
    onPageChange: () => {},
};

export default ChangeStrategy;
