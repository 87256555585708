import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Modal, Title, Paragraph, TabPane, Tabs, Infobox,
} from 'ui-library';
import Preloader from 'components/Preloader';
import { ExistingInteraction, InteractionDetails } from 'templates/Interaction';
import { useTypesAndTopicsInteraction } from 'hooks/useTypesAndTopicsInteraction';
import { useNewInteraction } from 'hooks/useNewInteraction';
import { useClientProfile } from 'prodivers/clientProfile';
import { useExistingInteraction } from 'hooks/useExistingInteraction';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import './Interaction.css';

function InteractionAction(props) {
    const {
        changeStep, onPageChange, contactId,
    } = props;
    const { t } = useTranslation();
    const [isSending, setSending] = useState(false);
    const [interactionType, setInteractionType] = useState('existing');

    // Client Provider
    const { clientName } = useClientProfile();

    // Hooks
    const {
        isLoading: isLoadingTypesAndTopics, types, topics,
    } = useTypesAndTopicsInteraction(true);
    const {
        data: existInteractionData,
        isLoading: isLoadingExist,
        error: errorExist,
        onExistInteractionChange,
        errorUpdating,
        updateInteraction,
        onExistInteractionCommentChange,
        formErrors: existInteractionFormErrors,
        onExistInteractionHandleSubmit,
        values,
        register: existInteractioRegister,
    } = useExistingInteraction(contactId, isLoadingTypesAndTopics, types, topics);
    const {
        register,
        data: newInteractionData,
        isLoading: isLoadingNew,
        error: errorNew,
        values: newInteractionValues,
        formErrors,
        onInteractionChange,
        onNewInteractionCommentChange,
        onNewInteractionHandleSubmit,
        errorCreating: errorCreatingInteraction,
        createInteraction,
        isSearching,
        onSearch,
        control,
    } = useNewInteraction(contactId, isLoadingTypesAndTopics, types, topics, clientName);

    useEffect(() => {
        if (!isLoadingExist && existInteractionData.length === 0) {
            setInteractionType('new');
        }
    }, [isLoadingExist, existInteractionData]);

    // Callbacks
    const onConfirm = () => (params) => {
        const modal = Modal.confirm({
            title: t('clientDashboard.confirm'),
            content: t('clientDashboard.changeStrategy.interactionConfirmContent'),
            okText: t('clientDashboard.yes'),
            cancelText: t('clientDashboard.no'),
            onOk: async () => {
                modal.destroy();

                setSending(true);
                try {
                    if (interactionType === 'existing') await updateInteraction();
                    else await createInteraction(params);
                    onPageChange('funding');
                    setSending(false);
                } catch (e) {
                    setSending(false);
                }
            },
            className: 'accept-interaction',
        });
    };
    const onConfirmClick = () => {
        if (interactionType === 'existing') onExistInteractionHandleSubmit(onConfirm())();
        else onNewInteractionHandleSubmit(onConfirm())();
    };

    return (
        <OnBoardingBaseTemplate
            title={t('clientDashboard.interaction')}
            className="interaction"
            classNameContent="interaction-content"
            prevButton={{
                loading: isSending,
                onClick: () => changeStep(7),
            }}
            nextButton={{
                text: t('onBoarding.confirm'),
                loading: isSending,
                onClick: onConfirmClick,
            }}
            buttonsRowError={(
                <>
                    {(interactionType === 'existing' && existInteractionFormErrors.interactionId) && (
                        <Infobox error className="interaction-select-error">
                            {existInteractionFormErrors.interactionId}
                        </Infobox>
                    )}
                    {errorUpdating && <Infobox error>{errorUpdating.message}</Infobox>}
                    {errorCreatingInteraction && (
                        <Infobox error>{errorCreatingInteraction.message}</Infobox>
                    )}
                </>
            )}
        >
            <Title type={3}>{t('clientDashboard.interaction')}</Title>
            <Paragraph type="secondary">{t('clientDashboard.interactionDescription')}</Paragraph>
            <Tabs activeKey={interactionType} onChange={setInteractionType}>
                <TabPane tab={t('clientDashboard.existingInteraction')} key="existing">
                    <Preloader isLoading={isLoadingExist} error={errorExist}>
                        <ExistingInteraction
                            data={existInteractionData}
                            comment={values.comment}
                            selectedRows={[values.interactionId]}
                            onRowChange={onExistInteractionChange}
                            onCommentChange={onExistInteractionCommentChange}
                            register={existInteractioRegister}
                            errors={existInteractionFormErrors}
                        />
                    </Preloader>
                </TabPane>
                <TabPane tab={t('clientDashboard.newInteraction')} key="new">
                    <Preloader isLoading={isLoadingNew} error={errorNew}>
                        <InteractionDetails
                            data={newInteractionData}
                            errors={formErrors}
                            values={newInteractionValues}
                            onChange={onInteractionChange}
                            onCommentChange={onNewInteractionCommentChange}
                            isSearching={isSearching}
                            onSearch={onSearch}
                            register={register}
                            control={control}
                            editable
                            showCommentHelpText
                        />
                    </Preloader>
                </TabPane>
            </Tabs>
        </OnBoardingBaseTemplate>
    );
}

InteractionAction.propTypes = {
    contactId: PropTypes.string.isRequired,
    changeStep: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

InteractionAction.defaultProps = {
};

export default InteractionAction;
