import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptData } from '../adapters/adaptData';

const initialState = {
    isLoadingSecurityTypes: true,
    errorSecurityTypes: null,
    dataSecurityTypes: {},
};

function reducer(state, action) {
    switch (action.type) {
        case 'setTypes':
            return {
                ...state,
                isLoadingSecurityTypes: action.payload.isLoading,
                dataSecurityTypes: action.payload.data,
            };

        case 'setErr':
            return {
                ...state,
                errorSecurityTypes: action.payload.error,
            };
        case 'setIsLoading':
            return {
                ...state,
                isLoadingSecurityTypes: action.payload.isLoading,
            };

        default: return state;
    }
}

export const useSecurityTypes = (options) => {
    const { withLiquidity = false } = (options || {});
    const [state, dispatch] = useReducer(reducer, initialState);
    const { i18n } = useTranslation();

    const setError = (error) => {
        dispatch({ type: 'setErr', payload: { error } });
    };
    const setIsLoading = (isLoading) => {
        dispatch({ type: 'setIsLoading', payload: { isLoading } });
    };

    useEffect(() => {
        ServiceManager.commonService('getSecurityTypes', [i18n.language])
            .then((response) => {
                try {
                    const data = adaptData(response.data, { withLiquidity });

                    dispatch({
                        type: 'setTypes',
                        payload: { data, isLoading: false },
                    });
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading: setIsLoading }),
            ));
    }, [i18n.language, withLiquidity]);

    return state;
};
