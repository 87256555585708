import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import {
    object, number, string, boolean,
} from 'yup';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import {
    adaptLanguages, adaptCommon, adaptCountriesWithCode,
} from '../adapters/adaptCommon';

export const useDetails = () => {
    const { t, i18n } = useTranslation();
    const genders = useMemo(() => [
        { label: t('clientDashboard.details.female'), value: false },
        { label: t('clientDashboard.details.male'), value: true },
    ], [t]);

    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [options, setOptions] = useState({
        genders,
        salutations: [],
        countries: [],
        languages: [],
    });
    const [selectedCountryCode, setCountryCode] = useState('');
    const [selectedCountryId, setCountryId] = useState('');

    const getDropdown = useCallback(async () => {
        try {
            setError(null);
            setLoading(true);

            const responses = await Promise.all([
                ServiceManager.commonService('getSalutations', [i18n.language]),
                ServiceManager.commonService('getCountries', [i18n.language]),
                ServiceManager.commonService('getLanguages', [i18n.language]),
            ]);

            try {
                const adapted = {
                    genders,
                    salutations: adaptCommon(responses[0].data),
                    countries: adaptCountriesWithCode(responses[1].data),
                    languages: adaptLanguages(responses[2].data),
                };

                setOptions(adapted);
                setLoading(false);

                return responses;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [i18n.language, genders]);

    useEffect(() => {
        getDropdown();
    }, [getDropdown]);

    const schema = useMemo(() => object().shape({
        salutation: number().required(t('validation.mandatoryField')),
        firstName: string().required(t('validation.mandatoryField')),
        middleName: string(),
        lastName: string().required(t('validation.mandatoryField')),
        gender: boolean().required(t('validation.mandatoryField')),
        nationality: string().required(t('validation.mandatoryField')),
        language: string().required(t('validation.mandatoryField')),
        birthDate: string().required(t('validation.mandatoryField')),
        birthPlace: string().required(t('validation.mandatoryField')),
        street: string().required(t('validation.mandatoryField')),
        number: string().required(t('validation.mandatoryField')),
        additional: string(),
        postalCode: string().required(t('validation.mandatoryField')),
        city: string().required(t('validation.mandatoryField')),
        country: string().required(t('validation.mandatoryField')),
        mobile: string().required(t('validation.mandatoryField')),
        countryCode: string().required(t('validation.mandatoryField')),
        countryId: number().required(t('validation.mandatoryField')),
        email: string().email(t('validation.invalidEmail')).required(t('validation.mandatoryField')),
    }), [t]);
    const resolver = useYupValidationResolver(schema);
    const {
        register, handleSubmit, errors, setValue, control, watch, formState, trigger,
    } = useForm({ resolver, reValidateMode: 'onChange' });

    useEffect(() => {
        register('mobile');
        register('countryCode');
        register('countryId');
    }, [register]);

    // Validation
    const formErrors = Object.keys(errors || {}).reduce((acc, key) => ({
        ...acc, [key]: errors[key].message,
    }), {});

    // Callbacks
    const onChange = useCallback((type) => (value) => {
        if (type === 'country') {
            const countryCode = options.countries?.find((i) => i.value === value)?.code;
            const countryId = options.countries?.find((i) => i.value === value)?.id;

            setCountryCode(countryCode);
            setCountryId(countryId);
        }

        setValue(type, value);

        if (formState.isSubmitted) trigger(type);
    }, [
        trigger,
        formState.isSubmitted,
        setValue,
        options.countries,
        setCountryCode,
        setCountryId,
    ]);

    useEffect(() => {
        setValue('countryCode', selectedCountryCode);

        if (formState.isSubmitted) trigger('countryCode');
    }, [selectedCountryCode, setValue]);

    useEffect(() => {
        setValue('countryId', selectedCountryId);

        if (formState.isSubmitted) trigger('countryId');
    }, [selectedCountryId, setValue]);

    return {
        options,
        isLoadingOptions: isLoading,
        errorOptions: error,
        control,
        register,
        formErrors,
        handleSubmit,
        onChange,
        values: watch(),
    };
};
