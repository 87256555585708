import { getTimestamp, subtractUnits } from 'utils/datetime';
import { checkNullData } from 'utils';
import { formatCurrencyWithPrecision, formatWithDate } from 'utils/formatting';
import { adaptPlotsAmount } from 'utils/adaptPlotsAmount';
import { isNeedToAddFlatLine, adaptStartDate } from 'utils/charts';

export const adaptInvestmentPerformance = (
    data,
    currency,
    chartStartDate,
    filter,
    isTabletSize,
    getFormattedCurrency,
    getFormattedDate,
) => {
    if (checkNullData(data)) return [{ data: [] }];

    const values = adaptPlotsAmount(data, isTabletSize);
    const adaptedData = [];

    values.forEach(({ Value, Date: date }, idx) => {
        if (idx === 0) {
            const start = adaptStartDate(chartStartDate, date);

            if (isNeedToAddFlatLine(start, date)) {
                adaptedData.push([getTimestamp(start), Math.round(Value * 100) / 100]);
                adaptedData.push([getTimestamp(subtractUnits(date, 1, 'days')), Math.round(Value * 100) / 100]);
            }
        }

        adaptedData.push([getTimestamp(date), Math.round(Value * 100) / 100]);
    });

    return [
        {
            data: adaptedData,
            tooltipFormat: (number, date) => formatWithDate(
                getFormattedCurrency
                    ? getFormattedCurrency(number, { currency: currency.CurrencyCode })
                    : formatCurrencyWithPrecision(number, 0, currency.CurrencyCode),
                date,
                getFormattedDate,
            ),
        },
    ];
};
