import { CellLink, CellPDFView } from 'components/renderers';
import { sorterByDate, sorterString } from 'utils/sorting';

export const getColumns = (t, isTabletSize) => [
    {
        key: 'nameCol',
        title: t('clientDashboard.documents.name'),
        width: 200,
        render: CellPDFView,
        sortable: true,
        sorter: sorterString('nameCol.value'),
    },
    {
        key: 'type',
        title: t('clientDashboard.documents.type'),
        sorter: sorterString('type'),
        sortable: true,
        width: 180,
        hidden: isTabletSize,
    },
    {
        key: 'category',
        title: t('clientDashboard.documents.category'),
        sorter: sorterString('category'),
        sortable: true,
        width: 180,
    },
    {
        key: 'createDate',
        title: t('clientDashboard.documents.createDate'),
        sorter: sorterByDate('fullDate'),
        sortable: true,
        width: 160,
        minWidth: 60,
    },
    {
        key: 'contactId',
        title: t('clientDashboard.documents.accountPortfolio'),
        render: CellLink,
        sorter: sorterString('contactId.label'),
        sortable: true,
        width: 160,
        className: 'table-cell_account',
    },
    {
        key: 'icon',
        title: '',
        titleType: 'columns',
        width: 30,
        minWidth: 30,
    },
];

/*
export const filters = data.map((item) => (
    Object.values(item)
))
    .reduce((a, b) => (
        a.concat(b)
    ), [])
    .filter((val, i, arr) => (
        arr.indexOf(val) === i && val !== ''
    ));
 */
