import {
    checkNullData, getParent, roundAllocations, sum,
} from 'utils';
import { sortAssets } from 'utils/sortingAllocation';
import {
    formatBigNumber, formatCurrency, percentIsZero, validateData, validateNumber,
} from 'utils/formatting';
import { adaptAllocationGroups } from 'adaptors/adaptAllocationGroups';
import {
    BAR, LIST, PIE, PIE_SA,
} from 'constants/constants';
import { adaptProjection } from 'adaptors/adaptAllocations';
import { LiquidityId } from 'constants/instrument';
import { isLiquidity, adjustPrice, redirectToReadOnlyPositionDetails } from '../../common/utils';

export const groupPositions = (
    {
        initialPositions = [],
        changedPositions = [],
        portfolioCurrency,
        portfolioValue,
        baseUrl,
        t,
        getFormattedNumber,
        getFormattedCurrency,
    },
) => {
    const data = [...initialPositions];
    const changedPositionsObj = changedPositions.reduce((acc, item) => ({
        ...acc, [item.Security.Id]: item,
    }), {});
    const newlyAddedData = changedPositions.filter((item) => !data
        .find((itemData) => itemData.Security.Id === item.Security.Id));
    const newBalance = changedPositions.reduce((acc, item) => (
        acc + adjustPrice(item.AmountAccountCurrency, { ...item.Security })
    ), 0);
    const dataWithoutModified = data.filter((item) => item.Security.Type.Id !== 1
        && !changedPositionsObj[item.Security.Id]);
    const dataWithoutModifiedSum = sum(dataWithoutModified, 'Allocation');
    const hasChanges = changedPositions.length > 0;

    // Check if new positions appeared
    const concatData = data.concat(newlyAddedData);
    const newAllocationsArr = concatData.map((item) => {
        const securityData = item.Security;
        const newValue = changedPositions
            .find((changed) => changed.Security.Id === securityData.Id);
        const currValueData = adjustPrice(
            item.InvestmentValuePortfolioCurrency || 0, { ...securityData },
        );
        const allocationData = item.Allocation || 0;
        let newValuation;
        let newAllocation;

        if (newValue === undefined && securityData.Type.Id !== 1) {
            // If not a liquidity and no changes
            newValuation = 0;
        } else if (securityData.Type.Id === 1) {
            // Liquidity changes
            newValuation = hasChanges ? currValueData - newBalance : 0;
        } else if (newValue !== undefined) {
            // New value + existing value
            newValuation = adjustPrice(
                newValue.AmountAccountCurrency + currValueData, { ...securityData },
            );
        }

        if (newValue === undefined && securityData.Type.Id !== 1) {
            // If not a liquidity and no changes
            newAllocation = allocationData;
        } else if (securityData.Type.Id === 1 && !hasChanges) {
            // If a liquidity and no changes
            newAllocation = allocationData;
        } else {
            // If the are some changes
            newAllocation = parseFloat((newValuation / portfolioValue).toFixed(4));
        }

        return {
            id: securityData.Id,
            value: newValuation,
            Allocation: newAllocation,
            Security: { Id: securityData.Id },
        };
    });
    const newAllocationsForRound = newAllocationsArr
        .filter(({ Allocation, value }) => Allocation === 0 || value !== 0);
    const roundedAllocation = data.length && roundAllocations(data);
    const roundedAllocationNew = roundAllocations(
        newAllocationsForRound || [],
        10000,
        100,
        parseInt((10000 - Math.ceil(dataWithoutModifiedSum * 10000).toFixed(4)), 10),
    );

    const groupByParent = concatData.reduce((obj, item) => {
        const accumulator = { ...obj };
        const securityData = item.Security;
        // Common
        const accParent = getParent(item.Security.AssetClass).Name;
        const currency = validateData(securityData.Currency.CurrencyCode);
        const name = securityData.Type.Id === 1 ? `${t('common.liquidity')} ${currency}` : validateData(securityData.Name);
        // Initial data
        const roundedAllocationData = roundedAllocation.find((n) => n.id === securityData.Id)?.value
            || 0;
        const currValueData = item.InvestmentValuePortfolioCurrency || 0;
        // Modified data
        const newAllocationData = roundedAllocationNew.find((n) => n.id === securityData.Id);
        const newAllocation = newAllocationData?.value ?? roundedAllocationData;
        const newValue = changedPositions.find((n) => n.Security.Id === securityData.Id);
        const newValueData = currValueData + (adjustPrice(
            newValue?.AmountAccountCurrency || 0, { ...securityData },
        ) || 0) - (securityData.Type.Id === LiquidityId ? newBalance : 0);

        const newValueObj = newAllocationsArr.find((n) => n.id === securityData.Id);
        const newValueAllocationAmount = (newValue || newValueObj)
            ? newValueObj?.Allocation : item.Allocation;

        const childItem = {
            id: securityData.Id,
            Item: item,
            Name: {
                label: name,
                // eslint-disable-next-line no-nested-ternary
                link: isLiquidity(securityData)
                    ? undefined
                    : `${baseUrl}/${securityData.Id}/1/3`,
                maxLength: 50,
            },
            ISIN: validateData(securityData.Isin),
            Units: getFormattedNumber(
                validateData(item.Quantity),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            Value: validateNumber(item.InvestmentValuePortfolioCurrency),
            LatestPrice: getFormattedNumber(validateNumber(item.ValuationPrice), {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            AllocationData: roundedAllocationData,
            NewAllocationData: newAllocation,
            AllocationD: {
                currentValue: `${getFormattedNumber(roundedAllocationData, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
                newValue: `${getFormattedNumber(newAllocation, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
            },
            ValueData: {
                currentValue: getFormattedCurrency(
                    validateNumber(currValueData),
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
                newValue: getFormattedCurrency(
                    validateNumber(newValueData),
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
            },
            CurrentValueData: currValueData,
            CurrentValue: formatCurrency(
                validateNumber(currValueData),
                portfolioCurrency,
            ),
            NewValueData: newValueData,
            NewValue: formatCurrency(
                newValueData,
                portfolioCurrency,
            ),
            Icon: { icon: '' },
            Currency: currency,
            Allocation: {
                old: item.Allocation || 0,
                new: newValueAllocationAmount ?? 0,
            },
        };

        accumulator[accParent] = obj[accParent] || [];
        accumulator[accParent].push(childItem);

        return accumulator;
    }, {});

    const parentAssetClasses = (concatData || []).map(
        (item) => getParent(item.Security.AssetClass),
    );

    const parentsIds = Object.keys(groupByParent).map((key) => ({
        key, id: parentAssetClasses?.find((i) => i.Name === key)?.Id,
    }));

    return sortAssets(parentsIds).map(({ key, id }) => {
        const groupParent = groupByParent[key];

        return {
            id,
            Name: key,
            key: `${key} - ${id}`,
            Value: sum(groupParent, 'Value'),
            AllocationD: {
                currentValue: `${getFormattedNumber(sum(groupParent, 'AllocationData'), {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
                newValue: `${getFormattedNumber(sum(groupParent, 'NewAllocationData'), {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
            },
            ValueData: {
                currentValue: getFormattedCurrency(
                    sum(groupParent, 'CurrentValueData'),
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
                newValue: getFormattedCurrency(
                    sum(groupParent, 'NewValueData'),
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
            },
            CurrentValue: formatCurrency(
                sum(groupParent, 'CurrentValueData'),
                portfolioCurrency,
            ),
            NewValData: sum(groupParent, 'NewValueData'),
            NewValue: formatCurrency(
                sum(groupParent, 'NewValueData'),
                portfolioCurrency,
            ),
            Allocation: `${sum(groupParent, 'AllocationData').toFixed(2)}%`,
            Icon: '',
            children: groupParent,
        };
    });
};

export const groupForAllocation = (positions) => positions
    .map(({ children }) => children)
    .flat()
    .map((item) => ({
        InstrumentId: item.id,
        Allocation: item.Allocation?.new,
    }));

export const adaptModify = ({
    initialPositions,
    changedPositions,
    currency,
    portfolioValue,
    modifiedLocally,
    baseUrl,
    t,
    getFormattedNumber,
    getFormattedCurrency,
} = {}) => {
    if (checkNullData(initialPositions) || checkNullData(currency)
        || checkNullData(portfolioValue)) {
        return {
            positions: [],
            positionsAllocation: [],
            isPositiveCashAmount: true,
        };
    }

    const positions = groupPositions({
        initialPositions,
        changedPositions,
        portfolioCurrency: currency,
        portfolioValue,
        baseUrl,
        t,
        getFormattedNumber,
        getFormattedCurrency,
    });
    const modifiedObj = modifiedLocally.reduce((acc, item) => ({
        ...acc, [item.id]: item.IsNotAdvised,
    }), {});

    const isPositiveCashAmount = positions.find((i) => i.NewValData <= 0);

    return {
        positions,
        isPositiveCashAmount: !isPositiveCashAmount,
        positionsAllocation: groupForAllocation(positions)
            .filter(({ Allocation }) => Allocation !== 0)
            .map((item) => ({ ...item, IsNotAdvised: modifiedObj[item.InstrumentId] || false })),
    };
};

export const groupModifiedPositions = ({
    portfolioValue, portfolioCurrency, positions = [],
    positionsModified = [], positionsModifiedAllocations = [],
    baseUrl, t, getFormattedNumber, getFormattedCurrency,
} = {}) => {
    const roundedAllocation = positions?.length ? roundAllocations(positions) : [];
    const roundedNewAllocation = roundAllocations(positionsModifiedAllocations.map((item) => ({
        Id: item.InstrumentId, Allocation: item.Allocation,
    })) || []);
    const newlyAddedData = positionsModified.filter((item) => !positions
        .find((itemData) => itemData.Security.Id === item.Security.Id));
    const concatData = positions.concat(newlyAddedData);
    const newBalance = positionsModified.reduce((acc, item) => (
        acc + adjustPrice(item.AmountAccountCurrency, { ...item.Security })
    ), 0);
    const groupByParent = concatData.reduce((obj, item) => {
        const securityData = item.Security;
        const accumulator = { ...obj };
        const roundedAllocData = roundedAllocation.find((n) => n.id === securityData.Id);
        const roundedNewAllocData = roundedNewAllocation.find((n) => n.id === securityData.Id);
        const roundedNewAllocOrig = positionsModifiedAllocations
            ?.find((n) => n.InstrumentId === securityData.Id);
        const roundedAlloc = roundedAllocData ? roundedAllocData.value : 0;
        const roundedNewAlloc = roundedNewAllocOrig?.Allocation === item.Allocation
            ? (roundedAllocData?.value || 0) : (roundedNewAllocData?.value || 0);
        const currentValueData = item?.InvestmentValuePortfolioCurrency || (roundedAlloc
            ? (roundedAlloc * portfolioValue) / 100
            : 0);
        const newValueChange = roundedNewAllocOrig?.Allocation === item.Allocation
            ? item?.InvestmentValuePortfolioCurrency : undefined;
        const modifiedItem = positionsModified.find((temp) => temp.Security.Id === securityData.Id);
        // eslint-disable-next-line no-nested-ternary
        const modifiedAmount = securityData.Type.Id === 1 && newBalance !== 0
            ? currentValueData - newBalance
            : modifiedItem?.AmountAccountCurrency
                ? Math.abs(currentValueData + (adjustPrice(
                    modifiedItem?.AmountAccountCurrency || 0, { ...securityData },
                ) || 0))
                : undefined;
        const newValueData = newValueChange || modifiedAmount
            || (roundedNewAlloc ? (roundedNewAlloc * portfolioValue) / 100 : 0);

        const accParent = getParent(securityData.AssetClass).Name;
        const baseUrlLink = baseUrl && `${baseUrl}/${securityData.Id}/2/overview`;
        const link = isLiquidity(securityData) ? undefined : baseUrlLink;
        const currency = validateData(securityData.Currency.CurrencyCode);
        const name = securityData.Type.Id === 1 ? `${t('common.liquidity')} ${currency}` : validateData(securityData.Name);

        accumulator[accParent] = obj[accParent] || [];
        accumulator[accParent].push({
            id: securityData.Id,
            name: {
                label: name,
                link,
                maxLength: 28,
            },
            valueData: {
                currentValue: getFormattedCurrency(
                    currentValueData,
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
                newValue: getFormattedCurrency(
                    newValueData,
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
            },
            allocationD: {
                currentValue: `${getFormattedNumber(roundedAlloc, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
                newValue: `${getFormattedNumber(roundedNewAlloc, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
            },
            isin: validateData(securityData.Isin),
            securityId: validateNumber(securityData.Id),
            url: '',
            allocationData: roundedAlloc,
            allocationNewData: roundedNewAlloc,
            allocation: `${percentIsZero(roundedAlloc)}%`,
            currency,
            units: getFormattedNumber(
                validateNumber(item.Quantity),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            performance: getFormattedNumber(
                validateNumber(item.Performance),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            latestPrice: getFormattedNumber(
                validateNumber(item.ValuationPrice),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            valuation: getFormattedCurrency(
                validateNumber(item.InvestmentValuePortfolioCurrency),
                {
                    currency: portfolioCurrency,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            currentValueData,
            newValueData,
            fxRate: getFormattedNumber(validateNumber(item.FxRate), {
                maximumFractionDigits: 4,
                minimumFractionDigits: 4,
            }),
            valuationData: getFormattedNumber(
                validateNumber(item.InvestmentValuePortfolioCurrency),
            ),
            performanceCCY: getFormattedNumber(validateNumber(item.MonetaryPerformance)),
            actions: isLiquidity(securityData)
                ? {}
                : {
                    value: 'action',
                    actions: [
                        {
                            text: t('clientDashboard.portfolio.showMoreDetails'),
                            onClick: () => redirectToReadOnlyPositionDetails(
                                baseUrl, securityData.Id, 1,
                            ),
                        },
                        {
                            text: t('clientDashboard.portfolio.showPerformance'),
                            onClick: () => redirectToReadOnlyPositionDetails(
                                baseUrl, securityData.Id, 2,
                            ),
                        },
                    ],
                },
        });

        return accumulator;
    }, {});

    const parentAssetClasses = (positions.concat(positionsModified) || [])
        .map((item) => getParent(item.Security.AssetClass));
    const parentsIds = Object.keys(groupByParent).map((key) => ({
        key, id: parentAssetClasses?.find((i) => i.Name === key)?.Id,
    }));

    return sortAssets(parentsIds).map(({ key, id }) => {
        const groupMembersData = groupByParent[key];

        return {
            id,
            valuation: getFormattedCurrency(sum(groupMembersData, 'valuationData'), { currency: portfolioCurrency }),
            allocation: `${percentIsZero(formatBigNumber(sum(groupMembersData, 'allocationData')))}%`,
            allocationData: sum(groupMembersData, 'allocationData'),
            allocationNewData: sum(groupMembersData, 'allocationNewData'),
            allocationD: {
                currentValue: `${getFormattedNumber(sum(groupMembersData, 'allocationData'), {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
                newValue: `${getFormattedNumber(sum(groupMembersData, 'allocationNewData'), {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
            },
            valueData: {
                currentValue: getFormattedCurrency(
                    sum(groupMembersData, 'currentValueData'),
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
                newValue: getFormattedCurrency(
                    sum(groupMembersData, 'newValueData'),
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
            },
            name: key,
            children: groupMembersData,
            icon: 'action',
            actions: {},
        };
    });
};

export const adaptModelData = (options) => {
    const {
        data, positions = [], positionsModified = [], positionsModifiedAllocations = [],
        getFormattedNumber, getFormattedCurrency,
    } = (options || {});
    const t = options?.t || ((d) => d);
    const positionsEditedObj = positionsModifiedAllocations
        ? positionsModifiedAllocations.reduce((acc, item) => ({
            ...acc,
            [item.InstrumentId]: item.Allocation || 0,
        }), {})
        : {};
    const newlyAddedData = positionsModified.filter((item) => !positions
        .find((itemData) => itemData.Security.Id === item.Security.Id));
    const newBalance = positionsModified.reduce((acc, item) => (
        acc + adjustPrice(item.AmountAccountCurrency, { ...item.Security })
    ), 0);
    const concatData = positions.concat(newlyAddedData);
    const positionsModifiedObj = positionsModified.reduce((acc, item) => ({
        ...acc, [item.Security.Id]: item,
    }), {});
    const positionsModifiedFull = concatData.map((item) => ({
        ...item,
        Allocation: positionsEditedObj[item.Security.Id] || 0,
        InvestmentValuePortfolioCurrency: item.Security.Type.Id === 1 && newBalance !== 0
            ? (item?.InvestmentValuePortfolioCurrency || 0) - newBalance
            : (item?.InvestmentValuePortfolioCurrency || 0)
                + (adjustPrice(
                    positionsModifiedObj[item.Security.Id]?.AmountAccountCurrency || 0,
                    { ...item.Security },
                ) || 0),
    }));
    const IsNotAdvisedObj = positionsModifiedAllocations.reduce((acc, item) => ({
        ...acc, [item.InstrumentId]: item.IsNotAdvised,
    }), {});
    const investmentAllocations = positionsModifiedAllocations
        .filter(({ Allocation }) => Allocation !== 0);
    const investmentAllocationsRound = roundAllocations(
        positionsModifiedAllocations.map((item) => ({ ...item, Id: item.InstrumentId })),
        10000, 10000,
    );

    return ({
        ...data,
        investmentAllocation: {
            chart: adaptAllocationGroups({
                securitiesValue: data?.portfolioValue,
                comparedAllocations: data?.allocationGroups,
                chartTypesList: [PIE, PIE_SA, BAR, LIST],
                positions: positionsModifiedFull,
                currency: data?.currency,
                t,
                getFormattedNumber,
                getFormattedCurrency,
            }),
            currency: data?.currency,
        },
        investmentAllocations,
        investmentAllocationsRound: investmentAllocationsRound.map(({ id, value }) => ({
            InstrumentId: id, Allocation: value, IsNotAdvised: IsNotAdvisedObj[id],
        })),
        projection: adaptProjection({
            data: positionsModifiedAllocations.map((item) => ({
                Id: item.InstrumentId, Allocation: item.Allocation,
            })).filter(({ Allocation }) => Allocation !== 0),
            CurrencyId: data?.currencyId,
            ProjectionYears: 5,
            InitialInvestmentAmount: data?.portfolioValue,
        }),
    });
};
