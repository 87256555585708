import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import useLeaveRoute from 'hooks/useLeaveRoute';
import { useNewPortfolioData } from './hooks/useNewPortfolioData';
import { useNewPortfolioLayout } from './hooks/useNewPortfolioLayout';
import Modify from './pages/Modify';
import Summary from './pages/Summary';
import Completion from './pages/Completion';
import AddPosition from './pages/AddPosition';
import Interaction from './pages/Interaction/Interaction';
import PositionDetails from './pages/PositionDetails';
import CreateNewPortfolio from './CreateNewPortfolio';
import './useNewPortfolioLayout.css';

const Router = (props) => {
    const { match: { path, url } } = props;
    const withProps = (Component) => (defProps) => <Component {...props} {...defProps} />;

    const { clearNewPortfolio, clearModifiedPositions } = useNewPortfolioData();

    useLeaveRoute(url, () => {
        clearNewPortfolio();
        clearModifiedPositions();
    });

    return (
        <Switch>
            <Route exact path={`${path}`} render={withProps(CreateNewPortfolio)} />
            <Route exact path={`${path}/modify`} component={Modify} />
            <Route exact path={`${path}/position/:positionId`} component={PositionDetails} />
            <Route exact path={`${path}/add-position`} component={AddPosition} />
            <Route exact path={`${path}/add-position/:positionId`} component={(p) => PositionDetails({ ...p, isModify: true })} />
            <Route exact path={`${path}/summary/position/:positionId`} component={(p) => PositionDetails({ ...p, isSummary: true })} />
            <Route exact path={`${path}/summary`} component={Summary} />
            <Route exact path={`${path}/completion`} render={withProps(Completion)} />
            <Route exact path={`${path}/interaction`} render={withProps(Interaction)} />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
};

Router.displayName = 'Router';

export default Router;
