import React, {
    useState, useEffect, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import {
    Table,
    Button,
    Modal,
    Infobox,
} from 'ui-library';
import EmptyContent from 'components/EmptyContent';
import WidgetError from 'components/WidgetError';
import { useLocale, useFormatting } from 'locale';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject,
} from 'constants/portfolioProducts';
import { CHANGE_MODEL, CHANGE_STRATEGY } from 'constants/constants';
import { adaptConstraints } from 'adaptors/adaptConstraints';
import { goalSummarySelector, onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { getModifyColumns } from '../../constants';
import { adaptPositionsModify } from '../../adapters/adaptPositionsModify';
import { adaptPositions } from '../../adapters/adaptPositions';
import { usePositionsModify } from '../../hooks/usePositionsModify';
import { useReviewData } from '../../hooks/useReviewData';
import { useModifiedSecurities } from '../../hooks/useModifiedSecurities';
import { adaptSecuritiesForSave } from '../../adapters/adaptSecuritiesForSave';
import { useSaveProposals } from '../../hooks/useSaveProposals';
import { useLiquidityCurrencyPosition } from './hooks/useLiquidityCurrencyPosition';
import OnBoardingBaseTemplate from '../../components/OnBoardingBaseTemplate';
import { usePositionsConstrains } from '../../hooks/usePositionsConstrains';

import './ModifyGoal.css';

const getPositionSum = (positions) => (isEmpty(positions.positions)
    ? 0 : parseFloat(positions.positions
        .reduce((acc, item) => (acc + parseFloat(item.value)), 0)
        .toFixed(2)));

function ModifyGoal(props) {
    const {
        contactId, onPageChange, changeStep, location: { pathname },
    } = props;
    const positionLink = `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`;

    const {
        product,
        getGoalDetails,
        clearModifiedPositions,
        saveProposalId,
        getCreatedProposalId,
        getModifiedPositions,
        saveModifiedPositions,
        clearGoalChanges,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        updateProposal, isLoadingUpdate, errorUpdate,
    } = useOnBoardingSelector(goalSummarySelector);
    const goalDetails = useMemo(() => getGoalDetails(), [getGoalDetails]);
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();
    const { numberSeparators } = useLocale();
    const { t } = useTranslation();

    const {
        data: reviewData,
        isLoading: isLoadingReviewData,
        error: reviewDataError,
    } = useReviewData(contactId, { nameLength: 35, positionLink });

    const { positions: modifiedPositions } = useModifiedSecurities({
        preview: false, positionLink,
    });
    const {
        isLoading: isLoadingLiquidityCurrency, error: errorLiquidityCurrency,
    } = useLiquidityCurrencyPosition({ modifiedPositions, reviewData, onPageChange });
    const {
        saveProposal,
        isCreatingProposal,
        errorCreatingProposal,
    } = useSaveProposals(contactId);

    const [rawPositions, setRawPositions] = useState([]);
    const equalPositions = useMemo(() => {
        const rawPositionsLength = (rawPositions || []).reduce((acc, group) => acc
            + group.children.length, 0);
        const modifiedPositionsLength = (modifiedPositions || []).reduce((acc, group) => acc
            + group.children.length, 0);

        return modifiedPositionsLength === rawPositionsLength;
    }, [modifiedPositions, rawPositions]);

    useEffect(() => {
        if (modifiedPositions && !equalPositions) {
            setRawPositions(modifiedPositions);
        }
    }, [modifiedPositions, equalPositions]);

    useEffect(() => {
        if (modifiedPositions?.length > 0) {
            setRawPositions(modifiedPositions);

            return;
        }
        if (reviewData?.positionsRaw) {
            const adapted = adaptPositions(reviewData.positionsRaw, {
                portfolioCurrency: reviewData?.currency,
                portfolioValue: reviewData?.portfolioValue,
                isNew: false,
                nameLength: 35,
                getFormattedNumber,
                getFormattedCurrency,
                t,
            });

            console.log('##  adapted');

            setRawPositions(adapted);
        }
    }, [reviewData.positionsRaw]);

    const positionsData = useMemo(() => adaptPositionsModify(rawPositions, {
        t, getFormattedNumber, options: { positionLink, maxLength: 35 },
    }), [getFormattedNumber, positionLink, rawPositions, t]);

    const { positions, positionsChanged } = usePositionsModify(
        isLoadingReviewData,
        positionsData,
        reviewData?.portfolioValue,
        reviewData?.currency,
    );


    const { positionsConstraints } = usePositionsConstrains({
        positions,
        optimizationConstraints: reviewData?.optimizationConstraints,
    });

    const totalPositions = getPositionSum(positionsChanged);

    const saveModified = () => {
        saveModifiedPositions({
            positions: positions.positions,
            positionsChanged: positionsChanged.positions,
        });
    };

    const nextAction = useMemo(() => generateObject({
        [ADVISORY]: () => onPageChange(''),
        [DISCRETIONARY]: () => onPageChange(''),
        [EXECUTION_ONLY]: () => changeStep(5),
    }).getByName(product?.name), [onPageChange, changeStep, product?.name]);
    const onNext = useCallback(() => {
        saveModified();
        clearGoalChanges();
        sessionStorage.removeItem(CHANGE_STRATEGY);
        sessionStorage.removeItem(CHANGE_MODEL);
        nextAction();
    }, [clearGoalChanges, nextAction, saveModified]);
    const handleModify = async () => {
        const isUpdate = typeof getCreatedProposalId === 'number';

        const securities = adaptSecuritiesForSave(positions?.positions, isUpdate);

        if (isUpdate) {
            if (!isEqual(positionsChanged.positions, getModifiedPositions?.positionsChanged)) {
                const params = {
                    BenchmarkId: {
                        DoUpdate: true,
                        Value: null,
                    },
                    ModelPortfolioId: {
                        DoUpdate: true,
                        Value: null,
                    },
                    OptimizationConstraints: {
                        DoUpdate: true,
                        Value: adaptConstraints(reviewData?.optimizationConstraints),
                    },
                    InstrumentAllocations: {
                        DoUpdate: true,
                        Value: securities,
                    },
                };

                await updateProposal(getCreatedProposalId, params);
                onNext();

                return;
            }
        } else {
            const { modelPortfolioId, ...proposalData } = reviewData;
            const savedProposalData = await saveProposal(contactId, proposalData, securities);

            if (savedProposalData?.createdProposalId) {
                saveProposalId(savedProposalData.createdProposalId);
                onNext();

                return;
            }
        }

        nextAction();
    };

    const onPrev = () => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: async () => {
                const isUpdate = typeof getCreatedProposalId === 'number';

                if (isUpdate) {
                    const params = {
                        ModelPortfolioId: {
                            DoUpdate: true,
                            Value: reviewData.modelPortfolioId,
                        },
                        OptimizationConstraints: {
                            DoUpdate: true,
                            Value: adaptConstraints(reviewData?.optimizationConstraints),
                        },
                    };

                    await updateProposal(getCreatedProposalId, params);
                }
                clearModifiedPositions();
                onPageChange('');
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };

    const handleAddPosition = () => {
        saveModified();
        onPageChange('add-position');
    };

    const errorMessages = useMemo(() => ([
        totalPositions !== 100 && t('clientDashboard.portfolio.errorSum'),
        positionsConstraints?.isConstraints
        && `${t('onBoarding.modify.positions.constraints.part1')} ${positionsConstraints?.constraintsValue}% ${t('onBoarding.modify.positions.constraints.part2')} `,
    ]), [positionsConstraints?.isConstraints, totalPositions]);

    return (
        <OnBoardingBaseTemplate
            title={`${goalDetails.goalName} - ${t('clientDashboard.portfolio.modify')}`}
            error={errorLiquidityCurrency || reviewDataError}
            isLoading={isLoadingLiquidityCurrency || isLoadingReviewData}
            className="goal-review on-boarding_modify"
            prevButton={{
                loading: isCreatingProposal || isLoadingUpdate,
                onClick: onPrev,
            }}
            nextButton={{
                text: t('onBoarding.save'),
                disabled: totalPositions !== 100 || positionsConstraints?.isConstraints,
                loading: isCreatingProposal || isLoadingUpdate,
                onClick: handleModify,
            }}
            topAction={(
                <Button
                    type="secondary"
                    size="small"
                    disabled={isLoadingReviewData}
                    onClick={handleAddPosition}
                >
                    {t('clientDashboard.portfolioEdit.addPosition')}
                </Button>
            )}
            buttonsRowError={(
                <>
                    {(totalPositions !== 100 || positionsConstraints?.isConstraints)
                        && <WidgetError accent error message={errorMessages} />}
                    {errorUpdate && <Infobox error>{errorUpdate.message}</Infobox>}
                    {errorCreatingProposal
                        && <Infobox error>{errorCreatingProposal.message}</Infobox>}
                </>
            )}
        >
            <EmptyContent data={reviewData} text={t('advisoryDashboard.noData')}>
                <div className="modify-goal_content">
                    <Table
                        columns={getModifyColumns(t, numberSeparators)}
                        data={positions.positions}
                        footer={positionsChanged.positionsFooter}
                        defaultExpandAllRows
                    />
                </div>
            </EmptyContent>
        </OnBoardingBaseTemplate>
    );
}

ModifyGoal.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
    onPageChange: PropTypes.func,
    changeStep: PropTypes.func,
};

ModifyGoal.defaultProps = {
    onPageChange: () => {
    },
    changeStep: () => {
    },
};

export default ModifyGoal;
