import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import { getClientId } from 'utils';
import { formatDate } from 'utils/datetime';
import { useClientProfile } from 'prodivers/clientProfile';
import { modelPortfolioSelector, usePortfolio, usePortfolioSelector } from 'domain/Portfolio';
import { useCreateProposal } from '../../common/hooks/useCreateProposal';
import { mapActionTypeName, REBALANCE } from '../../../constants';
import { useExecuteStrategy } from '../../common/hooks/useExecuteStrategy';
import InteractionAction from '../../../containers/InteractionAction';

function Interaction(props) {
    const {
        match: { params: { dfsClientId, portfolioId } },
    } = props;
    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();

    // Client Provider
    const { clientName } = useClientProfile();

    // Portfolio Domain
    const { portfolio: { data, isLoading, error } } = usePortfolio();
    const { data: modelPortfolio } = usePortfolioSelector(modelPortfolioSelector);

    // Hooks
    const { onCreateProposal, isCreating, errorCreating } = useCreateProposal(REBALANCE);
    const { onAcceptExecute, isAccepting, errorAccepting } = useExecuteStrategy(
        clientId, dfsClientId, portfolioId, REBALANCE,
    );

    // Callbacks
    const onNext = async (type) => {
        if (type === 'proposal') {
            const nameProposal = `${mapActionTypeName(REBALANCE, t)} ${t('proposal.ofPortfolio')} ${data?.title} ${t('proposal.on')} ${formatDate(new Date())}`;

            onCreateProposal({
                clientId,
                dfsClientId,
                portfolioId,
                productId: data.productId,
                constraints: data.constraints,
                allocations: modelPortfolio.investmentAllocations,
                name: nameProposal,
                modelPortfolioId: data.modelPortfolioId,
                contactGroupId: data.contactGroupId,
            });
        } else {
            onAcceptExecute({ modelPortfolioId: data.modelPortfolioId });
        }
    };

    return (
        <Preloader isLoading={isLoading} error={error}>
            <InteractionAction
                dfsClientId={dfsClientId}
                actionType={REBALANCE}
                portfolioId={portfolioId}
                portfolioTitle={data.title}
                isLoadingContinue={isAccepting || isCreating}
                errorContinue={errorAccepting || errorCreating}
                onNext={onNext}
                clientName={clientName}
            />
        </Preloader>
    );
}

Interaction.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

Interaction.defaultProps = {
};

export default Interaction;
