import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const LinkNews = (props) => {
    const {
        link, id, children, linkBase,
    } = props;

    if (link) {
        return (
            <a
                className="LinkNews"
                href={link}
                rel="noopener noreferrer"
                target="_blank"
            >
                {children}
            </a>
        );
    }


    return (
        <Link
            className="LinkNews"
            to={{ pathname: `/${linkBase || 'news'}/${id}`, query: { ...props } }}
        >
            {children}
        </Link>
    );
};

LinkNews.propTypes = {
    id: PropTypes.number,
    link: PropTypes.string,
    linkBase: PropTypes.string,
    children: PropTypes.node,

};

LinkNews.defaultProps = {
    id: null,
    link: null,
    linkBase: null,
    children: null,
};
