import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TabPane, Tabs } from 'ui-library';
import { ListTable } from 'templates/ListPreview';

const ListComponent = ({
    tab,
    changeTab,
    needUpdate,
    useDynamicSorting,
    onRowSelect,
    onNewInteraction,
    isLoading,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Tabs activeKey={tab} defaultActiveKey="upcoming" onChange={changeTab}>
            <TabPane tab={t('interaction.upcoming')} key="upcoming">
                <ListTable
                    noData={t('interaction.noData')}
                    noDataButtonText={t('interaction.newInteraction')}
                    noDataOnClick={onNewInteraction}
                    needUpdate={needUpdate}
                    active={tab === 'upcoming'}
                    useDynamicSorting={useDynamicSorting}
                    onRowSelect={onRowSelect}
                    isLoading={isLoading}
                    {...props}
                />
            </TabPane>
            <TabPane tab={t('interaction.past')} key="past">
                <ListTable
                    noData={t('interaction.noData')}
                    noDataOnClick={onNewInteraction}
                    useDynamicSorting={useDynamicSorting}
                    needUpdate={needUpdate}
                    active={tab === 'past'}
                    onRowSelect={onRowSelect}
                    isLoading={isLoading}
                    {...props}
                />
            </TabPane>
            <TabPane tab={t('interaction.canceled')} key="canceled">
                <ListTable
                    noData={t('interaction.noData')}
                    noDataOnClick={onNewInteraction}
                    needUpdate={needUpdate}
                    useDynamicSorting={useDynamicSorting}
                    onRowSelect={onRowSelect}
                    active={tab === 'canceled'}
                    isLoading={isLoading}
                    {...props}
                />
            </TabPane>
        </Tabs>
    );
};

ListComponent.propTypes = {
    tab: PropTypes.string.isRequired,
    needUpdate: PropTypes.bool.isRequired,
    changeTab: PropTypes.func.isRequired,
    useDynamicSorting: PropTypes.bool,
    isLoading: PropTypes.bool,
    onRowSelect: PropTypes.func.isRequired,
    onNewInteraction: PropTypes.func.isRequired,
};

ListComponent.defaultProps = {
    useDynamicSorting: false,
    isLoading: false,

};

export default ListComponent;
