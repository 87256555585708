import { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { changeLocale } from 'ui-library';
import { setup } from 'additiv-services';
import { post } from '@additiv-modularity/event-bus';
import { importLocale } from 'utils/datetime';
import { languagesAdapt } from '../adaptors/languagesAdapt';

export const useLanguageSwitcher = () => {
    const { i18n, t } = useTranslation();
    const languages = useMemo(() => languagesAdapt(global.availableLanguages, t), [
        global.availableLanguages,
        i18n.language,
    ]);

    const changeLanguage = useCallback(
        (lang) => {
            i18n.changeLanguage(lang);
            importLocale(lang);
            changeLocale(lang);
            setup({
                CLIENT_SERVICE_HEADERS: { 'accept-language': lang },
            });
        },
        [i18n.language],
    );

    useEffect(() => {
        importLocale(i18n.language);
        changeLocale(i18n.language);
        setup({
            CLIENT_SERVICE_HEADERS: { 'accept-language': i18n.language },
        });
    }, []);

    useEffect(() => {
        post('i18n/language', { language: i18n.language });
        try {
            if (!global.availableLanguages.map(({ value }) => value).includes(i18n.language)) {
                if (i18n.options.fallbackLng && i18n.options.fallbackLng.length) {
                    changeLanguage(i18n.options.fallbackLng[0]);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }, [i18n.language]);

    return {
        languages, changeLanguage, language: i18n.language, t,
    };
};
