import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {
    Modal, Accordion, AccordionPanel as Panel, Infobox,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import Allocation from 'components/AllocationCompare';
import PositionsCompare from 'components/PositionsCompare';
import ChangeModelOverview from 'components/ChangeModelOverview';
import Analysis from 'components/Analysis';
import { useModelList } from 'hooks/useModelList';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useModelChange } from 'hooks/useModelChange';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import { CHANGE_STRATEGY } from 'constants/constants';
import { adaptConstraints } from 'adaptors/adaptConstraints';
import { goalSummarySelector, onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useReviewData } from '../../../../hooks/useReviewData';
import { useSaveProposals } from '../../../../hooks/useSaveProposals';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { useGoalReach } from '../../hooks/useGoalReach';
import './ChangeModel.css';

function ChangeModel(props) {
    const { onPageChange, contactId, location: { pathname } } = props;
    const { t } = useTranslation();
    const [riskCategory, setRiskCategory] = useState(null);
    const [errorSameStrategy, setErrorSameStrategy] = useState(false);
    const positionLink = `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`;

    // OnBoarding domain
    const {
        product, getGoalDetails, getGoalChanges, saveGoalChanges,
        clearGoalChanges, saveProposalId, getRiskCategory, createdProposalId,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        updateProposal, isLoadingUpdate, errorUpdate,
    } = useOnBoardingSelector(goalSummarySelector);
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const goalChanges = useMemo(() => (Number.isInteger(+getGoalChanges())
        ? getGoalChanges() : undefined), [getGoalChanges]);

    // Hooks
    const {
        data, isLoading, error, rawData,
    } = useReviewData(contactId, { positionLink });
    const {
        data: modelList, isLoading: isLoadingModelList, error: errorModelList,
    } = useModelList({ productId: +product.id, riskCategoryId: riskCategory?.Id });
    const fullData = useMemo(() => ({
        ...data,
        productId: +product.id,
        investedAmount: +goalDetails.initialInvestment,
        portfolioValue: +goalDetails.initialInvestment,
    }), [data, goalDetails, product]);
    const {
        modelData: strategyData, positions, modelId,
        isLoadingModelData, errorModelData, onModelChange, onModelReset,
    } = useModelChange({
        portfolio: fullData, portfolioPositions: rawData?.Positions, baseUrl: positionLink,
    });
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);
    const {
        analysisData, isLoadingModel, errorModel,
        onPerformanceChange, performanceSelected, projectionResponse,
    } = useAnalysisDetails({
        portfolio: data,
        strategy: strategyData,
        positions,
        isLoading,
        benchmark,
    });
    const { saveProposal, isCreatingProposal, errorCreatingProposal } = useSaveProposals(contactId);

    // Data
    const isLoadingData = useMemo(() => isLoading || isLoadingModelData,
        [isLoading, isLoadingModelData]);
    const errorData = useMemo(() => error || errorModelData,
        [error, errorModelData]);

    // Effects
    const { dataExternal, isGoalReach } = useGoalReach({
        goalState: goalDetails, projectionResponse,
    });

    useEffect(() => {
        (async () => {
            if (goalChanges) {
                setRiskCategory(rawData?.RiskCategory);
            } else {
                const risk = await getRiskCategory();

                setRiskCategory(risk);
            }
        })();
    }, [getRiskCategory, rawData, goalChanges]);

    // Renderers
    const AllocationRender = useMemo(() => (
        <Allocation
            data={data.investmentAllocation}
            dataNew={strategyData?.investmentAllocation || data.investmentAllocation}
            isLoading={isLoadingData}
            error={errorData}
        />
    ), [data.investmentAllocation, strategyData, isLoadingData, errorData]);

    // Callbacks
    const onStrategyReset = () => {
        sessionStorage.removeItem(CHANGE_STRATEGY);
        onModelReset();
    };
    const onModelChangeFunc = (val) => {
        setErrorSameStrategy(false);
        onModelChange(val);
    };
    const onBackClick = () => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                clearGoalChanges();
                onStrategyReset();
                onPageChange('');
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onConfirm = useCallback(async () => {
        if (data?.modelPortfolioId === +modelId || !modelId) {
            setErrorSameStrategy(true);

            return;
        }

        const isUpdate = typeof createdProposalId === 'number';

        if (isUpdate) {
            const params = {
                BenchmarkId: {
                    DoUpdate: true,
                    Value: strategyData.benchmarkId,
                },
                ModelPortfolioId: {
                    DoUpdate: true,
                    Value: strategyData.modelPortfolioId,
                },
                OptimizationConstraints: {
                    DoUpdate: true,
                    Value: adaptConstraints(strategyData?.constraints),
                },
            };

            await updateProposal(createdProposalId, params);
        } else {
            const savedProposalData = await saveProposal(
                contactId,
                strategyData,
                strategyData?.investmentAllocations,
            );

            if (savedProposalData?.createdProposalId) {
                saveProposalId(savedProposalData.createdProposalId);
            }
        }
        saveGoalChanges(modelId);
        onStrategyReset();
        onPageChange('');
    }, [
        contactId,
        data,
        modelId,
        onPageChange,
        saveGoalChanges,
        saveProposal,
        saveProposalId,
        strategyData,
        createdProposalId,
        updateProposal,
        onStrategyReset,
    ]);

    return (
        <OnBoardingBaseTemplate
            title={`${goalDetails.goalName} - ${t('overview.changeModel')}`}
            className="goal-review goal-change-strategy"
            classNameContent="stepper-content-full"
            prevButton={{
                loading: isCreatingProposal || isLoadingUpdate,
                disabled: isLoading,
                onClick: onBackClick,
            }}
            nextButton={{
                text: t('onBoarding.continue'),
                loading: isCreatingProposal || isLoadingUpdate,
                disabled: isLoading,
                onClick: onConfirm,
            }}
            topInfoboxContent={!isGoalReach && !isLoadingModel && !isLoadingBenchmark && (
                <div className="goal-reached">
                    <Infobox accent>{t('onBoarding.cannotReachedGoal')}</Infobox>
                </div>
            )}
            buttonsRowError={(
                <>
                    {errorSameStrategy && <Infobox error>{t('validation.errorChangeSameModel')}</Infobox>}
                    {errorUpdate && <Infobox error>{errorUpdate.message}</Infobox>}
                    {errorCreatingProposal
                        && (<Infobox error>{errorCreatingProposal.message}</Infobox>)}
                </>
            )}
        >
            <Preloader isLoading={isLoading} error={error}>
                <EmptyContent data={{ test: 'test' }} text={t('advisoryDashboard.noData')}>
                    <div className="mb-0 overview change-strategy-overview">
                        <ChangeModelOverview
                            data={data.overview}
                            selectedModel={modelId}
                            isLoading={isLoadingModelList}
                            error={errorModelList}
                            modelList={modelList}
                            onModelChange={onModelChangeFunc}
                        />
                    </div>
                    <div className="content-wrapper">
                        <Accordion defaultActiveKey={['1', '2', '3']}>
                            <Panel header={t('clientDashboard.portfolio.allocation')} key="1" className="allocation">
                                {AllocationRender}
                            </Panel>
                            <Panel header={t('clientDashboard.portfolio.analysis')} key="2" className="analysis">
                                <Analysis
                                    data={analysisData}
                                    onFilterChange={onPerformanceChange}
                                    benchmarkOptions={benchmarkOptions}
                                    benchmarkSelected={benchmarkSelected}
                                    onBenchmarkChange={onBenchmarkChange}
                                    isLoadingBenchmarks={isLoadingBenchmark}
                                    performanceSelected={performanceSelected}
                                    projectionDataExternal={dataExternal}
                                    isLoading={isLoadingModel || isLoadingModelData}
                                    error={errorModel || errorModelData}
                                    isPortfolioProposed
                                />
                            </Panel>
                            <Panel header={t('clientDashboard.portfolio.positions')} className="positions" key="3">
                                <PositionsCompare
                                    data={{ positions }}
                                    isLoading={isLoading}
                                    error={error}
                                    defaultExpandAllRows
                                />
                            </Panel>
                        </Accordion>
                    </div>
                </EmptyContent>
            </Preloader>
        </OnBoardingBaseTemplate>
    );
}

ChangeModel.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
    onPageChange: PropTypes.func,
};

ChangeModel.defaultProps = {
    onPageChange: () => {},
};

export default ChangeModel;
