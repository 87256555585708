import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    find, floor, ceil, reduce,
} from 'lodash/fp';
import { percentIsZero, validateNumber } from 'utils/formatting';
import { sum } from 'utils';
import { Modal } from 'ui-library';
import { parseLocaleNumber, useFormatting, useLocale } from 'locale';
import { LiquidityId } from 'constants/instrument';

const getDecimals = (value) => Number((`${value}`).split('.')[1] || 0);
const findValue = (data, securityId) => find(['securityId', securityId], data).value;

export function usePositionsModify(
    isLoading,
    positionsData,
    portfolioValue = null,
    portfolioCurrency,
) {
    const [positions, setPositions] = useState(positionsData);
    const [positionsChanged, setPositionsChanged] = useState([]);
    const { t } = useTranslation();
    const { locale } = useLocale();
    const { getFormattedCurrency, getFormattedNumber } = useFormatting();

    useEffect(() => {
        if (positionsData?.positions && positionsData?.positionsFooter) {
            const newPositions = positionsData.positions.map(({ children }) => children).flat();
            const allocation = sum(newPositions, 'newAllocationData');
            const valuation = sum(newPositions, 'valuationData');

            setPositions(positionsData);
            setPositionsChanged(
                {
                    positions: positionsData.positions.map((group) => (
                        group.children.map(({ securityId, new: { value } }) => (
                            { securityId, value }
                        )))).flat(),
                    positionsFooter: {
                        ...positionsData.positionsFooter,
                        new: `${getFormattedNumber(allocation)}%`,
                        valuation: getFormattedCurrency(valuation, { currency: portfolioCurrency }),
                    },
                },
            );
        }
    }, [positionsData?.positions, positionsData?.positionsFooter]);

    const onValueChange = (securityId) => (eValue) => {
        const value = eValue?.target ? eValue.target.value : eValue;
        const valueNumeric = (parseLocaleNumber(`${value}`, locale) || 0).toFixed(2);

        setPositions((oldData) => {
            const newPositionValues = oldData.positions.reduce((accum, data) => {
                const newChildrenValue = data.children.reduce((itemAccum, itemData) => {
                    if (itemData.securityId !== securityId) {
                        return itemAccum.concat({ ...itemData });
                    }

                    const valuation = portfolioValue * (valueNumeric / 100);

                    return itemAccum.concat({
                        ...itemData,
                        new: {
                            value: valueNumeric,
                        },
                        units: getFormattedNumber(itemData.typeId === 1
                            ? 1 : Math.round(valuation / itemData.latestPriceData), {
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                        }),
                        newAllocation: `${valueNumeric}%`,
                        newAllocationData: valueNumeric,
                        valuation: getFormattedCurrency(
                            validateNumber(valuation), { currency: portfolioCurrency },
                        ),
                        valuationData: valuation,
                    });
                }, []);

                return accum.concat({
                    ...data,
                    children: newChildrenValue,
                    valuation: getFormattedCurrency(sum(newChildrenValue, 'valuationData'), {
                        currency: portfolioCurrency,
                    }),
                });
            }, []);

            return {
                positions: newPositionValues,
                positionsFooter: {
                    ...oldData.positionsFooter,
                },
            };
        });

        setPositionsChanged((oldData) => {
            const index = oldData.positions.findIndex((item) => item.securityId === securityId);
            const newPositionValues = index !== -1
                ? [
                    ...oldData.positions.slice(0, index),
                    { securityId, value: valueNumeric },
                    ...oldData.positions.slice(index + 1),
                ]
                : [...oldData.positions, { securityId, value: valueNumeric }];
            const val = sum(newPositionValues, 'value');

            return {
                positions: newPositionValues,
                positionsFooter: {
                    ...oldData.positionsFooter,
                    new: `${percentIsZero(getFormattedNumber(val))}%`,
                    valuation: getFormattedCurrency(
                        portfolioValue * (val / 100), { currency: portfolioCurrency },
                    ),
                },
            };
        });
    };


    const onDecrease = (securityId) => {
        const oldValue = parseFloat(findValue(positionsChanged.positions, securityId));

        if (oldValue === 0) return;
        if (getDecimals(oldValue) === 0) {
            onValueChange(securityId)(oldValue - 1);
        } else {
            onValueChange(securityId)(floor(oldValue));
        }
    };

    const onIncrease = (securityId) => {
        const oldValue = parseFloat(findValue(positionsChanged.positions, securityId));

        if (oldValue === 100) return;
        if (getDecimals(oldValue) === 0) {
            onValueChange(securityId)(oldValue + 1);
        } else {
            onValueChange(securityId)(ceil(oldValue));
        }
    };

    const deletePosition = (securityId) => {
        setPositions((oldData) => {
            const newPositionValues = reduce((accum, group) => {
                const children = group.children.filter((i) => i.securityId !== securityId);

                return accum.concat({ ...group, children });
            }, [], oldData.positions);

            return {
                positions: newPositionValues.filter((i) => i.children.length > 0),
                positionsFooter: { ...oldData.positionsFooter },
            };
        });

        setPositionsChanged((oldData) => {
            const newPositionValues = oldData.positions.filter((i) => i.securityId !== securityId);
            const val = sum(newPositionValues, 'value');

            return {
                positions: newPositionValues,
                positionsFooter: {
                    ...oldData.positionsFooter,
                    new: `${percentIsZero(getFormattedNumber(val))}%`,
                    valuation: getFormattedCurrency(
                        portfolioValue * (val / 100), { currency: portfolioCurrency },
                    ),
                },
            };
        });
    };

    const handleDelete = (securityId) => {
        Modal.confirm({
            title: t('onBoarding.remove'),
            content: t('onBoarding.deletePosition.confirmation'),
            okText: t('confirmation.confirm'),
            onOk: () => deletePosition(securityId),
            cancelText: t('confirmation.cancel'),
        });
    };

    const positionsWithChanges = useMemo(
        () => ({
            positions: positions?.positions?.map((group) => {
                const children = group.children.map((item) => {
                    const isUnRemovable = item.typeId === LiquidityId
                        && (portfolioCurrency === item.currency || group.children.length === 1);

                    return {
                        ...item,
                        new: {
                            ...item.new,
                            onChange: onValueChange(item.securityId),
                        },
                        icon: {
                            onIncrease: () => onIncrease(item.securityId),
                            onDecrease: () => onDecrease(item.securityId),
                        },
                        actions: {
                            icon: isUnRemovable ? 'questionmark' : 'close-button',
                            action: isUnRemovable ? () => {} : () => handleDelete(item.securityId),
                            infoText: isUnRemovable ? t('onBoarding.deletePosition.mustBeAtLeastOne') : '',
                            text: isUnRemovable ? '' : t('onBoarding.remove'),
                        },
                    };
                });

                return {
                    ...group,
                    children,
                    valuation: getFormattedCurrency(sum(children, 'valuationData'), { currency: portfolioCurrency }),
                };
            }),
            positionsFooter: positions.positionsFooter,
        }),
        [handleDelete, onDecrease, onIncrease, onValueChange, positions, t],
    );

    return {
        positions: positionsWithChanges,
        positionsChanged,
    };
}
