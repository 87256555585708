export const adaptMessages = (data) => {
    if (data === undefined || data === null) return null;

    return data.Data.map((item) => ({
        id: item.Id,
        Subject: {
            title: item.SenderFullName,
            subtitle: item.Subject,
        },
        Date: item.CreateDate,
    }));
};
