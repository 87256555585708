import { formatMoney, formatNumber } from 'accounting-js';
import { isNumber, getOr } from 'lodash/fp';
import { isToday, isTomorrow, isYesterday } from './index';
import { formatDate, formatTime, getDatesDifference } from './datetime';

export const NA = 'N/A';
export const TYPE_CURRENCY = 'currency';
export const TYPE_PERCENT = 'percent';
export const TYPE_RATIO = 'ratio';
export const TYPE_NUMBER = 'number';
export const TYPE_TEXT = 'text';

export const VALUE_TYPES = [
    TYPE_CURRENCY,
    TYPE_PERCENT,
    TYPE_NUMBER,
    TYPE_TEXT,
    TYPE_RATIO,
];

export const validateStringData = (data) => data || NA;

export const getAdaptedDate = (date, t, withTodayWord) => {
    if (date === undefined) return NA;

    if (isToday(date)) return withTodayWord ? t('messages.today') : formatTime(date);
    if (isTomorrow(date)) return t('messages.tomorrow');
    if (isYesterday(date)) return t('messages.yesterday');

    return formatDate(date);
};

export const percentIsZero = (num, precision = 2) => {
    if (num === 0) return num;
    if (Number(num).toFixed(precision) === (0).toFixed(precision)) return '<1';
    if (Number(num).toFixed(precision) === `-${(0).toFixed(precision)}`) return '>-1';

    return num;
};

export const chartValueFormatCurrency = {
    symbol: '$',
    precision: 0,
    thousand: ',',
    decimal: '.',
    format: '%v%s',
};

export const chartValueFormatPercent = {
    symbol: '%',
    precision: 2,
    thousand: ',',
    decimal: '.',
    format: '%v%s',
};

export const formatCurrency = function (amount, symbol) {
    return isNumber(+amount)
        ? formatMoney(+amount, {
            symbol: symbol || '',
            decimal: '.',
            thousand: ',',
            format: '%s %v',
        })
        : NA;
};

export const formatCurrencyWithPrecision = (amount, precision, symbol) => (isNumber(+amount)
    ? formatMoney(+amount, {
        precision,
        symbol: symbol || '',
        decimal: '.',
        thousand: ',',
        format: '%s %v',
    })
    : NA);

export const formatPercentageValue = function (amount, precision = 2) {
    if (!isNumber(amount)) return NA;
    const number = Math.abs(amount.toFixed(precision)) === Number((0).toFixed(precision))
        ? 0 : amount;

    return formatNumber(number, { precision, thousand: '', decimal: ',' });
};

export const formatPercentage = (amount, { multiplier } = { multiplier: 1 }) => (typeof amount === 'number'
    ? `${formatPercentageValue(amount * multiplier)}%`
    : `${NA} %`);

export const getfromDataObject = (data, path, defaultData = NA) => getOr(defaultData, path, data);

export const formatBigNumber = (value, precision = 2) => (
    value === undefined || Number.isNaN(value)
        ? NA
        : formatMoney(value, {
            precision,
            decimal: '.',
            thousand: ',',
            format: '%v',
        })
);

const getContractedNumber = (num, value, formatter, precision) => (formatter
    ? formatter(value / num, {
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
    })
    : formatBigNumber(value / num));

export const formatNumberRounding = (value, formatter, precision = 2) => {
    if (value === undefined || Number.isNaN(value)) {
        return NA;
    }
    const getContracted = (num) => getContractedNumber(num, value, formatter, precision);

    if (Math.abs(value) >= 1000000000) {
        return `${getContracted(1000000000)}Bn`;
    }

    if (Math.abs(value) >= 1000000) {
        return `${getContracted(1000000)}M`;
    }

    if (Math.abs(value) >= 1000) {
        return `${getContracted(1000)}K`;
    }

    return formatter ? formatter(value) : formatBigNumber(value);
};

const formatNumberOnlyWithFormatterDecorator = (
    formatNumberFunction,
) => (value, formatter, precision = 2, useOnlyFormatter = false) => {
    if (useOnlyFormatter && formatter) {
        return formatter(value, {
            maximumFractionDigits: precision,
            minimumFractionDigits: precision,
        });
    }

    return formatNumberFunction(value, formatter, precision);
};

const decoratedFormatNumberRounding = formatNumberOnlyWithFormatterDecorator(formatNumberRounding);

export const formatSignPercent = (value) => (isNumber(value) ? `${value > 0 ? '+' : ''}${formatPercentage(value)}` : `${NA} %`);
export const formatSignPercentWithoutDecimals = (value) => (isNumber(value) ? `${value > 0 ? '+' : ''}${formatPercentageValue(value, 0)}%` : `${NA} %`);

export const formatValueByType = (
    value, typeValue, withSign, formatter, precision = 2, useOnlyFormatter = false,
) => {
    switch (typeValue) {
        case TYPE_CURRENCY:
            return `${withSign && value > 0 ? '+' : ''}${decoratedFormatNumberRounding(value, formatter, precision, useOnlyFormatter)}`;
        case TYPE_PERCENT:
            return `${withSign && value > 0 ? '+' : ''}${formatter(value, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}%`;
        case TYPE_RATIO:
            return `${withSign && value > 0 ? '+' : ''}${formatter(value, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
        case TYPE_NUMBER:
            return `${withSign && value > 0 ? '+' : ''}${formatter(value, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}`;
        default:
            return value;
    }
};

export const getColorClass = (value, className, precision = 2) => {
    if (typeof value !== 'number') return `${className}`;

    const number = Number(value.toFixed(precision));

    if (number > 0) {
        return `${className} ${className}_positive`;
    }
    if (number < 0) {
        return `${className} ${className}_negative`;
    }

    return `${className}`;
};

export const yAxisFormatTotal = ({ value }) => formatNumberRounding(value);
export const yAxisFormatPercent = (value) => `${formatNumber(value * 1, {
    precision: 2,
    thousand: '',
    decimal: ',',
})}%`;

export const calendarFormat = {
    dayFormat: 'D',
    timeFormat: 'H:mm',
    monthsFormat: 'MMM',
    firstDayFormat: 'MMMM Do',
    weekdaysMinFormat: 'dddd',
};

export const labelFormat = (num, formatter = (n) => n) => `<span class="label">${formatter(formatSignPercentWithoutDecimals(num))}</span>`;
export const allocationPercentFormat = (number) => `${percentIsZero(formatBigNumber(number, 0))}%`;
export const allocationSAPercentFormat = (number) => `${percentIsZero(formatBigNumber(number, 0))}`;
export const validateData = (data) => ((data || data === 0) ? data : NA);
export const validateNumber = (data) => data || 0;
export const formatWithDate = (num, date, getFormattedDate) => `<b>${num}</b><br/>${getFormattedDate
    ? getFormattedDate(formatDate(date))
    : formatDate(date)}`;

export const formatProjectionDate = (date, t) => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1);
    const year = getDatesDifference(date, start, 'years');
    const months = getDatesDifference(date, start, 'months') - 12 * year;
    const yearText = `${t('clientDashboard.year')}${year > 1 ? 's' : ''}`;
    const monthsText = `${t('clientDashboard.month')}${months > 1 ? 's' : ''}`;

    return `${year} ${yearText} ${months} ${monthsText}`;
};

export const formatInput = (value) => ((value !== undefined && value !== '')
    ? formatBigNumber(value, 0) : '');

export const labelBarFormat = (label, value) => {
    const valuesFormatted = formatInput(value);

    return `<label class="Form-control-label">
        <span>${label}</span></label>${valuesFormatted}`;
};
