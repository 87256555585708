import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkNullData } from 'utils';
import ServiceManager from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import AdapterError from 'errors/AdapterError';
import { executionHardCoded } from '../mockData';
import { adaptModelPortfolio } from '../adapters/adaptModelPortfolio';

export const useModelPortfolio = (clientId) => {
    const [investAmount] = useState(1000000);
    const [acceptedRisk, setAcceptedRisk] = useState(undefined);
    const [chosenProduct, setChosenProduct] = useState(undefined);
    const [showModelPortfolio, setShowModalPortfolio] = useState(false);
    const [data, setData] = useState({
        investmentAllocation: { chart: [{ data: { pie: {} } }] },
        positions: [],
        overview: {},
        currency: {},
    });
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const { i18n, t } = useTranslation();
    const params = { language: i18n.language };

    const onShowModelPortfolio = useCallback(() => {
        setError(null);
        setLoading(true);
        setShowModalPortfolio(true);

        (async () => {
            try {
                const { data: { BaseCurrency } } = await ServiceManager
                    .portfolioManagement('getInvestmentOverview', [clientId, params]);
                const paramsModel = BaseCurrency && BaseCurrency.Id
                    ? { ...params, currencyId: BaseCurrency.Id } : params;
                const { data: models } = await ServiceManager
                    .portfolioManagement('getModelPortfolios', [chosenProduct.id, params]);

                if (checkNullData(models)) {
                    try {
                        setData(adaptModelPortfolio(
                            { ...executionHardCoded, BaseCurrency }, investAmount, chosenProduct, t,
                        ));
                        setLoading(false);
                    } catch (err) {
                        console.log(err);
                        throw new AdapterError(err);
                    }
                } else {
                    const model = models.find(({ RiskCategory: { Id } }) => Id === acceptedRisk);
                    const { data: modelData } = await ServiceManager
                        .portfolioManagement('getModelPortfolio', [model.Id, paramsModel, false]);

                    try {
                        setData(adaptModelPortfolio(modelData, investAmount, chosenProduct, t));
                        setLoading(false);
                    } catch (err) {
                        console.log(err);
                        throw new AdapterError(err);
                    }
                }
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                )(err);
            }
        })();
    }, [acceptedRisk, chosenProduct, i18n.language]);

    const returnOnRiskProfile = useCallback(() => {
        setShowModalPortfolio(false);
    }, [showModelPortfolio]);

    return {
        setAcceptedRisk,
        setChosenProduct,
        showModelPortfolio,
        onShowModelPortfolio,
        returnOnRiskProfile,
        data,
        isLoading,
        error,
    };
};
