import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { subtractUnits } from 'utils/datetime';
import ServerError from 'errors/ServerError';
import { useGetInteractions } from 'hooks/useGetInteractions';
import { object, string, number } from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useInteractionModify } from 'domain/Interactions';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { adaptInteractions } from 'adaptors/adaptExistingInteraction';

export const useExistingInteraction = (contactId, isLoadingTypesAndTopics, types, topics) => {
    const [errorUpdating, setErrorUpdating] = useState(null);
    const { t } = useTranslation();

    const schema = useMemo(() => object().shape({
        interactionId: number().required(t('interaction.validation.noExistingInteractionSelected')),
        comment: string().required(t('validation.mandatoryField')).min(10, t('interaction.commentsHelpText')),
    }), [t]);

    const resolver = useYupValidationResolver(schema);
    const {
        register, handleSubmit, errors, setValue, reset, formState, trigger, watch,
    } = useForm({ resolver });

    useEffect(() => {
        register('interactionId');
    }, [register]);

    useEffect(() => {
        reset({
            interactionId: undefined,
            comment: null,
        });
    }, [reset]);

    const values = watch();

    // Validation
    const formErrors = Object.keys(errors || {}).reduce((acc, key) => ({
        ...acc, [key]: errors[key].message,
    }), {});

    // Initial
    const params = useMemo(() => ({
        DateFrom: subtractUnits(new Date(), 1, 'week'),
        DateTo: new Date(),
        PageNumber: 1,
        PageSize: 1000,
    }), []);

    const { data, error, isLoading } = useGetInteractions(adaptInteractions, {
        params,
        contactId,
        loadInitially: true,
        typesAndTopics: { isLoading: isLoadingTypesAndTopics, types, topics },
    });

    const { updateInteractionJournal } = useInteractionModify({ clientId: contactId });

    // Callbacks
    const onExistInteractionCommentChange = useCallback((e) => {
        setValue('comment', e.target.value);

        if (formState.isSubmitted) {
            trigger('comment');
        }
    }, [setValue, trigger, formState.isSubmitted]);

    const onExistInteractionChange = useCallback(([key]) => {
        setValue('interactionId', key);

        if (formState.isSubmitted) {
            trigger('interactionId');
        }
    }, [setValue, trigger, formState.isSubmitted]);

    const updateInteraction = useCallback(() => new Promise(async (resolve, reject) => {
        setErrorUpdating(null);

        const postJournalOptions = { Content: values?.comment };

        try {
            const journal = await updateInteractionJournal(
                { interactionId: values?.interactionId, postJournalOptions },
            );

            resolve(journal);
        } catch (err) {
            setErrorUpdating(new ServerError(err));
            reject(err);
        }
    }), [contactId, values.comment, values?.interactionId]);

    return {
        data,
        error,
        isLoading,
        onExistInteractionCommentChange,
        onExistInteractionChange,
        errorUpdating,
        updateInteraction,
        register,
        formErrors,
        values,
        onExistInteractionHandleSubmit: handleSubmit,
    };
};
