import { useCallback, useState } from 'react';
import Service from 'additiv-services';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptMessageDetails, adaptMessages } from 'adaptors/messagesAdapt';
import { useFormatting } from 'locale';

const defParams = {
    SortOrder: 'desc',
    SortColumn: 'CreateDate',
};

const sortColumnMessaging = {
    Date: 'CreateDate',
    Subject: 'Subject',
};

export const useMessaging = (contactId, memberId) => {
    const [tab, setTab] = useState('inbox');
    const [data, setData] = useState([]);
    const [isGetting, setIsGetting] = useState(false);
    const [needUpdate, setNeedUpdate] = useState(true);
    const { getFormattedDate } = useFormatting();

    const getData = useCallback((params) => new Promise((resolve, reject) => {
        const method = tab === 'sentbox' ? 'getMemberSentMessages' : 'getMemberInboxMessages';
        const args = tab === 'sentbox' ? [
            {
                ...defParams,
                Text: (params?.filters || []).join(' '),
                pageNumber: params?.currentPage || 1,
                pageSize: params?.pageSize,
                SortOrder: params?.sortOrder,
                SortColumn: sortColumnMessaging[params?.sortColumn],
                RecipientIds: [contactId],
            },
            memberId,
        ] : [{
            ...defParams,
            Text: (params?.filters || []).join(' '),
            pageNumber: params?.currentPage || 1,
            pageSize: params?.pageSize,
            SortOrder: params?.sortOrder,
            SortColumn: sortColumnMessaging[params?.sortColumn],
            SenderIds: [contactId],
            memberId,
        }];

        setIsGetting(true);
        ServiceManager.messageService(method, args)
            .then((response) => {
                try {
                    const listOfEmails = adaptMessages(response.data.Data, memberId);

                    setData(listOfEmails);
                    setIsGetting(false);
                    resolve({
                        data: listOfEmails,
                        total: response.data.Total,
                        filters: params?.filters,
                        currentPage: params?.currentPage,
                        sorting: {
                            sortOrder: params?.sortOrder || 'desc',
                            sortColumn: params?.sortColumn || 'Date',
                        },
                    });
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({
                    setError: reject,
                    setLoading: () => {
                        setIsGetting(false);
                        setNeedUpdate(false);
                    },
                }),
            ));
    }), [tab, contactId, memberId]);
    const getDataCallback = () => {
        setNeedUpdate(false);
    };
    const onSearch = (value, params) => new Promise((resolve, reject) => {
        setIsGetting(true);
        try {
            getData(params).then((response) => {
                setIsGetting(false);
                resolve({
                    data: response.data,
                    total: response.total,
                    filters: response.filters,
                });
            });
        } catch (err) {
            reject(new AdapterError(err));
        }
    });
    const getPreview = (id) => new Promise((resolve, reject) => {
        if (needUpdate || isGetting) return;
        setIsGetting(true);
        Service.cancelAll();
        ServiceManager.messageService('getMessageContent', [id, contactId])
            .then((response) => {
                const message = adaptMessageDetails(response.data, memberId, getFormattedDate);

                if (message.Subject.isRead === false) {
                    ServiceManager.messageService('readMessage', [id, contactId])
                        .then(() => {
                            setIsGetting(false);
                            resolve({ ...message, Subject: { ...message.Subject, isRead: true } });
                        })
                        .catch(handlerRequestCanceling(
                            HandlerError({
                                setLoading: setIsGetting,
                                setError: reject,
                            }),
                        ));
                } else {
                    setIsGetting(false);
                    resolve(message);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({
                    setLoading: setIsGetting,
                    setError: reject,
                }),
            ));
    });
    const changeTab = (key) => {
        if (isGetting === false) {
            setTab(key);
            setNeedUpdate(true);
        }
    };
    const deleteItem = (id) => new Promise((resolve, reject) => {
        ServiceManager.messageService('deleteMessage', [id, memberId])
            .then(() => {
                resolve(id);
            })
            .catch(handlerRequestCanceling(
                HandlerError({
                    setLoading: () => true,
                    setError: reject,
                }),
            ));
    });

    return {
        tab,
        needUpdate,
        getData,
        onSearch,
        changeTab,
        getPreview,
        deleteItem,
        getDataCallback,
        data,
    };
};
